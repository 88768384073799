import * as actionTypes from '../actions/actionTypes' 

const initState = {
    entityError: null,
    entitySuccess: null,
    userevaluationsessions: null,
    evaluation: null, 
    evaluationquestions: null,
    evaluationratings: null,
    userevaluationsessionsresponses: null,
    retentionSessionResults: null,
    evaluations: null, 
    assignments: null,
    userattributelookups: null,
    jobpositions:null,
    lookupselections:null,
    goals: null,
    skills: null, 
    skillChecklist: null,
    skillChecklistGroupBy: null,
    userevaluation: null,
    notificationmessages: null,
    processsteps: null, 
    userlookup:null,
    attachments:null,
    reminders:null,
    audittrail:null,
    policies:null,
    groups:null,
    menus:null,
    knowledges:null,
    orgDivisions:null,
    testlibrary:null,
    knowledgeinstances:null,
    facilities:null,
    enrollmentMessage:null,
    certifications:null,
    knowledgesessions:null,
    myknowledgesessions:null,
    mycalendar:null,
    knowledgesessionevaluation:null,
    knowledgesessionassessment:null,
    scorm:null,
    scormusersession:null,
    aiccusersession:null,
}

const entityReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GETUSEREVALUATIONSESSION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETUSEREVALUATIONSESSION_SUCCESS:
            return {
                ...state, 
                userevaluationsessions: action.payload.userevaluationsessions,
                error: null
            }
        case actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_SUCCESS:
            return {
                ...state, 
                userevaluationsessionresponses: action.payload.userevaluationsessionresponses,
                error: null
            }
        case actionTypes.GETUSEREVALUATION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETUSEREVALUATION_SUCCESS:
            return {
                ...state, 
                userevaluation: action.payload.userevaluation,
                error: null
            }
        case actionTypes.GETEVALUATION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETEVALUATION_SUCCESS:
            return {
                ...state, 
                evaluation: action.payload.evaluation,
                error: null
            }
        case actionTypes.GETEVALUATIONQUESTIONS_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETEVALUATIONQUESTIONS_SUCCESS:
            return {
                ...state, 
                evaluationquestions: action.payload.evaluationquestions,
                error: null
            }
        case actionTypes.GETEVALUATIONRATINGS_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETEVALUATIONRATINGS_SUCCESS:
            return {
                ...state, 
                evaluationratings: action.payload.evaluationratings,
                error: null
            }
        case actionTypes.UPDATEEVALUATIONRESPONSES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATIONRESPONSES_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEEVALUATIONSESSION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATIONSESSION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETRETENTIONPREDICTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETRETENTIONPREDICTION_SUCCESS:
            return {
                ...state, 
                retentionSessionResults: action.payload
            }
        case actionTypes.GETEVALUATIONS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETEVALUATIONS_SUCCESS:
            return {
                ...state, 
                evaluations: action.payload
            }
        case actionTypes.GETASSIGNMENTS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETASSIGNMENTS_SUCCESS:
            return {
                ...state, 
                assignments: action.payload
            }
        case actionTypes.GETUSERATTRIBUTELOOKUPS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETUSERATTRIBUTELOOKUPS_SUCCESS:
            return {
                ...state, 
                userattributelookups: action.payload.userattributeslookups
            }
        case actionTypes.GETUSERSBYPOLICY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETUSERSBYPOLICY_SUCCESS:
            return {
                ...state, 
                userlookup: action.payload.userlookup
            }
        case actionTypes.INSERTUSERGROUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERGROUP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEUSERGROUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEUSERGROUP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERGROUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERGROUP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERLICENSE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERLICENSE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERLICENSE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERLICENSE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERCREDENTIAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERCREDENTIAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERCREDENTIAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERCREDENTIAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERGOAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERGOAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERGOAL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERGOAL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSERSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTUSERSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEUSERSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEUSERSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEUSERSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEUSERSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETSKILLS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETSKILLS_SUCCESS:
            return {
                ...state, 
                skills: action.payload
            }
        case actionTypes.INSERTSKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTSKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETESKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETESKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATESKILL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATESKILL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETSKILLCHECKLIST_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETSKILLCHECKLIST_SUCCESS:
            return {
                ...state, 
                skillChecklist: action.payload
            }
        case actionTypes.GETSKILLCHECKLISTGROUPBY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETSKILLCHECKLISTGROUPBY_SUCCESS:
            return {
                ...state, 
                skillChecklistGroupBy: action.payload
            }
        case actionTypes.GETJOBPOSITIONS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETJOBPOSITIONS_SUCCESS:
            return {
                ...state, 
                jobpositions: action.payload
            }
        case actionTypes.GETJOBPOSITIONREQUIREMENTS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONREQUIREMENTS_SUCCESS:
            return {
                ...state, 
                jobpositions: {
                    ...state.jobpositions,  
                    jobpositions: {
                        ...state.jobpositions.jobpositions,
                        JobPositionRequirement: action.payload.JobPositionRequirement
                    }
                },
                authError: null
            }
        case actionTypes.GETJOBPOSITIONSKILLS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONSKILLS_SUCCESS:
            return {
                ...state, 
                jobpositions: {
                    ...state.jobpositions,  
                    jobpositions: {
                        ...state.jobpositions.jobpositions,
                        JobPositionSkill: action.payload.JobPositionSkill
                    }
                },
                authError: null
            }
        case actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_SUCCESS:
            return {
                ...state, 
                jobpositions: {
                    ...state.jobpositions,  
                    jobpositions: {
                        ...state.jobpositions.jobpositions,
                        JobPositionEvaluationQuestionGroup: action.payload.JobPositionEvaluationQuestionGroup
                    }
                },
                authError: null
            }
        case actionTypes.UPDATEREMINDERLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATEREMINDERLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Reminder: action.payload.Reminder
                },
                authError: null
            }
        case actionTypes.UPDATEJPLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATEJPLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    JobPosition: action.payload.JobPosition
                },
                authError: null
            }
        case actionTypes.UPDATEGOALLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Goal: action.payload.Goal
                },
                authError: null
            }
        case actionTypes.UPDATEGOALLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATESKILLLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Skill: action.payload.Skill
                },
                authError: null
            }
        case actionTypes.UPDATESKILLLOOKUPSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATEUSERLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    User: action.payload.User
                },
                authError: null
            }
        case actionTypes.INSERTEVALUATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTEVALUATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEEVALUATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEEVALUATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEEVALUATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTEVALUATIONQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTEVALUATIONQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEEVALUATIONQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEEVALUATIONQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEEVALUATIONQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEEVALUATIONQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTEVALUATIONRATING_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTEVALUATIONRATING_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEEVALUATIONRATING_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEEVALUATIONRATING_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTASSIGNMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTASSIGNMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEASSIGNMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEASSIGNMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEASSIGNMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEASSIGNMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETGOALS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETGOALS_SUCCESS:
            return {
                ...state, 
                goals: action.payload
            }
        case actionTypes.GETNOTIFICATIONMESSAGES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETNOTIFICATIONMESSAGES_SUCCESS:
            return {
                ...state, 
                notificationmessages: action.payload
            }
        case actionTypes.INSERTNOTIFICATIONMESSAGE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTNOTIFICATIONMESSAGE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETENOTIFICATIONMESSAGE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETENOTIFICATIONMESSAGE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATENOTIFICATIONMESSAGE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATENOTIFICATIONMESSAGE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETREMINDERS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETREMINDERS_SUCCESS:
            return {
                ...state, 
                reminders: action.payload
            }
        case actionTypes.GETAUDITTRAIL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETAUDITTRAIL_SUCCESS:
            return {
                ...state, 
                audittrail: action.payload
            }
        case actionTypes.INSERTREMINDER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTREMINDER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEREMINDER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEREMINDER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEREMINDER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEREMINDER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTREMINDEREMAIL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTREMINDEREMAIL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEREMINDEREMAIL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEREMINDEREMAIL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEREMINDEREMAIL_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEREMINDEREMAIL_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETPROCESSSTEPS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPROCESSSTEPS_SUCCESS:
            return {
                ...state, 
                processsteps: action.payload
            }
        case actionTypes.INSERTPROCESSSTEP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTPROCESSSTEP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEPROCESSSTEP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEPROCESSSTEP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETATTACHMENTS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETATTACHMENTS_SUCCESS:
            return {
                ...state, 
                attachments: action.payload
            }
        case actionTypes.INSERTATTACHMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTATTACHMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEATTACHMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEATTACHMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEPROCESSSTEP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEPROCESSSTEP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETCOMPETENCIES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETCOMPETENCIES_SUCCESS:
            return {
                ...state, 
                userattributelookups: {
                    ...state.userattributelookups,  
                    Skills: action.payload.Skills
                },
                authError: null
            }
        case actionTypes.GETPOLICIES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPOLICIES_SUCCESS:
            return {
                ...state, 
                policies: action.payload
            }
        case actionTypes.INSERTPOLICY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTPOLICY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEPOLICY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEPOLICY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEPOLICY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEPOLICY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTGROUPPOLICY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTGROUPPOLICY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEGROUPPOLICY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEGROUPPOLICY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETGROUPS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETGROUPS_SUCCESS:
            return {
                ...state, 
                groups: action.payload
            }
        case actionTypes.INSERTGROUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTGROUP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEGROUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEGROUP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEGROUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEGROUP_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETMENUS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETMENUS_SUCCESS:
            return {
                ...state, 
                menus: action.payload
            }
        case actionTypes.INSERTMENU_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTMENU_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEMENU_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEMENU_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEMENU_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEMENU_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETPGKNOWLEDGES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGKNOWLEDGES_SUCCESS:
            return {
                ...state, 
                knowledges: action.payload
            }
        case actionTypes.GETPGKNOWLEDGESLOOKUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGKNOWLEDGESLOOKUP_SUCCESS:
            return {
                ...state, 
                knowledgesLookup: action.payload
            }
        case actionTypes.INSERTKNOWLEDGE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTKNOWLEDGE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEKNOWLEDGE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEKNOWLEDGE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETPGKNOWLEDGEINSTANCES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGKNOWLEDGEINSTANCES_SUCCESS:
            return {
                ...state, 
                knowledgeinstances: action.payload
            }
        case actionTypes.INSERTKNOWLEDGEINSTANCE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTKNOWLEDGEINSTANCE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTKNOWLEDGEINSTANCERECURRING_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTKNOWLEDGEINSTANCERECURRING_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEKNOWLEDGEINSTANCE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEKNOWLEDGEINSTANCE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGEINSTANCE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGEINSTANCE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETPGKNOWLEDGESESSIONS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGKNOWLEDGESESSIONS_SUCCESS:
            return {
                ...state, 
                knowledgesessions: action.payload
            }
        case actionTypes.GETPGKNOWLEDGESESSIONSBYUSER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGKNOWLEDGESESSIONSBYUSER_SUCCESS:
            return {
                ...state, 
                myknowledgesessions: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGESESSION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEKNOWLEDGESESSION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTUSER_ERROR:
            return {
                ...state, 
                entityError: action.payload,
                entitySuccess: null
            }
        case actionTypes.INSERTUSER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload,
                entityError: null
            }
        case actionTypes.UPDATEUSER_ERROR:
            return {
                ...state, 
                entityError: action.payload,
                entitySuccess: null
            }
        case actionTypes.UPDATEUSER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload,
                entityError: null
            }
        case actionTypes.GETCONTENTS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETCONTENTS_SUCCESS:
            return {
                ...state, 
                contents: action.payload
            }
        case actionTypes.INSERTCONTENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTCONTENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETECONTENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETECONTENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATECONTENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATECONTENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEPILLARLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Pillar: action.payload.Pillar
                },
                authError: null
            }
        case actionTypes.UPDATEKNOWLEDGELOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Knowledge: action.payload.Knowledge
                },
                authError: null
            }
        case actionTypes.UPDATECONTENTLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Content: action.payload.Content
                },
                authError: null
            }
        case actionTypes.GETORGLEARNINGOBJECTTREE_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETORGLEARNINGOBJECTTREE_SUCCESS:
            return {
                ...state, 
                orgLearningObjectTree: action.payload.orgLearningObjectTree,
                authError: null
            }
        case actionTypes.INSERTCATALOG_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTCATALOG_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETECATALOG_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETECATALOG_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATECATALOG_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATECATALOG_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETTESTLIBRARIES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETTESTLIBRARIES_SUCCESS:
            return {
                ...state, 
                testlibrary: action.payload
            }
        case actionTypes.INSERTTESTLIBRARY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTTESTLIBRARY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETETESTLIBRARY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETETESTLIBRARY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATETESTLIBRARY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATETESTLIBRARY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTTESTINSTANCE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTTESTINSTANCE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETETESTINSTANCE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETETESTINSTANCE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATETESTINSTANCE_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATETESTINSTANCE_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTTESTQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTTESTQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETETESTQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETETESTQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATETESTQUESTION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATETESTQUESTION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTTESTANSWER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTTESTANSWER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETETESTANSWER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETETESTANSWER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATETESTANSWER_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATETESTANSWER_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEASSESSMENTLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Assessment: action.payload.Assessment
                },
                authError: null
            }
        case actionTypes.UPDATENOTIFICATIONMESSAGELOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    NotificationMessage: action.payload.NotificationMessage
                },
                authError: null
            }
        case actionTypes.UPDATEFACILITYROOMLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    FacilityRoom: action.payload.FacilityRoom
                },
                authError: null
            }
        case actionTypes.GETFACILITIES_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETFACILITIES_SUCCESS:
            return {
                ...state, 
                facilities: action.payload
            }
        case actionTypes.INSERTFACILITY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTFACILITY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEFACILITY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEFACILITY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEFACILITY_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEFACILITY_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTFACILITYROOM_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTFACILITYROOM_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATEFACILITYROOM_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATEFACILITYROOM_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETEFACILITYROOM_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETEFACILITYROOM_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETPGCERTIFICATIONS_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGCERTIFICATIONS_SUCCESS:
            return {
                ...state, 
                certifications: action.payload
            }
        case actionTypes.GETPGCERTIFICATIONSLOOKUP_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETPGCERTIFICATIONSLOOKUP_SUCCESS:
            return {
                ...state, 
                certificationsLookup: action.payload
            }
            case actionTypes.INSERTCERTIFICATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTCERTIFICATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.DELETECERTIFICATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.DELETECERTIFICATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATECERTIFICATION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.UPDATECERTIFICATION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.UPDATECERTIFICATIONLOOKUPSELECTION_SUCCESS:
            return {
                ...state, 
                lookupselections: {
                    ...state.lookupselections,  
                    Certification: action.payload.Certification
                },
                authError: null
            }
        case actionTypes.GETUSERCALENDAR_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.GETUSERCALENDAR_SUCCESS:
            return {
                ...state, 
                mycalendar: action.payload
            }
        case actionTypes.GETKNOWLEDGESESSIONEVALUATION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETKNOWLEDGESESSIONEVALUATION_SUCCESS:
            return {
                ...state, 
                knowledgesessionevaluation: action.payload,
                error: null
            }
        case actionTypes.GETKNOWLEDGESESSIONASSESSMENT_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETKNOWLEDGESESSIONASSESSMENT_SUCCESS:
            return {
                ...state, 
                knowledgesessionassessment: action.payload,
                error: null
            }
        case actionTypes.INSERTKNOWLEDGESESSIONASSESSMENT_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTKNOWLEDGESESSIONASSESSMENT_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.GETSCORMFORLAUNCH_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETSCORMFORLAUNCH_SUCCESS:
            return {
                ...state, 
                scorm: action.payload,
                error: null
            }
        case actionTypes.GETSCORMUSERSESSION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETSCORMUSERSESSION_SUCCESS:
            return {
                ...state, 
                scormusersession: action.payload,
                error: null
            }
        case actionTypes.GETAICCUSERSESSION_ERROR:
            return {
                ...state, 
                error: action.payload
            }
        case actionTypes.GETAICCUSERSESSION_SUCCESS:
            return {
                ...state, 
                aiccusersession: action.payload,
                error: null
            }
        case actionTypes.INSERTSCORMUSERSESSION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTSCORMUSERSESSION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }
        case actionTypes.INSERTAICCUSERSESSION_ERROR:
            return {
                ...state, 
                entityError: action.payload
            }
        case actionTypes.INSERTAICCUSERSESSION_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload
            }                
        case actionTypes.PROCESSENROLLMENT_ERROR:
            return {
                ...state, 
                enrollmentMessage: action.payload
            }
        case actionTypes.PROCESSENROLLMENT_SUCCESS:
            return {
                ...state, 
                enrollmentMessage: action.payload
            }
        case actionTypes.PROCESSENROLLMENTCLEAR_ERROR:
            return {
                ...state, 
                enrollmentMessage: action.payload
            }
        case actionTypes.PROCESSENROLLMENTCLEAR_SUCCESS:
            return {
                ...state, 
                enrollmentMessage: action.payload
            }
        case actionTypes.CLEARENTITYSTATE_SUCCESS:
            return {
                ...state, 
                entityError: null,
                entitySuccess: null,
                userevaluationsessions: null,
                evaluation: null, 
                evaluationquestions: null,
                evaluationratings: null,
                userevaluationsessionsresponses: null,
                retentionSessionResults: null,
                evaluations: null, 
                assignments: null,
                userattributelookups: null,
                jobpositions:null,
                lookupselections:null,
                goals: null,
                skills: null, 
                skillChecklist: null,
                skillChecklistGroupBy: null,                
                userevaluation: null,
                notificationmessages: null,
                processsteps: null, 
                userlookup: null,
                attachments: null, 
                reminders: null,
                audittrail: null,
                policies:null,
                groups:null,
                menus:null,
                knowledges:null,
                orgDivisions:null,
                testlibrary:null,
                knowledgeinstances:null,
                facilities:null,
                enrollmentMessage:null,
                certifications:null,
                knowledgesessions:null,
                myknowledgesessions:null,
                mycalendar:null,
                knowledgesessionevaluation:null,
                knowledgesessionassessment:null,
                scorm:null,
                scormusersession:null,
                aiccusersession:null,            
            }
                                                                                                                
        default:
            return state;
    }
}

export default entityReducer



