import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { styles } from '../layout/styles';
import { Button, Card, CardBody, Modal, Table } from 'react-bootstrap';
import { Scorm12API, Scorm2004API, AICC } from "scorm-again";
import { GradingType, KnowledgePlayerType, LOLifeCycleStatus } from '../../config/Constant';
import AlertMessage from "./AlertMessage";
import Spinner from "./Spinner";
import { insertScormUserSession, insertAICCUserSession, getScormUserSession, getAICCUserSession, processEnrollment, processEnrollmentClear } from "../../store/actions/entityActions";

function ScormAiccPlayer (props) {
//const ScormAiccPlayer = ({ knowledgePlayerType, courseUrl, initialData, onCommit }) => {
  const { knowledgePlayerType, courseUrl, onCommit, sessionid, userid, api_access_token, scormInitialData, scormusersession, profile, auth, 
        gradingTypeID, passingScore, sessionCreditUnits, sessionCreditHours, sessionCreditOthers } = props;    

  const [courseAPI, setCourseAPI] = useState(null);
  const [lastSavedData, setLastSavedData] = useState({});
  const [apiCommitObject, setApiCommitObject] = useState(null);
  const [initialData, setInitialData] = useState({});
  const iframeRef = useRef(null);
  const autoSaveInterval = useRef(null);

  useEffect(() => {
    // window.top=window;
    // window.opener=window;

    let api = null;
    var scormaiccjson = generateInitialJSON();
    console.log(scormaiccjson);

    //const oSettings = {"logLevel": 1};//getSettingsFromParams();
    const oSettings = getSettingsFromParams();
    console.log(oSettings);

    // Detect SCORM/AICC type from the provided course URL
    if (knowledgePlayerType === KnowledgePlayerType.SCORM2004) {
      api = new Scorm2004API(oSettings);
      console.log("Initializing SCORM 2004", api);
      setCourseAPI(api);
      window.API_1484_11 = api;
      window.API_1484_11.loadFromFlattenedJSON(scormaiccjson);
    } else if (knowledgePlayerType === KnowledgePlayerType.SCORM12) { 
      api = new Scorm12API(oSettings);
      console.log("Initializing SCORM 1.2", api);
      api.loadfromJSON(scormaiccjson);
      setCourseAPI(api);
      window.API = api;
      window.API.loadFromFlattenedJSON(scormaiccjson);
    } else if (knowledgePlayerType === KnowledgePlayerType.AICC) {
      api = new AICC(oSettings);
      console.log("Initializing AICC", api);
      setCourseAPI(api);
      window.API = api;
      window.API.loadFromFlattenedJSON(scormaiccjson);
    } else {
      api = new Scorm12API(oSettings);
      console.warn("Unknown Course Type - Defaulting to SCORM 1.2", api);
      setCourseAPI(api);
      window.API = api;
      window.API.loadFromFlattenedJSON(scormaiccjson);
    }

    console.log(courseUrl);

    // return () => {
    //   // if (api) api.terminate();
    //   //if (autoSaveInterval.current) clearInterval(autoSaveInterval.current);
    // };
  }, [courseUrl]);

  function getSettingsFromParams() {
     //function getSettingsFromParams(urlParams) {
    //const urlParams = window.location.search;
    var settings = {
      autocommit: false,
      autocommitSeconds: 60,
      autoProgress: false,
      logLevel: 1,
      mastery_override: false,
      sendFullCommit: true,
      asyncCommit: false,
      autoProgress: true,
      dataCommitFormat: 'flattened',
      commitRequestDataType: 'application/json;charset=UTF-8',
      lmsCommitUrl: 'blob:null', // goes no-where
      fetchMode: 'no-cors',      
      xhrWithCredentials: false,      
      xhrHeaders: { accept: 'application/json',},  
      //lmsCommitUrl: process.env.REACT_APP_RETENTASAPI_INSERTSCORMUSERSESSION,      
      // lmsCommitUrl: 'https://apidev.observatis.com/api/learningobject/InsertScormUserSession',
      //fetchMode: 'cors',      
      //xhrWithCredentials: true,
      // lmsCommitUrl: 'https://localhost:44332/api/learningobject/InsertScormUserSession',
      // fetchMode: 'no-cors',      
      // xhrWithCredentials: false,
      // xhrHeaders: { 
      //   accept: 'application/json',
      //   Authorization: `Bearer ${api_access_token}` // Add Bearer token here
      // },      
      requestHandler: function (commitObject) {
        // console.log(commitObject);
        const updatedCommitObject = {
          ...commitObject, 
          obplayertype: knowledgePlayerType, 
          obsid: sessionid,
          obuid: userid,
        }                
        // console.log(updatedCommitObject);
        processCommit(updatedCommitObject);
        return updatedCommitObject;
      }      
    };
 
    return settings;
  }  

  const isISODateString = (dateString) => {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(dateString);
  };

  const formatToISO = (dateString) => {
      try {
          let isoString = new Date(dateString).toISOString();

          // Ensure SCORM-compatible format (only up to 3 decimal places)
          return isoString.replace(/\.\d{3}Z$/, "Z"); 
      } catch (error) {
          console.error("Invalid timestamp:", dateString);
          return dateString; // Return as-is if parsing fails
      }
  };  
  
  function generateInitialJSON() {
    if ((knowledgePlayerType === KnowledgePlayerType.SCORM12) || (knowledgePlayerType === KnowledgePlayerType.SCORM2004)) {
      if ((scormusersession) && (scormusersession.length > 0)) {
        let scormData = {};

        scormusersession.forEach(item => {
          if (item.ScormCMIValue !== '{}') {
            //scormData[item.ScormCMIType] = item.ScormCMIValue;
            let key = item.ScormCMIType;
            let value = item.ScormCMIValue;
            // If SCORM version is 2004, and key contains "timestamp", and value is not in ISO format
            if (knowledgePlayerType === KnowledgePlayerType.SCORM2004 && key.toLowerCase().includes("timestamp") && !isISODateString(value)) {
                value = '';//formatToISO(value);
            }

            scormData[key] = value;            
          }
        });
    
        return scormData; 
      } else if (knowledgePlayerType === KnowledgePlayerType.SCORM12) {
        var oInitialData = {
          'cmi.core.student_id': auth?.UserID,
          'cmi.core.student_name': profile?.FirstName + ' ' + profile?.LastName,          
        }
        if (gradingTypeID === GradingType.PassingScoreRequired) {
          oInitialData = {
            ...oInitialData, 
            'cmi.student_data.mastery_score': passingScore,          
          }                
        }
        return oInitialData;
      } else if (knowledgePlayerType === KnowledgePlayerType.SCORM2004) {        
        var oInitialData = {
          'cmi.learner_id': auth?.UserID,
          'cmi.learner_name': profile?.FirstName + ' ' + profile?.LastName,
        }
        if (gradingTypeID === GradingType.PassingScoreRequired) {
          oInitialData = {
            ...oInitialData, 
            'cmi.scaled_passing_score': parseFloat((passingScore/100)).toString(),          
          }                
        }
        return oInitialData;
      } 
    } else if (knowledgePlayerType === KnowledgePlayerType.AICC) {
      return {};
    } 
  }

  async function processCommit(commitObject) {
    if (JSON.stringify(apiCommitObject) !== JSON.stringify(commitObject)) {
      console.log('New CMI Calls - Saving...', commitObject);
      setApiCommitObject(commitObject);      
      await props.insertScormUserSession(commitObject);
      
      await processWBTEnrollment(commitObject);

      // const oSParams = {
      //   LearningObjectSessionID: sessionid, 
      // }; 
      // console.log(oSParams);
      // if ((knowledgePlayerType === KnowledgePlayerType.SCORM12) || (knowledgePlayerType === KnowledgePlayerType.SCORM2004)) {
      //   await props.getScormUserSession(oSParams);
      // } else if (knowledgePlayerType === KnowledgePlayerType.AICC) {
      //   await props.getAICCUserSession(oSParams);
      // }
    } else {
      console.log('Duplicate CMI Calls - Skipped');
    }
  }

  function getScormCompletionStatus(commitObject) {
    let completionStatus = "unknown"; // Default
    let successStatus = "unknown";
    let userScore = null;
    let masteryScore = null;

    if (knowledgePlayerType === KnowledgePlayerType.SCORM12) {
        // SCORM 1.1 & 1.2
        completionStatus = commitObject["cmi.core.lesson_status"] || "unknown";
        userScore = commitObject["cmi.core.score.raw"] ? parseFloat(commitObject["cmi.core.score.raw"]) : null;
        masteryScore = commitObject["cmi.student_data.mastery_score"] ? parseFloat(commitObject["cmi.student_data.mastery_score"]) : null;
    } else if (knowledgePlayerType === KnowledgePlayerType.SCORM2004) {
        // SCORM 2004 (All Editions)
        completionStatus = commitObject["cmi.completion_status"] || "unknown";
        successStatus = commitObject["cmi.success_status"] || "unknown";
        userScore = commitObject["cmi.score.scaled"] ? parseFloat(commitObject["cmi.score.scaled"]) * 100 : null;
        masteryScore = commitObject["cmi.scaled_passing_score"] ? parseFloat(commitObject["cmi.scaled_passing_score"]) * 100 : null;
    } else {
        return { error: "Unsupported SCORM version" };
    }

    // Determine if the course is completed/passed
    let isComplete = completionStatus === "completed" || completionStatus === "passed";
    let isPassed = successStatus === "passed";

    if (!isComplete && masteryScore !== null && userScore !== null) {
        if (userScore >= masteryScore) {
            isPassed = true;
            if (knowledgePlayerType === KnowledgePlayerType.SCORM2004) {
                completionStatus = "completed";
                successStatus = "passed";
            } else {
                completionStatus = "passed";
            }
        } else {
            isPassed = false;
            if (knowledgePlayerType === KnowledgePlayerType.SCORM2004) {
                successStatus = "failed";
            }
        }
    }

    return {
        completionStatus: completionStatus,
        successStatus: successStatus,
        userScore: userScore,
        masteryScore: masteryScore,
        isComplete: isComplete,
        isPassed: isPassed
    };
  }

  async function processWBTEnrollment(commitObject) {
    const oCompletionParams = getScormCompletionStatus(commitObject);
    console.log('getScormCompletionStatus', oCompletionParams);
    var sessionStatusChange = LOLifeCycleStatus.Enrolled;
    if (oCompletionParams.isComplete) {
      sessionStatusChange = LOLifeCycleStatus.Complete
      const oParams  = [{
        LearningObjectSessionID: sessionid,
        Status: sessionStatusChange, 
        UpdatedUserID: auth?.UserID,
        Score: oCompletionParams.userScore,
        Complete: oCompletionParams.isComplete,
        Passed: oCompletionParams.isPassed,
        WBT: true,
        CreditUnits: sessionCreditUnits,
        CreditHours: sessionCreditHours,
        CreditOthers: sessionCreditOthers,
        IsStartEnrollment: false,
        IsProcessEnrollment: true,
        DateCompleted: new Date(),
        CreatedByUserID: auth?.UserID,
        UpdatedByUserID: auth?.UserID,
        UserID: auth?.UserID,
        CompanyID: auth?.CompanyID,
      }];        
      console.log('ProcessEnrollment', oParams);
      await props.processEnrollment(oParams);        
    }
  }

  // async function handleOpenScorm() {
  //   window.open(courseUrl);
  // }

  return (
    <div style={{ width: "100%", height: "90vh", border: "1px solid #ccc" }}>
      {/* <a onClick={() => handleOpenScorm()}>
          <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Open Attachment'>open_in_browser</span>
      </a> */}
        <iframe
        ref={iframeRef}
        src={courseUrl}
        width="100%"
        height="100%"
        title="Course Player"
        allowFullScreen
      />
    </div>
  );  
  
};


const mapStateToProps = (state, ownProps) => {
    return {
      auth: state?.auth?.user,
      profile: state?.auth?.profile,
      api_access_token: state.auth?.rtapitoken?.rtapitokendata?.data?.access_token,
      userDetail: state.auth.userDetail,
      companyData: state?.auth?.companyData,
      enrollmentMessage: state.entity.enrollmentMessage,
      scorm: state?.entity?.scorm?.scorm?.Contents,
      scormusersession: state?.entity?.scormusersession?.scormusersession?.ScormUserSession,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScormUserSession: (oSParams) => dispatch(getScormUserSession(oSParams)),
        getAICCUserSession: (oSParams) => dispatch(getAICCUserSession(oSParams)),
        insertScormUserSession: (oParams) => dispatch(insertScormUserSession(oParams)),
        insertAICCUserSession: (oParams) => dispatch(insertAICCUserSession(oParams)),
        processEnrollment: (oParams) => dispatch(processEnrollment(oParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScormAiccPlayer)

