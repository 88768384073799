import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, Table, ButtonGroup, Button, ToggleButton, Modal } from 'react-bootstrap';
import { styles } from '../layout/styles';
import KnowledgeSessionList from './KnowledgeSessionList';
import { KnowledgeListType, LOLifeCycleStatus, LILifeCycleStatus, RetentasType, KnowledgeType, KnowledgeSearchType, Topics, CheckListGroupBy, EmpowermentType } from '../../config/Constant';
import MyCalendar from './MyCalendar';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/daygrid';
import { ResponsiveContainer, PieChart, Pie, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Sector, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { getKnowledgeSessionsByUser, getJobPositionSuccession, getUserCalendar, getSkillChecklist } from "../../store/actions/entityActions";
import UserEvaluationList from './UserEvaluationList';
import UserSuccession from './UserSuccession';
import Spinner from '../layout/Spinner';
import moment from 'moment';
import KnowledgeSearch from './KnowledgeSearch';
import CompetencyChecklist from './CompetencyChecklist';
import TryOut from './TryOut';
import CustomTooltip from '../layout/CustomTooltip';
import CylinderBar from './CylinderBar';

const MyKnowledge = (props) => {
    const { companyData, auth, profile, empowermentType } = props;
    const [loading, setLoading] = useState(false);
    const [expandedCell, setExpandedCell] = useState(null);
    const [profileDialogUserID, setProfileDialogUserID] = useState(-1);
    const [knowledgeStartDateTime, setKnowledgeStartDateTime] = useState(moment().startOf('month'));
    const [knowledgeEndDateTime, setKnowledgeEndDateTime] = useState(moment().endOf('month'));
    const [knowledgeTopicID, setKnowledgeTopicID] = useState(-1);
    const [typeSummary, setTypeSummary] = useState('empowerment');
    const [paramsForChildren, setParamsForChildren] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [radioValue, setRadioValue] = useState(initialTabValue());
    const radios = [
        { name: 'Intelligence', value: 1},
        { name: 'Knowledge', value: 2},
        { name: 'Questionnaires', value: 3},
        { name: 'Performance', value: 4},
    ];
    const [radioKnowledgeValue, setRadioKnowledgeValue] = useState(initialKnowledgeTabValue());
    const radioKnowledges = [
        { name: 'My Tracks', value: 2 },
        { name: 'Calendar', value: 3 },
        { name: 'Search', value: 4 },
        { name: 'Team Enrollments', value: 5 },
        { name: 'Try Out', value: 6 },
    ];
    const onPieEnter = useCallback(
        (_, index) => {
        setActiveIndex(index);
        },
        [setActiveIndex]
    );
    

    const calendarRef = useRef(null);
    const [showProfileGapAnalysis, setShowProfileGapAnalysis] = useState(false);
    const handleCloseProfileGapAnalysis = () => {setShowProfileGapAnalysis(false);}
    const [showCompetencyCheckList, setShowCompetencyCheckList] = useState(false);
    const handleCloseCompetencyCheckList = () => {setShowCompetencyCheckList(false);}

    useEffect(() => {
      if (radioValue === 1) {
        setTypeSummary('Intelligence');
        setKnowledgeTopicID(-1);
      } else if (radioValue === 2) {
        setTypeSummary('Knowledge');
        setRadioKnowledgeValue(2);
      } else if (radioValue === 3) {
        setTypeSummary('Questionnaires');
      } else if (radioValue === 4) {
        setTypeSummary('Performance');
      }
    }, [radioValue])    
    
    useEffect(() => {
        setRadioValue(empowermentType);
    }, [empowermentType])    
    
    function initialKnowledgeTabValue() {
        return 2;
    };

    function initialTabValue() {
        return 1;
    };

    async function handleShowProfileGapAnalysis() {
        setShowProfileGapAnalysis(true); 
    };

    async function showProfileGapAnalysisDialog(userid, jpid) {
        setProfileDialogUserID(userid);
        setLoading(true);
        await props.getJobPositionSuccession(jpid);
        setShowProfileGapAnalysis(true);
        setLoading(false);
    }

    async function handleShowCompetencyCheckList() {        
        setShowCompetencyCheckList(true); 
    };

    async function showCompetencyCheckListDialog(userid) {
        setProfileDialogUserID(userid);
        setLoading(true);
        const oParams = {
            CheckListGroupBy:CheckListGroupBy.User,
            UserID:auth?.UserID,
            SearchUserID:auth?.UserID,
            IsComplete:'false',
            RecordSize:'10',
            PageNumber:'1',
        };           
        console.log(oParams); 
        await props.getSkillChecklist(oParams);        
        setShowCompetencyCheckList(true);
        setLoading(false);
    }

    const handleToggleFullScreen = (cellId) => {
      setExpandedCell(expandedCell === cellId ? null : cellId);
    };

    async function handleKnowledgeTabClick(tab) {
        setLoading(true);
        setRadioKnowledgeValue(tab);
        if (tab === 2) {
            const oKParams = {
                UserID:auth?.UserID,
                LearningObjectProcessStatusTypeID:LOLifeCycleStatus.Enrolled,
                RecordSize:'10',
                PageNumber:1,
            }; 
            console.log(oKParams);
            setParamsForChildren(oKParams);
            await props.getKnowledgeSessionsByUser(oKParams);
        } else if (tab === 3) { // My Calendar
            const oParams = {
                UserID:auth?.UserID,
                CompanyID:auth?.CompanyID,
                SearchTypeID:KnowledgeSearchType.User,
                GroupID:'2',
                StartDateTime: moment(knowledgeStartDateTime).format('YYYY-MM-DD hh:mm a'),
                EndDateTime: moment(knowledgeEndDateTime).format('YYYY-MM-DD hh:mm a'),
                LearningObjectProcessStatusTypeID: LOLifeCycleStatus.Enrolled,
                LearningObjectTypeID: KnowledgeType.Classroom,
                RecordSize:'10',
                PageNumber:'1',
            };                  
            console.log(oParams);
            await props.getUserCalendar(oParams);
        } else if (tab === 4) { // Search Knowlegde
            const oParams = {
                UserID:auth?.UserID,
                CompanyID:auth?.CompanyID,
                SearchTypeID:KnowledgeSearchType.Search,
                GroupID:'2',
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID: KnowledgeType.Classroom,
                RecordSize:'10',
                PageNumber:'1',
            };                  
            console.log(oParams);
            await props.getUserCalendar(oParams);
        } else if (tab === 5) { // Team Tracks
            const oParams = {
                ManagerUserID:auth?.UserID,
                CompanyID:auth?.CompanyID,
                SearchTypeID:KnowledgeSearchType.Manager,
                GroupID:'1',
                LearningObjectProcessStatusTypeID: LOLifeCycleStatus.Enrolled,
                RecordSize:'10',
                PageNumber:'1',
            };                  
            console.log(oParams);
            await props.getUserCalendar(oParams);
        }
        // displayKnowledgeTabContent();
        setLoading(false);    
    };

    async function displayKnowledgeByTopic(topicid) {
        if (knowledgeTopicID === topicid) {
            setKnowledgeTopicID(-1);
        } else {
            setKnowledgeTopicID(topicid);
            const oParams = {
                UserID:auth?.UserID,
                CompanyID:auth?.CompanyID,
                SearchTypeID:KnowledgeSearchType.Search,
                GroupID:'2',
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID: KnowledgeType.ClassroomAndOnline,
                TopicID: topicid,
                RecordSize:'10',
                PageNumber:'1',
            };                  
            // console.log(oParams);
            await props.getUserCalendar(oParams);    
        }
    }

    function displayKnowledgeTabButtons() {
        return (
            radioKnowledges.map((radio, idx) => (
                <li style={(radioKnowledgeValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                    <span style={styles.SpanContainer} onClick={(e) => {handleKnowledgeTabClick(radio.value)}}>{radio.name}</span>
                </li>
            ))
        );
    }

    function getGoogleSmileyRating(ratingScore, ratingTypeName, ratingPillarName) {
        const sTitle = ratingPillarName + ' - ' + ratingScore;
        if (ratingScore === 1) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={sTitle} style={{color:companyData?.StronglyDisagreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_very_dissatisfied</span>
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 2) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={sTitle} style={{color:companyData?.DisagreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_dissatisfied</span>
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 3) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={sTitle} style={{color:companyData?.NeutralColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_neutral</span>                
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 4) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={sTitle} style={{color:companyData?.AgreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_satisfied</span>                
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } else if (ratingScore === 5) {
            return (    
                <Table style={{background:'transparent'}} >
                    <thead>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <span class="material-symbols-outlined" title={sTitle} style={{color:companyData?.StronglyAgreeColor, fontSize:'48px', fontWeight:'lighter', fontStyle:'normal'}}>sentiment_very_satisfied</span>                
                            </th>
                        </tr>
                        <tr>
                            <th style={{background:'transparent'}} >
                                <label class="form-check-label">{ratingTypeName}</label>
                            </th>
                        </tr>
                    </thead>
                </Table>
            );
        } 
    }

    function displayUserRadarChart(currentUser) {
        const radarChartData = [{
            Pillar:'1',
            UserScore: currentUser.PerformanceScore,
            fullMark: 5,
        },
        {
            Pillar:'2',
            UserScore: currentUser.WorkSafetyScore,
            fullMark: 5,
        },
        {
            Pillar:'3',
            UserScore: currentUser.JobStressScore,
            fullMark: 5,
        },
        {
            Pillar:'4',
            UserScore: currentUser.JobSatisficationScore,
            fullMark: 5,
        },
        {
            Pillar:'5',
            UserScore: currentUser.SocialSupportScore,
            fullMark: 5,
        },
        {
            Pillar:'6',
            UserScore: currentUser.ControlScore,
            fullMark: 5,
        },
        {
            Pillar:'7',
            UserScore: currentUser.PersonalEfficacyScore,
            fullMark: 5,
        },
        {
            Pillar:'8',
            UserScore: currentUser.TurnoverIntentionScore,
            fullMark: 5,
        },
        {
            Pillar:'9',
            UserScore: currentUser.OrganizationalCommitmentScore,
            fullMark: 5,
        },
        {
            Pillar:'10',
            UserScore: currentUser.CyberSecurityScore,
            fullMark: 5,
        }];
                
        return (
            <ResponsiveContainer width={160} height={160}>
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="Pillar" />
                <PolarRadiusAxis />
                <Radar name={currentUser.FirstName} dataKey="UserScore" stroke={companyData?.AccentColorSecondary} fill={companyData?.AccentColorSecondary} fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
          );
    }

    function getEmpowermentStats(){
        const isUnHappy = (parseFloat(profile?.ScoredProbabilities) === 1) ? 1 : 2;
        return (
            <Card style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <Card.Header>
                    <Table style={styles.tableBGNoPadding} hover variant="light" >
                        <thead>
                        <tr>
                            <th style={styles.tableText}>Performance-1</th>
                            <th style={styles.tableText}>Safety-2</th>
                            <th style={styles.tableText}>Stress-3</th>
                            <th style={styles.tableText}>Satisfaction-4</th>
                            <th style={styles.tableText}>Social-5</th>
                            <th style={styles.tableText}>Control-6</th>
                            <th style={styles.tableText}>Efficacy-7</th>
                            <th style={styles.tableText}>Turnover-8</th>
                            <th style={styles.tableText}>Commitment-9</th>
                            <th style={styles.tableText}>Cybersecurity-10</th>
                            <th style={styles.tableText}>Overall</th>
                        </tr>
                        <tr>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.Performance)}}>
                                    <span style={(knowledgeTopicID === Topics.Performance) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Performance Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.WorkSafety)}}>
                                    <span style={(knowledgeTopicID === Topics.WorkSafety) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Work Safety Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.JobStress)}}>
                                    <span style={(knowledgeTopicID === Topics.JobStress) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Job Stress Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.JobSatisfaction)}}>
                                    <span style={(knowledgeTopicID === Topics.JobSatisfaction) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Job Satisfaction Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.SocialSupport)}}>
                                    <span style={(knowledgeTopicID === Topics.SocialSupport) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Social Support Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.ControlScale)}}>
                                    <span style={(knowledgeTopicID === Topics.ControlScale) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Control Scale Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.PersonalEfficacy)}}>
                                    <span style={(knowledgeTopicID === Topics.PersonalEfficacy) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Personal Efficacy Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.Turnover)}}>
                                    <span style={(knowledgeTopicID === Topics.Turnover) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Turnover Intention Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.OrganizationCommitment)}}>
                                    <span style={(knowledgeTopicID === Topics.OrganizationCommitment) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Organization Commitment Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                                <a onClick={() => {displayKnowledgeByTopic(Topics.Cybersecurity)}}>
                                    <span style={(knowledgeTopicID === Topics.Cybersecurity) ? styles.ActionIcon : styles.ActionInsideIconGrey} className="material-symbols-outlined" title="Cybersecurity Engagement">network_intelligence_update</span>
                                </a>
                            </th>
                            <th style={styles.tableText}>
                            </th>
                        </tr>
                        </thead>
                            <tbody>
                                <tr>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.PerformanceScore, profile?.PerformanceRatingTypeName, 'Performance')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.WorkSafetyScore, profile?.WorkSafetyRatingTypeName, 'Work Safety')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.JobStressScore, profile?.JobStressRatingTypeName, 'Job Stress')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.JobSatisficationScore, profile?.JobSatisticationRatingTypeName, 'Job Satisfaction')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.SocialSupportScore, profile?.SocialSupportRatingTypeName, 'Social Support')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.ControlScore, profile?.ControlRatingTypeName, 'Control Scale')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.PersonalEfficacyScore, profile?.PersonalEfficacyRatingTypeName, 'Personal Efficacy')}
                                    </td>
                                    <td style={styles.tableText}>   
                                        {getGoogleSmileyRating(profile?.TurnoverIntentionScore, profile?.TurnoverIntentionRatingTypeName, 'Turnover Intention')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.OrganizationalCommitmentScore, profile?.OrganizationalCommitmentRatingTypeName, 'Organization Commitment')}
                                    </td>
                                    <td style={styles.tableText}>
                                        {getGoogleSmileyRating(profile?.CyberSecurityScore, profile?.CyberSecurityRatingTypeName, 'Cybersecurity')}
                                    </td>
                                    <td style={styles.tableText}>      
                                        {displayUserRadarChart(profile)}
                                    </td>
                                </tr>
                            </tbody>
                    </Table>        
                </Card.Header>
                <Card.Body>
                    {((knowledgeTopicID) && (knowledgeTopicID !== -1)) ? 
                        <KnowledgeSearch listby={KnowledgeType.ClassroomAndOnline} topicid={knowledgeTopicID}/>
                    :
                        <></>
                    }
                </Card.Body>
            </Card>
        );
    }

  const renderRetentionPie = (props) => {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
      } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? "start" : "end";
      const fill2 = companyData?.AccentColorSecondary;
  
      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.name}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill2}
            // fill={companyData?.accentColorSecondary}
          //   fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} 
              fill={fill} 
              stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >{`${value.toLocaleString()}`}({payload.KnowledgeTypeName})</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };
      
    function getUserKnowledgeSummary() {
        //7(MyKnowledgeSession)~2(MyAssignments)~15(MyCertification)~1(PastDue)~294(TotalCompletions)~149.6(CreditHours)~45.5(CreditUnits)~16(CompletionAssignments)~0(PastDueAssignments)~0(CreditOthers)~0~0~0
        //7~2~15~1~294~149.6~45.5~16~0~0~0~0~0
        const dataArray = profile?.UserLearningSummary.split('~');
        var userKnowledgeOverall = [];
        var userKnowledgeType = [];
        var userKnowledgeCredit = [];

        if (dataArray.length > 4) {
            userKnowledgeOverall = [
                {
                    "KnowledgeTypeName": "Assigned",
                    "Status": 2,
                    "KnowledgeCount": parseInt(dataArray[0], 10)
                },
                {
                    "KnowledgeTypeName": "Completed",
                    "Status": 4,
                    "KnowledgeCount": parseInt(dataArray[4], 10)
                }
            ];            
        }
        if (dataArray.length > 4) {
            userKnowledgeType = [
                {
                    "KnowledgeTypeName": "Knowledge",
                    "KnowledgeTypeID": 1,
                    "Count": parseInt(dataArray[0], 10)
                },
                {
                    "KnowledgeTypeName": "Assignment",
                    "KnowledgeTypeID": 2,
                    "Count": parseInt(dataArray[1], 10)
                },
                {
                    "KnowledgeTypeName": "Certification",
                    "KnowledgeTypeID": 3,
                    "Count": parseInt(dataArray[2], 10)
                },
            ];            
        }
        if (dataArray.length > 11) {
            userKnowledgeCredit = [
                {
                    "KnowledgeTypeName": "Credit Units",
                    "KnowledgeTypeID": 1,
                    "Count": parseInt(dataArray[6], 10)
                },
                {
                    "KnowledgeTypeName": "Credit Hours",
                    "KnowledgeTypeID": 2,
                    "Count": parseInt(dataArray[5], 10)
                },
                {
                    "KnowledgeTypeName": "Credit Others",
                    "KnowledgeTypeID": 3,
                    "Count": parseInt(dataArray[9], 10)
                },
            ];            
        }

        return (
            <Card style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <Card.Header>
                    <Table style={styles.tableBGNoPadding} hover variant="light" >
                        <thead>
                            <tr>
                                <td style={styles.tableCellInnerGraph}>
                                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Knowledge Progress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <ResponsiveContainer width={'100%'} height={220}>
                                                        <PieChart>
                                                            <Pie
                                                                activeIndex={activeIndex}
                                                                activeShape={renderRetentionPie}
                                                                data={userKnowledgeOverall}
                                                                cx={226}
                                                                cy={106}
                                                                innerRadius={42}
                                                                outerRadius={62}
                                                                fill={companyData?.AccentColorLighter}
                                                                nameKey="KnowledgeTypeName"
                                                                dataKey="KnowledgeCount"
                                                                onMouseEnter={onPieEnter}
                                                            />
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                                                    
                                </td>                            
                                <td style={styles.tableCellInnerGraph}>
                                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Competency Growth Tracker</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <ResponsiveContainer width={'100%'} height={220}>
                                                    <BarChart data={userKnowledgeType}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="KnowledgeTypeName"/>
                                                        <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                        <Tooltip content={<CustomTooltip displayName='KnowledgeTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                        <Bar dataKey="Count" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"Count"} lowerbar={true}/>}/>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                                                    
                                </td>                            
                                <td style={styles.tableCellInnerGraph}>
                                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Metrics</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <ResponsiveContainer width={'100%'} height={220}>
                                                    <BarChart data={userKnowledgeCredit}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="KnowledgeTypeName"/>
                                                        <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 25']}/>
                                                        <Tooltip content={<CustomTooltip displayName='KnowledgeTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                        <Bar dataKey="Count" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"Count"} lowerbar={true}/>}/>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                                                    
                                </td>                            
                            </tr>    
                        </thead>
                    </Table>        
                </Card.Header>
            </Card>
        );
    }
      
    function getUserKnowledgeSectionsToogle(tab) {
        return (
            <Table variant="light" style={styles.tableBGNoPadding}>
                <tbody>
                    {/* First Row */}
                    <tr>
                    {(tab === 1) && [1].map((id) =>
                        expandedCell === null || expandedCell === id ? (
                        <td
                            key={id}
                            style={{
                            display: expandedCell && expandedCell !== id ? "none" : "",
                            width: expandedCell === id ? "100%" : "auto",
                            height: expandedCell === id ? "80vh" : "auto",
                            backgroundColor: companyData?.PagesBGColor
                            }}
                            colSpan={expandedCell === id ? 1 : 2}
                        >
                            <Table hover variant="light" style={styles.tableBGNoPadding}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign:'center', width:'70%', color: companyData?.Darkgrey, backgroundColor: companyData?.PagesBGColor }}>
                                        <div style={{ display:'flex', justifyContent:'flex-start', alignItems:'center', gap:'5px', padding:'0' }}>
                                            <h4 style={styles.SubHeader}>Insights&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                                            <h6 style={styles.LabelDataEntryNoPaddingBold}>{profile?.JobClassDescription} - {profile?.JobPositionDescription}</h6>
                                        </div>
                                    </th>
                                    <th style={{ textAlign:'right', color: companyData?.Darkgrey, backgroundColor: companyData?.PagesBGColor }}>
                                        <div style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px', padding:'0' }}>
                                            <a style={{ display: 'flex', alignItems: 'center', gap: '1px', padding:'0' }} onClick={() => {showCompetencyCheckListDialog(profile?.UserID)}}>
                                                <label style={styles.LabelDataEntryNoPaddingBold}>Competency List&nbsp;&nbsp;</label>
                                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='Competency List'>fact_check</span>
                                            </a>
                                            <a style={{ display: 'flex', alignItems: 'center', gap: '1px', padding:'0' }} onClick={() => {showProfileGapAnalysisDialog(profile?.UserID, profile?.PositionID)}}>
                                                <label style={styles.LabelDataEntryNoPaddingBold}>Analysis&nbsp;&nbsp;</label>
                                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='Competency & Gap Analysis'>workspace_premium</span>
                                            </a>
                                            <a style={{ display: 'flex', alignItems: 'center', gap: '1px', padding:'0' }} onClick={() => {showProfileGapAnalysisDialog(profile?.UserID, profile?.PositionID)}}>
                                                <label style={styles.LabelDataEntryNoPaddingBold}>Transcript&nbsp;</label>
                                                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined" title='My Transcript'>verified</span>
                                            </a>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {((knowledgeTopicID) && (knowledgeTopicID === -1)) && 
                                <tr>
                                    <td colSpan="3" style={{ backgroundColor: companyData?.PagesBGColor }}>
                                        {getUserKnowledgeSummary()}
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td colSpan="3" style={{ backgroundColor: companyData?.PagesBGColor }}>
                                        {getEmpowermentStats()}
                                    </td>
                                </tr>
                            </tbody>
                            </Table>
                        </td>
                        ) : null
                    )}
                    </tr>

                    {/* Second Row */}
                    <tr>
                    {(tab === 2) && [2, 3, 4, 5, 6].map((id) =>
                        ((radioKnowledgeValue === id) && (expandedCell === null || expandedCell === id)) ? (
                        <td
                            key={id}
                            style={{
                            display: expandedCell && expandedCell !== id ? "none" : "",
                            width: expandedCell === id ? "100%" : (id === 3) ? "32%" : "auto",
                            height: expandedCell === id ? "80vh" : (id === 3) ? "60vh" : "auto",
                            backgroundColor: companyData?.PagesBGColor
                            }}
                            colSpan={id === 2 ? 1 : 1}
                        >
                            <Table hover variant="light" style={styles.tableBGNoPadding100}>
                            <tbody>
                                <tr>
                                    <td colSpan="2" style={{ backgroundColor: companyData?.PagesBGColor }}>
                                        {id === 2 && radioKnowledgeValue === 2 && <KnowledgeSessionList listType={KnowledgeListType.User} ParentParams={paramsForChildren}/>}
                                        {id === 3 && radioKnowledgeValue === 3 && <div style={{width:'100%', height:'100%'}}><MyCalendar/></div>}
                                        {id === 4 && radioKnowledgeValue === 4 && <KnowledgeSearch/>}
                                        {id === 5 && radioKnowledgeValue === 5 && <KnowledgeSessionList listType={KnowledgeListType.Manager} />}
                                        {id === 6 && radioKnowledgeValue === 6 && <div style={{width:'100%', height:'100%'}}><TryOut/></div>}
                                    </td>
                                </tr>
                            </tbody>
                            </Table>
                        </td>
                        ) : null
                    )}
                    </tr>
                </tbody>
            </Table>
        );
    }

    function getMainSection() {
        if (radioValue === 1) {
            return (
                getUserKnowledgeSectionsToogle(1)
            );    
        } else if (radioValue === 2) {
            return (
                <>
                <div style={{display:'flex', flexWrap:'wrap'}}>
                    {displayKnowledgeTabButtons()}
                </div>
                {getUserKnowledgeSectionsToogle(2)}
                </>
            );    
        } else if (radioValue === 3) {
            return (
                <UserEvaluationList processleveltypeidprops={RetentasType.Surveys}/>
            );    
        } else if (radioValue === 4) {
            return (
                <UserEvaluationList processleveltypeidprops={RetentasType.PRFs}/>
            );    
        } 
    }

    return (    
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showProfileGapAnalysis} onShow={handleShowProfileGapAnalysis} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseProfileGapAnalysis}>
            <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserSuccession isSelfAnalysis={true} viewUserID={profileDialogUserID}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseProfileGapAnalysis}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showCompetencyCheckList} onShow={handleShowCompetencyCheckList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseCompetencyCheckList}>
            <Modal.Header closeButton>
            <Modal.Title>{profile?.FirstName} {profile?.LastName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CompetencyChecklist listby={CheckListGroupBy.User} lookupUserID={profileDialogUserID} fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseCompetencyCheckList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        {(loading) ? (            
            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                    <Spinner/>
                </div>
            </Card.Body>
        ) 
        : 
        (                                          
            <Card style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                {/* <Card.Header> 
                    <Table style={styles.tableBGNoPadding}>
                            <thead>
                            <tr>
                                <th style={{width:'50%', alignItems:'left', backgroundColor:companyData?.PagesBGColor}}> <h5 style={styles.SubHeaderBold}>Empowerment</h5>
                                </th>
                                <th style={{width:'35%', alignItems:'center', backgroundColor:companyData?.PagesBGColor}}>
                                </th>
                                <th style={{width:'15%', alignItems:'right', backgroundColor:companyData?.PagesBGColor}}>
                                    <Card.Title>
                                        <ButtonGroup className="mb-2" size='sm'>
                                            {radios.map((radio, idx) => (
                                                <ToggleButton
                                                    key={typeSummary + idx}
                                                    id={`radio-${typeSummary + idx}`}
                                                    type="radio"
                                                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                                                    name="radio"
                                                    value={radio.value}
                                                    checked={radioValue === radio.value}
                                                    size='sm'
                                                    onChange={(e) => {setRadioValue(e.currentTarget.value)}}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>                   
                                    </Card.Title>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                    </Table>
                </Card.Header> */}
                <Card.Body>
                    {getMainSection()}
                </Card.Body>
            </Card>
        )}
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth?.user,
        companyData: state?.auth?.companyData,
        profile: state.auth?.profile,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledgeSessionsByUser: (kParams) => dispatch(getKnowledgeSessionsByUser(kParams)),
        getJobPositionSuccession: (id) => dispatch(getJobPositionSuccession(id)),
        getUserCalendar: (kParams) => dispatch(getUserCalendar(kParams)),
        getSkillChecklist: (oParams) => dispatch(getSkillChecklist(oParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(MyKnowledge)
