import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup, FormControl, CardFooter, DropdownButton, Dropdown  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getKnowledges, getKnowledgeInstances, getKnowledgeSessions, getKnowledgeSessionsByUser, getUserCalendar, 
        getUserAttributesLookups, updateKnowledgeSession, processEnrollment, processEnrollmentClear,
        getEvaluation, getEvaluationQuestions, getEvaluationRatings, getKnowledgeSessionEvaluation, getTestLibraries, getKnowledgeSessionAssessment } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import { UserLookup, Rights, KnowledgeType, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant, 
        GradingType, LOLifeCycleStatus, AlertEnrollmentTimeOut, KnowledgeListType, KnowledgeSearchType,
        RetentasType} from '../../config/Constant';
import { getUserLookup, loadingMenuSelection } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import Attachment from '../layout/Attachment';
import AlertMessage from '../layout/AlertMessage';
import KnowledgeSessionDetail from './KnowledgeSessionDetail';
import { companySignLogo } from '../../config/Colors';
import LaunchEvaluation from './LaunchEvaluation';
import KnowledgeSessionObjectives from './KnowledgeSessionObjectives';

const KnowledgeSessionList = (props) => {
    const { auth, companyData, profile, knowledgesessions, myknowledgesessions, myteamknowledgesessions, evaluations, userattributelookups, orgDivisions, lookupselections, loadingMenu, listby, fromLookupParam,
        enrollmentMessage, listType, paramsForChildren } = props;    
    const { id } = useParams();
    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    let knowledgeListType = location?.state?.listType;
    if ((knowledgeListType === null) || (knowledgeListType === undefined)) {
        knowledgeListType = listType;
    }

    const [hoveredRow, setHoveredRow] = useState(null);
    const [isTableHovered, setIsTableHovered] = useState(false);

    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [parentParams, setParentParams] = useState(location?.state?.parentParams);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [actionVisible, setActionVisible] = useState(false);
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [editKnowledgeSession, setEditKnowledgeSession] = useState(false);
    const [knowledgeSessionName, setKnowledgeSessionName] = useState('');
    const [knowledgeID, setKnowledgeID] = useState(id);
    const [knowledgeInstanceID, setKnowledgeInstanceID] = useState(id);
    const [knowledgeSessionID, setKnowledgeSessionID] = useState(-1);
    const [sessionStatusChange, setSessionStatusChange] = useState(-1);
    const [sessionPassOrFailed, setSessionPassOrFailed] = useState(-1);
    const [sessionCancellationReason, setSessionCancellationReson] = useState(-1);
    const [sessionPassingScore, setSessionPassingScore] = useState(null);
    const [sessionCreditUnits, setSessionCreditUnits] = useState(null);
    const [sessionCreditHours, setSessionCreditHours] = useState(null);
    const [sessionCreditOthers, setSessionCreditOthers] = useState(null);
    const [sessionNewAttendees, setSessionNewAttendees] = useState('');
    const [sessionAttendees, setSessionAttendees] = useState('');
    const [sessionAuthorizers, setSessionAuthorizers] = useState('');
    const [sessionTransactionID, setSessionTransactionID] = useState(null);
    const [sessionAssignmentSessionID, setSessionAssignmentSessionID] = useState(null);
    const [sessionDueDate, setSessionDueDate] = useState(null);
    const [sessionProcessStatusChangeTypeID, setSessionProcessStatusChangeTypeID] = useState(null);
    const [sessionKnowledgeTypeID, setSessionKnowledgeTypeID] = useState(null);
    const [sessionComments, setSessionComments] = useState(null);
    const [sessionAssignedCertificationID, setSessionAssignedCertificationID] = useState(null);

    const [showUserList, setShowUserList] = useState(false);
    const [showSessionDetail, setShowSessionDetail] = useState(false);
    const [showLaunchEvaluation, setShowLaunchEvaluation] = useState(false);
    const [showSessionObjectives, setShowSessionObjectives] = useState(false);
    const [showKnowledgeLaunchPad, setShowKnowledgeLaunchPad] = useState(false);

    const [isAttachmentSectionDisabled, setIsAttachmentSectionDisabled] = useState(false);
    const [isAttachmentSectionVisible, setIsAttachmentSectionVisible] = useState(false);

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }


    const stateknowledgesessions = (knowledgeListType === KnowledgeListType.User) ? myknowledgesessions?.myknowledgesessions : (knowledgeListType === KnowledgeListType.Manager) ? myteamknowledgesessions?.mycalendar : knowledgesessions?.knowledgesessions;
    const knowledgesession = stateknowledgesessions?.LearningObjectSession;
    const oFirstLOS = (((knowledgesession) && (knowledgesession.length > 0)) ? knowledgesession[0] : null);
    const losLifeCycle = stateknowledgesessions?.LearningObjectSessionLifeCycle;
    const losAuthorizer = stateknowledgesessions?.LearningObjectSessionAuthorizer;

    const processStatusChangeTypeData = userattributelookups?.ProcessStatusChangeTypes;

    const navigate = useNavigate();

    const radioKnowledege = [
        { name: 'Attributes', value: 1 },
        { name: 'Attachment', value: 2 },
    ];

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }

    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Pending', value: '1' },
        { name: 'Enrolled', value: '2' },
        { name: 'Class Session Complete', value: '3' },
        { name: 'Waitlisted', value: '16' },
        { name: 'Rerequisites Not Completed', value: '18' },
        { name: 'Completed', value: '4' },
        { name: 'Cancelled By User', value: '5' },
        { name: 'Cancelled By Authorizer', value: '10' },
        { name: 'Cancelled By Administrator', value: '11' },
        { name: 'Denied By Authorizer', value: '12' },
        { name: 'Denied By Administrator', value: '13' },
        { name: 'Completed(Failed)', value: '14' },
        { name: 'Employment Terminated', value: '15' },
        { name: 'Moved To Another Instance', value: '19' },
        { name: 'ECard Issued', value: '20' },
        { name: 'All', value: '0' },
    ];

    function initialRadioValue() {
        return '2';
    };

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = stateknowledgesessions?.PagingMetaData?.PageNumber - 1;
        if (knowledgeListType === KnowledgeListType.User) {
            const oParams = {
                UserID:auth?.UserID,
                LearningObjectProcessStatusTypeID:radioValue,
                RecordSize:'10',
                PageNumber:previousPageNumber,
            };     
            await props.getKnowledgeSessionsByUser(oParams);
        } else {
            const oParams = {
                LearningObjectInstanceID:knowledgeInstanceID,
                RecordSize:'10',
                PageNumber:previousPageNumber,
            };     
            await props.getKnowledgeSessions(oParams);
        }           
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = stateknowledgesessions?.PagingMetaData?.PageNumber + 1;
        if (knowledgeListType === KnowledgeListType.User) {
            const oParams = {
                UserID:auth?.UserID,
                LearningObjectProcessStatusTypeID:radioValue,
                RecordSize:'10',
                PageNumber:nextPageNumber,
            };     
            await props.getKnowledgeSessionsByUser(oParams);
        } else {
            const oParams = {
                LearningObjectInstanceID:knowledgeInstanceID,
                RecordSize:'10',
                PageNumber:nextPageNumber,
            };            
            await props.getKnowledgeSessions(oParams);    
        }        
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
     
    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += ',';
                }
                currentSelectedValue += row.LearningObjectSessionID;
            } 
        });
        setSelectedValue(currentSelectedValue);
        return currentSelectedValue;
    }
    
    function initialTabValue() {
        return 1;
    };

    function getSearchParamsEx(bRadioValue, pageNumber)  {
        let oParams = {
            LearningObjectProcessStatusTypeID:bRadioValue,
            LearningObjectInstanceID:knowledgeInstanceID,
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 
        if (knowledgeListType === KnowledgeListType.User) {
            oParams = {
                UserID:auth?.UserID,
                LearningObjectProcessStatusTypeID:bRadioValue,
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        }
        if (knowledgeListType === KnowledgeListType.Manager) {
            oParams = {
                ManagerUserID:auth?.UserID,
                CompanyID:auth?.CompanyID,
                SearchTypeID:KnowledgeSearchType.Manager,
                GroupID:'1',
                LearningObjectProcessStatusTypeID:bRadioValue,
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        }
        return oParams;
    }
    
    async function searchKnowledgeTrackSessions(bRadioValue) {
        setLoading(true);
        const oParams = getSearchParamsEx(bRadioValue, '1');
        if (knowledgeListType === KnowledgeListType.User) {
            await props.getKnowledgeSessionsByUser(oParams);
        } else if (knowledgeListType === KnowledgeListType.Manager) {
            await props.getUserCalendar(oParams);
        } else {
            await props.getKnowledgeSessions(oParams);
        }
        setLoading(false);
    }
    
    async function handleRadioChange(e) {
        setRadioValue(e.currentTarget.value);
        searchKnowledgeTrackSessions(e.currentTarget.value);
    }
        
    async function handleCancel() {
        setLoading(true);
        await props.getKnowledgeSessions(parentParams);
        setLoading(false);
        navigate('/knowledgeinstancelist/' + id, {state: {permissiontypeid:permissionTypeID, listby:listByValue}});
    }

    async function handleLaunchEvaluation(evalid, sessionid) {
        setLoading(true);
        setKnowledgeSessionID(sessionid);
        await props.getEvaluation(evalid);
        await props.getEvaluationQuestions(evalid);
        await props.getEvaluationRatings(evalid);
        const oParams  = {
            EvaluationID:evalid,
            LearningObjectSessionID:sessionid,
        };          
        console.log(oParams);      
        await props.getKnowledgeSessionEvaluation(oParams);
        setShowLaunchEvaluation(true);
        setLoading(false);
    }

    async function handleKnowledgeLaunchPad(sessionRec) {
        setLoading(true);
        props.loadingMenuSelection(true);

        setKnowledgeSessionID(sessionRec?.LearningObjectSessionID);

        // get evaluation for this session
        await props.getEvaluation(sessionRec?.EvaluationID);
        await props.getEvaluationQuestions(sessionRec?.EvaluationID);
        await props.getEvaluationRatings(sessionRec?.EvaluationID);
        const oEParams  = {
            EvaluationID:sessionRec?.EvaluationID,
            LearningObjectSessionID:sessionRec?.LearningObjectSessionID,
        };          
        await props.getKnowledgeSessionEvaluation(oEParams);
        // get assessments for this session
        let sAIDs = '';
        if ((sessionRec?.TestInstanceIDPre) && (sessionRec?.TestInstanceIDPre > 0)) {
            sAIDs = sessionRec?.TestInstanceIDPre;
        } 
        if ((sessionRec?.TestInstanceID) && (sessionRec?.TestInstanceID > 0)) {
            if (sAIDs !== '') {
                sAIDs += ',';
            }
            sAIDs += sessionRec?.TestInstanceID;
        } 
        if (sAIDs !== '') {
            const oAParams = {
                TestInstanceIDs:sAIDs,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getTestLibraries(oAParams);    
            const oATTParams = {
                TestInstanceIDs:sAIDs,
                LearningObjectSessionID: sessionRec?.LearningObjectSessionID,
                RecordSize:'10',
                PageNumber:1,
            }; 
            console.log(oATTParams);
            await props.getKnowledgeSessionAssessment(oATTParams);    
        }

        // get scorm content for this session

        // pass current list params
        const oCurrentSessionParam = {
            UserID:auth?.UserID,
            LearningObjectSessionID:sessionRec?.LearningObjectSessionID,
            RecordSize:'10',
            PageNumber:stateknowledgesessions?.PagingMetaData?.PageNumber,
        };     

        setShowKnowledgeLaunchPad(true);
        setLoading(false);
        navigate('/launchknowledge/' + sessionRec?.LearningObjectSessionID, {state: {listType:knowledgeListType, parentSessionParams:oCurrentSessionParam}});    
        props.loadingMenuSelection(false);
    }

    async function handleShowSessionDetail() {
        setShowSessionDetail(true); 
    };

    async function handleCloseSessionDetail() {
        setLoading(true);
        await props.processEnrollmentClear();
        searchKnowledgeTrackSessions(radioValue);
        setKnowledgeSessionID(-1);
        setShowSessionDetail(false);
        setLoading(false);
    }

    async function handleShowSessionObjectives() {
        setShowSessionObjectives(true); 
    };

    async function handleCloseSessionObjectives() {
        setLoading(true);
        await props.processEnrollmentClear();
        searchKnowledgeTrackSessions(radioValue);
        setKnowledgeSessionID(-1);
        setShowSessionObjectives(false);
        setLoading(false);
    }

    async function handleLaunchObjectives(sessionid) {
        setLoading(true);
        setKnowledgeSessionID(sessionid);
        setShowSessionObjectives(true);
        setLoading(false);
    }

    async function handleShowLaunchEvaluation() {
        setShowLaunchEvaluation(true); 
    };

    async function handleCloseLaunchEvaluation() {
        setLoading(true);
        searchKnowledgeTrackSessions(radioValue);
        setShowLaunchEvaluation(false);
        setLoading(false);
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        // Only for Attachment Tab
        if (tab === 4) {
            console.log(editKnowledgeSession);
            if (editKnowledgeSession) {
                setIsAttachmentSectionDisabled(false);
                setIsAttachmentSectionVisible(true);
            }
        }

        displayTabContent();
    };

    async function handleSessionActionClicked() {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (actionVisible) {
            setActionVisible(false);
        } else {
            setActionVisible(true);
        }
    };

    async function handleUserSessionActionsClicked(actionType, id, id1, id2, id3) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (actionType === 0) {
            const assEditedRec = knowledgesession?.filter((assRec) => (assRec.LearningObjectSessionID === id));
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setKnowledgeSessionName(assEditedRec[0]?.UserFullName + ' - ' + assEditedRec[0]?.LearningObjectName);
            }
    
            setKnowledgeSessionID(id);
            setKnowledgeInstanceID(id1);
            setKnowledgeID(id2);
            const oParams = {
                LearningObjectID:id2,
                LearningObjectInstanceID:id1,
                RecordSize:'10',
                PageNumber:1,
            }; 
            console.log(oParams);
            await props.getKnowledgeInstances(oParams);    
            setShowSessionDetail(true);
        } else if (actionType === 2) {
            const assEditedRec = knowledgesession?.filter((assRec) => (assRec.LearningObjectSessionID === id));
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setKnowledgeSessionName('Evaluation - ' + assEditedRec[0]?.LearningObjectName);
                setKnowledgeSessionID(id);
                setKnowledgeInstanceID(id1);
                setKnowledgeID(id2);
                handleLaunchEvaluation(id3, id);
            }
        } else if (actionType === 9) {
            const assEditedRec = knowledgesession?.filter((assRec) => (assRec.LearningObjectSessionID === id));
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setKnowledgeSessionName(assEditedRec[0]?.LearningObjectName);
                setKnowledgeSessionID(id);
                setKnowledgeInstanceID(id1);
                setKnowledgeID(id2);
                handleLaunchObjectives(id);
            }
        } else if (actionType === 3) { // Main KnowledgePadControl
            const assEditedRec = knowledgesession?.filter((assRec) => (assRec.LearningObjectSessionID === id));
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setKnowledgeSessionName(assEditedRec[0]?.LearningObjectName);
                setKnowledgeSessionID(id);
                setKnowledgeInstanceID(id1);
                setKnowledgeID(id2);
                handleKnowledgeLaunchPad(assEditedRec[0]);
            }
        } else {
            if (actionVisible) {
                setActionVisible(false);
            } else {
                setActionVisible(true);
            }    
        }

        setLoading(false);
    };

    async function handleAddSessionActionClicked() {
        setLoading(true);
        setShowUserList(true);
        setLoading(false);
    }

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function searchKnowledgeSessions() {
        setLoading(true);
        if (knowledgeListType === KnowledgeListType.User) {
            const oParams = {
                UserID:auth?.UserID,
                LearningObjectProcessStatusTypeID:radioValue,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeSessionsByUser(oParams);        
        } else {
            const oParams = {
                LearningObjectInstanceID:knowledgeInstanceID,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeSessions(oParams);            
        }
        setLoading(false);
    }
    
    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            LearningObjectInstanceID:kRec.LearningObjectInstanceID,
            LearningObjectSessionID:kRec.LearningObjectSessionID,
            takeAction:false,
            showDetails:false,
            editKnowledgeSessionClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledgesession);
        setSliderValue(initialScoreValues);
    }, [knowledgesession])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    useEffect(() => {
        if ((enrollmentMessage) && (enrollmentMessage?.returnParams) && (enrollmentMessage?.returnParams?.length > 0) && 
            (!enrollmentMessage?.returnParams[0]?.ProcessSuccess) && 
            (enrollmentMessage?.returnParams[0]?.ProcessErrorDescription) && (enrollmentMessage?.returnParams[0]?.ProcessErrorDescription.trim() !== '')) {
            setAlertMessage(enrollmentMessage?.returnParams[0]?.ProcessErrorDescription.trim().replaceAll("###LINEBREAK###", "<br>"));
            setAlertVariant(AlertVariant.Danger);
            setShowAlertMessage(true);
        } else if ((enrollmentMessage) && (enrollmentMessage?.returnParams) && (enrollmentMessage?.returnParams?.length > 0) && 
            (enrollmentMessage?.returnParams[0]?.ProcessSuccess) && 
            (enrollmentMessage?.returnParams[0]?.ProcessDetailDescription) && (enrollmentMessage?.returnParams[0]?.ProcessDetailDescription.trim() !== '')) {
            setAlertMessage(enrollmentMessage?.returnParams[0]?.ProcessDetailDescription.trim().replaceAll("###LINEBREAK###", "<br>"));
            setAlertVariant(AlertVariant.Success);
            setShowAlertMessage(true);
        }
    }, [enrollmentMessage])  

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;
        sliderValue && sliderValue?.map((row, index) => {
            if ((row) && (parseInt(row?.LearningObjectSessionID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
  
    const handleCloseUserList = () => {
        setShowUserList(false);
        const sSelectedIDs = updateUserSelection();
        handleSaveChildernClick(2, null, sSelectedIDs);
    }

    async function handleShowUserList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.User,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getUserLookup(oParams);

        setShowUserList(true); 
        setLoading(false);
    };

    function updateUserSelection() {
        var sSelections = lookupselections?.User;
        // if (sessionNewAttendees !== '') {
        //     sSelections = sessionNewAttendees + ',' + sSelections;
        // }
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                }
            });
            setSessionNewAttendees(sSelectedIds);
            return sSelectedIds;
        } else {
            setSessionNewAttendees('');
            return '';
        }
    }

    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.LearningObjectSessionID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                    setKnowledgeSessionID(-1);
                } else {
                    row.showDetails = true;
                    setKnowledgeSessionID(rowIndex);
                }
            } else {
                row.showDetails = false;
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();        
    }

    async function handleSaveChildernClick(tab, currentEval, sSelectedIDs) {
        var bCanSave = true;
        if (tab === 3) {
            if (!((selectedValue) && (selectedValue.trim !== ''))) {
                setAlertMessage('Select attendees before proceeding with roster action.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
                bCanSave = false;
            }
        } else if (tab === 2) {
            console.log(sSelectedIDs);
            if (!((sSelectedIDs) && (sSelectedIDs.trim !== ''))) {
                setAlertMessage('Select attendees before proceeding with enrollment.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
                bCanSave = false;
            } 
        }

        if (bCanSave) {
            setLoading(true);
            if (tab === 1) {
                const oParams  = {
                    LearningObjectID: knowledgeID,
                    LearningObjectInstanceID: knowledgeInstanceID,
                    LearningObjectSessionID: knowledgeSessionID,
                    CreatedByUserID: auth?.UserID,
                    UpdatedByUserID: auth?.UserID,
                    CompanyID: auth?.CompanyID,
                };        
                console.log(oParams);
                if (knowledgeSessionID !== -1) {
                    await props.updateKnowledgeSession(oParams);    
                    setEditKnowledgeSession(false);
                }
            } else if (tab === 2) {
                const oParams  = [{
                    LearningObjectID: knowledgeID,
                    LearningObjectInstanceID: knowledgeInstanceID,
                    LearningObjectSessionID: knowledgeSessionID,
                    LearningObjectTransactionID:-1,
                    AssignmentSessionID: -1,
                    Attendees: sSelectedIDs,
                    Authorizers: '',
                    Status:LOLifeCycleStatus.Enrolled,
                    IsStartEnrollment: true,
                    IsProcessEnrollment: false,
                    CreatedByUserID: auth?.UserID,
                    UpdatedByUserID: auth?.UserID,
                    UserID: auth?.UserID,
                    CompanyID: auth?.CompanyID,
                }];        
                console.log('Start Enrollment', oParams);
                await props.processEnrollment(oParams);    
                setActionVisible(false);
            }  else if (tab === 3) {
                const oParams  = [{
                    LearningObjectID: knowledgeID,
                    LearningObjectInstanceID: knowledgeInstanceID,
                    LearningObjectSessionID: knowledgeSessionID,
                    Attendees: selectedValue,
                    Authorizers: '',
                    LearningObjectTransactionID:oFirstLOS?.LearningObjectTransactionID,
                    AssignmentSessionID: oFirstLOS?.AssignmentSessionID,
                    Status: sessionStatusChange, 
                    UpdatedUserID: auth?.UserID,
                    DueDate: oFirstLOS?.DueDate,
                    ProcessStatusChangeTypeID: sessionCancellationReason,
                    Score: sessionPassingScore,
                    Complete: (sessionStatusChange === LOLifeCycleStatus.Complete) ? true : false,
                    WBT: ((oFirstLOS?.LearningObjectTypeID === KnowledgeType.Online) ? true : false),
                    CreditUnits: sessionCreditUnits,
                    CreditHours: sessionCreditHours,
                    CreditOthers: sessionCreditOthers,
                    IsStartEnrollment: false,
                    IsProcessEnrollment: true,
                    Comment: sessionComments,
                    AssignedCertificationID: oFirstLOS?.AssignedCertificationID,
                    DateCompleted: new Date(),
                    CreatedByUserID: auth?.UserID,
                    UpdatedByUserID: auth?.UserID,
                    UserID: auth?.UserID,
                    CompanyID: auth?.CompanyID,
                }];        
                console.log('ProcessEnrollment', oParams);
                await props.processEnrollment(oParams);    
                setActionVisible(false);
            } 
    
            const oParams = {
                LearningObjectInstanceID:knowledgeInstanceID,
                LearningObjectProcessStatusTypeID: radioValue,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeSessions(oParams);
    
            setLoading(false);    
        }
    }

    function UpdateChildrenState(id) {
        // let dateIDs = '';
        // const instanceDateRec = loiDates?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // instanceDateRec?.map((row, index) => {
        //     if (dateIDs.trim() !== '') {
        //         dateIDs += '&';
        //     }
        //     dateIDs += row?.StartDateTime + '|' + row?.EndDateTime + '|' + row?.TimeZone;
        // });
        // if (dateIDs !== '') {
        //     setInstanceDateDetails(dateIDs);
        //     setInstanceDateArray(dateIDs.split('&'));    
        // } else {
        //     setInstanceDateDetails('');
        //     setInstanceDateArray([]);    
        // }

        // let instrIDs = '';
        // let instrNames = '';
        // let instrDetails = '';
        // let presIDs = '';
        // let presNames = '';
        // let presDetails = '';
        // const instrRec = loiInstructors?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // instrRec?.map((row, index) => {
        //     if (row?.OnlyPresenter) {
        //         if (presIDs.trim() !== '') {
        //             presIDs += ',';
        //         }
        //         if (presDetails.trim() !== '') {
        //             presDetails += '&';
        //         }
        //         presIDs += row?.InstructorUserID;
        //         presDetails += row?.InstructorUserID + '=' + row?.InstructorName + '|' + row?.InstructorVendorUserID + '|' + row?.InstructorEmail;    

        //     } else {
        //         if (instrIDs.trim() !== '') {
        //             instrIDs += ',';
        //         }
        //         if (instrDetails.trim() !== '') {
        //             instrDetails += '&';
        //         }
        //         instrIDs += row?.InstructorUserID;
        //         instrDetails += row?.InstructorUserID + '=' + row?.InstructorName + '|' + row?.InstructorVendorUserID + '|' + row?.InstructorEmail;    
        //     }
        // });
        // if (instrIDs.trim() !== '') {
        //     setInstructors(instrIDs);
        // } else {
        //     setInstructors('');
        // }
        // if (instrDetails.trim() !== '') {
        //     const oSelections = instrDetails?.split('&');
        //     setInstructorArray(oSelections);
        // } else {
        //     setInstructorArray([]);
        // }

        // if (presIDs.trim() !== '') {
        //     setPresenters(presIDs);
        // } else {
        //     setPresenters('');
        // }
        // if (presDetails.trim() !== '') {
        //     const oSelections = presDetails?.split('&');
        //     setPresenterArray(oSelections);
        // } else {
        //     setPresenterArray([]);
        // }

        // let jpIDs = '';
        // let jpNames = '';
        // let jpDetails = '';
        // const jpRec = loiJobPositions?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // jpRec?.map((row, index) => {
        //     if (jpIDs.trim() !== '') {
        //         jpIDs += ',';
        //     }
        //     if (jpDetails.trim() !== '') {
        //         jpDetails += '&';
        //     }
        //     jpIDs += row?.JobPositionID;
        //     jpDetails += row?.JobPositionID + '=' + row?.JobPositionDescription?.replaceAll("&", "and");    
        // });
        // if (jpIDs.trim() !== '') {
        //     setJobPositions(jpIDs);
        // } else {
        //     setJobPositions([]);
        // }
        // if (jpDetails.trim() !== '') {
        //     const oSelections = jpDetails?.split('&');
        //     setJobPositionArray(oSelections);
        // } else {
        //     setJobPositionArray([]);
        // }

        // let authDetails = '';
        // const authRec = loiAuthorizerGroups?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // authRec?.map((row, index) => {
        //     if (authDetails.trim() !== '') {
        //         authDetails += '&';
        //     }
        //     authDetails += row?.GroupID + '|' + row?.GroupName?.replaceAll("&", "and") + '|' + row?.LevelNumber + '|' + row?.NotificationMessageID + '|' + row?.NotificationMessageName?.replaceAll("&", "and");    
        // });
        // if (authDetails.trim() !== '') {
        //     const oSelections = authDetails?.split('&');
        //     setAuthorizationArray(oSelections);
        //     setAuthorizationDetails(authDetails);
        // } else {
        //     setAuthorizationArray([]);
        //     setAuthorizationDetails('');
        // }

        // let resDetails = '';
        // const resRec = loiResources?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // resRec?.map((row, index) => {
        //     if (resDetails.trim() !== '') {
        //         resDetails += '&';
        //     }
        //     resDetails += row?.ResourceTypeID + '|' + row?.ResourceTypeName?.replaceAll("&", "and") + '|' + row?.NumberOfUnits + '|' + row?.TotalPrice + '|' + row?.NumberOfUnitsAllocated + '|' + row?.DueDate + '|' + row?.CompletedDate;    
        // });
        // if (resDetails.trim() !== '') {
        //     const oSelections = resDetails?.split('&');
        //     setResourceArray(oSelections);
        //     setResourceDetails(resDetails);
        // } else {
        //     setResourceArray([]);
        //     setResourceDetails('');
        // }

        // let pmIDs = '';
        // const pmRec = loiPaymentModes?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // pmRec?.map((row, index) => {
        //     if (pmIDs.trim() !== '') {
        //         pmIDs += ',';
        //     }
        //     pmIDs += row?.PaymentModeID;
        // });
        // if (pmIDs != '') {
        //     setPaymentModes(pmIDs);
        // } else {            
        //     setPaymentModes('');
        // }

        // let divisionIds = '';
        // let divisionDetails = '';
        // const availabilityRec = availability?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        // availabilityRec?.map((row, index) => {
        //     if (divisionIds.trim() !== '') {
        //         divisionIds += ',';
        //     }
        //     if (divisionDetails.trim() !== '') {
        //         divisionDetails += '&';
        //     }
        //     divisionIds += row?.DivisionID;
        //     divisionDetails += row?.DivisionID + '=' + row?.DivisionNamePath;
        // });
        // if (divisionIds) {
        //     // setSelectedNodes(nodes);
        //     setKnowledgeDivisions(divisionIds);
        //     setKnowledgeDivisionDetails(divisionDetails);
        // } else {
        //     setKnowledgeDivisions('');
        //     setKnowledgeDivisionDetails('');
        // }
    }

    async function handleUpdateChildernClick(id, mainid) {
        setLoading(true);
        setTabValue(1);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const assEditedRec = knowledgesession?.filter((assRec) => (assRec.LearningObjectSessionID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setKnowledgeSessionID(assEditedRec[0]?.LearningObjectSessionID);
            setKnowledgeInstanceID(assEditedRec[0]?.LearningObjectInstanceID);
            UpdateChildrenState(id);
           
            setEditKnowledgeSession(true);
            setIsAttachmentSectionDisabled(true);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(tab) {
        if (tab === 1) {
            if (knowledgeSessionID !== -1) {
                setEditKnowledgeSession(false);
            }
            ClearStateFields();    
        } else if (tab === 3) {
            setActionVisible(false);
        }
    }
    
    async function ClearStateFields() {
        setKnowledgeSessionID(-1);
    }

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Knowledge Session'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Knowledge Session'>Cancel</Button>
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radioKnowledege.map((radio, idx) => (
                <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                    <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                </li> 
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        } else if (tabValue === 2 ) {
            return (
                <div>
                    {displayAttachment()}
                </div>
            );
        } 
    }

    function displayNewKnowledge() {
        if (editKnowledgeSession) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            Edit Knowledge Track Session
                        </Card.Title>
                        <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayTabButtons()}
                                </td>
                            </tr>
                            </tbody>
                        </Table>    
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                {displaySaveButtons()}
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }
   
    function displayAttributes() {
        return (
            <>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            Instance Logistics
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {/* <table class="table table-sm caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="loistatus" style={styles.LabelDataEntryNoPaddingRequired}>Status</label>
                                    <select id="loistatus" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={processStatusTypeID} onChange={(e) => (setProcessStatusTypeID(e.target.value))}>
                                        <option value='-1'>Select One</option>
                                        <option value='1'>Pending</option>
                                        <option value='9'>Scheduled</option>
                                        <option value='4'>Complete</option>
                                        <option value='5'>Cancel</option>
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowFacilityList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Facility Room List'>mystery</span>                                    
                                                Facility Room
                                            </label>
                                        </a>
                                        <input id="facroom" type="text" required disabled style={styles.TextDataEntryNoPadding} value={facilityNames}/>                                    
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowInstructorList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Instructor List'>mystery</span>                                    
                                                Instructors
                                            </label>
                                        </a>
                                        {displayAssignedInstructors()}
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowPresenterList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Presenter List'>mystery</span>                                    
                                                Presenters
                                            </label>
                                        </a>
                                        {displayAssignedPresenters()}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="extweburl" style={styles.LabelDataEntryNoPadding}>External Webinar URL</label>
                                    <input id="extweburl" type="text" style={styles.TextDataEntryNoPadding} value={externalWebinarURL} onChange={(e) => (setExternalWebinarURL(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="guestspeakers" style={styles.LabelDataEntryNoPaddingWBG}>Guest Speakers</label>
                                    <input id="guestspeakers" type="text" style={styles.TextDataEntryNoPadding} value={guestSpeakers} onChange={(e) => (setGuestSpeakers(e.target.value))}/>
                                </td>
                            </tr>       
                        </tbody>
                    </table>                                    */}
                    </CardBody>
                </Card>
            </>
        );
    }

function displayAttachment() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <Attachment isAttachmentVisible={isAttachmentSectionVisible} isAttachmentDisable={isAttachmentSectionDisabled} iAttachmentTypeID={AttachmentType.Course} iEntityRowTypeID={EntityRowType.KnowledgeSession} iEntityID={knowledgeSessionID}/>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayListRadioButtons() {
    return (
        radios.map((radio, idx) => (
            <option value={radio.value}>{radio.name}</option>
        ))
    );
}

function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <h3 style={styles.SubHeader}>Knowledge Track Session</h3>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
                <td style={{textAlign:'right', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                        <select id="losstatusselect" class="browser-default" style={styles.TextDataEntryNoPaddingWidth30WBG} value={radioValue} onChange={(e) => (handleRadioChange(e))}>
                            {displayListRadioButtons()}
                        </select>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Knowledge Track Session'/>
                        <a onClick={() => searchKnowledgeSessions()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        <a onClick={() => handleAddSessionActionClicked()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Attendees'>group_add</span>
                        </a>
                        {(actionVisible) ? 
                            <a onClick={() => handleSessionActionClicked()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Roster Actions'>hide</span>
                            </a>
                        :
                            <a onClick={() => handleSessionActionClicked()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Roster Actions'>rule</span>
                            </a>
                        }
                        <ButtonGroup className="mb-2" size='sm'>
                            <Button disabled={loading} style={styles.CDFullButton} onClick={() => handleCancel()}>Cancel</Button>                                        
                        </ButtonGroup>                                                                   
                    </div>                                            
                </td>
            </tr>
            {((knowledgesession) && (knowledgesession.length > 0)) ?
                <tr>
                    <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                        <h5 style={styles.SubHeader}>{knowledgesession[0]?.LearningObjectName} - ({knowledgesession[0]?.LearningObjectInstanceID})</h5>
                    </td>
                </tr>
            :
                <></>
            }
            {(actionVisible) ? 
            <tr>
                <td colSpan='2' style={{textAlign:'right', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                    {displayCompletionSection()}
                </td>
            </tr>
            :
                <></>
            }
            </tbody>
        </Table>                            
        );    
    }
   
    function displayUserSessionHeader() {
        return (
            <Table responsive="sm" style={styles.tableBGNoPadding}>
                <tbody>
                <tr>
                    <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor, padding: 0}}>
                        <Table responsive="sm" style={styles.tableBGNoPadding}>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left', padding: 0}}>
                                        <h5 style={styles.SubHeader}>My Tracks</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                    <td style={{textAlign:'right', width:'50%', backgroundColor:companyData?.PagesBGColor, padding: 0}}>
                        <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                            <select id="losstatusselect" class="browser-default" style={styles.TextDataEntryNoPaddingWidth30WBG} value={radioValue} onChange={(e) => (handleRadioChange(e))}>
                                {displayListRadioButtons()}
                            </select>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search My Track '/>
                            <a onClick={() => searchKnowledgeSessions()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                        </div>                                            
                    </td>
                </tr>
                </tbody>
            </Table>                            
        );    
    }
   
    function displayManagerSessionHeader() {
        return (
            <Table responsive="sm" style={styles.tableBGNoPadding}>
                <tbody>
                <tr>
                    <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor, padding: 0}}>
                        <Table responsive="sm" style={styles.tableBGNoPadding}>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left', padding: 0}}>
                                        <h5 style={styles.SubHeader}>My Team's Enrollments</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                    <td style={{textAlign:'right', width:'50%', backgroundColor:companyData?.PagesBGColor, padding: 0}}>
                        <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                            <select id="losstatusselect" class="browser-default" style={styles.TextDataEntryNoPaddingWidth30WBG} value={radioValue} onChange={(e) => (handleRadioChange(e))}>
                                {displayListRadioButtons()}
                            </select>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Track '/>
                            <a onClick={() => searchKnowledgeSessions()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                        </div>                                            
                    </td>
                </tr>
                </tbody>
            </Table>                            
        );    
    }
   
    function displayMainName(oRow) {
        if (fromLookup) {
            return (
                <td style={styles.tableTextMainColumn}>
                    {oRow?.UserFullName}({oRow?.VendorUserID})
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" 
                            checked={getDetailSwitchValue(oRow?.LearningObjectSessionID)} id={`switch${'detail' + oRow.LearningObjectSessionID}`} 
                            style={(getDetailSwitchValue(oRow?.LearningObjectSessionID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                            onChange={(e) => {handleSwitchDetailChange(oRow?.LearningObjectSessionID)}}>
                        </input>
                        <label class="form-check-label" for={`switch${'detail' + oRow?.LearningObjectSessionID}`}>Select</label>
                    </div>
                </td>
            );
        } else {
            return (
                <td style={styles.tableTextMainColumn}>
                    {oRow?.UserFullName}({oRow?.VendorUserID})
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" 
                            checked={getDetailSwitchValue(oRow?.LearningObjectSessionID)} id={`switch${'detail' + oRow.LearningObjectSessionID}`} 
                            style={(getDetailSwitchValue(oRow?.LearningObjectSessionID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                            onChange={(e) => {handleSwitchDetailChange(oRow?.LearningObjectSessionID)}}>
                        </input>
                        <label class="form-check-label" for={`switch${'detail' + oRow?.LearningObjectSessionID}`}>Details</label>
                    </div>                    
                </td>
            );
        }
    }

    function displayUserSessionMainName(oRow) {
        if (fromLookup) {
            return (
                <td style={styles.tableTextMainColumn}>
                    {((oRow?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) && (oRow?.DueDate) && (new Date(oRow.DueDate) < (new Date()))) ? 
                        <span style={{color:'red'}}>{oRow?.LearningObjectName}</span>
                        :
                        <span >{oRow?.LearningObjectName}</span>
                    }
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" 
                            checked={getDetailSwitchValue(oRow?.LearningObjectSessionID)} id={`switch${'detail' + oRow.LearningObjectSessionID}`} 
                            style={(getDetailSwitchValue(oRow?.LearningObjectSessionID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                            onChange={(e) => {handleSwitchDetailChange(oRow?.LearningObjectSessionID)}}>
                        </input>
                        <label class="form-check-label" for={`switch${'detail' + oRow?.LearningObjectSessionID}`}>Select</label>
                    </div>
                </td>
            );
        } else {
            return (
                <td style={styles.tableTextMainColumn}>
                    {((oRow?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) && (oRow?.DueDate) && (new Date(oRow.DueDate) < (new Date()))) ? 
                        <span style={{color:'red'}}>{oRow?.LearningObjectName}</span>
                        :
                        <span >{oRow?.LearningObjectName}</span>
                    }
                    {(parseInt(knowledgeListType) !== parseInt(KnowledgeListType.User)) && 
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" 
                            checked={getDetailSwitchValue(oRow?.LearningObjectSessionID)} id={`switch${'detail' + oRow.LearningObjectSessionID}`} 
                            style={(getDetailSwitchValue(oRow?.LearningObjectSessionID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                            onChange={(e) => {handleSwitchDetailChange(oRow?.LearningObjectSessionID)}}>
                        </input>
                        <label class="form-check-label" for={`switch${'detail' + oRow?.LearningObjectSessionID}`}>Details</label>
                    </div>                    
                    }
                </td>
            );
        }
    }

    function displayCompletionSection() {
        const bSessionSelected = (getSelectedCount() > 0) ? true : false;

        if (oFirstLOS) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            Roster Actions
                        </Card.Title>
                    </Card.Header>                   
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table table-sm caption-top text-left">  
                            <tbody> 
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="statuschange" style={styles.LabelDataEntryNoPaddingRequired}>Status Change</label>
                                        <select id="statuschange" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={sessionStatusChange} onChange={(e) => (setSessionStatusChange(e.target.value))}>
                                            <option value='-1'>Select One</option>
                                            <option value='4'>Complete</option>
                                            <option value='6'>No Show</option>
                                            <option value='11'>Cancel</option>
                                            <option value='2'>Reset Enroll</option>
                                            <option value='19'>Move</option>
                                        </select>                                     
                                    </td>
                                    {((oFirstLOS?.GradingTypeID) && (oFirstLOS?.GradingTypeID === GradingType.PassAndFail)) ? 
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="newlosgrades" style={styles.LabelDataEntryNoPaddingRequired}>Completion</label>
                                            <select id="newlosgrades" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={sessionPassOrFailed} onChange={(e) => (setSessionPassOrFailed(e.target.value))}>
                                                <option value='-1'>Select One</option>
                                                <option value='1'>Pass</option>
                                                <option value='0'>Failed</option>
                                            </select>                                     
                                        </td>
                                    : 
                                        <></>
                                    }
                                    {((oFirstLOS?.GradingTypeID) && (oFirstLOS?.GradingTypeID === GradingType.PassingScoreRequired)) ? 
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="passingscore" style={styles.LabelDataEntryNoPaddingRequired}>Passing Score {(oFirstLOS?.PassingScore) ? '(Allowed - ' + oFirstLOS?.PassingScore + ')' : ''}</label>
                                            <input id="passingscore" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={sessionPassingScore} onChange={(e) => (setSessionPassingScore(e.target.value))}/>
                                        </td>
                                    : 
                                        <></>
                                    }
                                    {(oFirstLOS?.LOCreditUnits) ? 
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="creditunits" style={styles.LabelDataEntryNoPaddingRequired}>Credit Units {(oFirstLOS?.LOCreditUnits) ? '(Allowed - ' + oFirstLOS?.LOCreditUnits + ')' : ''}</label>
                                            <input id="creditunits" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={sessionCreditUnits} onChange={(e) => (setSessionCreditUnits(e.target.value))}/>
                                        </td>
                                    : 
                                        <></>
                                    }
                                    {(oFirstLOS?.LOCreditHours) ? 
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="acccredithours" style={styles.LabelDataEntryNoPaddingRequired}>Credit Hours {(oFirstLOS?.LOCreditHours) ? '(Allowed - ' + oFirstLOS?.LOCreditHours + ')' : ''}</label>
                                            <input id="credithours" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={sessionCreditHours} onChange={(e) => (setSessionCreditHours(e.target.value))}/>
                                        </td>
                                    : 
                                        <></>
                                    }
                                    {(oFirstLOS?.LOCreditOthers) ? 
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="creditothers" style={styles.LabelDataEntryNoPaddingRequired}>Credit Others {(oFirstLOS?.LOCreditOthers) ? '(Allowed - ' + oFirstLOS?.LOCreditOthers + ')' : ''}</label>
                                            <input id="creditothers" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={sessionCreditOthers} onChange={(e) => (setSessionCreditOthers(e.target.value))}/>
                                        </td>
                                    : 
                                        <></>
                                    }
                                </tr>
                                <tr>
                                    {((sessionStatusChange) && (parseInt(sessionStatusChange) === LOLifeCycleStatus.CancelledByAdmin)) ? 
                                        <>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="loscancelreason" style={styles.LabelDataEntryNoPaddingRequired}>Cancellation Reasons</label>
                                            <select id="loscancelreason" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={sessionCancellationReason} onChange={(e) => (setSessionCancellationReson(e.target.value))}>
                                                <option value='-1'>Select One</option>
                                                {processStatusChangeTypeData?.map((oItem, iIndex) => (
                                                    <option id={oItem?.ProcessStatusChangeTypeID} value={oItem?.ProcessStatusChangeTypeID}>{oItem.ProcessStatusChangeTypeName}</option>        
                                                ))}
                                            </select>                                     
                                        </td>
                                        <td colSpan='6' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="loscomments" style={styles.LabelDataEntryNoPaddingRequired}>Comments</label>
                                            <input id="loscomments" type="text" style={styles.TextDataEntryNoPaddingWBG} value={sessionComments} onChange={(e) => (setSessionComments(e.target.value))}/>
                                        </td>
                                        </>
                                    : 
                                        <td colSpan='7' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="loscomments" style={styles.LabelDataEntryNoPaddingRequired}>Comments</label>
                                            <input id="loscomments" type="text" style={styles.TextDataEntryNoPaddingWBG} value={sessionComments} onChange={(e) => (setSessionComments(e.target.value))}/>
                                        </td>
                                    }
                                </tr>
                            </tbody>
                        </table>   
                    </Card.Body>
                    <CardFooter>                    
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(3, null, null)} disabled={(loading) || (!bSessionSelected)} style={styles.CDPillButtonSMGrey} title='Update Roster'>Update Roster</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(3)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel'>Cancel</Button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            )    
        } else {
            return (<></>)
        }
    }

    function displayLifeCycle(currentItem) {
        const oChildrens = losLifeCycle?.filter((oItem) => (oItem.LearningObjectSessionID === currentItem.LearningObjectSessionID));

        return (
            <>
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableTextLeft}>#</th>
                        <th style={styles.tableTextLeft}>Status</th>
                        <th style={styles.tableTextLeft}>User</th>
                        <th style={styles.tableText}>Date</th>
                    </tr>
                </thead>                        
                <tbody> 
                {oChildrens?.map((oItem, iIndex) => (
                    <tr> 
                        <td style={styles.tableTextLeft}>{iIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.LearningObjectProcessStatusTypeName}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.CreatorFullName}</td> 
                        <td style={styles.tableText}>{moment(oItem?.CreatedDate).format('YYYY-MM-DD hh:mm a')}</td> 
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function displayAuthorizer(currentItem) {
        const oChildrens = losAuthorizer?.filter((oItem) => (oItem.LearningObjectSessionID === currentItem.LearningObjectSessionID));

        return (
            <>
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableTextLeft}>#</th>
                        <th style={styles.tableTextLeft}>Level</th>
                        <th style={styles.tableTextLeft}>Name</th>
                        <th style={styles.tableTextLeft}>Approved</th>
                    </tr>
                </thead>                        
                <tbody> 
                {oChildrens?.map((oItem, iIndex) => (
                    <tr> 
                        <td style={styles.tableTextLeft}>{iIndex + 1}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.LevelNumber}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.AuthorizerFullName}</td> 
                        <td style={styles.tableText}>{((oItem?.Approved) && (oItem?.Approved === '1') ? 'Yes' : 'No')}</td> 
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function getDetailSwitchTR(currentItem) {
        const oRequestList = sliderValue?.filter((oItem) => oItem.LearningObjectSessionID === currentItem?.LearningObjectSessionID);
        const oLCChildrens = losLifeCycle?.filter((oItem) => (oItem.LearningObjectSessionID === currentItem.LearningObjectSessionID));
        const oAuthChildrens = losAuthorizer?.filter((oItem) => (oItem.LearningObjectSessionID === currentItem.LearningObjectSessionID));

        if ((oRequestList) && (oRequestList.length > 0) && (oRequestList[0]?.showDetails)) {
                return (
                <>
                {(oLCChildrens) && (oLCChildrens.length > 0) &&               
                    <tr id={`tr${'instdetail' + currentItem.LearningObjectSessionID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Life Cycle</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayLifeCycle(currentItem)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>       
                }
                {(oAuthChildrens) && (oAuthChildrens.length > 0) &&               
                <tr id={`tr${'instdetail' + currentItem.LearningObjectSessionID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Authorizers</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayAuthorizer(currentItem)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                }
                </>
            );
        } 
    }

    function addHours (date, hoursToAdd) {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + hoursToAdd);
        return newDate;
    };

    function displayUserSessionAction(assRec) {
        // check if user can cancel the session.
        // if (assRec?.LearningObjectTypeID === 1) {
        //     console.log(new Date(assRec?.StartDateTime));
        //     console.log(addHours(assRec?.StartDateTime, parseInt(assRec?.CancellationCutOffHours)));    
        //     console.log((new Date(assRec?.StartDateTime)) < (addHours(assRec?.StartDateTime, parseInt(assRec?.CancellationCutOffHours))));    
        // }
        var bCanCancel = false;
        if ((assRec?.OtherActionID) && (assRec?.OtherActionID === LOLifeCycleStatus.CancelledByUser) && (assRec?.CancellationCutOff) && (assRec?.CancellationCutOff === true) && 
            (assRec?.CancellationCutOffHours) && (assRec?.CancellationCutOffHours !== '' ) && 
            (assRec?.StartDateTime) && (new Date(assRec?.StartDateTime) < addHours(assRec?.StartDateTime, parseInt(assRec?.CancellationCutOffHours))) &&
            (assRec?.LearningObjectProcessStatusTypeID) && 
            ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
            bCanCancel = true;
        } else if ((assRec?.OtherActionID) && (assRec?.OtherActionID === LOLifeCycleStatus.CancelledByUser) && (assRec?.CreatedByUserID) && (assRec?.CreatedByUserID === auth?.UserID) &&
            (assRec?.LearningObjectProcessStatusTypeID) && 
            ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
            bCanCancel = true;
        } else if ((assRec?.LearningObjectProcessStatusTypeID) && ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Waitlist) ||
            (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
            bCanCancel = true;
        }

        return (
            <>
            <DropdownButton title="Launch Pad" variant={AlertVariant.Secondary} className="mb-2" size='sm'>
                <Dropdown.Item onClick={() => handleUserSessionActionsClicked(0, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID)}>Details</Dropdown.Item>
                {((assRec?.TotalAttachments) && (assRec?.TotalAttachments > 0)) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(1, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>Attachments</Dropdown.Item>
                    </>
                :
                    <></>
                }
                {((assRec?.EvaluationID) && (assRec?.EvaluationID > 0) && (assRec?.LearningObjectProcessStatusTypeID) && 
                    ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(2, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, assRec?.EvaluationID)}>Evaluation</Dropdown.Item>
                    </>
                :
                    <></>
                }
                {((assRec?.LearningObjectTypeID) && (assRec?.LearningObjectTypeID === 2) && (assRec?.LearningObjectProcessStatusTypeID) && 
                    ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(3, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>Knowledge Hub</Dropdown.Item>
                    </>
                :
                    <></>
                }
                {/* {((assRec?.TestInstanceID) && (assRec?.TestInstanceID > 0) && (assRec?.LearningObjectProcessStatusTypeID) && 
                    ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(4, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>Assessment</Dropdown.Item>
                    </>
                :
                    <></>
                } */}
                {((assRec?.IsWebEx) && (assRec?.IsWebEx === true) && (assRec?.ConferenceTypeID) && (assRec?.ConferenceTypeID > 0) && (assRec?.WebExAttendeeURL) && (assRec?.WebExAttendeeURL.trim() !== '') && 
                    (assRec?.LearningObjectProcessStatusTypeID) && 
                    ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(5, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>Webinar</Dropdown.Item>
                    </>
                :
                    <></>
                }
                {((assRec?.AllowROMS) && (assRec?.AllowROMS === true) && (assRec?.LearningObjectProcessStatusTypeID) && 
                    ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(9, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>Objectives</Dropdown.Item>
                    </>
                :
                    <></>
                }
                {(bCanCancel) ?
                    <>
                    <Dropdown.Item onClick={() => handleUserSessionActionsClicked(6, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>Cancel Enrollment</Dropdown.Item>
                    </>
                :
                    <></>
                }
            </DropdownButton>
            </>
        );
    }   

    function displayInstructorSessionAction(assRec) {
        // check if user can cancel the session.
        var bCanCancel = false;
        // if ((assRec?.OtherActionID) && (assRec?.OtherActionID === LOLifeCycleStatus.CancelledByUser) && (assRec?.CancellationCutOff) && (assRec?.CancellationCutOff === true) && 
        //     (assRec?.CancellationCutOffHours) && (assRec?.CancellationCutOffHours !== '' ) && 
        //     (assRec?.StartDateTime) && (new Date(assRec?.StartDateTime) < addHours(assRec?.StartDateTime, parseInt(assRec?.CancellationCutOffHours))) &&
        //     (assRec?.LearningObjectProcessStatusTypeID) && 
        //     ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
        //     bCanCancel = false;
        // } else if ((assRec?.OtherActionID) && (assRec?.OtherActionID === LOLifeCycleStatus.CancelledByUser) && (assRec?.CreatedByUserID) && (assRec?.CreatedByUserID !== auth?.UserID) &&
        //     (assRec?.LearningObjectProcessStatusTypeID) && 
        //     ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
        //     bCanCancel = false;
        // } 
        if ((assRec?.LearningObjectProcessStatusTypeID) && ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Waitlist) ||
            (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Pending) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
                bCanCancel = true;
        }

        return (
            <>
            <a onClick={() => handleUserSessionActionsClicked(0, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='View Knowledge Track Session'>quick_reference</span>
            </a>
            {(bCanCancel) ?
                <a onClick={() => handleUserSessionActionsClicked(6, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Cancel Knowledge'>cancel</span>
                </a>
            :
                <></>
            }
            </>
        );
    }   

    function displayManagerSessionAction(assRec) {
        // check if user can cancel the session.
        var bCanCancel = false;
        var bApprovalActions = false;
        // if ((assRec?.OtherActionID) && (assRec?.OtherActionID === LOLifeCycleStatus.CancelledByUser) && (assRec?.CancellationCutOff) && (assRec?.CancellationCutOff === true) && 
        //     (assRec?.CancellationCutOffHours) && (assRec?.CancellationCutOffHours !== '' ) && 
        //     (assRec?.StartDateTime) && (new Date(assRec?.StartDateTime) < addHours(assRec?.StartDateTime, parseInt(assRec?.CancellationCutOffHours))) &&
        //     (assRec?.LearningObjectProcessStatusTypeID) && 
        //     ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
        //     bCanCancel = false;
        // } else if ((assRec?.OtherActionID) && (assRec?.OtherActionID === LOLifeCycleStatus.CancelledByUser) && (assRec?.CreatedByUserID) && (assRec?.CreatedByUserID !== auth?.UserID) &&
        //     (assRec?.LearningObjectProcessStatusTypeID) && 
        //     ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.ClassSessionComplete))) {
        //     bCanCancel = false;
        // } 
        if ((assRec?.LearningObjectProcessStatusTypeID) && ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || 
            (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Waitlist))) {
            bCanCancel = true;
        } else if ((assRec?.LearningObjectProcessStatusTypeID) && ((assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Enrolled) || 
            (assRec?.LearningObjectProcessStatusTypeID === LOLifeCycleStatus.Waitlist))) {
            bApprovalActions = true;
        }

        return (
            <>
            <a onClick={() => handleUserSessionActionsClicked(0, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='View Knowledge Track Session'>quick_reference</span>
            </a>
            {(bApprovalActions) ?
                <>
                <a onClick={() => handleUserSessionActionsClicked(7, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Approve Enrollment'>add_task</span>
                </a>
                <a onClick={() => handleUserSessionActionsClicked(8, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Deny Enrollment'>do_not_distrub_on</span>
                </a>
                </>
            :
                <></>
            }
            {(bCanCancel) ?
                <a onClick={() => handleUserSessionActionsClicked(6, assRec?.LearningObjectSessionID, assRec?.LearningObjectInstanceID, assRec?.LearningObjectID, null)}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Cancel Knowledge'>cancel</span>
                </a>
            :
                <></>
            }
            </>
        );
    }   

    function displayKnowledgeTypeName(assRec) {
        if (assRec?.LearningObjectTypeID === 1) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Classroom-Based'>supervised_user_circle</span>
            );
        } else if (assRec?.LearningObjectTypeID === 2) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Online'>touchpad_mouse</span>
            )
        } else if (assRec?.LearningObjectTypeID === 3) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Manual/External'>other_admission</span>
            );
        } else if (assRec?.LearningObjectTypeID === 4) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Historical'>work_history</span>
            );
        }
    }   

    function displayKnowledgeSessions() {
        if (knowledgeListType === KnowledgeListType.User) {
            return (
                <>
                {displayKnowledgeSessionsUser()}
                </>
            );            
        } else if (knowledgeListType === KnowledgeListType.Manager) {
            return (
                <>
                {displayKnowledgeSessionsManager()}
                </>
            );            
        } else {
            return (
                <>
                {displayKnowledgeSessionsInstructor()}
                </>
            );            
        }
    }

    function displayKnowledgeSessionsUser() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {displayUserSessionHeader()}
                    </Card.Title>
                    {(showAlertMessage) ?
                        <Card.Subtitle>
                            <div>
                                <h6><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} alertTimeout={AlertEnrollmentTimeOut} handleParentAlertClose={handleCloseAlert} /></h6>
                            </div>
                        </Card.Subtitle>
                        :
                            <></>
                        }
                </Card.Header>                   
                {(loading) ? (            
                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                        <Spinner/>
                    </div>
                ) 
                : 
                (                                          
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {((!knowledgesession) || (knowledgesession.length === 0)) ? (                                        
                        <div style={styles.tableText}>
                            No Knowledge Track Sessions.
                        </div>)
                        :
                        (
                            <Table responsive="sm" hover variant="light" style={styles.tableBGNoPadding}>
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableText}>Type</th>
                                        <th style={styles.tableTextLeft}>Knowledge</th>
                                        <th style={styles.tableText}>Due Date</th>
                                        <th style={styles.tableText}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {knowledgesession && knowledgesession.map((assRec, index) => {
                                        return (
                                            <>
                                            <tr>
                                                <td style={styles.tableText}>{index+1}</td>
                                                <td style={styles.tableText}>{displayKnowledgeTypeName(assRec)}</td>                                                
                                                {displayUserSessionMainName(assRec)}
                                                <td style={styles.tableText}>{(assRec?.DueDate) ? moment(assRec?.DueDate).format('MM/DD/YYYY') : ''}</td>
                                                <td style={styles.tableText}>
                                                    <div>
                                                        {displayUserSessionAction(assRec)}
                                                    </div>
                                                </td>
                                            </tr>
                                            {getDetailSwitchTR(assRec)}
                                            </>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>                                                            
                        )
                    }
                </Card.Body>
                )}
                <Card.Footer className="text-right" style={{alignItems:'end'}}>
                    <Table responsive="sm" style={styles.tableBGNoPadding}>
                        <tbody>
                            <tr>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left', padding:'0'}}>
                                    <h6 style={styles.tableTextLeft}>{stateknowledgesessions?.RecordFound?.toLocaleString()}   Tracks&nbsp;&nbsp;({getSelectedCount()} Selected)</h6>
                                </td>
                                <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center', padding:'0'}}>
                                    {(loading) ? <></> : 
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                        <h6 style={styles.tableText}>
                                        {(stateknowledgesessions?.PagingMetaData?.PreviousPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                    <span title='Previous Page'>Previous</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        {(stateknowledgesessions?.PagingMetaData?.NextPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                    <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        </h6>
                                        </div>
                                    }
                                </td>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right', padding:'0'}}>
                                    <h6 style={styles.tableTextRight}> Page   {stateknowledgesessions?.PagingMetaData?.PageNumber}  of  {stateknowledgesessions?.PagingMetaData?.TotalPage?.toLocaleString()}</h6>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Footer>
            </Card>
        );
    }
    
    function displayKnowledgeSessionsUserEx() {
        return (
            <Card style={{ width: '100%', backgroundColor: companyData?.PagesBGColor, justifyContent: 'left' }}>
                <Card.Header style={{ width: '100%', backgroundColor: companyData?.PagesBGColor, justifyContent: 'left' }}>
                    <Card.Title>{displayUserSessionHeader()}</Card.Title>
                    {showAlertMessage && (
                        <Card.Subtitle>
                            <h6>
                                <AlertMessage
                                    showAlertMessage={showAlertMessage}
                                    alertMessage={alertMessage}
                                    alertVariant={alertVariant}
                                    alertTimeout={AlertEnrollmentTimeOut}
                                    handleParentAlertClose={handleCloseAlert}
                                />
                            </h6>
                        </Card.Subtitle>
                    )}
                </Card.Header>
    
                {loading ? (
                    <div style={{ display: 'flex', width: '100%', height: '100%', textAlign: 'center' }}>
                        <Spinner />
                    </div>
                ) : (
                    <Card.Body 
                        style={{ 
                            width: '100%', 
                            backgroundColor: companyData?.PagesBGColor, 
                            justifyContent: 'left' 
                        }}
                    >
                        {(!knowledgesession || knowledgesession.length === 0) ? (
                            <div style={styles.tableText}>No Knowledge Track Sessions.</div>
                        ) : (
                            <div 
                                style={{ 
                                    display: 'flex', 
                                    width: '100%', 
                                    transition: 'all 0.3s ease-in-out' 
                                }}
                                onMouseEnter={() => setIsTableHovered(true)}
                                onMouseLeave={() => setIsTableHovered(false)}
                            >
                                {/* Table Section */}
                                <div 
                                    style={{ 
                                        width: isTableHovered ? '70%' : '100%', 
                                        transition: 'all 0.3s ease-in-out' 
                                    }}
                                >
                                    <Table responsive="sm" hover variant="light" style={styles.tableBGNoPadding}>
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableText}>Type</th>
                                                <th style={styles.tableTextLeft}>Knowledge</th>
                                                <th style={styles.tableText}>Due Date</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {knowledgesession.map((assRec, index) => (
                                                <tr
                                                    key={index}
                                                    onMouseEnter={() => setHoveredRow(index)}
                                                    onMouseLeave={() => setHoveredRow(null)}
                                                    style={{
                                                        transition: 'all 0.3s ease-in-out',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <td style={{ ...styles.tableText, flex: 1 }}>{index + 1}</td>
                                                    <td style={{ ...styles.tableText, flex: 2 }}>{displayKnowledgeTypeName(assRec)}</td>
                                                    <td style={{ ...styles.tableTextLeft, flex: 3 }}>
                                                        {displayUserSessionMainName(assRec)}
                                                    </td>
                                                    <td style={{ ...styles.tableText, flex: 2 }}>
                                                        {assRec?.DueDate ? moment(assRec?.DueDate).format('MM/DD/YYYY') : ''}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
    
                                {/* Drawer Menu (Appears when hovered) */}
                                <div 
                                    style={{ 
                                        width: isTableHovered ? '30%' : '0%', 
                                        overflow: 'hidden', 
                                        transition: 'all 0.3s ease-in-out',
                                        backgroundColor: '#f8f9fa', 
                                        padding: isTableHovered ? '10px' : '0px',
                                        display: isTableHovered ? 'flex' : 'none', 
                                        justifyContent: 'center', 
                                        alignItems: 'center'
                                    }}
                                >
                                    <DropdownButton title="Menu" variant="secondary">
                                        <Dropdown.Item>Edit</Dropdown.Item>
                                        <Dropdown.Item>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                        )}
                    </Card.Body>
                )}
            </Card>
        );
    }
    
    function displayKnowledgeSessionsManager() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {displayManagerSessionHeader()}
                    </Card.Title>
                    {(showAlertMessage) ?
                        <Card.Subtitle>
                            <div>
                                <h6><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} alertTimeout={AlertEnrollmentTimeOut} handleParentAlertClose={handleCloseAlert} /></h6>
                            </div>
                        </Card.Subtitle>
                        :
                            <></>
                        }
                </Card.Header>                   
                {(loading) ? (            
                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                        <Spinner/>
                    </div>
                ) 
                : 
                (                                          
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {((!knowledgesession) || (knowledgesession.length === 0)) ? (                                        
                        <div style={styles.tableText}>
                            No Knowledge Track Sessions.
                        </div>)
                        :
                        (
                            <Table responsive="sm" hover variant="light" style={styles.tableBGNoPadding}>
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableTextLeft}>Name</th>
                                        <th style={styles.tableTextLeft}>Knowledge</th>
                                        <th style={styles.tableText}>Type</th>
                                        <th style={styles.tableText}>Due Date</th>
                                        <th style={styles.tableText}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {knowledgesession && knowledgesession.map((assRec, index) => {
                                        return (
                                            <>
                                            <tr>
                                                <td style={styles.tableText}>{index+1}</td>
                                                <td style={styles.tableTextLeft}>{assRec?.UserFullName}</td>
                                                {displayUserSessionMainName(assRec)}
                                                <td style={styles.tableText}>{displayKnowledgeTypeName(assRec)}</td>                                                
                                                <td style={styles.tableText}>{(assRec?.DueDate) ? moment(assRec?.DueDate).format('MM/DD/YYYY') : ''}</td>
                                                <td style={styles.tableText}>
                                                    <div>
                                                        {displayManagerSessionAction(assRec)}
                                                    </div>
                                                </td>
                                            </tr>
                                            {getDetailSwitchTR(assRec)}
                                            </>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>                                                            
                        )
                    }
                </Card.Body>
                )}
                <Card.Footer className="text-right" style={{alignItems:'end'}}>
                    <Table responsive="sm" style={styles.tableBGNoPadding}>
                        <tbody>
                            <tr>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left', padding:'0'}}>
                                    <h6 style={styles.tableTextLeft}>{stateknowledgesessions?.RecordFound?.toLocaleString()}   Tracks&nbsp;&nbsp;({getSelectedCount()} Selected)</h6>
                                </td>
                                <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center', padding:'0'}}>
                                    {(loading) ? <></> : 
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                        <h6 style={styles.tableText}>
                                        {(stateknowledgesessions?.PagingMetaData?.PreviousPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                    <span title='Previous Page'>Previous</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        {(stateknowledgesessions?.PagingMetaData?.NextPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                    <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        </h6>
                                        </div>
                                    }
                                </td>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right', padding:'0'}}>
                                    <h6 style={styles.tableTextRight}> Page   {stateknowledgesessions?.PagingMetaData?.PageNumber}  of  {stateknowledgesessions?.PagingMetaData?.TotalPage?.toLocaleString()}</h6>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Footer>
            </Card>
        );
    }

    function displayKnowledgeSessionsInstructor() {
        return (
            <div style={styles.MainInnerDiv}>
                <div style={styles.DashboardContainer}>
                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                            <Card.Subtitle>
                            {(showAlertMessage) ?
                                <div>
                                    <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} alertTimeout={AlertEnrollmentTimeOut} handleParentAlertClose={handleCloseAlert} /></h5>
                                </div>
                                :
                                    <></>
                                }
                            </Card.Subtitle>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {((!knowledgesession) || (knowledgesession.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Knowledge Track Sessions.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextLeft}>Name</th>
                                                <th style={styles.tableText}>Status</th>
                                                <th style={styles.tableText}>Department</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {knowledgesession && knowledgesession.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{assRec?.LearningObjectSessionID}</td>
                                                        {displayMainName(assRec)}
                                                        <td style={styles.tableText}>{assRec?.LearningObjectProcessStatusTypeName}</td>
                                                        <td style={styles.tableText}>{assRec?.UserDepartmentFullName}</td>
                                                        <td style={styles.tableText}>
                                                            <div>
                                                                {displayInstructorSessionAction(assRec)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {getDetailSwitchTR(assRec)}
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{stateknowledgesessions?.RecordFound?.toLocaleString()}   Knowledge Track Sessions&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(stateknowledgesessions?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(stateknowledgesessions?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {stateknowledgesessions?.PagingMetaData?.PageNumber}  of  {stateknowledgesessions?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showUserList} onShow={handleShowUserList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseUserList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseUserList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showSessionDetail} onShow={handleShowSessionDetail} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseSessionDetail}>
            <Modal.Header closeButton>
            <Modal.Title><h3 style={styles.SubHeader}>{knowledgeSessionName}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <KnowledgeSessionDetail id={knowledgeSessionID} knowledgeListType={knowledgeListType}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseSessionDetail}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showLaunchEvaluation} onShow={handleShowLaunchEvaluation} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseLaunchEvaluation}>
            <Modal.Header closeButton>
            <Modal.Title><h3 style={styles.SubHeader}>{knowledgeSessionName}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LaunchEvaluation id={knowledgeSessionID} processleveltypeidProps={RetentasType.Knowledge} fromLookup={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseLaunchEvaluation}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showSessionObjectives} onShow={handleShowSessionObjectives} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseSessionObjectives}>
            <Modal.Header closeButton>
            <Modal.Title><h3 style={styles.SubHeader}>{knowledgeSessionName}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <KnowledgeSessionObjectives id={knowledgeSessionID} paramsForChildren={paramsForChildren}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseSessionObjectives}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        {displayKnowledgeSessions()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      knowledgesessions: state.entity.knowledgesessions, 
      myknowledgesessions: state.entity.myknowledgesessions, 
      myteamknowledgesessions: state.entity.mycalendar, 
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
      enrollmentMessage: state.entity.enrollmentMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadingMenuSelection: (isLoading) => dispatch(loadingMenuSelection(isLoading)),
        getKnowledges: (kParams) => dispatch(getKnowledges(kParams)),
        getKnowledgeInstances: (kParams) => dispatch(getKnowledgeInstances(kParams)),
        getKnowledgeSessions: (kParams) => dispatch(getKnowledgeSessions(kParams)),
        getKnowledgeSessionsByUser: (kParams) => dispatch(getKnowledgeSessionsByUser(kParams)),
        getUserCalendar: (kParams) => dispatch(getUserCalendar(kParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        updateKnowledgeSession: (oParams) => dispatch(updateKnowledgeSession(oParams)),
        processEnrollment: (oParams) => dispatch(processEnrollment(oParams)),
        processEnrollmentClear: () => dispatch(processEnrollmentClear()),
        getEvaluation: (id) => dispatch(getEvaluation(id)),
        getEvaluationQuestions: (id) => dispatch(getEvaluationQuestions(id)),
        getEvaluationRatings: (id) => dispatch(getEvaluationRatings(id)),
        getKnowledgeSessionEvaluation: (oParams) => dispatch(getKnowledgeSessionEvaluation(oParams)),        
        getTestLibraries: (oParams) => dispatch(getTestLibraries(oParams)),
        getKnowledgeSessionAssessment: (oParams) => dispatch(getKnowledgeSessionAssessment(oParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeSessionList)