import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getUserAttributesLookups, insertCatalog, updateCatalog, deleteCatalog, getLearningObjectTree } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import { UserLookup, Rights, KnowledgeType, AttachmentType, EntityRowType, WebConferenceType, MenuType } from '../../config/Constant';
import UserLookupList from './UserLookupList';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { getUserLookup } from '../../store/actions/authActions';

const CatalogList = (props) => {
    const { auth, companyData, catalogs, orgDivisions, userattributelookups, lookupselections, loadingMenu, orgLearningObjectTree } = props;    
    const location  = useLocation();
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [learningObjectTreeNodeName, setLearningObjectTreeNodeName] = useState('');
    const [learningObjectTreeSelectedNodes, setLearningObjectTreeSelectedNodes] = useState('');
    const [addNewCatalog, setAddNewCatalog] = useState(false);
    const [editCatalog, setEditCatalog] = useState(false);
    const [catalogID, setCatalogID] = useState(-1);
    const [catalogName, setCatalogName] = useState('');
    const [catalogPublic, setCatalogPublic] = useState('1');
    const [catalogDescription, setCatalogDescription] = useState('');
    const [showOwnershipList, setShowOwnershipList] = useState('');    
    const [catalogOwnerships, setCatalogOwnerships] = useState('');    
    const [catalogOwnershipDetails, setCatalogOwnershipDetails] = useState('');    
    const [catalogOwnershipArray, setCatalogOwnershipArray] = useState([]);    
    const [catalogTreeNodes, setCatalogTreeNodes] = useState('');    
    const [catalogTreeNodeDetails, setCatalogTreeNodeDetails] = useState('');
    const [catalogTreeNodeNames, setCatalogTreeNodeNames] = useState('');
    const [catalogParentTreeNodes, setCatalogParentTreeNodes] = useState('');    
    const [catalogParentTreeNodeDetails, setCatalogParentTreeNodeDetails] = useState('');
    const [catalogParentTreeNodeNames, setCatalogParentTreeNodeNames] = useState('');
    const [catalogDivisionDetails, setCatalogDivisionDetails] = useState('');
    const [catalogDivisions, setCatalogDivisions] = useState('');

    const catalog = catalogs?.catalogs?.Catalogs;
    const availability = catalogs?.catalogs?.Availability;
    const ownership = catalogs?.catalogs?.LearningObjectTreeOwnership;

    const radioKnowledege = [
        { name: 'Attributes', value: 1 },
        { name: 'Organization', value: 2 },
    ];
       
    function initialTabValue() {
        return 1;
    };

    const handleCloseOwnershipList = () => {
        setShowOwnershipList(false);
        updateOwnershipSelection();
    }

    async function handleShowOwnershipList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.Preceptor,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getUserLookup(oParams);

        setShowOwnershipList(true); 
        setLoading(false);
    };

    function updateOwnershipSelection() {
        var sSelections = lookupselections?.User;
        if (catalogOwnershipDetails !== '') {
            sSelections = catalogOwnershipDetails + '&' + sSelections;
        }
        // if (catalogOwnerships !== '') {
        //     sSelections = catalogOwnerships + '&' + sSelections;
        // }
        console.log(sSelections);
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setCatalogOwnershipDetails(sSelections);
            setCatalogOwnershipArray(oSelections);
            setCatalogOwnerships(sSelectedIds);
        }
    }

    function handleDeleteAssignedOwnershipClick(goal) {
        var tempSkills = catalogOwnershipArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setCatalogOwnershipArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setCatalogOwnerships(sSelectedIds);
            setCatalogOwnershipDetails(sSelectedDetails);
        }        
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        displayTabContent();
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        // const divisionId = nodes[0]?.data?.id;
        let divisionIds = '';
        let divisionDetails = '';
        // console.log(nodes);
        nodes.forEach(element => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += element?.data?.id;
            divisionDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (divisionIds) {
            setSelectedNodes(nodes);
            setCatalogDivisions(divisionIds);
            setCatalogDivisionDetails(divisionDetails);
        }
        setLoading(false);
    }

    function setSearchLearningObjectTreeNodeName(e) {
        setLearningObjectTreeNodeName(e.target.value);
    }

    async function saveLearningObjectTreeSelectedNodes(nodes) {
        setLoading(true);
        let treeIds = '';
        let treeDetails = '';
        // console.log(nodes);
        nodes.forEach(element => {
            if (treeIds.trim() !== '') {
                treeIds += ',';
            }
            if (treeDetails.trim() !== '') {
                treeDetails += '&';
            }
            treeIds += element?.data?.id;
            treeDetails += element?.data?.id + '=' + element?.data?.name + '|' + element?.data?.Description + '|' + element?.data?.IsNodeRoot + 
                    '|' + element?.data?.IsPublic  + '|' + element?.data?.ParentID + '|' + element?.data?.ParentName + '|' + element?.data?.ParentPath +
                    '|' + element?.data?.Divisions + '|' + element?.data?.Ownerships + '|' + element?.data?.ChildCount + '|' + element?.data?.ChildCountLO;
        });
        if (treeIds) {
            setLearningObjectTreeSelectedNodes(nodes);
            setCatalogTreeNodes(treeIds);
            setCatalogTreeNodeDetails(treeDetails);
        }
        setLoading(false);
    }
   
    const addInitialSliderArray = (catalog) => {
        return catalog?.map((kRec) => ({
            LearningObjectTreeID:kRec.LearningObjectTreeID, 
            takeAction:false,
            showDetails:false,
            editCatalogClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(catalog);
        setSliderValue(initialScoreValues);
    }, [catalog])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewCatalog) ? setAddNewCatalog(false) : setAddNewCatalog(true);
            setCatalogID(-1);
        } 

        setLoading(false);
    }

    async function handleSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const oParams  = {
                LearningObjectTreeID: catalogID,
                LearningObjectTreeName: catalogName,
                IsPublic: (catalogPublic === '1' ? true : false),
                IsNodeRoot: ((catalogParentTreeNodes !== '') ? true : false),
                ParentLearningObjectTreeID: catalogParentTreeNodes,
                LearningObjectTreeDescription: catalogDescription,
                LearningObjectTreeOwnerships: catalogOwnerships,
                DivisionIDs: catalogDivisions,
                IsDeleted:false,
                CreatedByUserID: auth?.UserID,
                UpdatedByUserID: auth?.UserID,
            };        
            console.log(oParams);
            if (catalogID === -1) {
                await props.insertCatalog(oParams);    
                setAddNewCatalog(false);
            } else {
                await props.updateCatalog(oParams);    
                setEditCatalog(false);
            }
        } 

        await props.getLearningObjectTree(auth?.UserID);

        setAddNewCatalog(false);
        setEditCatalog(false);
        setLoading(false);
    }

    async function handleDeleteChildernClick(id) {
        setLoading(true);

        const oParams  = {
            LearningObjectTreeID: id,
        };        
        console.log(oParams);
        await props.deleteCatalog(oParams);    
        
        await props.getLearningObjectTree(auth?.UserID);

        setLoading(false);
    }

    async function handleUpdateParentNodeClick(id, name) {
        setLoading(true);
        setCatalogParentTreeNodes(id);
        setCatalogParentTreeNodeNames(name?.split('|')[0]);
        setLoading(false);
    }

    async function handleUpdateChildernClick(id) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const assEditedRec = catalogTreeNodeDetails?.split('=')[1].split('|');        
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setCatalogID(id);
            setCatalogName(assEditedRec[0]);
            setCatalogDescription(assEditedRec[1]);
            setCatalogPublic((assEditedRec[3] === true) ? '1' : '0'); 
            setCatalogParentTreeNodes(assEditedRec[4]);
            setCatalogParentTreeNodeNames(assEditedRec[5]);

            let divisionIds = '';
            let divisionDetails = '';
            const divisionRec = assEditedRec[7].split('~');
            console.log(divisionRec);
            divisionRec.forEach(element => {
                if (divisionIds.trim() !== '') {
                    divisionIds += ',';
                }
                if (divisionDetails.trim() !== '') {
                    divisionDetails += '&';
                }
                divisionIds += element?.split('-')[0];
                divisionDetails += element?.split('-')[0] + '=' + element?.split('-')[1];
            });
            if (divisionIds) {
                // setSelectedNodes(nodes);
                setCatalogDivisions(divisionIds);
                setCatalogDivisionDetails(divisionDetails);
            }

            let ownershipIDs = '';
            let ownershipDetails = '';
            const ownershipRec = assEditedRec[8].split('~');
            ownershipRec.forEach(element => {
                if (element.trim() !== '') {
                    if (ownershipIDs.trim() !== '') {
                        ownershipIDs += ',';
                    }
                    if (ownershipDetails.trim() !== '') {
                        ownershipDetails += '&';
                    }        
                    ownershipIDs += element?.split('-')[0];
                    ownershipDetails += element?.split('-')[0] + '=' + element?.split('-')[1] + '|' + element?.split('-')[2] + '|' + element?.split('-')[3];
                }
            });
            if (ownershipIDs.trim() !== '') {
                setCatalogOwnerships(ownershipIDs);
            }
            if (ownershipDetails.trim() !== '') {
                setCatalogOwnershipDetails(ownershipDetails);
                const oSelections = ownershipDetails?.split('&');
                setCatalogOwnershipArray(oSelections);
            }  

            setEditCatalog(true);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(id) {
        if (catalogID === -1) {
            setAddNewCatalog(false);
        } else {
            setEditCatalog(false);
        }
        ClearStateFields();
    }
    
    async function ClearStateFields() {
        setCatalogID(-1);
        setCatalogName('');
        setCatalogPublic(-1); 
        setCatalogDescription('');
        setCatalogOwnerships('');
        setCatalogDivisions('');
        setCatalogDivisionDetails('');
    }

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Catalog'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Catalog'>Cancel</Button>
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radioKnowledege.map((radio, idx) => (
                <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                    <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                </li> 
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        }
        else if (tabValue === 2 ) {
            return (
                <div>
                    {displayOrganization()}
                </div>
            );
        } 
    }

    function displayNewCatalog() {
        if ((addNewCatalog) || (editCatalog)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewCatalog) ? 'New Catalog Node' : 'Edit Catalog Node'}                        
                        </Card.Title>
                        <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayTabButtons()}
                                </td>
                            </tr>
                            </tbody>
                        </Table>    
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                {displaySaveButtons()}
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayAssignedOwnerships() {
        if (catalogOwnershipArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {catalogOwnershipArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedOwnershipClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Ownership'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAttributes() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="catalogname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                <input id="catalogname" type="text" style={styles.TextDataEntryNoPadding} value={catalogName} onChange={(e) => (setCatalogName(e.target.value))}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="asstype" style={styles.LabelDataEntryNoPadding}>Public</label>
                                <select id="asstype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={catalogPublic} onChange={(e) => (setCatalogPublic(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                <input id="description" type="text" style={styles.TextDataEntryNoPadding} value={catalogDescription} onChange={(e) => (setCatalogDescription(e.target.value))}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="parentnode" style={styles.LabelDataEntryNoPadding}>Parent</label>
                                <input id="parentnode" type="text" disabled style={styles.TextDataEntryNoPadding} value={catalogParentTreeNodeNames}/>
                            </div>                    
                        </td>
                    </tr>       
                    <tr>
                        <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                <a onClick={() => setShowOwnershipList(true)}>
                                    <label style={styles.LabelDataEntryNoPadding}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Ownership List'>mystery</span>                                    
                                        Ownership
                                    </label>
                                </a>
                                {displayAssignedOwnerships()}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>                                   
        );
    }

    function displayCatalogTree() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                            padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    <Tree 
                                                        width='100%'
                                                        data={orgLearningObjectTree} 
                                                        openByDefault={false}
                                                        indent={40}
                                                        padding={10}
                                                        searchTerm={learningObjectTreeNodeName}
                                                        searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                        onSelect={(nodes) => {saveLearningObjectTreeSelectedNodes(nodes)}}         
                                                        disableEdit={false}                                    
                                                        disableDrag={false}                                    
                                                        disableDrop={false}                                    
                                                        disableMultiSelection={false}              
                                                        selection={catalogTreeNodes}                      
                                                    >
                                                    </Tree>
                                                </td>
                                                <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 24px 0px 24px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    {displaySelectedLearningObjectTree()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                            
                                </Card.Body>
                            </Card>
                        </td>
                    </tr>
                </tbody>
            </table>                                   
        );
    }

    function displayOrganization() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                    <Card.Title>
                                        <div>
                                            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h4 style={styles.SubHeader}>Organization Tree</h4></td>
                                                    <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                        <div className='input-field'>
                                                            <input id="searchDivisionNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchDivisionNodeName} placeholder='Search Division'/>
                                                        </div>                                            
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>                            
                                        </div>
                                    </Card.Title>
                                </Card.Header>                   
                                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                            padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    <Tree 
                                                        width='100%'
                                                        data={orgDivisions} 
                                                        openByDefault={false}
                                                        indent={40}
                                                        padding={10}
                                                        searchTerm={divisionNodeName}
                                                        searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                        onSelect={(nodes) => {treeSelectedNodes(nodes)}}         
                                                        disableEdit={false}                                    
                                                        disableDrag={false}                                    
                                                        disableDrop={false}                                    
                                                        disableMultiSelection={false}              
                                                        selection={catalogDivisions}                      
                                                    >
                                                    </Tree>
                                                </td>
                                                <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                            padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        {displaySelectedDivisions()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                            
                                </Card.Body>
                                <Card.Footer className="text-left">
                                </Card.Footer>
                            </Card>
                        </td>
                    </tr>
                </tbody>
            </table>                                   
        );
    }

    function displaySelectedDivisions() {
        const catalogDivisionArray = catalogDivisionDetails?.split('&'); 
        if ((catalogDivisionArray) && (catalogDivisionArray.length > 0) && (catalogDivisionArray[0].trim() !== '')) {
        return (
                <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <thead>
                    <tr>
                        <th style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Division(s)</h5></th>
                    </tr>
                </thead>
                <tbody>
                    {catalogDivisionArray.map((node, index) => {
                        return (
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]}</h6></td>
                        </tr>
                        )
                    })}
                </tbody>
            </Table>                                                    
            );
        }
    }

    function displaySelectedLearningObjectTree() {
        const catalogLearningObjectTreeArray = catalogTreeNodeDetails?.split('&'); 
        if ((catalogLearningObjectTreeArray) && (catalogLearningObjectTreeArray.length > 0) && (catalogLearningObjectTreeArray[0].trim() !== '')) {
        return (
                <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <thead>
                    <tr>
                        <th colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Tree Node(s)</h5></th>
                    </tr>
                </thead>
                <tbody>
                    {catalogLearningObjectTreeArray.map((node, index) => {
                        if ((addNewCatalog) || (editCatalog)) {
                            return (
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]?.split('|')[0]}</h6></td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleUpdateParentNodeClick(node?.split('=')[0], node?.split('=')[1])}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Select as a parent node'>table_convert</span>
                                        </a>
                                    </td>
                                </tr>
                            )
                        } else {
                            const iChildTreeCount = parseInt(node?.split('=')[1]?.split('|')[9]);
                            const iChildTreeLOCount = parseInt(node?.split('=')[1]?.split('|')[9]);
                            if ((iChildTreeCount === 0) && (iChildTreeLOCount === 0)) {
                                return (
                                    <tr>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]?.split('|')[0]}</h6></td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleUpdateChildernClick(node?.split('=')[0])}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Tree Node'>edit</span>
                                            </a>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleDeleteChildernClick(node?.split('=')[0])}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Tree Node'>delete</span>
                                            </a>
                                        </td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]?.split('|')[0]}</h6></td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleUpdateChildernClick(node?.split('=')[0])}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Tree Node'>edit</span>
                                            </a>
                                        </td>
                                    </tr>
                                )
                            }
                        }
                    })}
                </tbody>
            </Table>                                                    
            );
        }
    }

    function getSearchParamsEx(bRadioValue, bRadioTypeValue, pageNumber)  {
        let oParams = {
            LearningObjectTreeName:nodeName,
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 
        return oParams;
    }

    async function searchCatalog(bRadioValue, bRadioTypeValue) {
        setLoading(true);
        const oParams = getSearchParamsEx(bRadioValue, bRadioTypeValue, '1');
        await props.getCatalogs(oParams);
        setLoading(false);
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Catalog Tree</h3></td>
                <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchLearningObjectTreeNodeName} placeholder='Search Catalog Node'/>
                        {/* <a onClick={() => searchCatalog()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a> */}
                        {(permissionFlags.CanCreate) ? 
                            (addNewCatalog) ? 
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Add Catalog'>shadow_minus</span>
                                </a>
                                :
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Add Catalog'>note_add</span>
                                </a>
                        : 
                            <></>
                        }
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );    
    }

    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showOwnershipList} onShow={handleShowOwnershipList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseOwnershipList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseOwnershipList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewCatalog()} 
                            {displayCatalogTree()}                                               
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      orgLearningObjectTree: state.entity.orgLearningObjectTree,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        insertCatalog: (oParams) => dispatch(insertCatalog(oParams)),
        deleteCatalog: (oParams) => dispatch(deleteCatalog(oParams)),
        updateCatalog: (oParams) => dispatch(updateCatalog(oParams)),
        getLearningObjectTree: (id) => dispatch(getLearningObjectTree(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogList)