import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup, FormControl, CardFooter  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getKnowledgeSessionsByUser, getUserAttributesLookups, updateKnowledgeSessionObjective } from '../../store/actions/entityActions';
import { UserLookup, Rights, KnowledgeType, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant, 
        GradingType, LOLifeCycleStatus, AlertEnrollmentTimeOut, KnowledgeListType, KnowledgeSearchType,
        RetentasType} from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import AlertMessage from '../layout/AlertMessage';

const KnowledgeSessionObjectives = (props) => {
    const { auth, companyData, profile, knowledgesessions, myknowledgesessions, myteamknowledgesessions, userattributelookups, loadingMenu, listby, fromLookupParam,
        enrollmentMessage, listType, paramsForChildren } = props;    
    const { id } = useParams();
    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [parentParams, setParentParams] = useState(location?.state?.parentParams);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [actionVisible, setActionVisible] = useState(false);
    const [editKnowledgeSession, setEditKnowledgeSession] = useState(false);
    const [knowledgeSessionName, setKnowledgeSessionName] = useState('');
    const [knowledgeSessionID, setKnowledgeSessionID] = useState(-1);
    const [knowledgeSessionROMSID, setKnowledgeSessionROMSID] = useState(-1);
    const [objectiveComplete, setObjectiveComplete] = useState(-1);
    const [userSignOff, setUserSignOff] = useState(-1);
    const [dueDate, setDueDate] = useState(null);
    const [completionDate, setCompletionDate] = useState(null);
    const [assessmentCompletionDate, setAssessmentCompletionDate] = useState(null);
    const [skillName, setSkillName] = useState(null);
    const [objectives, setObjectives] = useState(null);
    const [score, setScore] = useState(null);
    const [assessmentScore, setAssessmentScore] = useState(null);
    const [objectiveURL, setObjectiveURL] = useState(null);
    const [managerComments, setManagerComments] = useState(null);
    const [attendeeComments, setAttendeeComments] = useState(null);

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const stateknowledgesessions = myknowledgesessions?.myknowledgesessions 
    const knowledgesession = stateknowledgesessions?.LearningObjectSessionROMS;

    const navigate = useNavigate();

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = stateknowledgesessions?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            UserID:auth?.UserID,
            LearningObjectSessionID:id,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };     
        await props.getKnowledgeSessionsByUser(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = stateknowledgesessions?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            UserID:auth?.UserID,
            LearningObjectSessionID:id,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };     
        await props.getKnowledgeSessionsByUser(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
     
    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += ',';
                }
                currentSelectedValue += row.LearningObjectSessionID;
            } 
        });
        setSelectedValue(currentSelectedValue);
        return currentSelectedValue;
    }
    
    function initialTabValue() {
        return 1;
    };

    async function searchKnowledgeTrackSessions(bRadioValue) {
        setLoading(true);
        setLoading(false);
    }
    
    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function searchKnowledgeSessions() {
        setLoading(true);
        setLoading(false);
    }
    
    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            LearningObjectInstanceID:kRec.LearningObjectInstanceID,
            LearningObjectSessionID:kRec.LearningObjectSessionID,
            takeAction:false,
            showDetails:false,
            editKnowledgeSessionClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledgesession);
        setSliderValue(initialScoreValues);
    }, [knowledgesession])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.LearningObjectSessionID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                    setKnowledgeSessionROMSID(-1);
                } else {
                    row.showDetails = true;
                    setKnowledgeSessionROMSID(rowIndex);
                }
            } else {
                row.showDetails = false;
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();        
    }

    async function handleSaveChildernClick(tab, currentEval, sSelectedIDs) {
        var bCanSave = true;

        if (bCanSave) {
            setLoading(true);
            const oParams  = {
                LearningObjectSessionID: knowledgeSessionID,
                LearningObjectSessionROMSID: knowledgeSessionROMSID,
                AttendeeComments:attendeeComments,
                UserObjectiveSignOff: (userSignOff === '1') ? true : false,
                CreatedByUserID: auth?.UserID,
                UpdatedByUserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
            };        
            console.log(oParams);
            if (knowledgeSessionROMSID !== -1) {
                await props.updateKnowledgeSessionObjective(oParams);    
                setEditKnowledgeSession(false);
            }
    
            console.log(paramsForChildren);
            const oKParams = {
                UserID:auth?.UserID,
                LearningObjectProcessStatusTypeID:LOLifeCycleStatus.Enrolled,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeSessionsByUser(oKParams);//paramsForChildren);
    
            setLoading(false);    
        }
    }

    async function handleUpdateChildernClick(id, mainid) {
        setLoading(true);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        console.log(id);
        const assEditedRec = knowledgesession?.filter((assRec) => (assRec.LearningObjectSessionROMSID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setKnowledgeSessionID(assEditedRec[0]?.LearningObjectSessionID);
            setKnowledgeSessionROMSID(assEditedRec[0]?.LearningObjectSessionROMSID);
            setObjectiveComplete(assEditedRec[0]?.ObjectivesComplete);
            setUserSignOff(assEditedRec[0]?.UserObjectiveSignOff ? '1' : '0');
            setDueDate(assEditedRec[0]?.DueDate);
            setCompletionDate(assEditedRec[0]?.ObjectiveCompletedDate);
            setAssessmentCompletionDate(assEditedRec[0]?.TestDateCompleted);
            setSkillName(assEditedRec[0]?.SkillName);
            setObjectives(assEditedRec[0]?.Objectives);
            setScore(assEditedRec[0]?.Score);
            setAssessmentScore(assEditedRec[0]?.TestScore);
            setObjectiveURL(assEditedRec[0]?.ObjectiveURL);
            setManagerComments(assEditedRec[0]?.ManagerComments);
            setAttendeeComments(assEditedRec[0]?.AttendeeComments);
        
            setEditKnowledgeSession(true);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(tab) {
        if (tab === 1) {
            if (knowledgeSessionID !== -1) {
                setEditKnowledgeSession(false);
            }
            ClearStateFields();    
        } else if (tab === 3) {
            setActionVisible(false);
        }
    }
    
    async function ClearStateFields() {
        setKnowledgeSessionID(-1);
        setKnowledgeSessionROMSID(-1);
    }

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Objective'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Objective'>Cancel</Button>
            </div>
        );
    }

    function displayTabContent() {
            return (
                <>
                    <table class="table table-sm caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="skillname" style={styles.LabelDataEntryNoPadding}>Skill</label>
                                    <input id="skillname" type="text" disabled style={styles.TextDataEntryNoPadding} value={skillName}/>
                                </td>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="objective" style={styles.LabelDataEntryNoPadding}>Objective</label>
                                    <input id="objective" type="text" disabled style={styles.TextDataEntryNoPadding} value={objectives}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="ourl" style={styles.LabelDataEntryNoPadding}>Objective URL</label>
                                    <input id="ourl" type="text" disabled style={styles.TextDataEntryNoPadding} value={objectiveURL}/>
                                </td>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="metric" style={styles.LabelDataEntryNoPadding}>Metric</label>
                                    <input id="metric" type="text" disabled style={styles.TextDataEntryNoPadding} value={objectives}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="ocomplete" style={styles.LabelDataEntryNoPadding}>Complete</label>
                                    <select id="ocomplete" disabled class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={objectiveComplete} >
                                        <option value='-1'>Select One</option>
                                        <option value='1'>Yes</option>
                                        <option value='0'>No</option>
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="oscore" style={styles.LabelDataEntryNoPadding}>Score</label>
                                    <input id="oscore" type="text" disabled style={styles.TextDataEntryNoPadding} value={score}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="assscore" style={styles.LabelDataEntryNoPadding}>Assessment Score</label>
                                    <input id="assscore" type="text" disabled style={styles.TextDataEntryNoPadding} value={assessmentScore}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="pcomments" style={styles.LabelDataEntryNoPaddingWBG}>Perpector Comments</label>
                                    <input id="pucomments" type="text" disabled style={styles.TextDataEntryNoPadding} value={managerComments}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="usignoff" style={styles.LabelDataEntryNoPaddingRequired}>User SignOff</label>
                                    <select id="usignoff" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={userSignOff}  onChange={(e) => (setUserSignOff(e.target.value))} >
                                        <option value='-1'>Select One</option>
                                        <option value='1'>Yes</option>
                                        <option value='0'>No</option>
                                    </select>                                     
                                </td>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="ucomments" style={styles.LabelDataEntryNoPaddingWBGRequired}>User Comments</label>
                                    <input id="ucomments" type="text" style={styles.TextDataEntryNoPadding} value={attendeeComments} onChange={(e) => (setAttendeeComments(e.target.value))}/>
                                </td>
                            </tr>       
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="duedate" style={styles.LabelDataEntryNoPadding}>Due Date</label>
                                    <input id="duedate" type="date" disabled style={styles.TextDataEntryNoPadding} value={dueDate?.substr(0,10)}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="comdate" style={styles.LabelDataEntryNoPadding}>Completion Date</label>
                                    <input id="comdate" type="date" disabled style={styles.TextDataEntryNoPadding} value={completionDate?.substr(0,10)}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="asscomdate" style={styles.LabelDataEntryNoPadding}>Assessment Completion Date</label>
                                    <input id="asscomdate" type="date" disabled style={styles.TextDataEntryNoPadding} value={assessmentCompletionDate?.substr(0,10)}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                </>
            );
    }

    function displayNewKnowledge() {
        if (editKnowledgeSession) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            Edit Objective
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                {displaySaveButtons()}
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }
   
    function displayAttributes() {
        return (
            <>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            Instance Logistics
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    </CardBody>
                </Card>
            </>
        );
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <h3 style={styles.SubHeader}>Objectives</h3>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
                <td style={{textAlign:'right', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Knowledge Track Session'/>
                        <a onClick={() => searchKnowledgeSessions()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );    
    }
   
    function displayKnowledgeSessionObjectives() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {displayMyTeamHeader()}
                    </Card.Title>
                    {(showAlertMessage) ?
                        <Card.Subtitle>
                            <div>
                                <h6><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} alertTimeout={AlertEnrollmentTimeOut} handleParentAlertClose={handleCloseAlert} /></h6>
                            </div>
                        </Card.Subtitle>
                        :
                            <></>
                        }
                </Card.Header>                   
                {(loading) ? (            
                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                        <Spinner/>
                    </div>
                ) 
                : 
                (                                          
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {displayNewKnowledge()}                                               
                    {((!knowledgesession) || (knowledgesession.length === 0)) ? (                                        
                        <div style={styles.tableText}>
                            No Knowledge Track Sessions.
                        </div>)
                        :
                        (
                            <Table responsive="sm" hover variant="light" style={styles.tableBGNoPadding}>
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableTextLeft}>Skill</th>
                                        <th style={styles.tableTextLeft}>Objectives</th>
                                        <th style={styles.tableText}>Due Date</th>
                                        <th style={styles.tableText}>Complete</th>
                                        <th style={styles.tableText}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {knowledgesession && knowledgesession.map((assRec, index) => {
                                        return (
                                            <>
                                            <tr>
                                                <td style={styles.tableText}>{index+1}</td>
                                                <td style={styles.tableTextLeft}>{assRec?.SkillName}</td>
                                                <td style={styles.tableTextLeft}>{assRec?.Objectives}</td>
                                                <td style={styles.tableText}>{(assRec?.DueDate) ? moment(assRec?.DueDate).format('MM/DD/YYYY') : ''}</td>
                                                <td style={styles.tableText}>{(assRec?.ObjectiveComplete) ? 'Yes' : 'No'}</td>
                                                <td style={styles.tableText}>
                                                    <a onClick={() => handleUpdateChildernClick(assRec?.LearningObjectSessionROMSID, assRec?.LearningObjectSessionID)}>
                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Objectives'>edit</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            </>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>                                                            
                        )
                    }
                </Card.Body>
                )}
                {/* <Card.Footer className="text-right" style={{alignItems:'end'}}>
                    <Table responsive="sm" style={styles.tableBGNoPadding}>
                        <tbody>
                            <tr>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left', padding:'0'}}>
                                    <h6 style={styles.tableTextLeft}>{stateknowledgesessions?.RecordFound?.toLocaleString()}   Tracks&nbsp;&nbsp;({getSelectedCount()} Selected)</h6>
                                </td>
                                <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center', padding:'0'}}>
                                    {(loading) ? <></> : 
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                        <h6 style={styles.tableText}>
                                        {(stateknowledgesessions?.PagingMetaData?.PreviousPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                    <span title='Previous Page'>Previous</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        {(stateknowledgesessions?.PagingMetaData?.NextPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                    <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        </h6>
                                        </div>
                                    }
                                </td>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right', padding:'0'}}>
                                    <h6 style={styles.tableTextRight}> Page   {stateknowledgesessions?.PagingMetaData?.PageNumber}  of  {stateknowledgesessions?.PagingMetaData?.TotalPage?.toLocaleString()}</h6>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Footer> */}
            </Card>
        );
    }

    return (
        <>
            {displayKnowledgeSessionObjectives()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
    //   knowledgesessions: state.entity.knowledgesessions, 
      myknowledgesessions: state.entity.myknowledgesessions, 
    //   myteamknowledgesessions: state.entity.mycalendar, 
      userattributelookups: state.entity.userattributelookups,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
      enrollmentMessage: state.entity.enrollmentMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledgeSessionsByUser: (kParams) => dispatch(getKnowledgeSessionsByUser(kParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        updateKnowledgeSessionObjective: (kParams) => dispatch(updateKnowledgeSessionObjective(kParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeSessionObjectives)