import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup, FormControl, Dropdown, DropdownMenu  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getKnowledges, getUserCalendar, getUserAttributesLookups, processEnrollment } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import { UserLookup, Rights, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant, LOLifeCycleStatus, KnowledgeListType, LILifeCycleStatus, KnowledgeType } from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import Attachment from '../layout/Attachment';
import AlertMessage from '../layout/AlertMessage';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const KnowledgeInstanceDetail = (props) => {
    const { auth, userDetail, companyData, profile, mycalendar, lookupselections, loadingMenu, id, enrollmentMessage, assignUserID} = props;    
    const { id:paramID } = useParams();
    const knowledgeInstanceIDPassed = id || paramID;
    const location  = useLocation();
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [parentParams, setParentParams] = useState(location?.state?.parentParams);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [nodeType, setnodeType] = useState('1');
    const [nodeTypeDescription, setnodeTypeDescription] = useState('Search Knowledge');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');

    const [knowledgeID, setKnowledgeID] = useState(id);
    const [knowledgeName, setKnowledgeName] = useState('');
    const [knowledgeInstanceID, setKnowledgeInstanceID] = useState(knowledgeInstanceIDPassed);
    const [knowledgeTypeID, setKnowledgeTypeID] = useState(-1);
    const [knowledgeCreditUnits, setKnowledgeCreditUnits] = useState('');
    const [knowledgeCreditHours, setKnowledgeCreditHours] = useState('');
    const [knowledgeCreditOthers, setKnowledgeCreditOthers] = useState('');
    const [knowledgeAuthorizerID, setKnowledgeAuthorizerID] = useState(null);
    const [maximumStudent, setMaximumStudent] = useState('');
    const [minimumStudent, setMinimumStudent] = useState('');
    const [signInOut, setSignInOut] = useState('');
    const [registrationCutOff, setRegistrationCutOff] = useState('');
    const [registrationCutOffHours, setRegistrationCutOffHours] = useState('');
    const [cancellationCutOff, setCancellationCutOff] = useState('');
    const [cancellationCutOffHours, setCancellationCutOffHours] = useState('');
    const [testTakeType, setTestTakeType] = useState('');
    const [guestSpeakers, setGuestSpeakers] = useState('');
    const [processStatusTypeID, setProcessStatusTypeID] = useState('');
    const [launchDate, setLaunchDate] = useState('');
    const [enrollmentPeriod, setEnrollmentPeriod] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [subjectCode, setSubjectCode] = useState('');
    const [notes, setNotes] = useState('');
    const [privateNotes, setPrivateNotes] = useState('');
    const [externalWebinarURL, setExternalWebinarURL] = useState('');
    const [advertisementOnly, setAdvertisementOnly] = useState('');
    const [featured, setFeatured] = useState('');
    const [preventTranscripLaunch, setPreventTranscripLaunch] = useState('');
    const [allowReprintCertificate, setAllowReprintCertificate] = useState('');
    const [reprintCertificationCost, setReprintCertificationCost] = useState('');
    const [allowEnrollmentExtension, setAllowEnrollmentExtension] = useState('');
    const [enrollmentExtensionCost, setEnrollmentExtensionCost] = useState('');
    const [allowMove, setAllowMove] = useState('');
    const [noShowCharge, setNoShowCharge] = useState('');
    const [cancellationCharge, setCancellationCharge] = useState('');
    const [promotionPrice, setPromotionPrice] = useState('');
    const [assignmentInstance, setAssignmentInstance] = useState('');
    const [forcePayment, setForcePayment] = useState('');
    const [prerequisiteDuringLaunch, setPrerequisiteDuringLaunch] = useState('');
    const [byPassSessionCap, setByPassSessionCap] = useState('');
    const [byPassValidation, setByPassValidation] = useState('');
    const [byPassNotification, setByPassNotification] = useState('');
    const [preventRetake, setPreventRetake] = useState(false);
    const [byPassDateTimeConflicts, setByPassDateTimeConflicts] = useState(false);
    const [enableMultiSession, setEnableMultiSession] = useState(false);
    const [byPassPrerequisites, setByPassPrerequisites] = useState(false);
    const [prerequisiteExpression, setPrerequisiteExpression] = useState('');
    const [forceEvaluation, setForceEvaluation] = useState(false);
    const [evaluationStartDays, setEvaluationStartDays] = useState(0);
    const [evaluationEndDays, setEvaluationEndDays] = useState(0);
    const [forceROMS, setForceROMS] = useState(false);
    const [evaluationFromTranscript, setEvaluationFromTranscript] = useState(false);
    const [includeWaitlist, setIncludeWaitlist] = useState(false);
    const [autoTransfer, setAutoTransfer] = useState(false);
    const [waitlistCap, setWaitlistCap] = useState(0);
    const [emailTo, setEmailTo] = useState('');
    const [otherEmails, setOtherEmails] = useState('');
    const [authorizeInstructorOnly, setAuthorizeInstructorOnly] = useState(false);
    const [byPassAuthorization, setByPassAuthorization] = useState(true);
    const [paymentModes, setPaymentModes] = useState('');
    const [instanceDateArray, setInstanceDateArray] = useState([]);
    const [instanceDateDetails, setInstanceDateDetails] = useState('');
    const [instanceDates, setInstanceDates] = useState('');
    const [instructors, setInstructors] = useState('');    
    const [instructorDetails, setInstructorDetails] = useState('');    
    const [instructorArray, setInstructorArray] = useState([]);    
    const [presenters, setPresenters] = useState('');    
    const [presenterDetails, setPresenterDetails] = useState('');    
    const [presenterArray, setPresenterArray] = useState([]);    
    const [jobPositions, setJobPositions] = useState('');
    const [jobPositionDetails, setJobPositionDetails] = useState('');    
    const [jobPositionArray, setJobPositionArray] = useState([]);    
    const [authorizationGroup, setAuthorizationGroup] = useState('');    
    const [authorizationGroupName, setAuthorizationGroupName] = useState('');    
    const [authorizationLevel, setAuthorizationLevel] = useState('');    
    const [authorizationNotificationMessageArray, setAuthorizationNotificationMessageArray] = useState([]);
    const [authorizationNotificationMessageDetails, setAuthorizationNotificationMessageDetails] = useState([]);
    const [authorizationNotificationMessages, setAuthorizationNotificationMessages] = useState('');    
    const [authorizationNotificationMessageNames, setAuthorizationNotificationMessageNames] = useState('');    
    const [authorizationDetails, setAuthorizationDetails] = useState('');    
    const [authorizationArray, setAuthorizationArray] = useState([]);    
    const [facilities, setFacilities] = useState('');    
    const [facilityRooms, setFacilityRooms] = useState('');    
    const [facilityNames, setFacilityNames] = useState('');    
    const [facilityDirection, setFacilityDirection] = useState('');    
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [resourceDetails, setResourceDetails] = useState('');    
    const [resourceArray, setResourceArray] = useState([]);    

    const [isAttachmentSectionDisabled, setIsAttachmentSectionDisabled] = useState(true);
    const [isAttachmentSectionVisible, setIsAttachmentSectionVisible] = useState(true);

    const [knowledgeDivisionDetails, setKnowledgeDivisionDetails] = useState('');
    const [knowledgeDivisions, setKnowledgeDivisions] = useState('');

    const [showInstanceDetail, setShowInstanceDetail] = useState(false);
    const handleCloseInstanceDetail = () => {setShowInstanceDetail(false);}

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const knowledgeinstance = mycalendar?.mycalendar?.LearningObjectInstance;
    const loiDates = mycalendar?.mycalendar?.LearningObjectInstanceDate;
    const loiInstructors = mycalendar?.mycalendar?.LearningObjectInstanceInstructor;
    const loiPaymentModes = mycalendar?.mycalendar?.LearningObjectInstancePaymentMode;
    const loiJobPositions = mycalendar?.mycalendar?.LearningObjectInstanceJobPosition;
    const loiAuthorizerGroups = mycalendar?.mycalendar?.LearningObjectInstanceAuthorizerGroup;
    const loiResources = mycalendar?.mycalendar?.LearningObjectInstanceResource;
    const availability = mycalendar?.mycalendar?.Availability;
    const supervisorData = userDetail?.UserSupervisor;

    const navigate = useNavigate();

    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Attributes', value: 1 },
        { name: 'Outline', value: 2 },
        { name: 'Attachments', value: 3 },
    ];

    function initialRadioValue() {
        return KnowledgeType.Classroom;
    };

    function initialTabValue() {
        return 1;
    };
     
    async function handleCancel() {
        setLoading(true);
        await props.getKnowledges(parentParams);
        setLoading(false);
        // navigate('/knowledgelist', {state: {permissiontypeid:permissionTypeID, listby:listByValue}});
    }

    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            LearningObjectInstanceID:kRec.LearningObjectInstanceID,
            takeAction:false,
            showDetails:false,
            editKnowledgeInstanceClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledgeinstance);
        setSliderValue(initialScoreValues);
    }, [knowledgeinstance])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  

    useEffect(() => {
        handleViewChildernClick(knowledgeInstanceID)
    }, [])  

    useEffect(() => {
        if ((enrollmentMessage) && (enrollmentMessage?.returnParams) && (enrollmentMessage?.returnParams?.length > 0) && 
            (!enrollmentMessage?.returnParams[0]?.ProcessSuccess) && 
            (enrollmentMessage?.returnParams[0]?.ProcessErrorDescription) && (enrollmentMessage?.returnParams[0]?.ProcessErrorDescription.trim() !== '')) {
            setAlertMessage(enrollmentMessage?.returnParams[0]?.ProcessErrorDescription.trim().replaceAll("###LINEBREAK###", "<br>"));
            setAlertVariant(AlertVariant.Danger);
            setShowAlertMessage(true);
        } else if ((enrollmentMessage) && (enrollmentMessage?.returnParams) && (enrollmentMessage?.returnParams?.length > 0) && 
            (enrollmentMessage?.returnParams[0]?.ProcessSuccess) && 
            (enrollmentMessage?.returnParams[0]?.ProcessDetailDescription) && (enrollmentMessage?.returnParams[0]?.ProcessDetailDescription.trim() !== '')) {
            setAlertMessage(enrollmentMessage?.returnParams[0]?.ProcessDetailDescription.trim().replaceAll("###LINEBREAK###", "<br>"));
            setAlertVariant(AlertVariant.Success);
            setShowAlertMessage(true);
        }
    }, [enrollmentMessage])  

    async function handleRadioChange(e) {
        setRadioValue(e.currentTarget.value);
    }
            
    function getHTMLFromEditor() {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    function setEditorHTML(isEmpty, value) {
        if (isEmpty) {
            setEditorState(EditorState.createEmpty());
        } else if (value === null) {
            setEditorState(EditorState.createEmpty());
        } else {
            const contentBlock = htmlToDraft(value);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const oEditorState = EditorState.createWithContent(contentState);
              setEditorState(oEditorState);
            }
        }
    }

    function UpdateChildrenState(id) {
        let dateIDs = '';
        const instanceDateRec = loiDates?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        instanceDateRec?.map((row, index) => {
            if (dateIDs.trim() !== '') {
                dateIDs += '&';
            }
            dateIDs += row?.StartDateTime + '|' + row?.EndDateTime + '|' + row?.TimeZone;
        });
        if (dateIDs !== '') {
            setInstanceDateDetails(dateIDs);
            setInstanceDateArray(dateIDs.split('&'));    
        } else {
            setInstanceDateDetails('');
            setInstanceDateArray([]);    
        }

        let instrIDs = '';
        let instrNames = '';
        let instrDetails = '';
        let presIDs = '';
        let presNames = '';
        let presDetails = '';
        const instrRec = loiInstructors?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        instrRec?.map((row, index) => {
            if (row?.OnlyPresenter) {
                if (presIDs.trim() !== '') {
                    presIDs += ',';
                }
                if (presDetails.trim() !== '') {
                    presDetails += '&';
                }
                presIDs += row?.InstructorUserID;
                presDetails += row?.InstructorUserID + '=' + row?.InstructorName + '|' + row?.InstructorVendorUserID + '|' + row?.InstructorEmail;    

            } else {
                if (instrIDs.trim() !== '') {
                    instrIDs += ',';
                }
                if (instrDetails.trim() !== '') {
                    instrDetails += '&';
                }
                instrIDs += row?.InstructorUserID;
                instrDetails += row?.InstructorUserID + '=' + row?.InstructorName + '|' + row?.InstructorVendorUserID + '|' + row?.InstructorEmail;    
            }
        });
        if (instrIDs.trim() !== '') {
            setInstructors(instrIDs);
        } else {
            setInstructors('');
        }
        if (instrDetails.trim() !== '') {
            const oSelections = instrDetails?.split('&');
            setInstructorArray(oSelections);
        } else {
            setInstructorArray([]);
        }

        if (presIDs.trim() !== '') {
            setPresenters(presIDs);
        } else {
            setPresenters('');
        }
        if (presDetails.trim() !== '') {
            const oSelections = presDetails?.split('&');
            setPresenterArray(oSelections);
        } else {
            setPresenterArray([]);
        }

        let jpIDs = '';
        let jpNames = '';
        let jpDetails = '';
        const jpRec = loiJobPositions?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        jpRec?.map((row, index) => {
            if (jpIDs.trim() !== '') {
                jpIDs += ',';
            }
            if (jpDetails.trim() !== '') {
                jpDetails += '&';
            }
            jpIDs += row?.JobPositionID;
            jpDetails += row?.JobPositionID + '=' + row?.JobPositionDescription?.replaceAll("&", "and");    
        });
        if (jpIDs.trim() !== '') {
            setJobPositions(jpIDs);
        } else {
            setJobPositions([]);
        }
        if (jpDetails.trim() !== '') {
            const oSelections = jpDetails?.split('&');
            setJobPositionArray(oSelections);
        } else {
            setJobPositionArray([]);
        }

        let authDetails = '';
        const authRec = loiAuthorizerGroups?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        authRec?.map((row, index) => {
            if (authDetails.trim() !== '') {
                authDetails += '&';
            }
            authDetails += row?.GroupID + '|' + row?.GroupName?.replaceAll("&", "and") + '|' + row?.LevelNumber + '|' + row?.NotificationMessageID + '|' + row?.NotificationMessageName?.replaceAll("&", "and");    
        });
        if (authDetails.trim() !== '') {
            const oSelections = authDetails?.split('&');
            setAuthorizationArray(oSelections);
            setAuthorizationDetails(authDetails);
        } else {
            setAuthorizationArray([]);
            setAuthorizationDetails('');
        }

        let resDetails = '';
        const resRec = loiResources?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        resRec?.map((row, index) => {
            if (resDetails.trim() !== '') {
                resDetails += '&';
            }
            resDetails += row?.ResourceTypeID + '|' + row?.ResourceTypeName?.replaceAll("&", "and") + '|' + row?.NumberOfUnits + '|' + row?.TotalPrice + '|' + row?.NumberOfUnitsAllocated + '|' + row?.DueDate + '|' + row?.CompletedDate;    
        });
        if (resDetails.trim() !== '') {
            const oSelections = resDetails?.split('&');
            setResourceArray(oSelections);
            setResourceDetails(resDetails);
        } else {
            setResourceArray([]);
            setResourceDetails('');
        }

        let pmIDs = '';
        const pmRec = loiPaymentModes?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        pmRec?.map((row, index) => {
            if (pmIDs.trim() !== '') {
                pmIDs += ',';
            }
            pmIDs += row?.PaymentModeID;
        });
        if (pmIDs != '') {
            setPaymentModes(pmIDs);
        } else {            
            setPaymentModes('');
        }

        let divisionIds = '';
        let divisionDetails = '';
        const availabilityRec = availability?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        availabilityRec?.map((row, index) => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += row?.DivisionID;
            divisionDetails += row?.DivisionID + '=' + row?.DivisionNamePath;
        });
        if (divisionIds) {
            // setSelectedNodes(nodes);
            setKnowledgeDivisions(divisionIds);
            setKnowledgeDivisionDetails(divisionDetails);
        } else {
            setKnowledgeDivisions('');
            setKnowledgeDivisionDetails('');
        }
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        // Only for Attachment Tab
        if (tab === 3) {
            setIsAttachmentSectionDisabled(true);
            setIsAttachmentSectionVisible(true);
        }

        displayTabContent();
    };

    async function handleShowInstanceDetail() {
        setShowInstanceDetail(true); 
    };

    async function handleEnrollClick(enrollType) {
        setLoading(true);
        let bCanEnroll = true;
        if ((byPassAuthorization) && (byPassAuthorization === '0') && ((!knowledgeAuthorizerID) || (knowledgeAuthorizerID === '-1')))  {
            setAlertMessage('An authorizer is required for this enrollment.');
            setAlertVariant(AlertVariant.Warning);
            setShowAlertMessage(true);
            bCanEnroll = false;
        } 

        let sSelectedIDs = '';
        let sAuthorizerIDs = '';

        if (enrollType === 1) {
            if ((assignUserID) && (assignUserID > 0)) {
                sSelectedIDs = assignUserID;
            } else {
                sSelectedIDs = auth?.UserID;
            }
            if ((byPassAuthorization) && (byPassAuthorization === '0') && ((!knowledgeAuthorizerID) || (knowledgeAuthorizerID !== '-1')))  {
                sAuthorizerIDs = knowledgeAuthorizerID;    
            }
        }

        if (bCanEnroll) {
            const oParams  = [{
                LearningObjectID: knowledgeID,
                LearningObjectInstanceID: knowledgeInstanceID,
                LearningObjectSessionID: -1,
                LearningObjectTransactionID:-1,
                AssignmentSessionID: -1,
                Attendees: sSelectedIDs,
                Authorizers: '',
                Status:LOLifeCycleStatus.Enrolled,
                IsStartEnrollment: true,
                IsProcessEnrollment: false,
                CreatedByUserID: auth?.UserID,
                UpdatedByUserID: auth?.UserID,
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
            }];        
            console.log('Start Enrollment', oParams);
            await props.processEnrollment(oParams);    
        }
        setLoading(false);
    };

    async function handleViewChildernClick(id) {
        setLoading(true);
        setKnowledgeInstanceID(id);

        const assEditedRec = knowledgeinstance?.filter((assRec) => (assRec.LearningObjectInstanceID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setKnowledgeInstanceID(assEditedRec[0]?.LearningObjectInstanceID);
            setKnowledgeTypeID(assEditedRec[0]?.LearningObjectTypeID);
            setKnowledgeName(assEditedRec[0]?.LearningObjectName);
            setAdvertisementOnly((assEditedRec[0]?.AdvertisementOnly) ? '1' : '0');
            setAllowEnrollmentExtension((assEditedRec[0]?.AllowExtention) ? '1' : '0');
            setAllowReprintCertificate((assEditedRec[0]?.AllowReprintCertificate) ? '1' : '0');
            setTestTakeType((assEditedRec[0]?.AssessmentTakeType) ? '1' : '0');
            setAssignmentInstance((assEditedRec[0]?.AssignmentInstance) ? '1' : '0');
            setAuthorizeInstructorOnly((assEditedRec[0]?.AuthorizeByInstructorOnly) ? '1' : '0');
            setByPassDateTimeConflicts((assEditedRec[0]?.ByPassDateTimeConflicts) ? '1' : '0');
            setByPassNotification((assEditedRec[0]?.ByPassEmailNotification) ? '1' : '0');
            setByPassPrerequisites((assEditedRec[0]?.ByPassPrerequisiteChecking) ? '1' : '0');
            setByPassValidation((assEditedRec[0]?.ByPassValidationCheck) ? '1' : '0');
            setCancellationCharge(assEditedRec[0]?.CancellationCost);
            setCancellationCutOff((assEditedRec[0]?.CancellationCutOff) ? '1' : '0');
            setCancellationCutOffHours(assEditedRec[0]?.CancellationCutOffHours);
            setSignInOut((assEditedRec[0]?.ClockedInOut) ? '1' : '0');
            setByPassAuthorization((assEditedRec[0]?.DisableAuthorization) ? '1' : '0');
            setEmailTo(assEditedRec[0]?.EmailToTypeID);
            setEnableMultiSession((assEditedRec[0]?.EnableStudentMultiSessions) ? '1' : '0');
            setRegistrationCutOff((assEditedRec[0]?.EnrollmentCutOff) ? '1' : '0');
            setRegistrationCutOffHours(assEditedRec[0]?.EnrollmentCutOffHours);
            setEnrollmentExtensionCost(assEditedRec[0]?.ExtentionCost);
            setEnrollmentPeriod(assEditedRec[0]?.Duration);
            setEvaluationEndDays(assEditedRec[0]?.EvaluationEndDays);
            setEvaluationFromTranscript((assEditedRec[0]?.EvaluationFromTranscript) ? '1' : '0');
            setEvaluationStartDays(assEditedRec[0]?.EvaluationStartDays);
            setExpirationDate(assEditedRec[0]?.ExpiryDate);
            setExternalWebinarURL(assEditedRec[0]?.ExternalWebAttendeeURL);
            setFeatured((assEditedRec[0]?.Featured) ? '1' : '0');
            setForceEvaluation((assEditedRec[0]?.ForceEvaluation) ? '1' : '0');
            setForcePayment((assEditedRec[0]?.ForcePayment) ? '1' : '0');
            setForceROMS((assEditedRec[0]?.ForceROMS) ? '1' : '0');
            setGuestSpeakers(assEditedRec[0]?.GuestSpeakers);
            setByPassSessionCap((assEditedRec[0]?.IgnoreSessionCap) ? '1' : '0');
            setInstanceDates(assEditedRec[0]?.InstanceDates);
            setLaunchDate(assEditedRec[0]?.LaunchDate);
            setProcessStatusTypeID(assEditedRec[0]?.LearningObjectProcessStatusTypeID);
            setMaximumStudent(assEditedRec[0]?.MaxStudents);
            setMinimumStudent(assEditedRec[0]?.MinStudents);
            setAllowMove((assEditedRec[0]?.MoveNoShows) ? '1' : '0');
            setNoShowCharge(assEditedRec[0]?.NoShowCost);
            setPreventTranscripLaunch((assEditedRec[0]?.NoTranscriptLaunch) ? '1' : '0');
            setOtherEmails(assEditedRec[0]?.OtherEmails);
            setPaymentModes(assEditedRec[0]?.PaymentModes);
            setPresenters(assEditedRec[0]?.Presenters);
            setPrerequisiteDuringLaunch((assEditedRec[0]?.PrerequisiteForLaunch) ? '1' : '0');
            setPrerequisiteExpression(assEditedRec[0]?.PrerequisitesExpression);
            setPreventRetake((assEditedRec[0]?.PreventRetake) ? '1' : '0');
            setPrivateNotes(assEditedRec[0]?.PrivateDescription);
            setPromotionPrice(assEditedRec[0]?.PromoPrice);
            setNotes(assEditedRec[0]?.PublicDescription);
            setReprintCertificationCost(assEditedRec[0]?.ReprintCertificateCost);
            setSubjectCode(assEditedRec[0]?.SubjectCode);
            setAutoTransfer((assEditedRec[0]?.WaitlistAutoTransfer) ? '1' : '0');
            setWaitlistCap(assEditedRec[0]?.WaitlistCap);
            setFacilities(assEditedRec[0]?.FacilityID);
            setFacilityRooms(assEditedRec[0]?.FacilityRoomID);
            setFacilityNames(assEditedRec[0]?.FullFacilityName);
            setKnowledgeCreditUnits(assEditedRec[0]?.CreditUnits);
            setKnowledgeCreditHours(assEditedRec[0]?.CreditHours);
            setKnowledgeCreditOthers(assEditedRec[0]?.CreditOthers);
            setEditorHTML(false, assEditedRec[0]?.LearningObjectOutline)
            UpdateChildrenState(id);           
        }    
        setShowInstanceDetail(true);
        setLoading(false);
    }

    async function ClearStateFields() {
        setKnowledgeInstanceID(-1);
        setAdvertisementOnly(null);
        setAllowEnrollmentExtension(null);
        setAllowReprintCertificate(null);
        setTestTakeType(null);
        setAssignmentInstance(null);
        setAuthorizeInstructorOnly(null);
        setByPassDateTimeConflicts(null);
        setByPassNotification(null);
        setByPassPrerequisites(null);
        setByPassValidation(null);
        setCancellationCharge(null);
        setCancellationCutOff(null);
        setCancellationCutOffHours(null);
        setSignInOut(null);
        setByPassAuthorization(null);
        setEmailTo(null);
        setEnableMultiSession(null);
        setRegistrationCutOff(null);
        setRegistrationCutOffHours(null);
        setEnrollmentExtensionCost(null);
        setEnrollmentPeriod(null);
        setEvaluationEndDays(null);
        setEvaluationFromTranscript(null);
        setEvaluationStartDays(null);
        setExpirationDate(null);
        setExternalWebinarURL(null);
        setFeatured(null);
        setForceEvaluation(null);
        setForcePayment(null);
        setForceROMS(null);
        setGuestSpeakers(null);
        setByPassSessionCap(null);
        setInstructors(null);
        setInstanceDates(null);
        setKnowledgeInstanceID(null);
        setLaunchDate(null);
        setProcessStatusTypeID(null);
        setMaximumStudent(null);
        setMinimumStudent(null);
        setAllowMove(null);
        setNoShowCharge(null);
        setPreventTranscripLaunch(null);
        setOtherEmails(null);
        setPaymentModes(null);
        setPresenters(null);
        setPrerequisiteDuringLaunch(null);
        setPrerequisiteExpression(null);
        setPreventRetake(null);
        setPrivateNotes(null);
        setPromotionPrice(null);
        setNotes(null);
        setReprintCertificationCost(null);
        setSubjectCode(null);
        setAutoTransfer(null);
        setWaitlistCap(null);        
        setInstanceDates('');
        setInstanceDateDetails('');
        setInstanceDateArray([]);
        setInstructors('');
        setInstructorDetails('');
        setInstructorArray([]);
        setPresenters('');
        setPresenterDetails('');
        setPresenterArray([]);
        setJobPositions('');
        setJobPositionDetails('');
        setJobPositionArray([]);
        setAuthorizationGroup('');
        setAuthorizationGroupName('');
        setAuthorizationLevel(null);                
        setAuthorizationNotificationMessages(null);                
        setAuthorizationNotificationMessageNames(null);                
        setAuthorizationNotificationMessageDetails('');                
        setAuthorizationNotificationMessageArray([]);                
        setAuthorizationDetails('');
        setAuthorizationArray([]);
        setResourceDetails('');
        setResourceArray([]);
        setFacilities(null);                
        setFacilityRooms(null);                
        setFacilityNames(null);                
        setKnowledgeCreditUnits(null);
        setKnowledgeCreditHours(null);
        setKnowledgeCreditOthers(null);
    }

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleEnrollClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Enroll Self'>Enroll</Button>&nbsp;&nbsp;
                {((profile?.IsManager) && (profile?.IsManager === 'True') && ((!assignUserID) || (assignUserID <= 0))) ? 
                    <Button onClick={() => handleEnrollClick(2)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Enroll My Team'>Enroll My Team</Button>
                :
                    <></>
                }
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radios.map((radio, idx) => (
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        } else if (tabValue === 2 ) {
            return (
                <div>
                    {displayOutline()}
                </div>
            );
        } else if (tabValue === 3 ) {
            return (
                <div>
                    {displayAttachment()}
                </div>
            );
        } 
    }

    function displayInstanceDetail() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Title style={styles.tableSubHeaderCenterBold}>
                        Knowledge Details 
                    </Card.Title>
                    <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                        <tbody>
                        <tr>
                            <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                {/* {displayTabButtons()} */}
                            </td>
                        </tr>
                        </tbody>
                    </Table>    
                </CardHeader>                                        
                <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {displayTabContent()}
                </CardBody>
                <Card.Footer>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-auto">
                            {displaySaveButtons()}
                        </div>
                    </div>
                </Card.Footer>
            </Card>
        );
    }

    function displayAssignedInstructors() {
        if (instructorArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {instructorArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedPresenters() {
        if (presenterArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {presenterArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayNewInstanceDates() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {((instanceDateArray) && (instanceDateArray.length > 0)) ? 
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextMain}>Start Date</th>
                                    <th style={styles.tableText}>End Date</th>
                                    <th style={styles.tableText}>Time Zone</th>
                                </tr>
                            </thead>                            
                            <tbody>
                                {(instanceDateArray && instanceDateArray.map((oItem, index) => {
                                    return (<tr>
                                        <td style={styles.tableText}>{index+1}</td>
                                        <td style={styles.tableText}>{moment(oItem.split('|')[0]).format('MM/DD/YYYY hh:mm a')}</td>
                                        <td style={styles.tableText}>{moment(oItem.split('|')[1]).format('MM/DD/YYYY hh:mm a')}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                    </tr>)
                                }))
                                }
                            </tbody>
                        </table>
                    :
                        <></>
                    }                        
                </CardBody>
            </Card>
        )
    }

    function displayAttributes() {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table table-sm caption-top text-left">  
                        <tbody> 
                            {(knowledgeTypeID === parseInt(KnowledgeType.Classroom)) ?
                                <>
                                <tr>
                                    <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label style={styles.LabelDataEntryNoPadding}>Dates</label>
                                        {displayNewInstanceDates()}
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <label style={styles.LabelDataEntryNoPadding}>Instructors</label>
                                            {displayAssignedInstructors()}
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <label style={styles.LabelDataEntryNoPadding}>Presenters</label>
                                            {displayAssignedPresenters()}
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="guestspeakers" style={styles.LabelDataEntryNoPaddingWBG}>Guest Speakers</label>
                                        <input id="guestspeakers" type="text" readOnly style={styles.TextDataEntryNoPadding} defaultValue={guestSpeakers} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label style={styles.LabelDataEntryNoPadding}>Facility Room</label>
                                        <input id="facroom" type="text" required readOnly style={styles.TextDataEntryNoPadding} defaultValue={facilityNames}/>                                    
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label style={styles.LabelDataEntryNoPadding}>Facility Directions</label>
                                        <input id="facroom" type="text" required readOnly style={styles.TextDataEntryNoPadding} defaultValue={facilityDirection}/>                                    
                                    </td>
                                </tr>       
                                </>
                            :
                                <></>
                            }
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="creditunits" style={styles.LabelDataEntryNoPaddingWBG}>Credit Units</label>
                                    <input id="creditunits" type="number" min='1' max='100' readOnly style={styles.TextDataEntryNoPaddingWBG} defaultValue={knowledgeCreditUnits}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="credithours" style={styles.LabelDataEntryNoPaddingWBG}>Credit Hours</label>
                                    <input id="credithours" type="number" min='1' max='100' readOnly style={styles.TextDataEntryNoPaddingWBG} defaultValue={knowledgeCreditHours}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="creditothers" style={styles.LabelDataEntryNoPaddingWBG}>Credit Others</label>
                                    <input id="creditothers" type="number" min='1' max='100' readOnly style={styles.TextDataEntryNoPaddingWBG} defaultValue={knowledgeCreditOthers}/>
                                </td>
                            </tr>
                            {((byPassAuthorization) && (byPassAuthorization === '1')) ? 
                                <></>
                            :
                                <tr>
                                    <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="agroups" style={styles.LabelDataEntryNoPaddingWBGRequired}>Authorizer</label>
                                        <select id="agroups" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100WBG} onChange={(e) => (setKnowledgeAuthorizerID(e.target.value))}>
                                            <option value='-1'>Select One</option>
                                            {supervisorData?.map((oItem, iIndex) => (
                                                <option id={oItem?.SupervisorID} value={oItem?.SupervisorID}>{oItem.SupervisorFullName} - {oItem.SupervisorEmail}</option>        
                                            ))}
                                        </select>                                     
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>                                   
                    </CardBody>
                </Card>
            );    
    }

    function displayOutline() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div style={{height:'300px', overflowY: 'auto'}}>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    readOnly={true}
                                />
                            </div>
                        </td>
                    </tr>  
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="notes" style={styles.LabelDataEntryNoPadding}>Notes</label>
                            <FormControl as='textarea' rows={5} id="notes" readOnly style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} defaultValue={notes} />
                        </td>
                    </tr>                
                </tbody>
            </table>                                   
        );
    }

    function displayAttachment() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <Attachment isAttachmentVisible={isAttachmentSectionVisible} isAttachmentDisable={isAttachmentSectionDisabled} iAttachmentTypeID={AttachmentType.Course} iEntityRowTypeID={EntityRowType.Knowledge} iEntityID={knowledgeID}/>
                        </td>
                    </tr>                
                </tbody>
            </table>                                   
        );
    }

    function displayListRadioButtons() {
        return (
            radios.map((radio, idx) => (
                <option value={radio.value}>{radio.name}</option>
            ))
        );
    }
        
    return (
        <>
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                {(loading) ? (            
                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                        <Spinner/>
                    </div>
                ) 
                : 
                (                                          
                <>
                <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Subtitle>
                    {(showAlertMessage) ?
                        <div>
                            <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} handleParentAlertClose={handleCloseAlert} /></h5>
                        </div>
                        :
                            <></>
                        }
                    </Card.Subtitle>
                    <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                        <tbody>
                        <tr>
                            <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                {displayTabButtons()}
                            </td>
                        </tr>
                        </tbody>
                    </Table>    
                </CardHeader>                                        
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {displayTabContent()}
                </Card.Body>
                <Card.Footer>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-auto">
                            {displaySaveButtons()}
                        </div>
                    </div>
                </Card.Footer>
                </>
                )}
        </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state?.auth?.user,
      userDetail: state?.auth?.userDetail,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      mycalendar: state.entity.mycalendar, 
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
      enrollmentMessage: state.entity.enrollmentMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledges: (kParams) => dispatch(getKnowledges(kParams)),
        getUserCalendar: (kParams) => dispatch(getUserCalendar(kParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        processEnrollment: (oParams) => dispatch(processEnrollment(oParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeInstanceDetail)