import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, ListGroup, Badge, Table, Image, ButtonGroup, ToggleButton, Form, Modal, CardBody, FormControl } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getUserAttributesLookups, assignLookupSelection, getFacilities, insertFacility, deleteFacility, updateFacility,  
    insertFacilityRoom, deleteFacilityRoom, updateFacilityRoom } from '../../store/actions/entityActions';
import { getUserLookup } from '../../store/actions/authActions';
import { connect, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { UserLookup, Rights, MenuType } from '../../config/Constant';
import { getPermissionFlags } from '../layout/Utils';
import UserLookupList from './UserLookupList';

const FacilityList = (props) => {
    const { auth, companyData, facilities, userattributelookups, fromLookupParam, lookupselections, loadingMenu } = props;
    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    const facility = facilities?.facilities?.Facilities;
    const FacilityRoom = facilities?.facilities?.FacilityRooms;

    const trainingVendorTypeData = userattributelookups?.TrainingVendorTypes;
    const stateData = userattributelookups?.States;
    const [stateCountryData , setStateCountryData] = useState(userattributelookups?.Countries?.filter((assRec) => (assRec.CountryCode.trim() === 'USA')));
    const countryData = userattributelookups?.Countries;

    const [loading, setLoading] = useState(false);
    const [showDeleteAlertModal, setshowDeleteAlertModal ] = useState(false);
    const [deleteSuggestion, setdeleteSuggestion] = useState([]);
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [addNewFacility, setAddNewFacility] = useState(false);
    const [editFacility, setEditFacility] = useState(false);
    const [facilityID, setFacilityID] = useState(-1);
    const [facilityName, setFacilityName] = useState('');
    const [facilityDescription, setFacilityDescription] = useState('');
    const [trainingFacility, setTrainingFacility] = useState(-1);
    const [facilityEmail, setFacilityEmail] = useState('');
    const [facilityPhone, setFacilityPhone] = useState('');
    const [facilityFax, setFacilityFax] = useState('');
    const [facilityAddress, setFacilityAddress] = useState('');
    const [facilityCity, setFacilityCity] = useState('');
    const [facilityZipCode, setFacilityZipCode] = useState('');
    const [facilityState, setFacilityState] = useState('');
    const [facilityCountry, setFacilityCountry] = useState('USA');
    
    const [addNewFacilityRoom, setAddNewFacilityRoom] = useState(false);
    const [editFacilityRoom, setEditFacilityRoom] = useState(false);
    const [facilityRoomID, setFacilityRoomID] = useState(-1);
    const [facilityRoomName, setFacilityRoomName] = useState('');
    const [facilityRoomActive, setFacilityRoomActive] = useState(-1);
    const [facilityRoomNumber, setFacilityRoomNumber] = useState('');
    const [facilityRoomMaximumCapacity, setFacilityRoomMaximumCapacity] = useState('');
    const [facilityRoomContactName, setFacilityRoomContactName] = useState('');
    const [facilityRoomContactPhone, setFacilityRoomContactPhone] = useState('');
    const [facilityRoomContactEmail, setFacilityRoomContactEmail] = useState('');
    const [facilityRoomDescription, setFacilityRoomDescription] = useState('');
    const [facilityRoomDirections, setFacilityRoomDirections] = useState('');

    async function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }
    
    async function searchFacility() {
        setLoading(true);
        const oParams = {
            FacilityName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getFacilities(oParams);
        setLoading(false);
    }
    
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = facilities?.facilities?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            FacilityName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getFacilities(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = facilities?.facilities?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            FacilityName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getFacilities(oParams);
        setLoading(false);
    }

    function handleDeleteAlertModalClose() { 
        setshowDeleteAlertModal(false);
    }
    
    function handleDeleteAlertModalShow(suggestion) { 
        setshowDeleteAlertModal(true);
        setdeleteSuggestion(suggestion);
    }

    async function handleDeleteAlertModalYes() { 
        setshowDeleteAlertModal(false);
    }

    function handleDeleteAlertModalNo() { 
        setshowDeleteAlertModal(false);
    }    

    let currentRecIndex = 0;

    const [sliderValue, setSliderValue] = useState([]);
    const [subSliderValue, setSubSliderValue] = useState([]);

    const addInitialSliderArray = (oList) => {
        return facility?.map((oDetail) => ({
            FacilityID:oDetail.FacilityID, 
            FacilityName: oDetail.FacilityName,
            addFacilityClicked: false,
            editFacilityClicked: false,
            addFacilityRoomClicked: false,
            editFacilityRoomClicked: false,
            takeAction:false,
            showDetails:false,
        }))
    }
        
    const addInitialSubSliderArray = (oList) => {
        return FacilityRoom?.map((oDetail) => ({
            FacilityID:oDetail.FacilityID, 
            FacilityName: oDetail.FacilityName,
            RoomID:oDetail.RoomID, 
            RoomName: oDetail.RoomName,
            showDetails:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(facility);
        setSliderValue(initialScoreValues);
    }, [facility])

    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSubSliderArray(FacilityRoom);
        setSubSliderValue(initialScoreValues);
    }, [FacilityRoom])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  

    useEffect(() => {
         setStateCountryData(stateData?.filter((assRec) => (assRec.CountryCode.trim() === facilityCountry.trim())));
    }, [facilityCountry])    

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;
        sliderValue && sliderValue?.map((row, index) => {
            if ((row) && (parseInt(row?.FacilityID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
  
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.FacilityID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                    setFacilityID(-1);
                } else {
                    row.showDetails = true;
                    setFacilityID(rowIndex);
                }
            } else {
                row.showDetails = false;
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function getSubDetailSwitchValue(rowIndex) {
        let bReturn = false;
        subSliderValue && subSliderValue.map((row, index) => {
            if ((row) && (parseInt(row.RoomID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
  
    function handleSwitchSubDetailChange(rowIndex, rowName) {
        const updatedSubSliderValues = subSliderValue.slice(0);
        updatedSubSliderValues.map((row, index) => {
            if (row.RoomID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSubSliderValue(updatedSubSliderValues);
        getSelectedValue();
    }

     function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        subSliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.FacilityID + '|' + row.RoomID + '=' + row.FacilityName.replaceAll("&", "and") + '|' + row.RoomName.replaceAll("&", "and");
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.Facility, currentSelectedValue);
        return currentSelectedValue;
    }

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewFacility) ? setAddNewFacility(false) : setAddNewFacility(true);
            setAddNewFacilityRoom(false);
        } else if (tab === 2) {
            (addNewFacilityRoom) ? setAddNewFacilityRoom(false) : setAddNewFacilityRoom(true);
            setAddNewFacility(false);
        } 

        setLoading(false);
    }

    async function handleSaveChildernClick(tab, currentJP) {
        setLoading(true);
        const oParams = {
            FacilityName:nodeName,
            RecordSize:'10',
            PageNumber:1,
        };            

        if (tab === 1) {
            const oIParams = {
                FacilityID: facilityID,
                FacilityName: facilityName,
                FacilityDescription: facilityDescription,
                DivisionID: trainingFacility,
                Address: facilityAddress,
                City:facilityCity,
                State: facilityState,
                ZipCode: facilityZipCode,
                Phone: facilityPhone,
                Email: facilityEmail,
                CountryCode: facilityCountry,
                Fax: facilityFax,
                IsDeleted: false,
                CreatedByUserID:auth?.UserID,
                UpdatedByUserID:auth?.UserID
            }
            if (addNewFacility) {
                console.log(oIParams);
                await props.insertFacility(oIParams);
                setAddNewFacility(false);
            } else if (editFacility) {
                console.log('Update - ', oIParams);
                await props.updateFacility(oIParams);
                setEditFacility(false);
            }
            await props.getFacilities(oParams);
            ClearStateFields(tab);
        } 
        else if (tab === 2) {
            const oIParams = {
                FacilityID: facilityID,
                RoomID: facilityRoomID, 
                RoomName: facilityRoomName,
                RoomDescription: facilityRoomDescription,
                RoomNumber: facilityRoomNumber,
                IsDeleted: (facilityRoomActive == '1' ? true : false),
                ContactName: facilityRoomContactName,                
                ContactPhone: facilityRoomContactPhone,                
                ContactEmail: facilityRoomContactEmail,                
                RoomDirections: facilityRoomDirections,                
                MaximumCapacity: facilityRoomMaximumCapacity,                
                CreatedByUserID:auth?.UserID,
                UpdatedByUserID:auth?.UserID
            }
            if (addNewFacilityRoom) {
                console.log(oIParams);
                await props.insertFacilityRoom(oIParams);
                setAddNewFacilityRoom(false);
            } else if (editFacilityRoom) {
                console.log('Update - ', oIParams);
                await props.updateFacilityRoom(oIParams);
                setEditFacilityRoom(false);
            }
            await props.getFacilities(oParams);
            ClearStateFields(tab);
        } 
        setLoading(false);
    }

    async function handleDeleteChildernClick(tab, currentJP, id, otherId) {
        setLoading(true);
        const oParams = {
            FacilityName:nodeName,
            RecordSize:'10',
            PageNumber:1,
        };            

        if (tab === 1) {
            const oDParams = {
                FacilityID: id,
            }
            console.log(oDParams);
            await props.deleteFacility(oDParams);
            await props.getFacilities(oParams);
        } else if (tab === 2) {
            const oDParams = {
                RoomID: id,
                FacilityID: otherId,
            }
            console.log(oDParams);
            await props.deleteFacilityRoom(oDParams);
            await props.getFacilities(oParams);
        } 
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const assEditedRec = facility?.filter((assRec) => (assRec.FacilityID === id));
            console.log(assEditedRec);
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setFacilityID(id);
                setFacilityName(assEditedRec[0]?.FacilityName);
                setFacilityDescription(assEditedRec[0]?.FacilityDescription);
                setTrainingFacility(assEditedRec[0]?.DivisionID);
                setFacilityAddress(assEditedRec[0]?.Address);
                setFacilityState(assEditedRec[0]?.State);
                setFacilityCity(assEditedRec[0]?.City);
                setFacilityZipCode(assEditedRec[0]?.ZipCode);
                setFacilityCountry(assEditedRec[0]?.CountryCode);
                setFacilityFax(assEditedRec[0]?.Fax);
                setEditFacility(true);
            }    
        } else if (tab === 2) {
            const assEditedRec = FacilityRoom?.filter((assRec) => (assRec.RoomID === id));
            console.log(assEditedRec);
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setFacilityID(assEditedRec[0]?.FacilityID);
                setFacilityRoomID(assEditedRec[0]?.RoomID);
                setFacilityRoomName(assEditedRec[0]?.RoomName);
                setFacilityRoomDescription(assEditedRec[0]?.RoomDescription);
                setFacilityRoomDirections(assEditedRec[0]?.RoomDirections);
                setFacilityRoomActive((assEditedRec[0]?.IsDeleted) ? '1' : '0');
                setFacilityRoomMaximumCapacity(assEditedRec[0]?.MaximumCapacity);
                setFacilityRoomContactName(assEditedRec[0]?.ContactName);
                setFacilityRoomContactPhone(assEditedRec[0]?.ContactPhone);
                setFacilityRoomContactEmail(assEditedRec[0]?.ContactEmail);
                setEditFacilityRoom(true);
            }
        } 
        setLoading(false);
    }

    async function handleCancelChildernClick(tab) {
        if (tab === 1) {
            if (facilityID === -1) {
                setAddNewFacility(false);
            } else {
                setEditFacility(false);
            }    
        } else if (tab === 2) {
            if (facilityRoomID === -1) {
                setAddNewFacilityRoom(false);
            } else {
                setEditFacilityRoom(false);
            }    
        } 
        ClearStateFields(tab);
    }
    
    async function ClearStateFields(tab) {
        if (tab === 1) {
            setFacilityID(-1);
            setFacilityName('');    
            setFacilityName('');
            setFacilityDescription('');
            setTrainingFacility(-1);
            setFacilityAddress('');
            setFacilityState('');
            setFacilityCity('');
            setFacilityZipCode('');
            setFacilityCountry('');
            setFacilityFax('');
        } else if (tab === 2) {
            setFacilityRoomID(-1);
            setFacilityRoomName('');
            setFacilityRoomDescription('');
            setFacilityRoomDirections('');
            setFacilityRoomActive('-1');
            setFacilityRoomMaximumCapacity('');
            setFacilityRoomContactName('');
            setFacilityRoomContactPhone('');
            setFacilityRoomContactEmail('');
    } 
    }

    function displayHeader() {
        return (
            <Table responsive="lg" >
                <thead>
                <tr>
                    <th style={{ textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}> <h3 style={styles.SubHeader}>Facility</h3>
                    </th>
                    <th style={{ textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                        <div className='input-field'>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Facility'/>
                            <a onClick={() => searchFacility()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                            {(permissionFlags.CanCreate) ? 
                                (addNewFacility) ? 
                                    <a onClick={() => handleAddChildernClick(1, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Facility'>shadow_minus</span>
                                    </a>
                                    :
                                    <a onClick={() => handleAddChildernClick(1, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Facility'>note_add</span>
                                    </a>
                            : 
                                <></>
                            }
                        </div>                                            
                    </th>
                </tr>
                </thead>
            </Table>
        );
    }

    function displayAddButtons(tabValue, currentJP) {
        const oRequestList = sliderValue.filter((oItem) => oItem.FacilityID === currentJP.FacilityID);

        if (tabValue === 1 ) {
            if (oRequestList[0]?.newFacilityClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(1, currentJP.FacilityID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Facility'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(1, currentJP.FacilityID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Facility'>note_add</span>
                    </a>
                );
            }
        } else if (tabValue === 2 ) {
            if (oRequestList[0]?.newFacilityRoomClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(2, currentJP.FacilityID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Facility Room'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(2, currentJP.FacilityID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Facility Room'>note_add</span>
                    </a>
                );
            }
        } 
    }

    function displayNewFacility() {
        if ((addNewFacility) || (editFacility)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewFacility) ? 'New Facility' : 'Edit Facility'}                        
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="fname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                        <input id="fname" type="text" style={styles.TextDataEntryNoPadding} value={facilityName} onChange={(e) => (setFacilityName(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="femail" style={styles.LabelDataEntryNoPadding}>Email</label>
                                        <input id="femail" type="email" style={styles.TextDataEntryNoPadding} value={facilityEmail} onChange={(e) => (setFacilityEmail(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="trainingfacility" style={styles.LabelDataEntryNoPadding}>Training Facility</label>
                                        <select id="trainingfacility" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={trainingFacility} onChange={(e) => (setTrainingFacility(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            {trainingVendorTypeData?.map((oItem, iIndex) => (
                                                <option id={oItem?.TrainingVendorID} value={oItem?.TrainingVendorID}>{oItem.TrainingVendorName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="fdesc" style={styles.LabelDataEntryNoPadding}>Description</label>
                                        <input id="fdesc" type="text" style={styles.TextDataEntryNoPadding} value={facilityDescription} onChange={(e) => (setFacilityDescription(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="fphone" style={styles.LabelDataEntryNoPadding}>Phone</label>
                                        <input id="fphone" type="text" style={styles.TextDataEntryNoPadding} value={facilityPhone} onChange={(e) => (setFacilityPhone(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="ffax" style={styles.LabelDataEntryNoPadding}>Fax</label>
                                        <input id="ffax" type="text" style={styles.TextDataEntryNoPadding} value={facilityFax} onChange={(e) => (setFacilityFax(e.target.value))}/>
                                    </div>                    
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="faddress" style={styles.LabelDataEntryNoPadding}>Address</label>
                                        <input id="faddress" type="text" style={styles.TextDataEntryNoPadding} value={facilityAddress} onChange={(e) => (setFacilityAddress(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="fcity" style={styles.LabelDataEntryNoPadding}>City</label>
                                        <input id="fcity" type="text" style={styles.TextDataEntryNoPadding} value={facilityCity} onChange={(e) => (setFacilityCity(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="fstate" style={styles.LabelDataEntryNoPaddingWBG}>State</label>
                                        <select id="fstate" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={facilityState} onChange={(e) => (setFacilityState(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            {stateCountryData?.map((oItem, iIndex) => (
                                                <option id={oItem?.StateCode} value={oItem?.StateCode}>{oItem.StateName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="fzipcode" style={styles.LabelDataEntryNoPadding}>Zip Code</label>
                                        <input id="fzipcode" type="text" style={styles.TextDataEntryNoPadding} value={facilityZipCode} onChange={(e) => (setFacilityZipCode(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="fcountry" style={styles.LabelDataEntryNoPaddingWBG}>Country</label>
                                        <select id="fcountry" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={facilityCountry} onChange={(e) => (setFacilityCountry(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            {countryData?.map((oItem, iIndex) => (
                                                <option id={oItem?.CountryCode.trim()} value={oItem?.CountryCode.trim()}>{oItem.CountryName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Facility'>Save</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Facility'>Cancel</Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayNewFacilityRoom(currentItem) {
        if ((addNewFacilityRoom) || (editFacilityRoom)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewFacilityRoom) ? 'New Facility Room' : 'Edit Facility Room'}                        
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="rname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="rname" type="text" style={styles.TextDataEntryNoPadding} value={facilityRoomName} onChange={(e) => (setFacilityRoomName(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="ractive" style={styles.LabelDataEntryNoPaddingWBG}>Not Available</label>
                                    <select id="ractive" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={facilityRoomActive} onChange={(e) => (setFacilityRoomActive(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="rnumber" style={styles.LabelDataEntryNoPadding}>Number</label>
                                    <input id="rnumber" type="text" style={styles.TextDataEntryNoPadding} value={facilityRoomNumber} onChange={(e) => (setFacilityRoomNumber(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="maxc" style={styles.LabelDataEntryNoPadding}>Maximum Capacity</label>
                                    <input id="maxc" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={facilityRoomMaximumCapacity} onChange={(e) => (setFacilityRoomMaximumCapacity(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="rcname" style={styles.LabelDataEntryNoPadding}>Contact Name</label>
                                    <input id="rcname" type="text" style={styles.TextDataEntryNoPadding} value={facilityRoomContactName} onChange={(e) => (setFacilityRoomContactName(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="rphone" style={styles.LabelDataEntryNoPadding}>Contact Phone</label>
                                    <input id="rphone" type="text" style={styles.TextDataEntryNoPadding} value={facilityRoomContactPhone} onChange={(e) => (setFacilityRoomContactPhone(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="remail" style={styles.LabelDataEntryNoPadding}>Contact Email</label>
                                    <input id="remail" type="email" style={styles.TextDataEntryNoPadding} value={facilityRoomContactEmail} onChange={(e) => (setFacilityRoomContactEmail(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="notes" style={styles.LabelDataEntryNoPadding}>Directions</label>
                                    <FormControl as='textarea' rows={5} id="notes" style={{backgroundColor:companyData?.PagesBGColor}} value={facilityRoomDirections} onChange={(e) => (setFacilityRoomDirections(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="rdesc" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <FormControl as='textarea' rows={5} id="rdesc" style={styles.TextDataEntryNoPaddingWBG} value={facilityRoomDescription} onChange={(e) => (setFacilityRoomDescription(e.target.value))}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(2, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Facility Room'>Save</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(2)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Facility Room'>Cancel</Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayFacilityRooms(currentItem) {
        const oChildrens = FacilityRoom?.filter((oItem) => (oItem.FacilityID === currentItem.FacilityID));

        return (
            (oChildrens?.length === 0) ? <>{displayNewFacilityRoom(currentItem)}</> : 
            <>
            {displayNewFacilityRoom(currentItem)}
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Name</th>
                        <th style={styles.tableText}>Number</th>
                        <th style={styles.tableText}>Capacity</th>
                        <th style={styles.tableText}>Contact</th>
                        <th style={styles.tableText}>Email</th>
                        <th style={styles.tableText}>Action</th>
                    </tr>
                </thead>                        
                <tbody> 
                {oChildrens?.map((oItem, iIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{oItem?.RoomID}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.RoomName}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.RoomNumber}</td> 
                        <td style={styles.tableText}>{oItem?.MaximumCapacity}</td> 
                        <td style={styles.tableText}>{oItem?.ContactName}</td> 
                        <td style={styles.tableText}>{oItem?.ContactEmail}</td> 
                        <td style={styles.tableText}>
                            {(fromLookup) ? 
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" 
                                        checked={getSubDetailSwitchValue(oItem?.RoomID)} id={`switch${'detail' + oItem?.FacilityRoomID}`} 
                                        style={(getSubDetailSwitchValue(oItem?.RoomID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                        onChange={(e) => {handleSwitchSubDetailChange(oItem?.RoomID, oItem?.RoomName)}}>
                                    </input>
                                    <label class="form-check-label" for={`switch${'detail' + oItem?.RoomID}`}>Select</label>
                                </div>
                            :
                                <div>
                                    <a onClick={() => handleUpdateChildernClick(2, oItem?.RoomID)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Facility Room'>edit</span>
                                    </a>
                                    <a onClick={() => handleDeleteChildernClick(2, oItem, oItem?.RoomID, oItem?.FacilityID)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Facility Room'>delete</span>
                                    </a>
                                </div>
                            }
                        </td>
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function getDetailSwitchTR(currentItem) {
        const oRequestList = sliderValue?.filter((oItem) => oItem.FacilityID === currentItem?.FacilityID);

        if ((oRequestList) && (oRequestList.length > 0) && (oRequestList[0]?.showDetails)) {
                return (
                <>
                <tr id={`tr${'instdetail' + currentItem.FacilityID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Rooms</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentItem)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayFacilityRooms(currentItem)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                </>
            );
        } 
    }

    function displayFacilityHeader() {
        if (fromLookup) {
            return (
                <tr>
                <th style={styles.tableText}>#</th>
                <th style={styles.tableTextLeft}>Name</th>
                <th style={styles.tableTextLeft}>Email</th>
                <th style={styles.tableTextLeft}>Instances</th>
            </tr>
            );
        } else {
            return (
            <tr>
                <th style={styles.tableText}>#</th>
                <th style={styles.tableTextLeft}>Name</th>
                <th style={styles.tableTextLeft}>Email</th>
                <th style={styles.tableTextLeft}>Instances</th>
                <th style={styles.tableTextLeft}>Action</th>
            </tr>
            );
        }
    }

    function displayFacilityName(oRow) {
        if (fromLookup) {
            return (
                <>
                <tr>
                    <td style={styles.tableText}>{oRow.FacilityID}</td>
                    <td style={styles.tableTextMainColumn}>
                        {oRow?.FacilityName}
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" 
                                checked={getDetailSwitchValue(oRow?.FacilityID)} id={`switch${'detail' + oRow.FacilityID}`} 
                                style={(getDetailSwitchValue(oRow?.FacilityID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                onChange={(e) => {handleSwitchDetailChange(oRow?.FacilityID)}}>
                            </input>
                            <label class="form-check-label" for={`switch${'detail' + oRow?.FacilityID}`}>Details</label>
                        </div>
                    </td>
                    <td style={styles.tableTextLeft}>{(oRow?.Email) ? oRow?.Email : ''}</td>
                    <td style={styles.tableText}>{oRow?.FacilityInstanceCount?.toLocaleString()}</td>
                </tr>
                </>
            );
        } else {
            return (
                <>
                <tr>
                    <td style={styles.tableText}>{oRow.FacilityID}</td>
                    <td style={styles.tableTextMainColumn}>
                        {oRow?.FacilityName}
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" 
                                checked={getDetailSwitchValue(oRow?.FacilityID)} id={`switch${'detail' + oRow.FacilityID}`} 
                                style={(getDetailSwitchValue(oRow?.FacilityID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                onChange={(e) => {handleSwitchDetailChange(oRow?.FacilityID)}}>
                            </input>
                            <label class="form-check-label" for={`switch${'detail' + oRow?.FacilityID}`}>Details</label>
                        </div>                    
                    </td>
                    <td style={styles.tableTextLeft}>{(oRow?.Email) ? oRow?.Email : ''}</td>
                    <td style={styles.tableText}>{oRow?.FacilityInstanceCount?.toLocaleString()}</td>
                    <td style={styles.tableText}>
                        <div>
                            {(permissionFlags.CanModify) ? 
                                <>
                                <a onClick={() => handleUpdateChildernClick(1, oRow?.FacilityID)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Facility'>edit</span>
                                </a>
                                {((permissionFlags.CanDelete) && (parseInt(oRow?.FacilityInstanceCount) === 0)) ? 
                                    <a onClick={() => handleDeleteChildernClick(1, oRow, oRow?.FacilityID, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Facility'>delete</span>
                                    </a>
                                    :
                                    <></>
                                }
                                </>
                            : 
                                <></>
                            }
                        </div>
                    </td>
                </tr>
                </>
            );
        }
    }
    
    return ( 
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
            <Modal style={{background:'transparent', maxHeight:'100%'}} size='xl' aria-labelledby="contained-modal-title-vcenter" centered 
                show={showDeleteAlertModal} onHide={handleDeleteAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Do you want to delete this prediction?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>If you select 'Yes', it cannot be reverted. Please confirm.</p>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleDeleteAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleDeleteAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal> 

            <Table style={{background:'transparent'}} >
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {(facility) ? 
                                (
                                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                            {displayHeader()}
                                        </Card.Header>
                                        {(loading) ? (            
                                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                                    <Spinner/>
                                                </div>
                                            </Card.Body>
                                        ) 
                                        : 
                                        (                                          
                                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                {displayNewFacility()}                                               
                                                {(facility?.length === 0) ? (
                                                    <div style={styles.tableText}>
                                                        No Test Libraries.
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Table responsive="sm" striped bordered hover variant="light">
                                                            <thead>
                                                                {displayFacilityHeader()}
                                                            </thead>
                                                            <tbody>
                                                                {facility && facility.map((oItem, index) => {
                                                                    return (
                                                                        <>
                                                                        {displayFacilityName(oItem)}
                                                                        {getDetailSwitchTR(oItem)}
                                                                        </>
                                                                    )
                                                                }
                                                            ) 
                                                            }    

                                                            </tbody>
                                                        </Table>        
                                                    </div>
                                                )}
                                            </Card.Body>
                                        )}

                                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                                            <Table variant="light">
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                            <h5 style={styles.tableTextLeft}>{facilities?.facilities?.RecordFound.toLocaleString()}   Test Libraries&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                                        </td>
                                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                                            {(loading) ? <></> : 
                                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                                <h5 style={styles.tableText}>
                                                                {(facilities?.facilities?.PagingMetaData?.PreviousPage) ? (
                                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                                            <span title='Previous Page'>Previous</span>
                                                                        </a>
                                                                ) 
                                                                : 
                                                                <></> }
                                                                {(facilities?.facilities?.PagingMetaData?.NextPage) ? (
                                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                                        </a>
                                                                ) 
                                                                : 
                                                                <></> }
                                                                </h5>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                                            <h5 style={styles.tableTextRight}> Page   {facilities?.facilities?.PagingMetaData?.PageNumber}  of  {facilities?.facilities?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Footer>
                                    </Card>
                                )
                                :
                                (<></>)}
                        </td>
                    </tr>
                </tbody>
            </Table>

            </div>
        </div>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state?.auth?.user,
        companyData: state?.auth?.companyData,
        facilities: state?.entity?.facilities,
        userattributelookups: state?.entity?.userattributelookups,
        lookupselections: state.entity.lookupselections,
        loadingMenu: state?.auth?.loadingMenu,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
        getFacilities: (oParams) => dispatch(getFacilities(oParams)),
        insertFacility: (oParams) => dispatch(insertFacility(oParams)),
        deleteFacility: (oParams) => dispatch(deleteFacility(oParams)),
        updateFacility: (oParams) => dispatch(updateFacility(oParams)),
        insertFacilityRoom: (oParams) => dispatch(insertFacilityRoom(oParams)),
        deleteFacilityRoom: (oParams) => dispatch(deleteFacilityRoom(oParams)),
        updateFacilityRoom: (oParams) => dispatch(updateFacilityRoom(oParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(FacilityList)
