import React, { useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { styles } from '../layout/styles';
import { Button, Card, CardBody, Modal, Table } from 'react-bootstrap';
import 'survey-core/defaultV2.min.css';
import { Model, tryFocusPage } from 'survey-core';
import { Survey } from 'survey-react-ui';
import '../layout/survey.css';
import { updateEvaluationResponses, updateUserEvaluationSession, updateKnowledgeSessionEvaluationResponses, getUserEvaluationSessions, 
        insertKnowledgeSessionAssessment, getKnowledgeSessionAssessment, processEnrollment, processEnrollmentClear, getKnowledgeSessionsByUser,  
        getAttachments, getAttachmentStream, getScormForLaunch, getScormUserSession, getAICCUserSession } from '../../store/actions/entityActions'
import moment from 'moment';
import { AttachmentType, EntityRowType, KnowledgeListType, KnowledgeType, LOLifeCycleStatus, RetentasType, WBTType, AlertVariant, AlertEnrollmentTimeOut, KnowledgePlayerType, GradingType } from '../../config/Constant';
import { SurveyThemeJson } from '../../config/SurveyTheme';
import { Stepper, Step } from 'react-form-stepper';
import AlertMessage from '../layout/AlertMessage';
import Spinner from '../layout/Spinner';
import ScormAiccPlayer from '../layout/ScormAiccPlayer';

function LaunchKnowledge (props) {
    // const { id } = useParams();
    const { id, auth, companyData, evaluation, evaluationquestions, evaluationratings, evaluationquestionratings, goals, subgoals, subgoalratings, subgoalgroups, 
        userevaluationsessions, launchuserevaluation, launchuserevaluationsession, userDetail, knowledgesessionevaluation, processleveltypeidProps, fromLookup, listType, 
        knowledgesessions, myknowledgesessions, myteamknowledgesessions, testlibrary, knowledgesessionassessmentsummary, parentSessionParams, attachments, 
        enrollmentMessage, scorm, scormusersession } = props;

    const { id:paramID } = useParams();
    const launchID = id || paramID;

    const location  = useLocation();
    let processleveltypeid = location?.state?.processleveltypeid;
    if ((processleveltypeid === null) || (processleveltypeid === undefined)) {
        processleveltypeid = processleveltypeidProps;
    }

    let knowledgeListType = location?.state?.listType;
    if ((knowledgeListType === null) || (knowledgeListType === undefined)) {
        knowledgeListType = listType;
    }

    let oParentSessionParams = location?.state?.parentSessionParams;
    if ((oParentSessionParams === null) || (oParentSessionParams === undefined)) {
        knowledgeListType = parentSessionParams;
    }

    let survey = null;
    const surveyComplete = useCallback((sender) => {
        handleAlertModalShow();
        saveSurveyResults(sender.data);
    });
    const assessmentComplete = useCallback((sender) => {
        // handleAlertModalShow();
        saveAssessmentResults(sender.data);
    });

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [publishEvaluation, setpublishEvaluation] = useState(false);

    const [ showAlertModal, setshowAlertModal ] = useState(false);

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const [header, setHeader] = useState('Knowledge Hub');
    const [knowledgeName, setKnowledgeName] = useState('');
    const [knowledgeSessionID, setKnowledgeSessionID] = useState(null);
    const [knowledgeSessionStatus, setKnowledgeSessionStatus] = useState(null);
    const [knowledgeType, setKnowledgeType] = useState('');
    const [launchTestInstanceID, setLaunchTestInstanceID] = useState('');
    const [isWBT, setIsWBT] = useState(false);
    const [gradingTypeID, setGradingTypeID] = useState(-1);
    const [passingScore, setPassingScore] = useState(0);
    const [sessionCreditUnits, setSessionCreditUnits] = useState(null);
    const [sessionCreditHours, setSessionCreditHours] = useState(null);
    const [sessionCreditOthers, setSessionCreditOthers] = useState(null);
    const [hasPreTest, setHasPreTest] = useState(false);
    const [testIDPre, setTestIDPre] = useState(-1);
    const [testInstanceIDPre, setTestInstanceIDPre] = useState(-1);
    const [preTestInstanceRec, setPreTestInstanceRec] = useState(null);
    const [forcePreTestNextStep, setForcePreTestNextStep] = useState(false);
    const [preTestPassingScore, setPreTestPassingScore] = useState(100);
    const [forcePreTestCompletion, setForcePreTestCompletion] = useState(false);
    const [forcePreTestCompletionPassingScore, setForcePreTestCompletionPassingScore] = useState(100);
    const [hasPostTest, setHasPostTest] = useState(false);
    const [testID, setTestID] = useState(-1);
    const [testInstanceID, setTestInstanceID] = useState(-1);
    const [testInstanceRec, setTestInstanceRec] = useState(null);
    const [hasContent, setHasContent] = useState(false);
    const [canDisplayContent, setCanDisplayContent] = useState(true);
    const [hasEvaluation, setHasEvaluation] = useState(false);
    const [evaluationID, setEvaluationID] = useState(-1);
    const [wbtTypeID, setWbtTypeID] = useState(-1);
    const [scormID, setScormID] = useState(-1);
    const [singleScorm, setSingleScorm] = useState(false);
    const [singleScormURL, setSingleScormURL] = useState('');
    const [wbtURL, setWbtURL] = useState('');
    const [aicc, setAICC] = useState(false);
    const [aiccUrl, setAICCUrl] = useState(false);
    const [acknowledgementContent, setAcknowledgementContent] = useState(false);
    const [acknowledgementText, setAcknowledgementText] = useState('');
    const [acknowledgementURL, setAcknowledgementURL] = useState('');
    const [acknowledgementChecked, setAcknowledgementChecked] = useState(false);
    const [knowledgePlayerType, setKnowledgePlayerType] = useState(KnowledgePlayerType.SCORM12);

    const [radioStepValue, setRadioStepValue] = useState(1);
    // Define which steps should be included dynamically
    const [stepVisibility, setStepVisibility] = useState({
                introduction: true,
                preAssessment: true,  // Set to true if Pre Assessment should be shown
                postAssessment: true,  // Set to true if Post Assessment should be shown
                evaluations: true,     // Set to true if Evaluations should be shown
                content: true,          // Set to true if Content should be shown
                summary: true,
            });
    const [filteredSteps, setFilteredSteps] = useState([]);
    
    const stateknowledgesessions = (knowledgeListType === KnowledgeListType.User) ? myknowledgesessions?.myknowledgesessions : (knowledgeListType === KnowledgeListType.Manager) ? myteamknowledgesessions?.mycalendar : knowledgesessions?.knowledgesessions;
    const knowledgesession = stateknowledgesessions?.LearningObjectSession;
    const testinstances = testlibrary?.testlibrary?.TestInstance;
    const testquestions = testlibrary?.testlibrary?.TestQuestion;
    const testanswers = testlibrary?.testlibrary?.TestAnswer;
    const losLifeCycle = stateknowledgesessions?.LearningObjectSessionLifeCycle;
    const losAuthorizer = stateknowledgesessions?.LearningObjectSessionAuthorizer;
    
    useEffect(() => {
        var bHasPreTest = false;
        var bHasPostTest = false;
        var bHasEvaluation = false;
        var bHasContent = false;

        if ((launchID) && (launchID > 0)) {
            const sessionRec = knowledgesession?.filter((assRec) => (parseInt(assRec.LearningObjectSessionID) === parseInt(launchID)));
            if ((sessionRec) && (sessionRec.length > 0)) {
                console.log(sessionRec[0]);
                setKnowledgeName(sessionRec[0]?.LearningObjectName);
                if ((sessionRec[0]?.LearningObjectSessionID) && (parseInt(sessionRec[0]?.LearningObjectSessionID) > 0)) {
                    setKnowledgeSessionID(parseInt(sessionRec[0]?.LearningObjectSessionID));
                }
                if ((sessionRec[0]?.LearningObjectProcessStatusTypeID) && (parseInt(sessionRec[0]?.LearningObjectProcessStatusTypeID) > 0)) {
                    setKnowledgeSessionStatus(parseInt(sessionRec[0]?.LearningObjectProcessStatusTypeID));
                }
                if (sessionRec[0]?.LearningObjectTypeID) {
                    setKnowledgeType(sessionRec[0]?.LearningObjectTypeID);
                }
                if ((sessionRec[0]?.LearningObjectTypeID) && (parseInt(sessionRec[0]?.LearningObjectTypeID) === parseInt(KnowledgeType.Online))) {
                    setIsWBT(true);
                }
                if ((sessionRec[0]?.GradingTypeID) && (parseInt(sessionRec[0]?.GradingTypeID) > 0)) {
                    setGradingTypeID(parseInt(sessionRec[0]?.GradingTypeID));
                }
                if ((sessionRec[0]?.PassingScore) && (parseFloat(sessionRec[0]?.PassingScore) > 0)) {
                    setPassingScore(parseFloat(sessionRec[0]?.PassingScore));
                }
                if ((sessionRec[0]?.LOCreditUnits) && (parseFloat(sessionRec[0]?.LOCreditUnits) > 0)) {
                    setSessionCreditUnits(parseFloat(sessionRec[0]?.LOCreditUnits));
                }
                if ((sessionRec[0]?.LOCreditHours) && (parseFloat(sessionRec[0]?.LOCreditHours) > 0)) {
                    setSessionCreditHours(parseFloat(sessionRec[0]?.LOCreditHours));
                }
                if ((sessionRec[0]?.LOCreditOthers) && (parseFloat(sessionRec[0]?.LOCreditOthers) > 0)) {
                    setSessionCreditOthers(parseFloat(sessionRec[0]?.LOCreditOthers));
                }
                if ((sessionRec[0]?.ForcePreTestForNextStep) && (sessionRec[0]?.ForcePreTestForNextStep === true)) {
                    setForcePreTestNextStep(sessionRec[0]?.ForcePreTestForNextStep);
                    if ((sessionRec[0]?.PreTestPassingScore) && (parseInt(sessionRec[0]?.PreTestPassingScore) > 0)) {
                        setPreTestPassingScore(parseInt(sessionRec[0]?.PreTestPassingScore));
                        if ((sessionRec[0]?.PreTestScore) && (sessionRec[0]?.PreTestScore > 0)) {
                            if (parseFloat(sessionRec[0]?.PreTestScore) < parseFloat(sessionRec[0]?.PreTestPassingScore)) {
                                setCanDisplayContent(false);                            
                            } else {
                                setCanDisplayContent(true);          
                                if (radioStepValue === 1) {
                                    setRadioStepValue(4);                  
                                }
                            }
                        } else {
                            setCanDisplayContent(false);                            
                        }
                    }    
                }
                if ((sessionRec[0]?.ForceCompletionPreTestPassed) && (sessionRec[0]?.ForceCompletionPreTestPassed === true)) {
                    setForcePreTestCompletion(sessionRec[0]?.ForceCompletionPreTestPassed);
                    if ((sessionRec[0]?.ForceCompletionPreTestPassingScore) && (parseInt(sessionRec[0]?.ForceCompletionPreTestPassingScore) > 0)) {
                        setForcePreTestCompletionPassingScore(parseInt(sessionRec[0]?.ForceCompletionPreTestPassingScore));
                    }    
                }
                if ((sessionRec[0]?.TestInstanceIDPre) && (parseInt(sessionRec[0]?.TestInstanceIDPre) > 0)) {
                    bHasPreTest = true;
                    setHasPreTest(true);
                    setTestInstanceIDPre(sessionRec[0]?.TestInstanceIDPre);
                    const preAssInstanceRec = testinstances?.filter((instRec) => (parseInt(instRec.TestInstanceID) === parseInt(sessionRec[0]?.TestInstanceIDPre)));
                    if ((preAssInstanceRec) && (preAssInstanceRec.length > 0)) {
                        setTestIDPre(preAssInstanceRec[0]?.TestID);
                        setPreTestInstanceRec(preAssInstanceRec[0]);
                    }
                }
                if ((sessionRec[0]?.TestInstanceID) && (parseInt(sessionRec[0]?.TestInstanceID) > 0)) {
                    bHasPostTest = true;
                    setHasPostTest(true);
                    setTestInstanceID(sessionRec[0]?.TestInstanceID);
                    const postAssInstanceRec = testinstances?.filter((instRec) => (parseInt(instRec.TestInstanceID) === parseInt(sessionRec[0]?.TestInstanceID)));
                    if ((postAssInstanceRec) && (postAssInstanceRec.length > 0)) {
                        setTestID(postAssInstanceRec[0]?.TestID);
                        setTestInstanceRec(postAssInstanceRec[0]);
                    }
                }
                if ((sessionRec[0]?.EvaluationID) && (parseInt(sessionRec[0]?.EvaluationID) > 0)) {
                    bHasEvaluation = true;
                    setHasEvaluation(true);
                    setWbtTypeID(sessionRec[0]?.EvaluationID);
                }
                if ((sessionRec[0]?.WBTTypeID) && (parseInt(sessionRec[0]?.WBTTypeID) > 0)) {
                    bHasContent = true;
                    setHasContent(true);
                    setWbtTypeID(sessionRec[0]?.WBTTypeID);
                    if (parseInt(sessionRec[0]?.WBTTypeID) === parseInt(WBTType.Acknowledgement)) {
                        setAcknowledgementContent(true);
                        if ((sessionRec[0]?.AcknowledgementText) && (sessionRec[0]?.AcknowledgementText !== '')) {
                            setAcknowledgementText(sessionRec[0]?.AcknowledgementText);
                        }
                        getAcknowledgeAttachment(sessionRec[0]?.LearningObjectID);
                    } else if (parseInt(sessionRec[0]?.WBTTypeID) === parseInt(WBTType.SingleSCORM)) {
                        setSingleScorm(true);
                        if ((sessionRec[0]?.ScormRootID) && (sessionRec[0]?.ScormRootID !== '')) {
                            getScormContent(sessionRec[0]?.ScormRootID);
                        }
                    } else if (parseInt(sessionRec[0]?.WBTTypeID) === parseInt(WBTType.AICC) && (sessionRec[0]?.WBTURL) && (sessionRec[0]?.WBTURL !== '')) {
                        setAICC(true);
                        getAICCContent(sessionRec[0]?.WBTURL);
                    } 
                }
                if ((sessionRec[0]?.WBTURL) && (sessionRec[0]?.WBTURL !== '')) {
                    bHasContent = true;
                    setHasContent(true);
                    setWbtURL(sessionRec[0]?.WBTURL);
                }
            }

            // Filter steps dynamically based on stepVisibility
            const dynamicFilteredSteps = [
                { name: 'Introduction', value: 1 },  // Always show Step 1
                ...(bHasPreTest ? [
                    { name: 'Pre Assessment', value: 2 },
                    { name: 'Pre Assessment Result', value: 3 }
                ] : []),
                ...(bHasContent ? [{ name: 'Content', value: 4 }] : []),
                ...(bHasPostTest ? [
                    { name: 'Post Assessment', value: 5 },
                    { name: 'Post Assessment Result', value: 6 }
                ] : []),
                ...(bHasEvaluation ? [{ name: 'Evaluation', value: 7 }] : []),
                { name: 'Summary', value: 8 }  // Always show Step 8
            ];            
            setFilteredSteps(dynamicFilteredSteps);
        }
    }, [myknowledgesessions])  

    useEffect(() => {
        if ((enrollmentMessage) && (enrollmentMessage?.returnParams) && (enrollmentMessage?.returnParams?.length > 0) && 
            (!enrollmentMessage?.returnParams[0]?.ProcessSuccess) && 
            (enrollmentMessage?.returnParams[0]?.ProcessErrorDescription) && (enrollmentMessage?.returnParams[0]?.ProcessErrorDescription.trim() !== '')) {
            setAlertMessage(enrollmentMessage?.returnParams[0]?.ProcessErrorDescription.trim().replaceAll("###LINEBREAK###", "<br>"));
            setAlertVariant(AlertVariant.Danger);
            setShowAlertMessage(true);
        } else if ((enrollmentMessage) && (enrollmentMessage?.returnParams) && (enrollmentMessage?.returnParams?.length > 0) && 
            (enrollmentMessage?.returnParams[0]?.ProcessSuccess) && 
            (enrollmentMessage?.returnParams[0]?.ProcessDetailDescription) && (enrollmentMessage?.returnParams[0]?.ProcessDetailDescription.trim() !== '')) {
            setAlertMessage(enrollmentMessage?.returnParams[0]?.ProcessDetailDescription.trim().replaceAll("###LINEBREAK###", "<br>"));
            setAlertVariant(AlertVariant.Success);
            setShowAlertMessage(true);
        }
    }, [enrollmentMessage])  

    const handleStepperNext = () => {
        const currentStepIndex = filteredSteps.findIndex(step => step.value === radioStepValue);
        if (currentStepIndex < filteredSteps.length - 1) {
            if ((filteredSteps[currentStepIndex].value === 2) || (filteredSteps[currentStepIndex].value === 5)) {
                refreshKnowlegdeSession();
            } else if (filteredSteps[currentStepIndex + 1].value === 4) {
                getAcknowledgementURL();
            }
            setRadioStepValue(filteredSteps[currentStepIndex + 1].value);
        }
    };

    const handleStepperPrevious = () => {
        const currentStepIndex = filteredSteps.findIndex(step => step.value === radioStepValue);
        if (currentStepIndex > 0) {
            setRadioStepValue(filteredSteps[currentStepIndex - 1].value);
        }
    };

    function handleSubmit(e) {
        e.preventDefault();
    }
    
    function handleAlertModalClose() { 
        setshowAlertModal(false);
    }
    
    function handleAlertModalShow() { 
        if ((processleveltypeid === RetentasType.Surveys) || (processleveltypeid === RetentasType.PRFs)) {
            setshowAlertModal(true);
        } else {
            setshowAlertModal(false);
        }
    }
    
    async function handleAlertModalYes() { 
        setshowAlertModal(false);
        console.log('Call publish evaluation session.');
        if (processleveltypeid == RetentasType.Surveys) {
                const userEvalSessionParams = [{
                UserID:auth.UserID,
                UserEvaluationSessionID:launchID,
                CompletedDate:moment().toDate(),
                IsPublished:'true',
                Score:'0',
                Weight:'0',
                DataSyncOut:'',
                DataSyncMessageOut:''
            }];
            await props.updateUserEvaluationSession(userEvalSessionParams);
            await props.getUserEvaluationSessions(auth.UserID);
            setpublishEvaluation(true);
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        } else {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
        }
    }

    function handleAlertModalNo() { 
        setshowAlertModal(false);
        console.log('Do not publish evaluation session.');
        setpublishEvaluation(false);
        if (processleveltypeid == RetentasType.Surveys) {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        } else {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
        }
    }
    
    async function handleCancel() {
        if (knowledgeListType === KnowledgeListType.User) {
            navigate('/');
        }
        await props.processEnrollmentClear();        
    }

    async function handleAcknowledge() {
        const dSubmitDateTime = moment(new Date()).format('YYYY-MM-DD hh:mm:ss a');
        const iUpdateStatus = LOLifeCycleStatus.Complete;
        const bPassed = true;
        // console.log("Before delay - Loading:", loading);   
        // await new Promise(resolve => setTimeout(resolve, 5000)); // 3-second delay for debugging
        // console.log("After delay - Loading:", loading);
        processEnrollmentStage(null, null, null, iUpdateStatus, bPassed, dSubmitDateTime);
    }

    async function handleScormAICCCommit() {

    }

    function animate (isShowing) {
        const element = document.getElementById("root");
        if (!!element) {
            const classes = element.classList;
            if (!classes.contains("hidden")) {
                classes.add("hidden");
            }
            isShowing ? classes.add("visible") : classes.remove("visible");
        }
    }
    
    function showAnimated () { animate(true); }
    function hideAnimated () { animate(false); }
    
    var doAnimation = true;

    function checkStepValidation(stepID) {
        var bShowNextStep = true;

        return bShowNextStep;
    }

    async function getAcknowledgeAttachment(id) {
        setLoading(true);
        const oAParams  = {
            UserID:auth.UserID,
            EntityID: id,
            AttachmentTypeIDs: AttachmentType.Acknowledgement,
            EntityRowTypeID: EntityRowType.Course,
            SearchFilter:'',
        };     
        console.log(oAParams);
        await props.getAttachments(oAParams);    
        setLoading(false);
    }    

    async function getAcknowledgementURL() {
        if ((acknowledgementContent) && (attachments) && (attachments.length > 0)) {
            var attachmentRows = attachments?.filter((aRow) => (parseInt(aRow.AttachmentTypeID) === parseInt(AttachmentType.Acknowledgement)));
            if ((attachmentRows) && (attachmentRows.length > 0)) {
                const oASParams  = {
                    CompanyID:auth?.CompanyID,
                    AttachmentID: attachmentRows[0].AttachmentID,
                };                
                const sURL = await getAttachmentStream(oASParams);    
                setAcknowledgementURL(sURL);
            }
        }
    }

    async function getScormContent(id) {
        setScormID(id);
        const oParams = {
            ScormID: id, 
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getScormForLaunch(oParams);
        const oSParams = {
            LearningObjectSessionID: launchID, 
        }; 
        console.log(oSParams);
        await props.getScormUserSession(oSParams);
    }

    async function getAICCContent(sUrl) {
        const sCompleteAICCURL = sUrl + '?aicc_url=https://appdev.observatis.com/AICCCapture/' + auth?.CompanyID + '/' + launchID;
        setAICCUrl(sCompleteAICCURL);
        const oSParams = {
            LearningObjectSessionID: launchID, 
        }; 
        console.log(oSParams);
        await props.getAICCUserSession(oSParams);
    }

    async function refreshKnowlegdeSession() {
        // get latest knowledge Session.
        await props.getKnowledgeSessionsByUser(oParentSessionParams);        
        //get latest Knowledge Session Assessments.
        let sAIDs = '';
        if ((testInstanceIDPre) && (testInstanceIDPre > 0)) {
            sAIDs = testInstanceIDPre;
        } 
        if ((testInstanceID) && (testInstanceID > 0)) {
            if (sAIDs !== '') {
                sAIDs += ',';
            }
            sAIDs += testInstanceID;
        } 
        if (sAIDs !== '') {
            const oATTParams = {
                TestInstanceIDs:sAIDs,
                LearningObjectSessionID: launchID,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeSessionAssessment(oATTParams);    
        }        
    }

    function generateEvaluationTabular() {
        try {
            const surveyJson = {
                };        

            const survey = new Model(surveyJson);
            survey.showQuestionNumbers = 'off';
            survey.completeText = "Submit"; // Change the Complete button text
            survey.completedHtml = "<h4>Thank you for your submission!</h4><p>We appreciate your feedback.</p><br></br>"; // Change the completion page content    
            //survey.css = rtCss;
            survey.applyTheme({
                "cssVariables" : {
                    "--sjs-primary-backcolor": companyData?.AccentColor,
                    "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                }
            })
            const page = survey.addNewPage(evaluation[0].EvaluationName);
            evaluationquestions && evaluationquestions.map((question, index) => {
                // Add a panel to the page
                let panel = survey.getPanelByName(question.EvaluationQuestionGroupName);
                if (!panel) {
                    panel = page.addNewPanel(question.EvaluationQuestionGroupName);
                    //console.log(question.EvaluationQuestionGroupName);
                    panel.title = question.EvaluationQuestionGroupName;
                    panel.description = 'Please indicate if you agree or disagree with the following statements';
                    panel.state = "collapsed";                        
                }
                // Add a question to the panel
                const surveyquestion = panel.addNewQuestion("rating", question.EvaluationQuestion, question.EvaluationQuestionID);
                surveyquestion.title = question.EvaluationQuestion;    
                surveyquestion.value = question.EvaluationQuestionID;
                surveyquestion.type = 'rating'; 
                // surveyquestion.rateType = 'smileys';
                // surveyquestion.scaleColorMode = 'colored';
                // surveyquestion.rateColorMode = 'scale';
                // surveyquestion.rateCount = 5;
                // surveyquestion.rateMax = 5;
                surveyquestion.rateValues = generateRatingsByEvaluationAndQuestion(question.EvaluationID, question.EvaluationQuestionID);
                surveyquestion.defaultValue = generatePreviousRatings(question.EvaluationID, question.EvaluationQuestionID);
                surveyquestion.minRateDescription = ' ';
                surveyquestion.maxRateDescription = ' ';
                surveyquestion.displayMode = 'buttons';          
            })
            return survey;
        } catch(error) {
            console.log(error);
        };
    }

    function generateRatingsByEvaluationAndQuestion(evalId, evalQuestionId) {
        // [{value:'45', text:'Strongly Agree'},{value:'46', text:'More Than Agree'},{value:'48', text:'Agree'},{value:'49', text:'Somewhat Disagree'},{value:'54', text:'Strongly Disagree'}];
        let eRatings = [];
        let eQRatings = [];
        evaluationratings && evaluationratings.map((ratings, ratingindex) => {
            if (ratings.EvaluationID === evalId) {
                eRatings.push({value: [evalQuestionId, ratings.EvaluationRatingTypeID] , text: ratings.EvaluationRatingTypeName });
            }
        });
        evaluationquestionratings && evaluationquestionratings.map((ratings, ratingindex) => {
            if (ratings.EvaluationQuestionID === evalQuestionId) {
                eQRatings.push({value: [evalQuestionId, ratings.EvaluationRatingTypeID] , text: ratings.EvaluationRatingTypeName });
            }
        });

        if (eQRatings.length > 0) {
            return eQRatings;
        } else {
            return eRatings;
        }
    }

    function generatePreviousRatings(evalId, evalQuestionId) {
        let pRatings = {}; // Ensure this is an object, not an array
    
        knowledgesessionevaluation && knowledgesessionevaluation.forEach((ratings) => {
            if (ratings.EvaluationID === evalId && ratings.EvaluationQuestionID === evalQuestionId) {
                var evalQuestionRow = evaluationquestions?.filter((evalq) => (parseInt(evalq.EvaluationQuestionID) === parseInt(evalQuestionId)));
                if ((evalQuestionRow) && (evalQuestionRow.length > 0)) {
                    pRatings[evalQuestionRow[0].EvaluationQuestion] = evalQuestionId + ',' + ratings.EvaluationRatingID;  // Assign value directly
                }
            }
        });
    
        console.log(pRatings);
        return pRatings;
    }
    
    function generateAssessmentTabular(bIsPreAssesment) {
        try {
            var currentAssRec = null;
            var currentAssInstID = -1;
            var currentAssID = -1;
            var titleName = '';
            var currentQuestionCount = 0;
            if (bIsPreAssesment) {
               currentAssRec = preTestInstanceRec; 
               currentAssInstID = testInstanceIDPre;
               currentAssID = testIDPre;
               titleName = 'Pre Assessment';
            } else {
                currentAssRec = testInstanceRec; 
                currentAssInstID = testInstanceID;
                currentAssID = testID;
                titleName = 'Assessment';
            }

            const surveyJson = {
                };        

            const survey = new Model(surveyJson);
            survey.showQuestionNumbers = 'off';
            survey.completeText = "Submit"; // Change the Complete button text
            survey.completedHtml = "<h4>Assessment Complete!</h4><br></br>";
            //survey.css = rtCss;
            survey.applyTheme({
                "cssVariables" : {
                    "--sjs-primary-backcolor": companyData?.AccentColor,
                    "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                }
            })
            survey.title = (currentAssRec.TestInstanceName) ? currentAssRec.TestInstanceName : titleName;
            //survey.firstPageIsStartPage = 'true';
            //survey.startSurvetText = 'Start Assessment';
            survey.showProgressBar = 'true';
            survey.progressBarLocation = 'top';
            survey.showTimer = 'true';
            survey.timeLimit = (30 * 60);
            testquestions && testquestions.map((question, index) => {
                if (parseInt(question.TestID) === parseInt(currentAssID)) {
                    let page = survey.getPageByName(question.TestQuestionText);
                    if (!page) {
                        page = survey.addNewPage(question.TestQuestionText);
                    }
                    const sQuestionType = getQuestionType(question.TestQuestionID);
                    const surveyquestion = page.addNewQuestion(sQuestionType, question.TestQuestionText, question.TestQuestionID);
                    surveyquestion.title = question.TestQuestionText;    
                    surveyquestion.value = question.TestQuestionID;
                    surveyquestion.type = 'radiogroup'; 
                    surveyquestion.choices = generateAnswersByQuestion(question.TestQuestionID);
                    surveyquestion.correctAnswer = generateCorrectAnswersByQuestion(question.TestQuestionID);
                    surveyquestion.defaultValue = generatePreviousAnswers(question.TestQuestionID);
                }
            });
            return survey;
        } catch(error) {
            console.log(error);
        };
    }

    function getQuestionType(questionId) {
        let questionTypeID = 1;
        testanswers && testanswers.map((answer, answerindex) => {
            if (answer.TestQuestionID === questionId) {
                questionTypeID = parseInt(answer.AnswerTypeID);
            }
        });

        if (questionTypeID === 1) {
            return 'radiogroup';
        } else if (questionTypeID === 2) {
            return 'text';
        } else if (questionTypeID === 3) {
            return 'text';
        } else if (questionTypeID === 4) {
            return 'checkbox';
        } 
    }

    function generateAnswersByQuestion(questionId) {
        // [{value:'45', text:'Strongly Agree'},{value:'46', text:'More Than Agree'},{value:'48', text:'Agree'},{value:'49', text:'Somewhat Disagree'},{value:'54', text:'Strongly Disagree'}];
        let eAnswers = [];
        testanswers && testanswers.map((answer, answerindex) => {
            if (answer.TestQuestionID === questionId) {
                const bCorrectAnswer = ((answer?.AnswerCorrect) && (answer?.AnswerCorrect === true)) ? 1 : 0;
                // eAnswers.push({value: [questionId,answer.TestAnswerID,answer.AnswerTypeID,bCorrectAnswer], text: answer.AnswerText });
                eAnswers.push({value: questionId + ',' + answer.TestAnswerID + ',' + answer.AnswerTypeID + ',' + bCorrectAnswer, text: answer.AnswerText });
            }
        });

        // console.log(eAnswers);
        return eAnswers;
    }

    function generateCorrectAnswersByQuestion(questionId) {
        let eAnswers = [];
        testanswers && testanswers.map((answer, answerindex) => {
            if ((answer.TestQuestionID === questionId) && ((answer.AnswerCorrect) && (answer.AnswerCorrect === true))) {
                const bCorrectAnswer = ((answer?.AnswerCorrect) && (answer?.AnswerCorrect === true)) ? 1 : 0;
                // eAnswers.push({value: [questionId,answer.TestAnswerID,answer.AnswerTypeID,bCorrectAnswer], text: answer.AnswerText });
                eAnswers.push({value: questionId + ',' + answer.TestAnswerID + ',' + answer.AnswerTypeID + ',' + bCorrectAnswer, text: answer.AnswerText });
            }
        });

        // console.log(eAnswers);
        return eAnswers;
    }

    function generateCorrectAnswersByInstance(testID) {
        let eAnswers = [];
        const questionRows = testquestions?.filter((question) => (parseInt(question.TestID) === parseInt(testID)));            
        questionRows && questionRows.map((question, questionindex) => {
            const answerRows = testanswers?.filter((answer) => (parseInt(answer.TestQuestionID) === parseInt(question.TestQuestionID)));            
            answerRows && answerRows.map((answer, answerindex) => {
                if ((answer.TestQuestionID === question.TestQuestionID) && ((answer.AnswerCorrect) && (answer.AnswerCorrect === true))) {
                    const bCorrectAnswer = ((answer?.AnswerCorrect) && (answer?.AnswerCorrect === true)) ? 1 : 0;
                    // eAnswers.push({value: [questionId,answer.TestAnswerID,answer.AnswerTypeID,bCorrectAnswer], text: answer.AnswerText });
                    eAnswers.push({value: question.TestQuestionID + ',' + answer.TestAnswerID + ',' + answer.AnswerTypeID + ',' + bCorrectAnswer, text: answer.AnswerText });
                }
            });
        });

        return eAnswers;
    }

    function generatePreviousAnswers(questionId) {
        let pRatings = {}; // Ensure this is an object, not an array
    
        // knowledgesessionevaluation && knowledgesessionevaluation.forEach((ratings) => {
        //     if (ratings.EvaluationID === evalId && ratings.EvaluationQuestionID === evalQuestionId) {
        //         var evalQuestionRow = evaluationquestions?.filter((evalq) => (parseInt(evalq.EvaluationQuestionID) === parseInt(evalQuestionId)));
        //         if ((evalQuestionRow) && (evalQuestionRow.length > 0)) {
        //             pRatings[evalQuestionRow[0].EvaluationQuestion] = evalQuestionId + ',' + ratings.EvaluationRatingID;  // Assign value directly
        //         }
        //     }
        // });
    
        // console.log(pRatings);
        return pRatings;
    }
    
    async function saveSurveyResults(surveyResponses) {    
        // console.log(surveyResponses);
        let dbResponse = [];
        Object.entries(surveyResponses).map(([group, groupResponses]) => {
            // console.log(group);
            // console.log(groupResponses);
            const oGroupResponses = groupResponses.toString().split(',');
            if ((oGroupResponses) && (oGroupResponses.length === 2)) {
                // oGroupResponses.map((questionid, ratingid) => {
                    dbResponse.push({UserID: auth.UserID, LearningObjectSessionID: launchID, EvaluationID:evaluation[0].EvaluationID, EvaluationQuestionID:oGroupResponses[0], EvaluationRatingTypeID:oGroupResponses[1], DataSyncOut:'', DataSyncMessageOut: '' });                 
                // });            
            }
        });
        // console.log(dbResponse);
        // save DB
        await props.updateKnowledgeSessionEvaluationResponses(dbResponse);
    }

    async function processEnrollmentStage(bPreTest, dPreTestScore, dPostTestScore, sessionStatusChange, bPassed, dSubmitDateTime) {
        setLoading(true);
        const oParams  = [{
            LearningObjectSessionID: launchID,
            Status: sessionStatusChange, 
            UpdatedUserID: auth?.UserID,
            PreTestScore: dPreTestScore,
            Score: dPostTestScore,
            Complete: (sessionStatusChange === LOLifeCycleStatus.Complete) ? true : false,
            WBT: isWBT,
            CreditUnits: sessionCreditUnits,
            CreditHours: sessionCreditHours,
            CreditOthers: sessionCreditOthers,
            IsStartEnrollment: false,
            IsProcessEnrollment: true,
            DateCompleted: dSubmitDateTime,
            CreatedByUserID: auth?.UserID,
            UpdatedByUserID: auth?.UserID,
            UserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
        }];        
        console.log('ProcessEnrollment', oParams);
        await props.processEnrollment(oParams);    
        setLoading(false);
    }

    function transformResponses(inputObj, userID, learningObjectSessionID, testInstanceID, dSubmitDateTime) {
        let outputArray = [];
        
        for (let question in inputObj) {
            let responses = inputObj[question];
            
            // Ensure responses are in an array format for uniform processing
            if (!Array.isArray(responses)) {
                responses = [responses];
            }
            
            responses.forEach(response => {
                let [TestQuestionID, TestAnswerID, AnswerTypeID, CorrectAnswer] = response.split(',').map(Number);
                
                outputArray.push({
                    UserID: userID,
                    LearningObjectSessionID: learningObjectSessionID,
                    TestInstanceID: testInstanceID,
                    TestQuestionID: TestQuestionID,
                    TestAnswerID: TestAnswerID,
                    AnswerTypeID: AnswerTypeID,
                    SubmitDateTime: dSubmitDateTime,
                    DataSyncOut: "",
                    DataSyncMessageOut: ""
                });
            });
        }
        
        return outputArray;
    }

    function checkAnswers(userAnswers, correctAnswers) {
        // Create a map to store correct answers by questionID
        const correctMap = new Map();
        
        correctAnswers.forEach(({ value }) => {
            const [questionID, answerID] = value.split(",");
            if (!correctMap.has(questionID)) {
                correctMap.set(questionID, new Set());
            }
            correctMap.get(questionID).add(answerID);
        });
    
        // Process user answers
        return Object.entries(userAnswers).map(([question, answers]) => {
            const userResponses = Array.isArray(answers) ? answers : [answers];
            const questionID = userResponses[0].split(",")[0]; // Extract question ID
    
            const correctAnswersSet = correctMap.get(questionID) || new Set();
            const userAnswerSet = new Set(userResponses.map(answer => answer.split(",")[1])); // Extract answer IDs
    
            // Check if user selected all correct answers and only correct answers
            const isCorrect = userAnswerSet.size === correctAnswersSet.size &&
                              [...userAnswerSet].every(answerID => correctAnswersSet.has(answerID));
    
            return {
                QuestionID: questionID,
                Correct: isCorrect
            };
        });
    }

    function calculateScore(results) {
        const totalQuestions = results.length;
        const correctCount = results.filter(q => q.Correct).length;
        const score = ((correctCount / totalQuestions) * 100).toFixed(2); // Score as percentage
    
        return {
            totalQuestions,
            correctCount,
            score: `${score}%`,
            scoreFloat: score,
        };
    }

    async function saveAssessmentResults(surveyResponses) {    
        // console.log(surveyResponses);
        let dbResponse = [];
        var bIsPreTest = false;
        var dScore = 0;
        var dCorrectAnswer = 0;
        var iTestID = testIDPre;
        var iTestInstanceID = testInstanceIDPre;
        if (radioStepValue === 2) {
            iTestInstanceID = testInstanceIDPre;
            bIsPreTest = true;
            iTestID = testIDPre;
        } else {
            iTestInstanceID = testInstanceID;
            bIsPreTest = false;
            iTestID = testID;
        }
        const dSubmitDateTime = moment(new Date()).format('YYYY-MM-DD hh:mm:ss a');

        // console.log(surveyResponses);
        dbResponse = transformResponses(surveyResponses, auth?.UserID, launchID, iTestInstanceID, dSubmitDateTime);

        // Object.entries(surveyResponses).map(([group, groupResponses]) => {
            //console.log(groupResponses);
            // const oGroupResponses = groupResponses.split(',');
            // if ((oGroupResponses) && (oGroupResponses.length === 4)) {
            //     dbResponse.push({UserID: auth.UserID, LearningObjectSessionID: launchID, TestInstanceID:iTestInstanceID, TestQuestionID:oGroupResponses[0], TestAnswerID:oGroupResponses[1], SubmitDateTime:dSubmitDateTime, AnswerTypeID:oGroupResponses[2], DataSyncOut:'', DataSyncMessageOut: '' });                 
            //     if (parseInt(oGroupResponses[3]) === 1) {
            //         dCorrectAnswer++;
            //     }                
            // }
            // if ((groupResponses) && (groupResponses.length === 4)) {
            //     dbResponse.push({UserID: auth.UserID, LearningObjectSessionID: launchID, TestInstanceID:iTestInstanceID, TestQuestionID:groupResponses[0], TestAnswerID:groupResponses[1], SubmitDateTime:dSubmitDateTime, AnswerTypeID:groupResponses[2], DataSyncOut:'', DataSyncMessageOut: '' });                 
            //     if (parseInt(groupResponses[3]) === 1) {
            //         dCorrectAnswer++;
            //     }                
            // }
        // });
        const dbCorrectResponse = generateCorrectAnswersByInstance(iTestInstanceID);
        const dbCheckAnswers = checkAnswers(surveyResponses, dbCorrectResponse);
        const dbScore = calculateScore(dbCheckAnswers);
        // console.log(dbResponse);
        // console.log(dbCorrectResponse);
        // console.log(dbCheckAnswers);
        console.log(dbScore);

        // save DB
        await props.insertKnowledgeSessionAssessment(dbResponse);

        const dCurrentScore = parseFloat(dbScore?.scoreFloat);//parseFloat((dCorrectAnswer * 100) / currentQuestionCount);
        const dPreTestScore = (bIsPreTest) ? dCurrentScore : 0;
        var dPostTestScore = (!bIsPreTest) ? dCurrentScore : 0;
        var iUpdateStatus = LOLifeCycleStatus.Enrolled;
        var dDateComplete = null;
        var bPassed = false;
        // Only for PreTest
        if (bIsPreTest) {
            if ((forcePreTestCompletion) && (dCurrentScore >= parseFloat(forcePreTestCompletionPassingScore))) {                
                dPostTestScore = dCurrentScore;
                iUpdateStatus = LOLifeCycleStatus.Complete;
                bPassed = true;
                dDateComplete = dSubmitDateTime;
            }     
        } else {
            if (dCurrentScore >= parseFloat(passingScore)) {                
                dPostTestScore = dCurrentScore;
                iUpdateStatus = LOLifeCycleStatus.Complete;
                bPassed = true;
                dDateComplete = dSubmitDateTime;
            }     
        }

        // console.log(dPreTestScore);
        // console.log(dPostTestScore);

        processEnrollmentStage(bIsPreTest, dPreTestScore, dPostTestScore, iUpdateStatus, bPassed, dDateComplete);
    }

    function displayEvaluation() {
        survey = generateEvaluationTabular(); // Tabular
        survey?.onComplete.add(surveyComplete);
      
        return (
            <Survey model={survey}/>
        );
    }

    function displayAssessment(bIsPreAssesment) {
        survey = generateAssessmentTabular(bIsPreAssesment); // Tabular
        survey?.onComplete.add(assessmentComplete);
      
        return (
            <Survey model={survey}/>
        );
    }

    function displayAssessmentSummary(bIsPreAssesment) {
        var iTestInstanceID = -1;
        if (bIsPreAssesment) {
            iTestInstanceID = testInstanceIDPre;
        } else {
            iTestInstanceID = testInstanceID;
        }
        var sStepSummaryMsg = '';
        if (parseInt(knowledgeSessionStatus) === parseInt(LOLifeCycleStatus.Complete)) {
            sStepSummaryMsg = "Congratulations! Your expertise has unlocked completion of this course. Feel free to explore other knowledge spaces to continue your learning journey."
        } 

        return (
            <div style={{ padding: "20px", backgroundColor:companyData?.PagesBGColor, borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                <h5 style={{ color:companyData?.Darkgrey, textAlign: "center", marginBottom: "15px" }}>Assessment Attempt(s)</h5>
                <p style={{ fontSize: "1.0rem", lineHeight: "1.6", textAlign: "center", color: "#555" }}>
                <span>{sStepSummaryMsg}</span>
                </p>
                <div style={{ marginTop: "20px", padding: "10px 20px", borderLeft: `5px solid ${companyData?.AccentColor || "#28a745"}`, backgroundColor: companyData?.PagesBGColor, borderRadius: "5px" }}>
                    <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.0rem", color:companyData?.Grey }}>
                    {((knowledgesessionassessmentsummary) && (knowledgesessionassessmentsummary.length > 0)) ? (
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                        {knowledgesessionassessmentsummary
                            .filter(summary => parseInt(summary.TestInstanceID) === parseInt(iTestInstanceID)) 
                            .map((summary, summaryIndex) => (
                                <li key={summaryIndex} style={{ marginBottom: "10px" }}>
                                    <strong>{summaryIndex + 1}: </strong>
                                    <strong>{moment(summary?.SubmitDateTime).format('MM/DD/YYYY hh:mm A')}</strong> -  
                                    You attempted <strong>{summary?.QuestionsAttempted}</strong> questions out of  
                                    <strong> {summary?.AllQuestions}</strong>, and <strong>{summary?.CorrectQuestions}</strong> were answered correctly.  
                                    Overall passing score: <b>{summary?.AllQuestions > 0 ? ((parseInt(summary?.CorrectQuestions, 10) * 100) / summary?.AllQuestions).toFixed(2) : "N/A"}%</b>.
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <span>No assessment attempts recorded yet.</span>
                    )}
                    </p>
                </div>
            </div>
        );
    }

    function displayContent() {
        if (canDisplayContent) {
            if (acknowledgementContent) {
                return (
                    displayAcknowledgementContent()
                );                
            } else if (singleScorm) {
                return (
                    displaySingleScormContent()
                );                
            } else if (aicc) {
                return (
                    displayAICCContent()
                );                
            } else {
                return (<></>);
            }
        } else {
            return (
                <div style={{ padding: "20px", backgroundColor:companyData?.PagesBGColor, borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <h5 style={{ color:companyData?.Darkgrey, textAlign: "center", marginBottom: "15px" }}>Further Preparation Required</h5>
                    <p style={{ fontSize: "1.0rem", lineHeight: "1.6", textAlign: "center", color: "#555" }}>
                    </p>
                    <div style={{ marginTop: "20px", padding: "10px 20px", borderLeft: `5px solid ${companyData?.AccentColor || "#28a745"}`, backgroundColor: companyData?.PagesBGColor, borderRadius: "5px" }}>
                        <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.0rem", color:companyData?.Grey }}>
                            <span>📢 Based on your pretest results, additional learning is recommended before proceeding. Take the time to strengthen your understanding, and revisit this content when you're ready to continue your journey.</span>
                        </p>
                    </div>
                </div>
            );    
        }
    }

    function displayAcknowledgementContent() {
        return (
            <>
            <div style={{ display: 'flex', justifyContent:'right', alignItems:'center', gap: '5px', padding:'10px'}}>
                {/* <input 
                    type="checkbox" 
                    id={'ackwbt' + launchID}
                    style={{ position:'relative', opacity: '1', width: '16px', height: '16px', pointerEvents: 'auto', verticalAlign:'middle' }}                                                   
                    onChange={(e) => (setAcknowledgementChecked(e.target.value))}
                /> */}
                <label className="form-check-label" htmlFor={'ackwbt' + launchID} style={styles.TextDataEntryNoPadding}>
                    {acknowledgementText}
                </label>                  
                <Button onClick={() => handleAcknowledge()} disabled={loading} style={styles.CDPillButtonSMGrey} title='Acknowlegde'>Acknowledge</Button>
            </div>
            <div>
                <iframe src={acknowledgementURL} style={{width:'100%', height:'60vh', border: `1px solid ${companyData?.Grey || "#ccc"}` }}></iframe>
            </div>
            </>
        );
    }
        
    const getSCORMVersion = (schemaVersion) => {
        const scormMapping = {
            "1.2": KnowledgePlayerType.SCORM12,
            "SCORM 1.2": KnowledgePlayerType.SCORM12,
            "1.3": KnowledgePlayerType.SCORM2004,
            "SCORM 2004": KnowledgePlayerType.SCORM2004,
            "CAM 1.3": KnowledgePlayerType.SCORM2004,
            "2004 2nd Edition": KnowledgePlayerType.SCORM2004,
            "2004 3rd Edition": KnowledgePlayerType.SCORM2004,
            "2004 4th Edition": KnowledgePlayerType.SCORM2004,
        };
    
        return scormMapping[schemaVersion] || KnowledgePlayerType.SCORM12;
    };    

    function displaySingleScormContent() {
        //<iframe src={scorm[0].ScormURL} style={{width:'100%', height:'60vh', border: `1px solid ${companyData?.Grey || "#ccc"}` }}></iframe>
        if ((scorm) && (scorm.length > 0) && (scorm[0]?.ScormURL)) {
            // console.log(scorm[0]?.ScormURL);
            // console.log(scorm[0]?.SchemaVersion);
            const scormVersion = getSCORMVersion(scorm[0]?.SchemaVersion);
            return (
                <div>
                    <ScormAiccPlayer knowledgePlayerType={scormVersion} courseUrl={scorm[0].ScormURL} onCommit={handleScormAICCCommit} 
                        sessionid={launchID} userid={auth?.UserID} gradingTypeID={gradingTypeID} passingScore={passingScore}
                        sessionCreditUnits={sessionCreditUnits} sessionCreditHours={sessionCreditHours} sessionCreditOthers={sessionCreditOthers}
                        />
                </div>
            );                
        } else {
            return (
                <div>
                    Content coming soon!
                </div>
            );    
        }
    }

    function displayAICCContent() {
        if (aiccUrl) {
            // console.log(aiccUrl);
            return (
                <div>
                    <ScormAiccPlayer knowledgePlayerType={KnowledgePlayerType.AICC} courseUrl={aiccUrl} onCommit={handleScormAICCCommit} 
                        sessionid={launchID} userid={auth?.UserID} gradingTypeID={gradingTypeID} passingScore={passingScore}
                        sessionCreditUnits={sessionCreditUnits} sessionCreditHours={sessionCreditHours} sessionCreditOthers={sessionCreditOthers}
                        />
                </div>
            );                
        } else {
            return (
                <div>
                    Content coming soon!
                </div>
            );    
        }
    }

    function displaySteps() {
        const currentStepIndex = filteredSteps.findIndex(step => step.value === radioStepValue);

        return (
            <Table responsive="sm" style={{backgroundColor:companyData?.PagesBGColor, padding:'0'}}>
                <tbody>
                    <tr>
                        <td style={{textAlign:'right', width:'10%', backgroundColor:companyData?.PagesBGColor}}>
                        {radioStepValue !== 1 && (
                            <a 
                                style={{ cursor: 'pointer', fontWeight: 'bold', color: companyData?.AccentColorSecondary }} 
                                onClick={() => handleStepperPrevious()}
                            >
                                <span style={styles.ActionIconGreyBold32} class="material-symbols-outlined" title='Previous Step'>arrow_back_ios</span>                                    
                            </a>
                        )}
                        </td>
                        <td style={{textAlign:'center', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                            <Stepper 
                                activeStep={currentStepIndex} // Adjusting for zero-based index
                                styleConfig={{
                                    size: '1.6em', 
                                    labelFontSize: '0.98rem', 
                                    circleFontSize: '0.78rem', 
                                    activeBgColor: companyData?.AccentColor, 
                                    completedBgColor: companyData?.AccentColorSecondary, 
                                    inactiveBgColor: companyData?.Grey
                                }}
                            >
                                {filteredSteps.map((step, index) => (
                                    <Step key={index} style={{ whiteSpace: 'pre-wrap' }} label={step.name} />
                                ))}
                            </Stepper>            
                        </td>
                        <td style={{textAlign:'left', width:'10%', backgroundColor:companyData?.PagesBGColor}}>
                            {(radioStepValue !== 8) && 
                                <a style={{ cursor: 'pointer', fontWeight: 'bold', color: companyData?.AccentColorSecondary }} 
                                    onClick={() => handleStepperNext()}>
                                    <span style={styles.ActionIconGreyBold32} class="material-symbols-outlined" title='Next Step'>arrow_forward_ios</span>                                    
                                </a>
                            }
                        </td>
                    </tr>
                </tbody>
            </Table>                            
        );
    }

    function displayStepNavigation() {
        return (
            <div style={{ marginTop: '10px', display: 'flex', alignContent:'center', alignItem:'center', justifyContent: "center", gap: '10px', padding:'0' }}>
                <h6 style={styles.tableText}>
                    {(radioStepValue !== 1) && 
                    <a style={{cursor:'pointer'}} onClick={() => handleStepperPrevious()}>
                        <span title='Previous Step' style={{color:companyData?.AccentColorSecondary, fontWeight:'bold'}}>Previous Step</span>
                    </a>
                    }
                    {(radioStepValue !== 8) && 
                    <a style={{cursor:'pointer'}} onClick={() => handleStepperNext()}>
                        <span  title='Next Step' style={{color:companyData?.AccentColorSecondary, fontWeight:'bold'}}>&nbsp;&nbsp;&nbsp;&nbsp;Next Step</span>
                    </a>
                    }
                </h6>
            </div>            
        );
    }
    
    function getIntroductionMessageIcons(steps) {
        const hasPreAssessment = steps.some(step => step.value === 2);
        const hasContent = steps.some(step => step.value === 4);
        const hasPostAssessment = steps.some(step => step.value === 5);
        const hasEvaluations = steps.some(step => step.value === 7);
        const sGradingType = (gradingTypeID === GradingType.NotGraded) ? 'is not Graded' : (gradingTypeID === GradingType.PassAndFail) ? ' requires passing' : 'requires passing score of ' + passingScore;
        const sPassingScore = (gradingTypeID === GradingType.PassingScoreRequired) ? passingScore : '';
        const sCreditUnits = (sessionCreditUnits) ? ' Credits Units - ' + sessionCreditUnits : '';
        const sCreditHours = (sessionCreditHours) ? ' Credits Hours - ' + sessionCreditHours : '';
        const sCreditOthers = (sessionCreditOthers) ? ' Credits Others - ' + sessionCreditOthers : '';
        const sKnowledgeType = (knowledgeType === 1) ? 'Instructor-led' : (acknowledgementContent) ? 'Acknowledge Knowledge' : 'E-Learning';
    
        return (
            <div style={{ padding: "20px", backgroundColor:companyData?.PagesBGColor, borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                {/* <h4 style={{ color: "#333", textAlign: "center", marginBottom: "15px" }}>🚀 Welcome to the Knowledge Hub</h4> */}
                <h5 style={{ color:companyData?.Darkgrey, textAlign: "center", marginBottom: "15px" }}>Welcome to the Knowledge Hub</h5>
                <p style={{ fontSize: "1.0rem", lineHeight: "1.6", textAlign: "center", color: "#555" }}>
                    Step into an interactive space where AI/ML-powered insights and learning experiences adapt to your journey. 
                </p>
                <div style={{ marginTop: "20px", padding: "10px 20px", borderLeft: `5px solid ${companyData?.AccentColor || "#28a745"}`, backgroundColor: companyData?.PagesBGColor, borderRadius: "5px" }}>
                    <h5 style={{ color:companyData?.Grey, textAlign: "center", marginBottom: "15px" }}>Your Track - {sKnowledgeType}</h5>
                    {/* <strong>Your Learning Path:</strong><br></br> */}
                    <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.0rem", color:companyData?.Grey }}>
                    <ul style={{ listStyleType: "none", padding: 5 }}>
                        <li>📌 <strong>Introduction - </strong> Get an overview of what’s ahead.<br></br></li>
                        {hasPreAssessment && <li>📊 <strong>Pre-Assessment - </strong> Check where you stand before starting.<br></br></li>}
                        {hasContent && <li>📚 <strong>Explore Content - </strong> Dive into curated materials and insights.<br></br></li>}
                        {hasPostAssessment && <li>📝 <strong>Post-Assessment - </strong> Test what you've learned.<br></br></li>}
                        {hasEvaluations && <li>🔍 <strong>Final Reflections - </strong> Provide feedback and wrap up.<br></br></li>}
                        {/* <li>🎯 <strong>Summary - </strong> Recap key takeaways from your journey.</li> */}
                        <li>🎯 <strong>Summary - </strong> This Knowledge {sGradingType}. {sCreditUnits} {sCreditHours} {sCreditOthers}</li>
                    </ul>
                    </p>
                </div>
                <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.1rem", color:companyData?.Grey }}>
                    <strong>Let’s get started!</strong> 
                </p>
            </div>
        );
    }

    function getSummaryMessageIcons() {
        if (knowledgeSessionStatus === LOLifeCycleStatus.Complete) {
            return (
                <div style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <h4 style={{ color:companyData?.Darkgrey, textAlign: "center", marginBottom: "15px" }}>Journey Complete!</h4>
                    <p style={{ fontSize: "1.0rem", lineHeight: "1.6", textAlign: "center", color: "#555" }}>
                        You’ve explored key insights, engaged with content, and gained new knowledge.  
                        Every step has helped build a stronger understanding — and now, it's time to apply it!  
                    </p>
                    <div style={{ marginTop: "20px", padding: "10px 20px", borderLeft: `5px solid ${companyData?.AccentColor || "#28a745"}`, backgroundColor: companyData?.PagesBGColor, borderRadius: "5px" }}>
                        <h5 style={{ color:companyData?.Grey, textAlign: "center", marginBottom: "15px" }}>Key Takeaways</h5>
                        {/* <strong>📌 Key Takeaways:</strong> */}
                        <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.0rem", color:companyData?.Grey }}>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                            <li>✅ Refreshed Understanding – You’ve covered essential concepts.</li><br></br>
                            <li>✅ Self-Assessment & Growth – Identified strengths and areas to improve.</li><br></br>
                            <li>✅ Actionable Insights – Ready to implement what you've learned.</li>
                        </ul>
                        </p>
                    </div>
                    <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.1rem", color:companyData?.Grey }}>
                        Keep exploring, apply what you’ve learned, and continue growing!  
                    </p>
                </div>
            );    
        } else {
            return (
                <div style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <h4 style={{ color:companyData?.Darkgrey, textAlign: "center", marginBottom: "15px" }}>Your Knowledge Journey Continues!</h4>
                    <p style={{ fontSize: "1.0rem", lineHeight: "1.6", textAlign: "center", color: "#555" }}>
                        You’ve started uncovering key insights and exploring valuable content. Keep going—there’s more to discover, learn, and apply!
                    </p>
                    <div style={{ marginTop: "20px", padding: "10px 20px", borderLeft: `5px solid ${companyData?.AccentColor || "#28a745"}`, backgroundColor: companyData?.PagesBGColor, borderRadius: "5px" }}>
                        <h5 style={{ color:companyData?.Grey, textAlign: "center", marginBottom: "15px" }}>Where You Left Off</h5>
                        {/* <strong>📌 Key Takeaways:</strong> */}
                        <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.0rem", color:companyData?.Grey }}>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                            <li>🔹 Exploration in Progress – Continue engaging with the content at your own pace.</li><br></br>
                            <li>🔹 Build Your Understanding – Each section you complete strengthens your knowledge.</li><br></br>
                            <li>🔹 Track Your Progress – Come back anytime and pick up where you left off.</li>
                        </ul>
                        </p>
                    </div>
                    <p style={{ textAlign: "center", marginTop: "15px", fontSize: "1.1rem", color:companyData?.Grey }}>
                        Stay curious, keep learning, and finish strong!  
                    </p>
                </div>
            );    
        }
    }

    function displayMainContainerEx() {
        if (radioStepValue === 1) {
            const introductionMessage = getIntroductionMessageIcons(filteredSteps);
            return (
                <div>
                    {introductionMessage}
                </div>
            );
        } else if (radioStepValue === 2) {
            return (
                <div>
                    {displayAssessment(true)}
                </div>
            );
        } else if (radioStepValue === 3) {
            return (
                <div>
                    {displayAssessmentSummary(true)}
                </div>
            );
        } else if (radioStepValue === 4) {
            return (
                <div>
                    {displayContent()}
                </div>
            );
        } else if (radioStepValue === 5) {
            return (
                <div>
                    {displayAssessment(false)}
                </div>
            );
        } else if (radioStepValue === 6) {
            return (
                <div>
                    {displayAssessmentSummary(false)}
                </div>
            );
        } else if (radioStepValue === 7) {
            return (
                <div>
                    {displayEvaluation()}
                </div>
            );
        } else if (radioStepValue === 8) {
            const summaryMessage = getSummaryMessageIcons();
            return (
                <div>
                    {summaryMessage}
                </div>
            );
        } 
    }

    function displayMainContainer() {
        return (                
            <>
            <Modal style={{background:'transparent'}} aria-labelledby="contained-modal-title-vcenter" centered 
                show={showAlertModal} onHide={handleAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing your request!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to submit the Questionnaire?</p>
                <p>If you select 'Yes' - your responses are saved and the questionnaire will marked as complete. You cannot change your responses once marked complete.</p>                            
                <p>If you select 'No' - your responses are saved and the questionnaire will NOT marked as complete. You can come again to change your responses.</p>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal>        

            <form onSubmit={handleSubmit}>
                <Card style={{ alignContent:'center', alignItem:'center', justifyContent: "center", backgroundColor:companyData?.PagesBGColor, padding:'0'}}>
                    {(!fromLookup) ? 
                        <Card.Header className='text-center' style={{backgroundColor:companyData?.PagesBGColor, padding:'0'}}> 
                            <Card.Title>
                                <div>
                                    <Table responsive="sm" style={{backgroundColor:companyData?.PagesBGColor, padding:'0'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'90%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>{knowledgeName}</h3></td>
                                                <td style={{textAlign:'center', width:'10%', backgroundColor:companyData?.PagesBGColor}}><Button disabled={loading} style={styles.CDFullButton} onClick={() => handleCancel()}>Close</Button></td>
                                            </tr>
                                        </tbody>
                                    </Table>                            
                                </div>
                                {(showAlertMessage) ?
                                    <Card.Subtitle>
                                        <div>
                                            <h6><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} alertTimeout={AlertEnrollmentTimeOut} handleParentAlertClose={handleCloseAlert} /></h6>
                                        </div>
                                    </Card.Subtitle>
                                    :
                                        <></>
                                }
                                <Card.Subtitle style={{backgroundColor:companyData?.PagesBGColor, padding:'0'}}>
                                    {displaySteps()}
                                    {/* {displayStepNavigation()} */}
                                </Card.Subtitle>
                            </Card.Title>
                        </Card.Header>                    
                    :
                        <></>
                    }
                    <Card.Body>
                        {(loading) ? 
                        <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                            <Spinner/>
                        </div>
                        :
                        <div >
                            {displayMainContainerEx()}
                        </div>
                        }
                    </Card.Body>
                    <Card.Footer>
                    </Card.Footer>
                </Card>
            </form>
            </>
        );
    }

    return (
        <>
            {(!fromLookup) ? 
                <div style={styles.MainInnerDiv}>
                    <div style={styles.DashboardContainer}>
                        {displayMainContainer()}
                    </div>
                </div>
            :
                <>
                    {displayMainContainer()}
                </>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
      auth: state.auth.user,
      userDetail: state.auth.userDetail,
      companyData: state?.auth?.companyData,
      evaluation: state?.entity?.evaluation,
      evaluationquestions: state?.entity?.evaluationquestions,
      evaluationratings: state?.entity?.evaluationratings,
      evaluationquestionratings: state?.entity?.evaluationquestionratings,
      userevaluationsessions: state?.entity?.userevaluationsessions, 
      launchuserevaluation: state?.entity?.userevaluation?.UserEvaluation, 
      launchuserevaluationsession: state?.entity?.userevaluation?.UserEvaluationSession, 
      knowledgesessionevaluation: state?.entity?.knowledgesessionevaluation?.knowledgesessionevaluation?.LearningObjectSessionEvaluation, 
      goals: state?.entity?.goals?.goals, 
      subgoals: state?.entity?.goals?.goals?.EvaluationQuestions,
      subgoalratings: state?.entity?.goals?.goals?.EvaluationQuestionRatings,
      subgoalgroups: state?.entity?.goals?.goals?.EvaluationQuestionGroups,
      knowledgesessions: state.entity.knowledgesessions, 
      myknowledgesessions: state.entity.myknowledgesessions, 
      myteamknowledgesessions: state.entity.mycalendar, 
      testlibrary: state?.entity?.testlibrary,
      knowledgesessionassessmentsummary: state?.entity?.knowledgesessionassessment?.knowledgesessionassessment?.LearningObjectSessionTestAnswerSummary,
      attachments:state.entity.attachments?.attachments,
      enrollmentMessage: state.entity.enrollmentMessage,
      scorm: state?.entity?.scorm?.scorm?.Contents,
      scormusersession: state?.entity?.scormusersession?.scormusersession?.ScormUserSession,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvaluationResponses: (evaluationResponses) => dispatch(updateEvaluationResponses(evaluationResponses)),
        updateUserEvaluationSession: (evaluationSession) => dispatch(updateUserEvaluationSession(evaluationSession)),
        updateKnowledgeSessionEvaluationResponses: (evaluationResponses) => dispatch(updateKnowledgeSessionEvaluationResponses(evaluationResponses)),
        getUserEvaluationSessions: (id) => dispatch(getUserEvaluationSessions(id)),
        insertKnowledgeSessionAssessment: (oResponses) => dispatch(insertKnowledgeSessionAssessment(oResponses)),
        getKnowledgeSessionAssessment: (oParams) => dispatch(getKnowledgeSessionAssessment(oParams)),
        processEnrollment: (oParams) => dispatch(processEnrollment(oParams)),
        processEnrollmentClear: () => dispatch(processEnrollmentClear()),
        getKnowledgeSessionsByUser: (kParams) => dispatch(getKnowledgeSessionsByUser(kParams)),
        getAttachments: (oAParams) => dispatch(getAttachments(oAParams)),
        getAttachmentStream: (oAParams) => dispatch(getAttachmentStream(oAParams)),
        getScormForLaunch: (oSParams) => dispatch(getScormForLaunch(oSParams)),
        getScormUserSession: (oSParams) => dispatch(getScormUserSession(oSParams)),
        getAICCUserSession: (oSParams) => dispatch(getAICCUserSession(oSParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaunchKnowledge)

