import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap';
import { KnowledgeListType, LOLifeCycleStatus, KnowledgeType } from '../../config/Constant';
import { processEnrollmentClear, getUserCalendar } from '../../store/actions/entityActions';
import moment from 'moment';
import { styles } from '../layout/styles';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/daygrid'
import KnowledgeSessionDetail from './KnowledgeSessionDetail';

const MyCalendar = (props) => {
    const { companyData, auth, mycalendar } = props;
    const [loading, setLoading] = useState(false);
    const calendarRef = useRef(null);
    const [myKnowledge, setMyKnowledge] = useState([]);
    const [knowledgeSessionID, setKnowledgeSessionID] = useState(-1);
    const [knowledgeName, setKnowledgeName] = useState('');
    const [showSessionDetail, setShowSessionDetail] = useState(false);
    const [knowledgeStartDateTime, setKnowledgeStartDateTime] = useState(moment().startOf('month'));
    const [knowledgeEndDateTime, setKnowledgeEndDateTime] = useState(moment().endOf('month'));

    const oEvents = [
        {
          "id": "1",
          "title": "Meeting",
          "start": "2025-03-15T10:00:00",
          "end": "2025-03-15T12:00:00",
          "allDay": false,
          "extendedProps": {
            "description": "Project planning discussion"
          }
        },
        {
            "id": "4",
            "title": "Fun",
            "start": "2025-03-15T14:00:00",
            "end": "2025-03-15T14:30:00",
            "allDay": false,
            "extendedProps": {
              "description": "Project planning discussion"
            }
        },
        {
          "id": "2",
          "title": "Vacation",
          "start": "2025-03-20",
          "end": "2025-03-24",
          "allDay": true,
          "color": "red"
        },
          {
          "id": "3",
          "title": "Birthday Party",
          "start": "2025-03-17T19:00:00",
          "end": "2025-03-17T23:00:00",
          "allDay": false,
            "textColor": "white",
          "backgroundColor": "purple"
        }
      ];

    useEffect(() => {
      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        // Now you can safely use calendarApi methods
        calendarApi.gotoDate('2025-03-15'); 
      }      
    }, []);

    useEffect(() => {
      const events = mycalendar?.mycalendar?.LearningObjectSession?.map(item => ({
        id: item.LearningObjectSessionID,
        title: item.LearningObjectName,
        start: item.StartDateTime,
        end: item.EndDateTime,
        allDay: false,        
        extendedProps: {
            description: item.FullFacilityName
        }
      }));      
      setMyKnowledge(events);
    }, [mycalendar]);
    
    const handleEventClick = ({ event, el }) => {
      setKnowledgeSessionID(event.id);
      setKnowledgeName(event.title);
      handleShowSessionDetail();
    };

    // useEffect(() => {
    //   if (knowledgeSessionID > -1) {
    //     handleShowSessionDetail();
    //   }      
    // }, [knowledgeSessionID]);

    async function handleShowSessionDetail() {
      setShowSessionDetail(true); 
    };

    async function handleCloseSessionDetail() {
        setLoading(true);
        setKnowledgeSessionID(-1);
        await props.processEnrollmentClear();
        const oParams = {
            UserID:auth?.UserID,
            CompanyID:auth?.CompanyID,
            SearchTypeID:'1',
            GroupID:'2',
            StartDateTime: moment(knowledgeStartDateTime).format('YYYY-MM-DD hh:mm a'),
            EndDateTime: moment(knowledgeEndDateTime).format('YYYY-MM-DD hh:mm a'),
            LearningObjectProcessStatusTypeID: LOLifeCycleStatus.Enrolled,
            LearningObjectTypeID: KnowledgeType.Classroom,
            RecordSize:'10',
            PageNumber:'1',
        };                  
        await props.getUserCalendar(oParams);
        setShowSessionDetail(false);
        setLoading(false);
    }

    return (
      <>
      <Modal style={{background:'transparent', maxHeight:'100%'}} show={showSessionDetail} onShow={handleShowSessionDetail} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseSessionDetail}>
        <Modal.Header closeButton>
        <Modal.Title><h3 style={styles.SubHeader}>{knowledgeName}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <KnowledgeSessionDetail id={knowledgeSessionID} knowledgeListType={KnowledgeListType.User}/>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleCloseSessionDetail}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>

      <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          themeSystem="bootstrap5"
          height='100%'
          headerToolbar={{start:'prev', center:'title', end:'next'}}        
          ref={calendarRef}
          events={myKnowledge}
          eventClick={handleEventClick}
      />
      </>
    );    
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth.user,
        companyData: state?.auth?.companyData,
        mycalendar: state?.entity?.mycalendar,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getUserCalendar: (kParams) => dispatch(getUserCalendar(kParams)),
      processEnrollmentClear: () => dispatch(processEnrollmentClear()),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar)
