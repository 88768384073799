import * as actionTypes from "./actionTypes"; 
import RetentasAPI from "../../api/RetentasAPI";
import RetentasAPIMain from "../../api/RetentasAPIMain";
import { store } from '../store'
import Colors from '../../config/Colors';
import { MenuType } from "../../config/Constant";

async function getSignInUserDetails(id) {
    try {
        const userDetailURL = process.env.REACT_APP_RETENTASAPI_USERDETAILURL + '?id=' + id;
        const respDetail = await RetentasAPI.get(userDetailURL)
        // console.log('Get User detail Success - ', respDetail.data);
        // console.log(respDetail.data.Users[0]);
        const userData = respDetail.data;//.Users[0];
        return userData; 
    } catch(error1) {
      console.log('Error in get user details', error1);
      console.log(error1);
      return null;
    };
}

async function getMyMenus(userGroupPolicy) {
    try {
        // Initialize an array to store the menus
        const myMenus = [];

        if (userGroupPolicy) {
            // Iterate over MenuType enum
            Object.entries(MenuType).forEach(([menuName, menuID]) => {
                // Filter userGroupPolicy to find matching records
                const oMenuRec = userGroupPolicy?.filter((gpRec) => gpRec.MenuID === menuID) || [];

                // Determine bShow and iPermissionTypeID based on matching records
                let bShow = false;
                let iPermissionTypeID = 1;
                let iPolicyID = -1;

                if (oMenuRec.length > 0) {
                    // Find the record with the highest PermissionTypeID
                    const highestPermissionRecord = oMenuRec.reduce((highest, current) =>
                        current.PermissionTypeID > highest.PermissionTypeID ? current : highest
                    );           

                    bShow = true;
                    iPolicyID = highestPermissionRecord.PolicyID;
                    iPermissionTypeID = highestPermissionRecord.PermissionTypeID;
                }

                // Add the menu to the myMenus array
                myMenus.push({
                    MenuName: menuName,
                    MenuID: menuID,
                    Show: bShow,
                    PolicyID: iPolicyID,
                    PermissionTypeID: iPermissionTypeID,
                });
            });
        }

        // console.log('My Menus - ', myMenus);
        return myMenus;
    } catch (error1) {
        console.error('Error in getMyMenus:', error1);
        return null;
    }
}

async function getSignInUserGroups(id) {
    try {
        const userGroupURL = process.env.REACT_APP_RETENTASAPI_USERGROUPSURL + '?id=' + id;
        const respGroup = await RetentasAPI.get(userGroupURL)
        // console.log('Get User group Success - ', respGroup.data);
        // console.log(respGroup.data);
        const userGroup = respGroup.data;
        return userGroup; 
    } catch(error1) {
      console.log('Error in get user details', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserLicenses(id) {
    try {
        const userLicensesURL = process.env.REACT_APP_RETENTASAPI_GETUSERLICENSES + '?id=' + id;
        const respDetail = await RetentasAPI.get(userLicensesURL)
        // console.log('Get User Licenses Success - ', respDetail.data);
        // console.log(respDetail.data.UserLicense);
        const userData = respDetail.data.UserLicense;
        return userData; 
    } catch(error1) {
      console.log('Error in get user licenses', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserCredentials(id) {
    try {
        const userCredentialsURL = process.env.REACT_APP_RETENTASAPI_GETUSERCREDENTIALS + '?id=' + id;
        const respDetail = await RetentasAPI.get(userCredentialsURL)
        // console.log('Get User Credential Success - ', respDetail.data);
        // console.log(respDetail.data.UserCredential);
        const userData = respDetail.data.UserCredential;
        return userData; 
    } catch(error1) {
      console.log('Error in get user licenses', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserEvaluations(id) {
    try {
        const userEvaluationsURL = process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONS + '?id=' + id;
        const respDetail = await RetentasAPI.get(userEvaluationsURL)
        // console.log(respDetail.data.UserEvaluationSession360);
        const userData = respDetail.data;//.UserEvaluationSession360;
        return userData; 
    } catch(error1) {
      console.log('Error in get user evaluation sessions', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserGoals(id) {
    try {
        const userGoalsURL = process.env.REACT_APP_RETENTASAPI_GETUSERGOALS + '?id=' + id;
        const respDetail = await RetentasAPI.get(userGoalsURL)
        // console.log('Get User Credential Success - ', respDetail.data);
        // console.log(respDetail.data.UserCredential);
        const userData = respDetail.data.UserGoal;
        return userData; 
    } catch(error1) {
      console.log('Error in get user goals', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserSkills(id) {
    try {
        const userSkillsURL = process.env.REACT_APP_RETENTASAPI_GETUSERSKILLS + '?id=' + id;
        const respDetail = await RetentasAPI.get(userSkillsURL)
        // console.log('Get User Skill Success - ', respDetail.data);
        // console.log(respDetail.data.UserSkill);
        const userData = respDetail.data.UserSkill;
        return userData; 
    } catch(error1) {
      console.log('Error in get user skills', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserJobPositionRequirements(id) {
    try {
        const userJPRURL = process.env.REACT_APP_RETENTASAPI_GETUSERJOBPOSITIONREQUIREMENT + '?id=' + id;
        const respDetail = await RetentasAPI.get(userJPRURL)
        // console.log('Get User JPR Success - ', respDetail.data);
        // console.log(respDetail.data.UserJobPositionRequirements);
        const userData = respDetail.data.UserJobPositionRequirements;
        return userData; 
    } catch(error1) {
      console.log('Error in get user job position requirements', error1);
      console.log(error1);
      return null;
    };
}

async function getAllUserFutureJobPositions(id) {
    try {
        const userJPRURL = process.env.REACT_APP_RETENTASAPI_GETUSERFUTUREJOBPOSITION + '?id=' + id;
        const respDetail = await RetentasAPI.get(userJPRURL)
        // console.log('Get User future  JP Success - ', respDetail.data);
        // console.log(respDetail.data.UserFutureJobPosition);
        const userData = respDetail.data.UserFutureJobPosition;
        return userData; 
    } catch(error1) {
      console.log('Error in get user future job position', error1);
      console.log(error1);
      return null;
    };
}


async function getAllOrgDivisions(id) {
    try {
        const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETDIVISIONS + '?id=' + id;
        const respDivisions = await RetentasAPI.get(userDivisionURL)
        // console.log('Get org divisions Success - ', respDivisions.data[0]);
        const orgDivisions = JSON.parse(respDivisions.data[0].TreeJSON);
        return orgDivisions; 
    } catch(error1) {
      console.log('Error in get org divisions', error1);
      console.log(error1);
      return null;
    };
}

async function getAllJobClasses(id) {
    try {
        const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETJOBCLASSES + '?id=' + id;
        const respDivisions = await RetentasAPI.get(userDivisionURL)
        //console.log('Get org job classes Success - ', respDivisions.data[0]);
        const orgJobClasses = JSON.parse(respDivisions.data[0].TreeJSON);
        return orgJobClasses; 
    } catch(error1) {
      console.log('Error in get org job classes', error1);
      console.log(error1);
      return null;
    };
}

async function getOrgDivisionLeadershipAssessmentLevelsInner(id) {
    try {
        const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETDIVISIONLEADERSHIPASSESSMENTLEVELS + '?id=' + id;
        const respDivisions = await RetentasAPI.get(userDivisionURL)
        // console.log('Get org divisions Success - ', respDivisions.data[0]);
        const orgDivisionSelectedLALs = respDivisions.data;
        return orgDivisionSelectedLALs; 
    } catch(error1) {
      console.log('Error in get org division lals', error1);
      console.log(error1);
      return null;
    };
}

async function getOrgDivisionCompetencyAnalysisInner(id) {
    try {
        const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETDIVISIONCOMPETENCYANALYSIS + '?id=' + id;
        const respDivisions = await RetentasAPI.get(userDivisionURL)
        // console.log('Get org divisions Success - ', respDivisions.data[0]);
        const orgDivisionSelectedCompetencyAnalysis = respDivisions.data;
        return orgDivisionSelectedCompetencyAnalysis; 
    } catch(error1) {
      console.log('Error in get org division competency analysis', error1);
      console.log(error1);
      return null;
    };
}

async function getOrgJobPositionPillarAnalysisInner(ijpType, id) {
    try {
        const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONPILLARANALYSIS + '?ijptype=' + ijpType + '&id=' + id;
        const respDivisions = await RetentasAPI.get(userDivisionURL)
        // console.log('Get org divisions Success - ', respDivisions.data[0]);
        const orgJobPositionSelectedPillarAnalysis = respDivisions.data;
        return orgJobPositionSelectedPillarAnalysis; 
    } catch(error1) {
      console.log('Error in get org job position pillar analysis', error1);
      console.log(error1);
      return null;
    };
}

async function getOrgDivisionPillarAnalysisInner(id) {
    try {
        const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETDIVISIONPILLARANALYSIS + '?id=' + id;
        const respDivisions = await RetentasAPI.get(userDivisionURL)
        // console.log('Get org divisions Success - ', respDivisions.data[0]);
        const orgDivisionSelectedPillarAnalysis = respDivisions.data;
        return orgDivisionSelectedPillarAnalysis; 
    } catch(error1) {
      console.log('Error in get org division pillar analysis', error1);
      console.log(error1);
      return null;
    };
}

async function getAllReports(oParams) {
    try {
        const reportsURL = process.env.REACT_APP_RETENTASAPI_GETPGREPORTS;
        const respReports = await RetentasAPI.post(reportsURL, oParams)
        //const reportsURL = process.env.REACT_APP_RETENTASAPI_GETREPORTS + '?id=' + id;
        //const respReports = await RetentasAPI.get(reportsURL)
        // console.log('Get reports Success - ', respReports.data);
        const reports = respReports.data;
        return reports; 
    } catch(error1) {
      console.log('Error in get reports', error1);
      console.log(error1);
      return null;
    };
}

async function getUserReportDetails(id) {
    try {
        const userReportDetailsURL = process.env.REACT_APP_RETENTASAPI_GETRTUSERREPORTDETAILS + '?id=' + id;
        const respUserReportDetails = await RetentasAPI.get(userReportDetailsURL)
        // console.log('Get user report details Success - ', respUserReportDetails.data[0]);
        const userReportDetails = JSON.parse(respUserReportDetails.data[0].RetentionJSON);
        return userReportDetails; 
    } catch(error1) {
      console.log('Error in get user report details', error1);
      console.log(error1);
      return null;
    };
}

async function getUserReportPillar(id, pillarTypeID) {
    try {
        const pillarParams = {
            UserID:id,
            PillarTypeID:pillarTypeID,
        };        
        const userReportPillarsURL = process.env.REACT_APP_RETENTASAPI_GETRTUSERREPORTPILLARS;
        const respUserReportPillar = await RetentasAPI.post(userReportPillarsURL, pillarParams)
        // console.log('Get user report pillar Success - ', respUserReportPillar.data[0]);
        const userReportPillars = JSON.parse(respUserReportPillar.data[0].RetentionJSON);
        return userReportPillars; 
    } catch(error1) {
      console.log('Error in get user report pillars', error1);
      console.log(error1);
      return null;
    };
}

async function getUserReportPerformance(id, evaluationID) {
    try {
        const oParams = {
            UserID:id,
            EvaluationID:evaluationID,
        };        
        const userReportURL = process.env.REACT_APP_RETENTASAPI_GETRTUSERREPORTPERFORMANCE;
        const respUserReportPerformance = await RetentasAPI.post(userReportURL, oParams)
        // console.log('Get user report pillar Success - ', respUserReportPillar.data[0]);
        const userReportPerformance = JSON.parse(respUserReportPerformance.data[0].RetentionJSON);
        return userReportPerformance; 
    } catch(error1) {
      console.log('Error in get user report performance', error1);
      console.log(error1);
      return null;
    };
}

async function getMyTeam(id) {
    try {
        const teamParams = {
            UserID:id,
            RecordSize:'10',
            PageNumber:'1',
        };        
        const myTeamURL = process.env.REACT_APP_RETENTASAPI_GETMYTEAM;
        const respDetail = await RetentasAPI.post(myTeamURL, teamParams)
        // console.log('Get MyTeam Success - ', respDetail.data);
        // console.log(respDetail.data);
        const myTeamData = respDetail.data;
        return myTeamData; 
    } catch(error1) {
      console.log('Error in get my team', error1);
      console.log(error1);
      return null;
    };
}

async function getMyTeamSelection(teamParams) {
    try {
        const myTeamURL = process.env.REACT_APP_RETENTASAPI_GETMYTEAM;
        const respDetail = await RetentasAPI.post(myTeamURL, teamParams)
        // console.log('Get MyTeam Selected Success - ', respDetail.data);
        // console.log(respDetail.data);
        const myTeamData = respDetail.data;
        return myTeamData; 
    } catch(error1) {
      console.log('Error in get my team selected', error1);
      console.log(error1);
      return null;
    };
}

async function getMyTeamAll(teamParams) {
    try {
        const myTeamURL = process.env.REACT_APP_RETENTASAPI_GETMYTEAM;
        const respDetail = await RetentasAPI.post(myTeamURL, teamParams)
        // console.log('Get MyTeam Selected Success - ', respDetail.data);
        // console.log(respDetail.data);
        const myTeamData = respDetail.data;
        return myTeamData; 
    } catch(error1) {
      console.log('Error in get my team all', error1);
      console.log(error1);
      return null;
    };
}

async function getUserLookupAll(oParams) {
    try {
        const myUserLookupURL = process.env.REACT_APP_RETENTASAPI_GETUSERLOOKUP;
        const respDetail = await RetentasAPI.post(myUserLookupURL, oParams)
        // console.log('Get MyTeam Selected Success - ', respDetail.data);
        // console.log(respDetail.data);
        const userLookupData = respDetail.data;
        return userLookupData; 
    } catch(error1) {
      console.log('Error in get user lookup all', error1);
      console.log(error1);
      return null;
    };
}

async function getMyTeamRetentionSuggestion(id) {
    try {
        const userSuggestionURL = process.env.REACT_APP_RETENTASAPI_GETMYTEAMRETENTIONSUGGESTIONS + '?id=' + id;
        const respSuggestions = await RetentasAPI.get(userSuggestionURL)
        // console.log('Get My Team Suggestions Success - ', respSuggestions.data);
        //console.log(respSuggestions.data);
        const myTeamSuggestionData = respSuggestions.data;
        return myTeamSuggestionData; 
    } catch(error1) {
      console.log('Error in get my team suggestions', error1);
      console.log(error1);
      return null;
    };
}

export const getMyTeamRetentionPredictionHistory = (oParams) => {
    return async (dispatch) => {

        try {
            const userHistoryURL = process.env.REACT_APP_RETENTASAPI_GETMYTEAMRETENTIONPREDICTIONHISTORY;
            const respHistory = await RetentasAPI.post(userHistoryURL, oParams)
            // console.log('Get My Team Suggestions Success - ', respSuggestions.data);
            //console.log(respSuggestions.data);
            const myTeamRetentionPredictionHistoryData = respHistory.data;
            dispatch( {type: actionTypes.GETMYTEAMRETENTIONPREDICTIONHISTORY_SUCCESS, payload: {myTeamRetentionPredictionHistoryData} } );
        } catch (error1) {
            console.log('Error in get my team retention prediction history', error1);
            console.log(error1);
            dispatch( {type: actionTypes.GETMYTEAMRETENTIONPREDICTIONHISTORY_ERROR, payload: error1 } );
        }        
    }
}

async function getMyTeamRetentasStats(id, cid) {
    try {
        const statsParams = {
            CompanyID:cid,
            UserID:id,
            DivisionID:'',
            FacilityID:'',
            RegionID:'',
            JobPositionID:'',
            JobClassID:'',
            JobCodeID:'',
            EmploymentTypeID:'',
        };

        const respStats = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETRETENTASSTATS, statsParams)
        // console.log('Get My Team Stats Success - ', respStats.data);
        // console.log(respStats.data);
        const myTeamStats = respStats.data;
        return myTeamStats; 
    } catch(error1) {
      console.log('Error in get my team stats', error1);
      console.log(error1);
      return null;
    };
}

export const validateSSOState = (pState) => {
    return async (dispatch) => {

        try {
            const cParams = {
                State:pState,
            };        
            const resp = await RetentasAPIMain.post(process.env.REACT_APP_RETENTASAPI_VALIDATEUSERSSOSTATE, cParams)
            const ssoStateData = resp.data;
            if (ssoStateData?.CompanyID) {
                dispatch( {type: actionTypes.VALIDATESSOSTATE_SUCCESS, payload: {ssoStateData } } );    
            } else {
                const error = 'Error in Validate SSO State ';
                dispatch( {type: actionTypes.VALIDATESSOSTATE_ERROR, payload: {error} } );                            
            }
        } catch (error) {
            console.log('Error in  Validate SSO State', error);
            dispatch( {type: actionTypes.VALIDATESSOSTATE_ERROR, payload: {error} } );
        }        
    }
}

export const validateCompany = (id, completeQueryStr) => {
    return async (dispatch) => {

        const companyID = id;
        try {
            const cParams = {
                CompanyID:id,
                AppCallbackURL:process.env.REACT_APP_RETENTASAPI_SSOREDIRECTURL,
                AppQueryString: completeQueryStr,
            };        
            // AppCallbackURL:'https://localhost:3000/LoginSSO',
//            console.log(cParams);
            const resp = await RetentasAPIMain.post(process.env.REACT_APP_RETENTASAPI_VALIDATECOMPANY, cParams)
            // console.log('Validate Company Success - ', resp.data);
            // console.log(resp.data);
            const companyData = resp.data;
            if (companyData?.Validate === 'true') {
                dispatch( {type: actionTypes.VALIDATECOMPANY_SUCCESS, payload: {companyData, companyID } } );    
                const sParams = Colors(companyData);
            } else {
                const error = 'Error in Validate Company ';
                console.log(error);
                dispatch( {type: actionTypes.VALIDATECOMPANY_ERROR, payload: {error, companyID} } );                            
            }
        } catch (error) {
            console.log('Error in  Validate Company', error);
            console.log(error);
            dispatch( {type: actionTypes.VALIDATECOMPANY_ERROR, payload: {error, companyID} } );
        }        
    }
}

export const signIn = (credentials) => {
    return async (dispatch) => {

        const companyID = credentials?.companyID;
        try {
            const loginParams = {
                UserName:credentials.username,
                Password:credentials.password,
                CompanyID:credentials.companyID,
                Validate:'',
                UserID:'',
                UserPhotoPath:'',
                UserTranscriptPath:'',
                UserCompanyParameters:'',
                StartDate:'',
                EndDate:'',
                ErrorMsg:'',
                ReportURL:''
            };
        
            // console.log(loginParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_VALIDATEUSERURL, loginParams)
            // console.log('Login Success - ', resp.data);
            //console.log(resp.data);
            const userData = resp.data;
            if (userData?.Validate === 'true') {
                //get user details
                const userDetail = await getSignInUserDetails(userData?.UserID);
                const profileData = userDetail.Users[0];
                // const userGroupsData = await getSignInUserGroups(userData?.UserID);
                const userGroupsData = userDetail.UserGroups;
                if ((profileData?.IsManager === 'True') || (profileData?.IsAdministrator === 'True') || (profileData?.IsCSA === 'True')) {
                    const myTeamData = null;//await getMyTeam(userData?.UserID);
                    const myTeamSuggestionData = await getMyTeamRetentionSuggestion(userData?.UserID);
                    const myTeamStats = await getMyTeamRetentasStats(userData?.UserID, userData?.CompanyID);
                    const myMenus = await getMyMenus(userDetail?.UserGroupPolicy);
                    dispatch( {type: actionTypes.LOGIN_SUCCESS, payload: {userData, profileData, userGroupsData, myTeamData, myTeamSuggestionData, myTeamStats, userDetail, companyID, myMenus } } );    
                } else {
                    const myTeamData = null;
                    const myTeamSuggestionData = null;
                    const myTeamStats = null;
                    const myMenus = null;
                    dispatch( {type: actionTypes.LOGIN_SUCCESS, payload: {userData, profileData, userGroupsData, myTeamData, myTeamSuggestionData, myTeamStats, userDetail, companyID, myMenus } } );    
                }
            } else {
                const error = 'Error in login - Invalid Credentials';
                console.log(error);
                dispatch( {type: actionTypes.LOGIN_ERROR, payload: {error, companyID} } );                            
            }
        } catch (error) {
            console.log('Error in Login', error);
            console.log(error);
            dispatch( {type: actionTypes.LOGIN_ERROR, payload: {error, companyID} } );
        }        
    }
}

export const signInSSO = (credentials) => {
    return async (dispatch) => {

        const companyID = credentials?.companyID;
        try {
            const loginParams = {
                UserName:credentials.username,
                Password:credentials.password,
                CompanyID:credentials.companyID,
                SSOCode:credentials.ssocode,
                Validate:'',
                UserID:'',
                UserPhotoPath:'',
                UserTranscriptPath:'',
                UserCompanyParameters:'',
                StartDate:'',
                EndDate:'',
                ErrorMsg:'',
                ReportURL:''
            };
        
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_VALIDATEUSERSSOURL, loginParams)
            //console.log('Login Success - ', resp.data);
            // console.log(resp.data);
            const userData = resp.data;
            if (userData?.Validate === 'true') {
                //get user details
                const userDetail = await getSignInUserDetails(userData?.UserID);
                const profileData = userDetail.Users[0];
                // const userGroupsData = await getSignInUserGroups(userData?.UserID);
                const userGroupsData = userDetail.UserGroups;
                if ((profileData?.IsManager === 'True') || (profileData?.IsAdministrator === 'True') || (profileData?.IsCSA === 'True')) {
                    const myTeamData = null;//await getMyTeam(userData?.UserID);
                    const myTeamSuggestionData = await getMyTeamRetentionSuggestion(userData?.UserID);
                    const myTeamStats = await getMyTeamRetentasStats(userData?.UserID, userData?.CompanyID);
                    const myMenus = await getMyMenus(userDetail?.UserGroupPolicy);
                    dispatch( {type: actionTypes.LOGIN_SUCCESS, payload: {userData, profileData, userGroupsData, myTeamData, myTeamSuggestionData, myTeamStats, userDetail, companyID, myMenus } } );    
                } else {
                    const myTeamData = null;
                    const myTeamSuggestionData = null;
                    const myTeamStats = null;
                    const myMenus = null;
                    dispatch( {type: actionTypes.LOGIN_SUCCESS, payload: {userData, profileData, userGroupsData, myTeamData, myTeamSuggestionData, myTeamStats, userDetail, companyID, myMenus } } );    
                }
            } else {
                const error = 'Error in login - Invalid Credentials';
                console.log(error);
                dispatch( {type: actionTypes.LOGIN_ERROR, payload: {error, companyID} } );                            
            }
        } catch (error) {
            console.log('Error in Login', error);
            console.log(error);
            dispatch( {type: actionTypes.LOGIN_ERROR, payload: {error, companyID} } );
        }        
    }
}

export const getMyTeamSelected = (teamParams) => {
    return async (dispatch) => {

        try {
            // console.log(teamParams);
            const myTeamSelected = await getMyTeamSelection(teamParams);
            // console.log(myTeamSelected);
            dispatch( {type: actionTypes.MYTEAMSELECTED_SUCCESS, payload: {myTeamSelected} } );    
        } catch (error) {
            console.log('Error in getMyTeamSelected', error);
            console.log(error);
            dispatch( {type: actionTypes.MYTEAMSELECTED_ERROR, payload: error } );
        }        
    }
}

export const getMyTeamFull = (teamParams) => {
    return async (dispatch) => {

        try {
            // console.log(teamParams);
            const myTeam = await getMyTeamAll(teamParams);
            // console.log(myTeam);
            dispatch( {type: actionTypes.GETMYTEAM_SUCCESS, payload: {myTeam} } );    
        } catch (error) {
            console.log('Error in getMyTeamAll', error);
            console.log(error);
            dispatch( {type: actionTypes.GETMYTEAM_ERROR, payload: error } );
        }        
    }
}

export const getUserLookup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(teamParams);
            const userLookup = await getUserLookupAll(oParams);
            // console.log(myTeam);
            dispatch( {type: actionTypes.GETUSERLOOKUP_SUCCESS, payload: {userLookup} } );    
        } catch (error) {
            console.log('Error in getMyTeamAll', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERLOOKUP_ERROR, payload: error } );
        }        
    }
}

export const assignActiveMenu = (menuId) => {
    return async (dispatch) => {

        try {
            // console.log(menuId);
            dispatch( {type: actionTypes.ASSIGNACTIVEMENU_SUCCESS, payload: {menuId} } );    
        } catch (error) {
            console.log('Error in assignActiveMenu', error);
            console.log(error);
            dispatch( {type: actionTypes.ASSIGNACTIVEMENU_ERROR, payload: error } );
        }        
    }
}

export const loadingMenuSelection = (loadingMenu) => {
    return async (dispatch) => {

        try {
            // console.log(loadingMenu);
            dispatch( {type: actionTypes.LOADINGMENUSELECTION_SUCCESS, payload: {loadingMenu} } );    
        } catch (error) {
            console.log('Error in loadingMenuSelection', error);
            console.log(error);
            dispatch( {type: actionTypes.LOADINGMENUSELECTION_ERROR, payload: error } );
        }        
    }
}

export const getOrgDivisions = (id) => {
    return async (dispatch) => {

        try {
            const orgDivisions = await getAllOrgDivisions(id);
            // console.log(orgDivisions);
            dispatch( {type: actionTypes.GETORGDIVISIONS_SUCCESS, payload: {orgDivisions} } );    
        } catch (error) {
            console.log('Error in getOrgDivisions', error);
            console.log(error);
            dispatch( {type: actionTypes.GETORGDIVISIONS_ERROR, payload: error } );
        }        
    }
}

export const getOrgJobClasses = (id) => {
    return async (dispatch) => {

        try {
            const orgJobClasses = await getAllJobClasses(id);
            // console.log(orgDivisions);
            dispatch( {type: actionTypes.GETORGJOBCLASSES_SUCCESS, payload: {orgJobClasses} } );    
        } catch (error) {
            console.log('Error in getOrgJobClasses', error);
            console.log(error);
            dispatch( {type: actionTypes.GETORGJOBCLASSES_ERROR, payload: error } );
        }        
    }
}

export const getOrgDivisionLeadershipAssessmentLevels = (id) => {
    return async (dispatch) => {

        try {
            const orgDivisionSelectedLALs = await getOrgDivisionLeadershipAssessmentLevelsInner(id);
            // console.log(orgDivisions);
            dispatch( {type: actionTypes.GETDIVISIONLEADERSHIPASSESSMENTLEVELS_SUCCESS, payload: {orgDivisionSelectedLALs} } );    
        } catch (error) {
            console.log('Error in getOrgDivisionLeadershipAssessmentLevels', error);
            console.log(error);
            dispatch( {type: actionTypes.GETDIVISIONLEADERSHIPASSESSMENTLEVELS_ERROR, payload: error } );
        }        
    }
}

export const getOrgDivisionCompetencyAnalysis = (id) => {
    return async (dispatch) => {

        try {
            const orgDivisionSelectedCompetencyAnalysis = await getOrgDivisionCompetencyAnalysisInner(id);
            // console.log(orgDivisions);
            dispatch( {type: actionTypes.GETDIVISIONCOMPETENCYANALYSIS_SUCCESS, payload: {orgDivisionSelectedCompetencyAnalysis} } );    
        } catch (error) {
            console.log('Error in getOrgDivisionCompetencyAnalysis', error);
            console.log(error);
            dispatch( {type: actionTypes.GETDIVISIONCOMPETENCYANALYSIS_ERROR, payload: error } );
        }        
    }
}

export const getOrgJobPositionPillarAnalysis = (ijptype, id) => {
    return async (dispatch) => {

        try {
            const orgJobPositionSelectedPillarAnalysis = await getOrgJobPositionPillarAnalysisInner(ijptype, id);
            // console.log(orgDivisions);
            dispatch( {type: actionTypes.GETJOBPOSITIONPILLARANALYSIS_SUCCESS, payload: {orgJobPositionSelectedPillarAnalysis} } );    
        } catch (error) {
            console.log('Error in getOrgJobPositionPillarAnalysis', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONPILLARANALYSIS_ERROR, payload: error } );
        }        
    }
}

export const getOrgDivisionPillarAnalysis = (id) => {
    return async (dispatch) => {

        try {
            const orgDivisionSelectedPillarAnalysis = await getOrgDivisionPillarAnalysisInner(id);
            // console.log(orgDivisions);
            dispatch( {type: actionTypes.GETDIVISIONPILLARANALYSIS_SUCCESS, payload: {orgDivisionSelectedPillarAnalysis} } );    
        } catch (error) {
            console.log('Error in getOrgDivisionPillarAnalysis', error);
            console.log(error);
            dispatch( {type: actionTypes.GETDIVISIONPILLARANALYSIS_ERROR, payload: error } );
        }        
    }
}

export const getDivisionManagers = (id) => {
    return async (dispatch) => {

        try {
            const userDivisionURL = process.env.REACT_APP_RETENTASAPI_GETDIVISIONMANAGERS + '?id=' + id;
            const respDivisions = await RetentasAPI.get(userDivisionURL)
            // console.log('Get org divisions Success - ', respDivisions.data[0]);
            const orgDivisionManagers = JSON.parse(respDivisions.data[0].TreeJSON);
            // console.log(orgDivisionManagers);
            dispatch( {type: actionTypes.GETDIVISIONMANAGERS_SUCCESS, payload: {orgDivisionManagers} } );    
        } catch (error) {
            console.log('Error in getOrgDivisionManagers', error);
            console.log(error);
            dispatch( {type: actionTypes.GETDIVISIONMANAGERS_ERROR, payload: error } );
        }        
    }
}

export const forgotPassword = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_FORGOTPASSWORD, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.FORGOTPASSWORD_SUCCESS, payload: {returnParams} } );    
        } catch (error) {
            console.log('Error in forgotPassword', error);
            console.log(error);
            dispatch( {type: actionTypes.FORGOTPASSWORD_ERROR, payload: error } );
        }        
    }
}

export const resetPassword = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_RESETPASSWORD, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.RESETPASSWORD_SUCCESS, payload: {returnParams} } );    
        } catch (error) {
            console.log('Error in resetPassword', error);
            console.log(error);
            dispatch( {type: actionTypes.RESETPASSWORD_ERROR, payload: error } );
        }        
    }
}

export const getProfileDetails = (id) => {
    return async (dispatch) => {
        try {
            // console.log(id);
            const userDetail = await getSignInUserDetails(id);
            const profileData = userDetail.Users[0];
            dispatch( {type: actionTypes.GETUSERPROFILE_SUCCESS, payload: {profileData} } );    
        } catch(error1) {
            console.log('Error in get user profile details', error1);
            console.log(error1);
            dispatch( {type: actionTypes.GETUSERPROFILE_ERROR, payload: error1 } );
        };
    }
}

export const getUserDetails = (id) => {
    return async (dispatch) => {
        try {
            console.log(id);
            const userDetailURL = process.env.REACT_APP_RETENTASAPI_USERDETAILURL + '?id=' + id;
            const respDetail = await RetentasAPI.get(userDetailURL)
            // console.log('Get User detail Success - ', respDetail.data);
            // console.log(respDetail.data.Users[0]);
            const selectedProfile = respDetail.data;
            // const profileData = userDetail.Users[0];
            dispatch( {type: actionTypes.GETSELECTEDUSERPROFILE_SUCCESS, payload: {selectedProfile} } );    
        } catch(error1) {
            console.log('Error in get user details', error1);
            console.log(error1);
            dispatch( {type: actionTypes.GETSELECTEDUSERPROFILE_ERROR, payload: error1 } );
        };
    }
}

export const getUserGroups = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserGroups = await getSignInUserGroups(id);
            // console.log(UserGroup);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERGROUPSELECTED_SUCCESS, payload: {UserGroups} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERGROUP_SUCCESS, payload: {UserGroups} } );    
            }
        } catch (error) {
            console.log('Error in getUserGroups', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERGROUP_ERROR, payload: error } );
        }        
    }
}

export const getUserLicenses = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserLicense = await getAllUserLicenses(id);
            // console.log(UserLicense);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERLICENSESELECTED_SUCCESS, payload: {UserLicense} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERLICENSE_SUCCESS, payload: {UserLicense} } );    
            }
        } catch (error) {
            console.log('Error in getUserLicenses', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERLICENSE_ERROR, payload: error } );
        }        
    }
}

export const getUserCredentials = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserCredential = await getAllUserCredentials(id);
            // console.log(UserCredential);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERCREDENTIALSELECTED_SUCCESS, payload: {UserCredential} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERCREDENTIAL_SUCCESS, payload: {UserCredential} } );    
            }
        } catch (error) {
            console.log('Error in getUserCredential', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const getUserEvaluationSessions360 = (id) => {
    return async (dispatch) => {

        try {
            const userData = await getAllUserEvaluations(id);
            const UserEvaluationSession360 = userData.UserEvaluationSession360;
            const UserEvaluationSession360StepSummary = userData.UserEvaluationSession360StepSummary;
            dispatch( {type: actionTypes.GETUSEREVALUATIONS_SUCCESS, payload: {UserEvaluationSession360, UserEvaluationSession360StepSummary} } );    
        } catch (error) {
            console.log('Error in getUserEvaluationSessions', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSEREVALUATIONS_ERROR, payload: error } );
        }        
    }
}

export const getUserGoals = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserGoal = await getAllUserGoals(id);
            // console.log(UserCredential);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERGOALSELECTED_SUCCESS, payload: {UserGoal} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERGOAL_SUCCESS, payload: {UserGoal} } );    
            }
        } catch (error) {
            console.log('Error in getUserCredential', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERGOAL_ERROR, payload: error } );
        }        
    }
}

export const getUserSkills = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserSkill = await getAllUserSkills(id);
            // console.log(UserSkill);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERSKILLSELECTED_SUCCESS, payload: {UserSkill} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERSKILL_SUCCESS, payload: {UserSkill} } );    
            }
        } catch (error) {
            console.log('Error in getUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const getUserJobPositionRequirements = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserJobPositionRequirements = await getAllUserJobPositionRequirements(id);
            // console.log(UserJobPositionRequirements);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERJOBPOSITIONREQUIREMENTSSELECTED_SUCCESS, payload: {UserJobPositionRequirements} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERJOBPOSITIONREQUIREMENTS_SUCCESS, payload: {UserJobPositionRequirements} } );    
            }
        } catch (error) {
            console.log('Error in getUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERJOBPOSITIONREQUIREMENTS_ERROR, payload: error } );
        }        
    }
}

export const getUserFutureJobPositions = (id, selectedUser) => {
    return async (dispatch) => {

        try {
            const UserFutureJobPosition = await getAllUserFutureJobPositions(id);
            // console.log(UserFutureJobPosition);
            if (selectedUser) {
                dispatch( {type: actionTypes.GETUSERFUTUREJOBPOSITIONSSELECTED_SUCCESS, payload: {UserFutureJobPosition} } );    
            } else {
                dispatch( {type: actionTypes.GETUSERFUTUREJOBPOSITIONS_SUCCESS, payload: {UserFutureJobPosition} } );    
            }
        } catch (error) {
            console.log('Error in getUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERFUTUREJOBPOSITIONS_ERROR, payload: error } );
        }        
    }
}

export const getReports = (oParams) => {
    return async (dispatch) => {

        try {
            const reports = await getAllReports(oParams);
            // console.log(reports);
            dispatch( {type: actionTypes.GETRTREPORTS_SUCCESS, payload: {reports} } );    
        } catch (error) {
            console.log('Error in get reports', error);
            console.log(error);
            dispatch( {type: actionTypes.GETRTREPORTS_ERROR, payload: error } );
        }        
    }
}

export const getUserReportData = (id) => {
    return async (dispatch) => {

        try {
            const userReportDetails = await getUserReportDetails(id);
            // console.log(userReportDetails);
            dispatch( {type: actionTypes.GETRTUSERREPORTDETAILS_SUCCESS, payload: {userReportDetails} } );    
        } catch (error) {
            console.log('Error in UserReportData', error);
            console.log(error);
            dispatch( {type: actionTypes.GETRTUSERREPORTDETAILS_ERROR, payload: error } );
        }        
    }
}

export const getUserReportPillarData = (id, pillarTypeID) => {
    return async (dispatch) => {

        try {
            const userReportPillar = await getUserReportPillar(id, pillarTypeID);
            // console.log(userReportPillar);
            dispatch( {type: actionTypes.GETRTUSERREPORTPILLARS_SUCCESS, payload: {userReportPillar} } );    
        } catch (error) {
            console.log('Error in getUserReportPillarData', error);
            console.log(error);
            dispatch( {type: actionTypes.GETRTUSERREPORTPILLARS_ERROR, payload: error } );
        }        
    }
}

export const getUserReportPerformanceData = (id, evaluationID) => {
    return async (dispatch) => {

        try {
            const userReportPerformance = await getUserReportPerformance(id, evaluationID);
            // console.log(userReportPillar);
            dispatch( {type: actionTypes.GETRTUSERREPORTPERFORMANCE_SUCCESS, payload: {userReportPerformance} } );    
        } catch (error) {
            console.log('Error in getUserReportPerformanceData', error);
            console.log(error);
            dispatch( {type: actionTypes.GETRTUSERREPORTPERFORMANCE_ERROR, payload: error } );
        }        
    }
}

export const signOut = () => {
    return async (dispatch) => {
        store.dispatch({type: "RESET"});
        dispatch({type: actionTypes.SIGNOUT_SUCCESS});
    }
}

export const signUp = (newUser) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        await firebase.auth().createUserWithEmailAndPassword(
            newUser.email, 
            newUser.password
        ).then( (resp) => {
            return firestore.collection('users').doc(resp.user.uid).set({
                firstname: newUser.firstname,
                lastname: newUser.lastname,
                initials: newUser.firstname[0] + newUser.lastname[0]
            }).then(() => {
                dispatch( {type: actionTypes.SIGNUP_SUCCESS })
            }).catch (err => {
                dispatch( {type: actionTypes.SIGNUP_ERROR, err })                
            })
        }).catch((err) => {
            dispatch( {type: actionTypes.SIGNUP_ERROR, err })
        });
    }
}

export const updateprofile = (currentUser, user, useruid) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        return firestore.collection('users').doc(useruid).set({
            firstname: user.firstname,
            lastname: user.lastname,
            initials: user.firstname[0] + user.lastname[0],
            profileimageurl: user.profileimageurl,
        }).then(() => {
            dispatch( {type: actionTypes.UPDATEPROFILE_SUCCESS })
        }).catch (err => {
            dispatch( {type: actionTypes.UPDATEPROFILE_ERROR, err })                
        })
    }
}

export const getMyTeamRetentionSuggestions = (id) => {
    return async (dispatch) => {

        try {
            // console.log(id);
            const myTeamSuggestionData = await getMyTeamRetentionSuggestion(id);
            dispatch( {type: actionTypes.GETMYTEAMRETENTIONSUGGESTIONS_SUCCESS, payload: {myTeamSuggestionData} } );
        } catch (error) {
            console.log('Error in getRetentionPrediction', error);
            console.log(error);
            dispatch( {type: actionTypes.GETMYTEAMRETENTIONSUGGESTIONS_ERROR, payload: error } );
        }        
    }
}

export const getMyTeamStatSelected = (id, cid) => {
    return async (dispatch) => {

        try {
            const myTeamStatSelected = await getMyTeamRetentasStats(id, cid);
            // console.log(myTeamStatSelected);
            dispatch( {type: actionTypes.GETMYTEAMSTATSELECTED_SUCCESS, payload: {myTeamStatSelected} } );    
        } catch (error) {
            console.log('Error in getmyteamstat Selected', error);
            console.log(error);
            dispatch( {type: actionTypes.GETMYTEAMSTATSELECTED_ERROR, payload: error } );
        }        
    }
}

export const assignRestructureDetails = (rParams) => {
    return async (dispatch) => {

        try {
            // console.log(rParams);
            dispatch( {type: actionTypes.ASSIGNRESTRUCTUREDETAILS_SUCCESS, payload: {rParams} } );    
        } catch (error) {
            console.log('Error in assignRestructureDetails', error);
            console.log(error);
            dispatch( {type: actionTypes.ASSIGNRESTRUCTUREDETAILS_ERROR, payload: error } );
        }        
    }
}

