import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";

const movies = Array.from({ length: 36 }, (_, i) => ({
  id: i + 1,
  title: `Movie ${i + 1}`,
  img: "https://via.placeholder.com/200x300",
}));

const TryOut = () => {
  const moviesPerPage = 12; // Show 12 movies per page (4x3 layout)
  const totalMovies = movies.length;
  const [page, setPage] = useState(0);
  const [hoveredMovie, setHoveredMovie] = useState(null);

  const startIdx = page * moviesPerPage;
  const endIdx = startIdx + moviesPerPage;
  const paginatedMovies = movies.slice(startIdx, endIdx);

  const nextPage = () => {
    if (startIdx + moviesPerPage < totalMovies) setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 0) setPage(page - 1);
  };

  return (
    <div style={{ width: "85%", margin: "auto", textAlign: "center" }}>
      <h2>Movie Gallery</h2>
      <motion.div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
          gridTemplateRows: "repeat(3, auto)", // 3 rows
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <AnimatePresence mode="wait">
          {paginatedMovies.map((movie) => (
            <motion.div
              key={movie.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              style={{
                position: "relative",
                background: "#222",
                color: "white",
                borderRadius: "10px",
                padding: "10px",
                textAlign: "center",
                cursor: "pointer",
                overflow: "hidden",
                height: "320px", // Increased height for full menu visibility
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onMouseEnter={() => setHoveredMovie(movie.id)}
              onMouseLeave={() => setHoveredMovie(null)}
            >
              <img
                src={movie.img}
                alt={movie.title}
                style={{ width: "100%", borderRadius: "10px", height: "70%" }}
              />
              <p style={{ margin: "10px 0", fontSize: "14px" }}>{movie.title}</p>

              {/* Sliding Menu inside each card */}
              <AnimatePresence>
                {hoveredMovie === movie.id && (
                  <motion.div
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "100%", opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "150px",
                      height: "100%",
                      background: "rgba(0,0,0,0.85)",
                      borderRadius: "10px 0 0 10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                      padding: "10px",
                    }}
                  >
                    <button style={menuButtonStyle}>▶ Play</button>
                    <button style={menuButtonStyle}>+ Watchlist</button>
                    <button style={menuButtonStyle}>⭐ Rate</button>
                    <button style={menuButtonStyle}>ℹ More Info</button>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>

      <div style={{ marginTop: "20px" }}>
        <button onClick={prevPage} disabled={page === 0} style={buttonStyle}>
          Previous
        </button>
        <span style={{ margin: "0 15px", fontSize: "18px", fontWeight: "bold" }}>
          Page {page + 1} of {Math.ceil(totalMovies / moviesPerPage)}
        </span>
        <button
          onClick={nextPage}
          disabled={startIdx + moviesPerPage >= totalMovies}
          style={buttonStyle}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const buttonStyle = {
  margin: "10px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  background: "#007bff",
  color: "white",
  cursor: "pointer",
  fontSize: "16px",
};

const menuButtonStyle = {
  background: "#444",
  border: "none",
  color: "white",
  padding: "8px 12px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "14px",
  width: "90%",
  textAlign: "center",
};

export default TryOut;


// import { motion } from "framer-motion";
// import { useState } from "react";

// const MovieCard = ({ movie }) => {
//   const [expanded, setExpanded] = useState(false);

//   return (
//     <motion.div
//       whileHover={{ scale: 1.1 }}
//       onClick={() => setExpanded(!expanded)}
//       style={{
//         width: "150px",
//         borderRadius: "10px",
//         padding: "10px",
//         background: expanded ? "#444" : "#222",
//         color: "white",
//         cursor: "pointer",
//         textAlign: "center",
//       }}
//     >
//       <img src={movie.img} alt={movie.title} style={{ width: "100px", borderRadius: "10px" }} />
//       <p>{movie.title}</p>
//       {expanded && (
//         <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
//           <button onClick={() => alert("Favorited!")}>❤️ Favorite</button>
//           <button onClick={() => alert("Deleted!")}>🗑️ Delete</button>
//         </motion.div>
//       )}
//     </motion.div>
//   );
// };

// const TryOut = () => {
//   const movies = [...Array(10)].map((_, i) => ({ id: i, title: `Movie ${i+1}`, img: "https://via.placeholder.com/150" }));

//   return (
//     <div style={{ display: "flex", gap: "10px", overflowX: "scroll" }}>
//       {movies.map((movie) => (
//         <MovieCard key={movie.id} movie={movie} />
//       ))}
//     </div>
//   );
// };

// export default TryOut;
