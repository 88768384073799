import React, { useEffect, useState } from 'react'
import { Button, Card, ListGroup, Badge, Table, Image, ButtonGroup, ToggleButton, Form, Modal, CardBody, ProgressBar, CardTitle } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import { getJobPositions, getJobPositionSkills, getJobPositionEvaluationQuestionGroups, getJobPositionRequirements, } from '../../store/actions/entityActions';
import { connect, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';


const UserSuccession = (props) => {
    const { auth, companyData, jobpositions, fromLookupParam, isSelfAnalysis, viewUserID } = props;
    // const location  = useLocation();
    // let fromLookup = location?.state?.fromLookup;
    // if ((fromLookup === null) || (fromLookup === undefined)) {
    //     fromLookup = fromLookupParam;
    // }
    console.log(jobpositions?.jobpositions);
    const jobPositionList = jobpositions?.jobpositions?.JobPositions;
    const { JobPositionRequirement, JobPositionSkill, JobPositionEvaluationQuestionGroup, JobPositionRequirementSuccession, JobPositionSkillSuccession, JobPositionEvaluationQuestionGroupSuccession } = jobpositions?.jobpositions;

    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);

    function displayMyTeamHeader() {
        var modalHeader = 'Succession Planning';
        if (isSelfAnalysis) {
            modalHeader = 'Competency & Gap Analysis'
        }

        return (
            <Table responsive="lg" >
                <thead>
                <tr>
                    <th style={{ width:'70%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> <h3 style={styles.SubHeader}>{modalHeader}</h3>
                    </th>
                    <th style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    </th>
                </tr>
                </thead>
                <tbody>
                </tbody>
            </Table>
        );
    }

    let currentRecIndex = 0;

    const addInitialSliderArray = () => {
        // let initsuccessorShowDetailsValue = '';
        if (JobPositionRequirementSuccession) {
            var uniqueSuccessors = JobPositionRequirementSuccession?.filter((v,i,a)=>a.findIndex(t=>(t.UserID === v.UserID))===i)
            if (uniqueSuccessors) {
                return uniqueSuccessors?.map((jpDetail) => ({
                    UserID:jpDetail.UserID, 
                    showDetails:false,
                }))
            }
        } 
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray();
        setSliderValue(initialScoreValues);
    }, [jobpositions])

    function getSuccessorDetailSwitchValue(successorIndex) {
        let bReturn = false;
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (parseInt(row.UserID) === parseInt(successorIndex))) {       
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
 
    function handleSuccessorSwitchDetailChange(successorIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        const previousShowValue = getSuccessorDetailSwitchValue(successorIndex);
        updatedSliderValues.map((row, index) => {
            if (row.UserID === successorIndex) {
                if (previousShowValue) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    function displaySuccessionRequirementFulfillDetails(successor, requirement) {
        const userJPRequirements = JobPositionRequirementSuccession?.filter((jobP) => 
            ((jobP.JobPositionRequirementTypeID === requirement.JobPositionRequirementTypeID) && (jobP.UserID === successor.UserID))) 
            // && (jobP.EvaluationRatingTypeID === successor.EvaluationRatingTypeID) && 
            // (jobP.EntityRowTypeID === successor.EntityRowTypeID) && (jobP.EntityID == successor.EntityID)))
        if ((userJPRequirements) && (userJPRequirements.length > 0)) {
            return (
                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined">thumb_up</span>
            );
        } else {
            return (
                <span style={styles.ActionIcon} class="material-symbols-outlined">thumb_down</span>
            );
        };
    }

    function displaySuccessionRequirementDetails(successor) {
        // const jpRequirements = JobPositionRequirement?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (JobPositionRequirement?.length === 0) ? <></> : 
            <>
            <Card style={{ width:'98%', backgroundColor:companyData?.PagesBGColor}}>
                <CardBody>
                    <Table class="table table-sm caption-top text-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                                <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Job Requirement</th>
                                    <th style={{textAlign:'right', width:'30%', backgroundColor:companyData?.PagesBGColor}}>
                                        {displaySubCompliantPercentage(successor, 1)}
                                    </th>
                                </tr>
                                </thead>
                    </Table>                            
                    <table class="table table-sm caption-top text-center">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextLeft}>Requirement</th>
                                <th style={styles.tableTextLeft}>Checked</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                        {JobPositionRequirement?.map((requirement, supIndex) => (
                            <tr> 
                                <td style={styles.tableText}>{supIndex + 1}</td> 
                                <td style={styles.tableTextLeft}>{requirement?.JobPositionRequirementTypeName}</td> 
                                <td style={styles.tableTextLeft}>{displaySuccessionRequirementFulfillDetails(successor, requirement)}</td> 
                            </tr> 
                        ))
                        }                        
                        </tbody>
                    </table>      
            </CardBody>                             
            </Card>            
            </>
        );
    }

    function displaySuccessionSkillFulfillDetails(successor, requirement) {
        const userJPSkills = JobPositionSkillSuccession?.filter((jobP) => 
            ((jobP.SkillID === requirement.SkillID) && (jobP.SkillLevelID === requirement.SkillLevelID) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
            
        if ((userJPSkills) && (userJPSkills.length > 0)) {
            return (
                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined">thumb_up</span>
            );
        } else {
            return (
                <span style={styles.ActionIcon} class="material-symbols-outlined">thumb_down</span>
            );
        };
    }

    function displaySuccessionSkillDetails(successor) {
        // const jpSkills = JobPositionSkill?.filter((jobP) => (jobP.JobPositionID === currentJP.JobPositionID));

        return (
            (JobPositionSkill?.length === 0) ? <></> : 
            <>
            <Card style={{ width:'98%', backgroundColor:companyData?.PagesBGColor}}>
                <CardBody>
                    <Table class="table table-sm caption-top text-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                        <thead>
                            <tr>
                                <th style={styles.tableTextLeft}>Job Competency & Skill</th>
                                <th style={{textAlign:'right', width:'30%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displaySubCompliantPercentage(successor, 2)}
                                </th>
                            </tr>
                        </thead>
                    </Table>                            
                    <table class="table table-sm caption-top text-center">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextLeft}>Skill</th>
                                <th style={styles.tableTextLeft}>Checked</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                        {JobPositionSkill.map((requirement, supIndex) => (
                            <tr> 
                                <td style={styles.tableText}>{supIndex + 1}</td> 
                                <td style={styles.tableTextLeft}>{requirement?.SkillName}</td> 
                                <td style={styles.tableTextLeft}>{displaySuccessionSkillFulfillDetails(successor, requirement)}</td> 
                            </tr> 
                        ))
                        }                        
                        </tbody>
                    </table>      
                </CardBody>                             
            </Card>            
            </>
        );
    }

    function displaySuccessionEQGFulfillDetails(successor, requirement) {
        const userJPEQGs = JobPositionEvaluationQuestionGroupSuccession?.filter((jobP) => 
            ((jobP.EvaluationQuestionGroupID === requirement.EvaluationQuestionGroupID) && (jobP.UserScore >= requirement.Score) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
            
        if ((userJPEQGs) && (userJPEQGs.length > 0)) {
            return (
                <span style={styles.ActionInsideIconGrey} class="material-symbols-outlined">thumb_up</span>
            );
        } else {
            return (
                <span style={styles.ActionIcon} class="material-symbols-outlined">thumb_down</span>
            );
        };
    }

    function displaySuccessionEQGDetails(successor) {
        return (
            (JobPositionEvaluationQuestionGroup?.length === 0) ? <></> : 
            <>
            <Card style={{ width:'98%', backgroundColor:companyData?.PagesBGColor}}>
                <CardBody>
                    <Table class="table table-sm caption-top text-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                        <thead>
                            <tr>
                                <th style={styles.tableTextLeft}>Job Performance</th>
                                <th style={{textAlign:'right', width:'30%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displaySubCompliantPercentage(successor, 3)}
                                </th>
                            </tr>
                        </thead>
                    </Table>                            
                    <table class="table table-sm caption-top text-center">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextLeft}>Performance</th>
                                <th style={styles.tableTextLeft}>Checked</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                        {JobPositionEvaluationQuestionGroup.map((requirement, supIndex) => (
                            <tr> 
                                <td style={styles.tableText}>{supIndex + 1}</td> 
                                <td style={styles.tableTextLeft}>{requirement?.EvaluationQuestionGroupName}</td> 
                                <td style={styles.tableTextLeft}>{displaySuccessionEQGFulfillDetails(successor, requirement)}</td> 
                            </tr> 
                        ))
                        }                        
                        </tbody>
                    </table>      
                </CardBody>                             
            </Card>            
            </>
        );
    }

    function displayCompliantPercentage(successor) {
        var iJPEQGCount = JobPositionEvaluationQuestionGroup?.length;
        var iUJPEQGCount = 0;

        JobPositionEvaluationQuestionGroup.map((requirement) => {
            const userJPEQGs = JobPositionEvaluationQuestionGroupSuccession?.filter((jobP) => 
                ((jobP.EvaluationQuestionGroupID === requirement.EvaluationQuestionGroupID) && (jobP.UserScore >= requirement.Score) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
                
            if ((userJPEQGs) && (userJPEQGs.length > 0)) {
                iUJPEQGCount++;
            };
        })

        var iJPSkillCount = JobPositionSkill?.length;
        var iUJSkillCount = 0;

        JobPositionSkill.map((requirement) => {
            const userJPSkills = JobPositionSkillSuccession?.filter((jobP) => 
                ((jobP.SkillID === requirement.SkillID) && (jobP.SkillLevelID === requirement.SkillLevelID) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
                
            if ((userJPSkills) && (userJPSkills.length > 0)) {
                iUJSkillCount++;
            };
        })
        
        var iJPRequirementCount = JobPositionRequirement?.length;
        var iUJRequirementCount = 0;

        JobPositionRequirement.map((requirement) => {
            const userJPRequirements = JobPositionRequirementSuccession?.filter((jobP) => 
            ((jobP.JobPositionRequirementTypeID === requirement.JobPositionRequirementTypeID) && (jobP.UserID === successor.UserID))) 

            if ((userJPRequirements) && (userJPRequirements.length > 0)) {
                iUJRequirementCount++;
            }; 
        })
        
        var iCompliantPercentage = 0;
        var sProgressBarVariant = 'danger';       

        iCompliantPercentage =  Math.ceil((((iUJPEQGCount + iUJRequirementCount + iUJSkillCount) * 100) / (iJPEQGCount + iJPRequirementCount + iJPSkillCount)));

        if ((iCompliantPercentage >= 75) && (iCompliantPercentage <= 100)) {
            sProgressBarVariant = 'success';       
        } else if ((iCompliantPercentage >= 50) && (iCompliantPercentage < 75)) {
            sProgressBarVariant = 'warning';       
        }

        return (
            <ProgressBar animated variant={sProgressBarVariant} now={iCompliantPercentage} label={`${iCompliantPercentage}%`} style={styles.progressBarInnerText} />
        );
    }

    function displaySubCompliantPercentage(successor, subType) {
        if (subType == 1) {
            var iJPRequirementCount = JobPositionRequirement?.length;
            var iUJRequirementCount = 0;
    
            JobPositionRequirement.map((requirement) => {
                const userJPRequirements = JobPositionRequirementSuccession?.filter((jobP) => 
                ((jobP.JobPositionRequirementTypeID === requirement.JobPositionRequirementTypeID) && (jobP.UserID === successor.UserID))) 
    
                if ((userJPRequirements) && (userJPRequirements.length > 0)) {
                    iUJRequirementCount++;
                }; 
            })
            
            var iCompliantPercentage = 0;
            var sProgressBarVariant = 'danger';       
    
            iCompliantPercentage =  Math.ceil(((iUJRequirementCount * 100) / iJPRequirementCount));
    
            if ((iCompliantPercentage >= 75) && (iCompliantPercentage <= 100)) {
                sProgressBarVariant = 'success';       
            } else if ((iCompliantPercentage >= 50) && (iCompliantPercentage < 75)) {
                sProgressBarVariant = 'warning';       
            }
    
            return (
                <ProgressBar animated variant={sProgressBarVariant} now={iCompliantPercentage} label={`${iCompliantPercentage}%`} style={styles.progressBarInnerText} />
            );    
        } else if (subType == 2) {
            var iJPSkillCount = JobPositionSkill?.length;
            var iUJSkillCount = 0;
    
            JobPositionSkill.map((requirement) => {
                const userJPSkills = JobPositionSkillSuccession?.filter((jobP) => 
                    ((jobP.SkillID === requirement.SkillID) && (jobP.SkillLevelID === requirement.SkillLevelID) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
                    
                if ((userJPSkills) && (userJPSkills.length > 0)) {
                    iUJSkillCount++;
                };
            })
                            
            var iCompliantPercentage = 0;
            var sProgressBarVariant = 'danger';       
    
            iCompliantPercentage =  Math.ceil(((iUJSkillCount * 100) / iJPSkillCount));
    
            if ((iCompliantPercentage >= 75) && (iCompliantPercentage <= 100)) {
                sProgressBarVariant = 'success';       
            } else if ((iCompliantPercentage >= 50) && (iCompliantPercentage < 75)) {
                sProgressBarVariant = 'warning';       
            }
    
            return (
                <ProgressBar animated variant={sProgressBarVariant} now={iCompliantPercentage} label={`${iCompliantPercentage}%`} style={styles.progressBarInnerText} />
            );    
        } if (subType == 3) {
            var iJPEQGCount = JobPositionEvaluationQuestionGroup?.length;
            var iUJPEQGCount = 0;
    
            JobPositionEvaluationQuestionGroup.map((requirement) => {
                const userJPEQGs = JobPositionEvaluationQuestionGroupSuccession?.filter((jobP) => 
                    ((jobP.EvaluationQuestionGroupID === requirement.EvaluationQuestionGroupID) && (jobP.UserScore >= requirement.Score) && (parseInt(jobP.UserID) === parseInt(successor.UserID)))) 
                    
                if ((userJPEQGs) && (userJPEQGs.length > 0)) {
                    iUJPEQGCount++;
                };
            })
                    
            var iCompliantPercentage = 0;
            var sProgressBarVariant = 'danger';       
    
            iCompliantPercentage =  Math.ceil(((iUJPEQGCount * 100) / iJPEQGCount));
    
            if ((iCompliantPercentage >= 75) && (iCompliantPercentage <= 100)) {
                sProgressBarVariant = 'success';       
            } else if ((iCompliantPercentage >= 50) && (iCompliantPercentage < 75)) {
                sProgressBarVariant = 'warning';       
            }
    
            return (
                <ProgressBar animated variant={sProgressBarVariant} now={iCompliantPercentage} label={`${iCompliantPercentage}%`} style={styles.progressBarInnerText} />
            );    
        } else {
            return (<></>);
        }
    }

    function displaySuccession(currentJP) {
        if (JobPositionRequirementSuccession) {
            var uniqueSuccessorsRD = JobPositionRequirementSuccession?.filter((v,i,a)=>a.findIndex(t=>(t.UserID === v.UserID))===i);
            var uniqueSuccessors = null;
            if (isSelfAnalysis) {
                uniqueSuccessors = uniqueSuccessorsRD.filter(user => user.UserID === viewUserID);
            } else {
                uniqueSuccessors = uniqueSuccessorsRD.filter(user => user.UserID !== viewUserID);
            }
            var jpCompliantPercentage = 66;
            if (uniqueSuccessors.length !== 0) {
                return (
                    <table class="table table-sm caption-top text-center">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextLeft}>Name</th>
                                <th style={styles.tableText}>ID</th>
                                <th style={styles.tableText}>Overall Compliant</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                        {uniqueSuccessors.map((successor, supIndex) => (
                            <>
                            <tr> 
                                <td style={styles.tableText}>{supIndex + 1}</td> 
                                <td style={styles.tableTextLeft}>
                                    {successor?.UserLastName},&nbsp;&nbsp;{successor?.UserFirstName}
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" 
                                            checked={getSuccessorDetailSwitchValue(successor?.UserID)} id={`switch${'succdetail' + successor?.UserID}`} 
                                            style={(getSuccessorDetailSwitchValue(successor?.UserID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                            onChange={(e) => {handleSuccessorSwitchDetailChange(successor?.UserID)}}>
                                        </input>
                                        <label class="form-check-label" for={`switch${'succdetail' + successor?.UserID}`}>Details</label>
                                    </div>                    
                                </td> 
                                <td style={styles.tableText}>{successor?.VendorUserID}</td> 
                                <td style={{backgroundColor:companyData?.PagesBGColor}}> 
                                    {displayCompliantPercentage(successor)}
                                </td>
                            </tr> 
                            {(getSuccessorDetailSwitchValue(successor.UserID)) ? 
                            <tr style={{alignItems:'center'}}>
                                <td colSpan="4" style={{alignItems:'center', backgroundColor:companyData?.PagesBGColor}}>
                                    {displaySuccessionRequirementDetails(successor)}
                                    {displaySuccessionSkillDetails(successor)}
                                    {displaySuccessionEQGDetails(successor)}
                                </td>
                            </tr>
                            :
                            <></>
                            }
                            </>
                        ))
                        }                        
                        </tbody>
                    </table>                                       
                );
            } else {
                return (<></>);
            }
        } 
    }

   
    return ( 
        <>
            {(jobPositionList) ? 
                (

                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            {displayMyTeamHeader()}
                        </Card.Header>
                        {(loading) ? (            
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                    <Spinner/>
                                </div>
                            </Card.Body>
                        ) 
                        : 
                        (                                          
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    {(jobPositionList?.length === 0) ? (
                                        <div style={styles.tableText}>
                                            No Successors.
                                        </div>
                                    ) : (
                                        <div>
                                            <Table responsive="sm" striped bordered hover variant="light">
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    {displaySuccession(jobPositionList[0])}
                                                </tbody>
                                            </Table>        
                                        </div>
                                    )}
                                </Card.Body>
                        )}

                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                        </Card.Footer>
                    </Card>
                )
                :
                (<></>)}
                </>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth.user,
        companyData: state?.auth?.companyData,
        jobpositions: state.entity.jobpositions,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJobPositions: (jpParams) => dispatch(getJobPositions(jpParams)),
        getJobPositionRequirements: (oParams) => dispatch(getJobPositionRequirements(oParams)),
        getJobPositionSkills: (oParams) => dispatch(getJobPositionSkills(oParams)),
        getJobPositionEvaluationQuestionGroups: (oParams) => dispatch(getJobPositionEvaluationQuestionGroups(oParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(UserSuccession)
