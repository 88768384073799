import * as actionTypes from "./actionTypes"; 
import RetentasAPI from "../../api/RetentasAPI";
import RetentasMLAPI from "../../api/RetentasMLAPI";
import { MenuType } from "../../config/Constant";

export const getAuditTrail = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETAUDITTRAIL, oParams)
            // console.log(resp.data);
            const audittrail = resp.data;
            dispatch( {type: actionTypes.GETAUDITTRAIL_SUCCESS, payload: {audittrail} } );
        } catch (error) {
            console.log('Error in GetAuditTrail', error);
            console.log(error);
            dispatch( {type: actionTypes.GETAUDITTRAIL_ERROR, payload: error } );
        }        
    }
}

export const getUserEvaluationSessions = (id) => {
    return async (dispatch) => {

        try {
            // console.log(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONSESSIONS + "?id=" + id);
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONSESSIONS + "?id=" + id)
            // console.log(resp.data);
            const userevaluationsessions = resp.data;
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSION_SUCCESS, payload: {userevaluationsessions} } );
        } catch (error) {
            console.log('Error in getUserEvaluationSession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSION_ERROR, payload: error } );
        }        
    }
}

export const getUserEvaluationSessionResponses = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATIONSESSIONRESPONSES + "?id=" + id)                                                    
            // console.log(resp.data);
            const userevaluationsessionresponses = resp.data;
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_SUCCESS, payload: {userevaluationsessionresponses} } );
        } catch (error) {
            console.log('Error in getUserEvaluationSession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSEREVALUATIONSESSIONRESPONSES_ERROR, payload: error } );
        }        
    }
}

export const getUserEvaluation = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSEREVALUATION + "?id=" + id)
            // console.log(resp.data);
            const userevaluation = resp.data;
            dispatch( {type: actionTypes.GETUSEREVALUATION_SUCCESS, payload: {userevaluation} } );
        } catch (error) {
            console.log('Error in getUserEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSEREVALUATION_ERROR, payload: error } );
        }        
    }
}

export const getEvaluation = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETEVALUATION + "?id=" + id)
            // console.log(resp.data);
            const evaluation = resp.data;
            dispatch( {type: actionTypes.GETEVALUATION_SUCCESS, payload: {evaluation} } );
        } catch (error) {
            console.log('Error in getEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const getEvaluationQuestions = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETEVALUATIONQUESTIONS + "?id=" + id)
            // console.log(resp.data);
            const evaluationquestions = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONQUESTIONS_SUCCESS, payload: {evaluationquestions} } );
        } catch (error) {
            console.log('Error in getEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONQUESTIONS_ERROR, payload: error } );
        }        
    }
}

export const getEvaluationRatings = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETEVALUATIONRATINGS + "?id=" + id)
            // console.log(resp.data);
            const evaluationratings = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONRATINGS_SUCCESS, payload: {evaluationratings} } );
        } catch (error) {
            console.log('Error in getEvaluationRatings', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONRATINGS_ERROR, payload: error } );
        }        
    }
}

export const getKnowledgeSessionEvaluation = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETKNOWLEDGESESSIONEVALUATION, oParams);
            // console.log(resp.data);
            const knowledgesessionevaluation = resp.data;
            dispatch( {type: actionTypes.GETKNOWLEDGESESSIONEVALUATION_SUCCESS, payload: {knowledgesessionevaluation} } );
        } catch (error) {
            console.log('Error in getKnowledgeSessionEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.GETKNOWLEDGESESSIONEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const updateEvaluationResponses = (evaluationResponses) => {
    return async (dispatch) => {

        try {
            //console.log(evaluationResponses);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSEREVALUATIONSESSIONRESPONSES, evaluationResponses)
            //console.log(resp.data);
            const evaluationratings = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATIONRESPONSES_SUCCESS, payload: {evaluationratings} } );
        } catch (error) {
            console.log('Error in updateEvaluationResponses', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATIONRESPONSES_ERROR, payload: error } );
        }        
    }
}

export const updateKnowledgeSessionEvaluationResponses = (evaluationResponses) => {
    return async (dispatch) => {

        try {
            //console.log(evaluationResponses);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES, evaluationResponses)
            //console.log(resp.data);
            const knowledgeevaluationratings = resp.data;
            dispatch( {type: actionTypes.UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_SUCCESS, payload: {knowledgeevaluationratings} } );
        } catch (error) {
            console.log('Error in updateKnowledgeSessionEvaluationResponses', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_ERROR, payload: error } );
        }        
    }
}

export const updateUserEvaluationSession = (evaluationSession) => {
    return async (dispatch) => {

        try {
            // console.log(evaluationSession);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSEREVALUATIONSESSION, evaluationSession)
            // console.log(resp.data);
            const evaluationsession = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATIONSESSION_SUCCESS, payload: {evaluationsession} } );
        } catch (error) {
            console.log('Error in updateEvaluationSession', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATIONSESSION_ERROR, payload: error } );
        }        
    }
}

export const getRetentionPrediction = (retentionSessions) => {
    return async (dispatch) => {

        try {
            // console.log(retentionSessions);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETRETENTIONPREDICTION, retentionSessions)
            // console.log(resp.data);
            const retentionSessionResults = resp.data;
            dispatch( {type: actionTypes.GETRETENTIONPREDICTION_SUCCESS, payload: {retentionSessionResults} } );
        } catch (error) {
            console.log('Error in getRetentionPrediction', error);
            console.log(error);
            dispatch( {type: actionTypes.GETRETENTIONPREDICTION_ERROR, payload: error } );
        }        
    }
}

export const updateUserSuggestion = (suggestions) => {
    return async (dispatch) => {

        try {
            // console.log(suggestions);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSERSUGGESTION, suggestions)
            // console.log(resp.data);
            const evaluationsession = resp.data;
            dispatch( {type: actionTypes.UPDATEUSERSUGGESTION_SUCCESS, payload: {} } );
        } catch (error) {
            console.log('Error in updateUserSuggestion', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEUSERSUGGESTION_ERROR, payload: error } );
        }        
    }
}

export const getEvaluations = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETEVALUATIONS, evalParams)
            // console.log(resp.data);
            const evaluations = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONS_SUCCESS, payload: {evaluations} } );
        } catch (error) {
            console.log('Error in getEvaluations', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONS_ERROR, payload: error } );
        }        
    }
}

export const getPGEvaluations = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGEVALUATIONS, evalParams)
            // console.log(resp.data);
            const evaluations = resp.data;
            dispatch( {type: actionTypes.GETEVALUATIONS_SUCCESS, payload: {evaluations} } );
        } catch (error) {
            console.log('Error in getEvaluations', error);
            console.log(error);
            dispatch( {type: actionTypes.GETEVALUATIONS_ERROR, payload: error } );
        }        
    }
}

export const insertEvaluation = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTEVALUATION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTEVALUATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const deleteEvaluation = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEEVALUATION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEEVALUATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const insertEvaluationQuestion = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTEVALUATIONQUESTION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTEVALUATIONQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTEVALUATIONQUESTION_ERROR, payload: error } );
        }        
    }
}

export const updateEvaluationQuestion = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEEVALUATIONQUESTION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATIONQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATIONQUESTION_ERROR, payload: error } );
        }        
    }
}

export const updateEvaluation = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEEVALUATION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEEVALUATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEEVALUATION_ERROR, payload: error } );
        }        
    }
}

export const deleteEvaluationQuestion = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEEVALUATIONQUESTION, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEEVALUATIONQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEEVALUATIONQUESTION_ERROR, payload: error } );
        }        
    }
}

export const insertEvaluationRating = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTEVALUATIONRATING, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTEVALUATIONRATING_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTEVALUATIONRATING_ERROR, payload: error } );
        }        
    }
}

export const deleteEvaluationRating = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEEVALUATIONRATING, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEEVALUATIONRATING_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteEvaluationQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEEVALUATIONRATING_ERROR, payload: error } );
        }        
    }
}

export const getAssignments = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGASSIGNMENTS, evalParams)
            // console.log(resp.data);
            const assignments = resp.data;
            dispatch( {type: actionTypes.GETASSIGNMENTS_SUCCESS, payload: {assignments} } );
        } catch (error) {
            console.log('Error in getEvaluations', error);
            console.log(error);
            dispatch( {type: actionTypes.GETASSIGNMENTS_ERROR, payload: error } );
        }        
    }
}

export const getGoals = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETGOALS, evalParams)
            // console.log(resp.data);
            const goals = resp.data;
            dispatch( {type: actionTypes.GETGOALS_SUCCESS, payload: {goals} } );
        } catch (error) {
            console.log('Error in getGoals', error);
            console.log(error);
            dispatch( {type: actionTypes.GETGOALS_ERROR, payload: error } );
        }        
    }
}

export const getPGGoals = (evalParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGGOALS, evalParams)
            // console.log(resp.data);
            const goals = resp.data;
            dispatch( {type: actionTypes.GETGOALS_SUCCESS, payload: {goals} } );
        } catch (error) {
            console.log('Error in getGoals', error);
            console.log(error);
            dispatch( {type: actionTypes.GETGOALS_ERROR, payload: error } );
        }        
    }
}

export const getCompetencies = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETCOMPETENCIES, oParams)
            // console.log(resp.data);
            const Skills = resp.data;
            dispatch( {type: actionTypes.GETCOMPETENCIES_SUCCESS, payload: {Skills} } );
        } catch (error) {
            console.log('Error in getCompetencies', error);
            console.log(error);
            dispatch( {type: actionTypes.GETCOMPETENCIES_ERROR, payload: error } );
        }        
    }
}

export const getSkills = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETSKILLS, oParams)
            // console.log(resp.data);
            const skills = resp.data;
            dispatch( {type: actionTypes.GETSKILLS_SUCCESS, payload: {skills} } );
        } catch (error) {
            console.log('Error in getskills', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSKILLS_ERROR, payload: error } );
        }        
    }
}

export const insertSkill = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTSKILL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTSKILL_ERROR, payload: error } );
        }        
    }
}

export const updateSkill = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATESKILL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATESKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATESKILL_ERROR, payload: error } );
        }        
    }
}

export const deleteSkill = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETESKILL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETESKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETESKILL_ERROR, payload: error } );
        }        
    }
}

export const getUserAttributesLookups = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSERATTRIBUTELOOKUPS + "?id=" + id)
            // console.log(resp.data);
            const userattributeslookups = resp.data;
            dispatch( {type: actionTypes.GETUSERATTRIBUTELOOKUPS_SUCCESS, payload: {userattributeslookups} } );
        } catch (error) {
            console.log('Error in getUserAttributesLookups', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERATTRIBUTELOOKUPS_ERROR, payload: error } );
        }        
    }
}

export const getUsersByPolicy = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETUSERSBYPOLICY + "?id=" + id)
            // console.log(resp.data);
            const userlookup = resp.data;
            dispatch( {type: actionTypes.GETUSERSBYPOLICY_SUCCESS, payload: {userlookup} } );
        } catch (error) {
            console.log('Error in getUsersByPolicy', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERSBYPOLICY_ERROR, payload: error } );
        }        
    }
}

export const insertUserGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERGROUP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserGroup', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERGROUP_ERROR, payload: error } );
        }        
    }
}

export const updateUserGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSERGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEUSERGROUP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateUserGroup', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEUSERGROUP_ERROR, payload: error } );
        }        
    }
}

export const deleteUserGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERGROUP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserGroup', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERGROUP_ERROR, payload: error } );
        }        
    }
}

export const insertUserLicense = (licParams) => {
    return async (dispatch) => {

        try {
            // console.log(licParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERLICENSE, licParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERLICENSE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserLicense', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERLICENSE_ERROR, payload: error } );
        }        
    }
}

export const deleteUserLicense = (licParams) => {
    return async (dispatch) => {

        try {
            // console.log(licParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERLICENSE, licParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERLICENSE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserLicense', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERLICENSE_ERROR, payload: error } );
        }        
    }
}

export const insertUserCredential = (credParams) => {
    return async (dispatch) => {

        try {
            // console.log(credParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERCREDENTIAL, credParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERCREDENTIAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserCredential', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const deleteUserCredential = (credParams) => {
    return async (dispatch) => {

        try {
            // console.log(credParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERCREDENTIAL, credParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERCREDENTIAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserCredential', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const insertUserGoal = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERGOAL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERGOAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserGoal', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERGOAL_ERROR, payload: error } );
        }        
    }
}

export const deleteUserGoal = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERGOAL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERGOAL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserGoal', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERCREDENTIAL_ERROR, payload: error } );
        }        
    }
}

export const insertUserSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const updateUserSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSERSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEUSERSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const deleteUserSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERSKILL_ERROR, payload: error } );
        }        
    }
}

export const insertUserJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERJOBPOSITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserJPR', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteUserJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERJOBPOSITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserJPR', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const insertUserFutureJobPosition = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSERFUTUREJOBPOSITION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSERFUTUREJOBPOSITION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserFuture JP', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSERFUTUREJOBPOSITION_ERROR, payload: error } );
        }        
    }
}

export const deleteUserFutureJobPosition = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEUSERFUTUREJOBPOSITION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEUSERFUTUREJOBPOSITION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUser Future JP', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEUSERFUTUREJOBPOSITION_ERROR, payload: error } );
        }        
    }
}

export const getJobPositions = (jpParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONS, jpParams)
            // console.log(resp.data);
            const jobpositions = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONS_SUCCESS, payload: {jobpositions} } );
        } catch (error) {
            console.log('Error in getjobpositions', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONS_ERROR, payload: error } );
        }        
    }
}

export const insertJobPositionSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTJOBPOSITIONSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTJOBPOSITIONSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTJOBPOSITIONSKILL_ERROR, payload: error } );
        }        
    }
}

export const deleteJobPositionSkill = (skillParams) => {
    return async (dispatch) => {

        try {
            // console.log(skillParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEJOBPSOITIONSKILL, skillParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEJOBPOSITIONSKILL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEJOBPOSITIONSKILL_ERROR, payload: error } );
        }        
    }
}

export const insertJobPositionEvaluationQuestionGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTJOBPOSITIONEVALUATIONQUESTIONGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTJOBPOSITIONEQG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTJOBPOSITIONEQG_ERROR, payload: error } );
        }        
    }
}

export const deleteJobPositionEvaluationQuestionGroup = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEJOBPSOITIONEVALUATIONQUESTIONGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEJOBPOSITIONEQG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEJOBPOSITIONEQG_ERROR, payload: error } );
        }        
    }
}

export const insertJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTJOBPOSITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteJobPositionRequirement = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEJOBPSOITIONREQUIREMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEJOBPOSITIONREQUIREMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteUserSkill', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEJOBPOSITIONREQUIREMENT_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionRequirements = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONREQUIREMENTS, oParams)
            // console.log(resp.data);
            const JobPositionRequirement = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONREQUIREMENTS_SUCCESS, payload: {JobPositionRequirement} } );
        } catch (error) {
            console.log('Error in getJobPositionRequirements', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONREQUIREMENTS_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionSkills = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONSKILLS, oParams)
            // console.log(resp.data);
            const JobPositionSkill = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONSKILLS_SUCCESS, payload: {JobPositionSkill} } );
        } catch (error) {
            console.log('Error in getJobPositionSkills', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONSKILLS_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionEvaluationQuestionGroups = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONEVALUATIONQUESTIONGROUP, oParams)
            // console.log(resp.data);
            const JobPositionEvaluationQuestionGroup = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_SUCCESS, payload: {JobPositionEvaluationQuestionGroup} } );
        } catch (error) {
            console.log('Error in getJobPositionEvaluationQuestionGroups', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_ERROR, payload: error } );
        }        
    }
}

export const assignLookupSelection = (id, value) => {
    return async (dispatch) => {

        try {
            // console.log(id);
            if (id === MenuType.Reminder) {
                const Reminder = value;
                dispatch( {type: actionTypes.UPDATEREMINDERLOOKUPSELECTION_SUCCESS, payload: {Reminder} } );    
            }
            else if (id === MenuType.JobPositions) {
                const JobPosition = value;
                dispatch( {type: actionTypes.UPDATEJPLOOKUPSELECTION_SUCCESS, payload: {JobPosition} } );    
            } else if (id === MenuType.Goals) {
                const Goal = value;
                dispatch( {type: actionTypes.UPDATEGOALLOOKUPSELECTION_SUCCESS, payload: {Goal} } );    
            } else if (id === MenuType.Competencies) {
                const Skill = value;
                dispatch( {type: actionTypes.UPDATESKILLLOOKUPSELECTION_SUCCESS, payload: {Skill} } );    
            } else if (id === MenuType.MyTeam) {
                const User = value;
                dispatch( {type: actionTypes.UPDATEUSERLOOKUPSELECTION_SUCCESS, payload: {User} } );    
            } else if (id === MenuType.Pillars) {
                const Pillar = value;
                dispatch( {type: actionTypes.UPDATEPILLARLOOKUPSELECTION_SUCCESS, payload: {Pillar} } );    
            } else if (id === MenuType.Knowledge) {
                const Knowledge = value;
                dispatch( {type: actionTypes.UPDATEKNOWLEDGELOOKUPSELECTION_SUCCESS, payload: {Knowledge} } );    
            } else if (id === MenuType.Content) {
                const Content = value;
                dispatch( {type: actionTypes.UPDATECONTENTLOOKUPSELECTION_SUCCESS, payload: {Content} } );    
            } else if (id === MenuType.Assessment) {
                const Assessment = value;
                dispatch( {type: actionTypes.UPDATEASSESSMENTLOOKUPSELECTION_SUCCESS, payload: {Assessment} } );    
            } else if (id === MenuType.NotificationMessage) {
                const NotificationMessage = value;
                dispatch( {type: actionTypes.UPDATENOTIFICATIONMESSAGELOOKUPSELECTION_SUCCESS, payload: {NotificationMessage} } );    
            }  else if (id === MenuType.Facility) {
                const FacilityRoom = value;
                dispatch( {type: actionTypes.UPDATEFACILITYROOMLOOKUPSELECTION_SUCCESS, payload: {FacilityRoom} } );    
            }  else if (id === MenuType.Certification) {
                const Certification = value;
                dispatch( {type: actionTypes.UPDATECERTIFICATIONLOOKUPSELECTION_SUCCESS, payload: {Certification} } );    
            } 
        } catch (error) {
            console.log('Error in assignLookupSelection', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEJPLOOKUPSELECTION_ERROR, payload: error } );
        }        
    }
}

export const getJobPositionSuccession = (id) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.get(process.env.REACT_APP_RETENTASAPI_GETJOBPOSITIONSUCCESSION + "?id=" + id)
            const jobpositions = resp.data;
            dispatch( {type: actionTypes.GETJOBPOSITIONS_SUCCESS, payload: {jobpositions} } );
        } catch (error) {
            console.log('Error in getjobpositionsuccession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETJOBPOSITIONS_ERROR, payload: error } );
        }        
    }
}

export const insertAssignment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTASSIGNMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTASSIGNMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertAssignment', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTASSIGNMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteAssignment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEASSIGNMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEASSIGNMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteAssignment', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEASSIGNMENT_ERROR, payload: error } );
        }        
    }
}

export const updateAssignment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEASSIGNMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEASSIGNMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateAssignment', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEASSIGNMENT_ERROR, payload: error } );
        }        
    }
}

export const getSkillChecklist = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETUSERSKILLCHECKLIST, oParams)
            // console.log(resp.data);
            const skillChecklist = resp.data;
            dispatch( {type: actionTypes.GETSKILLCHECKLIST_SUCCESS, payload: {skillChecklist} } );
        } catch (error) {
            console.log('Error in getSkillChecklist', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSKILLCHECKLIST_ERROR, payload: error } );
        }        
    }
}

export const getSkillChecklistGroupBy = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETUSERSKILLCHECKLISTGROUPBY, oParams)
            const skillChecklistGroupBy = resp.data;
            dispatch( {type: actionTypes.GETSKILLCHECKLISTGROUPBY_SUCCESS, payload: {skillChecklistGroupBy} } );
        } catch (error) {
            console.log('Error in getSkillChecklistGroupBy', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSKILLCHECKLISTGROUPBY_ERROR, payload: error } );
        }        
    }
}

export const getNotificationMessages = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGNOTIFICATIONMESSAGES, evalParams)
            // console.log(resp.data);
            const notificationmessages = resp.data;
            dispatch( {type: actionTypes.GETNOTIFICATIONMESSAGES_SUCCESS, payload: {notificationmessages} } );
        } catch (error) {
            console.log('Error in notificationmessages', error);
            console.log(error);
            dispatch( {type: actionTypes.GETNOTIFICATIONMESSAGES_ERROR, payload: error } );
        }        
    }
}

export const insertNotificationMessage = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTNOTIFICATIONMESSAGE, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTNOTIFICATIONMESSAGE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertNotificationMessage', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTNOTIFICATIONMESSAGE_ERROR, payload: error } );
        }        
    }
}

export const deleteNotificationMessage = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETENOTIFICATIONMESSAGE, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETENOTIFICATIONMESSAGE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteNotificationMessage', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETENOTIFICATIONMESSAGE_ERROR, payload: error } );
        }        
    }
}

export const updateNotificationMessage = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATENOTIFICATIONMESSAGE, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATENOTIFICATIONMESSAGE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateEvaluation', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATENOTIFICATIONMESSAGE_ERROR, payload: error } );
        }        
    }
}

export const getProcessSteps = (evalParams) => {
    return async (dispatch) => {

        try {
            console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGPROCESSSTEPS, evalParams)
            const processsteps = resp.data;
            dispatch( {type: actionTypes.GETPROCESSSTEPS_SUCCESS, payload: {processsteps} } );
        } catch (error) {
            console.log('Error in processsteps', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPROCESSSTEPS_ERROR, payload: error } );
        }        
    }
}

export const insertProcessStep = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTPROCESSSTEP, evalParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTPROCESSSTEP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertProcessStep', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTPROCESSSTEP_ERROR, payload: error } );
        }        
    }
}

export const deleteProcessStep = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEPROCESSSTEP, evalParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEPROCESSSTEP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteProcessStep', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEPROCESSSTEP_ERROR, payload: error } );
        }        
    }
}

export const updateProcessStep = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEPROCESSSTEP, evalParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEPROCESSSTEP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateProcessStep', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEPROCESSSTEP_ERROR, payload: error } );
        }        
    }
}

export const getAttachments = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETATTACHMENTS, oParams)
            const attachments = resp.data;
            dispatch( {type: actionTypes.GETATTACHMENTS_SUCCESS, payload: {attachments} } );
        } catch (error) {
            console.log('Error in get attachments', error);
            console.log(error);
            dispatch( {type: actionTypes.GETATTACHMENTS_ERROR, payload: error } );
        }        
    }
}

export const insertAttachmentForm = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTATTACHMENTFORM, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTATTACHMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertAttachment', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTATTACHMENT_ERROR, payload: error } );
        }        
    }
}

export const deleteAttachment = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEATTACHMENT, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEATTACHMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteAttachment', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEATTACHMENT_ERROR, payload: error } );
        }        
    }
}

export async function getAttachmentStream(oParams) {
    try {
        const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETATTACHMENTSTREAM, oParams, {responseType:'blob'});

        console.log("Full Response Object:", resp);
        console.log("Response Headers:", [...Object.entries(resp.headers)]);
        // Manually create a Blob
        const blob = new Blob([resp.data], { type: resp.headers["content-type"] });
        console.log("Blob Object:", blob);        

        const sURL = URL.createObjectURL(blob);
        console.log("Generated Blob URL:", sURL);

        return sURL;
    } catch (error) {
        console.log('Error in get attachments', error);
        console.log(error);
        return null;
    }        
}

export const getReminders = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGREMINDERS, evalParams)
            // console.log(resp.data);
            const reminders = resp.data;
            dispatch( {type: actionTypes.GETREMINDERS_SUCCESS, payload: {reminders} } );
        } catch (error) {
            console.log('Error in GetReminder', error);
            console.log(error);
            dispatch( {type: actionTypes.GETREMINDERS_ERROR, payload: error } );
        }        
    }
}

export const insertReminder = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTREMINDER, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTREMINDER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertReminder', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTREMINDER_ERROR, payload: error } );
        }        
    }
}

export const deleteReminder = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEREMINDER, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEREMINDER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteReminder', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEREMINDER_ERROR, payload: error } );
        }        
    }
}

export const updateReminder = (evalParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEREMINDER, evalParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEREMINDER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateReminder', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEREMINDER_ERROR, payload: error } );
        }        
    }
}

export const insertReminderEmail = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTREMINDEREMAIL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTREMINDEREMAIL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertReminderEmail', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTREMINDEREMAIL_ERROR, payload: error } );
        }        
    }
}

export const updateReminderEmail = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEREMINDEREMAIL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEREMINDEREMAIL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateReminderEmail', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEREMINDEREMAIL_ERROR, payload: error } );
        }        
    }
}

export const deleteReminderEmail = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEREMINDEREMAIL, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEREMINDEREMAIL_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteReminderEmail', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEREMINDEREMAIL_ERROR, payload: error } );
        }        
    }
}

export const getPolicies = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGPOLICIES, oParams)
            // console.log(resp.data);
            const policies = resp.data;
            dispatch( {type: actionTypes.GETPOLICIES_SUCCESS, payload: {policies} } );
        } catch (error) {
            console.log('Error in GetPolicies', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPOLICIES_ERROR, payload: error } );
        }        
    }
}

export const insertPolicy = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTPOLICY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTPOLICY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertPolicy', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTPOLICY_ERROR, payload: error } );
        }        
    }
}

export const deletePolicy = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEPOLICY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEPOLICY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deletePolicy', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEPOLICY_ERROR, payload: error } );
        }        
    }
}

export const updatePolicy = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEPOLICY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEPOLICY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updatePolicy', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEPOLICY_ERROR, payload: error } );
        }        
    }
}

export const insertGroupPolicy = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTGROUPPOLICY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTGROUPPOLICY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertGroupPolicy', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTGROUPPOLICY_ERROR, payload: error } );
        }        
    }
}

export const deleteGroupPolicy = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEGROUPPOLICY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEGROUPPOLICY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteReminderEmail', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEGROUPPOLICY_ERROR, payload: error } );
        }        
    }
}

export const getGroups = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGGROUPS, oParams)
            // console.log(resp.data);
            const groups = resp.data;
            dispatch( {type: actionTypes.GETGROUPS_SUCCESS, payload: {groups} } );
        } catch (error) {
            console.log('Error in GetPolicies', error);
            console.log(error);
            dispatch( {type: actionTypes.GETGROUPS_ERROR, payload: error } );
        }        
    }
}

export const insertGroup = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTGROUP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertGroup', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTGROUP_ERROR, payload: error } );
        }        
    }
}

export const deleteGroup = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEGROUP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteGroup', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEGROUP_ERROR, payload: error } );
        }        
    }
}

export const updateGroup = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEGROUP, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEGROUP_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateGroup', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEGROUP_ERROR, payload: error } );
        }        
    }
}

export const getMenus = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGMENUS, oParams)
            // console.log(resp.data);
            const menus = resp.data;
            dispatch( {type: actionTypes.GETMENUS_SUCCESS, payload: {menus} } );
        } catch (error) {
            console.log('Error in GetMenus', error);
            console.log(error);
            dispatch( {type: actionTypes.GETMENUS_ERROR, payload: error } );
        }        
    }
}

export const insertMenu = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTMENU, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTMENU_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertMenu', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTMENU_ERROR, payload: error } );
        }        
    }
}

export const deleteMenu = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEMENU, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEMENU_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteMenu', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEMENU_ERROR, payload: error } );
        }        
    }
}

export const updateMenu = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEMENU, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEMENU_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateMenu', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEMENU_ERROR, payload: error } );
        }        
    }
}

export const getKnowledges = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGKNOWLEDGES, oParams)
            const knowledges = resp.data;
            dispatch( {type: actionTypes.GETPGKNOWLEDGES_SUCCESS, payload: {knowledges} } );
        } catch (error) {
            console.log('Error in getKnowledges', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGKNOWLEDGES_ERROR, payload: error } );
        }        
    }
}

export const getKnowledgesLookup = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGKNOWLEDGESLOOKUP, oParams)
            const knowledgesLookup = resp.data;
            dispatch( {type: actionTypes.GETPGKNOWLEDGESLOOKUP_SUCCESS, payload: {knowledgesLookup} } );
        } catch (error) {
            console.log('Error in getKnowledgesLookup', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGKNOWLEDGESLOOKUP_ERROR, payload: error } );
        }        
    }
}

export const insertKnowledge = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTKNOWLEDGE, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTKNOWLEDGE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertKnowledge', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTKNOWLEDGE_ERROR, payload: error } );
        }        
    }
}

export const deleteKnowledge = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEKNOWLEDGE, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEKNOWLEDGE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteKnowledge', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEKNOWLEDGE_ERROR, payload: error } );
        }        
    }
}

export const updateKnowledge = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEKNOWLEDGE, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEKNOWLEDGE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateKnowledge', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEKNOWLEDGE_ERROR, payload: error } );
        }        
    }
}

export const getKnowledgeInstances = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGKNOWLEDGEINSTANCES, oParams)
            const knowledgeinstances = resp.data;
            dispatch( {type: actionTypes.GETPGKNOWLEDGEINSTANCES_SUCCESS, payload: {knowledgeinstances} } );
        } catch (error) {
            console.log('Error in getKnowledgeInstances', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGKNOWLEDGEINSTANCES_ERROR, payload: error } );
        }        
    }
}

export const insertKnowledgeInstance = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTKNOWLEDGEINSTANCE, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTKNOWLEDGEINSTANCE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertKnowledgeInstance', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTKNOWLEDGEINSTANCE_ERROR, payload: error } );
        }        
    }
}

export const insertKnowledgeInstanceRecurring = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTKNOWLEDGEINSTANCERECURRING, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTKNOWLEDGEINSTANCERECURRING_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertKnowledgeInstanceRecurring', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTKNOWLEDGEINSTANCERECURRING_ERROR, payload: error } );
        }        
    }
}

export const deleteKnowledgeInstance = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEKNOWLEDGEINSTANCE, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEKNOWLEDGEINSTANCE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteKnowledgeInstance', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEKNOWLEDGEINSTANCE_ERROR, payload: error } );
        }        
    }
}

export const updateKnowledgeInstance = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEKNOWLEDGEINSTANCE, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEKNOWLEDGEINSTANCE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateKnowledgeInstance', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEKNOWLEDGEINSTANCE_ERROR, payload: error } );
        }        
    }
}

export const getKnowledgeSessions = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGKNOWLEDGESESSIONS, oParams)
            const knowledgesessions = resp.data;
            dispatch( {type: actionTypes.GETPGKNOWLEDGESESSIONS_SUCCESS, payload: {knowledgesessions} } );
        } catch (error) {
            console.log('Error in getKnowledgeSessions', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGKNOWLEDGESESSIONS_ERROR, payload: error } );
        }        
    }
}

export const getKnowledgeSessionsByUser = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGKNOWLEDGESESSIONSBYUSER, oParams)
            const myknowledgesessions = resp.data;
            dispatch( {type: actionTypes.GETPGKNOWLEDGESESSIONSBYUSER_SUCCESS, payload: {myknowledgesessions} } );
        } catch (error) {
            console.log('Error in getKnowledgeSessionsByUser', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGKNOWLEDGESESSIONSBYUSER_ERROR, payload: error } );
        }        
    }
}

export const getUserCalendar = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETUSERCALENDAR, oParams)
            const mycalendar = resp.data;
            dispatch( {type: actionTypes.GETUSERCALENDAR_SUCCESS, payload: {mycalendar} } );
        } catch (error) {
            console.log('Error in getUserCalendar', error);
            console.log(error);
            dispatch( {type: actionTypes.GETUSERCALENDAR_ERROR, payload: error } );
        }        
    }
}

export const updateKnowledgeSession = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEKNOWLEDGESESSION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEKNOWLEDGESESSION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateKnowledgeSession', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEKNOWLEDGESESSION_ERROR, payload: error } );
        }        
    }
}

export const processEnrollment = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_PROCESSENROLLMENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.PROCESSENROLLMENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in processEnrollment', error);
            console.log(error);
            dispatch( {type: actionTypes.PROCESSENROLLMENT_ERROR, payload: error } );
        }        
    }
}

export const processEnrollmentClear = () => {
    return async (dispatch) => {

        try {
            const returnParams = null;
            dispatch( {type: actionTypes.PROCESSENROLLMENTCLEAR_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in processEnrollmentClear', error);
            console.log(error);
            dispatch( {type: actionTypes.PROCESSENROLLMENTCLEAR_ERROR, payload: {error} } );
        }        
    }
}

export const insertUser = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTUSER, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTUSER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUser', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTUSER_ERROR, payload: error } );
        }        
    }
}

export const updateUser = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEUSER, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEUSER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertUser', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEUSER_ERROR, payload: error } );
        }        
    }
}

export const getContents = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGCONTENTS, oParams)
            const contents = resp.data;
            dispatch( {type: actionTypes.GETCONTENTS_SUCCESS, payload: {contents} } );
        } catch (error) {
            console.log('Error in GetContents', error);
            console.log(error);
            dispatch( {type: actionTypes.GETCONTENTS_ERROR, payload: error } );
        }        
    }
}

export const getScormForLaunch = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETSCORMFORLAUNCH, oParams)
            const scorm = resp.data;
            dispatch( {type: actionTypes.GETSCORMFORLAUNCH_SUCCESS, payload: {scorm} } );
        } catch (error) {
            console.log('Error in GetScormForLaunch', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSCORMFORLAUNCH_ERROR, payload: error } );
        }        
    }
}

export const getScormUserSession = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETSCORMUSERSESSION, oParams)
            const scormusersession = resp.data;
            dispatch( {type: actionTypes.GETSCORMUSERSESSION_SUCCESS, payload: {scormusersession} } );
        } catch (error) {
            console.log('Error in getScormUserSession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETSCORMUSERSESSION_ERROR, payload: error } );
        }        
    }
}

export const getAICCUserSession = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETAICCUSERSESSION, oParams)
            const aiccusersession = resp.data;
            dispatch( {type: actionTypes.GETAICCUSERSESSION_SUCCESS, payload: {aiccusersession} } );
        } catch (error) {
            console.log('Error in getAICCUserSession', error);
            console.log(error);
            dispatch( {type: actionTypes.GETAICCUSERSESSION_ERROR, payload: error } );
        }        
    }
}

export const insertScormUserSession = (oParams) => {
    return async (dispatch) => {

        try {
            console.log(oParams);
            console.log(process.env.REACT_APP_RETENTASAPI_INSERTSCORMUSERSESSION);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTSCORMUSERSESSION, oParams)
            console.log(resp);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTSCORMUSERSESSION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertScormUserSession', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTSCORMUSERSESSION_ERROR, payload: error } );
        }        
    }
}

export const insertAICCUserSession = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTAICCUSERSESSION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTAICCUSERSESSION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertAICCUserSession', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTAICCUSERSESSION_ERROR, payload: error } );
        }        
    }
}

export const insertContent = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTCONTENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTCONTENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertContent', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTCONTENT_ERROR, payload: error } );
        }        
    }
}

export const insertContentForm = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTCONTENTFORM, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTCONTENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertContentForm', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTCONTENT_ERROR, payload: error } );
        }        
    }
}

export const deleteContent = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETECONTENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETECONTENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteContent', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETECONTENT_ERROR, payload: error } );
        }        
    }
}

export const updateContent = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATECONTENT, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATECONTENT_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateContent', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATECONTENT_ERROR, payload: error } );
        }        
    }
}

export const insertCatalog = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTCATALOG, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTCATALOG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertCatalog', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTCATALOG_ERROR, payload: error } );
        }        
    }
}

export const deleteCatalog = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETECATALOG, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETECATALOG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteCatalog', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETECATALOG_ERROR, payload: error } );
        }        
    }
}

export const updateCatalog = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATECATALOG, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATECATALOG_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateCatalog', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATECATALOG_ERROR, payload: error } );
        }        
    }
}

export const getLearningObjectTree = (id) => {
    return async (dispatch) => {

        try {
            const sURL = process.env.REACT_APP_RETENTASAPI_GETLEARNINGOBJECTTREE + '?id=' + id;
            const respLearningObjectTree = await RetentasAPI.get(sURL)
            const orgLearningObjectTree = JSON.parse(respLearningObjectTree.data[0].TreeJSON);  
            // console.log(orgLearningObjectTree);
            dispatch( {type: actionTypes.GETORGLEARNINGOBJECTTREE_SUCCESS, payload: {orgLearningObjectTree} } );    
        } catch (error) {
            console.log('Error in getLearningObjectTree', error);
            console.log(error);
            dispatch( {type: actionTypes.GETORGLEARNINGOBJECTTREE_ERROR, payload: error } );
        }        
    }
}

export const getTestLibraries = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETTESTLIBRARIES, oParams)
            const testlibrary = resp.data;
            dispatch( {type: actionTypes.GETTESTLIBRARIES_SUCCESS, payload: {testlibrary} } );
        } catch (error) {
            console.log('Error in GetTestLibraries', error);
            console.log(error);
            dispatch( {type: actionTypes.GETTESTLIBRARIES_ERROR, payload: error } );
        }        
    }
}

export const insertTestLibrary = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTTESTLIBRARY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTTESTLIBRARY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertTestLibrary', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTTESTLIBRARY_ERROR, payload: error } );
        }        
    }
}

export const deleteTestLibrary = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETETESTLIBRARY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETETESTLIBRARY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteTestLibrary', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETETESTLIBRARY_ERROR, payload: error } );
        }        
    }
}

export const updateTestLibrary = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATETESTLIBRARY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATETESTLIBRARY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateTestLibrary', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATETESTLIBRARY_ERROR, payload: error } );
        }        
    }
}

export const insertTestInstance = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTTESTINSTANCE, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTTESTINSTANCE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertTestInstance', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTTESTINSTANCE_ERROR, payload: error } );
        }        
    }
}

export const deleteTestInstance = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETETESTINSTANCE, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETETESTINSTANCE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteTestInstance', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETETESTINSTANCE_ERROR, payload: error } );
        }        
    }
}

export const updateTestInstance = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATETESTINSTANCE, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATETESTINSTANCE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateTestInstance', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATETESTINSTANCE_ERROR, payload: error } );
        }        
    }
}

export const insertTestQuestion = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTTESTQUESTION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTTESTQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertTestQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTTESTQUESTION_ERROR, payload: error } );
        }        
    }
}

export const deleteTestQuestion = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETETESTQUESTION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETETESTQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteTestQuestion', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETETESTQUESTION_ERROR, payload: error } );
        }        
    }
}

export const updateTestQuestion = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATETESTQUESTION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATETESTQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateTestAnswer', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATETESTQUESTION_ERROR, payload: error } );
        }        
    }
}

export const insertTestAnswer = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTTESTANSWER, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTTESTQUESTION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertTestAnswer', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTTESTQUESTION_ERROR, payload: error } );
        }        
    }
}

export const deleteTestAnswer = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETETESTANSWER, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETETESTANSWER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteTestAnswer', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETETESTANSWER_ERROR, payload: error } );
        }        
    }
}

export const updateTestAnswer = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATETESTANSWER, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATETESTANSWER_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateTestAnswer', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATETESTANSWER_ERROR, payload: error } );
        }        
    }
}

export const getFacilities = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGFACILITIES, oParams)
            // console.log(resp.data);
            const facilities = resp.data;
            dispatch( {type: actionTypes.GETFACILITIES_SUCCESS, payload: {facilities} } );
        } catch (error) {
            console.log('Error in GetFacilities', error);
            console.log(error);
            dispatch( {type: actionTypes.GETFACILITIES_ERROR, payload: error } );
        }        
    }
}

export const insertFacility = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTFACILITY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTFACILITY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertFacility', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTFACILITY_ERROR, payload: error } );
        }        
    }
}

export const deleteFacility = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEFACILITY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEFACILITY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteFacility', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEFACILITY_ERROR, payload: error } );
        }        
    }
}

export const updateFacility = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEFACILITY, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEFACILITY_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateFacility', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEFACILITY_ERROR, payload: error } );
        }        
    }
}

export const insertFacilityRoom = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTFACILITYROOM, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTFACILITYROOM_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertFacilityRoom', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTFACILITYROOM_ERROR, payload: error } );
        }        
    }
}

export const updateFacilityRoom = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(evalParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEFACILITYROOM, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEFACILITYROOM_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateFacilityRoom', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEFACILITYROOM_ERROR, payload: error } );
        }        
    }
}

export const deleteFacilityRoom = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETEFACILITYROOM, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETEFACILITYROOM_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteFacilityRoom', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETEFACILITYROOM_ERROR, payload: error } );
        }        
    }
}

export const getCertifications = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGCERTIFICATIONS, oParams)
            const certifications = resp.data;
            dispatch( {type: actionTypes.GETPGCERTIFICATIONS_SUCCESS, payload: {certifications} } );
        } catch (error) {
            console.log('Error in getCertifications', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGCERTIFICATIONS_ERROR, payload: error } );
        }        
    }
}

export const getCertificationsLookup = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETPGCERTIFICATIONSLOOKUP, oParams)
            const certificationsLookup = resp.data;
            dispatch( {type: actionTypes.GETPGCERTIFICATIONSLOOKUP_SUCCESS, payload: {certificationsLookup} } );
        } catch (error) {
            console.log('Error in getCertificationsLookup', error);
            console.log(error);
            dispatch( {type: actionTypes.GETPGCERTIFICATIONSLOOKUP_ERROR, payload: error } );
        }        
    }
}

export const insertCertification = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTCERTIFICATION, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.INSERTCERTIFICATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in insertCertification', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTCERTIFICATION_ERROR, payload: error } );
        }        
    }
}

export const deleteCertification = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_DELETECERTIFICATION, oParams)
            const returnParams = resp.data;
            dispatch( {type: actionTypes.DELETECERTIFICATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in deleteCertification', error);
            console.log(error);
            dispatch( {type: actionTypes.DELETECERTIFICATION_ERROR, payload: error } );
        }        
    }
}

export const updateCertification = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATECERTIFICATION, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATECERTIFICATION_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateCertification', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATECERTIFICATION_ERROR, payload: error } );
        }        
    }
}

export const updateKnowledgeSessionObjective = (oParams) => {
    return async (dispatch) => {

        try {
            // console.log(oParams);
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_UPDATEKNOWLEDGESESSIONOBJECTIVE, oParams)
            // console.log(resp.data);
            const returnParams = resp.data;
            dispatch( {type: actionTypes.UPDATEKNOWLEDGESESSIONOBJECTIVE_SUCCESS, payload: {returnParams} } );
        } catch (error) {
            console.log('Error in updateKnowledgeSessionObjective', error);
            console.log(error);
            dispatch( {type: actionTypes.UPDATEKNOWLEDGESESSIONOBJECTIVE_ERROR, payload: error } );
        }        
    }
}

export const insertKnowledgeSessionAssessment = (oResponses) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_INSERTKNOWLEDGESESSIONASSESSMENT, oResponses)
            const knowledgesessionassessment = resp.data;
            dispatch( {type: actionTypes.INSERTKNOWLEDGESESSIONASSESSMENT_SUCCESS, payload: {knowledgesessionassessment} } );
        } catch (error) {
            console.log('Error in insertKnowledgeSessionAssessment', error);
            console.log(error);
            dispatch( {type: actionTypes.INSERTKNOWLEDGESESSIONASSESSMENT_ERROR, payload: error } );
        }        
    }
}

export const getKnowledgeSessionAssessment = (oParams) => {
    return async (dispatch) => {

        try {
            const resp = await RetentasAPI.post(process.env.REACT_APP_RETENTASAPI_GETKNOWLEDGESESSIONASSESSMENT, oParams)                                                    
            // console.log(resp.data);
            const knowledgesessionassessment = resp.data;
            dispatch( {type: actionTypes.GETKNOWLEDGESESSIONASSESSMENT_SUCCESS, payload: {knowledgesessionassessment} } );
        } catch (error) {
            console.log('Error in getKnowledgeSessionAssessment', error);
            console.log(error);
            dispatch( {type: actionTypes.GETKNOWLEDGESESSIONASSESSMENT_ERROR, payload: error } );
        }        
    }
}

export const clearEntityState = () => {
    return async (dispatch) => {
        dispatch({type: actionTypes.CLEARENTITYSTATE_SUCCESS});
    }
}

