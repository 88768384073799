import { PermissionType, ObservatisModules } from "../../config/Constant";

export const getPermissionFlags = (iPermissionType) => {
    // Determine flags based on iPermissionType
    const CanCreate = iPermissionType === PermissionType.OnlyCreateNoModify || iPermissionType === PermissionType.FullControl;
    const CanModify = iPermissionType === PermissionType.OnlyModifyNoCreate || iPermissionType === PermissionType.FullControl;
    const CanDelete = iPermissionType === PermissionType.FullControl;

    return {
        CanCreate,
        CanModify,
        CanDelete,
    };
}

export const AIMLModelModuleSubscribed = (modules) => {
    if (!modules) return false; // Handle empty or null input
    const oModules = modules.split(',').map(Number); // Convert strings to numbers
    return oModules.includes(ObservatisModules.AIMLModel);
}

export const AdaptiveKnowledgeIntelligenceModuleSubscribed = (modules) => {
    if (!modules) return false; // Handle empty or null input
    const oModules = modules.split(',').map(Number); // Convert strings to numbers
    return oModules.includes(ObservatisModules.AdaptiveKnowledgeIntelligence);
}

export const CompetencyManagementSubscribed = (modules) => {
    if (!modules) return false; // Handle empty or null input
    const oModules = modules.split(',').map(Number); // Convert strings to numbers
    return oModules.includes(ObservatisModules.CompetencyManagement);
}

export const EngagementSurveySubscribed = (modules) => {
    if (!modules) return false; // Handle empty or null input
    const oModules = modules.split(',').map(Number); // Convert strings to numbers
    return oModules.includes(ObservatisModules.EngagementSurvey);
}

export const PerformanceManagementSubscribed = (modules) => {
    if (!modules) return false; // Handle empty or null input
    const oModules = modules.split(',').map(Number); // Convert strings to numbers
    return oModules.includes(ObservatisModules.PerformanceManagement);
}

export const GoalManagementSubscribed = (modules) => {
    if (!modules) return false; // Handle empty or null input
    const oModules = modules.split(',').map(Number); // Convert strings to numbers
    return oModules.includes(ObservatisModules.GoalManagement);
}

