import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getKnowledgesLookup, assignLookupSelection } from '../../store/actions/entityActions';
import { KnowledgeType, MenuType } from '../../config/Constant';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

const KnowledgeLookupList = (props) => {
    const { auth, companyData, knowledgesLookup, evaluations, userattributelookups, lookupselections, loadingMenu, listby, fromLookupParam } = props;    
    const location  = useLocation();
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }

    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');

    const knowledge = knowledgesLookup?.knowledgesLookup?.LearningObjects;

    const radioKnowledege = [
        { name: 'Attributes', value: 1 },
        { name: 'Web Based', value: 2 },
        { name: 'Catalog', value: 3 },
        { name: 'Categories', value: 4 },
        { name: 'Outline', value: 5 },
        { name: 'Assessment', value: 6 },
        { name: 'Attachment', value: 7 },
        { name: 'Advanced', value: 8 },
        { name: 'Organization', value: 9 },
    ];

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }
    
    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Inactive', value: '2' },
        { name: 'All', value: '3' },
    ];
    const [radioTypeValue, setRadioTypeValue] = useState(initialRadioTypeValue());
    const radioTypes = [
        { name: 'Classroom', value: KnowledgeType.Classroom },
        { name: 'Online', value: KnowledgeType.Online },
        { name: 'Manual', value: KnowledgeType.Manual },
        { name: 'Historical', value: KnowledgeType.Historical },
        { name: 'All', value: KnowledgeType.All },
    ];

    function initialRadioValue() {
        return '1';
    };

    function initialRadioTypeValue() {
        return listByValue;
    };    
   
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = knowledgesLookup?.knowledgesLookup?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getKnowledgesLookup(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = knowledgesLookup?.knowledgesLookup?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getKnowledgesLookup(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.LearningObjectID + '=' + row.LearningObjectName ;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.Knowledge, currentSelectedValue);
        return currentSelectedValue;
    }

    function initialTabValue() {
        return 1;
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function searchKnowledges() {
        setLoading(true);
        const oParams = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getKnowledgesLookup(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            LearningObjectName:kRec.LearningObjectName, 
            takeAction:false,
            showDetails:false,
            editKnowledgeClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledge);
        setSliderValue(initialScoreValues);
    }, [knowledge])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.LearningObjectID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.LearningObjectID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }


function getSearchParamsEx(bRadioValue, bRadioTypeValue, pageNumber)  {
    let oParams = {
        LearningObjectTypeID:bRadioTypeValue,
        LearningObjectName:nodeName,
        IsCourseActive:(bRadioValue === '1') ? 'false' : 'true',
        RecordSize:'10',
        PageNumber:pageNumber,
    }; 
    return oParams;
}

async function searchKnowledgeTracks(bRadioValue, bRadioTypeValue) {
    setLoading(true);
    const oParams = getSearchParamsEx(bRadioValue, bRadioTypeValue, '1');
    await props.getKnowledgesLookup(oParams);
    setLoading(false);
}

async function handleRadioChange(e) {
    setRadioValue(e.currentTarget.value);
    searchKnowledgeTracks(e.currentTarget.value, radioTypeValue);
}

async function handleRadioTypeChange(e) {
    setRadioTypeValue(e.currentTarget.value);
    searchKnowledgeTracks(radioValue, e.currentTarget.value);
}

function displayListRadioButtons() {
    return (
        radios.map((radio, idx) => (
            <ToggleButton
                key={'checklisttype' + idx}
                id={`radio-${'checklisttype' + idx}`}
                type="radio"
                style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                size='sm'
                onChange={(e) => handleRadioChange(e)}
            >
                {radio.name}
            </ToggleButton>
        ))
    );
}

function displayGroupRadioButtons() {
    return (
        radioTypes.map((radio, idx) => (
            <ToggleButton
                key={'checklistgroup' + idx}
                id={`radio-${'checklistgroup' + idx}`}
                type="radio"
                style={radioTypeValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                name="radioType"
                value={radio.value}
                checked={radioTypeValue === radio.value}
                size='sm'
                onChange={(e) => handleRadioTypeChange(e)}
            >
                {radio.name}
            </ToggleButton>
        ))
    );
}

function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Knowledge Tracks</h3></td>
                <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}>
                                    <ButtonGroup className="mb-2" size='sm'>
                                        {displayListRadioButtons()}
                                    </ButtonGroup>                                                                   
                                </td>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <ButtonGroup className="mb-2" size='sm'>
                                        {displayGroupRadioButtons()}
                                    </ButtonGroup>                                                                   
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Knowledge Track'/>
                        <a onClick={() => searchKnowledges()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );    
    }

    function displayKnowledgeTypeName(assRec) {
        if (assRec?.LearningObjectTypeID === 1) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Classroom-Based'>supervised_user_circle</span>
            );
        } else if (assRec?.LearningObjectTypeID === 2) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Online'>touchpad_mouse</span>
            )
        } else if (assRec?.LearningObjectTypeID === 3) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Manual/External'>other_admission</span>
            );
        } else if (assRec?.LearningObjectTypeID === 4) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Historical'>work_history</span>
            );
        }
    }   
    
    return (
        <>
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {/* {displayNewKnowledge()}                                                */}
                            {((!knowledge) || (knowledge.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Knowledge Tracks.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableText}>Type</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Number</th>
                                                <th style={styles.tableText}>Employees</th>
                                                <th style={styles.tableText}>Instances</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {knowledge && knowledge.map((assRec, index) => {
                                                if (fromLookup) {
                                                    return (
                                                    <>
                                                        <tr>
                                                            <td style={styles.tableText}>{assRec?.LearningObjectID}</td>
                                                            <td style={styles.tableText}>{displayKnowledgeTypeName(assRec)}</td>
                                                            <td style={styles.tableTextSubHeader}>{assRec?.LearningObjectName}</td>
                                                            <td style={styles.tableText}>{assRec?.CourseNumber}</td>
                                                            <td style={styles.tableText}>{assRec?.StudentCount}</td>
                                                            <td style={styles.tableText}>{assRec?.ClassCount}</td>
                                                            <td style={styles.tableText}>
                                                                <div class="form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" role="switch" 
                                                                        checked={getDetailSwitchValue(assRec?.LearningObjectID)} id={`switch${'detail' + assRec?.LearningObjectID}`} 
                                                                        style={(getDetailSwitchValue(assRec?.LearningObjectID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                        onChange={(e) => {handleSwitchDetailChange(assRec?.LearningObjectID)}}>
                                                                    </input>
                                                                    <label class="form-check-label" for={`switch${'detail' + assRec?.LearningObjectID}`}>Select</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{knowledgesLookup?.knowledgesLookup?.RecordFound?.toLocaleString()}   Knowledge Tracks&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(knowledgesLookup?.knowledgesLookup?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(knowledgesLookup?.knowledgesLookup?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {knowledgesLookup?.knowledgesLookup?.PagingMetaData?.PageNumber}  of  {knowledgesLookup?.knowledgesLookup?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      knowledgesLookup: state.entity.knowledgesLookup, 
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledgesLookup: (kParams) => dispatch(getKnowledgesLookup(kParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeLookupList)