import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup, FormControl  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getKnowledges, getKnowledgeInstances, getUserAttributesLookups, insertKnowledgeInstance, insertKnowledgeInstanceRecurring, updateKnowledgeInstance, deleteKnowledgeInstance, 
        getJobPositions, getNotificationMessages, getFacilities, getKnowledgeSessions } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import { UserLookup, Rights, KnowledgeType, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant, LOLifeCycleStatus, KnowledgeListType } from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import Attachment from '../layout/Attachment';
import AlertMessage from '../layout/AlertMessage';
import JobPositions from '../auth/JobPositions';
import NotificationMessageList from './NotificationMessageList';
import FacilityList from './FacilityList';
import { RRule } from "rrule";

// import DateTimePicker from 'react-datetime-picker';
// import 'react-datetime-picker/dist/DateTimePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css';

const KnowledgeInstanceList = (props) => {
    const { auth, companyData, profile, knowledgeinstances, evaluations, userattributelookups, orgDivisions, lookupselections, loadingMenu, listby } = props;    
    const { id } = useParams();
    const location  = useLocation();
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [parentParams, setParentParams] = useState(location.state.parentParams);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [addNewKnowledgeInstance, setAddNewKnowledgeInstance] = useState(false);
    const [editKnowledgeInstance, setEditKnowledgeInstance] = useState(false);
    const [addNewDate, setAddNewDate] = useState(false);
    const [addNewAuthorization, setAddNewAuthorization] = useState(false);
    const [addNewResource, setAddNewResource] = useState(false);

    const [knowledgeID, setKnowledgeID] = useState(id);
    const [knowledgeInstanceID, setKnowledgeInstanceID] = useState(-1);

    // const [knowledgeInstanceStartDate, onStartDateTimeChange] = useState(new Date());
    // const [knowledgeInstanceEndDate, onEndDateTimeChange] = useState(new Date());
    const [instanceStartDate, setInstanceStartDate] = useState(new Date());
    const [instanceStartTime, setInstanceStartTime] = useState('08:00');
    const [instanceStartAMPM, setInstanceStartAMPM] = useState('AM');
    const [instanceEndDate, setInstanceEndDate] = useState(new Date());
    const [instanceEndTime, setInstanceEndTime] = useState('08:00');
    const [instanceEndAMPM, setInstanceEndAMPM] = useState('AM');
    const [instanceTimeZone, setInstanceTimeZone] = useState('-1');
    const [maximumStudent, setMaximumStudent] = useState('');
    const [minimumStudent, setMinimumStudent] = useState('');
    const [signInOut, setSignInOut] = useState('');
    const [registrationCutOff, setRegistrationCutOff] = useState('');
    const [registrationCutOffHours, setRegistrationCutOffHours] = useState('');
    const [cancellationCutOff, setCancellationCutOff] = useState('');
    const [cancellationCutOffHours, setCancellationCutOffHours] = useState('');
    const [testTakeType, setTestTakeType] = useState('');
    const [guestSpeakers, setGuestSpeakers] = useState('');
    const [processStatusTypeID, setProcessStatusTypeID] = useState('');
    const [launchDate, setLaunchDate] = useState('');
    const [enrollmentPeriod, setEnrollmentPeriod] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [subjectCode, setSubjectCode] = useState('');
    const [notes, setNotes] = useState('');
    const [privateNotes, setPrivateNotes] = useState('');
    const [externalWebinarURL, setExternalWebinarURL] = useState('');
    const [advertisementOnly, setAdvertisementOnly] = useState('');
    const [featured, setFeatured] = useState('');
    const [preventTranscripLaunch, setPreventTranscripLaunch] = useState('');
    const [allowReprintCertificate, setAllowReprintCertificate] = useState('');
    const [reprintCertificationCost, setReprintCertificationCost] = useState('');
    const [allowEnrollmentExtension, setAllowEnrollmentExtension] = useState('');
    const [enrollmentExtensionCost, setEnrollmentExtensionCost] = useState('');
    const [allowMove, setAllowMove] = useState('');
    const [noShowCharge, setNoShowCharge] = useState('');
    const [cancellationCharge, setCancellationCharge] = useState('');
    const [promotionPrice, setPromotionPrice] = useState('');
    const [assignmentInstance, setAssignmentInstance] = useState('');
    const [forcePayment, setForcePayment] = useState('');
    const [prerequisiteDuringLaunch, setPrerequisiteDuringLaunch] = useState('');
    const [byPassSessionCap, setByPassSessionCap] = useState('');
    const [byPassValidation, setByPassValidation] = useState('');
    const [byPassNotification, setByPassNotification] = useState('');
    const [preventRetake, setPreventRetake] = useState(false);
    const [byPassDateTimeConflicts, setByPassDateTimeConflicts] = useState(false);
    const [enableMultiSession, setEnableMultiSession] = useState(false);
    const [byPassPrerequisites, setByPassPrerequisites] = useState(false);
    const [prerequisiteExpression, setPrerequisiteExpression] = useState('');
    const [forceEvaluation, setForceEvaluation] = useState(false);
    const [evaluationStartDays, setEvaluationStartDays] = useState(0);
    const [evaluationEndDays, setEvaluationEndDays] = useState(0);
    const [forceROMS, setForceROMS] = useState(false);
    const [evaluationFromTranscript, setEvaluationFromTranscript] = useState(false);
    const [includeWaitlist, setIncludeWaitlist] = useState(false);
    const [autoTransfer, setAutoTransfer] = useState(false);
    const [waitlistCap, setWaitlistCap] = useState(0);
    const [emailTo, setEmailTo] = useState('');
    const [otherEmails, setOtherEmails] = useState('');
    const [authorizeInstructorOnly, setAuthorizeInstructorOnly] = useState(false);
    const [byPassAuthorization, setByPassAuthorization] = useState(true);
    const [paymentModes, setPaymentModes] = useState('');
    const [instanceDateArray, setInstanceDateArray] = useState([]);
    const [instanceDateDetails, setInstanceDateDetails] = useState('');
    const [instanceDates, setInstanceDates] = useState('');
    const [instructors, setInstructors] = useState('');    
    const [instructorDetails, setInstructorDetails] = useState('');    
    const [instructorArray, setInstructorArray] = useState([]);    
    const [presenters, setPresenters] = useState('');    
    const [presenterDetails, setPresenterDetails] = useState('');    
    const [presenterArray, setPresenterArray] = useState([]);    
    const [jobPositions, setJobPositions] = useState('');
    const [jobPositionDetails, setJobPositionDetails] = useState('');    
    const [jobPositionArray, setJobPositionArray] = useState([]);    
    const [authorizationGroup, setAuthorizationGroup] = useState('');    
    const [authorizationGroupName, setAuthorizationGroupName] = useState('');    
    const [authorizationLevel, setAuthorizationLevel] = useState('');    
    const [authorizationNotificationMessageArray, setAuthorizationNotificationMessageArray] = useState([]);
    const [authorizationNotificationMessageDetails, setAuthorizationNotificationMessageDetails] = useState([]);
    const [authorizationNotificationMessages, setAuthorizationNotificationMessages] = useState('');    
    const [authorizationNotificationMessageNames, setAuthorizationNotificationMessageNames] = useState('');    
    const [authorizationDetails, setAuthorizationDetails] = useState('');    
    const [authorizationArray, setAuthorizationArray] = useState([]);    
    const [facilities, setFacilities] = useState('');    
    const [facilityRooms, setFacilityRooms] = useState('');    
    const [facilityNames, setFacilityNames] = useState('');    

    const [resourceTypeID, setResourceTypeID] = useState('');    
    const [resourceTypeName, setResourceTypeName] = useState('');    
    const [resourceNumberOfUnits, setResourceNumberOfUnits] = useState('');    
    const [resourceTotalPrice, setResourceTotalPrice] = useState('');    
    const [resourceNumberOfUnitsAllocated, setResourceNumberOfUnitsAllocated] = useState('');    
    const [resourceDueDate, setResourceDueDate] = useState('');    
    const [resourceCompletedDate, setResourceCompletedDate] = useState('');    
    const [resourceDetails, setResourceDetails] = useState('');    
    const [resourceArray, setResourceArray] = useState([]);    

    const [isAttachmentSectionDisabled, setIsAttachmentSectionDisabled] = useState(false);
    const [isAttachmentSectionVisible, setIsAttachmentSectionVisible] = useState(false);

    const [knowledgeDivisionDetails, setKnowledgeDivisionDetails] = useState('');
    const [knowledgeDivisions, setKnowledgeDivisions] = useState('');

    const [showInstructorList, setShowInstructorList] = useState(false);
    const [showPresenterList, setShowPresenterList] = useState(false);
    const [showJPList, setShowJPList] = useState(false);
    const [showNotificationMessageList, setShowNotificationMessageList] = useState(false);
    const [showFacilityList, setShowFacilityList] = useState(false);

    const [showRecurringParameters, setShowRecurringParameters] = useState(false);
    const [frequency, setFrequency] = useState(RRule.WEEKLY);
    const [interval, setInterval] = useState(1);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
    const [endDate, setEndDate] = useState("");
    const [events, setEvents] = useState([]);    
    const weekdays = [
        { label: "Monday", value: RRule.MO },
        { label: "Tuesday", value: RRule.TU },
        { label: "Wednesday", value: RRule.WE },
        { label: "Thursday", value: RRule.TH },
        { label: "Friday", value: RRule.FR },
        { label: "Saturday", value: RRule.SA },
        { label: "Sunday", value: RRule.SU },
      ];

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const knowledgeinstance = knowledgeinstances?.knowledgeinstances?.LearningObjectInstance;
    const loiDates = knowledgeinstances?.knowledgeinstances?.LearningObjectInstanceDate;
    const loiInstructors = knowledgeinstances?.knowledgeinstances?.LearningObjectInstanceInstructor;
    const loiPaymentModes = knowledgeinstances?.knowledgeinstances?.LearningObjectInstancePaymentMode;
    const loiJobPositions = knowledgeinstances?.knowledgeinstances?.LearningObjectInstanceJobPosition;
    const loiAuthorizerGroups = knowledgeinstances?.knowledgeinstances?.LearningObjectInstanceAuthorizerGroup;
    const loiResources = knowledgeinstances?.knowledgeinstances?.LearningObjectInstanceResource;
    const availability = knowledgeinstances?.knowledgeinstances?.Availability;

    const discountTypeData = userattributelookups?.DiscountTypes;
    const paymentModeTypeData = userattributelookups?.PaymentModes;
    const groupTypeData = userattributelookups?.Groups;
    const resourceTypeData = userattributelookups?.ResourceTypes;

    const navigate = useNavigate();

    const radioKnowledege = [
        { name: 'Attributes', value: 1 },
        { name: 'Registration', value: 2 },
        { name: 'Authorization', value: 3 },
        { name: 'Attachment', value: 4 },
        { name: 'Advanced', value: 5 },
        { name: 'Organization', value: 6 },
    ];

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }

    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Pending', value: '1' },
        { name: 'Schedule', value: '9' },
        { name: 'Completed', value: '4' },
        { name: 'Cancelled', value: '5' },
        { name: 'All', value: '0' },
    ];

    function initialRadioValue() {
        return '0';
    };

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = knowledgeinstances?.knowledgeinstances?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            LearningObjectProcessStatusTypeID:radioValue,
            LearningObjectID:knowledgeID,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getKnowledgeInstances(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = knowledgeinstances?.knowledgeinstances?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            LearningObjectProcessStatusTypeID:radioValue,
            LearningObjectID:knowledgeID,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getKnowledgeinstances(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    function initialTabValue() {
        return 1;
    };

    const toggleDay = (day) => {
        setDaysOfWeek((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };
    
    function generateRecurringInstances(occurrences) {
        const oParams  = {
            LearningObjectID: knowledgeID,
            LearningObjectInstanceID: -1,
            AdvertisementOnly: (advertisementOnly === '1' ? true : false),
            AllowExtention: (allowEnrollmentExtension === '1' ? true : false),
            AllowReprintCertificate: (allowReprintCertificate === '1' ? true : false),
            AssessmentTakeType: testTakeType,
            AssignmentInstance: (assignmentInstance === '1' ? true : false),
            AuthorizeByInstructorOnly: (authorizeInstructorOnly === '1' ? true : false),
            ByPassDateTimeConflicts: (byPassDateTimeConflicts === '1' ? true : false),
            ByPassEmailNotification: (byPassNotification === '1' ? true : false),
            ByPassPrerequisiteChecking: (byPassPrerequisites === '1' ? true : false),
            ByPassValidationCheck: (byPassValidation === '1' ? true : false),
            CancellationCost: cancellationCharge, 
            CancellationCutOff: (cancellationCutOff === '1' ? true : false),
            CancellationCutOffHours: cancellationCutOffHours,
            ClockedInOut: signInOut,
            DisableAuthorization: (byPassAuthorization === '1' ? true : false),
            DivisionIDs: knowledgeDivisions,
            EmailToTypeID: emailTo, 
            EnableStudentMultiSessons: (enableMultiSession === '1' ? true : false),
            EnrollmentCutOff: (registrationCutOff === '1' ? true : false),
            EnrollmentCutOffHours: registrationCutOffHours,
            ExtentionCost: enrollmentExtensionCost, 
            Duration: enrollmentPeriod,
            EvaluationEndDays: evaluationEndDays, 
            EvaluationFromTranscript: (evaluationFromTranscript === '1' ? true : false),
            EvaluationStartDays: evaluationStartDays,
            ExpiryDate: expirationDate,
            ExternalWebAttendeeURL: externalWebinarURL, 
            Featured: (featured === '1' ? true : false),
            ForceEvaluation: (forceEvaluation === '1' ? true : false),
            ForcePayment: (forcePayment === '1' ? true : false),
            ForceROMS: (forceROMS === '1' ? true : false),
            GuestSpeakers: guestSpeakers,
            IgnoreSessionCap: (byPassSessionCap === '1' ? true : false),
            LearningObjectInstanceInstructors: instructors,
            LearningObjectInstanceDates: instanceDateDetails,
            LaunchDate: launchDate,
            LearningObjectProcessStatusTypeID: processStatusTypeID,
            MaxStudents: maximumStudent,
            MinStudents: minimumStudent,
            MoveNoShows: (allowMove === '1' ? true : false),
            NoShowCost: noShowCharge, 
            NoTranscriptLaunch: preventTranscripLaunch,
            OtherEmails: otherEmails,
            LearningObjectInstanceAuthorizerGroups: authorizationDetails,
            LearningObjectInstancePaymentModes: paymentModes,
            LearningObjectInstancePresenters: presenters,
            LearningObjectInstanceJobPositions: jobPositions,
            LearningObjectInstanceResources: resourceDetails,
            FacilityID: facilities,
            FacilityRoomID: facilityRooms,
            PrerequisiteForLaunch: (prerequisiteDuringLaunch === '1' ? true : false),
            PrerequisitesExpression: prerequisiteExpression,
            PreventRetake: (preventRetake === '1' ? true : false),
            PrivateDescription: privateNotes, 
            PromoPrice: promotionPrice,
            PublicDescription: notes, 
            ReprintCertificateCost: reprintCertificationCost,
            SubjectCode: subjectCode, 
            WaitlistAutoTransfer: (autoTransfer === '1' ? true : false),
            WaitlistCap: waitlistCap,                      
            IsDeleted:false,
            CreatedByUserID: auth?.UserID,
            UpdatedByUserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
        };        

        return occurrences.map(date => {
            // Extract the time part from the existing LearningObjectInstanceDates
            const dateParts = oParams.LearningObjectInstanceDates.split('|');
            const timePart = dateParts[0].split('T')[1]; // Extracts "08:00:00"
            const timezone = dateParts[2]; // Extracts "PST"
    
            // Format new date while keeping the time component unchanged
            const formattedDate = date.toISOString().split('T')[0] + 'T' + timePart;
    
            // Create a new object with the updated LearningObjectInstanceDates
            return {
                ...oParams,
                LearningObjectInstanceDates: `${formattedDate}|${dateParts[1]}|${timezone}`
            };
        });
    }
    
    async function generateEvents(){
        setLoading(true);
        // Convert user-selected days (like "MO", "TU") to RRule constants
        const mappedDays = daysOfWeek.map((day) => RRule[day]);        
        const rule = new RRule({
            freq: frequency,
            interval: parseInt(interval, 10),
            byweekday: mappedDays.length > 0 ? mappedDays : null, 
            dtstart: new Date(startDate), 
            until: endDate ? new Date(endDate) : new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // Default to 1 year
        });

        const occurrences = rule.between(new Date(startDate), new Date(endDate), true).map(date => new Date(date.getTime() + (new Date().getTimezoneOffset() * 60000)));  // Adjust for local timezone
        console.log(occurrences);
        setEvents(occurrences); // Get all occurrence dates
        setShowRecurringParameters(false);
        const oRParams = generateRecurringInstances(occurrences);
        // console.log(oRParams);
        await props.insertKnowledgeInstanceRecurring(oRParams);    
        const oParams = {
            LearningObjectProcessStatusTypeID:radioValue,
            LearningObjectID:knowledgeID,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getKnowledgeInstances(oParams);
        ClearStateFields();
        setLoading(false);
    };
      
      
    async function handleCancel() {
        setLoading(true);
        await props.getKnowledges(parentParams);
        setLoading(false);
        navigate('/knowledgelist', {state: {permissiontypeid:permissionTypeID, listby:listByValue}});
    }

    const handleCloseRecurringParameters = () => {setShowRecurringParameters(false);}
    const handleShowRecurringParameters = () => {setShowRecurringParameters(true);}


    const handleCloseInstructorList = () => {
        setShowInstructorList(false);
        updateInstructorSelection();
    }

    async function handleShowInstructorList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.Preceptor,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getUserLookup(oParams);

        setShowInstructorList(true); 
        setLoading(false);
    };

    function updateInstructorSelection() {
        var sSelections = lookupselections?.User;
        if (instructorDetails !== '') {
            sSelections = instructorDetails + '&' + sSelections;
        }
        if (instructors !== '') {
            sSelections = instructors + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setInstructorDetails(sSelections);
            setInstructorArray(oSelections);
            setInstructors(sSelectedIds);
        }
    }

    function handleDeleteAssignedInstructorClick(goal) {
        var tempSkills = instructorArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setInstructorArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setInstructors(sSelectedIds);
            setInstructorDetails(sSelectedDetails);
        }        
    }

    const handleClosePresenterList = () => {
        setShowPresenterList(false);
        updatePresenterSelection();
    }

    async function handleShowPresenterList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.Preceptor,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getUserLookup(oParams);

        setShowPresenterList(true); 
        setLoading(false);
    };

    function updatePresenterSelection() {
        var sSelections = lookupselections?.User;
        if (presenterDetails !== '') {
            sSelections = presenterDetails + '&' + sSelections;
        }
        if (presenters !== '') {
            sSelections = presenters + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setPresenterDetails(sSelections);
            setPresenterArray(oSelections);
            setPresenters(sSelectedIds);
        }
    }

    function handleDeleteAssignedPresenterClick(goal) {
        var tempSkills = presenterArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setPresenterArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setPresenters(sSelectedIds);
            setPresenterDetails(sSelectedDetails);
            setPresenters(sSelectedIds);
        }        
    }

    const handleCloseJPList = () => {
        setShowJPList(false);
        updateJobPositionSelection();
    }

    async function handleShowJPList() {
        setLoading(true);
        const jpParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getJobPositions(jpParams);
        setShowJPList(true); 
        setLoading(false);
    };

    function updateJobPositionSelection() {
        const sSelections = lookupselections?.JobPosition;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1]?.split('|')[0];    
                }
            });
            setJobPositions(sSelectedIds);
            setJobPositionDetails(sSelections);
            setJobPositionArray(oSelections);
        }
    }

    function handleDeleteAssignedJobPositionClick(goal) {
        var tempSkills = jobPositionArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setJobPositionArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setJobPositions(sSelectedIds);
            setJobPositionDetails(sSelectedDetails);
        }        
    }

    const handleCloseNotificationMessageList = () => {
        setShowNotificationMessageList(false);
        updateNotificationMessageSelection();
    }

    async function handleShowNotificationMessageList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);

        setShowNotificationMessageList(true); 
        setLoading(false);
    };

    function updateNotificationMessageSelection() {
        var sSelections = lookupselections?.NotificationMessage;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setAuthorizationNotificationMessages(sSelectedIds);
            setAuthorizationNotificationMessageNames(sSelectedNames);
        }
    }

    const handleCloseFacilityList = () => {
        setShowFacilityList(false);
        updateFacilitySelection();
    }

    async function handleShowFacilityList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getFacilities(oParams);

        setShowFacilityList(true); 
        setLoading(false);
    };

    function updateFacilitySelection() {
        var sSelections = lookupselections?.FacilityRoom;
        console.log(sSelections);
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedOIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedOIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0].split('|')[0];
                sSelectedOIds += element?.split('=')[0].split('|')[1];
                sSelectedNames += element?.split('=')[1].split('|')[0] + ' - ' + element?.split('=')[1].split('|')[1];
            });
            setFacilities(sSelectedIds);
            setFacilityRooms(sSelectedOIds);
            setFacilityNames(sSelectedNames);
        }
    }

    function updateSubChildrenSelect(fieldIndex, selected) {
        if (fieldIndex === 1) {
            let selectedValues = '';
            let selectedNames = '';
            [...selected].map((sSelOption) => {
                if (selectedValues.trim() !== '') {
                    selectedValues += ',';
                }
                if (selectedNames.trim() !== '') {
                    selectedNames += ',';
                }
                selectedValues += sSelOption.value;
            });
            setPaymentModes(selectedValues);
        } else if (fieldIndex === 2) {
            setAuthorizationGroup(selected[0].value);
            setAuthorizationGroupName(selected[0].text);
        } else if (fieldIndex === 3) {
            setResourceTypeID(selected[0].value);
            setResourceTypeName(selected[0].text);
        } 
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        // Only for Attachment Tab
        if (tab === 4) {
            console.log(editKnowledgeInstance);
            if (addNewKnowledgeInstance) {
                setIsAttachmentSectionDisabled(true);
                setIsAttachmentSectionVisible(false);
            } else if (editKnowledgeInstance) {
                setIsAttachmentSectionDisabled(false);
                setIsAttachmentSectionVisible(true);
            }
        }

        displayTabContent();
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        // const divisionId = nodes[0]?.data?.id;
        let divisionIds = '';
        let divisionDetails = '';
        // console.log(nodes);
        nodes.forEach(element => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += element?.data?.id;
            divisionDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (divisionIds) {
            setSelectedNodes(nodes);
            setKnowledgeDivisions(divisionIds);
            setKnowledgeDivisionDetails(divisionDetails);
        }
        setLoading(false);
    }

    function handleDeleteAssignedTreeNodeClick(goal) {
        const knowledgeDivisionArray = knowledgeDivisionDetails.split('&');
        var tempSkills = knowledgeDivisionArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedDetails += element;
            });

            setKnowledgeDivisions(sSelectedIds);
            setKnowledgeDivisionDetails(sSelectedDetails);
        }        
    }

    async function searchKnowledgeInstances() {
        setLoading(true);
        const oParams = {
            LearningObjectProcessStatusTypeID:radioValue,
            LearningObjectID:knowledgeID,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getKnowledgeInstances(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            LearningObjectInstanceID:kRec.LearningObjectInstanceID,
            takeAction:false,
            showDetails:false,
            editKnowledgeInstanceClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledgeinstance);
        setSliderValue(initialScoreValues);
    }, [knowledgeinstance])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
            
    function handleCategoriesClick(e, type) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });

        if (type === 1) {
            setPaymentModes(selectedValues);
        } else if (type === 6) {
            setKnowledgeDivisions(selectedValues);
        } 
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewKnowledgeInstance) ? setAddNewKnowledgeInstance(false) : setAddNewKnowledgeInstance(true);
            setKnowledgeInstanceID(-1);
        } else if (tab === 2) {
            (addNewDate) ? setAddNewDate(false) : setAddNewDate(true);            
        } else if (tab === 3) {
            (addNewAuthorization) ? setAddNewAuthorization(false) : setAddNewAuthorization(true);            
        } else if (tab === 4) {
            (addNewResource) ? setAddNewResource(false) : setAddNewResource(true);            
        }
        
        setLoading(false);
    }

    async function handleSaveChildernClick(tab, currentEval) {
        var bCanSave = true;
        if (!((processStatusTypeID) && (processStatusTypeID.trim !== ''))) {
            setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
            setAlertVariant(AlertVariant.Warning);
            setShowAlertMessage(true);
            bCanSave = false;
        }

        if (bCanSave) {
            setLoading(true);
            if (tab === 1) {
                const oParams  = {
                    LearningObjectID: knowledgeID,
                    LearningObjectInstanceID: knowledgeInstanceID,
                    AdvertisementOnly: (advertisementOnly === '1' ? true : false),
                    AllowExtention: (allowEnrollmentExtension === '1' ? true : false),
                    AllowReprintCertificate: (allowReprintCertificate === '1' ? true : false),
                    AssessmentTakeType: testTakeType,
                    AssignmentInstance: (assignmentInstance === '1' ? true : false),
                    AuthorizeByInstructorOnly: (authorizeInstructorOnly === '1' ? true : false),
                    ByPassDateTimeConflicts: (byPassDateTimeConflicts === '1' ? true : false),
                    ByPassEmailNotification: (byPassNotification === '1' ? true : false),
                    ByPassPrerequisiteChecking: (byPassPrerequisites === '1' ? true : false),
                    ByPassValidationCheck: (byPassValidation === '1' ? true : false),
                    CancellationCost: cancellationCharge, 
                    CancellationCutOff: (cancellationCutOff === '1' ? true : false),
                    CancellationCutOffHours: cancellationCutOffHours,
                    ClockedInOut: signInOut,
                    DisableAuthorization: (byPassAuthorization === '1' ? true : false),
                    DivisionIDs: knowledgeDivisions,
                    EmailToTypeID: emailTo, 
                    EnableStudentMultiSessons: (enableMultiSession === '1' ? true : false),
                    EnrollmentCutOff: (registrationCutOff === '1' ? true : false),
                    EnrollmentCutOffHours: registrationCutOffHours,
                    ExtentionCost: enrollmentExtensionCost, 
                    Duration: enrollmentPeriod,
                    EvaluationEndDays: evaluationEndDays, 
                    EvaluationFromTranscript: (evaluationFromTranscript === '1' ? true : false),
                    EvaluationStartDays: evaluationStartDays,
                    ExpiryDate: expirationDate,
                    ExternalWebAttendeeURL: externalWebinarURL, 
                    Featured: (featured === '1' ? true : false),
                    ForceEvaluation: (forceEvaluation === '1' ? true : false),
                    ForcePayment: (forcePayment === '1' ? true : false),
                    ForceROMS: (forceROMS === '1' ? true : false),
                    GuestSpeakers: guestSpeakers,
                    IgnoreSessionCap: (byPassSessionCap === '1' ? true : false),
                    LearningObjectInstanceInstructors: instructors,
                    LearningObjectInstanceDates: instanceDateDetails,
                    LaunchDate: launchDate,
                    LearningObjectProcessStatusTypeID: processStatusTypeID,
                    MaxStudents: maximumStudent,
                    MinStudents: minimumStudent,
                    MoveNoShows: (allowMove === '1' ? true : false),
                    NoShowCost: noShowCharge, 
                    NoTranscriptLaunch: preventTranscripLaunch,
                    OtherEmails: otherEmails,
                    LearningObjectInstanceAuthorizerGroups: authorizationDetails,
                    LearningObjectInstancePaymentModes: paymentModes,
                    LearningObjectInstancePresenters: presenters,
                    LearningObjectInstanceJobPositions: jobPositions,
                    LearningObjectInstanceResources: resourceDetails,
                    FacilityID: facilities,
                    FacilityRoomID: facilityRooms,
                    PrerequisiteForLaunch: (prerequisiteDuringLaunch === '1' ? true : false),
                    PrerequisitesExpression: prerequisiteExpression,
                    PreventRetake: (preventRetake === '1' ? true : false),
                    PrivateDescription: privateNotes, 
                    PromoPrice: promotionPrice,
                    PublicDescription: notes, 
                    ReprintCertificateCost: reprintCertificationCost,
                    SubjectCode: subjectCode, 
                    WaitlistAutoTransfer: (autoTransfer === '1' ? true : false),
                    WaitlistCap: waitlistCap,                      
                    IsDeleted:false,
                    CreatedByUserID: auth?.UserID,
                    UpdatedByUserID: auth?.UserID,
                    CompanyID: auth?.CompanyID,
                };        
                console.log(oParams);
                if (knowledgeInstanceID === -1) {
                    await props.insertKnowledgeInstance(oParams);    
                    setAddNewKnowledgeInstance(false);
                } else {
                    await props.updateKnowledgeInstance(oParams);    
                    setEditKnowledgeInstance(false);
                }
            } 
    
            const oParams = {
                LearningObjectProcessStatusTypeID:radioValue,
                LearningObjectID:knowledgeID,
                RecordSize:'10',
                PageNumber:1,
            }; 
            console.log(oParams);
            await props.getKnowledgeInstances(oParams);
    
            setAddNewKnowledgeInstance(false);
            setEditKnowledgeInstance(false);
            setLoading(false);    
        }
    }

    async function handleDeleteChildernClick(id) {
        setLoading(true);

        const oParams  = {
            LearningObjectInstanceID: id,
        };        
        console.log(oParams);
        await props.deleteKnowledgeInstance(oParams);    
        
        const oParams1 = {
            LearningObjectProcessStatusTypeID:radioValue,
            LearningObjectID:knowledgeID,
            RecordSize:'10',
            PageNumber:1,
        }; 
        console.log(oParams1);
        await props.getKnowledgeInstances(oParams1);            

        setLoading(false);
    }

    function UpdateChildrenState(id) {
        let dateIDs = '';
        const instanceDateRec = loiDates?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        instanceDateRec?.map((row, index) => {
            if (dateIDs.trim() !== '') {
                dateIDs += '&';
            }
            dateIDs += row?.StartDateTime + '|' + row?.EndDateTime + '|' + row?.TimeZone;
        });
        if (dateIDs !== '') {
            setInstanceDateDetails(dateIDs);
            setInstanceDateArray(dateIDs.split('&'));    
        } else {
            setInstanceDateDetails('');
            setInstanceDateArray([]);    
        }

        let instrIDs = '';
        let instrNames = '';
        let instrDetails = '';
        let presIDs = '';
        let presNames = '';
        let presDetails = '';
        const instrRec = loiInstructors?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        instrRec?.map((row, index) => {
            if (row?.OnlyPresenter) {
                if (presIDs.trim() !== '') {
                    presIDs += ',';
                }
                if (presDetails.trim() !== '') {
                    presDetails += '&';
                }
                presIDs += row?.InstructorUserID;
                presDetails += row?.InstructorUserID + '=' + row?.InstructorName + '|' + row?.InstructorVendorUserID + '|' + row?.InstructorEmail;    

            } else {
                if (instrIDs.trim() !== '') {
                    instrIDs += ',';
                }
                if (instrDetails.trim() !== '') {
                    instrDetails += '&';
                }
                instrIDs += row?.InstructorUserID;
                instrDetails += row?.InstructorUserID + '=' + row?.InstructorName + '|' + row?.InstructorVendorUserID + '|' + row?.InstructorEmail;    
            }
        });
        if (instrIDs.trim() !== '') {
            setInstructors(instrIDs);
        } else {
            setInstructors('');
        }
        if (instrDetails.trim() !== '') {
            const oSelections = instrDetails?.split('&');
            setInstructorArray(oSelections);
        } else {
            setInstructorArray([]);
        }

        if (presIDs.trim() !== '') {
            setPresenters(presIDs);
        } else {
            setPresenters('');
        }
        if (presDetails.trim() !== '') {
            const oSelections = presDetails?.split('&');
            setPresenterArray(oSelections);
        } else {
            setPresenterArray([]);
        }

        let jpIDs = '';
        let jpNames = '';
        let jpDetails = '';
        const jpRec = loiJobPositions?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        jpRec?.map((row, index) => {
            if (jpIDs.trim() !== '') {
                jpIDs += ',';
            }
            if (jpDetails.trim() !== '') {
                jpDetails += '&';
            }
            jpIDs += row?.JobPositionID;
            jpDetails += row?.JobPositionID + '=' + row?.JobPositionDescription?.replaceAll("&", "and");    
        });
        if (jpIDs.trim() !== '') {
            setJobPositions(jpIDs);
        } else {
            setJobPositions('');
        }
        if (jpDetails.trim() !== '') {
            const oSelections = jpDetails?.split('&');
            setJobPositionArray(oSelections);
        } else {
            setJobPositionArray([]);
        }

        let authDetails = '';
        const authRec = loiAuthorizerGroups?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        authRec?.map((row, index) => {
            if (authDetails.trim() !== '') {
                authDetails += '&';
            }
            authDetails += row?.GroupID + '|' + row?.GroupName?.replaceAll("&", "and") + '|' + row?.LevelNumber + '|' + row?.NotificationMessageID + '|' + row?.NotificationMessageName?.replaceAll("&", "and");    
        });
        if (authDetails.trim() !== '') {
            const oSelections = authDetails?.split('&');
            setAuthorizationArray(oSelections);
            setAuthorizationDetails(authDetails);
        } else {
            setAuthorizationArray([]);
            setAuthorizationDetails('');
        }

        let resDetails = '';
        const resRec = loiResources?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        resRec?.map((row, index) => {
            if (resDetails.trim() !== '') {
                resDetails += '&';
            }
            resDetails += row?.ResourceTypeID + '|' + row?.ResourceTypeName?.replaceAll("&", "and") + '|' + row?.NumberOfUnits + '|' + row?.TotalPrice + '|' + row?.NumberOfUnitsAllocated + '|' + row?.DueDate + '|' + row?.CompletedDate;    
        });
        if (resDetails.trim() !== '') {
            const oSelections = resDetails?.split('&');
            setResourceArray(oSelections);
            setResourceDetails(resDetails);
        } else {
            setResourceArray([]);
            setResourceDetails('');
        }

        let pmIDs = '';
        const pmRec = loiPaymentModes?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        pmRec?.map((row, index) => {
            if (pmIDs.trim() !== '') {
                pmIDs += ',';
            }
            pmIDs += row?.PaymentModeID;
        });
        if (pmIDs != '') {
            setPaymentModes(pmIDs);
        } else {            
            setPaymentModes('');
        }

        let divisionIds = '';
        let divisionDetails = '';
        const availabilityRec = availability?.filter((cRec) => (cRec.LearningObjectInstanceID === id));
        availabilityRec?.map((row, index) => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += row?.DivisionID;
            divisionDetails += row?.DivisionID + '=' + row?.DivisionNamePath;
        });
        if (divisionIds) {
            // setSelectedNodes(nodes);
            setKnowledgeDivisions(divisionIds);
            setKnowledgeDivisionDetails(divisionDetails);
        } else {
            setKnowledgeDivisions('');
            setKnowledgeDivisionDetails('');
        }
    }

    async function handleViewChildernClick(id) {
        try {
            setLoading(true);
            const oKParams = {
                LearningObjectInstanceID:id,
                LearningObjectProcessStatusTypeID:LOLifeCycleStatus.Enrolled,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeSessions(oKParams);
            navigate('/knowledgesessionlist/' + id, {state: {permissiontypeid:permissionTypeID, parentParams:oKParams, listType:KnowledgeListType.Instructor }});    
            setLoading(false);
        } catch (err){
            console.log('Error is getting session details - ' + err);
            setLoading(false);
        }
    }

    async function handleViewRecurringParameterClick(id) {
        setLoading(true);
        const assEditedRec = knowledgeinstance?.filter((assRec) => (assRec.LearningObjectInstanceID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setKnowledgeInstanceID(assEditedRec[0]?.LearningObjectInstanceID);
            setAdvertisementOnly((assEditedRec[0]?.AdvertisementOnly) ? '1' : '0');
            setAllowEnrollmentExtension((assEditedRec[0]?.AllowExtention) ? '1' : '0');
            setAllowReprintCertificate((assEditedRec[0]?.AllowReprintCertificate) ? '1' : '0');
            setTestTakeType((assEditedRec[0]?.AssessmentTakeType) ? '1' : '0');
            setAssignmentInstance((assEditedRec[0]?.AssignmentInstance) ? '1' : '0');
            setAuthorizeInstructorOnly((assEditedRec[0]?.AuthorizeByInstructorOnly) ? '1' : '0');
            setByPassDateTimeConflicts((assEditedRec[0]?.ByPassDateTimeConflicts) ? '1' : '0');
            setByPassNotification((assEditedRec[0]?.ByPassEmailNotification) ? '1' : '0');
            setByPassPrerequisites((assEditedRec[0]?.ByPassPrerequisiteChecking) ? '1' : '0');
            setByPassValidation((assEditedRec[0]?.ByPassValidationCheck) ? '1' : '0');
            setCancellationCharge(assEditedRec[0]?.CancellationCost);
            setCancellationCutOff((assEditedRec[0]?.CancellationCutOff) ? '1' : '0');
            setCancellationCutOffHours(assEditedRec[0]?.CancellationCutOffHours);
            setSignInOut((assEditedRec[0]?.ClockedInOut) ? '1' : '0');
            setByPassAuthorization((assEditedRec[0]?.DisableAuthorization) ? '1' : '0');
            setEmailTo(assEditedRec[0]?.EmailToTypeID);
            setEnableMultiSession((assEditedRec[0]?.EnableStudentMultiSessions) ? '1' : '0');
            setRegistrationCutOff((assEditedRec[0]?.EnrollmentCutOff) ? '1' : '0');
            setRegistrationCutOffHours(assEditedRec[0]?.EnrollmentCutOffHours);
            setEnrollmentExtensionCost(assEditedRec[0]?.ExtentionCost);
            setEnrollmentPeriod(assEditedRec[0]?.Duration);
            setEvaluationEndDays(assEditedRec[0]?.EvaluationEndDays);
            setEvaluationFromTranscript((assEditedRec[0]?.EvaluationFromTranscript) ? '1' : '0');
            setEvaluationStartDays(assEditedRec[0]?.EvaluationStartDays);
            setExpirationDate(assEditedRec[0]?.ExpiryDate);
            setExternalWebinarURL(assEditedRec[0]?.ExternalWebAttendeeURL);
            setFeatured((assEditedRec[0]?.Featured) ? '1' : '0');
            setForceEvaluation((assEditedRec[0]?.ForceEvaluation) ? '1' : '0');
            setForcePayment((assEditedRec[0]?.ForcePayment) ? '1' : '0');
            setForceROMS((assEditedRec[0]?.ForceROMS) ? '1' : '0');
            setGuestSpeakers(assEditedRec[0]?.GuestSpeakers);
            setByPassSessionCap((assEditedRec[0]?.IgnoreSessionCap) ? '1' : '0');
            // setInstructors(assEditedRec[0]?.Instructors);
            setInstanceDates(assEditedRec[0]?.InstanceDates);
            setLaunchDate(assEditedRec[0]?.LaunchDate);
            setProcessStatusTypeID(assEditedRec[0]?.LearningObjectProcessStatusTypeID);
            setMaximumStudent(assEditedRec[0]?.MaxStudents);
            setMinimumStudent(assEditedRec[0]?.MinStudents);
            setAllowMove((assEditedRec[0]?.MoveNoShows) ? '1' : '0');
            setNoShowCharge(assEditedRec[0]?.NoShowCost);
            setPreventTranscripLaunch((assEditedRec[0]?.NoTranscriptLaunch) ? '1' : '0');
            setOtherEmails(assEditedRec[0]?.OtherEmails);
            setPaymentModes(assEditedRec[0]?.PaymentModes);
            setPresenters(assEditedRec[0]?.Presenters);
            setPrerequisiteDuringLaunch((assEditedRec[0]?.PrerequisiteForLaunch) ? '1' : '0');
            setPrerequisiteExpression(assEditedRec[0]?.PrerequisitesExpression);
            setPreventRetake((assEditedRec[0]?.PreventRetake) ? '1' : '0');
            setPrivateNotes(assEditedRec[0]?.PrivateDescription);
            setPromotionPrice(assEditedRec[0]?.PromoPrice);
            setNotes(assEditedRec[0]?.PublicDescription);
            setReprintCertificationCost(assEditedRec[0]?.ReprintCertificateCost);
            setSubjectCode(assEditedRec[0]?.SubjectCode);
            setAutoTransfer((assEditedRec[0]?.WaitlistAutoTransfer) ? '1' : '0');
            setWaitlistCap(assEditedRec[0]?.WaitlistCap);
            setFacilities(assEditedRec[0]?.FacilityID);
            setFacilityRooms(assEditedRec[0]?.FacilityRoomID);
            setFacilityNames(assEditedRec[0]?.FullFacilityName);
            UpdateChildrenState(id);           
        }
        setShowRecurringParameters(true);
        setLoading(false);
    }

    async function handleUpdateChildernClick(id, mainid) {
        setLoading(true);
        setTabValue(1);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const assEditedRec = knowledgeinstance?.filter((assRec) => (assRec.LearningObjectInstanceID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setKnowledgeInstanceID(assEditedRec[0]?.LearningObjectInstanceID);
            setAdvertisementOnly((assEditedRec[0]?.AdvertisementOnly) ? '1' : '0');
            setAllowEnrollmentExtension((assEditedRec[0]?.AllowExtention) ? '1' : '0');
            setAllowReprintCertificate((assEditedRec[0]?.AllowReprintCertificate) ? '1' : '0');
            setTestTakeType((assEditedRec[0]?.AssessmentTakeType) ? '1' : '0');
            setAssignmentInstance((assEditedRec[0]?.AssignmentInstance) ? '1' : '0');
            setAuthorizeInstructorOnly((assEditedRec[0]?.AuthorizeByInstructorOnly) ? '1' : '0');
            setByPassDateTimeConflicts((assEditedRec[0]?.ByPassDateTimeConflicts) ? '1' : '0');
            setByPassNotification((assEditedRec[0]?.ByPassEmailNotification) ? '1' : '0');
            setByPassPrerequisites((assEditedRec[0]?.ByPassPrerequisiteChecking) ? '1' : '0');
            setByPassValidation((assEditedRec[0]?.ByPassValidationCheck) ? '1' : '0');
            setCancellationCharge(assEditedRec[0]?.CancellationCost);
            setCancellationCutOff((assEditedRec[0]?.CancellationCutOff) ? '1' : '0');
            setCancellationCutOffHours(assEditedRec[0]?.CancellationCutOffHours);
            setSignInOut((assEditedRec[0]?.ClockedInOut) ? '1' : '0');
            setByPassAuthorization((assEditedRec[0]?.DisableAuthorization) ? '1' : '0');
            setEmailTo(assEditedRec[0]?.EmailToTypeID);
            setEnableMultiSession((assEditedRec[0]?.EnableStudentMultiSessions) ? '1' : '0');
            setRegistrationCutOff((assEditedRec[0]?.EnrollmentCutOff) ? '1' : '0');
            setRegistrationCutOffHours(assEditedRec[0]?.EnrollmentCutOffHours);
            setEnrollmentExtensionCost(assEditedRec[0]?.ExtentionCost);
            setEnrollmentPeriod(assEditedRec[0]?.Duration);
            setEvaluationEndDays(assEditedRec[0]?.EvaluationEndDays);
            setEvaluationFromTranscript((assEditedRec[0]?.EvaluationFromTranscript) ? '1' : '0');
            setEvaluationStartDays(assEditedRec[0]?.EvaluationStartDays);
            setExpirationDate(assEditedRec[0]?.ExpiryDate);
            setExternalWebinarURL(assEditedRec[0]?.ExternalWebAttendeeURL);
            setFeatured((assEditedRec[0]?.Featured) ? '1' : '0');
            setForceEvaluation((assEditedRec[0]?.ForceEvaluation) ? '1' : '0');
            setForcePayment((assEditedRec[0]?.ForcePayment) ? '1' : '0');
            setForceROMS((assEditedRec[0]?.ForceROMS) ? '1' : '0');
            setGuestSpeakers(assEditedRec[0]?.GuestSpeakers);
            setByPassSessionCap((assEditedRec[0]?.IgnoreSessionCap) ? '1' : '0');
            // setInstructors(assEditedRec[0]?.Instructors);
            setInstanceDates(assEditedRec[0]?.InstanceDates);
            setLaunchDate(assEditedRec[0]?.LaunchDate);
            setProcessStatusTypeID(assEditedRec[0]?.LearningObjectProcessStatusTypeID);
            setMaximumStudent(assEditedRec[0]?.MaxStudents);
            setMinimumStudent(assEditedRec[0]?.MinStudents);
            setAllowMove((assEditedRec[0]?.MoveNoShows) ? '1' : '0');
            setNoShowCharge(assEditedRec[0]?.NoShowCost);
            setPreventTranscripLaunch((assEditedRec[0]?.NoTranscriptLaunch) ? '1' : '0');
            setOtherEmails(assEditedRec[0]?.OtherEmails);
            setPaymentModes(assEditedRec[0]?.PaymentModes);
            setPresenters(assEditedRec[0]?.Presenters);
            setPrerequisiteDuringLaunch((assEditedRec[0]?.PrerequisiteForLaunch) ? '1' : '0');
            setPrerequisiteExpression(assEditedRec[0]?.PrerequisitesExpression);
            setPreventRetake((assEditedRec[0]?.PreventRetake) ? '1' : '0');
            setPrivateNotes(assEditedRec[0]?.PrivateDescription);
            setPromotionPrice(assEditedRec[0]?.PromoPrice);
            setNotes(assEditedRec[0]?.PublicDescription);
            setReprintCertificationCost(assEditedRec[0]?.ReprintCertificateCost);
            setSubjectCode(assEditedRec[0]?.SubjectCode);
            setAutoTransfer((assEditedRec[0]?.WaitlistAutoTransfer) ? '1' : '0');
            setWaitlistCap(assEditedRec[0]?.WaitlistCap);
            setFacilities(assEditedRec[0]?.FacilityID);
            setFacilityRooms(assEditedRec[0]?.FacilityRoomID);
            setFacilityNames(assEditedRec[0]?.FullFacilityName);
            UpdateChildrenState(id);
           
            setEditKnowledgeInstance(true);
            setIsAttachmentSectionDisabled(true);
            setAddNewDate(false);
            setAddNewAuthorization(false);
            setAddNewResource(false);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(id) {
        if (knowledgeInstanceID === -1) {
            setAddNewKnowledgeInstance(false);
        } else {
            setEditKnowledgeInstance(false);
        }
        ClearStateFields();
    }
    
    async function ClearStateFields() {
        setKnowledgeInstanceID(-1);
        setAdvertisementOnly(null);
        setAllowEnrollmentExtension(null);
        setAllowReprintCertificate(null);
        setTestTakeType(null);
        setAssignmentInstance(null);
        setAuthorizeInstructorOnly(null);
        setByPassDateTimeConflicts(null);
        setByPassNotification(null);
        setByPassPrerequisites(null);
        setByPassValidation(null);
        setCancellationCharge(null);
        setCancellationCutOff(null);
        setCancellationCutOffHours(null);
        setSignInOut(null);
        setByPassAuthorization(null);
        setEmailTo(null);
        setEnableMultiSession(null);
        setRegistrationCutOff(null);
        setRegistrationCutOffHours(null);
        setEnrollmentExtensionCost(null);
        setEnrollmentPeriod(null);
        setEvaluationEndDays(null);
        setEvaluationFromTranscript(null);
        setEvaluationStartDays(null);
        setExpirationDate(null);
        setExternalWebinarURL(null);
        setFeatured(null);
        setForceEvaluation(null);
        setForcePayment(null);
        setForceROMS(null);
        setGuestSpeakers(null);
        setByPassSessionCap(null);
        setInstructors(null);
        setInstanceDates(null);
        setKnowledgeInstanceID(null);
        setLaunchDate(null);
        setProcessStatusTypeID(null);
        setMaximumStudent(null);
        setMinimumStudent(null);
        setAllowMove(null);
        setNoShowCharge(null);
        setPreventTranscripLaunch(null);
        setOtherEmails(null);
        setPaymentModes(null);
        setPresenters(null);
        setPrerequisiteDuringLaunch(null);
        setPrerequisiteExpression(null);
        setPreventRetake(null);
        setPrivateNotes(null);
        setPromotionPrice(null);
        setNotes(null);
        setReprintCertificationCost(null);
        setSubjectCode(null);
        setAutoTransfer(null);
        setWaitlistCap(null);        
        setInstanceDates('');
        setInstanceDateDetails('');
        setInstanceDateArray([]);
        setInstructors('');
        setInstructorDetails('');
        setInstructorArray([]);
        setPresenters('');
        setPresenterDetails('');
        setPresenterArray([]);
        setJobPositions('');
        setJobPositionDetails('');
        setJobPositionArray([]);
        setAuthorizationGroup('');
        setAuthorizationGroupName('');
        setAuthorizationLevel(null);                
        setAuthorizationNotificationMessages(null);                
        setAuthorizationNotificationMessageNames(null);                
        setAuthorizationNotificationMessageDetails('');                
        setAuthorizationNotificationMessageArray([]);                
        setAuthorizationDetails('');
        setAuthorizationArray([]);
        setResourceTypeID(null);
        setResourceTypeName(null);
        setResourceNumberOfUnits(null);
        setResourceTotalPrice(null);
        setResourceNumberOfUnitsAllocated(null);
        setResourceDueDate(null);
        setResourceCompletedDate(null);
        setResourceDetails('');
        setResourceArray([]);
        setFacilities(null);                
        setFacilityRooms(null);                
        setFacilityNames(null);                
    }

    async function handleAddSubChildrenClick(tab){
        if (tab === 1) {
            if ((instanceStartDate) && (instanceStartDate.trim() !== '') && (instanceEndDate) && (instanceEndDate.trim() !== '') &&
                (instanceStartTime) && (instanceStartTime.trim() !== '') && (instanceEndTime) && (instanceEndTime.trim() !== '') && 
                (instanceStartAMPM) && (instanceStartAMPM.trim() !== '') && (instanceEndAMPM) && (instanceStartAMPM.trim() !== '') && 
                (instanceTimeZone) && (instanceTimeZone.trim() !== '')) {
                const oStartDate = new Date(instanceStartDate + ' ' + instanceStartTime + ' ' + instanceStartAMPM);
                const oEndDate = new Date(instanceEndDate + ' ' + instanceEndTime + ' ' + instanceEndAMPM);
                var sSelections =  moment(oStartDate).format('"MM/DD/YYYY hh:mm a"') + '|' + moment(oEndDate).format('"MM/DD/YYYY hh:mm a"') + '|' + instanceTimeZone;
                if ((instanceDateDetails) && (instanceDateDetails?.trim() !== '')) {
                    sSelections = instanceDateDetails + '&' + sSelections;
                }
                console.log(sSelections);
                const oSelections = sSelections.split('&');
                setInstanceDateArray(oSelections);
                setInstanceDateDetails(sSelections);
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 3) {
            if ((authorizationGroup) && (authorizationGroup.trim() !== '') && (authorizationLevel) && (authorizationLevel.trim() !== '')) {
                var sSelections =  authorizationGroup + '|' + authorizationGroupName + '|' + authorizationLevel + '|' + authorizationNotificationMessages + '|' + authorizationNotificationMessageNames;
                if ((authorizationDetails) && (authorizationDetails?.trim() !== '')) {
                    sSelections = authorizationDetails + '&' + sSelections;
                }
                console.log(sSelections);
                const oSelections = sSelections.split('&');
                setAuthorizationArray(oSelections);
                setAuthorizationDetails(sSelections);
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        }  else if (tab === 4) {
            if ((resourceTypeID) && (resourceTypeID.trim() !== '')) {
                var sSelections =  resourceTypeID + '|' + resourceTypeName + '|' + resourceNumberOfUnits + '|' + resourceTotalPrice + '|' + resourceNumberOfUnitsAllocated + '|' + 
                moment(resourceDueDate).format("MM/DD/YYYY hh:mm a") + '|' + moment(resourceCompletedDate).format("MM/DD/YYYY hh:mm a");
                if ((resourceDetails) && (resourceDetails?.trim() !== '')) {
                    sSelections = resourceDetails + '&' + sSelections;
                }
                console.log(sSelections);
                const oSelections = sSelections.split('&');
                setResourceArray(oSelections);
                setResourceDetails(sSelections);
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } 
    }

    async function handleDeleteSubChildren(tab, element) {
        setLoading(true);
        if (tab === 1) {
            var tempSkills = instanceDateArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setInstanceDateArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setInstanceDateDetails(sSelectedDetails);
            }            
        } else if (tab === 3) {
            var tempSkills = authorizationArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setAuthorizationArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setAuthorizationDetails(sSelectedDetails);
            }            
        } else if (tab === 4) {
            var tempSkills = resourceArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setResourceArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setResourceDetails(sSelectedDetails);
            }            
        } 
        setLoading(false);
    }    

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Knowledge Instance'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Knowledge Instance'>Cancel</Button>
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radioKnowledege.map((radio, idx) => (
                ((radio.value === 3) && (addNewKnowledgeInstance)) ? 
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer}  title= 'Only available in edit mode!' onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
                :
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        }
        else if (tabValue === 2 ) {
            return (
                <div>
                    {displayRegistration()}
                </div>
            );
        } else if (tabValue === 3 ) {
            return (
                <div>
                    {displayAuthorization()}
                </div>
            );
        } else if (tabValue === 4 ) {
            return (
                <div>
                    {displayAttachment()}
                </div>
            );
        } else if (tabValue === 5 ) {
            return (
                <div>
                    {displayAdvanced()}
                </div>
            );
        } else if (tabValue === 6 ) {
            return (
                <div>
                    {displayOrganization()}
                </div>
            );
        } 
    }

    function displayRecurringParameters() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <table class="table table-sm caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="frequency" style={styles.LabelDataEntryNoPadding}>Frequency</label>
                                <select id="frequency" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={frequency} onChange={(e) => (setFrequency(e.target.value))}>
                                    <option value={RRule.DAILY}>Daily</option>
                                    <option value={RRule.WEEKLY}>Weekly</option>
                                    <option value={RRule.MONTHLY}>Monthly</option>
                                    <option value={RRule.YEARLY}>Yearly</option>
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="repeatsevery" style={styles.LabelDataEntryNoPadding}>Repeats Every</label>
                                <input id="repeatsevery" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={interval} onChange={(e) => (setInterval(e.target.value))}/>
                            </td>
                        </tr>
                        {(parseInt(frequency) === RRule.WEEKLY) && (
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'left', backgroundColor: companyData?.PagesBGColor }}>
                                    <label htmlFor="repeaton" style={styles.LabelDataEntryNoPadding}>Repeats On</label>
                                    <div className="d-flex justify-content-evenly flex-wrap">
                                        {weekdays.map((day) => (
                                            <div key={day.label} className="d-flex align-items-center">
                                                <input 
                                                    type="checkbox" 
                                                    id={day.value}
                                                    style={{ position:'relative', opacity: '1', width: '16px', height: '16px', marginRight: '5px', pointerEvents: 'auto' }}                                                   
                                                    checked={daysOfWeek.includes(day.value)} 
                                                    onChange={() => toggleDay(day.value)}
                                                />
                                                <label className="form-check-label" htmlFor={day.value} style={styles.TextDataEntryNoPadding}>
                                                    {day.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="rStartDate" style={styles.LabelDataEntryNoPadding}>Start Date</label>
                                <input id="rStartDate" type="date" style={styles.TextDataEntryNoPadding} value={startDate} onChange={(e) => (setStartDate(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="renddate" style={styles.LabelDataEntryNoPadding}>End Date</label>
                                <input id="renddate" type="date" style={styles.TextDataEntryNoPadding} value={endDate} onChange={(e) => (setEndDate(e.target.value))}/>
                            </td>
                        </tr>       
                        <tr>
                            <td colSpan='2' style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}>
                                <Button onClick={() => generateEvents()} disabled={loading} style={styles.CDPillButtonSMGrey} title='Generate Instances'>Generate Instances</Button>&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
                </CardBody>
            </Card>


        );
    }

    function displayNewKnowledge() {
        if ((addNewKnowledgeInstance) || (editKnowledgeInstance)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewKnowledgeInstance) ? 'New Knowledge Track Instance' : 'Edit Knowledge Track Instance'}                        
                        </Card.Title>
                        <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayTabButtons()}
                                </td>
                            </tr>
                            </tbody>
                        </Table>    
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                {displaySaveButtons()}
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayAssignedInstructors() {
        if (instructorArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {instructorArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedInstructorClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Instructor'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedPresenters() {
        if (presenterArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {presenterArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedPresenterClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Presenter'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedJobPositions() {
        if ((jobPositionArray != undefined) && (jobPositionArray != null)) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {jobPositionArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedJobPositionClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete JobPosition'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayNewInstanceDates() {
        if ((addNewKnowledgeInstance) || (editKnowledgeInstance)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            <table class="table table-sm text-left">  
                                <tbody>
                                    <tr>
                                        <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                            Dates
                                        </td>
                                        {(permissionFlags.CanCreate) ? 
                                            (addNewDate) ? 
                                            <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                                <a onClick={() => handleAddChildernClick(2, null)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Date'>shadow_minus</span>
                                                </a>
                                            </td>
                                            :
                                            <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                                <a onClick={() => handleAddChildernClick(2, null)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Date'>note_add</span>
                                                </a>
                                            </td>
                                        : 
                                            <></>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {(addNewDate) ? 
                            <table class="table table-sm caption-top text-left">
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="startdate" style={styles.LabelDataEntryNoPaddingWBG}>Start Date</label>
                                    <input id="startdate" type="date" style={styles.TextDataEntryNoPadding} value={instanceStartDate} onChange={(e) => (setInstanceStartDate(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'55px', backgroundColor:companyData?.PagesBGColor}}>
                                    <input id="starttime" type="text" style={styles.TextDataEntryNoPadding} value={instanceStartTime} onChange={(e) => (setInstanceStartTime(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'75px', backgroundColor:companyData?.PagesBGColor}}>
                                    <select id="startampm" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={instanceStartAMPM} onChange={(e) => (setInstanceStartAMPM(e.target.value))}>
                                        <option value='AM'>AM</option>
                                        <option value='PM'>PM</option>
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="enddate" style={styles.LabelDataEntryNoPaddingWBG}>End Date</label>
                                    <input id="enddate" type="date" style={styles.TextDataEntryNoPadding} value={instanceEndDate} onChange={(e) => (setInstanceEndDate(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'55px', backgroundColor:companyData?.PagesBGColor}}>
                                    <input id="endtime" type="text" style={styles.TextDataEntryNoPadding} value={instanceEndTime} onChange={(e) => (setInstanceEndTime(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'75px', backgroundColor:companyData?.PagesBGColor}}>
                                    <select id="endampm" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={instanceEndAMPM} onChange={(e) => (setInstanceEndAMPM(e.target.value))}>
                                        <option value='AM'>AM</option>
                                        <option value='PM'>PM</option>
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="enddate" style={styles.LabelDataEntryNoPaddingWBG}>Time Zone</label>
                                    <select id="timezone" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={instanceTimeZone} onChange={(e) => (setInstanceTimeZone(e.target.value))}>
                                        <option value='-1'>Select One</option>
                                        <option value='EST'>Eastern Time</option>
                                        <option value='CST'>Central Time</option>
                                        <option value='MST'>Mountain Time</option>
                                        <option value='PST'>Pacific Time</option>
                                        <option value='Alaska'>Alaska Time</option>
                                        <option value='Hawaii'>Hawaii Time</option>
                                        <option value='Indiana East'>Indiana(East) Time</option>
                                        <option value='Arizona'>Arizona Time</option>
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={styles.LabelDataEntryNoPaddingWBG}>
                                        <label style={styles.LabelDataEntryNoPaddingWBG}>Add</label>
                                    </div>
                                    <div style={styles.LabelDataEntryNoPaddingWBG}>
                                        <a onClick={() => handleAddSubChildrenClick(1)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Date'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </table>
                        :
                            <></>
                        }
                        {((instanceDateArray) && (instanceDateArray.length > 0)) ? 
                            <table class="table table-sm caption-top text-left">  
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableTextMain}>Start Date</th>
                                        <th style={styles.tableText}>End Date</th>
                                        <th style={styles.tableText}>Time Zone</th>
                                        <th style={styles.tableText}>Delete</th>
                                    </tr>
                                </thead>                            
                                <tbody>
                                    {(instanceDateArray && instanceDateArray.map((oItem, index) => {
                                        return (<tr>
                                            <td style={styles.tableText}>{index+1}</td>
                                            <td style={styles.tableText}>{moment(oItem.split('|')[0]).format("MM/DD/YYYY hh:mm a")}</td>
                                            <td style={styles.tableText}>{moment(oItem.split('|')[1]).format("MM/DD/YYYY hh:mm a")}</td>
                                            <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                            <td style={styles.tableText}>
                                                <a onClick={() => handleDeleteSubChildren(1, oItem)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Date'>delete</span>
                                                </a>
                                            </td>
                                        </tr>)
                                    }))
                                    }
                                </tbody>
                            </table>
                        :
                            <></>
                        }                        
                    </CardBody>
                </Card>
            )
        }
    }

    function displayAuthorization() {
        return (
            <>
            {displayAuthorizationGroup()}
            </>
        )
    }

    function displayAuthorizationGroup() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                        <table class="table table-sm text-left">  
                            <tbody>
                                <tr>
                                    <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                        Authorization Groups
                                    </td>
                                    {(permissionFlags.CanCreate) ? 
                                        (addNewAuthorization) ? 
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(3, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Authorization'>shadow_minus</span>
                                            </a>
                                        </td>
                                        :
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(3, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Authorization'>note_add</span>
                                            </a>
                                        </td>
                                    : 
                                        <></>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </Card.Title>
                </CardHeader>                                        
                <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {(addNewAuthorization) ? 
                        <table class="table table-sm caption-top text-left">
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="agroups" style={styles.LabelDataEntryNoPaddingWBGRequired}>Group</label>
                                    <select id="agroups" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100WBG} value={authorizationGroup} onChange={(e) => (updateSubChildrenSelect(2, e.target.selectedOptions))}>
                                        <option value='-1'>Select One</option>
                                        {groupTypeData?.map((oItem, iIndex) => (
                                            <option id={oItem?.GroupID} value={oItem?.GroupID}>{oItem.GroupName}</option>        
                                        ))}
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="enddate" style={styles.LabelDataEntryNoPaddingWBGRequired}>Level</label>
                                    <input id="enddate" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={authorizationLevel} onChange={(e) => (setAuthorizationLevel(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => setShowNotificationMessageList(true)}>
                                        <label style={styles.LabelDataEntryNoPaddingWBG}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Notification Message List'>mystery</span>                                    
                                            Notifications
                                        </label>
                                    </a>
                                    <input id="notimsg" type="text" required disabled style={styles.TextDataEntryNoPadding} value={authorizationNotificationMessageNames}/>                                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={styles.LabelDataEntryNoPaddingWBG}>
                                        <label style={styles.LabelDataEntryNoPaddingWBG}>Add</label>
                                    </div>
                                    <div style={styles.LabelDataEntryNoPaddingWBG}>
                                        <a onClick={() => handleAddSubChildrenClick(3)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Authorization Group'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </table>
                    :
                        <></>
                    }
                    {((authorizationArray) && (authorizationArray.length > 0)) ? 
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextMain}>Group</th>
                                    <th style={styles.tableText}>Level</th>
                                    <th style={styles.tableText}>Notification</th>
                                    <th style={styles.tableText}>Delete</th>
                                </tr>
                            </thead>                            
                            <tbody>
                                {(authorizationArray && authorizationArray.map((oItem, index) => {
                                    return (<tr>
                                        <td style={styles.tableText}>{index+1}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[4]}</td>
                                        <td style={styles.tableText}>
                                            <a onClick={() => handleDeleteSubChildren(3, oItem)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Authorization Group'>delete</span>
                                            </a>
                                        </td>
                                    </tr>)
                                }))
                                }
                            </tbody>
                        </table>
                    :
                        <></>
                    }                        
                </CardBody>
            </Card>
        )
    }

    function displayNewInstanceResources() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                        <table class="table table-sm text-left">  
                            <tbody>
                                <tr>
                                    <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                        Resources
                                    </td>
                                    {(permissionFlags.CanCreate) ? 
                                        (addNewResource) ? 
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(4, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Resource'>shadow_minus</span>
                                            </a>
                                        </td>
                                        :
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(4, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Resource'>note_add</span>
                                            </a>
                                        </td>
                                    : 
                                        <></>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </Card.Title>
                </CardHeader>                                        
                <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {(addNewResource) ? 
                        <table class="table table-sm caption-top text-left">
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="rtypeid" style={styles.LabelDataEntryNoPaddingWBG}>Resource Type</label>
                                <select id="rtypeid" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={resourceTypeID} onChange={(e) => (updateSubChildrenSelect(3, e.target.selectedOptions))}>
                                    <option value='-1'>Select One</option>
                                        {resourceTypeData?.map((oItem, iIndex) => (
                                            <option id={oItem?.ResourceTypeID} value={oItem?.ResourceTypeID}>{oItem.ResourceTypeName}</option>        
                                        ))}
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="numunits" style={styles.LabelDataEntryNoPaddingWBG}>Units</label>
                                <input id="numunits" type="number" step='1' min='0' style={styles.TextDataEntryNoPadding} value={resourceNumberOfUnits} onChange={(e) => (setResourceNumberOfUnits(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="numunits" style={styles.LabelDataEntryNoPaddingWBG}>Units Allocated</label>
                                <input id="numunits" type="number" step='1' min='0' style={styles.TextDataEntryNoPadding} value={resourceNumberOfUnitsAllocated} onChange={(e) => (setResourceNumberOfUnitsAllocated(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="rtotalprice" style={styles.LabelDataEntryNoPaddingWBG}>Total Price</label>
                                <input id="rtotalprice" type="number" step='1' min='0' style={styles.TextDataEntryNoPadding} value={resourceTotalPrice} onChange={(e) => (setResourceTotalPrice(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="rduedate" style={styles.LabelDataEntryNoPaddingWBG}>Due Date</label>
                                <input id="rduedate" type="date" style={styles.TextDataEntryNoPadding} value={resourceDueDate} onChange={(e) => (setResourceDueDate(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="rcompleteddate" style={styles.LabelDataEntryNoPaddingWBG}>Completed Date</label>
                                <input id="rcompleteddate" type="date" style={styles.TextDataEntryNoPadding} value={resourceCompletedDate} onChange={(e) => (setResourceCompletedDate(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div style={styles.LabelDataEntryNoPaddingWBG}>
                                    <label style={styles.LabelDataEntryNoPaddingWBG}>Add</label>
                                </div>
                                <div style={styles.LabelDataEntryNoPaddingWBG}>
                                    <a onClick={() => handleAddSubChildrenClick(4)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Resource'>add_circle</span> 
                                    </a> 
                                </div>
                            </td>
                        </tr>
                    </table>
                    :
                        <></>
                    }
                    {((resourceArray) && (resourceArray.length > 0)) ? 
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextMain}>Resource</th>
                                    <th style={styles.tableText}>Units</th>
                                    <th style={styles.tableText}>Price</th>
                                    <th style={styles.tableText}>Delete</th>
                                </tr>
                            </thead>                            
                            <tbody>
                                {(resourceArray && resourceArray.map((oItem, index) => {
                                    return (<tr>
                                        <td style={styles.tableText}>{index+1}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[3].toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                    })}</td>
                                        <td style={styles.tableText}>
                                            <a onClick={() => handleDeleteSubChildren(4, oItem)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Resource'>delete</span>
                                            </a>
                                        </td>
                                    </tr>)
                                }))
                                }
                            </tbody>
                        </table>
                    :
                        <></>
                    }                        
                </CardBody>
            </Card>
        )
    }
    
    function displayAttributes() {
        return (
            <>
                {displayNewInstanceDates()}
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            Instance Logistics
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table table-sm caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="loistatus" style={styles.LabelDataEntryNoPaddingRequired}>Status</label>
                                    <select id="loistatus" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={processStatusTypeID} onChange={(e) => (setProcessStatusTypeID(e.target.value))}>
                                        <option value='-1'>Select One</option>
                                        <option value='1'>Pending</option>
                                        <option value='9'>Scheduled</option>
                                        <option value='4'>Complete</option>
                                        <option value='5'>Cancel</option>
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowFacilityList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Facility Room List'>mystery</span>                                    
                                                Facility Room
                                            </label>
                                        </a>
                                        <input id="facroom" type="text" required disabled style={styles.TextDataEntryNoPadding} value={facilityNames}/>                                    
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowInstructorList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Instructor List'>mystery</span>                                    
                                                Instructors
                                            </label>
                                        </a>
                                        {displayAssignedInstructors()}
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowPresenterList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Presenter List'>mystery</span>                                    
                                                Presenters
                                            </label>
                                        </a>
                                        {displayAssignedPresenters()}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="extweburl" style={styles.LabelDataEntryNoPadding}>External Webinar URL</label>
                                    <input id="extweburl" type="text" style={styles.TextDataEntryNoPadding} value={externalWebinarURL} onChange={(e) => (setExternalWebinarURL(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="guestspeakers" style={styles.LabelDataEntryNoPaddingWBG}>Guest Speakers</label>
                                    <input id="guestspeakers" type="text" style={styles.TextDataEntryNoPadding} value={guestSpeakers} onChange={(e) => (setGuestSpeakers(e.target.value))}/>
                                </td>
                            </tr>       
                        </tbody>
                    </table>                                   
                    </CardBody>
                </Card>
            </>
        );
    }

    function displayRegistration() {
        return (
            <>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            Launch Parameters
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table table-sm text-left">  
                            <tbody> 
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="launchdate" style={styles.LabelDataEntryNoPadding}>Launch Date</label>
                                        <input id="launchdate" type="date" style={styles.TextDataEntryNoPadding} value={launchDate?.substr(0,10)} onChange={(e) => (setLaunchDate(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="expirydate" style={styles.LabelDataEntryNoPadding}>Expiration Date</label>
                                        <input id="expirydate" type="date" style={styles.TextDataEntryNoPadding} value={expirationDate?.substr(0,10)} onChange={(e) => (setExpirationDate(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="enrollperiod" style={styles.LabelDataEntryNoPadding}>Enrollment Period(Days)</label>
                                        <input id="enrollperiod" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={enrollmentPeriod} onChange={(e) => (setEnrollmentPeriod(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="maxstu" style={styles.LabelDataEntryNoPadding}>Maximum Students</label>
                                        <input id="maxstud" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={maximumStudent} onChange={(e) => (setMaximumStudent(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="minstu" style={styles.LabelDataEntryNoPadding}>Minimum Students</label>
                                        <input id="minstud" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={minimumStudent} onChange={(e) => (setMinimumStudent(e.target.value))}/>
                                    </td>
                                </tr>
                             </tbody>
                        </table>
                    </CardBody>
                </Card>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            Enrollment Process Parameters
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table table-sm text-left">  
                            <tbody> 
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="includew" style={styles.LabelDataEntryNoPadding}>Include Waitlist</label>
                                        <select id="includew" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={includeWaitlist} onChange={(e) => (setIncludeWaitlist(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="autot" style={styles.LabelDataEntryNoPadding}>Auto Transfer</label>
                                        <select id="autot" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={autoTransfer} onChange={(e) => (setAutoTransfer(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="waitlistcap" style={styles.LabelDataEntryNoPadding}>Waitlist Cap</label>
                                        <input id="waitlistcap" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={waitlistCap} onChange={(e) => (setWaitlistCap(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="emailto" style={styles.LabelDataEntryNoPadding}>Email To</label>
                                        <select id="emailto" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={emailTo} onChange={(e) => (setEmailTo(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>User Only(Default)</option>        
                                            <option value='2'>Manager Only</option>        
                                            <option value='4'>User and Manager</option>        
                                            <option value='10'>User, Manager and Others</option>        
                                            <option value='9'>Others Only</option>        
                                            <option value='8'>User and Others</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="oemails" style={styles.LabelDataEntryNoPadding}>Other Emails</label>
                                        <input id="oemails" type="email" style={styles.TextDataEntryNoPadding} value={otherEmails} onChange={(e) => (setOtherEmails(e.target.value))}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="forceeval" style={styles.LabelDataEntryNoPadding}>Force Evaluation</label>
                                        <select id="forceeval" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={forceEvaluation} onChange={(e) => (setForceEvaluation(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="evalstartdays" style={styles.LabelDataEntryNoPadding}>Evaluation Start Days</label>
                                        <input id="evalstartdays" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={evaluationStartDays} onChange={(e) => (setEvaluationStartDays(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="evalenddays" style={styles.LabelDataEntryNoPadding}>Evaluation End Days</label>
                                        <input id="evalenddays" type="number" step='1' min='1'  style={styles.TextDataEntryNoPadding} value={evaluationEndDays} onChange={(e) => (setEvaluationEndDays(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="forceroms" style={styles.LabelDataEntryNoPadding}>Force Objectives</label>
                                        <select id="forceroms" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={forceROMS} onChange={(e) => (setForceROMS(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="evalfromtran" style={styles.LabelDataEntryNoPadding}>Evaluation from Transcript</label>
                                        <select id="evalfromtran" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={evaluationFromTranscript} onChange={(e) => (setEvaluationFromTranscript(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="signinouttype" style={styles.LabelDataEntryNoPadding}>Sign In/Out</label>
                                            <select id="signinouttype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={signInOut} onChange={(e) => (setSignInOut(e.target.value))}>
                                                <option value='-1'>Select One</option>
                                                <option value='1'>Sign In/Out</option>
                                                <option value='2'>Sign In</option>
                                                <option value='3'>Sign Out</option>
                                                <option value='4'>Sign In/Out Multiple</option>
                                            </select>                                     
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="regcutoff" style={styles.LabelDataEntryNoPadding}>Registration CutOff</label>
                                            <select id="regcutoff" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={registrationCutOff} onChange={(e) => (setRegistrationCutOff(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                            </select>                                     
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="regcutoffhours" style={styles.LabelDataEntryNoPadding}>Registration CutOff Hours</label>
                                            <input id="regcutoffhours" type="number" min='1' style={styles.TextDataEntryNoPadding} value={registrationCutOffHours} onChange={(e) => (setRegistrationCutOffHours(e.target.value))}/>
                                        </div>                    
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="cancelcutoff" style={styles.LabelDataEntryNoPadding}>Cancellation CutOff</label>
                                        <select id="cancelcutoff" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={cancellationCutOff} onChange={(e) => (setCancellationCutOff(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="cancutoffhours" style={styles.LabelDataEntryNoPadding}>Cancellation CutOff Hours</label>
                                            <input id="cancutoffhours" type="number" min='1' style={styles.TextDataEntryNoPadding} value={cancellationCutOffHours} onChange={(e) => (setCancellationCutOffHours(e.target.value))}/>
                                        </div>                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                            Validation Parameters
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table table-sm text-left">  
                            <tbody> 
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypasscap" style={styles.LabelDataEntryNoPadding}>Bypass Session Cap</label>
                                        <select id="bypasscap" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={byPassSessionCap} onChange={(e) => (setByPassSessionCap(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypassval" style={styles.LabelDataEntryNoPadding}>Bypass Validation</label>
                                        <select id="bypassval" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={byPassValidation} onChange={(e) => (setByPassValidation(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="enablems" style={styles.LabelDataEntryNoPadding}>Enable Multi-Session</label>
                                        <select id="enablems" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={enableMultiSession} onChange={(e) => (setEnableMultiSession(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="preventretake" style={styles.LabelDataEntryNoPadding}>Prevent Retake</label>
                                        <select id="preventretake" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={preventRetake} onChange={(e) => (setPreventRetake(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypassdtcon" style={styles.LabelDataEntryNoPadding}>Bypass Datetime Conflicts</label>
                                        <select id="bypassdtcon" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={byPassDateTimeConflicts} onChange={(e) => (setByPassDateTimeConflicts(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypassauth" style={styles.LabelDataEntryNoPadding}>ByPass Authorization</label>
                                        <select id="bypassauth" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={byPassAuthorization} onChange={(e) => (setByPassAuthorization(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="instauth" style={styles.LabelDataEntryNoPadding}>Instructor Authorization Only</label>
                                        <select id="instauth" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={authorizeInstructorOnly} onChange={(e) => (setAuthorizeInstructorOnly(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypassnot" style={styles.LabelDataEntryNoPadding}>Bypass Notifications</label>
                                        <select id="bypassnot" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={byPassNotification} onChange={(e) => (setByPassNotification(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypasspreq" style={styles.LabelDataEntryNoPadding}>ByPass Prerequisites</label>
                                        <select id="bypasspreq" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={byPassPrerequisites} onChange={(e) => (setByPassPrerequisites(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="preqcheckexp" style={styles.LabelDataEntryNoPadding}>Prerequisite Check Expression</label>
                                        <input id="preqcheckexp" type="text" style={styles.TextDataEntryNoPadding} value={prerequisiteExpression} onChange={(e) => (setPrerequisiteExpression(e.target.value))}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
                {displayNewInstanceResources()}
            </>
        );
}

function displayAttachment() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <Attachment isAttachmentVisible={isAttachmentSectionVisible} isAttachmentDisable={isAttachmentSectionDisabled} iAttachmentTypeID={AttachmentType.Course} iEntityRowTypeID={EntityRowType.KnowledgeInstance} iEntityID={knowledgeInstanceID}/>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayAdvanced() {
    return (
        <>
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                    Process Parameters
                </Card.Title>
            </CardHeader>                                        
            <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="allowreprintcert" style={styles.LabelDataEntryNoPadding}>Allow Reprint Certificate</label>
                                <select id="allowreprintcert" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={allowReprintCertificate} onChange={(e) => (setAllowReprintCertificate(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="reprintcost" style={styles.LabelDataEntryNoPadding}>Reprint Cost</label>
                                <input id="reprintcost" type="number" min='1' style={styles.TextDataEntryNoPadding} value={reprintCertificationCost} onChange={(e) => (setReprintCertificationCost(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="allowext" style={styles.LabelDataEntryNoPadding}>Allow Enrollment Extension</label>
                                <select id="allowext" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={allowEnrollmentExtension} onChange={(e) => (setAllowEnrollmentExtension(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="extcost" style={styles.LabelDataEntryNoPadding}>Enrollment Extension Cost</label>
                                <input id="extcost" type="number" min='1' style={styles.TextDataEntryNoPadding} value={enrollmentExtensionCost} onChange={(e) => (setEnrollmentExtensionCost(e.target.value))}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="allowmove" style={styles.LabelDataEntryNoPadding}>Allow Move(No Show)</label>
                                <select id="allowmove" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={allowMove} onChange={(e) => (setAllowMove(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="noshowcharge" style={styles.LabelDataEntryNoPadding}>No Show Charge</label>
                                <input id="noshowcharge" type="number" min='1' style={styles.TextDataEntryNoPadding} value={noShowCharge} onChange={(e) => (setNoShowCharge(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="cancharge" style={styles.LabelDataEntryNoPadding}>Cancellation Charge</label>
                                <input id="cancharge" type="number" min='1' style={styles.TextDataEntryNoPadding} value={cancellationCharge} onChange={(e) => (setCancellationCharge(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="promoprice" style={styles.LabelDataEntryNoPadding}>Promotion Price</label>
                                <input id="promoprice" type="number" style={styles.TextDataEntryNoPadding} value={promotionPrice} onChange={(e) => (setPromotionPrice(e.target.value))}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="forcepayment" style={styles.LabelDataEntryNoPadding}>Force Payment</label>
                                <select id="forcepayment" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={forcePayment} onChange={(e) => (setForcePayment(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="prereql" style={styles.LabelDataEntryNoPadding}>Check prerequisite at launch</label>
                                <select id="prereql" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={prerequisiteDuringLaunch} onChange={(e) => (setPrerequisiteDuringLaunch(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="testtaketype" style={styles.LabelDataEntryNoPadding}>Test Take Type</label>
                                <select id="testtaketype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testTakeType} onChange={(e) => (setTestTakeType(e.target.value))}>
                                    <option value='-1'>Select One</option>
                                    <option value='0'>Start Time</option>
                                    <option value='1'>End Time</option>
                                    <option value='2'>Any Time</option>
                                    <option value='3'>Between Start and End Time</option>
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="subjectcode" style={styles.LabelDataEntryNoPadding}>Subject Code</label>
                                <input id="subjectcode" type="text" style={styles.TextDataEntryNoPadding} value={subjectCode} onChange={(e) => (setSubjectCode(e.target.value))}/>
                            </td>
                        </tr>
                        <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="adsonly" style={styles.LabelDataEntryNoPadding}>Advertisement Only</label>
                                    <select id="adsonly" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={advertisementOnly} onChange={(e) => (setAdvertisementOnly(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="featured" style={styles.LabelDataEntryNoPadding}>Featured</label>
                                    <select id="featured" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={featured} onChange={(e) => (setFeatured(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="internalins" style={styles.LabelDataEntryNoPadding}>Internal Instance</label>
                                    <select id="internalins" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={assignmentInstance} onChange={(e) => (setAssignmentInstance(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="preventtlaunch" style={styles.LabelDataEntryNoPadding}>Prevent Transcript Launch</label>
                                    <select id="preventtlaunch" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={preventTranscripLaunch} onChange={(e) => (setPreventTranscripLaunch(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                            </tr>
                    </tbody>
                </table>
            </CardBody>
        </Card>
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                    Notes
                </Card.Title>
            </CardHeader>                                        
            <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="notes" style={styles.LabelDataEntryNoPadding}>Public</label>
                                <FormControl as='textarea' rows={5} id="notes" style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} value={notes} onChange={(e) => (setNotes(e.target.value))}/>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="pnotes" style={styles.LabelDataEntryNoPadding}>Private</label>
                                <FormControl as='textarea' rows={5} id="pnotes" style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} value={privateNotes} onChange={(e) => (setPrivateNotes(e.target.value))}/>
                            </td>
                        </tr>                
                    </tbody>
                </table>
            </CardBody>
        </Card>
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                    Selectors
                </Card.Title>
            </CardHeader>                                        
            <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                                <label htmlFor="paymodes" style={styles.LabelDataEntryNoPadding}>Payment Modes</label>
                                <select id="paymodes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={paymentModes} onChange={(e) => (handleCategoriesClick(e, 1))}>
                                    {paymentModeTypeData?.map((oItem, iIndex) => (
                                        <option id={oItem?.PaymentModeID} value={oItem?.PaymentModeID}>{oItem.PaymentModeName}</option>        
                                    ))}
                                </select>                                     
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => setShowJPList(true)}>
                                        <label style={styles.LabelDataEntryNoPadding}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Job Position List'>mystery</span>                                    
                                            Job Positions
                                        </label>
                                    </a>
                                    {displayAssignedJobPositions()}
                                </div>
                            </td>
                        </tr>                
                    </tbody>
                </table>
            </CardBody>
        </Card>
        </>
    );
}

function displayOrganization() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                <Card.Title>
                                    <div>
                                        <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h4 style={styles.SubHeader}>Organization Tree</h4></td>
                                                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                    <div className='input-field'>
                                                        <input id="searchDivisionNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchDivisionNodeName} placeholder='Search Division'/>
                                                    </div>                                            
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>                            
                                    </div>
                                </Card.Title>
                            </Card.Header>                   
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                <Tree 
                                                    width='100%'
                                                    data={orgDivisions} 
                                                    openByDefault={false}
                                                    indent={40}
                                                    padding={10}
                                                    searchTerm={divisionNodeName}
                                                    searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                    onSelect={(nodes) => {treeSelectedNodes(nodes)}}         
                                                    disableEdit={false}                                    
                                                    disableDrag={false}                                    
                                                    disableDrop={false}                                    
                                                    disableMultiSelection={false}              
                                                    selection={knowledgeDivisions}                      
                                                >
                                                </Tree>
                                            </td>
                                            <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    {displaySelectedDivisions()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>                            
                            </Card.Body>
                            <Card.Footer className="text-left">
                            </Card.Footer>
                        </Card>
                    </td>
                </tr>
            </tbody>
        </table>                                   
    );
}

function displaySelectedDivisions() {
    const knowledgeDivisionArray = knowledgeDivisionDetails?.split('&'); 
    if ((knowledgeDivisionArray) && (knowledgeDivisionDetails.trim() !== '')) {
    return (
            <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
            <thead>
                <tr>
                    <th style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Division(s)</h5></th>
                </tr>
            </thead>
            <tbody>
                {knowledgeDivisionArray.map((node, index) => {
                    return (
                    <tr>
                        {/* <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.data.name}</h6></td> */}
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]}</h6></td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => handleDeleteAssignedTreeNodeClick(node)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Division'>delete</span>
                            </a>
                        </td>
                    </tr>
                    )
                })}
            </tbody>
        </Table>                                                    
        );
    }
}

function getSearchParamsEx(bRadioValue, bRadioTypeValue, pageNumber)  {
    let oParams = {
        LearningObjectProcessStatusTypeID:bRadioTypeValue,
        LearningObjectID:knowledgeID,
        RecordSize:'10',
        PageNumber:pageNumber,
    }; 
    return oParams;
}

async function searchKnowledgeTrackInstances(bRadioValue, bRadioTypeValue) {
    setLoading(true);
    const oParams = getSearchParamsEx(bRadioValue, bRadioTypeValue, '1');
    await props.getKnowledgeInstances(oParams);
    setLoading(false);
}

async function handleRadioChange(e) {
    // currentRecIndex = 0; 
    setRadioValue(e.currentTarget.value);
    searchKnowledgeTrackInstances(e.currentTarget.value, radioValue);
}

function displayListRadioButtons() {
    return (
        radios.map((radio, idx) => (
            <ToggleButton
                disabled={loading} 
                key={'checklisttype' + idx}
                id={`radio-${'checklisttype' + idx}`}
                type="radio"
                style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                size='sm'
                onChange={(e) => handleRadioChange(e)}
            >
                {radio.name}
            </ToggleButton>
        ))
    );
}

function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <h3 style={styles.SubHeader}>Knowledge Track Instances</h3>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
                <td style={{textAlign:'right', width:'60%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Knowledge Track Instance'/>
                        <a onClick={() => searchKnowledgeInstances()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {(permissionFlags.CanCreate) ? 
                            (addNewKnowledgeInstance) ? 
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Knowledge Instance'>shadow_minus</span>
                                </a>
                                :
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Knowledge Instance'>note_add</span>
                                </a>
                        : 
                            <></>
                        }
                        <ButtonGroup className="mb-2" size='sm'>
                            {displayListRadioButtons()}
                        </ButtonGroup>                                                                   
                        <ButtonGroup className="mb-2" size='sm'>
                            <Button disabled={loading} style={styles.CDFullButton} onClick={() => handleCancel()}>Cancel</Button>                                        
                        </ButtonGroup>                                                                   
                    </div>                                            
                </td>
            </tr>
            {((knowledgeinstance) && (knowledgeinstance.length > 0)) ?
                <tr>
                    <td colSpan='2' style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                        <h5 style={styles.SubHeader}>{knowledgeinstance[0]?.LearningObjectName}</h5>
                    </td>
                </tr>
            :
                    <></>
            }
            </tbody>
        </Table>                            
        );    
    }

    function displayAssignedEmployeesDetails(assRec) {
        let sMsg = '';
        if (assRec?.AssignedUsers > 0) {
            sMsg = assRec?.AssignedUsers.toLocaleString() + ' assigned';
        }
        if (assRec?.CompletedUsers > 0) {
            if (sMsg !== '') {
                sMsg += ', ';
            }
            sMsg = assRec?.CompletedUsers.toLocaleString() + ' completed';
        }
        return sMsg;
    }

    function displayKnowledgeTypeName(assRec) {
        if (assRec?.LearningObjectTypeID === 1) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Classroom-Based'>supervised_user_circle</span>
            );
        } else if (assRec?.LearningObjectTypeID === 2) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Online'>touchpad_mouse</span>
            )
        } else if (assRec?.LearningObjectTypeID === 3) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Manual/External'>other_admission</span>
            );
        } else if (assRec?.LearningObjectTypeID === 4) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Historical'>work_history</span>
            );
        }
    }   
    
    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showInstructorList} onShow={handleShowInstructorList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseInstructorList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseInstructorList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showPresenterList} onShow={handleShowPresenterList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClosePresenterList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClosePresenterList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showJPList} onShow={handleShowJPList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseJPList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <JobPositions fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseJPList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showNotificationMessageList} onShow={handleShowNotificationMessageList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseNotificationMessageList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NotificationMessageList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseNotificationMessageList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showFacilityList} onShow={handleShowFacilityList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseFacilityList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FacilityList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseFacilityList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showRecurringParameters} onShow={handleShowRecurringParameters} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseRecurringParameters}>
            <Modal.Header closeButton>
            <Modal.Title>Recurring Parameters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {displayRecurringParameters()}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseRecurringParameters}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                            <Card.Subtitle>
                            {(showAlertMessage) ?
                                <div>
                                    <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} handleParentAlertClose={handleCloseAlert} /></h5>
                                </div>
                                :
                                    <></>
                                }
                            </Card.Subtitle>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewKnowledge()}                                               
                            {((!knowledgeinstance) || (knowledgeinstance.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Knowledge Track Instances.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Dates</th>
                                                <th style={styles.tableText}>Facility</th>
                                                <th style={styles.tableText}>Instructor</th>
                                                <th style={styles.tableText}>Status</th>
                                                <th style={styles.tableText}>Students</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {knowledgeinstance && knowledgeinstance.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{assRec?.LearningObjectInstanceID}</td>
                                                        <td style={styles.tableText}>{moment(assRec?.StartDateTime).format("MM/DD/YYYY hh:mm a")} - {moment(assRec?.EndDateTime).format("MM/DD/YYYY hh:mm a")}</td>
                                                        <td style={styles.tableTextSubHeader}>{assRec?.FullFacilityName}</td>
                                                        <td style={styles.tableText}>{assRec?.InstructorSummary}</td>
                                                        <td style={styles.tableText}>{assRec?.LearningObjectProcessStatusTypeName}</td>
                                                        <td style={styles.tableText}>{assRec?.StudentCount?.toLocaleString()}</td>
                                                        <td style={styles.tableText}>
                                                            <div>
                                                                {(permissionFlags.CanModify) ? 
                                                                    <>
                                                                    <a onClick={() => handleViewChildernClick(assRec?.LearningObjectInstanceID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='View Roster'>group</span>
                                                                    </a>
                                                                    <a onClick={() => handleViewRecurringParameterClick(assRec?.LearningObjectInstanceID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Recur instance'>dynamic_feed</span>
                                                                    </a>
                                                                    <a onClick={() => handleUpdateChildernClick(assRec?.LearningObjectInstanceID, assRec?.LearningObjectID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Knowledge Track Instance'>edit</span>
                                                                    </a>
                                                                    {((permissionFlags.CanDelete) && (assRec?.StudentCount === 0)) ? 
                                                                        <a onClick={() => handleDeleteChildernClick(assRec?.LearningObjectInstanceID)}>
                                                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Knowledge Track Instance'>delete</span>
                                                                        </a>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    </>
                                                                : 
                                                                    <></>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{knowledgeinstances?.knowledgeinstances?.RecordFound?.toLocaleString()}   Knowledge Track Instances&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(knowledgeinstances?.knowledgeinstances?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(knowledgeinstances?.knowledgeinstances?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {knowledgeinstances?.knowledgeinstances?.PagingMetaData?.PageNumber}  of  {knowledgeinstances?.knowledgeinstances?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      knowledgeinstances: state.entity.knowledgeinstances, 
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledges: (kParams) => dispatch(getKnowledges(kParams)),
        getKnowledgeInstances: (kParams) => dispatch(getKnowledgeInstances(kParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertKnowledgeInstance: (oParams) => dispatch(insertKnowledgeInstance(oParams)),
        insertKnowledgeInstanceRecurring: (oParams) => dispatch(insertKnowledgeInstanceRecurring(oParams)),
        deleteKnowledgeInstance: (oParams) => dispatch(deleteKnowledgeInstance(oParams)),
        updateKnowledgeInstance: (oParams) => dispatch(updateKnowledgeInstance(oParams)),
        getJobPositions: (jpParams) => dispatch(getJobPositions(jpParams)),
        getNotificationMessages: (oParams) => dispatch(getNotificationMessages(oParams)),
        getFacilities: (oParams) => dispatch(getFacilities(oParams)),
        getKnowledgeSessions: (kParams) => dispatch(getKnowledgeSessions(kParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeInstanceList)