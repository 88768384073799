import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import Spinner from '../layout/Spinner';
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';

const LaunchReport = (props) => {
    const { id } = useParams();

    const { auth, companyData, profile, reports, reportData, userReportPillar, userReportPerformance } = props;    
    const [loading, setloading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [prfEvaluationID, setPRFEvaluationID] = useState('');

    const navigate = useNavigate();


    const currentReport = reports.Reports.filter((report) => (report.ReportID === parseInt(id)));
   
    async function LoadReport() {
        try {
            setloading(true);
            const companyParameters = auth.UserCompanyParameters?.split('&');
            companyParameters.map((parameter) => {
                const parameterDetails = parameter?.split('=');
                if (parameterDetails[0] === 'PERFORMANCEEVALUATIONS') {
                    setPRFEvaluationID(parameterDetails[1]);
                }
            });
    
            //Activation using license code
            Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn04eLULI2MA30R38ouYCOkHfLPlSZaUBW6LKsUvTF+CkZYFj" +
            "tyPSsY69piagtu/MaYXmbC+WeI5pHvyGLSVHbOr0zIZKfuescfo8sNtS2OVR0c8hisT3aigP5JAxYtQ829PVU0QACC" +
            "llx3OPBlOLA1Ui4dRh4cwVQ/HHEDt0vp2/gTl3hMH6f2PjeBWpDrIUOj4n11a1JOZdjpuGqGGdEnkGmAuHubr7vbIB" +
            "4BcFw6DnOuv2Y1h1Qcd25bfRPf9XGmIYBpjaakjc2xs9NUfkCZLJ/sC/7vS4QRGpXpi/wkkra3PLhp9eoxTCzpTIrz" +
            "9bcAeoIpMrrUADtv1G1E7SdZWEo1uKOMiPaAIOJc1zGEntTlIyed8rHg7Gu1H7mdQ6x40OArGmCzmrnUchJ88B+QjI" +
            "KLuqsIg/CzmB6nDspXFFc4CTaSDxYBrxEznRMi28BIuDtPO7sbR3ey1c6SosUyXK1B6a3XaatNBgoqtTJ4TdHdGtz/" +
            "2QScmUrUu64+NrCFdCVUWNiA3UHSgfmnlJe1";            

            // Stimulsoft Reports module
            let stiViewer = new Stimulsoft.Viewer.StiViewer(undefined, 'StiViewer', false);
            let stiReport = new Stimulsoft.Report.StiReport();
            stiReport.loadFile('/reports/' + currentReport[0]?.ReportClassName);//RTUD.mrt');
//            console.log(stiReport);
    
            // Remove all connections from the report template
            stiReport.dictionary.databases.clear();
            if ((currentReport[0]?.ShowEvaluationSelector === true) && (currentReport[0]?.ParameterSummary !== null) && (currentReport[0]?.ParameterSummary === prfEvaluationID)) {
                // console.log(userReportPillar);
                // Create new DataSet object
                var dataSet = new Stimulsoft.System.Data.DataSet("Users3");
                dataSet.readJson(userReportPillar);
                // Register DataSet object
                stiReport.regData("Users2", "Users2", dataSet);
            } else if ((currentReport[0]?.ShowEvaluationSelector === true) && (currentReport[0]?.ParameterSummary !== null)) {
                // console.log(userReportPillar);
                // Create new DataSet object
                var dataSet = new Stimulsoft.System.Data.DataSet("Users2");
                dataSet.readJson(userReportPillar);
                // Register DataSet object
                stiReport.regData("Users2", "Users2", dataSet);
            } else {
                // Create new DataSet object
                var dataSet = new Stimulsoft.System.Data.DataSet("Users");
                dataSet.readJson(reportData);
                // console.log(dataSet);
                // Register DataSet object
                stiReport.regData("Users", "Users", dataSet);
            }
            stiViewer.report = stiReport;        
            stiViewer.renderHtml('stiViewer');
    
            setloading(false);
        } catch (err){
            console.log('Error in generating report  - ' + id);
            setloading(false);
        }
    }    

    useEffect(() => {
        LoadReport();
    }, [])    
   
    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                {(loading) ? (            
                    <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                        <Spinner/>
                    </div>) : (

                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                <Card.Title>
                                        <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'85%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Report - {(currentReport[0]?.ReportName) ? currentReport[0].ReportName : 'Report Viewer'}</h3></td>
                                                {/* <td style={{textAlign:'center', width:'15%', backgroundColor:companyData?.PagesBGColor}}><Button disabled={loading} style={styles.CDFullButton} onClick={() => LoadReport()}>{loading ? 'Showing...' : 'Show'}</Button></td>  */}
                                                <td style={{textAlign:'center', width:'15%', backgroundColor:companyData?.PagesBGColor}}><Button disabled={loading} style={styles.CDFullButton} onClick={() => navigate("/reports")}>Cancel</Button></td>
                                            </tr>
                                            </tbody>
                                        </Table>                            
                                </Card.Title>
                            </Card.Header>                   
                            <Card.Body style={{ width:'100%', height:'80%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <div id="stiViewer" style={{ width:'100%', height:'1000%'}}>
                                </div>                            
                            </Card.Body>
                            <Card.Footer className="text-left">
                            </Card.Footer>
                    </Card>

                    )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      reports: state.auth.reports, 
      reportData: state.auth.userReportDetails,
      userReportPillar: state.auth.userReportPillar,
      userReportPerformance: state.auth.userReportPerformance,
    }
}

export default connect(mapStateToProps, null)(LaunchReport)