import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { validateCompany } from '../../store/actions/authActions';

function AICCCapture(props) {
    const { authError } = props;
    const { cid, sid } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    //const sid = searchParams.get('sid');
    const completeQueryStr = searchParams.toString();
    const [showMessage, setShowMessage] = useState(true)
    const [message, setMessage] = useState('')    

    useEffect(() => {
        console.log(cid);
        console.log(sid);
        console.log(completeQueryStr);
        if ((cid) && (cid !== -1)) {
            getCompanyDetails();
        }
    }, [cid]);

    useEffect(() => {
        console.log(authError);
        setMessage(authError);
    }, [authError]);

    async function getCompanyDetails() {
        await props.validateCompany(cid, completeQueryStr);
        console.log(searchParams);
        // Extract query params (AICC request parameters)
        //const urlSearchParams = new URLSearchParams(location.search);
        //console.log(urlSearchParams);
        //const aiccData = urlSearchParams.toString(); // Convert to URL-encoded format        
    }

    function displayMessage() {
        if (showMessage) {
            return (
                <Alert variant="info" style={{textAlign:'center'}}>{message}</Alert>
            );    
        } else {
            return (
                <></>
            );    
        }
    }

    return (
    <>
        {displayMessage()}
    </>
    )
}

const mapsStateToProps = (state) => {
    return {
        authError: state?.auth?.authError,
        companyData: state?.auth?.companyData,
        auth: state?.auth?.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        validateCompany: (id, completeQueryStr) => dispatch(validateCompany(id, completeQueryStr)),
    }
} 

export default connect(mapsStateToProps, mapDispatchToProps)(AICCCapture)

