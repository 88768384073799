export const MAINAPITOKEN_SUCCESS = 'MAINAPITOKEN_SUCCESS'
export const MAINAPITOKENEXPIRESON_SUCCESS = 'MAINAPITOKENEXPIRESON_SUCCESS'
export const APITOKEN_SUCCESS = 'APITOKEN_SUCCESS'
export const APITOKENEXPIRESON_SUCCESS = 'APITOKENEXPIRESON_SUCCESS'
export const VALIDATECOMPANY_SUCCESS = 'VALIDATECOMPANY_SUCCESS'
export const VALIDATECOMPANY_ERROR = 'VALIDATECOMPANY_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const VALIDATESSOSTATE_SUCCESS = 'VALIDATESSOSTATE_SUCCESS'
export const VALIDATESSOSTATE_ERROR = 'VALIDATESSOSTATE_ERROR'
export const GETPROFILE_SUCCESS = 'GETPROFILE_SUCCESS'
export const GETPROFILE_ERROR = 'GETPROFILE_ERROR'
export const UPDATEPROFILE_SUCCESS = 'UPDATEPROFILE_SUCCESS'
export const UPDATEPROFILE_ERROR = 'UPDATEPROFILE_ERROR'
export const GETAUDITTRAIL_SUCCESS = 'GETAUDITTRAIL_SUCCESS'
export const GETAUDITTRAIL_ERROR = 'GETAUDITTRAIL_ERROR'
export const GETUSEREVALUATIONSESSION_SUCCESS = 'GETUSEREVALUATIONSESSION_SUCCESS'
export const GETUSEREVALUATIONSESSION_ERROR = 'GETUSEREVALUATIONSESSION_ERROR'
export const GETUSEREVALUATIONSESSIONRESPONSES_SUCCESS = 'GETUSEREVALUATIONSESSIONRESPONSES_SUCCESS'
export const GETUSEREVALUATIONSESSIONRESPONSES_ERROR = 'GETUSEREVALUATIONSESSIONRESPONSES_ERROR'
export const GETUSEREVALUATION_SUCCESS = 'GETUSEREVALUATION_SUCCESS'
export const GETUSEREVALUATION_ERROR = 'GETUSEREVALUATION_ERROR'
export const GETEVALUATION_SUCCESS = 'GETEVALUATION_SUCCESS'
export const GETEVALUATION_ERROR = 'GETEVALUATION_ERROR'
export const GETEVALUATIONQUESTIONS_SUCCESS = 'GETEVALUATIONQUESTIONS_SUCCESS'
export const GETEVALUATIONQUESTIONS_ERROR = 'GETEVALUATIONQUESTIONS_ERROR'
export const GETEVALUATIONRATINGS_SUCCESS = 'GETEVALUATIONRATINGS_SUCCESS'
export const GETEVALUATIONRATINGS_ERROR = 'GETEVALUATIONRATINGS_ERROR'
export const UPDATEEVALUATIONRESPONSES_SUCCESS = 'UPDATEEVALUATIONRESPONSES_SUCCESS'
export const UPDATEEVALUATIONRESPONSES_ERROR = 'UPDATEEVALUATIONRESPONSES_ERROR'
export const UPDATEEVALUATIONSESSION_SUCCESS = 'UPDATEEVALUATIONSESSION_SUCCESS'
export const UPDATEEVALUATIONSESSION_ERROR = 'UPDATEEVALUATIONSESSION_ERROR'
export const GETRETENTIONPREDICTION_SUCCESS = 'GETRETENTIONPREDICTION_SUCCESS'
export const GETRETENTIONPREDICTION_ERROR = 'GETRETENTIONPREDICTION_ERROR'
export const GETMYTEAMRETENTIONSUGGESTIONS_SUCCESS = 'GETMYTEAMRETENTIONSUGGESTIONS_SUCCESS'
export const GETMYTEAMRETENTIONSUGGESTIONS_ERROR = 'GETMYTEAMRETENTIONSUGGESTIONS_ERROR'
export const GETMYTEAMRETENTIONPREDICTIONHISTORY_SUCCESS = 'GETMYTEAMRETENTIONPREDICTIONHISTORY_SUCCESS'
export const GETMYTEAMRETENTIONPREDICTIONHISTORY_ERROR = 'GETMYTEAMRETENTIONPREDICTIONHISTORY_ERROR'
export const UPDATEUSERSUGGESTION_SUCCESS = 'UPDATEUSERSUGGESTION_SUCCESS'
export const UPDATEUSERSUGGESTION_ERROR = 'UPDATEUSERSUGGESTION_ERROR'
export const MYTEAMSELECTED_SUCCESS = 'MYTEAMSELECTED_SUCCESS'
export const MYTEAMSELECTED_ERROR = 'MYTEAMSELECTED_ERROR'
export const ASSIGNACTIVEMENU_SUCCESS = 'ASSIGNACTIVEMENU_SUCCESS'
export const ASSIGNACTIVEMENU_ERROR = 'ASSIGNACTIVEMENU_ERROR'
export const LOADINGMENUSELECTION_SUCCESS = 'LOADINGMENUSELECTION_SUCCESS'
export const LOADINGMENUSELECTION_ERROR = 'LOADINGMENUSELECTION_ERROR'
export const GETORGDIVISIONS_SUCCESS = 'GETORGDIVISIONS_SUCCESS'
export const GETORGDIVISIONS_ERROR = 'GETORGDIVISIONS_ERROR'
export const GETORGJOBCLASSES_SUCCESS = 'GETORGJOBCLASSES_SUCCESS'
export const GETORGJOBCLASSES_ERROR = 'GETORGJOBCLASSES_ERROR'
export const GETEVALUATIONS_SUCCESS = 'GETEVALUATIONS_SUCCESS'
export const GETEVALUATIONS_ERROR = 'GETEVALUATIONS_ERROR'
export const GETASSIGNMENTS_SUCCESS = 'GETASSIGNMENTS_SUCCESS'
export const GETASSIGNMENTS_ERROR = 'GETASSIGNMENTS_ERROR'
export const GETRTREPORTS_SUCCESS = 'GETRTREPORTS_SUCCESS'
export const GETRTREPORTS_ERROR = 'GETRTREPORTS_ERROR'
export const GETRTUSERREPORTDETAILS_SUCCESS = 'GETRTUSERREPORTDETAILS_SUCCESS'
export const GETRTUSERREPORTDETAILS_ERROR = 'GETRTUSERREPORTDETAILS_ERROR'
export const GETRTUSERREPORTPILLARS_SUCCESS = 'GETRTUSERREPORTPILLARS_SUCCESS'
export const GETRTUSERREPORTPILLARS_ERROR = 'GETRTUSERREPORTPILLARS_ERROR'
export const GETRTUSERREPORTPERFORMANCE_SUCCESS = 'GETRTUSERREPORTPERFORMANCE_SUCCESS'
export const GETRTUSERREPORTPERFORMANCE_ERROR = 'GETRTUSERREPORTPERFORMANCE_ERROR'
export const GETUSERATTRIBUTELOOKUPS_SUCCESS = 'GETUSERATTRIBUTELOOKUPS_SUCCESS'
export const GETUSERATTRIBUTELOOKUPS_ERROR = 'GETUSERATTRIBUTELOOKUPS_ERROR'
export const INSERTUSERLICENSE_SUCCESS = 'INSERTUSERLICENSE_SUCCESS'
export const INSERTUSERLICENSE_ERROR = 'INSERTUSERLICENSE_ERROR'
export const DELETEUSERLICENSE_SUCCESS = 'DELETEUSERLICENSE_SUCCESS'
export const DELETEUSERLICENSE_ERROR = 'DELETEUSERLICENSE_ERROR'
export const GETUSERLICENSE_SUCCESS = 'GETUSERLICENSE_SUCCESS'
export const GETUSERLICENSESELECTED_SUCCESS = 'GETUSERLICENSESELECTED_SUCCESS'
export const GETUSERLICENSE_ERROR = 'GETUSERLICENSE_ERROR'
export const INSERTUSERCREDENTIAL_SUCCESS = 'INSERTUSERCREDENTIAL_SUCCESS'
export const INSERTUSERCREDENTIAL_ERROR = 'INSERTUSERCREDENTIAL_ERROR'
export const DELETEUSERCREDENTIAL_SUCCESS = 'DELETEUSERCREDENTIAL_SUCCESS'
export const DELETEUSERCREDENTIAL_ERROR = 'DELETEUSERCREDENTIAL_ERROR'
export const GETUSERCREDENTIAL_SUCCESS = 'GETUSERCREDENTIAL_SUCCESS'
export const GETUSERCREDENTIALSELECTED_SUCCESS = 'GETUSERCREDENTIALSELECTED_SUCCESS'
export const GETUSERCREDENTIAL_ERROR = 'GETUSERCREDENTIAL_ERROR'
export const INSERTUSERSKILL_SUCCESS = 'INSERTUSERSKILL_SUCCESS'
export const INSERTUSERSKILL_ERROR = 'INSERTUSERSKILL_ERROR'
export const UPDATEUSERSKILL_SUCCESS = 'UPDATEUSERSKILL_SUCCESS'
export const UPDATEUSERSKILL_ERROR = 'UPDATEUSERSKILL_ERROR'
export const DELETEUSERSKILL_SUCCESS = 'DELETEUSERSKILL_SUCCESS'
export const DELETEUSERSKILL_ERROR = 'DELETEUSERSKILL_ERROR'
export const GETUSERSKILL_SUCCESS = 'GETUSERSKILL_SUCCESS'
export const GETUSERSKILLSELECTED_SUCCESS = 'GETUSERSKILLSELECTED_SUCCESS'
export const GETUSERSKILL_ERROR = 'GETUSERSKILL_ERROR'
export const GETSKILLCHECKLIST_SUCCESS = 'GETSKILLCHECKLIST_SUCCESS'
export const GETSKILLCHECKLIST_ERROR = 'GETSKILLCHECKLIST_ERROR'
export const GETSKILLCHECKLISTGROUPBY_SUCCESS = 'GETSKILLCHECKLISTGROUPBY_SUCCESS'
export const GETSKILLCHECKLISTGROUPBY_ERROR = 'GETSKILLCHECKLISTGROUPBY_ERROR'
export const GETJOBPOSITIONS_SUCCESS = 'GETJOBPOSITIONS_SUCCESS'
export const GETJOBPOSITIONS_ERROR = 'GETJOBPOSITIONS_ERROR'
export const INSERTJOBPOSITIONSKILL_SUCCESS = 'INSERTJOBPOSITIONSKILL_SUCCESS'
export const INSERTJOBPOSITIONSKILL_ERROR = 'INSERTJOBPOSITIONSKILL_ERROR'
export const DELETEJOBPOSITIONSKILL_SUCCESS = 'DELETEJOBPOSITIONSKILL_SUCCESS'
export const DELETEJOBPOSITIONSKILL_ERROR = 'DELETEJOBPOSITIONSKILL_ERROR'
export const INSERTJOBPOSITIONREQUIREMENT_SUCCESS = 'INSERTJOBPOSITIONREQUIREMENT_SUCCESS'
export const INSERTJOBPOSITIONREQUIREMENT_ERROR = 'INSERTJOBPOSITIONREQUIREMENT_ERROR'
export const DELETEJOBPOSITIONREQUIREMENT_SUCCESS = 'DELETEJOBPOSITIONREQUIREMENT_SUCCESS'
export const DELETEJOBPOSITIONREQUIREMENT_ERROR = 'DELETEJOBPOSITIONREQUIREMENT_ERROR'
export const INSERTJOBPOSITIONEQG_SUCCESS = 'INSERTJOBPOSITIONEQG_SUCCESS'
export const INSERTJOBPOSITIONEQG_ERROR = 'INSERTJOBPOSITIONEQG_ERROR'
export const DELETEJOBPOSITIONEQG_SUCCESS = 'DELETEJOBPOSITIONEQG_SUCCESS'
export const DELETEJOBPOSITIONEQG_ERROR = 'DELETEJOBPOSITIONEQG_ERROR'
export const GETJOBPOSITIONREQUIREMENTS_SUCCESS = 'GETJOBPOSITIONREQUIREMENTS_SUCCESS'
export const GETJOBPOSITIONREQUIREMENTS_ERROR = 'GETJOBPOSITIONREQUIREMENTS_ERROR'
export const GETJOBPOSITIONSKILLS_SUCCESS = 'GETJOBPOSITIONSKILLS_SUCCESS'
export const GETJOBPOSITIONSKILLS_ERROR = 'GETJOBPOSITIONSKILLS_ERROR'
export const GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_SUCCESS = 'GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_SUCCESS'
export const GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_ERROR = 'GETJOBPOSITIONEVALUATIONQUESTIONGROUPS_ERROR'
export const INSERTUSERJOBPOSITIONREQUIREMENT_SUCCESS = 'INSERTUSERJOBPOSITIONREQUIREMENT_SUCCESS'
export const INSERTUSERJOBPOSITIONREQUIREMENT_ERROR = 'INSERTUSERJOBPOSITIONREQUIREMENT_ERROR'
export const DELETEUSERJOBPOSITIONREQUIREMENT_SUCCESS = 'DELETEUSERJOBPOSITIONREQUIREMENT_SUCCESS'
export const DELETEUSERJOBPOSITIONREQUIREMENT_ERROR = 'DELETEUSERJOBPOSITIONREQUIREMENT_ERROR'
export const GETUSERJOBPOSITIONREQUIREMENTS_SUCCESS = 'GETUSERJOBPOSITIONREQUIREMENTS_SUCCESS'
export const GETUSERJOBPOSITIONREQUIREMENTSSELECTED_SUCCESS = 'GETUSERJOBPOSITIONREQUIREMENTSSELECTED_SUCCESS'
export const GETUSERJOBPOSITIONREQUIREMENTS_ERROR = 'GETUSERJOBPOSITIONREQUIREMENTS_ERROR'
export const INSERTUSERFUTUREJOBPOSITION_SUCCESS = 'INSERTUSERJOBPOSITIONREQUIREMENT_SUCCESS'
export const INSERTUSERFUTUREJOBPOSITION_ERROR = 'INSERTUSERFUTUREJOBPOSITION_ERROR'
export const DELETEUSERFUTUREJOBPOSITION_SUCCESS = 'DELETEUSERFUTUREJOBPOSITION_SUCCESS'
export const DELETEUSERFUTUREJOBPOSITION_ERROR = 'DELETEUSERFUTUREJOBPOSITION_ERROR'
export const GETUSERFUTUREJOBPOSITIONS_SUCCESS = 'GETUSERFUTUREJOBPOSITIONS_SUCCESS'
export const GETUSERFUTUREJOBPOSITIONSSELECTED_SUCCESS = 'GETUSERFUTUREJOBPOSITIONSSELECTED_SUCCESS'
export const GETUSERFUTUREJOBPOSITIONS_ERROR = 'GETUSERFUTUREJOBPOSITIONS_ERROR'
export const UPDATEJPLOOKUPSELECTION_SUCCESS = 'UPDATEJPLOOKUPSELECTION_SUCCESS'
export const UPDATEJPLOOKUPSELECTION_ERROR = 'UPDATEJPLOOKUPSELECTION_ERROR'
export const UPDATEUSERLOOKUPSELECTION_SUCCESS = 'UPDATEUSERLOOKUPSELECTION_SUCCESS'
export const UPDATEUSERLOOKUPSELECTION_ERROR = 'UPDATEUSERLOOKUPSELECTION_ERROR'
export const UPDATEGOALLOOKUPSELECTION_SUCCESS = 'UPDATEGOALLOOKUPSELECTION_SUCCESS'
export const UPDATEGOALLOOKUPSELECTION_ERROR = 'UPDATEGOALLOOKUPSELECTION_ERROR'
export const UPDATEREMINDERLOOKUPSELECTION_SUCCESS = 'UPDATEREMINDERLOOKUPSELECTION_SUCCESS'
export const UPDATEREMINDERLOOKUPSELECTION_ERROR = 'UPDATEREMINDERLOOKUPSELECTION_ERROR'
export const GETMYTEAM_SUCCESS = 'GETMYTEAM_SUCCESS'
export const GETMYTEAM_ERROR = 'GETMYTEAM_ERROR'
export const GETSELECTEDUSERPROFILE_SUCCESS = 'GETSELECTEDUSERPROFILE_SUCCESS'
export const GETSELECTEDUSERPROFILE_ERROR = 'GETSELECTEDUSERPROFILE_ERROR'
export const GETUSERLOOKUP_SUCCESS = 'GETUSERLOOKUP_SUCCESS'
export const GETUSERLOOKUP_ERROR = 'GETUSERLOOKUP_ERROR'
export const GETDIVISIONMANAGERS_SUCCESS = 'GETDIVISIONMANAGERS_SUCCESS'
export const GETDIVISIONMANAGERS_ERROR = 'GETDIVISIONMANAGERS_ERROR'
export const GETDIVISIONLEADERSHIPASSESSMENTLEVELS_SUCCESS = 'GETDIVISIONLEADERSHIPASSESSMENTLEVELS_SUCCESS'
export const GETDIVISIONLEADERSHIPASSESSMENTLEVELS_ERROR = 'GETDIVISIONLEADERSHIPASSESSMENTLEVELS_ERROR'
export const GETDIVISIONCOMPETENCYANALYSIS_SUCCESS = 'GETDIVISIONCOMPETENCYANALYSIS_SUCCESS'
export const GETDIVISIONCOMPETENCYANALYSIS_ERROR = 'GETDIVISIONCOMPETENCYANALYSIS_ERROR'
export const GETDIVISIONPILLARANALYSIS_SUCCESS = 'GETDIVISIONPILLARANALYSIS_SUCCESS'
export const GETDIVISIONPILLARANALYSIS_ERROR = 'GETDIVISIONPILLARANALYSIS_ERROR'
export const GETJOBPOSITIONPILLARANALYSIS_SUCCESS = 'GETJOBPOSITIONPILLARANALYSIS_SUCCESS'
export const GETJOBPOSITIONPILLARANALYSIS_ERROR = 'GETJOBPOSITIONPILLARANALYSIS_ERROR'
export const GETMYTEAMSTATSELECTED_SUCCESS = 'MYTEAMSTATSELECTED_SUCCESS'
export const GETMYTEAMSTATSELECTED_ERROR = 'MYTEAMSTATSELECTED_ERROR'
export const ASSIGNRESTRUCTUREDETAILS_SUCCESS = 'ASSIGNRESTRUCTUREDETAILS_SUCCESS'
export const ASSIGNRESTRUCTUREDETAILS_ERROR = 'ASSIGNRESTRUCTUREDETAILS_ERROR'
export const INSERTEVALUATION_SUCCESS = 'INSERTEVALUATION_SUCCESS'
export const INSERTEVALUATION_ERROR = 'INSERTEVALUATION_ERROR'
export const DELETEEVALUATION_SUCCESS = 'DELETEEVALUATION_SUCCESS'
export const DELETEEVALUATION_ERROR = 'DELETEEVALUATION_ERROR'
export const UPDATEEVALUATION_SUCCESS = 'UPDATEEVALUATION_SUCCESS'
export const UPDATEEVALUATION_ERROR = 'UPDATEEVALUATION_ERROR'
export const INSERTEVALUATIONQUESTION_SUCCESS = 'INSERTEVALUATIONQUESTION_SUCCESS'
export const INSERTEVALUATIONQUESTION_ERROR = 'INSERTEVALUATIONQUESTION_ERROR'
export const UPDATEEVALUATIONQUESTION_SUCCESS = 'UPDATEEVALUATIONQUESTION_SUCCESS'
export const UPDATEEVALUATIONQUESTION_ERROR = 'UPDATEEVALUATIONQUESTION_ERROR'
export const DELETEEVALUATIONQUESTION_SUCCESS = 'DELETEEVALUATIONQUESTION_SUCCESS'
export const DELETEEVALUATIONQUESTION_ERROR = 'DELETEEVALUATIONQUESTION_ERROR'
export const INSERTEVALUATIONRATING_SUCCESS = 'INSERTEVALUATIONRATING_SUCCESS'
export const INSERTEVALUATIONRATING_ERROR = 'INSERTEVALUATIONRATING_ERROR'
export const DELETEEVALUATIONRATING_SUCCESS = 'DELETEEVALUATIONRATING_SUCCESS'
export const DELETEEVALUATIONRATING_ERROR = 'DELETEEVALUATIONRATING_ERROR'
export const INSERTASSIGNMENT_SUCCESS = 'INSERTASSIGNMENT_SUCCESS'
export const INSERTASSIGNMENT_ERROR = 'INSERTASSIGNMENT_ERROR'
export const DELETEASSIGNMENT_SUCCESS = 'DELETEASSIGNMENT_SUCCESS'
export const DELETEASSIGNMENT_ERROR = 'DELETEASSIGNMENT_ERROR'
export const UPDATEASSIGNMENT_SUCCESS = 'UPDATEASSIGNMENT_SUCCESS'
export const UPDATEASSIGNMENT_ERROR = 'UPDATEASSIGNMENT_ERROR'
export const CLEARENTITYSTATE_SUCCESS = 'CLEARENTITYSTATE_SUCCESS'
export const CLEARENTITYSTATE_ERROR = 'CLEARENTITYSTATE_ERROR'
export const GETGOALS_SUCCESS = 'GETGOALS_SUCCESS'
export const GETGOALS_ERROR = 'GETGOALS_ERROR'
export const INSERTUSERGOAL_SUCCESS = 'INSERTUSERGOAL_SUCCESS'
export const INSERTUSERGOAL_ERROR = 'INSERTUSERGOAL_ERROR'
export const DELETEUSERGOAL_SUCCESS = 'DELETEUSERGOAL_SUCCESS'
export const DELETEUSERGOAL_ERROR = 'DELETEUSERGOAL_ERROR'
export const GETUSERGOAL_SUCCESS = 'GETUSERGOAL_SUCCESS'
export const GETUSERGOAL_ERROR = 'GETUSERGOAL_ERROR'
export const GETUSERGOALSELECTED_SUCCESS = 'GETUSERGOALSELECTED_SUCCESS'
export const GETCOMPETENCIES_SUCCESS = 'GETCOMPETENCIES_SUCCESS'
export const GETCOMPETENCIES_ERROR = 'GETCOMPETENCIES_ERROR'
export const GETSKILLS_SUCCESS = 'GETSKILLS_SUCCESS'
export const GETSKILLS_ERROR = 'GETSKILLS_ERROR'
export const INSERTSKILL_SUCCESS = 'INSERTSKILL_SUCCESS'
export const INSERTSKILL_ERROR = 'INSERTSKILL_ERROR'
export const DELETESKILL_SUCCESS = 'DELETESKILL_SUCCESS'
export const DELETESKILL_ERROR = 'DELETESKILL_ERROR'
export const UPDATESKILL_SUCCESS = 'UPDATESKILL_SUCCESS'
export const UPDATESKILL_ERROR = 'UPDATESKILL_ERROR'
export const UPDATESKILLLOOKUPSELECTION_SUCCESS = 'UPDATESKILLLOOKUPSELECTION_SUCCESS'
export const UPDATESKILLLOOKUPSELECTION_ERROR = 'UPDATESKILLLOOKUPSELECTION_ERROR'
export const GETNOTIFICATIONMESSAGES_SUCCESS = 'GETNOTIFICATIONMESSAGES_SUCCESS'
export const GETNOTIFICATIONMESSAGES_ERROR = 'GETNOTIFICATIONMESSAGES_ERROR'
export const INSERTNOTIFICATIONMESSAGE_SUCCESS = 'INSERTNOTIFICATIONMESSAGE_SUCCESS'
export const INSERTNOTIFICATIONMESSAGE_ERROR = 'INSERTNOTIFICATIONMESSAGE_ERROR'
export const DELETENOTIFICATIONMESSAGE_SUCCESS = 'DELETENOTIFICATIONMESSAGE_SUCCESS'
export const DELETENOTIFICATIONMESSAGE_ERROR = 'DELETENOTIFICATIONMESSAGE_ERROR'
export const UPDATENOTIFICATIONMESSAGE_SUCCESS = 'UPDATENOTIFICATIONMESSAGE_SUCCESS'
export const UPDATENOTIFICATIONMESSAGE_ERROR = 'UPDATENOTIFICATIONMESSAGE_ERROR'
export const GETUSERSBYPOLICY_SUCCESS = 'GETUSERSBYPOLICY_SUCCESS'
export const GETUSERSBYPOLICY_ERROR = 'GETUSERSBYPOLICY_ERROR'
export const GETUSEREVALUATIONS_SUCCESS = 'GETUSEREVALUATIONS_SUCCESS'
export const GETUSEREVALUATIONS_ERROR = 'GETUSEREVALUATIONS_ERROR'
export const GETPROCESSSTEPS_SUCCESS = 'GETPROCESSSTEPS_SUCCESS'
export const GETPROCESSSTEPS_ERROR = 'GETPROCESSSTEPS_ERROR'
export const INSERTPROCESSSTEP_SUCCESS = 'INSERTPROCESSSTEP_SUCCESS'
export const INSERTPROCESSSTEP_ERROR = 'INSERTPROCESSSTEP_ERROR'
export const DELETEPROCESSSTEP_SUCCESS = 'DELETEPROCESSSTEP_SUCCESS'
export const DELETEPROCESSSTEP_ERROR = 'DELETEPROCESSSTEP_ERROR'
export const UPDATEPROCESSSTEP_SUCCESS = 'UPDATEPROCESSSTEP_SUCCESS'
export const UPDATEPROCESSSTEP_ERROR = 'UPDATEPROCESSSTEP_ERROR'
export const GETATTACHMENTS_SUCCESS = 'GETATTACHMENTS_SUCCESS'
export const GETATTACHMENTS_ERROR = 'GETATTACHMENTS_ERROR'
export const INSERTATTACHMENT_SUCCESS = 'INSERTATTACHMENT_SUCCESS'
export const INSERTATTACHMENT_ERROR = 'INSERTATTACHMENT_ERROR'
export const DELETEATTACHMENT_SUCCESS = 'DELETEATTACHMENT_SUCCESS'
export const DELETEATTACHMENT_ERROR = 'DELETEATTACHMENT_ERROR'
export const GETREMINDERS_SUCCESS = 'GETREMINDERS_SUCCESS'
export const GETREMINDERS_ERROR = 'GETREMINDERS_ERROR'
export const INSERTREMINDER_SUCCESS = 'INSERTREMINDER_SUCCESS'
export const INSERTREMINDER_ERROR = 'INSERTREMINDER_ERROR'
export const DELETEREMINDER_SUCCESS = 'DELETEREMINDER_SUCCESS'
export const DELETEREMINDER_ERROR = 'DELETEREMINDER_ERROR'
export const UPDATEREMINDER_SUCCESS = 'UPDATEREMINDER_SUCCESS'
export const UPDATEREMINDER_ERROR = 'UPDATEREMINDER_ERROR'
export const INSERTREMINDEREMAIL_SUCCESS = 'INSERTREMINDEREMAIL_SUCCESS'
export const INSERTREMINDEREMAIL_ERROR = 'INSERTREMINDEREMAIL_ERROR'
export const DELETEREMINDEREMAIL_SUCCESS = 'DELETEREMINDEREMAIL_SUCCESS'
export const DELETEREMINDEREMAIL_ERROR = 'DELETEREMINDEREMAIL_ERROR'
export const UPDATEREMINDEREMAIL_SUCCESS = 'UPDATEREMINDEREMAIL_SUCCESS'
export const UPDATEREMINDEREMAIL_ERROR = 'UPDATEREMINDEREMAIL_ERROR'
export const GETPOLICIES_SUCCESS = 'GETPOLICIES_SUCCESS'
export const GETPOLICIES_ERROR = 'GETPOLICIES_ERROR'
export const INSERTPOLICY_SUCCESS = 'INSERTPOLICY_SUCCESS'
export const INSERTPOLICY_ERROR = 'INSERTPOLICY_ERROR'
export const DELETEPOLICY_SUCCESS = 'DELETEPOLICY_SUCCESS'
export const DELETEPOLICY_ERROR = 'DELETEPOLICY_ERROR'
export const UPDATEPOLICY_SUCCESS = 'UPDATEPOLICY_SUCCESS'
export const UPDATEPOLICY_ERROR = 'UPDATEPOLICY_ERROR'
export const INSERTGROUPPOLICY_SUCCESS = 'INSERTGROUPPOLICY_SUCCESS'
export const INSERTGROUPPOLICY_ERROR = 'INSERTGROUPPOLICY_ERROR'
export const DELETEGROUPPOLICY_SUCCESS = 'DELETEGROUPPOLICY_SUCCESS'
export const DELETEGROUPPOLICY_ERROR = 'DELETEGROUPPOLICY_ERROR'
export const GETGROUPS_SUCCESS = 'GETGROUPS_SUCCESS'
export const GETGROUPS_ERROR = 'GETGROUPS_ERROR'
export const INSERTGROUP_SUCCESS = 'INSERTGROUP_SUCCESS'
export const INSERTGROUP_ERROR = 'INSERTGROUP_ERROR'
export const DELETEGROUP_SUCCESS = 'DELETEGROUP_SUCCESS'
export const DELETEGROUP_ERROR = 'DELETEGROUP_ERROR'
export const UPDATEGROUP_SUCCESS = 'UPDATEGROUP_SUCCESS'
export const UPDATEGROUP_ERROR = 'UPDATEGROUP_ERROR'
export const GETMENUS_SUCCESS = 'GETMENUS_SUCCESS'
export const GETMENUS_ERROR = 'GETMENUS_ERROR'
export const INSERTMENU_SUCCESS = 'INSERTMENU_SUCCESS'
export const INSERTMENU_ERROR = 'INSERTMENU_ERROR'
export const DELETEMENU_SUCCESS = 'DELETEMENU_SUCCESS'
export const DELETEMENU_ERROR = 'DELETEMENU_ERROR'
export const UPDATEMENU_SUCCESS = 'UPDATEMENU_SUCCESS'
export const UPDATEMENU_ERROR = 'UPDATEMENU_ERROR'
export const INSERTUSER_SUCCESS = 'INSERTUSER_SUCCESS'
export const INSERTUSER_ERROR = 'INSERTUSER_ERROR'
export const UPDATEUSER_SUCCESS = 'UPDATEUSER_SUCCESS'
export const UPDATEUSER_ERROR = 'UPDATEUSER_ERROR'
export const GETUSERGROUP_SUCCESS = 'GETUSERGROUP_SUCCESS'
export const GETUSERGROUPSELECTED_SUCCESS = 'GETUSERGROUPSELECTED_SUCCESS'
export const GETUSERGROUP_ERROR = 'GETUSERGROUP_ERROR'
export const INSERTUSERGROUP_SUCCESS = 'INSERTUSERGROUP_SUCCESS'
export const INSERTUSERGROUP_ERROR = 'INSERTUSERGROUP_ERROR'
export const UPDATEUSERGROUP_SUCCESS = 'UPDATEUSERGROUP_SUCCESS'
export const UPDATEUSERGROUP_ERROR = 'UPDATEUSERGROUP_ERROR'
export const DELETEUSERGROUP_SUCCESS = 'DELETEUSERGROUP_SUCCESS'
export const DELETEUSERGROUP_ERROR = 'DELETEUSERGROUP_ERROR'
export const GETUSERPROFILE_SUCCESS = 'GETUSERPROFILE_SUCCESS'
export const GETUSERPROFILE_ERROR = 'GETUSERPROFILE_ERROR'
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS'
export const FORGOTPASSWORD_ERROR = 'FORGOTPASSWORD_ERROR'
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS'
export const RESETPASSWORD_ERROR = 'RESETPASSWORD_ERROR'
export const GETPGKNOWLEDGES_SUCCESS = 'GETPGKNOWLEDGES_SUCCESS'
export const GETPGKNOWLEDGES_ERROR = 'GETPGKNOWLEDGES_ERROR'
export const GETPGKNOWLEDGESLOOKUP_SUCCESS = 'GETPGKNOWLEDGESLOOKUP_SUCCESS'
export const GETPGKNOWLEDGESLOOKUP_ERROR = 'GETPGKNOWLEDGESLOOKUP_ERROR'
export const INSERTKNOWLEDGE_SUCCESS = 'INSERTKNOWLEDGE_SUCCESS'
export const INSERTKNOWLEDGE_ERROR = 'INSERTKNOWLEDGE_ERROR'
export const DELETEKNOWLEDGE_SUCCESS = 'DELETEKNOWLEDGE_SUCCESS'
export const DELETEKNOWLEDGE_ERROR = 'DELETEKNOWLEDGE_ERROR'
export const UPDATEKNOWLEDGE_SUCCESS = 'UPDATEKNOWLEDGE_SUCCESS'
export const UPDATEKNOWLEDGE_ERROR = 'UPDATEKNOWLEDGE_ERROR'
export const UPDATEPILLARLOOKUPSELECTION_SUCCESS = 'UPDATEPILLARLOOKUPSELECTION_SUCCESS'
export const UPDATEKNOWLEDGELOOKUPSELECTION_SUCCESS = 'UPDATEKNOWLEDGELOOKUPSELECTION_SUCCESS'
export const GETCONTENTS_SUCCESS = 'GETCONTENTS_SUCCESS'
export const GETCONTENTS_ERROR = 'GETCONTENTS_ERROR'
export const INSERTCONTENT_SUCCESS = 'INSERTCONTENT_SUCCESS'
export const INSERTCONTENT_ERROR = 'INSERTCONTENT_ERROR'
export const DELETECONTENT_SUCCESS = 'DELETECONTENT_SUCCESS'
export const DELETECONTENT_ERROR = 'DELETECONTENT_ERROR'
export const UPDATECONTENT_SUCCESS = 'UPDATECONTENT_SUCCESS'
export const UPDATECONTENT_ERROR = 'UPDATECONTENT_ERROR'
export const UPDATECONTENTLOOKUPSELECTION_SUCCESS = 'UPDATECONTENTLOOKUPSELECTION_SUCCESS'
export const GETORGLEARNINGOBJECTTREE_SUCCESS = 'GETORGLEARNINGOBJECTTREE_SUCCESS'
export const GETORGLEARNINGOBJECTTREE_ERROR = 'GETORGLEARNINGOBJECTTREE_ERROR'
export const INSERTCATALOG_SUCCESS = 'INSERTCATALOG_SUCCESS'
export const INSERTCATALOG_ERROR = 'INSERTCATALOG_ERROR'
export const DELETECATALOG_SUCCESS = 'DELETECATALOG_SUCCESS'
export const DELETECATALOG_ERROR = 'DELETECATALOG_ERROR'
export const UPDATECATALOG_SUCCESS = 'UPDATECATALOG_SUCCESS'
export const UPDATECATALOG_ERROR = 'UPDATECATALOG_ERROR'
export const GETTESTLIBRARIES_SUCCESS = 'GETTESTLIBRARIES_SUCCESS'
export const GETTESTLIBRARIES_ERROR = 'GETTESTLIBRARIES_ERROR'
export const INSERTTESTLIBRARY_SUCCESS = 'INSERTTESTLIBRARY_SUCCESS'
export const INSERTTESTLIBRARY_ERROR = 'INSERTTESTLIBRARY_ERROR'
export const DELETETESTLIBRARY_SUCCESS = 'DELETETESTLIBRARY_SUCCESS'
export const DELETETESTLIBRARY_ERROR = 'DELETETESTLIBRARY_ERROR'
export const UPDATETESTLIBRARY_SUCCESS = 'UPDATETESTLIBRARY_SUCCESS'
export const UPDATETESTLIBRARY_ERROR = 'UPDATETESTLIBRARY_ERROR'
export const INSERTTESTINSTANCE_SUCCESS = 'INSERTTESTINSTANCE_SUCCESS'
export const INSERTTESTINSTANCE_ERROR = 'INSERTTESTINSTANCE_ERROR'
export const DELETETESTINSTANCE_SUCCESS = 'DELETETESTINSTANCE_SUCCESS'
export const DELETETESTINSTANCE_ERROR = 'DELETETESTINSTANCE_ERROR'
export const UPDATETESTINSTANCE_SUCCESS = 'UPDATETESTINSTANCE_SUCCESS'
export const UPDATETESTINSTANCE_ERROR = 'UPDATETESTINSTANCE_ERROR'
export const INSERTTESTQUESTION_SUCCESS = 'INSERTTESTQUESTION_SUCCESS'
export const INSERTTESTQUESTION_ERROR = 'INSERTTESTQUESTION_ERROR'
export const DELETETESTQUESTION_SUCCESS = 'DELETETESTQUESTION_SUCCESS'
export const DELETETESTQUESTION_ERROR = 'DELETETESTQUESTION_ERROR'
export const UPDATETESTQUESTION_SUCCESS = 'UPDATETESTQUESTION_SUCCESS'
export const UPDATETESTQUESTION_ERROR = 'UPDATETESTQUESTION_ERROR'
export const INSERTTESTANSWER_SUCCESS = 'INSERTTESTANSWER_SUCCESS'
export const INSERTTESTANSWER_ERROR = 'INSERTTESTANSWER_ERROR'
export const DELETETESTANSWER_SUCCESS = 'DELETETESTANSWER_SUCCESS'
export const DELETETESTANSWER_ERROR = 'DELETETESTANSWER_ERROR'
export const UPDATETESTANSWER_SUCCESS = 'UPDATETESTANSWER_SUCCESS'
export const UPDATETESTANSWER_ERROR = 'UPDATETESTANSWER_ERROR'
export const UPDATEASSESSMENTLOOKUPSELECTION_SUCCESS = 'UPDATEASSESSMENTLOOKUPSELECTION_SUCCESS'
export const UPDATENOTIFICATIONMESSAGELOOKUPSELECTION_SUCCESS = 'UPDATENOTIFICATIONMESSAGELOOKUPSELECTION_SUCCESS'
export const GETPGKNOWLEDGEINSTANCES_SUCCESS = 'GETPGKNOWLEDGEINSTANCES_SUCCESS'
export const GETPGKNOWLEDGEINSTANCES_ERROR = 'GETPGKNOWLEDGEINSTANCES_ERROR'
export const INSERTKNOWLEDGEINSTANCE_SUCCESS = 'INSERTKNOWLEDGEINSTANCE_SUCCESS'
export const INSERTKNOWLEDGEINSTANCE_ERROR = 'INSERTKNOWLEDGEINSTANCE_ERROR'
export const INSERTKNOWLEDGEINSTANCERECURRING_SUCCESS = 'INSERTKNOWLEDGEINSTANCERECURRING_SUCCESS'
export const INSERTKNOWLEDGEINSTANCERECURRING_ERROR = 'INSERTKNOWLEDGEINSTANCERECURRING_ERROR'
export const DELETEKNOWLEDGEINSTANCE_SUCCESS = 'DELETEKNOWLEDGEINSTANCE_SUCCESS'
export const DELETEKNOWLEDGEINSTANCE_ERROR = 'DELETEKNOWLEDGEINSTANCE_ERROR'
export const UPDATEKNOWLEDGEINSTANCE_SUCCESS = 'UPDATEKNOWLEDGEINSTANCE_SUCCESS'
export const UPDATEKNOWLEDGEINSTANCE_ERROR = 'UPDATEKNOWLEDGEINSTANCE_ERROR'
export const GETFACILITIES_SUCCESS = 'GETFACILITIES_SUCCESS'
export const GETFACILITIES_ERROR = 'GETFACILITIES_ERROR'
export const INSERTFACILITY_SUCCESS = 'INSERTFACILITY_SUCCESS'
export const INSERTFACILITY_ERROR = 'INSERTFACILITY_ERROR'
export const DELETEFACILITY_SUCCESS = 'DELETEFACILITY_SUCCESS'
export const DELETEFACILITY_ERROR = 'DELETEFACILITY_ERROR'
export const UPDATEFACILITY_SUCCESS = 'UPDATEFACILITY_SUCCESS'
export const UPDATEFACILITY_ERROR = 'UPDATEFACILITY_ERROR'
export const INSERTFACILITYROOM_SUCCESS = 'INSERTFACILITYROOM_SUCCESS'
export const INSERTFACILITYROOM_ERROR = 'INSERTFACILITYROOM_ERROR'
export const UPDATEFACILITYROOM_SUCCESS = 'UPDATEFACILITYROOM_SUCCESS'
export const UPDATEFACILITYROOM_ERROR = 'UPDATEFACILITYROOM_ERROR'
export const DELETEFACILITYROOM_SUCCESS = 'DELETEFACILITYROOM_SUCCESS'
export const DELETEFACILITYROOM_ERROR = 'DELETEFACILITYROOM_ERROR'
export const UPDATEFACILITYROOMLOOKUPSELECTION_SUCCESS = 'UPDATEFACILITYROOMLOOKUPSELECTION_SUCCESS'
export const GETPGKNOWLEDGESESSIONS_SUCCESS = 'GETPGKNOWLEDGESESSIONS_SUCCESS'
export const GETPGKNOWLEDGESESSIONS_ERROR = 'GETPGKNOWLEDGESESSIONS_ERROR'
export const UPDATEKNOWLEDGESESSION_SUCCESS = 'UPDATEKNOWLEDGESESSION_SUCCESS'
export const UPDATEKNOWLEDGESESSION_ERROR = 'UPDATEKNOWLEDGESESSION_ERROR'
export const PROCESSENROLLMENT_SUCCESS = 'PROCESSENROLLMENT_SUCCESS'
export const PROCESSENROLLMENT_ERROR = 'PROCESSENROLLMENT_ERROR'
export const PROCESSENROLLMENTCLEAR_SUCCESS = 'PROCESSENROLLMENTCLEAR_SUCCESS'
export const PROCESSENROLLMENTCLEAR_ERROR = 'PROCESSENROLLMENTCLEAR_ERROR'
export const GETPGCERTIFICATIONS_SUCCESS = 'GETPGCERTIFICATIONS_SUCCESS'
export const GETPGCERTIFICATIONS_ERROR = 'GETPGCERTIFICATIONS_ERROR'
export const GETPGCERTIFICATIONSLOOKUP_SUCCESS = 'GETPGCERTIFICATIONSLOOKUP_SUCCESS'
export const GETPGCERTIFICATIONSLOOKUP_ERROR = 'GETPGCERTIFICATIONSLOOKUP_ERROR'
export const INSERTCERTIFICATION_SUCCESS = 'INSERTCERTIFICATION_SUCCESS'
export const INSERTCERTIFICATION_ERROR = 'INSERTCERTIFICATION_ERROR'
export const DELETECERTIFICATION_SUCCESS = 'DELETECERTIFICATION_SUCCESS'
export const DELETECERTIFICATION_ERROR = 'DELETECERTIFICATION_ERROR'
export const UPDATECERTIFICATION_SUCCESS = 'UPDATECERTIFICATION_SUCCESS'
export const UPDATECERTIFICATION_ERROR = 'UPDATECERTIFICATION_ERROR'
export const UPDATECERTIFICATIONLOOKUPSELECTION_SUCCESS = 'UPDATECERTIFICATIONLOOKUPSELECTION_SUCCESS'
export const GETPGKNOWLEDGESESSIONSBYUSER_SUCCESS = 'GETPGKNOWLEDGESESSIONSBYUSER_SUCCESS'
export const GETPGKNOWLEDGESESSIONSBYUSER_ERROR = 'GETPGKNOWLEDGESESSIONSBYUSER_ERROR'
export const GETUSERCALENDAR_SUCCESS = 'GETUSERCALENDAR_SUCCESS'
export const GETUSERCALENDAR_ERROR = 'GETUSERCALENDAR_ERROR'
export const GETKNOWLEDGESESSIONEVALUATION_SUCCESS = 'GETKNOWLEDGESESSIONEVALUATION_SUCCESS'
export const GETKNOWLEDGESESSIONEVALUATION_ERROR = 'GETKNOWLEDGESESSIONEVALUATION_ERROR'
export const UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_SUCCESS = 'UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_SUCCESS'
export const UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_ERROR = 'UPDATEKNOWLEDGESESSIONEVALUATIONRESPONSES_ERROR'
export const UPDATEKNOWLEDGESESSIONOBJECTIVE_SUCCESS = 'UPDATEKNOWLEDGESESSIONOBJECTIVE_SUCCESS'
export const UPDATEKNOWLEDGESESSIONOBJECTIVE_ERROR = 'UPDATEKNOWLEDGESESSIONOBJECTIVE_ERROR'
export const INSERTKNOWLEDGESESSIONASSESSMENT_SUCCESS = 'INSERTKNOWLEDGESESSIONASSESSMENT_SUCCESS'
export const INSERTKNOWLEDGESESSIONASSESSMENT_ERROR = 'INSERTKNOWLEDGESESSIONASSESSMENT_ERROR'
export const GETKNOWLEDGESESSIONASSESSMENT_SUCCESS = 'GETKNOWLEDGESESSIONASSESSMENT_SUCCESS'
export const GETKNOWLEDGESESSIONASSESSMENT_ERROR = 'GETKNOWLEDGESESSIONASSESSMENT_ERROR'
export const GETSCORMFORLAUNCH_SUCCESS = 'GETSCORMFORLAUNCH_SUCCESS'
export const GETSCORMFORLAUNCH_ERROR = 'GETSCORMFORLAUNCH_ERROR'
export const GETSCORMUSERSESSION_SUCCESS = 'GETSCORMUSERSESSION_SUCCESS'
export const GETSCORMUSERSESSION_ERROR = 'GETSCORMUSERSESSION_ERROR'
export const INSERTSCORMUSERSESSION_SUCCESS = 'INSERTSCORMUSERSESSION_SUCCESS'
export const INSERTSCORMUSERSESSION_ERROR = 'INSERTSCORMUSERSESSION_ERROR'
export const GETAICCUSERSESSION_SUCCESS = 'GETAICCUSERSESSION_SUCCESS'
export const GETAICCUSERSESSION_ERROR = 'GETAICCUSERSESSION_ERROR'
export const INSERTAICCUSERSESSION_SUCCESS = 'INSERTAICCUSERSESSION_SUCCESS'
export const INSERTAICCUSERSESSION_ERROR = 'INSERTAICCUSERSESSION_ERROR'
