import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getCertificationsLookup, assignLookupSelection } from '../../store/actions/entityActions';
import { UserLookup, Rights, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant } from '../../config/Constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import AlertMessage from '../layout/AlertMessage';

const CertificationLookupList = (props) => {
    const { auth, companyData, profile, certificationsLookup, userattributelookups, lookupselections, loadingMenu, listby } = props;    
    const location  = useLocation();
    const navigate = useNavigate();
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [certificationID, setCertificationID] = useState(-1);
    const [certificationName, setCertificationName] = useState('');


    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const certification = certificationsLookup?.certificationsLookup?.Certifications;

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }
    
    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Inactive', value: '2' },
        { name: 'All', value: '3' },
    ];

    function initialRadioValue() {
        return '1';
    };
  
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = certificationsLookup?.certificationsLookup?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getCertificationsLookup(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = certificationsLookup?.certificationsLookup?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };           
        await props.getCertificationsLookup(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
     
    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.CertificationID + '=' + row.CertificationName ;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.Certification, currentSelectedValue);
        return currentSelectedValue;
    }
    

    function initialTabValue() {
        return 1;
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }


    async function searchCertifications() {
        setLoading(true);
        const oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getCertificationsLookup(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (certification) => {
        return certification?.map((kRec) => ({
            CertificationID:kRec.CertificationID, 
            CertificationName:kRec.CertificationName, 
            takeAction:false,
            showDetails:false,
            editCertificationClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(certification);
        setSliderValue(initialScoreValues);
        ClearPreviousSelection();
    }, [certification])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function ClearPreviousSelection() {
        await props.assignLookupSelection(MenuType.Certification, '');
    }

    function getSearchParamsEx(bRadioValue, pageNumber)  {
        let oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(bRadioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 
        return oParams;
    }

    async function searchCertifications(bRadioValue) {
        setLoading(true);
        const oParams = getSearchParamsEx(bRadioValue, '1');
        await props.getCertificationsLookup(oParams);
        setLoading(false);
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.CertificationID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.CertificationID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    async function handleRadioChange(e) {
        setRadioValue(e.currentTarget.value);
        searchCertifications(e.currentTarget.value);
    }

    function displayListRadioButtons() {
        return (
            radios.map((radio, idx) => (
                <ToggleButton
                    key={'checklisttype' + idx}
                    id={`radio-${'checklisttype' + idx}`}
                    type="radio"
                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    size='sm'
                    onChange={(e) => handleRadioChange(e)}
                >
                    {radio.name}
                </ToggleButton>
            ))
        );
    }

    function displayMyTeamHeader() {
            return (
                <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                <tbody>
                <tr>
                    <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Certifications</h3></td>
                    <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                        <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Certification'/>
                            <a onClick={() => searchCertifications()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                            <ButtonGroup className="mb-2" size='sm'>
                                {displayListRadioButtons()}
                            </ButtonGroup>                                                                   
                        </div>                                            
                    </td>
                </tr>
                </tbody>
            </Table>                            
            );    
        }
    
    return (
        <>
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                            <Card.Subtitle>
                            {(showAlertMessage) ?
                                <div>
                                    <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} handleParentAlertClose={handleCloseAlert} /></h5>
                                </div>
                                :
                                    <></>
                                }
                            </Card.Subtitle>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {((!certification) || (certification.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Certifications.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextLeft}>Name</th>
                                                <th style={styles.tableText}>Active</th>
                                                <th style={styles.tableText}>Expires</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {certification && certification.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{assRec?.CertificationID}</td>
                                                        <td style={styles.tableTextLeft}>{assRec?.CertificationName}</td>
                                                        <td style={styles.tableText}>{(assRec?.IsDeleted) ? 'Yes' : 'No'}</td>
                                                        <td style={styles.tableText}>{(assRec?.Expires) ? 'Yes' : 'No'}</td>
                                                        <td style={styles.tableText}>
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" 
                                                                    checked={getDetailSwitchValue(assRec?.CertificationID)} id={`switch${'detail' + assRec?.CertificationID}`} 
                                                                    style={(getDetailSwitchValue(assRec?.CertificationID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                    onChange={(e) => {handleSwitchDetailChange(assRec?.CertificationID)}}>
                                                                </input>
                                                                <label class="form-check-label" for={`switch${'detail' + assRec?.CertificationID}`}>Select</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{certificationsLookup?.certificationsLookup?.RecordFound?.toLocaleString()}   Certifications&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(certificationsLookup?.certificationsLookup?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(certificationsLookup?.certificationsLookup?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {certificationsLookup?.certificationsLookup?.PagingMetaData?.PageNumber}  of  {certificationsLookup?.certificationsLookup?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      certificationsLookup: state.entity.certificationsLookup, 
      userattributelookups: state.entity.userattributelookups,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCertificationsLookup: (kParams) => dispatch(getCertificationsLookup(kParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificationLookupList)