import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, Form  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getContents, getUserAttributesLookups, insertContent, deleteContent, updateContent, insertContentForm,
        assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { PermissionType, AuditEntityType, AcceptableAttachmentsType, MenuType } from '../../config/Constant';
import AuditTrail from '../layout/AuditTrail';

const ContentList = (props) => {
    const { auth, companyData, profile, contents, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewContent, setAddNewContent] = useState(false);
    const [addNewGroupContent, setAddNewGroupContent] = useState(false);
    const [editContent, setEditContent] = useState(false);
    const [contentID, setContentID] = useState(-1);
    const [contentDescription, setContentDescription] = useState('');
    const [contentActive, setContentActive] = useState(-1);
    const [contentBulkUpload, setContentBulkUpload] = useState(-1);
    const [contentBulkUploadType, setContentBulkUploadType] = useState(-1);
    const [EditedContentID, setEditedContentID] = useState(-1);
    const [EditedGroupContentID, setEditedGroupContentID] = useState(-1);
    const [uploadBulkFile, setUploadBulkFile] = useState(null);
    const [uploadManifestFile, setUploadManifestFile] = useState(null);
    const [uploadZipFile, setUploadZipFile] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');

    const content = contents?.contents?.Contents;

    const [auditEntityID, setAuditEntityID] = useState(-1);
    const [showAudit, setShowAudit] = useState(false);
    const handleCloseAudit = () => {setShowAudit(false); }
    function handleShowAudit() {
        setShowAudit(true); 
    };
    async function handleAuditClick(iID) {
        setLoading(true);
        setAuditEntityID(iID);
        handleShowAudit();
        setLoading(false);
    }    

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = contents?.contents?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            Title:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getContents(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = contents?.contents?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            Title:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getContents(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.ContentID + '=' + row.Title ;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.Content, currentSelectedValue);
        return currentSelectedValue;
    }
    
    async function searchContents() {
        setLoading(true);
        const oParams = {
            Title:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (entity) => {
        return content?.map((oRec) => ({
            ContentID:oRec.ScormID, 
            Title:oRec.Title, 
            EditedContentID:-1,
            takeAction:false,
            showDetails:false,
            disableNewContent:false,
            newContentClicked:false,
            editContentClicked:false,
            disableNewGroupContent:false,
            newGroupContentClicked:false,
            editGroupContentClicked:false,
            EditedGroupContentID:-1,
            IsUser:false,
            PermissionTypeID:-1,
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(content);
        setSliderValue(initialScoreValues);
    }, [content])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleBulkFileOnChange (e) {
        setUploadBulkFile(e.target.files[0]);
    }

    function handleManifestFileOnChange (e) {
        setUploadManifestFile(e.target.files[0]);
    }

    function handleZipFileOnChange (e) {
        setUploadZipFile(e.target.files[0]);
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewContent) ? setAddNewContent(false) : setAddNewContent(true);
            setContentID(-1);
            setContentDescription('');
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEntity) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.ContentID === currentEntity?.ContentID) {
                    if (tab === 2) {
                        row.disableNewContent = false;                    
                        row.newContentClicked = false;
                        row.editContentClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setContentID(-1);
            setEditedContentID(-1);
            setEditedGroupContentID(-1);
            setContentDescription('');
            setAddNewContent(false);
            setEditContent(false);
            setAddNewGroupContent(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                SCORMID : -1,
                Title : contentDescription,
                CreatedByUserID : auth?.UserID,
            };        
            console.log(oIParams);
            await props.insertContent(oIParams);    
        } 
        
        const oParams = {
            Title:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);        
        setContentID(-1);
        setEditedGroupContentID(-1);
        setContentDescription('');
        setAddNewContent(false);
        setEditContent(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            if (EditedContentID > 0) {
                const oUParams  = {
                    SCORMID : -1,
                    Title : contentDescription,
                    UpdatedByUserID : auth?.UserID,
                };        
                await props.updateContent(oUParams);    
            } else {
                if ((uploadManifestFile) || (uploadZipFile)) {
                    const formData = new FormData();
                    if (uploadManifestFile) {
                        formData.append("attachment", uploadManifestFile);
                        formData.append("FileSize", uploadManifestFile[0]?.size);
                        formData.append("ContentFileExt", uploadManifestFile?.name.slice((uploadManifestFile?.name.lastIndexOf(".") - 1 >>> 0) + 2));    
                        formData.append("IsZipFile", false);
                        formData.append("IsManifestFile", true);
                        formData.append("IsExcelFile", false);
                    } else if (uploadZipFile) {
                        formData.append("attachment", uploadZipFile);
                        formData.append("FileSize", uploadZipFile[0]?.size);
                        formData.append("ContentFileExt", uploadZipFile?.name.slice((uploadZipFile?.name.lastIndexOf(".") - 1 >>> 0) + 2));    
                        formData.append("IsZipFile", true);
                        formData.append("IsManifestFile", false);
                        formData.append("IsExcelFile", false);
                    }
                    formData.append("SCORMID", -1);
                    formData.append("Title", contentDescription);
                    formData.append("UserID", auth?.UserID);
                    formData.append("CreatedByUserID", auth?.UserID);
                    formData.append("CompanyID", companyData?.CompanyID);
                    console.log(formData);
                    await props.insertContentForm(formData);
                } else {
                    const oUParams  = {
                        ContentID : EditedContentID,
                        ContentDescription : contentDescription,
                        CreatedByUserID : auth?.UserID,
                    };        
                    await props.insertContent(oUParams);    
                }
            }
            setContentID(-1);
            setEditedContentID(-1);
            setContentDescription('');
            setAddNewContent(false);
            setEditContent(false);    
        } 
   
        const oParams = {
            Title:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = content?.filter((oRec) => (oRec.ContentID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setContentID(id);
                setEditedContentID(id);
                setContentDescription((oEdited[0]?.ContentDescription) ? oEdited[0]?.ContentDescription : '');
            }

            setEditContent(true);
        }  
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                SCORMID: id,
            };        
            console.log(oParams);
            await props.deleteContent(oParams);    
        } 
        
        const oParams = {
            Title:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.ContentID === rowIndex) {
                if (tab === 1) {
                    row.GroupID = value;                    
                } else if (tab === 2) {
                    row.PermissionTypeID = value;
                } else if (tab === 3) {
                    row.IsUser = (value === '1') ? true : false;
                } 
            }        
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function checkMainContent(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {                       
                return (
                    <div>
                        <a onClick={() => handleAuditClick(id)}>
                            <span style={styles.ActionIconGreyBold} class="material-symbols-outlined" title='Show Audit'>history_2</span>
                        </a>
                        <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Content'>edit</span>
                        </a>
                        <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Content'>delete</span>
                        </a>
                    </div>
                );      
            } else {
                return (<></>)
            }
        } else {
            return (
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" 
                        checked={getDetailSwitchValue(id)} id={`switch${'odetail' + id}`} 
                        style={(getDetailSwitchValue(id)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                        onChange={(e) => {handleSwitchDetailChange(id)}}>
                    </input>
                    <label class="form-check-label" htmlFor={`switch${'detail' + id}`}>Select</label>
                </div>
            )
        }
    }

    function displayNewContent() {
        if ((!fromLookup) && ((addNewContent) || (editContent))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {(addNewContent) ? 'New Content' : 'Edit Content'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Title</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={contentDescription} onChange={(e) => (setContentDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="assactive" style={styles.LabelDataEntryNoPadding}>Active</label>
                                    <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={contentActive} onChange={(e) => (setContentActive(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Form.Group controlId="formFileMan" className="mb-3">
                                        <Form.Label style={styles.LabelDataEntryNoPadding}>Manifest file</Form.Label>
                                        <Form.Control style={styles.TextDataEntryNoPaddingWBG} type="file" onChange={handleManifestFileOnChange}
                                            accept={AcceptableAttachmentsType.XML}
                                        />
                                    </Form.Group>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Form.Group controlId="formFileZip" className="mb-3">
                                        <Form.Label style={styles.LabelDataEntryNoPadding}>Zip file</Form.Label>
                                        <Form.Control style={styles.TextDataEntryNoPaddingWBG} type="file" onChange={handleZipFileOnChange}
                                            accept={AcceptableAttachmentsType.ZIP}
                                        />
                                    </Form.Group>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="bulkupload" style={styles.LabelDataEntryNoPadding}>Bulk Upload</label>
                                    <select id="bulkupload" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={contentBulkUpload} onChange={(e) => (setContentBulkUpload(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="bulkuploadtype" style={styles.LabelDataEntryNoPadding}>Bulk Type</label>
                                    <select id="bulkuploadtype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={contentBulkUploadType} onChange={(e) => (setContentBulkUploadType(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Scorm</option>        
                                        <option value='2'>Assessment</option>        
                                        <option value='2'>Web Based</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Form.Group controlId="formFileBulk" className="mb-3">
                                        <Form.Label style={styles.LabelDataEntryNoPadding}>Bulk Upload</Form.Label>
                                        <Form.Control style={styles.TextDataEntryNoPaddingWBG} type="file" onChange={handleBulkFileOnChange}
                                            accept={AcceptableAttachmentsType.XLS}
                                        />
                                    </Form.Group>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col-auto">
                                        <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Content'>Save</Button>
                                    </div>
                                    <div class="col-auto">
                                        <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                    </div>
                                </div>
                            </td>                        
                        </tr>  
                    </tbody>
                </table>                
                </Card.Body>
                </Card>                   
            );
        }
    }

    function displayAddMainContent() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewContent) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Content'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Content'>note_add</span>
                        </a>
                    );    
                }
                return (<></>);
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Knowledge Content</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Content'/>
                        <a onClick={() => searchContents()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainContent()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.ContentID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.ContentID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    return (
        <>
        <AuditTrail showFromAudit={showAudit} handleParentAuditClose={handleCloseAudit} AuditEntityTypeID={AuditEntityType.Content} EntityID={auditEntityID}/>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewContent()}                                               
                            {((!content) || (content.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Knowledge Content.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Title</th>
                                                <th style={styles.tableText}>Root Folder</th>
                                                <th style={styles.tableText}>Usage</th>
                                                <th style={styles.tableText}>Tracks</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {content && content.map((oRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.ScormID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.Title}</td>
                                                        <td style={styles.tableTextLeft}>{oRec?.RootFolder}</td>
                                                        <td style={styles.tableTextLeft}>{oRec?.Usage?.toLocaleString()}</td>
                                                        <td style={styles.tableTextLeft}>{oRec?.CourseUsageCount?.toLocaleString()}</td>
                                                        <td style={styles.tableText10}>{checkMainContent(1, oRec?.ScormID, -1)}</td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{contents?.contents?.RecordFound?.toLocaleString()}   Knowledge Content&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(contents?.contents?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(contents?.contents?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {contents?.contents?.PagingMetaData?.PageNumber}  of  {contents?.contents?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      contents: state.entity?.contents, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContents: (oParams) => dispatch(getContents(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertContent: (oParams) => dispatch(insertContent(oParams)),
        insertContentForm: (oParams) => dispatch(insertContentForm(oParams)),
        deleteContent: (oParams) => dispatch(deleteContent(oParams)),
        updateContent: (oParams) => dispatch(updateContent(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentList)