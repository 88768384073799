import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getNotificationMessages, getUserAttributesLookups, insertNotificationMessage, deleteNotificationMessage, updateNotificationMessage, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getPermissionFlags } from '../layout/Utils';
import moment from 'moment';
import { MenuType } from '../../config/Constant';

const NotificationMessageList = (props) => {
    const { auth, companyData, profile, notificationmessages, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewNotificationMessage, setAddNewNotificationMessage] = useState(false);
    const [editNotificationMessage, setEditNotificationMessage] = useState(false);
    const [notificationMessageID, setNotificationMessageID] = useState(-1);
    const [notificationMessageName, setNotificationMessageName] = useState('');
    const [EditedNotificationMessageID, setEditedNotificationMessageID] = useState(-1);
    const [notificationMessageDescription, setNotificationMessageDescription] = useState('');
    const [messageSubject, setMessageSubject] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [messageSubjectResourceKey, setMessageSubjectResourceKey] = useState('');
    const [messageBodyResourceKey, setMessageBodyResourceKey] = useState('');
    const [isGlobalAnnouncement, setIsGlobalAnnouncement] = useState(0);
    const [globalAnnouncementStartDate, setGlobalAnnouncementStartDate] = useState(null);
    const [globalAnnouncementEndDate, setGlobalAnnouncementEndDate] = useState(null);
    const [globalAnnouncementExternalLink, setGlobalAnnouncementExternalLink] = useState('');
    const [globalAnnouncementShowLogins, setGlobalAnnouncementShowLogins] = useState(0);
    const [policyID, setPolicyID] = useState(-1);
    const [notificationTypeID, setNotificationTypeID] = useState(-1);
    const [selectedValue, setSelectedValue] = useState('');

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const notificationmessage = notificationmessages?.notificationmessages?.NotificationMessages;

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = notificationmessages?.notificationmessages?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            NotificationMessageName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getNotificationMessages(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = notificationmessages?.notificationmessages?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            NotificationMessageName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getNotificationMessages(oParams);
        setLoading(false);
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (parseInt(row.NotificationMessageID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
  
    function handleSwitchDetailChange(rowIndex, rowName) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.NotificationMessageID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.NotificationMessageID + '=' + row.NotificationMessageName;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.NotificationMessage, currentSelectedValue);
        return currentSelectedValue;
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    async function searchNotificationMessages() {
        setLoading(true);
        const oParams = {
            NotificationMessageName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        // const oParams  = {
        //     UserID:auth.UserID,
        //     SearchFilter:nodeName,
        // };        
        await props.getNotificationMessages(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (evaluation) => {
        return notificationmessage?.map((oRec) => ({
            NotificationMessageID:oRec.NotificationMessageID, 
            NotificationMessageName:oRec.NotificationMessageName, 
            EditedNotificationMessageID:-1,
            takeAction:false,
            showDetails:false,
            disableNewEvaluation:false,
            newEvaluationClicked:false,
            editEvaluationClicked:false,
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(notificationmessage);
        setSliderValue(initialScoreValues);
        ClearPreviousSelection();
    }, [notificationmessage])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function ClearPreviousSelection() {
        await props.assignLookupSelection(MenuType.NotificationMessage, '');
    }

    function handleEditorChange(oEditorState) {
        setEditorState(oEditorState);
    }

    function getHTMLFromEditor() {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    function setEditorHTML(isEmpty, value) {
        if (isEmpty) {
            setEditorState(EditorState.createEmpty());
        } else {
            // console.log(value);
            const contentBlock = htmlToDraft(value);
            // console.log(contentBlock);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const oEditorState = EditorState.createWithContent(contentState);
            //   console.log(oEditorState);
              setEditorState(oEditorState);
            }
        }
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewNotificationMessage) ? setAddNewNotificationMessage(false) : setAddNewNotificationMessage(true);
            setNotificationMessageID(-1);
            setNotificationMessageName('');
            setNotificationMessageDescription('');
            setMessageSubject('');
            setMessageBody('');
            setEditorHTML(true, null)
            setMessageSubjectResourceKey('');
            setMessageBodyResourceKey('');
            setPolicyID(-1);
            setIsGlobalAnnouncement('0');
            setGlobalAnnouncementStartDate(null);
            setGlobalAnnouncementEndDate(null);
            setGlobalAnnouncementExternalLink('');
            setGlobalAnnouncementShowLogins(0);
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.NotificationMessageID === currentEval?.NotificationMessageID) {
                    if (tab === 2) {
                        row.disableNewEvaluation = false;                    
                        row.newEvaluationClicked = false;
                        row.editEvaluationClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setNotificationMessageID(-1);
            setEditedNotificationMessageID(-1);
            setNotificationMessageName('');
            setNotificationMessageDescription('');
            setMessageSubject('');
            setMessageBody('');
            setEditorHTML(true, null)
            setMessageSubjectResourceKey('');
            setMessageBodyResourceKey('');
            setPolicyID(-1);
            setIsGlobalAnnouncement('0');
            setGlobalAnnouncementStartDate(null);
            setGlobalAnnouncementEndDate(null);
            setGlobalAnnouncementExternalLink('');
            setGlobalAnnouncementShowLogins(0);
            setAddNewNotificationMessage(false);
            setEditNotificationMessage(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                NotificationMessageID : -1,
                NotificationMessageName : notificationMessageName,
                Description : notificationMessageDescription,
                MessageSubject: messageSubject,
                MessageBody : getHTMLFromEditor(),
                MessageSubjectResourceKey : messageSubjectResourceKey,
                MessageBodyResourceKey : messageBodyResourceKey,
                IsGlobalAnnouncement : ((isGlobalAnnouncement === '1') ? true : false),
                GlobalAnnouncementStartDate: globalAnnouncementStartDate ? moment(globalAnnouncementStartDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                GlobalAnnouncementEndDate: globalAnnouncementEndDate ? moment(globalAnnouncementEndDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                GlobalAnnouncementExternalLink : globalAnnouncementExternalLink,
                GlobalAnnouncementShowLogins : globalAnnouncementShowLogins
            };        
            // console.log(oIParams);
            await props.insertNotificationMessage(oIParams);    
        } 

        // const oParams  = {
        //     UserID:auth.UserID,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            NotificationMessageName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);        
        setNotificationMessageID(-1);
        setNotificationMessageName('');
        setNotificationMessageDescription('');
        setMessageSubject('');
        setMessageBody('');
        setEditorHTML(true, null)
        setMessageSubjectResourceKey('');
        setMessageBodyResourceKey('');
        setPolicyID(-1);
        setIsGlobalAnnouncement('0');
        setGlobalAnnouncementStartDate(null);
        setGlobalAnnouncementEndDate(null);
        setGlobalAnnouncementExternalLink('');
        setGlobalAnnouncementShowLogins(0);
        setAddNewNotificationMessage(false);
        setEditNotificationMessage(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const oUParams  = {
                NotificationMessageID : EditedNotificationMessageID,
                NotificationMessageName : notificationMessageName,
                Description : notificationMessageDescription,
                MessageSubject: messageSubject,
                MessageBody : getHTMLFromEditor(),
                MessageSubjectResourceKey : messageSubjectResourceKey,
                MessageBodyResourceKey : messageBodyResourceKey,
                IsGlobalAnnouncement : ((isGlobalAnnouncement === '1') ? true : false),
                GlobalAnnouncementStartDate: globalAnnouncementStartDate ? moment(globalAnnouncementStartDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                GlobalAnnouncementEndDate: globalAnnouncementEndDate ? moment(globalAnnouncementEndDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                GlobalAnnouncementExternalLink : globalAnnouncementExternalLink,
                GlobalAnnouncementShowLogins : globalAnnouncementShowLogins
            };        
            // console.log(oUParams);
            if (EditedNotificationMessageID > 0) {
                await props.updateNotificationMessage(oUParams);    
            } else {
                await props.insertNotificationMessage(oUParams);    
            }
            setNotificationMessageID(-1);
            setEditedNotificationMessageID(-1);
            setNotificationMessageName('');
            setNotificationMessageDescription('');
            setMessageSubject('');
            setMessageBody('');
            setEditorHTML(true, null)
            setMessageSubjectResourceKey('');
            setMessageBodyResourceKey('');
            setPolicyID(-1);
            setIsGlobalAnnouncement('0');
            setGlobalAnnouncementStartDate(null);
            setGlobalAnnouncementEndDate(null);
            setGlobalAnnouncementExternalLink('');
            setGlobalAnnouncementShowLogins(0);
            setAddNewNotificationMessage(false);
            setEditNotificationMessage(false);    
        } 

   
        // const oParams  = {
        //     UserID:auth.UserID,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            NotificationMessageName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = notificationmessage?.filter((oRec) => (oRec.NotificationMessageID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setNotificationMessageID(id);
                setEditedNotificationMessageID(id);
                setNotificationMessageName(oEdited[0]?.NotificationMessageName);
                setNotificationMessageDescription((oEdited[0]?.Description) ? oEdited[0]?.Description : '');
                setMessageSubject(oEdited[0]?.MessageSubject);
                setMessageBody(oEdited[0]?.MessageBody);
                setEditorHTML(false, oEdited[0]?.MessageBody)
                setMessageSubjectResourceKey(oEdited[0]?.MessageSubjectResourceKey);
                setMessageBodyResourceKey(oEdited[0]?.MessageBodyResourceKey);
                setPolicyID(oEdited[0]?.PolicyID);    
                setIsGlobalAnnouncement((oEdited[0]?.IsGlobalAnnouncement) ? '1' : '0');
                setGlobalAnnouncementStartDate(oEdited[0]?.GlobalAnnouncementStartDate ? moment(oEdited[0]?.GlobalAnnouncementStartDate) : null);
                setGlobalAnnouncementEndDate(oEdited[0]?.GlobalAnnouncementEndDate ? moment(oEdited[0]?.GlobalAnnouncementEndDate) : null);
                setGlobalAnnouncementExternalLink(oEdited[0]?.GlobalAnnouncementExternalLink);
                setGlobalAnnouncementShowLogins(oEdited[0]?.GlobalAnnouncementShowLogins);
            }

            setEditNotificationMessage(true);
        } 
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                NotificationMessageID: id,
            };        
            // console.log(oParams);
            await props.deleteNotificationMessage(oParams);    
        } 
        
        // const oParams  = {
        //     UserID:auth.UserID,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            NotificationMessageName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        // const updatedSliderValues = sliderValue.slice(0);
        // updatedSliderValues.map((row, index) => {
        //     if (row.EvaluationID === rowIndex) {
        //         if (tab === 1) {
        //             row.EvaluationQuestionGroupID = evalGroupData[value].EvaluationQuestionGroupID;
        //         } else if (tab === 2) {
        //             row.DisplayOrder = value;                    
        //         } else if (tab === 3) {
        //             row.Weight = value;
        //         } else if (tab === 4) {
        //             row.Optional = (value === '1') ? true : false;                    
        //         } else if (tab === 5) {
        //             row.EvaluationQuestion = value;                    
        //         } else if (tab === 6) {
        //             row.EvaluationRatingTypeID = value;                    
        //         } 
        //     }        
        // });
        // setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function checkMainNotificationMessage(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {
                return (
                    <div>
                        <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Notification Message'>edit</span>
                        </a>
                        <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Notification Message'>delete</span>
                        </a>
                    </div>
                );            
            } else {
                return (<></>)
            }
        } else {
            return (
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" 
                        checked={getDetailSwitchValue(id)} id={`switch${'detail' + id}`} 
                        style={(getDetailSwitchValue(id)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                        onChange={(e) => {handleSwitchDetailChange(id, null)}}>
                    </input>
                    <label class="form-check-label" for={`switch${'detail' + id}`}>Select</label>
                </div>
            )
        }
    }

    function displayNewNotificationMessage() {
        if ((!fromLookup) && ((addNewNotificationMessage) || (editNotificationMessage))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title style={styles.tableSubHeaderCenterBold}>
                        {(addNewNotificationMessage) ? 'New Notification Message' : 'Edit Notification Message'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="notificationmessagename" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="notificationmessagename" type="text" style={styles.TextDataEntryNoPadding} defaultValue={notificationMessageName} onChange={(e) => (setNotificationMessageName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPadding} defaultValue={notificationMessageDescription} onChange={(e) => (setNotificationMessageDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="globalA" style={styles.LabelDataEntryNoPadding}>Is Global Annoucement?</label>
                                    <select id="globalA" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={isGlobalAnnouncement} onChange={(e) => (setIsGlobalAnnouncement(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="astartdate" style={styles.LabelDataEntryNoPadding}>Start Date</label>
                                    <input id="astartdate" type="date" style={styles.TextDataEntryNoPadding} defaultValue={globalAnnouncementStartDate ? moment(globalAnnouncementStartDate).format('YYYY-MM-DD') : ''} onChange={(e) => (setGlobalAnnouncementStartDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="aenddate" style={styles.LabelDataEntryNoPadding}>End Date</label>
                                    <input id="aenddate" type="date" style={styles.TextDataEntryNoPadding} defaultValue={globalAnnouncementEndDate ? moment(globalAnnouncementEndDate).format('YYYY-MM-DD') : ''} onChange={(e) => (setGlobalAnnouncementEndDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="globalL" style={styles.LabelDataEntryNoPadding}>Show Logins#</label>
                                    <input id="globalL" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={globalAnnouncementShowLogins} onChange={(e) => (setGlobalAnnouncementShowLogins(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>              
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="globalL" style={styles.LabelDataEntryNoPadding}>Announcement Link</label>
                                    <input id="globalL" type="text" style={styles.TextDataEntryNoPadding} defaultValue={globalAnnouncementExternalLink} onChange={(e) => (setGlobalAnnouncementExternalLink(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="sresourcekey" style={styles.LabelDataEntryNoPadding}>Subject Resource Key</label>
                                    <input id="sresourcekey" type="text" style={styles.TextDataEntryNoPadding} defaultValue={messageSubjectResourceKey} onChange={(e) => (setMessageSubjectResourceKey(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="bresourcekey" style={styles.LabelDataEntryNoPadding}>Body Resource Key</label>
                                    <input id="bresourcekey" type="text" style={styles.TextDataEntryNoPadding} defaultValue={messageBodyResourceKey} onChange={(e) => (setMessageBodyResourceKey(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>              
                        <tr>
                            <td colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="msubject" style={styles.LabelDataEntryNoPadding}>Subject</label>
                                    <input id="msubject" type="text" style={styles.TextDataEntryNoPadding} defaultValue={messageSubject} onChange={(e) => (setMessageSubject(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="bresourcekey" style={styles.LabelDataEntryNoPadding}>Body</label>
                                    {/* <input id="bresourcekey" type="text" style={styles.TextDataEntryNoPadding} defaultValue={messageBody} onChange={(e) => (setMessageBody(e.target.value))}/> */}
                                    <div style={{height:'300px', overflowY: 'auto'}}>
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            readOnly={false}
                                            mention={{
                                                separator: ' ',
                                                trigger: '@',
                                                suggestions: [
                                                    { text: 'Employee Address', value: '~Address~' },
                                                    { text: 'All Employee Assigned', value: '~AssignedUserEvaluationsNoDueDate~' },
                                                    { text: 'Assignment Due Date', value: '~Assignment_Due_Date~' },
                                                    { text: 'Authorizer Email', value: '~Auth_EMail~' },
                                                    { text: 'Authorizer First Name', value: '~Auth_Fname~' },
                                                    { text: 'Authorizer Last Name', value: '~Auth_Lname~' },
                                                    { text: 'Authorizer Phone Number', value: '~Auth_Phone~' },
                                                    { text: 'Cancellation User Email', value: '~Cancel_EMail~' },
                                                    { text: 'Cancellation User Full Name', value: '~Cancel_FullName~' },
                                                    { text: 'Due Date', value: '~Due_Date~' },
                                                    { text: 'Employee ID', value: '~EmployeeID~' },
                                                    { text: 'Evaluation Launch Link/Text', value: '~SingleEvaluationLaunchLink~' },
                                                    { text: 'Evaluator First and Last Name', value: '~Evaluator_FirstLast_Name~' },
                                                    { text: 'Evaluator Full Name', value: '~Evaluator_Full_Name~' },
                                                    { text: 'First Name', value: '~First_Name~' },
                                                    { text: 'Last Name', value: '~Last_Name~' },
                                                    { text: 'Login Page', value: '~GLMLoginPage~' },
                                                    { text: 'Manager EvaluationLink/Text', value: '~ManagerEvaluationLaunchLink~' },
                                                    { text: 'Title', value: '~Title~' },
                                                    { text: 'UserName', value: '~User_UserName~' }
                                                    ],
                                                }}                                            
                                        />
                                    </div>
                                </div>                    
                            </td>
                        </tr>              
                    </tbody>
                </table>                
                </Card.Body>
                <Card.Footer>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-auto">
                            <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Notification Message'>Save</Button>
                        </div>
                        <div class="col-auto">
                            <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                        </div>
                    </div>
                </Card.Footer>
                </Card>                   
            );
        }
    }

    function displayAddMainNotificationMessage() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewNotificationMessage) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Notification'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Notification Message'>note_add</span>
                        </a>
                    );    
                }
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Notification Messages</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Notification Message'/>
                        <a onClick={() => searchNotificationMessages()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainNotificationMessage()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewNotificationMessage()}                                               
                            {((!notificationmessage) || (notificationmessage.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Notification Messages.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Description</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notificationmessage && notificationmessage.map((oRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.NotificationMessageID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.NotificationMessageName}</td>
                                                        <td style={styles.tableTextLeft}>{oRec?.Description}</td>
                                                        <td style={styles.tableText5}>{checkMainNotificationMessage(1, oRec?.NotificationMessageID, -1)}</td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{notificationmessages?.notificationmessages?.RecordFound?.toLocaleString()}   Notification Messages&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(notificationmessages?.notificationmessages?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(notificationmessages?.notificationmessages?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {notificationmessages?.notificationmessages?.PagingMetaData?.PageNumber}  of  {notificationmessages?.notificationmessages?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      notificationmessages: state.entity?.notificationmessages, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNotificationMessages: (oParams) => dispatch(getNotificationMessages(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertNotificationMessage: (oParams) => dispatch(insertNotificationMessage(oParams)),
        deleteNotificationMessage: (oParams) => dispatch(deleteNotificationMessage(oParams)),
        updateNotificationMessage: (oParams) => dispatch(updateNotificationMessage(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMessageList)