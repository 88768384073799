import React, { useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { styles } from '../layout/styles';
import { Button, Card, CardBody, Modal, Table } from 'react-bootstrap';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import '../layout/survey.css';
import { updateEvaluationResponses, updateUserEvaluationSession, updateKnowledgeSessionEvaluationResponses, getUserEvaluationSessions } from '../../store/actions/entityActions'
import moment from 'moment';
import { RetentasType } from '../../config/Constant';
import { SurveyThemeJson } from '../../config/SurveyTheme';

function LaunchEvaluation (props) {
    // const { id } = useParams();
    const { id, auth, companyData, evaluation, evaluationquestions, evaluationratings, evaluationquestionratings, goals, subgoals, subgoalratings, subgoalgroups, 
        userevaluationsessions, launchuserevaluation, launchuserevaluationsession, userDetail, knowledgesessionevaluation, processleveltypeidProps, fromLookup } = props;

    const { id:paramID } = useParams();
    const launchID = id || paramID;

    const location  = useLocation();
    let processleveltypeid = location?.state?.processleveltypeid;
    if ((processleveltypeid === null) || (processleveltypeid === undefined)) {
        processleveltypeid = processleveltypeidProps;
    }
    //const entity = props.entities.find(item => item.id === id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [publishEvaluation, setpublishEvaluation] = useState(false);

    const [ showAlertModal, setshowAlertModal ] = useState(false);

    const [header, setHeader] = useState((processleveltypeid == RetentasType.Surveys) ? 'Employee Engagement' : 'Performance');
    
    function handleAlertModalClose() { 
        setshowAlertModal(false);
    }
    
    function handleAlertModalShow() { 
        if ((processleveltypeid === RetentasType.Surveys) || (processleveltypeid === RetentasType.PRFs)) {
            setshowAlertModal(true);
        } 
    }
    
    async function handleAlertModalYes() { 
        setshowAlertModal(false);
        console.log('Call publish evaluation session.');
        if (processleveltypeid == RetentasType.Surveys) {
                const userEvalSessionParams = [{
                UserID:auth.UserID,
                UserEvaluationSessionID:launchID,
                CompletedDate:moment().toDate(),
                IsPublished:'true',
                Score:'0',
                Weight:'0',
                DataSyncOut:'',
                DataSyncMessageOut:''
            }];
            await props.updateUserEvaluationSession(userEvalSessionParams);
            await props.getUserEvaluationSessions(auth.UserID);
            setpublishEvaluation(true);
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        } else {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
        }
    }

    function handleAlertModalNo() { 
        setshowAlertModal(false);
        console.log('Do not publish evaluation session.');
        setpublishEvaluation(false);
        if (processleveltypeid == RetentasType.Surveys) {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        } else {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
        }
    }

    function handleCancel() {
        if (processleveltypeid == RetentasType.Surveys) {
            navigate('/', {state: {processleveltypeid:RetentasType.Surveys}});
            // navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        } else {
            navigate('/', {state: {processleveltypeid:RetentasType.PRFs}});
            // navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
        }
    }

    function animate (isShowing) {
        const element = document.getElementById("root");
        if (!!element) {
            const classes = element.classList;
            if (!classes.contains("hidden")) {
                classes.add("hidden");
            }
            isShowing ? classes.add("visible") : classes.remove("visible");
        }
    }
    
    function showAnimated () { animate(true); }
    function hideAnimated () { animate(false); }
    
    var doAnimation = true;
    // var rtCss = {
    //   navigation: {
    //     complete: "sv-btn sv-footer__complete-btn my-button",
    //     prev: "sv-btn sv-footer__prev-btn  my-button",
    //     next: "sv-btn sv-footer__next-btn my-button",
    //     start: "sv-btn sv-footer__start-btn  my-button",
    //     preview: "sv-btn sv-footer__preview-btn  my-button",
    //     edit: "sv-btn sv-footer__edit-btn my-button",
    //   },    
    // };    

    // console.log(auth);
    //console.log(evaluation);
    //console.log(evaluationquestions);
    //console.log(evaluationratings);

    function populateHeaderSurveyJSON(prevJson) {
        const updatedJson = { ...prevJson };
        const employeeInfo = updatedJson.pages[0].elements[0].elements;
        var launchUserEvalRow = launchuserevaluationsession?.filter((usereval) => (parseInt(usereval.UserEvaluationSessionID) === parseInt(launchID)));
        if ((launchUserEvalRow) && (launchUserEvalRow.length > 0)) {
            // Update the corresponding field in the employee_info array
            const fieldIndexET = employeeInfo.findIndex((item) => item.name === 'question_filledBy');
            if (fieldIndexET !== -1) {
                if (launchUserEvalRow[0].UserEvaluationTypeID == 1) {
                    employeeInfo[fieldIndexET].defaultValue = "Self";
                } else if (launchUserEvalRow[0].UserEvaluationTypeID == 2) {
                    employeeInfo[fieldIndexET].defaultValue = "Manager";
                } if (launchUserEvalRow[0].UserEvaluationTypeID == 3) {
                    employeeInfo[fieldIndexET].defaultValue = "Peer";
                } if (launchUserEvalRow[0].UserEvaluationTypeID == 4) {
                    employeeInfo[fieldIndexET].defaultValue = "Subordinate";
                } 
            }
            const fieldIndexName = employeeInfo.findIndex((item) => item.name === 'employee_name');
            if (fieldIndexName !== -1) {
                employeeInfo[fieldIndexName].defaultValue = launchUserEvalRow[0].UserFullName;
            }
            const fieldIndexJP = employeeInfo.findIndex((item) => item.name === 'job_position');
            if (fieldIndexJP !== -1) {
                employeeInfo[fieldIndexJP].defaultValue = launchUserEvalRow[0].JobPositionDescription;
            }
            const fieldIndexDH = employeeInfo.findIndex((item) => item.name === 'date_of_hired');
            if (fieldIndexDH !== -1) {
                employeeInfo[fieldIndexDH].defaultValue = moment(launchUserEvalRow[0].DateHired).toDate();
            }
            const fieldIndexPD = employeeInfo.findIndex((item) => item.name === 'start_date_current_position');
            if (fieldIndexPD !== -1) {
                if (!launchUserEvalRow[0].PromotionDate) {
                    employeeInfo[fieldIndexPD].defaultValue = moment(launchUserEvalRow[0].DateHired).toDate();
                } else {
                    employeeInfo[fieldIndexPD].defaultValue = moment(launchUserEvalRow[0].PromotionDate).toDate();
                }
            }        
            const fieldIndexEI = employeeInfo.findIndex((item) => item.name === 'employee_id');
            if (fieldIndexEI !== -1) {
                employeeInfo[fieldIndexEI].defaultValue = launchUserEvalRow[0].VendorUserID;
            }
            const fieldIndexRT = employeeInfo.findIndex((item) => item.name === 'reports_to');
            if (fieldIndexRT !== -1) {
                if ((launchUserEvalRow[0].SupervisorUserFullName) && (launchUserEvalRow[0].SupervisorUserFullName.length > 1)) {
                    employeeInfo[fieldIndexRT].defaultValue = launchUserEvalRow[0].SupervisorUserFullName;
                } else {
                    employeeInfo[fieldIndexRT].defaultValue = launchUserEvalRow[0].EvaluatorFullName;
                }
            }        
            const fieldIndexD = employeeInfo.findIndex((item) => item.name === 'department');
            if (fieldIndexD !== -1) {
                employeeInfo[fieldIndexD].defaultValue = launchUserEvalRow[0].DepartmentName;
            }

        }

        return updatedJson;             
    }

    function findAndUpdateImmediateParentByName(json, partialValue, updateCallback, ratingID, comments, isgoal) {
        function search(obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
    
                    // Check if the key is "name" and value contains the partial text
                    if (key === "name" && typeof value === "string" && value.includes(partialValue)) {
                        // Apply the update callback on the immediate parent object
                        if (isgoal) {
                            updateCallbackGoal(obj, ratingID, comments);
                        } else {
                            updateCallback(obj, ratingID, comments);
                        }
                    }
    
                    // Recursively search nested objects
                    if (typeof value === "object" && value !== null) {
                        search(value);
                    }
                }
            }
        }
    
        search(json);
    }
    
    // Update callback to modify only the immediate parent - Questions
    function updateCallback(parent, ratingID, comments, isgoal) {
        const multiplematch = parent.name.match(/^multiplequestion_(\d+(_\d+)*)(_rating|_comments|_both)?$/);///^multiplequestion_(\d+)(_rating|_comments|both)?$/);
        if (multiplematch) {
            const type = multiplematch[multiplematch.length - 1]; // Can be '_rating' or '_comments' or both
            const questionids = multiplematch[1].split('_');
            const defaultValue = {};
            if (type === '_rating') {
                questionids.map((questionID, index) => {
                    var launchUserEvalRow = launchuserevaluation?.filter((usereval) => ((parseInt(usereval.UserEvaluationSessionID) === parseInt(launchID)) && (parseInt(usereval.EvaluationQuestionID) === parseInt(questionID)) ));
                    if (launchUserEvalRow && (launchUserEvalRow.length > 0)) {
                        defaultValue[questionID] = { Rating: launchUserEvalRow[0].EvaluationRatingTypeID.toString() };
                    }
                })
                parent.defaultValue = defaultValue;
            } else if (type === '_comments') {
                questionids.map((questionID, index) => {
                    var launchUserEvalRow = launchuserevaluation?.filter((usereval) => ((parseInt(usereval.UserEvaluationSessionID) === parseInt(launchID)) && (parseInt(usereval.EvaluationQuestionID) === parseInt(questionID)) ));
                    if (launchUserEvalRow && (launchUserEvalRow.length > 0)) {
                        defaultValue[questionID] = launchUserEvalRow[0].Comments;
                    }
                })
                parent.defaultValue = defaultValue;
            } else if (type === '_both') {
                questionids.map((questionID, index) => {
                    var launchUserEvalRow = launchuserevaluation?.filter((usereval) => ((parseInt(usereval.UserEvaluationSessionID) === parseInt(launchID)) && (parseInt(usereval.EvaluationQuestionID) === parseInt(questionID)) ));
                    if (launchUserEvalRow && (launchUserEvalRow.length > 0)) {
                        defaultValue[questionID] = { 'Rating': launchUserEvalRow[0].EvaluationRatingTypeID.toString(), 'Comments': launchUserEvalRow[0].Comments };
                    }
                })
                parent.defaultValue = defaultValue;
            }
        }                

        const match = parent.name.match(/^question_(\d+)(_rating|_comments|_both)?$/);
        if (match) {
            const questionID = parseInt(match[1], 10);
            const type = match[2]; // Can be '_rating' or '_comments'
            if (type === '_rating') {
                if (parent.type === 'boolean') {
                   if (parseInt(parent.valueTrue) === parseInt(ratingID)) {
                        parent.defaultValue = 'true';
                    } else {
                        parent.defaultValue = 'false';
                    }
                } else {
                    parent.defaultValue = ratingID;
                }
                // Populate comments for rating field
                if ((comments) && (comments !== '')) {
                    parent.comment = comments;
                }
            } else if (type === '_comments') {
                parent.defaultValue = comments;
            } else if (type === '_both') {
                if (parent.type === 'boolean') {
                    if (parseInt(parent.valueTrue) === parseInt(ratingID)) {
                        parent.defaultValue = 'true';
                    } else {
                        parent.defaultValue = 'false';
                    }
                    parent.comment = comments;
                    parent.Comment = comments;
                } else {
                    parent.defaultValue = ratingID;
                    parent.comment = comments;
                    parent.Comment = comments;
                }
            }   
        }                
        // console.log(parent);
    }

    // Update callback to modify only the immediate parent - Goals
    function updateCallbackGoal(parent, ratingID, comments, isgoal) {
        const multiplematch = parent.name.match(/^goalgroup_(\d+(_\d+)*)(_rating|_comments|_both)?$/);///^multiplequestion_(\d+)(_rating|_comments|both)?$/);
        if (multiplematch) {
            const type = multiplematch[multiplematch.length - 1]; // Can be '_rating' or '_comments' or both
            const questionids = multiplematch[1].split('_');
            const choices = [];
            const assignedGoals = [];
            if (type === '_rating') {
                questionids.map((groupID, index) => {
                    // populate choices
                    var subGoalRow = subgoals?.filter((goal) => (parseInt(goal.EvaluationQuestionGroupID) === parseInt(groupID) ));
                    if (subGoalRow && (subGoalRow.length > 0)) {
                        subGoalRow.map ((oRow) => {
                            var goalRatingRow = subgoalratings?.filter((rating) => (parseInt(rating.EvaluationQuestionID) === parseInt(oRow.EvaluationQuestionID) ));
                            let sgoalratingid = ',-1';
                            let sgoalratingname = '';
                            if ((goalRatingRow) && (goalRatingRow.length > 0)) {
                                sgoalratingid = ',' + goalRatingRow[0].EvaluationRatingTypeID.toString();
                                sgoalratingname = ' - Current Performance: ' + goalRatingRow[0].EvaluationRatingTypeName;    
                            }
                            choices.push({ value: oRow.EvaluationQuestionID.toString() + sgoalratingid, 
                                                text: 'Org Goal: ' + oRow.EvaluationQuestion + sgoalratingname }); 
                        })
                    }

                    // populate defaultValue
                    var userGoalRow = launchuserevaluation?.filter((usergoal) => (parseInt(usergoal.EvaluationQuestionGroupID) === parseInt(groupID) ));
                    if (userGoalRow && (userGoalRow.length > 0)) {
                        userGoalRow.map ((oRow) => {
                            assignedGoals.push( oRow.EvaluationQuestionID.toString() + ',' + oRow.EvaluationRatingTypeID.toString() ); 
                        })
                    }
                })
                parent.choices = choices;
                parent.defaultValue = assignedGoals;
            } 
        }                
    }

    function populateQuestionsSurveyJSON(prevJson) {
        const updatedJson = { ...prevJson };
        // Questions
        let isgoal = false;
        if ((launchuserevaluation) && (launchuserevaluation.length > 0)) {       
            launchuserevaluation && launchuserevaluation.map((question, index) => {
                let existing = findAndUpdateImmediateParentByName(updatedJson, 'question_' + question.EvaluationQuestionID + '_', updateCallback, question.EvaluationRatingTypeID, question.Comments, isgoal);
        })}

        // Goals
        isgoal = true; 
        if ((subgoalgroups) && (subgoalgroups.length > 0)) {       
            subgoalgroups && subgoalgroups.map((goalgroup, index) => {
                let existing = findAndUpdateImmediateParentByName(updatedJson, 'goalgroup_' + goalgroup.EvaluationQuestionGroupID + '_', updateCallbackGoal, -1, '', isgoal);
        })}

        return updatedJson;             
    }

    function generateEvaluation() {
        try {
            const surveyJson = {
                };        
            if (processleveltypeid === RetentasType.Surveys) {
                const survey = new Model(surveyJson);
                survey.showQuestionNumbers = 'off';
                //survey.css = rtCss;
                survey.applyTheme({
                    "cssVariables" : {
                        "--sjs-primary-backcolor": companyData?.AccentColor,
                        "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                    }
                })
                const page = survey.addNewPage(evaluation[0].EvaluationName);
                evaluationquestions && evaluationquestions.map((question, index) => {
                    // Add a panel to the page
                    let panel = survey.getPanelByName(question.EvaluationQuestionGroupName);
                    if (!panel) {
                        panel = page.addNewPanel(question.EvaluationQuestionGroupName);
                        //console.log(question.EvaluationQuestionGroupName);
                        panel.title = question.EvaluationQuestionGroupName;
                        panel.description = 'Please indicate if you agree or disagree with the following statements';
                        panel.state = "collapsed";                        
                    }
                    // Add a question to the panel
                    const surveyquestion = panel.addNewQuestion("rating", question.EvaluationQuestion);
                    surveyquestion.title = question.EvaluationQuestion;    
                    surveyquestion.value = question.EvaluationQuestionID;
                    surveyquestion.type = 'rating'; 
                    surveyquestion.rateType = 'smileys';
                    surveyquestion.scaleColorMode = 'colored';
                    surveyquestion.rateColorMode = 'scale';
                    surveyquestion.rateCount = 5;
                    surveyquestion.rateMax = 5;
                    surveyquestion.displayMode = 'buttons';          
                })
                return survey;
            } if (processleveltypeid === RetentasType.Knowledge) {
                const survey = new Model(surveyJson);
                survey.showQuestionNumbers = 'off';
                //survey.css = rtCss;
                survey.applyTheme({
                    "cssVariables" : {
                        "--sjs-primary-backcolor": companyData?.AccentColor,
                        "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                    }
                })
                const page = survey.addNewPage(evaluation[0].EvaluationName);
                evaluationquestions && evaluationquestions.map((question, index) => {
                    // Add a panel to the page
                    let panel = survey.getPanelByName(question.EvaluationQuestionGroupName);
                    if (!panel) {
                        panel = page.addNewPanel(question.EvaluationQuestionGroupName);
                        //console.log(question.EvaluationQuestionGroupName);
                        panel.title = question.EvaluationQuestionGroupName;
                        panel.description = 'Please indicate if you agree or disagree with the following statements';
                        panel.state = "collapsed";                        
                    }
                    // Add a question to the panel
                    const surveyquestion = panel.addNewQuestion("rating", question.EvaluationQuestion);
                    surveyquestion.title = question.EvaluationQuestion;    
                    surveyquestion.value = question.EvaluationQuestionID;
                    surveyquestion.type = 'rating'; 
                    surveyquestion.rateType = 'smileys';
                    surveyquestion.scaleColorMode = 'colored';
                    surveyquestion.rateColorMode = 'scale';
                    surveyquestion.rateCount = 5;
                    surveyquestion.rateMax = 5;
                    surveyquestion.displayMode = 'buttons';          
                })
                return survey;
            } else {
                let survey = null;
                if ((evaluation[0]?.UseTemplate) && (evaluation[0]?.Template)) {
                    const surveyJSON = JSON.parse(evaluation[0]?.Template);
                    const updatedHeaderSurveyJson = populateHeaderSurveyJSON(surveyJSON);
                    const updatedSurveyJson = populateQuestionsSurveyJSON(updatedHeaderSurveyJson);
                    console.log(updatedSurveyJson);
                    survey = new Model(updatedSurveyJson);                    
                } else {
                    const survey = new Model(surveyJson);
                }
                survey.showQuestionNumbers = 'off';
                //survey.css = rtCss;
                //survey.applyTheme(SurveyThemeJson);
                survey.applyTheme({
                    "cssVariables" : {
                        "--sjs-primary-backcolor": companyData?.AccentColor,
                        "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                    }
                })
                return survey;
            }
                
        } catch(error) {
            console.log(error);
        };
    }

    function generateEvaluationTabular() {
        try {
            const surveyJson = {
                };        
            if (processleveltypeid === RetentasType.Surveys) {
                const survey = new Model(surveyJson);
                survey.showQuestionNumbers = 'off';
                //survey.css = rtCss;
                survey.applyTheme({
                    "cssVariables" : {
                        "--sjs-primary-backcolor": companyData?.AccentColor,
                        "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                    }
                })
                const page = survey.addNewPage(evaluation[0].EvaluationName);
                evaluationquestions && evaluationquestions.map((question, index) => {
                    // Add a panel to the page
                    let panel = survey.getPanelByName(question.EvaluationQuestionGroupName);
                    if (!panel) {
                        panel = page.addNewPanel(question.EvaluationQuestionGroupName);
                        //console.log(question.EvaluationQuestionGroupName);
                        panel.title = question.EvaluationQuestionGroupName;
                        panel.description = 'Your feedback matters. Please evaluate the statements below.';
                        panel.state = "collapsed";                        
                    }
                    // Add a question to the panel
                    const surveyquestion = panel.addNewQuestion("rating", question.EvaluationQuestion);
                    surveyquestion.title = question.EvaluationQuestion;    
                    surveyquestion.value = question.EvaluationQuestionID;
                    surveyquestion.type = 'rating'; 
                    surveyquestion.rateType = 'smileys';
                    surveyquestion.scaleColorMode = 'colored';
                    surveyquestion.rateColorMode = 'scale';
                    surveyquestion.rateCount = 5;
                    surveyquestion.rateMax = 5;
                    surveyquestion.displayMode = 'buttons';          
                })
                return survey;
            } if (processleveltypeid === RetentasType.Knowledge) {
                const survey = new Model(surveyJson);
                survey.showQuestionNumbers = 'off';
                //survey.css = rtCss;
                survey.applyTheme({
                    "cssVariables" : {
                        "--sjs-primary-backcolor": companyData?.AccentColor,
                        "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                    }
                })
                const page = survey.addNewPage(evaluation[0].EvaluationName);
                evaluationquestions && evaluationquestions.map((question, index) => {
                    // Add a panel to the page
                    let panel = survey.getPanelByName(question.EvaluationQuestionGroupName);
                    if (!panel) {
                        panel = page.addNewPanel(question.EvaluationQuestionGroupName);
                        //console.log(question.EvaluationQuestionGroupName);
                        panel.title = question.EvaluationQuestionGroupName;
                        panel.description = 'Please indicate if you agree or disagree with the following statements';
                        panel.state = "collapsed";                        
                    }
                    // Add a question to the panel
                    const surveyquestion = panel.addNewQuestion("rating", question.EvaluationQuestion, question.EvaluationQuestionID);
                    surveyquestion.title = question.EvaluationQuestion;    
                    surveyquestion.value = question.EvaluationQuestionID;
                    surveyquestion.type = 'rating'; 
                    // surveyquestion.rateType = 'smileys';
                    // surveyquestion.scaleColorMode = 'colored';
                    // surveyquestion.rateColorMode = 'scale';
                    // surveyquestion.rateCount = 5;
                    // surveyquestion.rateMax = 5;
                    surveyquestion.rateValues = generateRatingsByEvaluationAndQuestion(question.EvaluationID, question.EvaluationQuestionID);
                    surveyquestion.defaultValue = generatePreviousRatings(question.EvaluationID, question.EvaluationQuestionID);
                    surveyquestion.minRateDescription = ' ';
                    surveyquestion.maxRateDescription = ' ';
                    surveyquestion.displayMode = 'buttons';          
                })
                return survey;
            } else {
                let survey = null;
                if ((evaluation[0]?.UseTemplate) && (evaluation[0]?.Template)) {
                    const surveyJSON = JSON.parse(evaluation[0]?.Template);
                    const updatedHeaderSurveyJson = populateHeaderSurveyJSON(surveyJSON);
                    const updatedSurveyJson = populateQuestionsSurveyJSON(updatedHeaderSurveyJson);
                    console.log(updatedSurveyJson);
                    survey = new Model(updatedSurveyJson);                    
                } else {
                    const survey = new Model(surveyJson);
                }
                survey.showQuestionNumbers = 'off';
                //survey.css = rtCss;
                //survey.applyTheme(SurveyThemeJson);
                survey.applyTheme({
                    "cssVariables" : {
                        "--sjs-primary-backcolor": companyData?.AccentColor,
                        "--sjs-primary-backcolor-light": companyData?.AccentColorLighter,                    
                    }
                })
                return survey;
            }
                
        } catch(error) {
            console.log(error);
        };
    }

    function generateQuestionsByGroup(groupId) {
        let matrixRows = [];
        evaluationquestions && evaluationquestions.map((question, index) => {
            if (question.EvaluationQuestionGroupID === groupId) {
                matrixRows.push({value: question.EvaluationQuestionID , text: question.EvaluationQuestion });
            }
        });
        //console.log(matrixRows);
        return matrixRows;
    }
    
    function generateRatingsByEvaluation(evalId) {
        let matrixColumns = [];
        evaluationratings && evaluationratings.map((ratings, ratingindex) => {
            if (ratings.EvaluationID === evalId) {
                matrixColumns.push({value: ratings.EvaluationRatingTypeID , text: ratings.EvaluationRatingTypeName });
            }
        });
        //console.log(matrixColumns);
        return matrixColumns;
    }
    
    function generateRatingsByEvaluationAndQuestion(evalId, evalQuestionId) {
        // [{value:'45', text:'Strongly Agree'},{value:'46', text:'More Than Agree'},{value:'48', text:'Agree'},{value:'49', text:'Somewhat Disagree'},{value:'54', text:'Strongly Disagree'}];
        let eRatings = [];
        let eQRatings = [];
        evaluationratings && evaluationratings.map((ratings, ratingindex) => {
            if (ratings.EvaluationID === evalId) {
                eRatings.push({value: [evalQuestionId, ratings.EvaluationRatingTypeID] , text: ratings.EvaluationRatingTypeName });
            }
        });
        evaluationquestionratings && evaluationquestionratings.map((ratings, ratingindex) => {
            if (ratings.EvaluationQuestionID === evalQuestionId) {
                eQRatings.push({value: [evalQuestionId, ratings.EvaluationRatingTypeID] , text: ratings.EvaluationRatingTypeName });
            }
        });

        if (eQRatings.length > 0) {
            return eQRatings;
        } else {
            return eRatings;
        }
    }

    function generatePreviousRatings(evalId, evalQuestionId) {
        let pRatings = {}; // Ensure this is an object, not an array
    
        knowledgesessionevaluation && knowledgesessionevaluation.forEach((ratings) => {
            if (ratings.EvaluationID === evalId && ratings.EvaluationQuestionID === evalQuestionId) {
                var evalQuestionRow = evaluationquestions?.filter((evalq) => (parseInt(evalq.EvaluationQuestionID) === parseInt(evalQuestionId)));
                if ((evalQuestionRow) && (evalQuestionRow.length > 0)) {
                    pRatings[evalQuestionRow[0].EvaluationQuestion] = evalQuestionId + ',' + ratings.EvaluationRatingID;  // Assign value directly
                }
            }
        });
    
        console.log(pRatings);
        return pRatings;
    }
    
    function generateEvaluationMatrix() {
        try {
            const surveyJson = {};        
            const survey = new Model(surveyJson);
            survey.commpleteText = 'Submit'
            survey.showQuestionNumbers = 'off';
            survey.applyTheme({
                "cssVariables" : {
                    "--sjs-primary-backcolor": "rgba(231, 29, 55, 1)",
                    "--sjs-primary-backcolor-light": "rgba(231, 29, 55, 0.1)",                    
                }
            })
            //survey.css = rtCss;
            //const page = survey.addNewPage(evaluation[0].EvaluationName);
            evaluationquestions && evaluationquestions.map((question, index) => {
                // Add a page
                let page = survey.getPageByName(question.EvaluationQuestionGroupName);
                if (!page) {
                    page = survey.addNewPage(question.EvaluationQuestionGroupName);
                }             

                let surveyquestion = page.getQuestionByName(question.EvaluationQuestionGroupName);
                if (!surveyquestion) {
                    // Add a question to the page
                    surveyquestion = page.addNewQuestion("matrix", question.EvaluationQuestionGroupName);
                    surveyquestion.title = question.EvaluationQuestionGroupName;    
                    surveyquestion.type = 'matrix'; 
                    surveyquestion.description = 'Please indicate if you agree or disagree with the following statements';
                    surveyquestion.columns = generateRatingsByEvaluation(question.EvaluationID);
                    surveyquestion.rows = generateQuestionsByGroup(question.EvaluationQuestionGroupID);
                }

            })
        
            survey.onAfterRenderSurvey.add(showAnimated);
            survey.onCurrentPageChanging.add(function (_, options) {
                if (!doAnimation) return;
                options.allow = false;
                setTimeout(function () {
                    doAnimation = false;
                    survey.currentPage = options.newCurrentPage;
                    doAnimation = true;
                }, 1000);
                hideAnimated();
            });
            
            survey.onCurrentPageChanged.add(showAnimated);
            
            survey.onCompleting.add(function (_, options) {
                if (!doAnimation) return;
                options.allow = false;
                setTimeout(function () {
                    doAnimation = false;
                    survey.doComplete();
                    doAnimation = true;
                }, 1000);
                hideAnimated();
            });
            
            survey?.onComplete.add(showAnimated);
        
            return survey;
        } catch(error) {
            console.log(error);
        };
    }

    async function saveSurveyResults(surveyResponses) {
        if (processleveltypeid == RetentasType.PRFs) {
            savePerformanceResults(surveyResponses);
        } if (processleveltypeid == RetentasType.Knowledge) {
            // console.log(surveyResponses);
            let dbResponse = [];
            Object.entries(surveyResponses).map(([group, groupResponses]) => {
                // console.log(group);
                // console.log(groupResponses);
                const oGroupResponses = groupResponses.toString().split(',');
                if ((oGroupResponses) && (oGroupResponses.length === 2)) {
                    // oGroupResponses.map((questionid, ratingid) => {
                        dbResponse.push({UserID: auth.UserID, LearningObjectSessionID: launchID, EvaluationID:evaluation[0].EvaluationID, EvaluationQuestionID:oGroupResponses[0], EvaluationRatingTypeID:oGroupResponses[1], DataSyncOut:'', DataSyncMessageOut: '' });                 
                    // });            
                }
            });
            console.log(dbResponse);
            // save DB
            await props.updateKnowledgeSessionEvaluationResponses(dbResponse);
        } else {
            console.log(surveyResponses);
            let dbResponse = [];
            Object.entries(surveyResponses).map(([group, groupResponses]) => {
                console.log(group);
                console.log(groupResponses);
    
                Object.entries(groupResponses).map(([questionid, ratingid]) => {
                    dbResponse.push({UserID: auth.UserID, UserEvaluationSessionID: launchID, EvaluationID:evaluation[0].EvaluationID, EvaluationQuestionID:questionid, EvaluationRatingTypeID:ratingid, DataSyncOut:'', DataSyncMessageOut: '' });                 
                });
        
            });
            console.log(dbResponse);
            // save DB
            if (processleveltypeid == RetentasType.Surveys) {
                await props.updateEvaluationResponses(dbResponse);
            }    
        }
    }

    function ProcessSurveyJSON(data) {
        const results = [];

        Object.keys(data).forEach((key) => {
            if (key.startsWith("question_") && !key.includes("filledBy")) {
                //const match = key.match(/^question_(\d+)(?:-(Comment|rating)|_(comments|rating))?$/);
                const match = key.match(/^question_(\d+)(?:-(Comment|rating)|_(comments|rating-Comment|rating))?$/);

                if (match) {
                    const questionId = parseInt(match[1], 10);
                    const type = match[2] || match[3];
                    if (type === "Comment" || type === "comments" || type === "rating-Comment") {
                        const existing = results.find((q) => q.QuestionID === questionId);
                        if (existing) {
                            existing.Comments = data[key];
                        } else {
                            results.push({
                                QuestionID: questionId,
                                RatingID: -1,
                                Comments: data[key],
                            });
                        }
                    } else if (type === "rating") {
                        const existing = results.find((q) => q.QuestionID === questionId);
                        if (existing) {
                            existing.RatingID = parseInt(data[key], 10);
                        } else {
                            results.push({
                                QuestionID: questionId,
                                RatingID: parseInt(data[key], 10),
                                Comments: "",
                            });
                        }
                    } else {
                        results.push({
                            QuestionID: questionId,
                            RatingID: parseInt(data[key], 10),
                            Comments: "",
                        });
                    }
                }
            } else if (key.startsWith("multiplequestion_")) {
                const match = key.match(/^multiplequestion_(.+)$/);
                if (match) {
                    const questionsData = data[key];
                    if (typeof questionsData === "object") {
                        Object.entries(questionsData).forEach(([qId, value]) => {
                            const questionId = parseInt(qId, 10);
                            if (typeof value === "object") {
                                results.push({
                                    QuestionID: questionId,
                                    RatingID: value.Rating ? parseInt(value.Rating, 10) : -1,
                                    Comments: value.Comments || "",
                                });
                            } else {
                                results.push({
                                    QuestionID: questionId,
                                    RatingID: -1,
                                    Comments: value || "",
                                });
                            }
                        });
                    }
                }
            } else if (key.startsWith("goalgroup_")) {
                const match = key.match(/^goalgroup_(.+)$/);
                if (match) {
                    const questionsData = data[key];
                    if (typeof questionsData === "object") {
                        Object.entries(questionsData).forEach(([iindex, value]) => {
                            if (typeof value === "object") {
                                Object.entries(value).forEach(([qId, qvalue]) => {
                                    const questionId = parseInt(qvalue.split(',')[0], 10);
                                    const ratingId = parseInt(qvalue.split(',')[1], 10);
                                    results.push({
                                        QuestionID: questionId,
                                        RatingID: ratingId,
                                    });
                                })
                            } 
                        });
                    }
                }
            }
        });
    
        return results;
    }


    async function savePerformanceResults(surveyResponses) {
        console.log(surveyResponses);
        const parsedSurveyResponses = ProcessSurveyJSON(surveyResponses);
        console.log(parsedSurveyResponses);

        let dbResponse = [];
        Object.entries(parsedSurveyResponses).map(([group, groupResponses]) => {
            dbResponse.push({
                UserID: launchuserevaluationsession[0].UserID, 
                UserEvaluationSessionID: launchID, 
                EvaluationID:launchuserevaluationsession[0].EvaluationID, 
                EvaluationQuestionID:groupResponses.QuestionID, 
                EvaluationRatingTypeID:groupResponses.RatingID, 
                Comments: groupResponses.Comments,
                Metric: '',
                UserEvaluationTypeID: -1,
                UserEvaluationUserID: -1,
                UpdatedByUserID: auth.UserID,   
                UpdatorFullName: userDetail?.Users[0]?.FullName,           
                DataSyncOut:'', 
                DataSyncMessageOut: '' 
            });                     
        });

        console.log(dbResponse);
        // save DB
        await props.updateEvaluationResponses(dbResponse);
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    let survey = null;
    if (processleveltypeid === RetentasType.Knowledge) {
        survey = generateEvaluationTabular(); // Tabular
    } else {
        survey = generateEvaluation(); // With smiley faces.
    }

    //const survey = generateEvaluation(); // With smiley faces.
    //const survey = generateEvaluationMatrix(); // animation

    const surveyComplete = useCallback((sender) => {
        handleAlertModalShow();
        saveSurveyResults(sender.data);
    });
    survey?.onComplete.add(surveyComplete);

    function displayEvaluation() {
        return (                
            <>
            <Modal style={{background:'transparent'}} aria-labelledby="contained-modal-title-vcenter" centered 
                show={showAlertModal} onHide={handleAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing your request!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to submit the Questionnaire?</p>
                <p>If you select 'Yes' - your responses are saved and the questionnaire will marked as complete. You cannot change your responses once marked complete.</p>                            
                <p>If you select 'No' - your responses are saved and the questionnaire will NOT marked as complete. You can come again to change your responses.</p>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal>        

            <form onSubmit={handleSubmit}>
                <Card style={{ alignContent:'center', alignItem:'center', justifyContent: "center", backgroundColor:companyData?.PagesBGColor}}>
                    {(!fromLookup) ? 
                        <Card.Header className='text-center'> 
                            <Card.Title>
                                <div>
                                    <Table responsive="sm" style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'85%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>{header}</h3></td>
                                            {/* <td style={{textAlign:'center', width:'15%'}}><Button disabled={loading} type='submit' style={styles.CDFullButton}>{loading ? 'Submiting...' : 'Submit'}</Button></td> */}
                                            <td style={{textAlign:'center', width:'15%', backgroundColor:companyData?.PagesBGColor}}><Button disabled={loading} style={styles.CDFullButton} onClick={() => handleCancel()}>Cancel</Button></td>
                                        </tr>
                                        </tbody>
                                    </Table>                            
                                </div>
                            </Card.Title>
                        </Card.Header>                    
                    :
                        <></>
                    }
                    <Card.Body>
                        <div >
                            {/* <Card style={{width:'100%', backgroundColor:'transparent'}}> */}
                            <Card className="text-left">
                                <Card.Header className='text-center' style={{backgroundColor:companyData?.PagesBGColor}}> 
                                    <Card.Title className='text-center'>
                                        {(processleveltypeid == RetentasType.Surveys) ?
                                            <Table responsive="sm" className='text-center' style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign:'center', width:'70%', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeader}>{evaluation && evaluation[0].EvaluationName ? evaluation[0].EvaluationName : "Questionnaire"}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td style={{textAlign:'center', width:'70%', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>{evaluation && evaluation[0].EvaluationHeader ? evaluation[0].EvaluationHeader : ""}</h6></td>
                                                </tr>
                                            </tbody>
                                            </Table>                            
                                            :
                                            <></>
                                        }
                                    </Card.Title>
                                </Card.Header>                    
                                <CardBody style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <Survey model={survey}/>
                                </CardBody>
                            <Card.Footer className="text-right" style={{backgroundColor:companyData?.PagesBGColor}}>
                            </Card.Footer>
                        </Card>
                        </div>
                    </Card.Body>
                </Card>
            </form>
            </>
        );
    }

    return (
        <>
            {(!fromLookup) ? 
                <div style={styles.MainInnerDiv}>
                    <div style={styles.DashboardContainer}>
                        {displayEvaluation()}
                    </div>
                </div>
            :
                <>
                    {displayEvaluation()}
                </>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
      auth: state.auth.user,
      userDetail: state.auth.userDetail,
      companyData: state?.auth?.companyData,
      evaluation: state?.entity?.evaluation,
      evaluationquestions: state?.entity?.evaluationquestions,
      evaluationratings: state?.entity?.evaluationratings,
      evaluationquestionratings: state?.entity?.evaluationquestionratings,
      userevaluationsessions: state?.entity?.userevaluationsessions, 
      launchuserevaluation: state?.entity?.userevaluation?.UserEvaluation, 
      launchuserevaluationsession: state?.entity?.userevaluation?.UserEvaluationSession, 
      knowledgesessionevaluation: state?.entity?.knowledgesessionevaluation?.knowledgesessionevaluation?.LearningObjectSessionEvaluation, 
      goals: state?.entity?.goals?.goals, 
      subgoals: state?.entity?.goals?.goals?.EvaluationQuestions,
      subgoalratings: state?.entity?.goals?.goals?.EvaluationQuestionRatings,
      subgoalgroups: state?.entity?.goals?.goals?.EvaluationQuestionGroups,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvaluationResponses: (evaluationResponses) => dispatch(updateEvaluationResponses(evaluationResponses)),
        updateUserEvaluationSession: (evaluationSession) => dispatch(updateUserEvaluationSession(evaluationSession)),
        updateKnowledgeSessionEvaluationResponses: (evaluationResponses) => dispatch(updateKnowledgeSessionEvaluationResponses(evaluationResponses)),
        getUserEvaluationSessions: (id) => dispatch(getUserEvaluationSessions(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaunchEvaluation)

