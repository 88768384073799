import React, { useEffect, useState } from "react"
import { Button, Card, Table, ButtonGroup, ToggleButton } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { styles } from '../layout/styles'
import MyAnalytics from "../userentities/MyAnalytics"
import Spinner from '../layout/Spinner';
import UserEvaluationList from "../userentities/UserEvaluationList"
import { RetentasType, LOLifeCycleStatus, EmpowermentType } from "../../config/Constant"
import MyKnowledge from "../userentities/MyKnowledge"
import { getKnowledgeSessionsByUser, getUserAttributesLookups } from "../../store/actions/entityActions"

function Dashboard(props) {
    const { auth, companyData, myTeam, userattributelookups, processleveltypeidProps } = props;
    const user = props.auth;
    const userevaluationsessions = props.entity;
    const location  = useLocation();
    let processleveltypeid = location?.state?.processleveltypeid;
    if ((processleveltypeid === null) || (processleveltypeid === undefined)) {
        processleveltypeid = processleveltypeidProps;
    }

    //const myTeam = props.myTeam;
    const loadingMenu = props.loadingMenu;
    const [loading, setLoading] = useState(false);
    const [typeSummary, setTypeSummary] = useState('Analytics');
    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
      { name: 'Intelligence', value: '1' },
      { name: 'Knowledge', value: '2' },
      { name: 'Questionnaires', value: '3' },
      { name: 'Performance', value: '4' },
      { name: 'Analytics', value: '5' },
    ];

    useEffect(() => {
      setLoading(loadingMenu);
    }, [loadingMenu])
  
    useEffect(() => {
      if (radioValue === '1') {
        setTypeSummary('Intelligence');
      } else if (radioValue === '2') {
        setTypeSummary('Analytics');
      } else if (radioValue === '3') {
        setTypeSummary('Questionnaires');
      } else if (radioValue === '4') {
        setTypeSummary('Performance');
      } else if (radioValue === '5') {
        setTypeSummary('Analytics');
      }
    }, [radioValue])
  
    useEffect(() => {
    }, [])
  
    function initialRadioValue() {
      // if ((processleveltypeid) && (processleveltypeid === RetentasType.Surveys)) {
      //   return '3';
      // } else if ((processleveltypeid) && (processleveltypeid === RetentasType.PRFs)) {
      //   return '4';
      // } else {
        return '1';
      // }
    }

    async function getMyKnowledgeSession() {
      const oKParams = {
        UserID:auth?.UserID,
        LearningObjectProcessStatusTypeID:LOLifeCycleStatus.Enrolled,
        RecordSize:'10',
        PageNumber:1,
      }; 
      // console.log(oKParams);
      await props.getKnowledgeSessionsByUser(oKParams);
      if (!userattributelookups) {
        await props.getUserAttributesLookups(auth?.UserID);
      }            
    }

    function displayMainDashboard() {
      getMyKnowledgeSession();
      if (radioValue === '1') {
        return (
          <MyKnowledge empowermentType={EmpowermentType.Intelligence}/>
        )
      }else if (radioValue === '2') {
        return (
          <MyKnowledge empowermentType={EmpowermentType.Knowledge}/>
        )
      } else if (radioValue === '3') {
        return (
          <MyKnowledge empowermentType={EmpowermentType.Questionnaire}/>
        )
      } else if (radioValue === '4') {
        return (
          <MyKnowledge empowermentType={EmpowermentType.Performance}/>
        )
      } else if (radioValue === '5') {
        return (
          <MyAnalytics myTeam={myTeam}  />
        )
      } 
    }

    if (!user) {
      return (
        <div>Please login again!</div>
      )
    } 
    else {
      return (
        <>
          {(!loading) ? 
            <div style={styles.MainInnerDiv}>
              <div style={styles.DashboardContainer}>
                <Card style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                    <Card.Header> 
                        <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                <thead>
                                <tr>
                                    <th style={{width:'50%', alignItems:'left', backgroundColor:companyData?.PagesBGColor}}> <h3 style={styles.SubHeader}>Empowerment</h3>
                                    </th>
                                    <th style={{width:'35%', alignItems:'center', backgroundColor:companyData?.PagesBGColor}}>
                                    </th>
                                    <th style={{width:'15%', alignItems:'right', backgroundColor:companyData?.PagesBGColor}}>
                                      <Card.Title>
                                          <ButtonGroup className="mb-2" size='sm'>
                                              {radios.map((radio, idx) => (
                                                  <ToggleButton
                                                      disabled={loading}
                                                      key={typeSummary + idx}
                                                      id={`radio-${typeSummary + idx}`}
                                                      type="radio"
                                                      style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                                                      name="radio"
                                                      value={radio.value}
                                                      checked={radioValue === radio.value}
                                                      size='sm'
                                                      onChange={(e) => {setRadioValue(e.currentTarget.value)}}
                                                  >
                                                      {radio.name}
                                                  </ToggleButton>
                                              ))}
                                          </ButtonGroup>                   
                                      </Card.Title>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                        </Table>
                    </Card.Header>
                    <Card.Body>
                      {displayMainDashboard()}
                    </Card.Body>
                </Card>
              </div>
            </div>
            
            :
            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
              <Spinner/>
            </div>
          }
        </>
      )
    }
} 

const mapStateToProps = (state) => {
  return {
    auth: state.auth?.user,
    entity: state.entity?.userevaluationsessions,
    myTeam: state.auth?.myTeam,
    companyData: state?.auth?.companyData,
    loadingMenu: state.auth.loadingMenu,
    userattributelookups: state.entity.userattributelookups,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledgeSessionsByUser: (kParams) => dispatch(getKnowledgeSessionsByUser(kParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
    }
}

//export default compose(connect(mapStateToProps),)(Dashboard)
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

