import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup, FormControl, Dropdown, DropdownMenu  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getKnowledges, getUserCalendar, getUserAttributesLookups, processEnrollmentClear } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import { UserLookup, Rights, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant, LOLifeCycleStatus, KnowledgeListType, LILifeCycleStatus, KnowledgeType, Topics } from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import Attachment from '../layout/Attachment';
import AlertMessage from '../layout/AlertMessage';
import KnowledgeInstanceDetail from './KnowledgeInstanceDetail';

const KnowledgeSearch = (props) => {
    const { auth, companyData, profile, mycalendar, orgDivisions, lookupselections, loadingMenu, listby, topicid, assignUserID } = props;    
    const { id } = useParams();
    const location  = useLocation();
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [parentParams, setParentParams] = useState(location?.state?.parentParams);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [nodeType, setnodeType] = useState('1');
    const [nodeTypeDescription, setnodeTypeDescription] = useState('Search Knowledge');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');

    const [knowledgeID, setKnowledgeID] = useState(id);
    const [knowledgeName, setKnowledgeName] = useState('');
    const [knowledgeInstanceID, setKnowledgeInstanceID] = useState(-1);
    const [knowledgeTypeID, setKnowledgeTypeID] = useState(-1);
    const [knowledgeCreditUnits, setKnowledgeCreditUnits] = useState('');
    const [knowledgeCreditHours, setKnowledgeCreditHours] = useState('');
    const [knowledgeCreditOthers, setKnowledgeCreditOthers] = useState('');

    const [instanceStartDate, setInstanceStartDate] = useState(new Date());
    const [instanceStartTime, setInstanceStartTime] = useState('08:00');
    const [instanceStartAMPM, setInstanceStartAMPM] = useState('AM');
    const [instanceEndDate, setInstanceEndDate] = useState(new Date());
    const [instanceEndTime, setInstanceEndTime] = useState('08:00');
    const [instanceEndAMPM, setInstanceEndAMPM] = useState('AM');
    const [instanceTimeZone, setInstanceTimeZone] = useState('-1');
    const [maximumStudent, setMaximumStudent] = useState('');
    const [minimumStudent, setMinimumStudent] = useState('');
    const [signInOut, setSignInOut] = useState('');
    const [registrationCutOff, setRegistrationCutOff] = useState('');
    const [registrationCutOffHours, setRegistrationCutOffHours] = useState('');
    const [cancellationCutOff, setCancellationCutOff] = useState('');
    const [cancellationCutOffHours, setCancellationCutOffHours] = useState('');
    const [testTakeType, setTestTakeType] = useState('');
    const [guestSpeakers, setGuestSpeakers] = useState('');
    const [processStatusTypeID, setProcessStatusTypeID] = useState('');
    const [launchDate, setLaunchDate] = useState('');
    const [enrollmentPeriod, setEnrollmentPeriod] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [subjectCode, setSubjectCode] = useState('');
    const [notes, setNotes] = useState('');
    const [privateNotes, setPrivateNotes] = useState('');
    const [externalWebinarURL, setExternalWebinarURL] = useState('');
    const [advertisementOnly, setAdvertisementOnly] = useState('');
    const [featured, setFeatured] = useState('');
    const [preventTranscripLaunch, setPreventTranscripLaunch] = useState('');
    const [allowReprintCertificate, setAllowReprintCertificate] = useState('');
    const [reprintCertificationCost, setReprintCertificationCost] = useState('');
    const [allowEnrollmentExtension, setAllowEnrollmentExtension] = useState('');
    const [enrollmentExtensionCost, setEnrollmentExtensionCost] = useState('');
    const [allowMove, setAllowMove] = useState('');
    const [noShowCharge, setNoShowCharge] = useState('');
    const [cancellationCharge, setCancellationCharge] = useState('');
    const [promotionPrice, setPromotionPrice] = useState('');
    const [assignmentInstance, setAssignmentInstance] = useState('');
    const [forcePayment, setForcePayment] = useState('');
    const [prerequisiteDuringLaunch, setPrerequisiteDuringLaunch] = useState('');
    const [byPassSessionCap, setByPassSessionCap] = useState('');
    const [byPassValidation, setByPassValidation] = useState('');
    const [byPassNotification, setByPassNotification] = useState('');
    const [preventRetake, setPreventRetake] = useState(false);
    const [byPassDateTimeConflicts, setByPassDateTimeConflicts] = useState(false);
    const [enableMultiSession, setEnableMultiSession] = useState(false);
    const [byPassPrerequisites, setByPassPrerequisites] = useState(false);
    const [prerequisiteExpression, setPrerequisiteExpression] = useState('');
    const [forceEvaluation, setForceEvaluation] = useState(false);
    const [evaluationStartDays, setEvaluationStartDays] = useState(0);
    const [evaluationEndDays, setEvaluationEndDays] = useState(0);
    const [forceROMS, setForceROMS] = useState(false);
    const [evaluationFromTranscript, setEvaluationFromTranscript] = useState(false);
    const [includeWaitlist, setIncludeWaitlist] = useState(false);
    const [autoTransfer, setAutoTransfer] = useState(false);
    const [waitlistCap, setWaitlistCap] = useState(0);
    const [emailTo, setEmailTo] = useState('');
    const [otherEmails, setOtherEmails] = useState('');
    const [authorizeInstructorOnly, setAuthorizeInstructorOnly] = useState(false);
    const [byPassAuthorization, setByPassAuthorization] = useState(true);
    const [paymentModes, setPaymentModes] = useState('');
    const [instanceDateArray, setInstanceDateArray] = useState([]);
    const [instanceDateDetails, setInstanceDateDetails] = useState('');
    const [instanceDates, setInstanceDates] = useState('');
    const [instructors, setInstructors] = useState('');    
    const [instructorDetails, setInstructorDetails] = useState('');    
    const [instructorArray, setInstructorArray] = useState([]);    
    const [presenters, setPresenters] = useState('');    
    const [presenterDetails, setPresenterDetails] = useState('');    
    const [presenterArray, setPresenterArray] = useState([]);    
    const [jobPositions, setJobPositions] = useState('');
    const [jobPositionDetails, setJobPositionDetails] = useState('');    
    const [jobPositionArray, setJobPositionArray] = useState([]);    
    const [authorizationGroup, setAuthorizationGroup] = useState('');    
    const [authorizationGroupName, setAuthorizationGroupName] = useState('');    
    const [authorizationLevel, setAuthorizationLevel] = useState('');    
    const [authorizationNotificationMessageArray, setAuthorizationNotificationMessageArray] = useState([]);
    const [authorizationNotificationMessageDetails, setAuthorizationNotificationMessageDetails] = useState([]);
    const [authorizationNotificationMessages, setAuthorizationNotificationMessages] = useState('');    
    const [authorizationNotificationMessageNames, setAuthorizationNotificationMessageNames] = useState('');    
    const [authorizationDetails, setAuthorizationDetails] = useState('');    
    const [authorizationArray, setAuthorizationArray] = useState([]);    
    const [facilities, setFacilities] = useState('');    
    const [facilityRooms, setFacilityRooms] = useState('');    
    const [facilityNames, setFacilityNames] = useState('');    
    const [facilityDirection, setFacilityDirection] = useState('');    

    const [resourceDetails, setResourceDetails] = useState('');    
    const [resourceArray, setResourceArray] = useState([]);    

    const [isAttachmentSectionDisabled, setIsAttachmentSectionDisabled] = useState(false);
    const [isAttachmentSectionVisible, setIsAttachmentSectionVisible] = useState(false);

    const [knowledgeDivisionDetails, setKnowledgeDivisionDetails] = useState('');
    const [knowledgeDivisions, setKnowledgeDivisions] = useState('');

    const [showInstanceDetail, setShowInstanceDetail] = useState(false);

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const knowledgeinstance = mycalendar?.mycalendar?.LearningObjectInstance;
    const loiDates = mycalendar?.mycalendar?.LearningObjectInstanceDate;
    const loiInstructors = mycalendar?.mycalendar?.LearningObjectInstanceInstructor;
    const loiPaymentModes = mycalendar?.mycalendar?.LearningObjectInstancePaymentMode;
    const loiJobPositions = mycalendar?.mycalendar?.LearningObjectInstanceJobPosition;
    const loiAuthorizerGroups = mycalendar?.mycalendar?.LearningObjectInstanceAuthorizerGroup;
    const loiResources = mycalendar?.mycalendar?.LearningObjectInstanceResource;
    const availability = mycalendar?.mycalendar?.Availability;

    const navigate = useNavigate();

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }

    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Classroom', value: KnowledgeType.Classroom },
        { name: 'Online', value: KnowledgeType.Online },
        { name: 'Classroom & Online', value: '1,2' },
    ];

    function initialRadioValue() {
        if ((listByValue !== null) && (listByValue !== undefined)) {
            return listByValue;
        } else {
            return KnowledgeType.Classroom;            
        }
    };

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = mycalendar?.mycalendar?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            UserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
            SearchTypeID: KnowledgeListType.Manager,
            GroupID: "2",
            LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
            LearningObjectTypeID: radioValue,
            LearningObjectName: nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getUserCalendar(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = mycalendar?.mycalendar?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            UserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
            SearchTypeID: KnowledgeListType.Manager,
            GroupID: "2",
            LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
            LearningObjectTypeID: radioValue,
            LearningObjectName: nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        console.log(oParams);
        await props.getUserCalendar(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    function initialTabValue() {
        return 1;
    };
     
    async function handleCloseInstanceDetail() {
        setLoading(true);
        await props.processEnrollmentClear();
        setShowInstanceDetail(false);
        setLoading(false);
    }

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function changeSearchValue(e) {
        if (e.target.innerHTML === 'Knowledge') {
            setnodeTypeDescription('Search Knowledge');
            setnodeType('1');
        } else if (e.target.innerHTML === 'Instructor') {
            setnodeTypeDescription('Search Instructor Name');
            setnodeType('2');
        } else if (e.target.innerHTML === 'Facility') {
            setnodeTypeDescription('Search Facility');
            setnodeType('3');
        } else if (e.target.innerHTML === 'Facility Room') {
            setnodeTypeDescription('Search Facility Room');
            setnodeType('4');
        } else if (e.target.innerHTML === 'Training Vendor') {
            setnodeTypeDescription('Search Training Vendor');
            setnodeType('5');
        } else if (e.target.innerHTML === 'Topic') {
            setnodeTypeDescription('Search Topic');
            setnodeType('6');
        } else if (e.target.innerHTML === 'Credit Units') {
            setnodeTypeDescription('Search Credit Units');
            setnodeType('7');
        } else if (e.target.innerHTML === 'Credit Hours') {
            setnodeTypeDescription('Search Credit Hours');
            setnodeType('8');
        }
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        // const divisionId = nodes[0]?.data?.id;
        let divisionIds = '';
        let divisionDetails = '';
        // console.log(nodes);
        nodes.forEach(element => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += element?.data?.id;
            divisionDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (divisionIds) {
            setSelectedNodes(nodes);
            setKnowledgeDivisions(divisionIds);
            setKnowledgeDivisionDetails(divisionDetails);
        }
        setLoading(false);
    }

    async function searchKnowledgeInstances() {
        setLoading(true);
        const oParams = {
            UserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
            SearchTypeID: KnowledgeListType.Manager,
            LearningObjectName: nodeName,
            GroupID: "1",
            LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
            LearningObjectTypeID:radioValue,
            RecordSize:'10',
            PageNumber:1,
        }; 
        console.log(oParams);
        await props.getUserCalendar(oParams);        
        setLoading(false);
    }
    
    async function searchKnowledgeInstancesByType(type) {
        setLoading(true);
        const oParams = {
            UserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
            SearchTypeID: KnowledgeListType.Manager,
            LearningObjectName: nodeName,
            GroupID: "1",
            LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
            LearningObjectTypeID:type,
            RecordSize:'10',
            PageNumber:1,
        }; 
        console.log(oParams);
        await props.getUserCalendar(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            LearningObjectInstanceID:kRec.LearningObjectInstanceID,
            takeAction:false,
            showDetails:false,
            editKnowledgeInstanceClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledgeinstance);
        setSliderValue(initialScoreValues);
    }, [knowledgeinstance])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  

    async function handleRadioChange(e) {
        setRadioValue(e.currentTarget.value);
        searchKnowledgeInstancesByType(e.currentTarget.value);
    }
            
    async function handleShowInstanceDetail() {
        setShowInstanceDetail(true); 
    };

    async function handleViewChildernClick(id, name, cnumber) {
        setLoading(true);
        setKnowledgeInstanceID(id);
        if ((cnumber) && (cnumber.trim() !== '')) {
            setKnowledgeName(name + ' (' + cnumber + ')');
        } else {
            setKnowledgeName(name);
        }

        setShowInstanceDetail(true);
        setLoading(false);
    }

    async function ClearStateFields() {
        setKnowledgeInstanceID(-1);
    }

    function getSearchParamsEx(bRadioValue, bRadioTypeValue, pageNumber)  {
        let oParams = {
            UserID: auth?.UserID,
            CompanyID: auth?.CompanyID,
            SearchTypeID: KnowledgeListType.Manager,
            GroupID: "2",
            LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
            LearningObjectTypeID:radioValue,
            LearningObjectName: nodeName,
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 

        if (nodeType === '2') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                InstructorName: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } else if (nodeType === '3') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                FacilityName: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } else if (nodeType === '4') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                RoomName: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } else if (nodeType === '5') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                VendorName: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } else if (nodeType === '6') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                TopicName: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } else if (nodeType === '7') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                CreditUnits: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } else if (nodeType === '3') {
            oParams = {
                UserID: auth?.UserID,
                CompanyID: auth?.CompanyID,
                SearchTypeID: KnowledgeListType.Manager,
                GroupID: "2",
                LearningObjectProcessStatusTypeID: LILifeCycleStatus.Scheduled,
                LearningObjectTypeID:radioValue,
                CreditHours: nodeName,
                RecordSize:'10',
                PageNumber:pageNumber,
            };            
        } 
        return oParams;
    }

    async function searchKnowledgeTrackInstances(bRadioValue, bRadioTypeValue) {
        setLoading(true);
        const oParams = getSearchParamsEx(bRadioValue, bRadioTypeValue, '1');
        await props.getUserCalendar(oParams);
        setLoading(false);
    }

    function displayListRadioButtons() {
        return (
            radios.map((radio, idx) => (
                <option value={radio.value}>{radio.name}</option>
            ))
        );
    }

    function getTopicName() {
        if (topicid) {
            if (topicid === Topics.Performance) {
                return 'Performance';
            } else if (topicid === Topics.WorkSafety) {
                return 'Work Safety';
            } else if (topicid === Topics.JobStress) {
                return 'Job Stress';
            } else if (topicid === Topics.JobSatisfaction) {
                return 'Job Satisfaction';
            } else if (topicid === Topics.SocialSupport) {
                return 'Social Support';
            } else if (topicid === Topics.ControlScale) {
                return 'Control Scale';
            } else if (topicid === Topics.PersonalEfficacy) {
                return 'Personal Efficacy';
            } else if (topicid === Topics.Turnover) {
                return 'Turnover Intention';
            } else if (topicid === Topics.OrganizationCommitment) {
                return 'Organization Commitment';
            } else if (topicid === Topics.Cybersecurity) {
                return 'Cybersecurity';
            }
        } else {
            return '';
        }

    }
        
    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <h4 style={styles.SubHeader}>Knowledge Search</h4>
                                    <h6 style={styles.SubHeader}>{getTopicName()}</h6>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
                <td style={{textAlign:'right', width:'60%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                        <select id="losstatusselect" class="browser-default" style={styles.TextDataEntryNoPaddingWidth30WBG} value={radioValue} onChange={(e) => (handleRadioChange(e))}>
                            {displayListRadioButtons()}
                        </select>
                        <Dropdown style={{backgroundColor:companyData?.PagesBGColor}}>
                            <Dropdown.Toggle className='dropdown-search-transparent' id="dropdown-custom-components">
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>expand_circle_down</span>
                            </Dropdown.Toggle>                                
                            <DropdownMenu style={{backgroundColor:companyData?.PagesBGColor}}>
                                {(nodeType === '1') ?
                                    <Dropdown.Item eventKey="1" className='dropdown-search-item-selected' onClick={changeSearchValue}>Knowledge</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="1" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Knowledge</Dropdown.Item>
                                }                                        
                                {(nodeType === '2') ?
                                    <Dropdown.Item eventKey="2" className='dropdown-search-item-selected' onClick={changeSearchValue}>Instructor</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="2" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Instructor</Dropdown.Item>
                                }                                        
                                {(nodeType === '3') ?
                                    <Dropdown.Item eventKey="3" className='dropdown-search-item-selected' onClick={changeSearchValue}>Facility</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="3" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Facility</Dropdown.Item>
                                }             
                                {(nodeType === '4') ?
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-selected' onClick={changeSearchValue}>Facility Room</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Facility Room</Dropdown.Item>
                                }             
                                {(nodeType === '5') ?
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-selected' onClick={changeSearchValue}>Training Vendor</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Training Vendor</Dropdown.Item>
                                }             
                                {(nodeType === '6') ?
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-selected' onClick={changeSearchValue}>Topic</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Topic</Dropdown.Item>
                                }             
                                {(nodeType === '7') ?
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-selected' onClick={changeSearchValue}>Credit Units</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Credit Units</Dropdown.Item>
                                }             
                                {(nodeType === '8') ?
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-selected' onClick={changeSearchValue}>Credit Hours</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="4" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Credit Hours</Dropdown.Item>
                                }             
                            </DropdownMenu>                           
                        </Dropdown>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder={nodeTypeDescription}/>
                        <a onClick={() => searchKnowledgeInstances()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );    
    }

    function displayKnowledgeTypeName(assRec) {
        if (assRec?.LearningObjectTypeID === 1) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Classroom-Based'>supervised_user_circle</span>
            );
        } else if (assRec?.LearningObjectTypeID === 2) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Online'>touchpad_mouse</span>
            )
        } else if (assRec?.LearningObjectTypeID === 3) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Manual/External'>other_admission</span>
            );
        } else if (assRec?.LearningObjectTypeID === 4) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Historical'>work_history</span>
            );
        }
    }   

    function displayDatesAndInstructor(assRec) {
        const sStartDate = moment(assRec?.StartDateTime).format("MM/DD/YYYY hh:mm a");
        const sEndDate = moment(assRec?.EndDateTime).format("MM/DD/YYYY hh:mm a");
        const sInstructor = (assRec?.InstructorSummary) ? assRec?.InstructorSummary : '';
        if (parseInt(assRec?.LearningObjectTypeID) === parseInt(KnowledgeType.Online)) {
            return (
                <>
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Online'>touchpad_mouse</span><span style={styles.tableText}>Online</span>
                </>
            );            
        } if (parseInt(assRec?.LearningObjectTypeID) === parseInt(KnowledgeType.Classroom)) {
            return (
                <>
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Classroom-Based'>supervised_user_circle</span>{sStartDate} - {sEndDate} - {sInstructor}
                </>
            );            
        }
    }
    
    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showInstanceDetail} onShow={handleShowInstanceDetail} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseInstanceDetail}>
            <Modal.Header closeButton>
            <Modal.Title><h3 style={styles.SubHeader}>{knowledgeName}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <KnowledgeInstanceDetail id={knowledgeInstanceID} assignUserID={assignUserID}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseInstanceDetail}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        {/* <Modal style={{background:'transparent', maxHeight:'100%'}} show={showInstructorList} onShow={handleShowInstructorList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseInstructorList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseInstructorList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal> */}

        {/* <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}> */}
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                            <Card.Subtitle>
                            {(showAlertMessage) ?
                                <div>
                                    <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} handleParentAlertClose={handleCloseAlert} /></h5>
                                </div>
                                :
                                    <></>
                                }
                            </Card.Subtitle>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {((!knowledgeinstance) || (knowledgeinstance.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Knowledge Tracks.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextLeft}>Knowledge</th>
                                                <th style={styles.tableTextLeft}>Type-Dates-Instructor</th>
                                                <th style={styles.tableText}>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {knowledgeinstance && knowledgeinstance.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{index+1}</td>
                                                        <td style={styles.tableTextLeft}>{assRec?.LearningObjectName}</td>
                                                        <td style={styles.tableTextLeft}>{displayDatesAndInstructor(assRec)}</td>
                                                        <td style={styles.tableText}>
                                                            <div>
                                                                <a onClick={() => handleViewChildernClick(assRec?.LearningObjectInstanceID, assRec?.LearningObjectName, assRec?.CourseNumber)}>
                                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='View Knowledge Track'>quick_reference</span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{mycalendar?.mycalendar?.RecordFound?.toLocaleString()}   Knowledge Tracks&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(mycalendar?.mycalendar?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(mycalendar?.mycalendar?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            {(mycalendar?.mycalendar?.RecordFound > 0) ? 
                                                <h5 style={styles.tableTextRight}> Page   {mycalendar?.mycalendar?.PagingMetaData?.PageNumber}  of  {mycalendar?.mycalendar?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                            :
                                                <></>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            {/* </div>
        </div> */}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      mycalendar: state.entity.mycalendar, 
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledges: (kParams) => dispatch(getKnowledges(kParams)),
        getUserCalendar: (kParams) => dispatch(getUserCalendar(kParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        processEnrollmentClear: () => dispatch(processEnrollmentClear()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeSearch)