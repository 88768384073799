import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, ListGroup, Badge  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPGEvaluations, getUserAttributesLookups, insertEvaluation, updateEvaluation, deleteEvaluation, 
    insertEvaluationQuestion, updateEvaluationQuestion, deleteEvaluationQuestion,
    insertEvaluationRating, deleteEvaluationRating, getReminders, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { AuditEntityType, MenuType } from '../../config/Constant';
import AuditTrail from '../layout/AuditTrail';
import { getPermissionFlags } from '../layout/Utils';
import ReminderList from './ReminderList';

const EvaluationList = (props) => {
    const { auth, companyData, profile, evaluations, userattributelookups, loadingMenu, lookupselections, fromLookupParam } = props;    
    const location  = useLocation();
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewPillar, setAddNewPillar] = useState(false);
    const [editPillar, setEditPillar] = useState(false);
    const [pillarEvaluationID, setPillarEvaluationID] = useState(-1);
    const [pillarName, setPillarName] = useState('');
    const [pillarDescription, setPillarDescription] = useState('');
    const [pillarWeight, setPillarWeight] = useState(100);
    const [pillarAnonymous, setPillarAnonymous] = useState('0');
    const [pillarRatingTypes, setPillarRatingTypes] = useState('1');
    const [pillarShowHeader, setPillarShowHeader] = useState('0');
    const [pillarUserEvaluation, setPillarUserEvaluation] = useState(false);
    const [pillarManagerEvaluation, setPillarManagerEvaluation] = useState(false);
    const [pillarPeerEvaluation, setPillarPeerEvaluation] = useState(false);
    const [pillarSubordinateEvaluation, setPillarSubordinateEvaluation] = useState(false);
    const [pillarUserEvaluationWeight, setPillarUserEvaluationWeight] = useState(0);
    const [pillarManagerEvaluationWeight, setPillarManagerEvaluationWeight] = useState(0);
    const [pillarPeerEvaluationWeight, setPillarPeerEvaluationWeight] = useState(0);
    const [pillarSubordinateEvaluationWeight, setPillarSubordinateEvaluationWeight] = useState(0);
    const [pillarMeritBased, setPillarMeritBased] = useState('0');
    const [pillarCompensationRate, setPillarCompensationRate] = useState(0);
    const [pillarReminderID, setPillarReminderID] = useState(-1);
    const [pillarReminderName, setPillarReminderName] = useState('');
    const [pillarProcessLevels, setPillarProcessLevels] = useState('0');
    const [pillarSteps, setPillarSteps] = useState([]);
    const [pillarSteps1, setPillarSteps1] = useState('');
    const [pillarStepID, setPillarStepID] = useState(-1);
    const [pillarStepName, setPillarStepName] = useState('');
    const [pillarNotificationID, setPillarNotificationID] = useState(-1);
    const [pillarProcessLevelTypeID, setPillarProcessLevelTypeID] = useState(102);
    const [showReminderList, setShowReminderList] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const evaluation = evaluations?.evaluations?.Evaluations;
    const evaluationquestions = evaluations?.evaluations?.EvaluationQuestions;
    const evaluationratings = evaluations?.evaluations?.EvaluationRatings;
    const ratingData = userattributelookups?.Ratings;
    const evalGroupData = userattributelookups?.EvaluationQuestionGroups;
    const processStepData = userattributelookups?.ProcessSteps;
    const notificationData = userattributelookups?.NotificationMessages;

    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }

    const [auditEntityID, setAuditEntityID] = useState(-1);
    const [showAudit, setShowAudit] = useState(false);
    const handleCloseAudit = () => {setShowAudit(false); }
    function handleShowAudit() {
        setShowAudit(true); 
    };
    async function handleAuditClick(iID) {
        setLoading(true);
        setAuditEntityID(iID);
        handleShowAudit();
        setLoading(false);
    }    

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = evaluations?.evaluations?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            EvaluationName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getPGEvaluations(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = evaluations?.evaluations?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            EvaluationName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getPGEvaluations(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.EvaluationID + '=' + row.EvaluationName ;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.Pillars, currentSelectedValue);
        return currentSelectedValue;
    }

    async function searchPillars() {
        setLoading(true);
        // let companyPillars = '';
        // const companyParameters = auth.UserCompanyParameters?.split('&');
        // companyParameters.map((parameter) => {
        //     const parameterDetails = parameter?.split('=');
        //     if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
        //         companyPillars = parameterDetails[1];
        //     }
        // });        
        // const evalParams1  = {
        //     UserID:auth.UserID,
        //     EvaluationIDs:companyPillars,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            EvaluationName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPGEvaluations(oParams);        
        setLoading(false);
    }
    
    const handleCloseReminderList = () => {
        setShowReminderList(false);
        updateReminderSelection();
    }

    async function handleShowReminderList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };              
        await props.getReminders(oParams);
        setShowReminderList(true); 
        setLoading(false);
    };

    function updateReminderSelection() {
        const sSelections = lookupselections?.Reminder;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1];//.split('|')[0];
            });
            setPillarReminderID(sSelectedIds);
            setPillarReminderName(sSelectedNames);
        }
    }

    const addInitialSliderArray = (evaluation) => {
        return evaluation?.map((evalRec) => ({
                            EvaluationID:evalRec.EvaluationID, 
                            EvaluationName:evalRec.EvaluationName,
                            EvaluationQuestionID:-1, 
                            EvaluationQuestionGroupID:-1,
                            DisplayOrder:-1,                    
                            Weight:-1,
                            Optional:false,
                            EvaluationQuestion:'',
                            EvaluationRatingTypeID:'',                                    
                            EditedEvaluationQuestionID:-1,
                            EvaluationEntitySubTypeID:-1, 
                            ShowComments:false, 
                            ExcludeFromCalculation:false, 
                            HideRatings:false, 
                            MultipleRatingsSelection:false, 
                            MinRatingScoreForComments:-1, 
                            MaxRatingScoreForComments:-1, 
                            ForceComments:false, 
                            EvaluationQuestionRatingTypes:'',             
                            takeAction:false,
                            showDetails:false,
                            disableNewQuestion:false,
                            newQuestionClicked:false,
                            editQuestionClicked:false,
                            newRatingClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(evaluation);
        setSliderValue(initialScoreValues);
    }, [evaluation])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleRatingsClick(e) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });
        setPillarRatingTypes(selectedValues);
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewPillar) ? setAddNewPillar(false) : setAddNewPillar(true);
        } else {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === rowIndex) {
                    if (tab === 2) {
                        (row.newQuestionClicked) ? row.newQuestionClicked = false : row.newQuestionClicked = true;                    
                    } else if (tab === 3) {
                        (row.newRatingClicked) ? row.newRatingClicked = false : row.newRatingClicked = true;                    
                    } 
                }
            });
            setSliderValue(updatedSliderValues);
        }

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 2) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === currentEval.EvaluationID) {
                    if (tab === 2) {
                        row.editQuestionClicked = false;                    
                        row.disableNewQuestion = false;
                        row.newQuestionClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        } else if (tab === 3) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === currentEval.EvaluationID) {
                    if (tab === 3) {
                        row.newRatingClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        } else if (tab === 1) {
            setEditPillar(false);
            setAddNewPillar(false);
            setPillarEvaluationID(-1);
            setPillarSteps([]);
            setPillarProcessLevels('');
            setPillarRatingTypes('');
        }
    }
    
    async function handleSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const evalParams  = {
                EvaluationEntityTypeID:4,
                EvaluationID: pillarEvaluationID,
                EvaluationName: pillarName,
                EvaluationHeader:pillarDescription,
                IsAnonymous:(pillarAnonymous === '1') ? true : false,
                UserEvaluationWeight:parseFloat(pillarWeight),
                EvaluationRatingTypes:pillarRatingTypes,
                ShowHeader:(pillarShowHeader === '1') ? true : false,
                UserEvaluation:pillarUserEvaluation,
                ManagerEvaluation:pillarManagerEvaluation,
                PeerEvaluation:pillarPeerEvaluation,
                SubordinateEvaluation:pillarSubordinateEvaluation,
                UserEvaluationWeight:parseFloat(pillarUserEvaluationWeight),
                ManagerEvaluationWeight:parseFloat(pillarManagerEvaluationWeight),
                PeerEvaluationWeight:parseFloat(pillarPeerEvaluationWeight),
                SubordinateEvaluationWeight:parseFloat(pillarSubordinateEvaluationWeight),
                MeritBased:(pillarMeritBased === '1') ? true : false,
                CompensationRate:parseFloat(pillarCompensationRate),
                ReminderID:parseInt(pillarReminderID),
                ProcessLevels:pillarProcessLevels,
                ProcessLevelTypeID:parseInt(pillarProcessLevelTypeID),
            };        
            console.log(evalParams);
            if ((editPillar) && (pillarEvaluationID > 0)) {
                await props.updateEvaluation(evalParams);    
            } else {
                await props.insertEvaluation(evalParams);    
            }
        } else if (tab === 2) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentEval.EvaluationID);
            const evalParams  = {
                EvaluationEntityTypeID:4,
                EvaluationID: currentEval.EvaluationID,
                EvaluationQuestionID: jpRequestList[0].EditedEvaluationQuestionID,
                EvaluationQuestionGroupID:jpRequestList[0]?.EvaluationQuestionGroupID,
                DisplayOrder:jpRequestList[0]?.DisplayOrder,
                Weight:jpRequestList[0]?.Weight,
                Optional:jpRequestList[0]?.Optional,
                EvaluationQuestion:jpRequestList[0]?.EvaluationQuestion,
                EvaluationEntitySubTypeID:jpRequestList[0]?.EvaluationEntitySubTypeID, 
                ShowComments:jpRequestList[0]?.ShowComments, 
                ExcludeFromCalculation:jpRequestList[0]?.ExcludeFromCalculation, 
                HideRatings:jpRequestList[0]?.HideRatings, 
                MultipleRatingsSelection:jpRequestList[0]?.MultipleRatingsSelection, 
                MinRatingScoreForComments:jpRequestList[0]?.MinRatingScoreForComments, 
                MaxRatingScoreForComments:jpRequestList[0]?.MaxRatingScoreForComments, 
                ForceComments:jpRequestList[0]?.ForceComments, 
                EvaluationQuestionRatingTypes:jpRequestList[0]?.EvaluationQuestionRatingTypes, 
            };        
            console.log(evalParams);
            console.log(jpRequestList);
            if ((jpRequestList) && (jpRequestList.length > 0) && (jpRequestList[0].editQuestionClicked) && (jpRequestList[0].EditedEvaluationQuestionID > 0)) {
                await props.updateEvaluationQuestion(evalParams);    
            } else {
                await props.insertEvaluationQuestion(evalParams);    
            }
        } if (tab === 3) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentEval.EvaluationID);
            const evalParams  = {
                EvaluationEntityTypeID:4,
                EvaluationID: currentEval.EvaluationID,
                EvaluationRatingTypeID:jpRequestList[0]?.EvaluationRatingTypeID,
           };        
            console.log(evalParams);
            await props.insertEvaluationRating(evalParams);    
        }

        // let companyPillars = '';
        // const companyParameters = auth.UserCompanyParameters?.split('&');
        // companyParameters.map((parameter) => {
        //     const parameterDetails = parameter?.split('=');
        //     if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
        //         companyPillars = parameterDetails[1];
        //     }
        // });        
        // const evalParams1  = {
        //     UserID:auth.UserID,
        //     EvaluationIDs:companyPillars,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            EvaluationName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPGEvaluations(oParams);        

        if (tab === 1) {
            setEditPillar(false);
            setAddNewPillar(false);
            setPillarEvaluationID(-1);
            setPillarSteps([]);
            setPillarProcessLevels('');
            setPillarRatingTypes('');
        }        
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const evalParams  = {
                EvaluationEntityTypeID:4,
                EvaluationID: pillarEvaluationID,
                EvaluationName: pillarName,
                EvaluationHeader:pillarDescription,
                IsAnonymous:(pillarAnonymous === '1') ? true : false,
                UserEvaluationWeight:parseFloat(pillarWeight),
                EvaluationRatingTypes:pillarRatingTypes,
                ShowHeader:(pillarShowHeader === '1') ? true : false,
                UserEvaluation:pillarUserEvaluation,
                ManagerEvaluation:pillarManagerEvaluation,
                PeerEvaluation:pillarPeerEvaluation,
                SubordinateEvaluation:pillarSubordinateEvaluation,
                UserEvaluationWeight:parseFloat(pillarUserEvaluationWeight),
                ManagerEvaluationWeight:parseFloat(pillarManagerEvaluationWeight),
                PeerEvaluationWeight:parseFloat(pillarPeerEvaluationWeight),
                SubordinateEvaluationWeight:parseFloat(pillarSubordinateEvaluationWeight),
                MeritBased:(pillarMeritBased === '1') ? true : false,
                CompensationRate:parseFloat(pillarCompensationRate),
                ReminderID:parseInt(pillarReminderID),
                ProcessLevels:pillarProcessLevels,
                ProcessLevelTypeID:parseInt(pillarProcessLevelTypeID),
            };        
            console.log(evalParams);
            await props.updateEvaluation(evalParams);    
        } else if (tab === 2) {
            const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentEval.EvaluationID);
            const evalParams  = {
                EvaluationEntityTypeID:4,
                EvaluationID: currentEval.EvaluationID,
                EvaluationQuestionID: currentEval.EditedEvaluationQuestionID,
                EvaluationQuestionGroupID:jpRequestList[0]?.EvaluationQuestionGroupID,
                DisplayOrder:jpRequestList[0]?.DisplayOrder,
                Weight:jpRequestList[0]?.Weight,
                Optional:jpRequestList[0]?.Optional,
                EvaluationQuestion:jpRequestList[0]?.EvaluationQuestion,
                EvaluationEntitySubTypeID:jpRequestList[0]?.EvaluationEntitySubTypeID, 
                ShowComments:jpRequestList[0]?.ShowComments, 
                ExcludeFromCalculation:jpRequestList[0]?.ExcludeFromCalculation, 
                HideRatings:jpRequestList[0]?.HideRatings, 
                MultipleRatingsSelection:jpRequestList[0]?.MultipleRatingsSelection, 
                MinRatingScoreForComments:jpRequestList[0]?.MinRatingScoreForComments, 
                MaxRatingScoreForComments:jpRequestList[0]?.MaxRatingScoreForComments, 
                ForceComments:jpRequestList[0]?.ForceComments, 
                EvaluationQuestionRatingTypes:jpRequestList[0]?.EvaluationQuestionRatingTypes, 
            };        
            console.log(evalParams);
            await props.updateEvaluationQuestion(evalParams);    
        } 

        // let companyPillars = '';
        // const companyParameters = auth.UserCompanyParameters?.split('&');
        // companyParameters.map((parameter) => {
        //     const parameterDetails = parameter?.split('=');
        //     if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
        //         companyPillars = parameterDetails[1];
        //     }
        // });        
        // const evalParams1  = {
        //     UserID:auth.UserID,
        //     EvaluationIDs:companyPillars,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            EvaluationName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPGEvaluations(oParams);        

        if (tab === 1) {
            setEditPillar(false);
            setAddNewPillar(false);
            setPillarEvaluationID(-1);
            setPillarSteps([]);
            setPillarProcessLevels('');
            setPillarRatingTypes('');
        }        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 2) {
            const evalEditedQuestions = evaluationquestions?.filter((evalRec) => (evalRec.EvaluationQuestionID === id1));

            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.EvaluationID === id) {
                    if (tab === 2) {
                        row.editQuestionClicked = true;                    
                        row.disableNewQuestion = true;
                        row.newQuestionClicked = false;
                        row.EditedEvaluationQuestionID = id1;
                        row.EvaluationQuestionID = id1;
                        row.EvaluationQuestionGroupID = evalEditedQuestions[0]?.EvaluationQuestionGroupID;
                        row.DisplayOrder = evalEditedQuestions[0]?.DisplayOrder;
                        row.Weight = evalEditedQuestions[0]?.Weight;
                        row.Optional = evalEditedQuestions[0]?.Optional;
                        row.EvaluationQuestion = evalEditedQuestions[0]?.EvaluationQuestion;
                        row.EvaluationEntitySubTypeID = evalEditedQuestions[0]?.EvaluationEntitySubTypeID;
                        row.ShowComments = evalEditedQuestions[0]?.ShowComments;
                        row.ExcludeFromCalculation = evalEditedQuestions[0]?.ExcludeFromCalculation;
                        row.HideRatings = evalEditedQuestions[0]?.HideRatings; 
                        row.MultipleRatingsSelection = evalEditedQuestions[0]?.MultipleRatingsSelection; 
                        row.MinRatingScoreForComments = evalEditedQuestions[0]?.MinRatingScoreForComments;
                        row.MaxRatingScoreForComments = evalEditedQuestions[0]?.MaxRatingScoreForComments; 
                        row.ForceComments = evalEditedQuestions[0]?.ForceComments; 
                        row.EvaluationQuestionRatingTypes = evalEditedQuestions[0]?.EvaluationQuestionRatingTypes;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        } else if (tab === 1) {
            setEditPillar(true);
            setPillarEvaluationID(id);
            const evalEdited = evaluation?.filter((evalRec) => (evalRec.EvaluationID === id));
            if ((evalEdited) && (evalEdited.length > 0)) {
                setPillarName(evalEdited[0].EvaluationName);
                setPillarDescription(evalEdited[0].EvaluationHeader);
                setPillarWeight(evalEdited[0].Weight);
                setPillarAnonymous(evalEdited[0].IsAnonymous);
                setPillarRatingTypes(evalEdited[0].EvaluationRatingTypes);
                setPillarShowHeader((evalEdited[0].ShowHeader === true) ? 1 : 0);
                setPillarUserEvaluation(evalEdited[0].UserEvaluation);
                setPillarManagerEvaluation(evalEdited[0].ManagerEvaluation);
                setPillarPeerEvaluation(evalEdited[0].PeerEvaluation);
                setPillarSubordinateEvaluation(evalEdited[0].SubordinateEvaluation);
                setPillarUserEvaluationWeight(evalEdited[0].UserEvaluationWeight);
                setPillarManagerEvaluationWeight(evalEdited[0].ManagerEvaluationWeight);
                setPillarPeerEvaluationWeight(evalEdited[0].PeerEvaluationWeight);
                setPillarSubordinateEvaluationWeight(evalEdited[0].SubordinateEvaluationWeight);
                setPillarMeritBased((evalEdited[0].MeritBased === true) ? 1 : 0);
                setPillarCompensationRate(evalEdited[0].CompensationRate);
                setPillarReminderID(evalEdited[0].ReminderID);
                setPillarProcessLevels(evalEdited[0].ProcessLevels);
                setPillarProcessLevelTypeID(evalEdited[0].ProcessLevelTypeID);    
                // PopulateProcessLevelsSteps(evalEdited[0].ProcessLevels);
            }
        } 
        setLoading(false);
    }

    function checkedDisableNewQuestion(tab, id, id1) {
        let bDisabled = false;
        if (tab === 2) {
            sliderValue.map((row, index) => {
                if (row.EvaluationID === id) {
                    if (tab === 2) {
                        bDisabled = row.disableNewQuestion;
                    } 
                }
            });
        }
        return bDisabled;
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);
        // let companyPillars = '';
        // const companyParameters = auth.UserCompanyParameters?.split('&');
        // companyParameters.map((parameter) => {
        //     const parameterDetails = parameter?.split('=');
        //     if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
        //         companyPillars = parameterDetails[1];
        //     }
        // });        

        if (tab === 1) {
            const evalParams  = {
                EvaluationID: id,
            };        
            console.log(evalParams);
            await props.deleteEvaluation(evalParams);    
        } else if (tab === 2) {
            const evalParams  = {
                EvaluationQuestionID: id1,
            };        
            console.log(evalParams);
            await props.deleteEvaluationQuestion(evalParams);    
        } else if (tab === 3) {
            const evalParams  = {
                EvaluationRatingID: id1,
            };        
            console.log(evalParams);
            //await props.deleteEvaluationRating(evalParams);    
        }
        
        // const evalParams1  = {
        //     UserID:auth.UserID,
        //     EvaluationIDs:companyPillars,
        //     SearchFilter:nodeName,
        // };        
        const oParams = {
            EvaluationName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPGEvaluations(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.EvaluationID === rowIndex) {
                if (tab === 1) {
                    row.EvaluationQuestionGroupID = evalGroupData[value].EvaluationQuestionGroupID;
                } else if (tab === 2) {
                    row.DisplayOrder = value;                    
                } else if (tab === 3) {
                    row.Weight = value;
                } else if (tab === 4) {
                    row.Optional = (value === '1') ? true : false;                    
                } else if (tab === 5) {
                    row.EvaluationQuestion = value;                    
                } else if (tab === 6) {
                    row.EvaluationRatingTypeID = value;                    
                } else if (tab === 7) {
                    row.EvaluationEntitySubTypeID = value;                    
                } else if (tab === 8) {
                    row.ShowComments = (value === '1') ? true : false;                    
                } else if (tab === 9) {
                    row.ExcludeFromCalculation = (value === '1') ? true : false;                    
                } else if (tab === 10) {
                    row.HideRatings = (value === '1') ? true : false;                    
                } else if (tab === 11) {
                    row.MultipleRatingsSelection = (value === '1') ? true : false;                    
                } else if (tab === 12) {
                    row.MinRatingScoreForComments = value;                    
                } else if (tab === 13) {
                    row.MaxRatingScoreForComments = value;                    
                } else if (tab === 14) {
                    row.ForceComments = (value === '1') ? true : false;                    
                } else if (tab === 15) {
                    let selectedValues = '';
                    [...value.target.selectedOptions].map((sSelOption) => {
                        if (selectedValues.trim() !== '') {
                            selectedValues += ',';
                        }
                        selectedValues += sSelOption.value;
                    });
                    row.EvaluationQuestionRatingTypes = selectedValues;
                } 
            }        
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function handleEvaluationTypeClicked(type) {
        if (type === 1) {
            if (pillarUserEvaluation === true) {
                setPillarUserEvaluation(false);
            } else {
                setPillarUserEvaluation(true);
            }
        } else if (type === 2) {
            if (pillarManagerEvaluation === true) {
                setPillarManagerEvaluation(false);
            } else {
                setPillarManagerEvaluation(true);
            }
        } else if (type === 3) {
            if (pillarPeerEvaluation === true) {
                setPillarPeerEvaluation(false);
            } else {
                setPillarPeerEvaluation(true);
            } 
        } else if (type === 4) {
            if (pillarSubordinateEvaluation === true) {
                setPillarSubordinateEvaluation(false);
            } else {
                setPillarSubordinateEvaluation(true);
            }
        }
    }

    function displayProcessStepsAsList() {
        if (pillarProcessLevels && pillarProcessLevels !== '') {
            const sParams = pillarProcessLevels?.split('&');
        
            return (
                sParams.map((item) => {
                    const key = item?.split('=')[0];
                    const value = item?.split('=')[1];
                    const sValueParams = value?.split(',');
        
                    if (key === 'PLS') {
                        // let stepIndex = 1;
        
                        return (
                            <ListGroup>
                                {sValueParams.map((sValues, index) => {
                                    const stepIndex = index + 1; // Adjust for 1-based indexing
                                    const sIndValues = sValues.split(':');
                                    let oListItem = null; // Default to null
                                    const iStepID = parseInt(sIndValues[1]);
                                    const iNotificationID = parseInt(sIndValues[2]);
                                    const processStep = processStepData?.filter((oRow) => oRow.ProcessStepID === iStepID);
                                    const notificationMessage = notificationData?.filter((oRow) => oRow.NotificationMessageID === iNotificationID);
                                    let sStepName = '';
                                    let sNotificationName = '';
                                    if ((processStep) && (processStep.length > 0)) {
                                        sStepName = processStep[0].ProcessStepName;
                                    }
                                    if ((notificationMessage) && (notificationMessage.length > 0)) {
                                        sNotificationName = notificationMessage[0].NotificationMessageName;
                                    }
                                    
                                    if (sIndValues && sIndValues.length === 3) {
                                        oListItem = (
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={stepIndex}>
                                                <div className="ms-2 me-auto">
                                                    <div>Step {stepIndex.toString()} - {sStepName}</div>
                                                    Notification - {sNotificationName}
                                                </div>
                                                {(stepIndex > 1) && (
                                                    <a onClick={() => handleUpDownStepClick(stepIndex, 1)}>
                                                        <span style={styles.ActionIconGrey} className="material-symbols-outlined" title="Move Step Up">move_up</span>
                                                    </a>
                                                )}
                                                {(stepIndex !== sValueParams.length) && (
                                                    <a onClick={() => handleUpDownStepClick(stepIndex, 0)}>
                                                        <span style={styles.ActionIconGrey} className="material-symbols-outlined" title="Move Step Down">move_down</span>
                                                    </a>
                                                )}
                                                <a onClick={() => handleRemoveStepClick(stepIndex)}>
                                                    <span style={styles.ActionIconGrey} className="material-symbols-outlined" title="Remove Step">delete</span>
                                                </a>
                                            </ListGroup.Item>
                                        );
                                    }
                                    {/* stepIndex++; */}
                                    return oListItem; // Return listItem (or null if invalid)
                                })}
                            </ListGroup>
                        );
                    }
                    return null; // Handle cases where `key !== 'PLS'`
                })
            );
        } else {
            return (<></>);
        }        
    }

    function PopulateProcessLevelsSteps(sProcessLevels) {
        //PL=102&PLS=1:-1,2:-1
        const processSteps = [];
        const sParams = sProcessLevels.split('&');
        sParams.map((item) => {
            let stepIndex = 1;
            const key = item.split('=')[0];
            const value = item.split('=')[1];
            const sValueParams = value.split(',');
            if (key === 'PLS') {
                sValueParams.map((sValues) => {
                    const sIndValues = sValues.split(':');
                    if ((sIndValues) && (sIndValues.length === 3)) {
                        const newStep = {
                            title: 'Step ' + stepIndex.toString(),
                            cardTitle: sIndValues[1],
                            cardSubtitle: 'Notification - ' + sIndValues[2],
                            cardDetailedText: ''
                        };
                        processSteps.push(newStep);
                        stepIndex++;
                    }    
                })    
            }
        });

        // console.log(processSteps);
        setPillarSteps(processSteps);
        //PL=2&PLCY=2024&PN1=103&PN2=104&PN3=23&PN6=24&PN7=25&TMSFormLOs=3502or3942,3509or3957or3510,3524or3960,3515or3956,3506or3948or3321,3503or3940,3511or3941,3526or3959,3518or3958,3492or3316or3937,3494or3307or3908&PLECVQ=6259|6260,87|6261,6262|6263&PLECQ=6264|6265,6266|6267,6268|6269,6270|6271,6272|6273&Groups=8,9,10&CompetencyQuestionIDs=13309,13310,13311,13312,13313,13314,13315,13316,13317,13318&CompetenciesRatingTypeID=580=Developing,581=Competent,582=Proficient,579=Expert        
    }

    function handleAddStepClick() {
        if (pillarProcessLevels && pillarStepID && pillarNotificationID) {
            const sections = pillarProcessLevels.split('&'); // Split into key-value pairs
            let updated = false;
            let newPLSValue = pillarStepID + ':' + pillarNotificationID; 
        
            const updatedSections = sections.map((section) => {
                const [key, value] = section.split('=');
        
                if (key === 'PLS') {
                    const stepIndex = value.split(',').length + 1;
                    newPLSValue = stepIndex.toString() + ':' + pillarStepID + ':' + pillarNotificationID;
                    console.log(newPLSValue);
                    // Append the new PLS value
                    const updatedValue = `${value},${newPLSValue}`;
                    updated = true;
                    return `${key}=${updatedValue}`;
                }
        
                return section; // Return unchanged sections
            });
        
            // If no PLS section existed, add it
            if (!updated) {
                newPLSValue = '1:' + pillarStepID + ':' + pillarNotificationID;
                updatedSections.push(`PLS=${newPLSValue}`);
            }
        
            const newProcessLevels = updatedSections.join('&')
            console.log(newProcessLevels);
            setPillarProcessLevels(newProcessLevels); // Recombine sections into a single string                
        }
    }

    function handleRemoveStepClick(index) {
        if (!pillarProcessLevels || pillarProcessLevels === '') {
            return; // Do nothing if no process levels are defined
        }
    
        const sParams = pillarProcessLevels.split('&');
        const updatedParams = sParams.map((item) => {
            const key = item.split('=')[0];
            const value = item.split('=')[1];
            
            // Only modify the PLS section
            if (key === 'PLS') {
                const sValueParams = value.split(',');
                // Remove the item at the given index (adjust for 0-based indexing)
                const updatedValues = sValueParams.filter((_, idx) => idx + 1 !== index);
    
                // Reindex the remaining steps sequentially
                const reindexedValues = updatedValues.map((value, idx) => {
                    const parts = value.split(':'); // Split the step into parts
                    parts[0] = (idx + 1).toString(); // Update the index (1-based)
                    return parts.join(':'); // Rejoin the step
                });
    
                // Reassign updated and reindexed PLS values
                return `${key}=${reindexedValues.join(',')}`;
            }
            
            return item; // Keep other sections as-is
        });
    
        // Join the updated params back into a single string
        const updatedProcessLevels = updatedParams.join('&');
    
        setPillarProcessLevels(updatedProcessLevels);
    }
    
    function handleUpDownStepClick(index, isUp) {
        const processLevel = pillarProcessLevels;
    
        // Split the processLevel into key-value pairs
        const sections = processLevel.split('&');
        let updatedPLS = '';
    
        const updatedSections = sections.map((section) => {
            const [key, value] = section.split('=');
    
            if (key === 'PLS') {
                // Split PLS into individual values
                const items = value.split(',');
    
                // Ensure index is within range
                if (index <= 1 && isUp === 1) return section; // Cannot move up the first index
                if (index >= items.length && isUp === 0) return section; // Cannot move down the last index
    
                const targetIndex = isUp ? index - 2 : index; // Calculate the target index for swapping
    
                // Swap the current index with the target index
                [items[index - 1], items[targetIndex]] = [items[targetIndex], items[index - 1]];
    
                updatedPLS = `${key}=${items.join(',')}`;
                return updatedPLS;
            }
    
            return section; // Return unchanged sections
        });
    
        // Recombine sections into the updated processLevel string
        const sTempProcessLevels = updatedSections.join('&');
        setPillarProcessLevels(sTempProcessLevels);
    }

    const handleStepSelected = (item) => {
        setPillarStepID(item.index);
    };

    function checkMainPillar(tab, id, id1) {
        if (!fromLookup) {
            let companyPillars = '';
            let isMainPillar = true;
            const companyParameters = auth.UserCompanyParameters?.split('&');
            companyParameters.map((parameter) => {
                const parameterDetails = parameter?.split('=');
                if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                    companyPillars = parameterDetails[1];
                    isMainPillar = ("," + companyPillars + ",").includes("," + id.toString() + ",");
                }
            });

            if (permissionFlags.CanModify) {
                if (!isMainPillar) {
                    if (tab === 2) {
                        return (
                            <div>
                                <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Evaluation Question'>edit</span>
                                </a>
                                {(permissionFlags.CanDelete) ?  
                                    <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Evaluation Question'>delete</span>
                                    </a>
                                : 
                                    <></>
                                }
                            </div>
                        );                
                    } if (tab === 3) {
                        return (
                            <div>
                                <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Evaluation Rating'>edit</span>
                                </a>
                                {(permissionFlags.CanDelete) ?  
                                    <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Evaluation Rating'>delete</span>
                                    </a>
                                    : 
                                    <></>
                                }
                            </div>
                        );                
                    } else {
                    return (
                        <div>
                            <a onClick={() => handleAuditClick(id)}>
                                <span style={styles.ActionIconGreyBold} class="material-symbols-outlined" title='Show Audit'>history_2</span>
                            </a>
                            <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Evaluation'>edit</span>
                            </a>
                            {(permissionFlags.CanDelete) ?  
                                <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Evaluation'>delete</span>
                                </a>
                            : 
                                <></>
                            }
                        </div>
                    );}            
                } else {
                    return (<></>)
                }    
            } else {
                return (<></>)
            }
        } else {
            return (<></>)
        }
    }

    function displayNewPillar() {
        if ((!fromLookup) && ((addNewPillar) || (editPillar))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title style={styles.tableSubHeaderCenterBold}>
                        {(addNewPillar) ? 'New Pillar' : 'Edit Pillar'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <table class="table table-sm caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', width:'65%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="pillarName" style={styles.LabelDataEntryNoPadding}>Name</label>
                                        <input id="pillarName" type="text" style={styles.TextDataEntryNoPadding} defaultValue={pillarName} onChange={(e) => (setPillarName(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', width:'25%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="anonymous" style={styles.LabelDataEntryNoPadding}>Anonymous</label>
                                        <select id="anonymous" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={pillarAnonymous} onChange={(e) => (setPillarAnonymous(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', width:'90%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Header</label>
                                        <input id="description" type="text" style={styles.TextDataEntryNoPadding} defaultValue={pillarDescription} onChange={(e) => (setPillarDescription(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td colSpan='1' style={{textAlign:'left', width:'10%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="showheader" style={styles.LabelDataEntryNoPadding}>Show Header</label>
                                        <select id="showheader" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={pillarShowHeader} onChange={(e) => (setPillarShowHeader(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </div>
                                </td>
                            </tr>              
                            <tr>
                                <td style={{textAlign:'left', width:'65%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div class='row'>
                                        <div>
                                            <label style={styles.LabelDataEntryNoPadding}>Type</label>
                                        </div>
                                        <div class='col'>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" id={`switch${'detail' + 'usereval'}`} 
                                                    checked={pillarUserEvaluation} 
                                                    style={(pillarUserEvaluation) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                    onChange={(e) => (handleEvaluationTypeClicked(1))}> 
                                                    {/* onChange={(e) => (setPillarUserEvaluation(e.target.value))}>  */}
                                                    </input>
                                                <label style={styles.LabelDataEntryNoPadding}>Self</label>
                                            </div>
                                        </div>
                                        <div class='col'>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" id={`switch${'detail' + 'maneval'}`} 
                                                    checked={pillarManagerEvaluation} 
                                                    style={(pillarManagerEvaluation) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                    onChange={(e) => (handleEvaluationTypeClicked(2))}> 
                                                </input>
                                                <label style={styles.LabelDataEntryNoPadding}>Manager</label>
                                            </div>                                            
                                        </div>
                                        <div class='col'>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" id={`switch${'detail' + 'peereval'}`} 
                                                    checked={pillarPeerEvaluation} 
                                                    style={(pillarPeerEvaluation) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                    onChange={(e) => (handleEvaluationTypeClicked(3))}> 
                                                </input>
                                                <label style={styles.LabelDataEntryNoPadding}>Peer</label>
                                            </div>                                            
                                        </div>
                                        <div class='col'>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" id={`switch${'detail' + 'subeval'}`} 
                                                    checked={pillarSubordinateEvaluation} 
                                                    style={(pillarSubordinateEvaluation) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                    onChange={(e) => (handleEvaluationTypeClicked(4))}> 
                                                </input>
                                                <label style={styles.LabelDataEntryNoPadding}>Subordinate</label>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div>
                                            <label style={styles.LabelDataEntryNoPadding}>Weight</label>
                                        </div>
                                        <div class='col'>
                                            <div>
                                                <input id="weight" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarUserEvaluationWeight} onChange={(e) => (setPillarUserEvaluationWeight(e.target.value))}/>
                                            </div>                    
                                        </div>
                                        <div class='col'>
                                            <div>
                                                <input id="weight" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarManagerEvaluationWeight} onChange={(e) => (setPillarManagerEvaluationWeight(e.target.value))}/>
                                            </div>                    
                                        </div>
                                        <div class='col'>
                                            <div>
                                                <input id="weight" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarPeerEvaluationWeight} onChange={(e) => (setPillarPeerEvaluationWeight(e.target.value))}/>
                                            </div>                    
                                        </div>
                                        <div class='col'>
                                            <div>
                                                <input id="weight" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarSubordinateEvaluationWeight} onChange={(e) => (setPillarSubordinateEvaluationWeight(e.target.value))}/>
                                            </div>                    
                                        </div>
                                    </div>
                                </td>
                                <td style={{textAlign:'left', width:'25%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="anonymous" style={styles.LabelDataEntryNoPadding}>Merit based</label>
                                        <select id="anonymous" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={pillarMeritBased} onChange={(e) => (setPillarMeritBased(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', width:'10%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="compensationrate" style={styles.LabelDataEntryNoPadding}>Compensation Rate</label>
                                        <input id="compensationrate" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarCompensationRate} onChange={(e) => (setPillarCompensationRate(e.target.value))}/>
                                    </div>                    
                                </td>
                            </tr>              
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="processlevel" style={styles.LabelDataEntryNoPadding}>Process Level</label>
                                        {displayProcessStepsAsList()}
                                    </div>                    
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', width:'65%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                        <select id="reqRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} defaultValue={pillarRatingTypes?.split(',')} onChange={(e) => (handleRatingsClick(e))}>
                                            {ratingData?.map((rating, supIndex) => (
                                                <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <a onClick={() => setShowReminderList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Reminder List'>mystery</span>                                    
                                                Reminder
                                            </label>
                                        </a>
                                        <input id="reminderid" type="text" disabled style={styles.TextDataEntryNoPadding} value={pillarReminderName}/>                                    
                                    </div>
                                </td>
                                <td style={{textAlign:'left', width:'10%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="weight" style={styles.LabelDataEntryNoPadding}>Weight</label>
                                        <input id="weight" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={pillarWeight} onChange={(e) => (setPillarWeight(e.target.value))}/>
                                    </div>                    
                                </td>
                            </tr>  
                            <tr>
                                <td style={{textAlign:'left', width:'65%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="stepname" style={styles.LabelDataEntryNoPadding}>Step Name</label>
                                        <select id="stepname" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={pillarStepID} onChange={(e) => (setPillarStepID(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            {processStepData?.map((step, supIndex) => (
                                                <option value={step.ProcessStepID}>{step.ProcessStepName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', width:'25%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="notification" style={styles.LabelDataEntryNoPadding}>Notification</label>
                                        <select id="notification" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={pillarNotificationID} onChange={(e) => (setPillarNotificationID(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            {notificationData?.map((notification, supIndex) => (
                                                <option value={notification.NotificationMessageID}>{notification.NotificationMessageName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'center', width:'10%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Add Step</label>
                                    </div>
                                    <div>
                                        <a onClick={() => handleAddStepClick()}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Step'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>  
                        </tbody>
                    </table>                                   
                </Card.Body>
                <Card.Footer>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-auto">
                            <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Pillar'>Save</Button>
                        </div>
                        <div class="col-auto">
                            <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Pillar'>Cancel</Button>
                        </div>
                    </div>
                </Card.Footer>
            </Card>
            );
        }
    }

    function displayNewQuestion(currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentJP.EvaluationID);
        const evalEditedQuestions = evaluationquestions?.filter((evalRec) => (evalRec.EvaluationQuestionID === jpRequestList[0]?.EditedEvaluationQuestionID));

        if ((jpRequestList[0].newQuestionClicked) || (jpRequestList[0].editQuestionClicked)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title style={styles.tableSubHeaderCenterBold}>
                        {(jpRequestList[0].newQuestionClicked) ? 'New Question' : 'Edit Question'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table caption-top text-left">  
                    <tbody> 
                        <tr>                          
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="reqNotes" style={styles.LabelDataEntryNoPadding}>Question</label>
                                    <input id="reqNotes" type="text" style={styles.TextDataEntryNoPadding} defaultValue={evalEditedQuestions[0]?.EvaluationQuestion} onChange={(e) => (handleAddChildernValues(5, currentJP.EvaluationID, e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="optional" style={styles.LabelDataEntryNoPadding}>Optional</label>
                                    <select id="optional" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={(evalEditedQuestions[0]?.Optional) ? '1' : '0'} onChange={(e) => (handleAddChildernValues(4, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="displayorder" style={styles.LabelDataEntryNoPadding}>Display Order</label>
                                    <input id="displayorder" type="number" min='1' defaultValue={evalEditedQuestions[0]?.DisplayOrder} style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(2, currentJP.EvaluationID, e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="qweight" style={styles.LabelDataEntryNoPadding}>Weight</label>
                                    <input id="qweight" type="number" min='1' max='100' step='1' defaultValue={evalEditedQuestions[0]?.Weight} style={styles.TextDataEntryNoPadding} onChange={(e) => (handleAddChildernValues(3, currentJP.EvaluationID, e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Group</label>
                                    <select id="requirementtype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.EvaluationQuestionGroupID} onChange={(e) => (handleAddChildernValues(1, currentJP.EvaluationID, e.target.selectedIndex))}>
                                        <option value="-1">Select One</option>
                                        {evalGroupData.map((requirement, supIndex) => (
                                            <option value={requirement.EvaluationQuestionGroupID}>{requirement.EvaluationQuestionGroupName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="forcecomments" style={styles.LabelDataEntryNoPadding}>Force Comments</label>
                                    <select id="forcecomments" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.ForceComments ? '1' :'0'}  onChange={(e) => (handleAddChildernValues(14, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="forcecommentsminscore" style={styles.LabelDataEntryNoPadding}>Force Comments Min. Score</label>
                                    <input id="forcecommentsminscore" type="number" min='1' style={styles.TextDataEntryNoPadding} defaultValue={evalEditedQuestions[0]?.MinRatingScoreForComments} onChange={(e) => (handleAddChildernValues(12, currentJP.EvaluationID, e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="forcecommentsminscore" style={styles.LabelDataEntryNoPadding}>Force Comments Max. Score</label>
                                    <input id="forcecommentsminscore" type="number" min='1' style={styles.TextDataEntryNoPadding} defaultValue={evalEditedQuestions[0]?.MaxRatingScoreForComments} onChange={(e) => (handleAddChildernValues(13, currentJP.EvaluationID, e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="subtype" style={styles.LabelDataEntryNoPadding}>Sub Type</label>
                                    <select id="subtype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.EvaluationEntitySubTypeID} onChange={(e) => (handleAddChildernValues(7, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>User</option>        
                                        <option value='2'>Manager</option>        
                                        <option value='3'>Peer</option>        
                                        <option value='4'>Subordinate</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="multiratings" style={styles.LabelDataEntryNoPadding}>Multiple Ratings</label>
                                    <select id="multiratings" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.MultipleRatingsSelection ? '1' :'0'}  onChange={(e) => (handleAddChildernValues(11, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="exccal" style={styles.LabelDataEntryNoPadding}>Exclude From Calculation</label>
                                    <select id="exccal" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.ExcludeFromCalculation ? '1' :'0'}  onChange={(e) => (handleAddChildernValues(9, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="hideratings" style={styles.LabelDataEntryNoPadding}>Hide Ratings</label>
                                    <select id="hideratings" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.HideRatings ? '1' :'0'}  onChange={(e) => (handleAddChildernValues(10, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'65%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="questionratings" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="questionratings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} defaultValue={evalEditedQuestions[0]?.EvaluationQuestionRatingTypes?.split(',')} onChange={(e) => (handleAddChildernValues(15, currentJP.EvaluationID, e))}>
                                        {ratingData?.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="showcomments" style={styles.LabelDataEntryNoPadding}>Show Comments</label>
                                    <select id="showcomments" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedQuestions[0]?.ShowComments ? '1' :'0'}  onChange={(e) => (handleAddChildernValues(8, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
                </Card.Body>
                <Card.Footer>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-auto">
                            <Button onClick={() => handleSaveChildernClick(2, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Question'>Save</Button>
                        </div>
                        <div class="col-auto">
                            <Button onClick={() => handleCancelChildernClick(2, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Question'>Cancel</Button>
                        </div>
                    </div>
                </Card.Footer>
                </Card>  
            );
        }
    }

    function displayNewRating(currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentJP.EvaluationID);
        if (jpRequestList[0].newRatingClicked) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title style={styles.tableSubHeaderCenterBold}>
                        New Rating
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="reqRatings1" style={styles.LabelDataEntryNoPadding}>Ratings</label>
                                    <select id="reqRatings1" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100WBG} onChange={(e) => (handleAddChildernValues(6, currentJP.EvaluationID, e.target.value))}>
                                        <option value="-1">Select One</option>
                                        {ratingData.map((rating, supIndex) => (
                                            <option value={rating.EvaluationRatingTypeID}>{rating.EvaluationRatingTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
                </Card.Body>
                <Card.Footer>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-auto">
                            <Button onClick={() => handleSaveChildernClick(3, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Rating'>Save</Button>
                        </div>
                        <div class="col-auto">
                            <Button onClick={() => handleCancelChildernClick(3, currentJP)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Rating'>Cancel</Button>
                        </div>
                    </div>
                </Card.Footer>
                </Card>  
            );
        }
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.EvaluationID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);//[...sliderValue];
        updatedSliderValues.map((row, index) => {
            if (row.EvaluationID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    function displayAddButtons(tabValue, currentJP) {
        const jpRequestList = sliderValue.filter((jobP) => jobP.EvaluationID === currentJP.EvaluationID);

        if (permissionFlags.CanCreate) {
            if (tabValue === 2 ) {
                if (!jpRequestList[0].disableNewQuestion) {
                    if (jpRequestList[0].newQuestionClicked) {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentJP.EvaluationID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Question'>shadow_minus</span>
                            </a>
                        );
                    } else {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentJP.EvaluationID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Question'>note_add</span>
                            </a>
                        );
                    }
                }
            } else if (tabValue === 3 ) {
                if (jpRequestList[0].newRatingClicked) {
                    return (
                        <a onClick={() => handleAddChildernClick(3, currentJP.EvaluationID)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Rating'>shadow_minus</span>
                        </a>
                    );
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(3, currentJP.EvaluationID)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Rating'>note_add</span>
                        </a>
                    );
                }
            }     
        }
    }

    function getDetailSwitchTR(currentEval) {
        const evalRequestList = sliderValue?.filter((evalRec) => evalRec.EvaluationID === currentEval.EvaluationID);
        const evalQuestions = evaluationquestions?.filter((evalRec) => (evalRec.EvaluationID === currentEval.EvaluationID));
        const evalRatings = evaluationratings?.filter((evalRec) => (evalRec.EvaluationID === currentEval.EvaluationID));
        if ((!fromLookup) && (evalRequestList[0]?.showDetails)) {
            return (
                <tr id={`tr${'detail' + currentEval.EvaluationID}`}>
                <td colSpan="6"  style={{ backgroundColor:companyData?.PagesBGColor}}> 
                    <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Questions</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentEval)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="6" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                    {displayNewQuestion(currentEval)}
                                    {(evalQuestions.length === 0) ? <></> :
                                        <table class="table table-sm caption-top">  
                                            <thead>
                                                <tr>
                                                    <th style={styles.tableText}>#</th>
                                                    <th style={styles.tableText}>ID</th>
                                                    <th style={styles.tableText}>Group</th>
                                                    <th style={styles.tableTextMain}>Question</th>
                                                    <th style={styles.tableText}>Order</th>
                                                    <th style={styles.tableText}>Weight</th>
                                                    <th style={styles.tableText}>Optional</th>
                                                    <th style={styles.tableText}>Action</th>
                                                </tr>
                                            </thead>                        
                                            <tbody> 
                                                {getEvaluationQuestions(evalQuestions)}  
                                            </tbody> 
                                        </table> 
                                        }
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    

                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Ratings</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(3, currentEval)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="6"  style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                    {displayNewRating(currentEval)}
                                    {(evalRatings.length === 0) ? <></> : 
                                        <table class="table table-sm caption-top">  
                                            <thead>
                                                <tr>
                                                    <th style={styles.tableText}>#</th>
                                                    <th style={styles.tableText}>ID</th>
                                                    <th style={styles.tableTextMain}>Name</th>
                                                    <th style={styles.tableText}>Score</th>
                                                    <th style={styles.tableText}>Order</th>
                                                    <th style={styles.tableText}>Color</th>
                                                    <th style={styles.tableText}>Delete</th>
                                                </tr>
                                            </thead>                        
                                            <tbody> 
                                                {getEvaluationRatings(evalRatings)}  
                                            </tbody>
                                        </table>    
                                    }
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                </td> 
            </tr>             
            );
        }
    }

    function getEvaluationQuestions(evalQuestions) {
        if (evalQuestions.length === 0) {
            return (
                <></>
            );
        } else {
            return evalQuestions.map((question, questionIndex) => (
                <tr> 
                    <td style={styles.tableText}>{questionIndex + 1}</td> 
                    <td style={styles.tableText}>{question?.EvaluationQuestionID}</td> 
                    <td style={styles.tableTextLeft}>{question?.EvaluationQuestionGroupName}</td> 
                    <td style={styles.tableTextLeft}>{question?.EvaluationQuestion}</td> 
                    <td style={styles.tableText}>{question?.DisplayOrder}</td> 
                    <td style={styles.tableText}>{(question?.Weight) ? question?.Weight : '100'}</td> 
                    <td style={styles.tableText}>{(question?.Optional) ? 'Yes' : 'No'}</td> 
                    <td style={styles.tableText}>{checkMainPillar(2, question?.EvaluationID, question?.EvaluationQuestionID)}</td> 
                </tr> 
            ));        
        }
    }

    function getEvaluationRatings(evalRatings) {
        if (evalRatings.length === 0) {
            return (
                <></>
            );
        } else {
            return evalRatings.map((rating, ratingIndex) => (
                <tr> 
                    <td style={styles.tableText}>{ratingIndex + 1}</td> 
                    <td style={styles.tableText}>{rating?.EvaluationRatingTypeID}</td> 
                    <td style={styles.tableTextLeft}>{rating?.EvaluationRatingTypeName}</td> 
                    <td style={styles.tableText}>{rating?.Score}</td> 
                    <td style={styles.tableText}>{rating?.Order}</td> 
                    <td style={styles.tableText}>{(rating?.ColorCode) ? rating?.ColorCode : 'N/A'}</td> 
                    <td style={styles.tableText}>{checkMainPillar(3, rating?.EvaluationID, rating?.EvaluationRatingID)}</td> 
                </tr> 
            ));        
        }        
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Pillars</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Pillar'/>
                        <a onClick={() => searchPillars()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {(permissionFlags.CanCreate) ? 
                            (addNewPillar) ? 
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Add Pillar'>shadow_minus</span>
                                </a>
                                :
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Add Pillar'>note_add</span>
                                </a>
                            :
                                <></>    
                        }
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showReminderList} onShow={handleShowReminderList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseReminderList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReminderList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseReminderList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <AuditTrail showFromAudit={showAudit} handleParentAuditClose={handleCloseAudit} AuditEntityTypeID={AuditEntityType.Evaluation} EntityID={auditEntityID}/>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewPillar()}                                               
                            {((!evaluation) || (evaluation.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Pillars.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Description</th>
                                                <th style={styles.tableText}>Anonymous</th>
                                                <th style={styles.tableText}>Weight</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {evaluation && evaluation.map((evalRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{evalRec?.EvaluationID}</td>
                                                        <td style={styles.tableTextSubHeader}>
                                                            {evalRec?.EvaluationName}
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" 
                                                                    checked={getDetailSwitchValue(evalRec?.EvaluationID)} id={`switch${'evaldetail' + evalRec?.EvaluationID}`} 
                                                                    style={(getDetailSwitchValue(evalRec?.EvaluationID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                    onChange={(e) => {handleSwitchDetailChange(evalRec?.EvaluationID)}}>
                                                                </input>
                                                                <label class="form-check-label" htmlFor={`switch${'detail' + evalRec?.UserID}`}>{(fromLookup) ? 'Select' : 'Details'}</label>
                                                            </div>
                                                        </td>
                                                        <td style={styles.tableText}>{evalRec?.EvaluationHeader}</td>
                                                        <td style={styles.tableText}>{(evalRec?.IsAnonymous) ? 'Yes' : 'No'}</td>
                                                        <td style={styles.tableText}>{evalRec?.UserEvaluationWeight}</td>
                                                        <td style={styles.tableText8}>{checkMainPillar(1, evalRec?.EvaluationID, evalRec?.EvaluationID)}</td>
                                                    </tr>
                                                    {getDetailSwitchTR(evalRec)}
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{evaluations?.evaluations?.RecordFound?.toLocaleString()}   Pillars&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(evaluations?.evaluations?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(evaluations?.evaluations?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {evaluations?.evaluations?.PagingMetaData?.PageNumber}  of  {evaluations?.evaluations?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      evaluations: state.entity.evaluations, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
      lookupselections: state.entity.lookupselections,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPGEvaluations: (evalParams) => dispatch(getPGEvaluations(evalParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertEvaluation: (evalParams) => dispatch(insertEvaluation(evalParams)),
        updateEvaluation: (evalParams) => dispatch(updateEvaluation(evalParams)),
        deleteEvaluation: (evalParams) => dispatch(deleteEvaluation(evalParams)),
        insertEvaluationQuestion: (evalParams) => dispatch(insertEvaluationQuestion(evalParams)),
        updateEvaluationQuestion: (evalParams) => dispatch(updateEvaluationQuestion(evalParams)),
        deleteEvaluationQuestion: (evalParams) => dispatch(deleteEvaluationQuestion(evalParams)),
        insertEvaluationRating: (evalParams) => dispatch(insertEvaluationRating(evalParams)),
        deleteEvaluationRating: (evalParams) => dispatch(deleteEvaluationRating(evalParams)),
        getReminders: (oParams) => dispatch(getReminders(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationList)