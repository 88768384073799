import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getUserAttributesLookups, getProcessSteps, insertProcessStep, deleteProcessStep, updateProcessStep, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';

const ProcessStepList = (props) => {
    const { auth, companyData, userattributelookups, processsteps, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewProcessStep, setAddNewProcessStep] = useState(false);
    const [editProcessStep, setEditProcessStep] = useState(false);
    const [processStepID, setProcessStepID] = useState(-1);
    const [processStepName, setProcessStepName] = useState('');
    const [EditedProcessStepID, setEditedProcessStepID] = useState(-1);
    const [entityTypeID, setEntityTypeID] = useState(null);
    const [assignedGroupID, setAssignedGroupID] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [moveToNextInProgress, setMoveToNextInProgress] = useState(false);
    const [isSignOffStep, setIsSignOffStep] = useState(false);
    const [isPublishStep, setIsPublishStep] = useState(false);
    const [userEvaluationTypeID, setUserEvaluationTypeID] = useState(null);
    const [nextUserEvaluationTypeID, setNextUserEvaluationTypeID] = useState(null);

    const processstep = processsteps?.processsteps.ProcessSteps;
    const groupData = userattributelookups?.Groups;

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = processsteps?.processsteps?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            ProcessStepName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getProcessSteps(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = processsteps?.processsteps?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            ProcessStepName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getProcessSteps(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    async function searchProcessSteps() {
        setLoading(true);
        const oParams = {
            ProcessStepName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        // const oParams  = {
        //     UserID:auth.UserID,
        //     SearchFilter:nodeName,
        // };        
        await props.getProcessSteps(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (evaluation) => {
        return processstep?.map((oRec) => ({
                            ProcessStepID:oRec.ProcessStepID, 
                            EditedNotificationMessageID:-1,
                            takeAction:false,
                            showDetails:false,
                            disableNewProcessStep:false,
                            newProcessStepClicked:false,
                            editProcessStepClicked:false,
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(processstep);
        setSliderValue(initialScoreValues);
    }, [processstep])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewProcessStep) ? setAddNewProcessStep(false) : setAddNewProcessStep(true);
            setProcessStepID(-1);
            setProcessStepName('');
            setEntityTypeID(-1);
            setAssignedGroupID(-1);
            setMoveToNextInProgress(false);
            setIsSignOffStep(false);
            setIsPublishStep(false);
            setUserEvaluationTypeID(-1);
            setNextUserEvaluationTypeID(-1);
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.ProcessStepID === currentEval?.ProcessStepID) {
                    if (tab === 2) {
                        row.disableNewProcessStep = false;                    
                        row.newProcessStepClicked = false;
                        row.editProcessStepClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setProcessStepID(-1);
            setProcessStepName('');
            setEntityTypeID(-1);
            setAssignedGroupID(-1);
            setMoveToNextInProgress(false);
            setIsSignOffStep(false);
            setIsPublishStep(false);
            setUserEvaluationTypeID(-1);
            setNextUserEvaluationTypeID(-1);
            setAddNewProcessStep(false);
            setEditProcessStep(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, oCurrentRow) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                ProcessStepID : -1,
                ProcessStepName : processStepName,
                EntityTypeID : entityTypeID,
                AssignedGroupID : assignedGroupID,
                MoveToNextInProgress : moveToNextInProgress,
                IsSignOffStep : isSignOffStep,
                IsPublishStep : isPublishStep,
                UserEvaluationTypeID : userEvaluationTypeID,
                NextUserEvaluationTypeID : nextUserEvaluationTypeID,
                };        
            console.log(oIParams);
            await props.insertNotificationMessage(oIParams);    
        } 

        const oParams  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getProcessSteps(oParams);        
        setProcessStepID(-1);
        setProcessStepName('');
        setEntityTypeID(-1);
        setAssignedGroupID(-1);
        setMoveToNextInProgress(false);
        setIsSignOffStep(false);
        setIsPublishStep(false);
        setUserEvaluationTypeID(-1);
        setNextUserEvaluationTypeID(-1);
        setAddNewProcessStep(false);
        setEditProcessStep(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const oUParams  = {
                ProcessStepID : EditedProcessStepID,
                ProcessStepName : processStepName,
                EntityTypeID : entityTypeID,
                AssignedGroupID : assignedGroupID,
                MoveToNextInProgress : moveToNextInProgress,
                IsSignOffStep : isSignOffStep,
                IsPublishStep : isPublishStep,
                UserEvaluationTypeID : userEvaluationTypeID,
                NextUserEvaluationTypeID : nextUserEvaluationTypeID,
            };        
            console.log(oUParams);
            if (EditedProcessStepID > 0) {
                await props.updateProcessStep(oUParams);    
            } else {
                await props.insertProcessStep(oUParams);    
            }
            await props.getUserAttributesLookups(auth?.UserID);
            setProcessStepID(-1);
            setProcessStepName('');
            setEntityTypeID(-1);
            setAssignedGroupID(-1);
            setMoveToNextInProgress(false);
            setIsSignOffStep(false);
            setIsPublishStep(false);
            setUserEvaluationTypeID(-1);
            setNextUserEvaluationTypeID(-1);
            setAddNewProcessStep(false);
            setEditProcessStep(false);
        } 

   
        const oParams  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getProcessSteps(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = processstep?.filter((oRec) => (oRec.ProcessStepID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setProcessStepID(id);
                setEditedProcessStepID(id);
                setProcessStepName(oEdited[0]?.ProcessStepName);
                setEntityTypeID(oEdited[0]?.EntityTypeID);
                setAssignedGroupID(oEdited[0]?.AssignedGroupID);
                setMoveToNextInProgress(oEdited[0]?.MoveToNextInProgress);
                setIsSignOffStep(oEdited[0]?.IsSignOffStep);
                setIsPublishStep(oEdited[0]?.IsPublishStep);
                setUserEvaluationTypeID(oEdited[0]?.UserEvaluationTypeID);
                setNextUserEvaluationTypeID(oEdited[0]?.NextUserEvaluationTypeID);    
            }

            setEditProcessStep(true);
        } 
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                ProcessStepID: id,
            };        
            console.log(oParams);
            await props.deleteProcessStep(oParams);    
        } 
        
        const oParams  = {
            UserID:auth.UserID,
            SearchFilter:nodeName,
        };        
        await props.getProcessSteps(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);
        setLoading(false);
    }

    function checkMainProcessStep(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {
                return (
                    <div>
                        <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Process Step'>edit</span>
                        </a>
                        <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Process Step'>delete</span>
                        </a>
                    </div>
                );            
            } else {
                return (<></>);
            }
        } else {
            return (<></>)
        }
    }

    function displayNewProcessStep() {
        if ((!fromLookup) && ((addNewProcessStep) || (editProcessStep))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title style={styles.tableSubHeaderCenterBold}>
                        {(addNewProcessStep) ? 'New Process Step' : 'Edit Process Step'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="processstepname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="processstepname" type="text" style={styles.TextDataEntryNoPadding} defaultValue={processStepName} onChange={(e) => (setProcessStepName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="entitytype" style={styles.LabelDataEntryNoPadding}>Entity Type</label>
                                    <select id="entitytype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={entityTypeID} onChange={(e) => (setEntityTypeID(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='9'>Questionnaire</option>        
                                        <option value='8'>PRF</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'33%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="signoffstep" style={styles.LabelDataEntryNoPadding}>Is Sign Off Step?</label>
                                    <select id="signoffstep" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={isSignOffStep} onChange={(e) => (setIsSignOffStep(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='true'>Yes</option>        
                                        <option value='false'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'33%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="publishstep" style={styles.LabelDataEntryNoPadding}>Is Publish Step?</label>
                                    <select id="publishstep" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={isPublishStep} onChange={(e) => (setIsPublishStep(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='true'>Yes</option>        
                                        <option value='false'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'33%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="inprogressstep" style={styles.LabelDataEntryNoPadding}>Move To In Progress Step?</label>
                                    <select id="inprogressstep" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={moveToNextInProgress} onChange={(e) => (setMoveToNextInProgress(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='true'>Yes</option>        
                                        <option value='false'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'33%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="assigngrooup" style={styles.LabelDataEntryNoPadding}>Assigned Group</label>
                                    <select id="assigngroup" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={assignedGroupID} onChange={(e) => (setAssignedGroupID(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        {groupData?.map((group, supIndex) => (
                                            <option value={group.GroupID}>{group.GroupName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'33%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="currevaltype" style={styles.LabelDataEntryNoPadding}>Current Evaluation Type</label>
                                    <select id="currevaltype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={userEvaluationTypeID} onChange={(e) => (setUserEvaluationTypeID(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>User</option>        
                                        <option value='2'>Manager</option>        
                                        <option value='3'>Peer</option>        
                                        <option value='4'>Subordinate</option>        
                                        <option value='11'>HR</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'33%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="nextevaltype" style={styles.LabelDataEntryNoPadding}>Next Step Evaluation Type</label>
                                    <select id="nextevaltype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={nextUserEvaluationTypeID} onChange={(e) => (setNextUserEvaluationTypeID(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>User</option>        
                                        <option value='2'>Manager</option>        
                                        <option value='3'>Peer</option>        
                                        <option value='4'>Subordinate</option>        
                                        <option value='11'>HR</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'right', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col-auto">
                                        <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Process Step'>Save</Button>
                                    </div>
                                    <div class="col-auto">
                                        <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                
                </Card.Body>
                </Card>                   
            );
        }
    }

    function displayAddMainProcessStep() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewProcessStep) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Process Step'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Process Step'>note_add</span>
                        </a>
                    );    
                }
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Process Steps</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Process Step'/>
                        <a onClick={() => searchProcessSteps()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainProcessStep()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewProcessStep()}                                               
                            {((!processstep) || (processstep.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Process Steps.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {processstep && processstep.map((oRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.ProcessStepID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.ProcessStepName}</td>
                                                        <td style={styles.tableText}>{checkMainProcessStep(1, oRec?.ProcessStepID, -1)}</td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{processsteps?.processsteps?.RecordFound?.toLocaleString()}   Process Steps&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(processsteps?.processsteps?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(processsteps?.processsteps?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {processsteps?.processsteps?.PagingMetaData?.PageNumber}  of  {processsteps?.processsteps?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      userattributelookups: state.entity.userattributelookups,
      processsteps: state.entity.processsteps,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        getProcessSteps: (oParams) => dispatch(getProcessSteps(oParams)),
        insertProcessStep: (oParams) => dispatch(insertProcessStep(oParams)),
        deleteProcessStep: (oParams) => dispatch(deleteProcessStep(oParams)),
        updateProcessStep: (oParams) => dispatch(updateProcessStep(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessStepList)