import React, { useEffect } from "react"
import Signup from "./auth/Signup"
import { Provider } from "react-redux"
import { PersistGate} from 'redux-persist/integration/react'
import { store, persistor } from '../store/store' 
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Dashboard from "./dashboard/Dashboard"
import Login from "./auth/Login"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./auth/ForgotPassword"
import UpdateProfile from "./auth/UpdateProfile"
// import EntityDetails from "./entities/EntityDetails"
// import CreateEntity from "./entities/CreateEntity"
// import UpdateEntity from "./entities/UpdateEntity"
import Navbar from "./layout/Navbar"
import "./App.css"
import Colors from "../config/Colors"
import { styles } from "./layout/styles"
import LaunchEvaluation from "./userentities/LaunchEvaluation"
import { Table } from 'react-bootstrap'
import MyTeam from "./userentities/MyTeam"
import UserEvaluationList from "./userentities/UserEvaluationList"
import Divisions from "./auth/Divisions"
import EvaluationList from "./userentities/EvaluationList"
import AssignmentList from "./userentities/AssignmentList"
import ReportList from "./userentities/ReportList"
import LaunchReport from "./userentities/LaunchReport"
import JobPositions from "./auth/JobPositions"
import JobClassTree from "./auth/JobClassTree"
import GoalList from "./userentities/GoalList"
import { ClipLoader, RingLoader, CircleLoader, GridLoader, HashLoader, PropagateLoader, RiseLoader } from 'react-spinners';
import CompetencyList from "./userentities/CompetencyList"
import CompetencyChecklist from "./userentities/CompetencyChecklist"
import LoginSSO from "./auth/LoginSSO"
import NotificationMessageList from "./userentities/NotificationMessageList"
import UserEvaluationSession from "./userentities/UserEvaluationSession"
import ProcessStepList from "./userentities/ProcessStepList"
import ReminderList from "./userentities/ReminderList"
import PolicyList from "./userentities/PolicyList"
import GroupList from "./userentities/GroupList"
import MenuList from "./userentities/MenuList"
import ResetPassword from "./auth/ResetPassword"
import UserIdle from "./layout/UserIdle"
import KnowledgeList from "./userentities/KnowledgeList"
import ContentList from "./userentities/ContentList"
import Catalog from "./userentities/Catalog"
import TestLibraryList from "./userentities/TestLibraryList"
import KnowledgeInstanceList from "./userentities/KnowledgeInstanceList"
import FacilityList from "./userentities/FacilityList"
import KnowledgeSessionList from "./userentities/KnowledgeSessionList"
import CertificationList from "./userentities/CertificationList"
import KnowledgeInstanceDetail from "./userentities/KnowledgeInstanceDetail"
import KnowledgeSessionDetail from "./userentities/KnowledgeSessionDetail"
import KnowledgeSessionObjectives from "./userentities/KnowledgeSessionObjectives"
import LaunchKnowledge from "./userentities/LaunchKnowledge"
import AICCCapture from "./auth/AICCCapture"

function App() {
  const loggedInState = store.getState();
  const override = {
    display: "block",
    borderColor: '#E71D37',
    textAlign:'center',
    alignSelf:'center',
    justifyContent: 'center', 
  };   

  useEffect(() => {
    if (loggedInState?.auth?.companyData) {
      Colors(loggedInState?.auth?.companyData);
    }
  }, []);


  return (
      // <Container className="d-flex align-items-center justify-content-center" style={{ minHeight:"80vh"}}>
      // //className="w-100" 
      <>
      {(!loggedInState?._persist?.rehydrated) ? (            
        <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
          <RingLoader 
            color='#E71D37' 
            size={76}
            cssOverride={override}
          />
        </div>
        ) 
        : 
        (  
        <div style={styles.DivMainAllPages}> 
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
              {/* class='opacity-50'  */}
                <Table style={{width:'100%', height:'100%', border:0, background:'rgba(0,0,0,0)'}}>
                  <thead>
                    <tr>
                        <th style={{width:'100%', height:'8%', alignItems:'center', background:'rgba(0,0,0,0)'}}>
                          <Navbar/>
                        </th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop:'none' }}>
                  <tr>
                      <td style={{width:'100%', height:'91%', alignItems:'center', background:'rgba(0,0,0,0)'}}>
                        <Routes>
                          <Route exact path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                          <Route path="/updateProfile" element={<PrivateRoute><UpdateProfile/></PrivateRoute>}/>
                          <Route path="/signup/:cid" element={<Signup/>}/>
                          <Route path="/login/:cid" element={<Login/>}/>
                          <Route path="/loginSSO" element={<LoginSSO/>}/>
                          <Route path="/forgotPassword/:cid" element={<ForgotPassword/>}/>
                          <Route path="/resetPassword/:cid/:rpid" element={<ResetPassword/>}/>
                          <Route path="/aicccapture/:cid/:sid" element={<AICCCapture/>}/>
                          <Route path="/launchevaluation/:id" element={<PrivateRoute><LaunchEvaluation/></PrivateRoute>}/>
                          <Route path="/myTeam" element={<PrivateRoute><MyTeam/></PrivateRoute>}/>
                          <Route path="/questionnaires" element={<PrivateRoute><UserEvaluationList/></PrivateRoute>}/>
                          <Route path="/performance" element={<PrivateRoute><UserEvaluationList/></PrivateRoute>}/>
                          <Route path="/divisions" element={<PrivateRoute><Divisions/></PrivateRoute>}/>
                          <Route path="/evaluations" element={<PrivateRoute><EvaluationList/></PrivateRoute>}/>
                          <Route path="/assignments" element={<PrivateRoute><AssignmentList/></PrivateRoute>}/>
                          <Route path="/reports" element={<PrivateRoute><ReportList/></PrivateRoute>}/>
                          <Route path="/launchreport/:id" element={<PrivateRoute><LaunchReport/></PrivateRoute>}/>
                          <Route path="/jobpositions" element={<PrivateRoute><JobPositions/></PrivateRoute>}/>
                          <Route path="/goals" element={<PrivateRoute><GoalList/></PrivateRoute>}/>
                          <Route path="/competencies" element={<PrivateRoute><CompetencyList/></PrivateRoute>}/>
                          <Route path="/competencychecklist" element={<PrivateRoute><CompetencyChecklist/></PrivateRoute>}/>
                          <Route path="/jobclasstree" element={<PrivateRoute><JobClassTree/></PrivateRoute>}/>
                          <Route path="/notificationmessagelist" element={<PrivateRoute><NotificationMessageList/></PrivateRoute>}/>
                          <Route path="/userevalsession/:id" element={<PrivateRoute><UserEvaluationSession/></PrivateRoute>}/>
                          <Route path="/processsteplist" element={<PrivateRoute><ProcessStepList/></PrivateRoute>}/>
                          <Route path="/reminderlist" element={<PrivateRoute><ReminderList/></PrivateRoute>}/>
                          <Route path="/policylist" element={<PrivateRoute><PolicyList/></PrivateRoute>}/>
                          <Route path="/grouplist" element={<PrivateRoute><GroupList/></PrivateRoute>}/>
                          <Route path="/menulist" element={<PrivateRoute><MenuList/></PrivateRoute>}/>
                          <Route path="/knowledgelist" element={<PrivateRoute><KnowledgeList/></PrivateRoute>}/>
                          <Route path="/contentlist" element={<PrivateRoute><ContentList/></PrivateRoute>}/>
                          <Route path="/catalog" element={<PrivateRoute><Catalog/></PrivateRoute>}/>
                          <Route path="/testlibrarylist" element={<PrivateRoute><TestLibraryList/></PrivateRoute>}/>
                          <Route path="/knowledgeinstancelist/:id" element={<PrivateRoute><KnowledgeInstanceList/></PrivateRoute>}/>
                          <Route path="/facilitylist" element={<PrivateRoute><FacilityList/></PrivateRoute>}/>
                          <Route path="/knowledgesessionlist/:id" element={<PrivateRoute><KnowledgeSessionList/></PrivateRoute>}/>
                          <Route path="/certificationlist" element={<PrivateRoute><CertificationList/></PrivateRoute>}/>
                          <Route path="/knowledgeinstancedetail/:id" element={<PrivateRoute><KnowledgeInstanceDetail/></PrivateRoute>}/>
                          <Route path="/knowledgesessiondetail/:id" element={<PrivateRoute><KnowledgeSessionDetail/></PrivateRoute>}/>
                          <Route path="/knowledgesessionobjectives/:id" element={<PrivateRoute><KnowledgeSessionObjectives/></PrivateRoute>}/>
                          <Route path="/launchknowledge/:id" element={<PrivateRoute><LaunchKnowledge/></PrivateRoute>}/>
                        </Routes>
                      </td>
                    </tr>
                    <tr>
                      <td style={{width:'100%', height:'1%', alignItems:'center', background:'rgba(0,0,0,0)'}}>
                        <UserIdle/>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Router>
            </PersistGate>
          </Provider>
        </div>
        )}
        </>
      // </Container> }
  )
}

export default App;

