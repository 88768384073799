import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getKnowledges, getUserAttributesLookups, insertKnowledge, updateKnowledge, deleteKnowledge, getPGEvaluations, 
    getReminders, getKnowledgesLookup, getContents, getTestLibraries, getNotificationMessages, getSkills, getKnowledgeInstances, getAttachments } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import CompetencyList from './CompetencyList';
import { UserLookup, Rights, KnowledgeType, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant } from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Attachment from '../layout/Attachment';
import EvaluationList from './EvaluationList';
import ReminderList from './ReminderList';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import KnowledgeLookupList from './KnowledgeLookupList';
import ContentList from './ContentList';
import TestLibraryList from './TestLibraryList';
import NotificationMessageList from './NotificationMessageList';
import AlertMessage from '../layout/AlertMessage';

const KnowledgeList = (props) => {
    const { auth, companyData, profile, knowledges, evaluations, userattributelookups, orgDivisions, lookupselections, loadingMenu, listby, orgLearningObjectTree } = props;    
    const location  = useLocation();
    const navigate = useNavigate();
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [learningObjectTreeNodeName, setLearningObjectTreeNodeName] = useState('');
    const [learningObjectTreeSelectedNodes, setLearningObjectTreeSelectedNodes] = useState('');
    const [addNewKnowledge, setAddNewKnowledge] = useState(false);
    const [editKnowledge, setEditKnowledge] = useState(false);
    const [knowledgeID, setKnowledgeID] = useState(-1);
    const [knowledgeName, setKnowledgeName] = useState('');
    const [knowledgeActive, setKnowledgeActive] = useState('1');
    const [knowledgeType, setKnowledgeType] = useState('-1');
    const [knowledgeDescription, setKnowledgeDescription] = useState('');
    const [knowledgePreEnrollmentText, setKnowledgePreEnrollmentText] = useState('');
    const [knowledgeCourseNumber, setKnowledgeCourseNumber] = useState('');
    const [knowledgeDuration, setKnowledgeDuration] = useState('');
    const [knowledgeListPrice, setKnowledgeListPrice] = useState('');
    const [knowledgeVersion, setKnowledgeVersion] = useState('');
    const [knowledgeGradingType, setKnowledgeGradingType] = useState(-1);
    const [knowledgePassingScore, setKnowledgePassingScore] = useState('');
    const [knowledgeWebConference, setKnowledgeWebConference] = useState(-1);
    const [knowledgeWebConferenceType, setKnowledgeWebConferenceType] = useState(-1);
    const [knowledgeAllowObjectives, setKnowledgeAllowObjectives] = useState(-1);
    const [knowledgeCreditUnits, setKnowledgeCreditUnits] = useState('');
    const [knowledgeCreditHours, setKnowledgeCreditHours] = useState('');
    const [knowledgeCreditOthers, setKnowledgeCreditOthers] = useState('');
    const [knowledgeForCertificationOnly, setKnowledgeForCertificationOnly] = useState('');
    const [knowledgeOnlineType, setKnowledgeOnlineType] = useState(-1);
    const [knowledgeOnlineURL, setKnowledgeOnlineURL] = useState('');
    const [knowledgeSCORMReset, setKnowledgeSCORMReset] = useState(-1);
    const [knowledgeCompletedSCORM, setKnowledgeCompletedSCORM] = useState(-1);
    const [knowledgeNewWindowLaunch, setKnowledgeNewWindowLaunch] = useState(-1);
    const [knowledgeNewWindowLaunchText, setKnowledgeNewWindowLaunchText] = useState('');
    const [knowledgeAcknowledgementText, setKnowledgeAcknowledgementText] = useState('');
    const [knowledgeTechnology, setKnowledgeTechnology] = useState('');
    const [knowledgeSponsors, setKnowledgeSponsors] = useState('');
    const [knowledgeCategory, setKnowledgeCategory] = useState('');
    const [knowledgeBasedTypes, setKnowledgeBasedTypes] = useState('');
    const [knowledgeTopics, setKnowledgeTopics] = useState('');
    const [knowledgeCEBrokerLicenses, setKnowledgeCEBrokerLicenses] = useState('');
    const [knowledgeCEBrokerPublishingNumber, setKnowledgeCEBrokerPublishingNumber] = useState('');
    const [knowledgeCEBrokerReportingNumber, setKnowledgeCEBrokerReportingNumber] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isAttachmentSectionDisabled, setIsAttachmentSectionDisabled] = useState(false);
    const [isAttachmentSectionVisible, setIsAttachmentSectionVisible] = useState(false);
    const [knowledgeVendor, setKnowledgeVendor] = useState(-1);
    const [knowledgeTrainingFacility, setKnowledgeTrainingFacility] = useState(-1);
    const [knowledgePaperCertificates, setKnowledgePaperCertificates] = useState('');
    const [knowledgePaperCertificateNames, setKnowledgePaperCertificateNames] = useState('');    
    const [showEvaluationList, setShowEvaluationList] = useState(false);
    const [knowledgeEvaluations, setKnowledgeEvaluations] = useState('');
    const [knowledgeEvaluationNames, setKnowledgeEvaluationNames] = useState('');    
    const [showReminderList, setShowReminderList] = useState(false);
    const [knowledgeReminders, setKnowledgeReminders] = useState('');
    const [knowledgeReminderNames, setKnowledgeReminderNames] = useState('');    
    const [knowledgeAccreditations, setKnowledgeAccreditations] = useState('');    
    const [knowledgeForumThreads, setKnowledgeForumThreads] = useState('');    
    const [knowledgeOwnerships, setKnowledgeOwnerships] = useState('');    
    const [knowledgePromotions, setKnowledgePromotions] = useState('');    
    const [knowledgeROMS, setKnowledgeROMS] = useState('');    
    const [knowledgeResources, setKnowledgeResources] = useState('');    
    const [knowledgeAssessments, setKnowledgeAssessments] = useState('');    
    const [knowledgeTreeNodes, setKnowledgeTreeNodes] = useState('');    
    const [knowledgeTreeNodeDetails, setKnowledgeTreeNodeDetails] = useState('');
    const [knowledgeTreeNodeArray, setKnowledgeTreeNodeArray] = useState([]);
    const [showContentList, setShowContentList] = useState(false);
    const [knowledgeSCORMS, setKnowledgeSCORMS] = useState('');
    const [knowledgeSCORMNames, setKnowledgeSCORMNames] = useState('');    

    const [showPostAssessmentList, setShowPostAssessmentList] = useState(false);
    const [knowledgePostAssessments, setKnowledgePostAssessments] = useState('');
    const [knowledgePostAssessmentNames, setKnowledgePostAssessmentNames] = useState('');
    const [knowledgePostAssessmentArray, setKnowledgePostAssessmentArray] = useState([]);
    const [showPreAssessmentList, setShowPreAssessmentList] = useState(false);
    const [knowledgePreAssessments, setKnowledgePreAssessments] = useState('');
    const [knowledgePreAssessmentNames, setKnowledgePreAssessmentNames] = useState('');
    const [knowledgePreAssessmentArray, setKnowledgePreAssessmentArray] = useState([]);
    const [knowledgeForcePreTest, setKnowledgeForcePreTest] = useState(-1);
    const [knowledgePreTestPassingScore, setKnowledgePreTestPassingScore] = useState(null);
    const [knowledgeForcePreTestComplete, setKnowledgeForcePreTestComplete] = useState(-1);
    const [knowledgePreTestCompletionPassingScore, setKnowledgePreTestCompletionPassingScore] = useState(null);

    const [addNewKnowledgeNotificationMessage, setAddNewKnowledgeNotificationMessage] = useState(false);
    const [knowledgeProcessStatusTypeID, setKnowledgeProcessStatusTypeID] = useState(null);
    const [knowledgeProcessStatusTypeName, setKnowledgeProcessStatusTypeName] = useState(null);
    const [knowledgeNotificationMessageArray, setKnowledgeNotificationMessageArray] = useState([]);
    const [knowledgeNotificationMessageDetails, setKnowledgeNotificationMessageDetails] = useState('');
    const [knowledgeNotificationMessages, setKnowledgeNotificationMessages] = useState('');
    const [knowledgeNotificationMessageNames, setKnowledgeNotificationMessageNames] = useState('');
    const [showNotificationMessageList, setShowNotificationMessageList] = useState(false);

    const [showCompetencyList, setShowCompetencyList] = useState(false);
    const [addNewKnowledgeObjective, setAddNewKnowledgeObjective] = useState(false);
    const [knowledgeObjectiveArray, setKnowledgeObjectiveArray] = useState([]);
    const [knowledgeObjectiveDetails, setKnowledgeObjectiveDetails] = useState(null);
    const [knowledgeObjectives, setKnowledgeObjectives] = useState(null);
    const [knowledgeObjectiveNames, setKnowledgeObjectiveNames] = useState(null);
    const [knowledgeObjectiveDisplayOrder, setKnowledgeObjectiveDisplayOrder] = useState(null);
    const [knowledgeObjectiveDuration, setKnowledgeObjectiveDuration] = useState(null);
    const [knowledgeObjectiveRequiresApproval, setKnowledgeObjectiveRequiresApproval] = useState(null);
    const [knowledgeObjectiveRequiresCompletion, setKnowledgeObjectiveRequiresCompletion] = useState(null);
    const [knowledgeObjectiveOnlineType, setKnowledgeObjectiveOnlineType] = useState(null);
    const [knowledgeObjectiveOnlineURL, setKnowledgeObjectiveOnlineURL] = useState(null);
    const [knowledgeObjective, setKnowledgeObjective] = useState(null);

    const [addNewKnowledgeAccreditation, setAddNewKnowledgeAccreditation] = useState(false);
    const [knowledgeAccreditationArray, setKnowledgeAccreditationArray] = useState([]);
    const [knowledgeAccreditationDetails, setKnowledgeAccreditationDetails] = useState(null);
    const [knowledgeAccreditationID, setKnowledgeAccreditationID] = useState(null);
    const [knowledgeAccreditationName, setKnowledgeAccreditationName] = useState(null);
    const [knowledgeAccreditationNumber, setKnowledgeAccreditationNumber] = useState(null);
    const [knowledgeAccreditationShowComments, setKnowledgeAccreditationShowComments] = useState(null);
    const [knowledgeAccreditationPassingScore, setKnowledgeAccreditationPassingScore] = useState(null);
    const [knowledgeAccreditationCreditUnits, setKnowledgeAccreditationCreditUnits] = useState(null);
    const [knowledgeAccreditationCreditHours, setKnowledgeAccreditationCreditHours] = useState(null);
    const [knowledgeAccreditationCreditOthers, setKnowledgeAccreditationCreditOthers] = useState(null);
    const [knowledgeAccreditationEnforceAttemptsLogic, setKnowledgeAccreditationEnforceAttemptsLogic] = useState(null);
    const [knowledgeAccreditationRetakeAttempts, setKnowledgeAccreditationRetakeAttempts] = useState(null);
    const [knowledgeAccreditationDaysBeforeRetake, setKnowledgeAccreditationDaysBeforeRetake] = useState(null);
    const [knowledgeAccreditationExpirationDate, setKnowledgeAccreditationExpirationDate] = useState(null);
    const [knowledgeAccreditationReleaseDate, setKnowledgeAccreditationReleaseDate] = useState(null);
    const [knowledgeAccreditationSCORMS, setKnowledgeAccreditationSCORMS] = useState(null);
    const [knowledgeAccreditationSCORMNames, setKnowledgeAccreditationSCORMNames] = useState(null);
    const [knowledgeAccreditationEvaluations, setKnowledgeAccreditationEvaluations] = useState(null);
    const [knowledgeAccreditationEvaluationNames, setKnowledgeAccreditationEvaluationNames] = useState(null);
    const [knowledgeAccreditationPostAssessments, setKnowledgeAccreditationPostAssessments] = useState(null);
    const [knowledgeAccreditationPostAssessmentNames, setKnowledgeAccreditationPostAssessmentNames] = useState(null);
    const [knowledgeAccreditationPaperCertificates, setKnowledgeAccreditationPaperCertificates] = useState(null);
    const [knowledgeAccreditationPaperCertificateNames, setKnowledgeAccreditationPaperCertificateNames] = useState(null);
    const [showAccreditationEvaluationList, setShowAccreditationEvaluationList] = useState(false);
    const [showAccreditationContentList, setShowAccreditationContentList] = useState(false);
    const [showAccreditationPostAssessmentList, setShowAccreditationPostAssessmentList] = useState(false);

    const [addNewKnowledgePromotion, setAddNewKnowledgePromotion] = useState(false);
    const [knowledgePromotionArray, setKnowledgePromotionArray] = useState([]);
    const [knowledgePromotionDetails, setKnowledgePromotionDetails] = useState(null);
    const [knowledgePromotionDiscountType, setKnowledgePromotionDiscountType] = useState(null);
    const [knowledgePromotionDiscountTypeName, setKnowledgePromotionDiscountTypeName] = useState(null);
    const [knowledgePromotionName, setKnowledgePromotionName] = useState(null);
    const [knowledgePromotionCode, setKnowledgePromotionCode] = useState(null);
    const [knowledgePromotionAccessCode, setKnowledgePromotionAccessCode] = useState(null);
    const [knowledgePromotionPrice, setKnowledgePromotionPrice] = useState(null);
    const [knowledgePromotionAutoApply, setKnowledgePromotionAutoApply] = useState(null);
    const [knowledgePromotionStartDate, setKnowledgePromotionStartDate] = useState(null);
    const [knowledgePromotionExpirationDate, setKnowledgePromotionExpirationDate] = useState(null);
    const [knowledgePromotionEmploymentTypes, setKnowledgePromotionEmploymentTypes] = useState(null);
    const [knowledgePromotionEmploymentTypeNames, setKnowledgePromotionEmploymentTypeNames] = useState(null);

    const [knowledgeDivisionDetails, setKnowledgeDivisionDetails] = useState('');
    const [knowledgeDivisions, setKnowledgeDivisions] = useState('');
    const [knowledgePrerequisitesArray, setKnowledgePrerequisitesArray] = useState([]);
    const [knowledgePrerequisitesDetails, setKnowledgePrerequisitesDetails] = useState('');
    const [knowledgePrerequisites, setKnowledgePrerequisites] = useState('');
    const [showKnowledgePrerequisitesList, setShowKnowledgePrerequisitesList] = useState(false);
    const [knowledgeSameAsArray, setKnowledgeSameAsArray] = useState([]);
    const [knowledgeSameAsDetails, setKnowledgeSameAsDetails] = useState('');
    const [knowledgeSameAs, setKnowledgeSameAs] = useState('');    
    const [showKnowledgeSameAsList, setShowKnowledgeSameAsList] = useState(false);
    const [knowledgeSkillPreceptorArray, setKnowledgeSkillPreceptorArray] = useState([]);
    const [knowledgeSkillPreceptorDetails, setKnowledgeSkillPreceptorDetails] = useState('');
    const [knowledgeSkillPreceptors, setKnowledgeSkillPreceptors] = useState('');
    const [showSkillPreceptorList, setShowSkillPreceptorList] = useState(false);

    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const knowledge = knowledges?.knowledges?.LearningObjects;

    const prerequisite = knowledges?.knowledges?.Prerequisite;
    const accreditations = knowledges?.knowledges?.LearningObjectAccreditation;
    const ceBrokerLicense = knowledges?.knowledges?.LearningObjectCEBrokerLicense;
    const forumThread = knowledges?.knowledges?.LearningObjectForumThread;
    const notes = knowledges?.knowledges?.LearningObjectNotes;
    const notificationMessage = knowledges?.knowledges?.LearningObjectNotificationMessage;
    const ownership = knowledges?.knowledges?.LearningObjectOwnership;
    const promotion = knowledges?.knowledges?.LearningObjectPromotion;
    const roms = knowledges?.knowledges?.LearningObjectROMS;
    const resource = knowledges?.knowledges?.LearningObjectResource;
    const sameAs = knowledges?.knowledges?.LearningObjectSameAs;
    const sponsor = knowledges?.knowledges?.LearningObjectSponsor;
    const subCategory = knowledges?.knowledges?.LearningObjectSubCategory;
    const technology = knowledges?.knowledges?.LearningObjectTechnology;
    const test = knowledges?.knowledges?.LearningObjectTest;
    const topic = knowledges?.knowledges?.LearningObjectTopic;
    const treeNode = knowledges?.knowledges?.LearningObjectTreeNode;
    const availability = knowledges?.knowledges?.Availability;
    const assessment = knowledges?.knowledges?.LearningObjectTest;

    const gradingTypeData = userattributelookups?.GradingTypes;
    const ceBrokerTypeData = userattributelookups?.CEBrokerLicenseTypes;
    const categoryTypeData = userattributelookups?.LearningObjectCategoryTypes;
    const subCategoryTypeData = userattributelookups?.LearningObjectSubCategoryTypes;
    const technologyTypeData = userattributelookups?.LearningObjectTechnologyTypes;
    const topicTypeData = userattributelookups?.TopicTypes;
    const trainingVendorTypeData = userattributelookups?.TrainingVendorTypes;
    const vendorData = userattributelookups?.Vendors;
    const wbtTypeData = userattributelookups?.WBTTypes;
    const knowledgeTypeData = userattributelookups?.LearningObjectTypes;
    const sponsorData = userattributelookups?.Sponsors;
    const paperCertificateData = userattributelookups?.PaperCertificates;
    const accreditationData = userattributelookups?.Accreditations;
    const employmentTypeData = userattributelookups?.EmploymentTypes;
    const discountTypeData = userattributelookups?.DiscountTypes;

    const radioKnowledege = [
        { name: 'Attributes', value: 1 },
        { name: 'Web Based', value: 2 },
        { name: 'Catalog', value: 3 },
        { name: 'Categories', value: 4 },
        { name: 'Outline', value: 5 },
        { name: 'Assessment', value: 6 },
        { name: 'Attachment', value: 7 },
        { name: 'Advanced', value: 8 },
        { name: 'Organization', value: 9 },
    ];

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }
    
    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Inactive', value: '2' },
        { name: 'All', value: '3' },
    ];
    const [radioTypeValue, setRadioTypeValue] = useState(initialRadioTypeValue());
    const radioTypes = [
        { name: 'Classroom', value: KnowledgeType.Classroom },
        { name: 'Online', value: KnowledgeType.Online },
        { name: 'Manual', value: KnowledgeType.Manual },
        { name: 'Historical', value: KnowledgeType.Historical },
        { name: 'All', value: KnowledgeType.All },
    ];

    function initialRadioValue() {
        return '1';
    };

    function initialRadioTypeValue() {
        return listByValue;
    };    
   
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = knowledges?.knowledges?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getKnowledges(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = knowledges?.knowledges?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getKnowledges(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    function initialTabValue() {
        return 1;
    };

    function getHTMLFromEditor() {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    function setEditorHTML(isEmpty, value) {
        if (isEmpty) {
            setEditorState(EditorState.createEmpty());
        } else if (value === null) {
            setEditorState(EditorState.createEmpty());
        } else {
            const contentBlock = htmlToDraft(value);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const oEditorState = EditorState.createWithContent(contentState);
              setEditorState(oEditorState);
            }
        }
    }

    const handleCloseEvaluationList = () => {
        setShowEvaluationList(false);
        updateEvaluationSelection();
    }

    async function handleShowEvaluationList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPGEvaluations(oParams);
        setShowEvaluationList(true); 
        setLoading(false);
    };

    function updateEvaluationSelection() {
        const sSelections = lookupselections?.Pillar;
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections.trim() !== '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1];//.split('|')[0];
            });
            setKnowledgeEvaluations(sSelectedIds);
            setKnowledgeEvaluationNames(sSelectedNames);
        }    
    }

    const handleCloseAccreditationEvaluationList = () => {
        setShowAccreditationEvaluationList(false);
        updateAccreditationEvaluationSelection();
    }

    async function handleShowAccreditationEvaluationList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPGEvaluations(oParams);
        setShowAccreditationEvaluationList(true); 
        setLoading(false);
    };

    function updateAccreditationEvaluationSelection() {
        const sSelections = lookupselections?.Pillar;
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections.trim() !== '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if ((element) && (element.trim !== '')) {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1];//.split('|')[0];    
                }
            });
            setKnowledgeAccreditationEvaluations(sSelectedIds);
            setKnowledgeAccreditationEvaluationNames(sSelectedNames);
        }    
    }

    const handleCloseReminderList = () => {
        setShowReminderList(false);
        updateReminderSelection();
    }

    async function handleShowReminderList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getReminders(oParams);

        setShowReminderList(true); 
        setLoading(false);
    };

    function updateReminderSelection() {
        var sSelections = lookupselections?.Reminder;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgeReminders(sSelectedIds);
            setKnowledgeReminderNames(sSelectedNames);
        }
    }

    const handleCloseContentList = () => {
        setShowContentList(false);
        updateContentSelection();
    }

    async function handleShowContentList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);

        setShowContentList(true); 
        setLoading(false);
    };

    function updateContentSelection() {
        var sSelections = lookupselections?.Content;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgeSCORMS(sSelectedIds);
            setKnowledgeSCORMNames(sSelectedNames);
        }
    }

    const handleCloseAccreditationContentList = () => {
        setShowAccreditationContentList(false);
        updateAccreditationContentSelection();
    }

    async function handleShowAccreditationContentList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);

        setShowAccreditationContentList(true); 
        setLoading(false);
    };

    function updateAccreditationContentSelection() {
        var sSelections = lookupselections?.Content;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if ((element) && (element.trim !== '')) {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setKnowledgeAccreditationSCORMS(sSelectedIds);
            setKnowledgeAccreditationSCORMNames(sSelectedNames);
        }
    }

    const handleCloseNotificationMessageList = () => {
        setShowNotificationMessageList(false);
        updateNotificationMessageSelection();
    }

    async function handleShowNotificationMessageList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);

        setShowNotificationMessageList(true); 
        setLoading(false);
    };

    function updateNotificationMessageSelection() {
        var sSelections = lookupselections?.NotificationMessage;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgeNotificationMessages(sSelectedIds);
            setKnowledgeNotificationMessageNames(sSelectedNames);
        }
    }

    const handleCloseCompetencyList = () => {
        setShowCompetencyList(false);
        updateCompetencySelection();
    }

    async function handleShowCompetencyList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getSkills(oParams);

        setShowCompetencyList(true); 
        setLoading(false);
    };

    function updateCompetencySelection() {
        var sSelections = lookupselections?.Skill;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgeObjectives(sSelectedIds);
            setKnowledgeObjectiveNames(sSelectedNames);
        }
    }

    const handleClosePostAssessmentList = () => {
        setShowPostAssessmentList(false);
        updatePostAssessmentSelection();
    }

    async function handleShowPostAssessmentList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getTestLibraries(oParams);

        setShowPostAssessmentList(true); 
        setLoading(false);
    };

    function updatePostAssessmentSelection() {
        var sSelections = lookupselections?.Assessment;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if ((element) && (element.trim() !== '')) {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];    
                }
            });
            setKnowledgePostAssessments(sSelectedIds);
            setKnowledgePostAssessmentNames(sSelectedNames);
        }
    }

    const handleCloseAccreditationPostAssessmentList = () => {
        setShowAccreditationPostAssessmentList(false);
        updateAccreditationPostAssessmentSelection();
    }

    async function handleShowAccreditationPostAssessmentList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getTestLibraries(oParams);

        setShowAccreditationPostAssessmentList(true); 
        setLoading(false);
    };

    function updateAccreditationPostAssessmentSelection() {
        var sSelections = lookupselections?.Assessment;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if ((element) && (element.trim() !== '')) {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0].split('|')[1];
                    sSelectedNames += element?.split('=')[1].split('|')[0];    
                }
            });
            setKnowledgeAccreditationPostAssessments(sSelectedIds);
            setKnowledgeAccreditationPostAssessmentNames(sSelectedNames);
        }
    }

    const handleClosePreAssessmentList = () => {
        setShowPreAssessmentList(false);
        updatePreAssessmentSelection();
    }

    async function handleShowPreAssessmentList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getTestLibraries(oParams);

        setShowPreAssessmentList(true); 
        setLoading(false);
    };

    function updatePreAssessmentSelection() {
        var sSelections = lookupselections?.Assessment;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if ((element) && (element.trim() !== '')) {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];    
                }
            });
            setKnowledgePreAssessments(sSelectedIds);
            setKnowledgePreAssessmentNames(sSelectedNames);
        }
    }

    const handleCloseSkillPreceptorList = () => {
        setShowSkillPreceptorList(false);
        updateSkillPreceptorSelection();
    }

    async function handleShowSkillPreceptorList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.Preceptor,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getUserLookup(oParams);

        setShowSkillPreceptorList(true); 
        setLoading(false);
    };

    function updateSkillPreceptorSelection() {
        var sSelections = lookupselections?.User;
        if (knowledgeSkillPreceptorDetails !== '') {
            sSelections = knowledgeSkillPreceptorDetails + '&' + sSelections;
        }
        if (knowledgeOwnerships !== '') {
            sSelections = knowledgeOwnerships + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setKnowledgeSkillPreceptorDetails(sSelections);
            setKnowledgeSkillPreceptorArray(oSelections);
            setKnowledgeSkillPreceptors(sSelectedIds);

            setKnowledgeOwnerships(sSelectedIds);
        }
    }

    function handleDeleteAssignedSkillPreceptorClick(goal) {
        var tempSkills = knowledgeSkillPreceptorArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setKnowledgeSkillPreceptorArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setKnowledgeSkillPreceptors(sSelectedIds);
            setKnowledgeSkillPreceptorDetails(sSelectedDetails);
            setKnowledgeOwnerships(sSelectedIds);
        }        
    }

    function handleDeleteAssignedTreeNodeClick(goal) {
        var tempSkills = knowledgeTreeNodeArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedDetails += element;
            });

            setKnowledgeTreeNodes(sSelectedIds);
            setKnowledgeTreeNodeDetails(sSelectedDetails);
            setKnowledgeTreeNodeArray(sSelectedDetails);
        }        
    }

    const handleCloseKnowledgePrerequisitesList = () => {        
        setShowKnowledgePrerequisitesList(false);
        updatePrerequisitesSelection();
    }

    async function handleShowKnowledgePrerequisitesList() {
        setLoading(true);
        const oParams = {
            LearningObjectTypeID:'1',
            IsCourseActive:'true',
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getKnowledgesLookup(oParams);

        setShowKnowledgePrerequisitesList(true); 
        setLoading(false);
    };

    function updatePrerequisitesSelection() {
        var sSelections = lookupselections?.Knowledge;
        if (knowledgePrerequisitesDetails !== '') {
            sSelections = knowledgePrerequisitesDetails + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined)) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setKnowledgePrerequisitesDetails(sSelections);
            setKnowledgePrerequisitesArray(oSelections);
            setKnowledgePrerequisites(sSelectedIds);
        }
    }

    function handleDeleteAssignedPrerequisitesClick(goal) {
        var tempPrerequisites = knowledgePrerequisitesArray.slice(0);
        var iRIndex = tempPrerequisites.indexOf(goal);
        if (iRIndex !== -1) {
            tempPrerequisites.splice(iRIndex, 1);
            setKnowledgePrerequisitesArray(tempPrerequisites);
            let sSelectedIds = ''
            let sSelectedNames = ''
            let sSelectedDetails = ''
            tempPrerequisites?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                sSelectedDetails += element;
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgePrerequisites(sSelectedIds);
            setKnowledgePrerequisitesDetails(sSelectedNames);
        }        
    }

    const handleCloseKnowledgeSameAsList = () => {        
        setShowKnowledgeSameAsList(false);
        updateSameAsSelection();
    }

    async function handleShowKnowledgeSameAsList() {
        setLoading(true);
        const oParams = {
            LearningObjectTypeID:'1',
            IsCourseActive:'true',
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getKnowledgesLookup(oParams);

        setShowKnowledgeSameAsList(true); 
        setLoading(false);
    };

    function updateSameAsSelection() {
        var sSelections = lookupselections?.Knowledge;
        if (knowledgeSameAsDetails !== '') {
            sSelections = knowledgeSameAsDetails + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined)) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setKnowledgeSameAsDetails(sSelections);
            setKnowledgeSameAsArray(oSelections);
            setKnowledgeSameAs(sSelectedIds);
        }
    }

    function handleDeleteAssignedSameAsClick(goal) {
        var tempSameAs = knowledgeSameAsArray.slice(0);
        var iRIndex = tempSameAs.indexOf(goal);
        if (iRIndex !== -1) {
            tempSameAs.splice(iRIndex, 1);
            setKnowledgeSameAsArray(tempSameAs);
            let sSelectedIds = ''
            let sSelectedNames = ''
            let sSelectedDetails = ''
            tempSameAs?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                sSelectedDetails += element;
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgeSameAs(sSelectedIds);
            setKnowledgeSameAsDetails(sSelectedNames);
        }        
    }

    function updateSubChildrenSelect(fieldIndex, selected) {
        if (fieldIndex === 1) {
            setKnowledgeProcessStatusTypeID(selected[0].value);
            setKnowledgeProcessStatusTypeName(selected[0].text);
        } else if (fieldIndex === 2) {
            setKnowledgeAccreditationID(selected[0].value);
            setKnowledgeAccreditationName(selected[0].text);
        } else if (fieldIndex === 3) {
            setKnowledgeAccreditationPaperCertificates(selected[0].value);
            setKnowledgeAccreditationPaperCertificateNames(selected[0].text);
        } else if (fieldIndex === 4) {
            let selectedValues = '';
            let selectedNames = '';
            [...selected].map((sSelOption) => {
                if (selectedValues.trim() !== '') {
                    selectedValues += ',';
                }
                if (selectedNames.trim() !== '') {
                    selectedNames += ',';
                }
                selectedValues += sSelOption.value;
                selectedNames += sSelOption.text;
            });
            setKnowledgePromotionEmploymentTypes(selectedValues);
            setKnowledgePromotionEmploymentTypeNames(selectedNames);
        } else if (fieldIndex === 5) {
            setKnowledgePromotionDiscountType(selected[0].value);
            setKnowledgePromotionDiscountTypeName(selected[0].text);
        }
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        // Only for Attachment Tab
        if (tab === 7) {
            if (addNewKnowledge) {
                setIsAttachmentSectionDisabled(true);
                setIsAttachmentSectionVisible(false);
            } else if (editKnowledge) {
                setIsAttachmentSectionDisabled(false);
                setIsAttachmentSectionVisible(true);
            }
        }

        displayTabContent();
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        // const divisionId = nodes[0]?.data?.id;
        let divisionIds = '';
        let divisionDetails = '';
        // console.log(nodes);
        nodes.forEach(element => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += element?.data?.id;
            divisionDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (divisionIds) {
            setSelectedNodes(nodes);
            setKnowledgeDivisions(divisionIds);
            setKnowledgeDivisionDetails(divisionDetails);
        }
        setLoading(false);
    }

    function setSearchLearningObjectTreeNodeName(e) {
        setLearningObjectTreeNodeName(e.target.value);
    }

    async function saveLearningObjectTreeSelectedNodes(nodes) {
        setLoading(true);
        let treeIds = '';
        let treeDetails = '';
        console.log(nodes);
        nodes.forEach(element => {
            if (treeIds.trim() !== '') {
                treeIds += ',';
            }
            if (treeDetails.trim() !== '') {
                treeDetails += '&';
            }
            treeIds += element?.data?.id;
            treeDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (treeIds) {
            setLearningObjectTreeSelectedNodes(nodes);
            setKnowledgeTreeNodes(treeIds);
            setKnowledgeTreeNodeDetails(treeDetails);
            setKnowledgeTreeNodeArray(treeDetails.split('&'));
        }
        setLoading(false);
    }

    async function searchKnowledges() {
        setLoading(true);
        const oParams = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getKnowledges(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (knowledge) => {
        return knowledge?.map((kRec) => ({
            LearningObjectID:kRec.LearningObjectID, 
            takeAction:false,
            showDetails:false,
            editKnowledgeClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(knowledge);
        setSliderValue(initialScoreValues);
    }, [knowledge])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleCategoriesClick(e, type) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });

        if (type === 1) {
            setKnowledgeTechnology(selectedValues);
        } else if (type === 2) {
            setKnowledgeSponsors(selectedValues);
        } else if (type === 3) {
            setKnowledgeCategory(selectedValues);
        } else if (type === 4) {
            setKnowledgeBasedTypes(selectedValues);
        } else if (type === 5) {
            setKnowledgeTopics(selectedValues);
        } else if (type === 6) {
            setKnowledgeCEBrokerLicenses(selectedValues);
        } else if (type === 7) {
            //setKnowledgeJobPositions(selectedValues);
        } else if (type === 6) {
            setKnowledgeDivisions(selectedValues);
        } 
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewKnowledge) ? setAddNewKnowledge(false) : setAddNewKnowledge(true);
            setKnowledgeID(-1);
        } else if (tab === 2) {
            (addNewKnowledgeNotificationMessage) ? setAddNewKnowledgeNotificationMessage(false) : setAddNewKnowledgeNotificationMessage(true);
        } else if (tab === 3) {
            (addNewKnowledgeObjective) ? setAddNewKnowledgeObjective(false) : setAddNewKnowledgeObjective(true);
        } else if (tab === 4) {
            (addNewKnowledgeAccreditation) ? setAddNewKnowledgeAccreditation(false) : setAddNewKnowledgeAccreditation(true);
        } else if (tab === 5) {
            (addNewKnowledgePromotion) ? setAddNewKnowledgePromotion(false) : setAddNewKnowledgePromotion(true);
        } 

        setLoading(false);
    }

    // async function handleCancelChildernClick(tab, currentAss) {
    //     const updatedSliderValues = sliderValue.slice(0);
    //     updatedSliderValues.map((row, index) => {
    //         if (row.KnowledgeID === currentAss?.KnowledgeID) {
    //             row.editKnowledgeClicked = false;
    //         }
    //     });
    //     setSliderValue(updatedSliderValues);    
    // }
    
    async function handleSaveChildernClick(tab, currentEval) {
        var bCanSave = true;
        var bClassroomBased = false;
        var bWebBased = false;
        var bOthers = false;
        if (knowledgeType === '1') {
            bClassroomBased = true;
        } else if (knowledgeType === '2') {
            bWebBased = true;
        } else {
            bOthers = true;
        } 
        if ((bClassroomBased) || (bOthers)) {
            if (!((knowledgeName) && (knowledgeName.trim !== '') && (knowledgeActive) && (knowledgeActive.trim !== -1) && (knowledgeGradingType) && (knowledgeGradingType.trim !== -1))) {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
                bCanSave = false;
            }
        } else if (bWebBased) {
            if (!((knowledgeName) && (knowledgeName.trim !== '') && (knowledgeActive) && (knowledgeActive.trim !== -1) && (knowledgeGradingType) && (knowledgeGradingType.trim !== -1)) && 
                (knowledgeOnlineType) && (knowledgeOnlineType.trim !== -1)) {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
                bCanSave = false;
            }
        }

        if (bCanSave) {
            setLoading(true);
            if (tab === 1) {
                const oParams  = {
                    LearningObjectID: knowledgeID,
                    LearningObjectName: knowledgeName,
                    IsCourseActive: (knowledgeActive === '1' ? true : false),
                    LearningObjectDescription: knowledgeDescription,
                    LearningObjectTypeID: knowledgeType,
                    ListPrice: knowledgeListPrice,
                    Version: knowledgeVersion, 
                    PreEnrollmentText: knowledgePreEnrollmentText,
                    CourseNumber: knowledgeCourseNumber,
                    NumberOfDays: knowledgeDuration,
                    GradingTypeID: knowledgeGradingType,
                    PassingScore: knowledgePassingScore,
                    CreditUnits: knowledgeCreditUnits,
                    CreditHours: knowledgeCreditHours,
                    CreditOthers: knowledgeCreditOthers,
                    IsWebEx: (knowledgeWebConference === '1' ? true : false),
                    ConferenceTypeID: knowledgeWebConferenceType,
                    AllowROMS: (knowledgeAllowObjectives === '1' ? true : false),
                    OnlyForCertification: (knowledgeForCertificationOnly === '1' ? true : false),
                    VendorID: knowledgeVendor,
                    WBTTypeID: knowledgeOnlineType,
                    WBTURL: knowledgeOnlineURL,
                    LaunchNewWindow: (knowledgeNewWindowLaunch === '1' ? true : false),
                    NewWindowLaunchText: knowledgeNewWindowLaunchText,
                    ResetSCORMCalls: (knowledgeSCORMReset === '1' ? true : false),
                    StoredCompletedSCORMCalls: (knowledgeCompletedSCORM === '1' ? true : false),
                    AcknowledgementText: knowledgeAcknowledgementText,
                    TrainingVendorID: knowledgeTrainingFacility,
                    CEBrokerPublishing: knowledgeCEBrokerPublishingNumber,
                    CEBrokerReporting: knowledgeCEBrokerReportingNumber,
                    CategoryTypeID: knowledgeCategory,
                    LearningObjectOutline: getHTMLFromEditor(),
                    EvaluationID: knowledgeEvaluations,
                    ReminderID: knowledgeReminders,
                    DivisionIDs: knowledgeDivisions,
                    PaperCertificateID: knowledgePaperCertificates,
                    SCORMRootID: knowledgeSCORMS,
                    LearningObjectSubCategories: knowledgeBasedTypes,
                    LearningObjectTechnologies: knowledgeTechnology,
                    LearningObjectCEBrokerLicenses: knowledgeCEBrokerLicenses,
                    LearningObjectTopics: knowledgeTopics,                
                    LearningObjectOwnerships: knowledgeOwnerships,
                    LearningObjectPrerequisites: knowledgePrerequisites,
                    LearningObjectSameAs: knowledgeSameAs,
                    LearningObjectTreeNodes: knowledgeTreeNodes,
                    LearningObjectPreAssessments: knowledgePreAssessments,
                    ForcePreTest: (knowledgeForcePreTest === '1' ? true : false),
                    PreTestPassingScore : knowledgePreTestPassingScore,
                    ForcePreTestComplete : (knowledgeForcePreTestComplete === '1' ? true : false),
                    PreTestCompletionPassingScore : knowledgePreTestCompletionPassingScore,
                    LearningObjectPostAssessments: knowledgePostAssessments,
                    LearningObjectNotificationMessages: knowledgeNotificationMessageDetails,
                    LearningObjectROMS: knowledgeObjectiveDetails,
                    LearningObjectAccreditations: knowledgeAccreditationDetails,
                    LearningObjectPromotions: knowledgePromotionDetails,
                    IsDeleted:false,
                    CreatedByUserID: auth?.UserID,
                    UpdatedByUserID: auth?.UserID,
                    CompanyID: auth?.CompanyID,
                };        
                console.log(oParams);
                if (knowledgeID === -1) {
                    await props.insertKnowledge(oParams);    
                    setAddNewKnowledge(false);
                } else {
                    await props.updateKnowledge(oParams);    
                    setEditKnowledge(false);
                }
            } 
    
            const oParams = {
                LearningObjectTypeID:radioTypeValue,
                LearningObjectName:nodeName,
                IsCourseActive:(radioValue === '1') ? 'true' : 'false',
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledges(oParams);
    
            setAddNewKnowledge(false);
            setEditKnowledge(false);
            setLoading(false);    
        }
    }

    async function handleDeleteChildernClick(id) {
        setLoading(true);

        const oParams  = {
            LearningObjectID: id,
        };        
        // console.log(oParams);
        await props.deleteKnowledge(oParams);    
        
        const oParams1 = {
            LearningObjectTypeID:radioTypeValue,
            LearningObjectName:nodeName,
            IsCourseActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:1,
        }; 
        
        await props.getKnowledges(oParams1);            

        setLoading(false);
    }

    function UpdateChildrenState(id) {
        let preassIDs = '';
        let preassNames = '';
        let preassDetails = '';
        let postassIDs = '';
        let postassNames = '';
        let postassDetails = '';
        const assRec = assessment?.filter((cRec) => (cRec.LearningObjectID === id));
        assRec?.map((row, index) => {
            if (row?.IsPreTest) {
                if (preassIDs.trim() !== '') {
                    preassIDs += ',';
                }
                if (preassNames.trim() !== '') {
                    preassNames += '&';
                }    
                if (preassDetails.trim() !== '') {
                    preassDetails += '&';
                }    
                preassIDs += row?.TestID + '|' + row?.TestInstanceID;
                preassNames += row?.TestInstanceName;
                preassDetails += row?.TestID + '|' + row?.TestInstanceID + '=' + row?.TestInstanceName;
                setKnowledgeForcePreTest((row?.ForcePreTestForNextStep) ? '1' : '0');
                setKnowledgePreTestPassingScore(row?.PreTestPassingScore);
                setKnowledgeForcePreTestComplete((row?.ForceCompletionPreTestPassed) ? '1' : '0');
                setKnowledgePreTestCompletionPassingScore(row?.ForceCompletionPreTestPassingScore);
            } else {
                if (postassIDs.trim() !== '') {
                    postassIDs += ',';
                }
                if (postassNames.trim() !== '') {
                    postassNames += '&';
                }    
                if (postassDetails.trim() !== '') {
                    postassDetails += '&';
                }    
                postassIDs += row?.TestID + '|' + row?.TestInstanceID;
                postassNames += row?.TestInstanceName;
                postassDetails += row?.TestID + '|' + row?.TestInstanceID + '=' + row?.TestInstanceName;
            }
        });

        if (preassIDs.trim() !== '') {
            setKnowledgePreAssessments(preassIDs);
            setKnowledgePreAssessmentNames(preassNames);
            if (preassDetails.trim() !== '') {
                const oSelections = preassDetails?.split('&');
                setKnowledgePreAssessmentArray(oSelections);
            }
        }
        if (postassIDs.trim() !== '') {
            setKnowledgePostAssessments(postassIDs);
            setKnowledgePostAssessmentNames(postassNames);
            if (postassDetails.trim() !== '') {
                const oSelections = postassDetails?.split('&');
                setKnowledgePostAssessmentArray(oSelections);
            }
        }

        let preqIDs = '';
        let preqDetails = '';
        const preqRec = prerequisite?.filter((cRec) => (cRec.LearningObjectID === id));
        preqRec?.map((row, index) => {
            if (preqIDs.trim() !== '') {
                preqIDs += ',';
            }
            if (preqDetails.trim() !== '') {
                preqDetails += '&';
            }

            preqIDs += row?.RequiredLearningObjectID;
            preqDetails += row?.RequiredLearningObjectID + '=' + row?.RequiredLearningObjectName;
        });
        if (preqIDs.trim() !== '') {
            setKnowledgePrerequisites(preqIDs);
        }
        if (preqDetails.trim() !== '') {
            const oSelections = preqDetails?.split('&');
            setKnowledgePrerequisitesArray(oSelections);
        }

        let accIDs = '';
        const accRec = accreditations?.filter((cRec) => (cRec.LearningObjectID === id));
        accRec?.map((row, index) => {
            if (accIDs.trim() !== '') {
                accIDs += '&';
            }
            accIDs += row?.AccreditationID + '|' + row?.AccreditationName + '|' + row?.AccreditationNumber + '|' + ((row?.ShowComments) ? '1' : '0') + '|' + row?.CreditUnits + 
                '|' + row?.CreditHours + '|' + row?.CreditOthers + '|' + row?.PassingScore + '|' + row?.ReleaseDate + '|' + row?.ExpiryDate +
                '|' + ((row?.EnforceRetakeLogic) ? '1' : '0') + '|' + row?.NumberofFalseStartsAllowed + '|' + row?.DaysBeforeRetake + '|' + row?.TestInstanceID + '|' + row?.TestInstanceName +
                '|' + row?.EvaluationID + '|' + row?.EvaluationName + '|' + row?.ScormID + '|' + row?.ScormTitle + '|' + row?.PaperCertificateID;

        });
        if (accIDs !== '') {
            setKnowledgeAccreditationDetails(accIDs);
            setKnowledgeAccreditationArray(accIDs.split('&'));
        }

        let ceBrokerLicIDs = '';
        const ceBrokerRec = ceBrokerLicense?.filter((cRec) => (cRec.LearningObjectID === id));
        ceBrokerRec?.map((row, index) => {
            if (ceBrokerLicIDs.trim() !== '') {
                ceBrokerLicIDs += ',';
            }
            ceBrokerLicIDs += row?.CEBrokerLicenseTypeID;
            setKnowledgeCEBrokerLicenses(ceBrokerLicIDs);
        });

        let forumThreadIDs = '';
        const forumThreadRec = forumThread?.filter((cRec) => (cRec.LearningObjectID === id));
        forumThreadRec?.map((row, index) => {
            if (forumThreadIDs.trim() !== '') {
                forumThreadIDs += ',';
            }
            forumThreadIDs += row?.LearningObjectForumThreadID;
            setKnowledgeForumThreads(forumThreadIDs);
        });

        const notesRec = notes?.filter((cRec) => (cRec.LearningObjectID === id));

        let nmIDs = '';
        const notificationMessageRec = notificationMessage?.filter((cRec) => (cRec.LearningObjectID === id));
        notificationMessageRec?.map((row, index) => {
            if (nmIDs.trim() !== '') {
                nmIDs += '&';
            }
            nmIDs += row?.LearningObjectProcessStatusTypeID + '|' + row?.LearningObjectProcessStatusTypeName + '|' + row?.NotificationMessageID + '|' + row?.NotificationMessageName;
        });
        if (nmIDs !== '') {
            setKnowledgeNotificationMessageDetails(nmIDs);
            setKnowledgeNotificationMessageArray(nmIDs.split('&'));    
        }

        let ownershipIDs = '';
        let ownershipNames = '';
        let ownershipDetails = '';
        const ownershipRec = ownership?.filter((cRec) => (cRec.LearningObjectID === id));
        ownershipRec?.map((row, index) => {
            if (ownershipIDs.trim() !== '') {
                ownershipIDs += ',';
            }
            if (ownershipDetails.trim() !== '') {
                ownershipDetails += '&';
            }

            ownershipIDs += row?.OwnerUserID;
            ownershipDetails += row?.OwnerUserID + '=' + row?.OwnerUserFullName + '|' + row?.OwnerVendorUserID + '|' + row?.OwnerEmail;
        });
        if (ownershipIDs.trim() !== '') {
            setKnowledgeOwnerships(ownershipIDs);
        }
        if (ownershipDetails.trim() !== '') {
            const oSelections = ownershipDetails?.split('&');
            setKnowledgeSkillPreceptorArray(oSelections);
        }

        let promotionIDs = '';
        const promotionRec = promotion?.filter((cRec) => (cRec.LearningObjectID === id));
        promotionRec?.map((row, index) => {
            if (promotionIDs.trim() !== '') {
                promotionIDs += '&';
            }
            promotionIDs += row?.PromotionName + '|' + row?.PromotionCode + '|' + row?.AccessCode + '|' + row?.StartDateTime + '|' + row?.EndDateTime + '|' + row?.DiscountTypeID + '|' + row?.DiscountTypeName +
                '|' + row?.PromotionPrice + '|' + ((row?.AutoApply) ? '1' : '0') + '|' + row?.EmploymentTypeIDs;
        });
        if (promotionIDs !== '') {
            setKnowledgePromotionDetails(promotionIDs);
            setKnowledgePromotionArray(promotionIDs.split('&'));
        }


        let romsIDs = '';
        const romsRec = roms?.filter((cRec) => (cRec.LearningObjectID === id));
        romsRec?.map((row, index) => {
            if (romsIDs.trim() !== '') {
                romsIDs += '&';
            }
            romsIDs += row?.SkillID + '|' + row?.ROMSID + '|' + row?.DurationOfCompletion + '|' + row?.Objective + '|' + ((row?.RequiresApprovalManger) ? '1' : '0') + 
            '|' + ((row?.RequiresCompletionManager) ? '1' : '0') + '|' + row?.WBTTypeID + '|' + row?.ObjectiveURL;
        });
        if (romsIDs !== '') {
            setKnowledgeObjectiveDetails(romsIDs);
            setKnowledgeObjectiveArray(romsIDs.split('&'));
        }

        let resourceIDs = '';
        const resourceRec = resource?.filter((cRec) => (cRec.LearningObjectID === id));
        resourceRec?.map((row, index) => {
            if (resourceIDs.trim() !== '') {
                resourceIDs += ',';
            }
            resourceIDs += row?.LearningObjectResourceID;
            setKnowledgeResources(resourceIDs);
        });

        let sameAsIDs = '';
        let sameAsDetails = '';
        const sameAsRec = sameAs?.filter((cRec) => (cRec.LearningObjectID === id));
        sameAsRec?.map((row, index) => {
            if (sameAsIDs.trim() !== '') {
                sameAsIDs += ',';
            }
            if (sameAsDetails.trim() !== '') {
                sameAsDetails += '&';
            }

            sameAsIDs += row?.LearningObjectForCreditID;
            sameAsDetails += row?.LearningObjectForCreditID + '=' + row?.LearningObjectForCreditName;
        });
        if (sameAsIDs.trim() !== '') {
            setKnowledgeSameAs(preqIDs);
        }
        if (sameAsDetails.trim() !== '') {
            const oSelections = sameAsDetails?.split('&');
            setKnowledgeSameAsArray(oSelections);
        }

        let sponsorIDs = '';
        const sponsorRec = sponsor?.filter((cRec) => (cRec.LearningObjectID === id));
        sponsorRec?.map((row, index) => {
            if (sponsorIDs.trim() !== '') {
                sponsorIDs += ',';
            }
            sponsorIDs += row?.SponsorID;
            setKnowledgeSponsors(sponsorIDs);
        });

        let subcategoryIDs = '';
        const subCategoryRec = subCategory?.filter((cRec) => (cRec.LearningObjectID === id));
        subCategoryRec?.map((row, index) => {
            if (subcategoryIDs.trim() !== '') {
                subcategoryIDs += ',';
            }
            subcategoryIDs += row?.LearningObjectSubCategoryTypeID;
            setKnowledgeBasedTypes(subcategoryIDs);
        });

        let technologyIDs = '';
        const technologyRec = technology?.filter((cRec) => (cRec.LearningObjectID === id));
        technologyRec?.map((row, index) => {
            if (technologyIDs.trim() !== '') {
                technologyIDs += ',';
            }
            technologyIDs += row?.LearningObjectTechnologyTypeID;
            setKnowledgeTechnology(technologyIDs);
        });

        let testIDs = '';
        const testRec = test?.filter((cRec) => (cRec.LearningObjectID === id));
        testRec?.map((row, index) => {
            if (testIDs.trim() !== '') {
                testIDs += ',';
            }
            testIDs += row?.TestInstanceID;
            setKnowledgeAssessments(testIDs);
        });

        let topicIDs = '';
        const topicRec = topic?.filter((cRec) => (cRec.LearningObjectID === id));
        topicRec?.map((row, index) => {
            if (topicIDs.trim() !== '') {
                topicIDs += ',';
            }
            topicIDs += row?.TopicID;
            setKnowledgeTopics(topicIDs);
        });

        let treeNodeIDs = '';
        let treeNodeDetails = '';
        const treeNodeRec = treeNode?.filter((cRec) => (cRec.LearningObjectID === id));
        treeNodeRec?.map((row, index) => {
            if (treeNodeIDs.trim() !== '') {
                treeNodeIDs += ',';
            }
            if (treeNodeDetails.trim() !== '') {
                treeNodeDetails += '&';
            }
            treeNodeIDs += row?.LearningObjectTreeID;
            treeNodeDetails += row?.LearningObjectTreeID + '=' + row?.TreePathName;
            if (treeNodeIDs) {
                setKnowledgeTreeNodes(treeNodeIDs);
                setKnowledgeTreeNodeDetails(treeNodeDetails);
                setKnowledgeTreeNodeArray(treeNodeDetails.split('&'));
            }            
        });

        let divisionIds = '';
        let divisionDetails = '';
        const availabilityRec = availability?.filter((cRec) => (cRec.LearningObjectID === id));
        availabilityRec?.map((row, index) => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += row?.DivisionID;
            divisionDetails += row?.DivisionID + '=' + row?.DivisionNamePath;
        });
        if (divisionIds) {
            // setSelectedNodes(nodes);
            setKnowledgeDivisions(divisionIds);
            setKnowledgeDivisionDetails(divisionDetails);
        }
    }

    async function handleViewChildernClick(id) {
        try {
            setLoading(true);
            const oKParams = getSearchParamsEx(radioValue, radioTypeValue, knowledges?.knowledges?.PagingMetaData?.PageNumber);
            const oParams = {
                LearningObjectID:id,
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getKnowledgeInstances(oParams);
            navigate('/knowledgeinstancelist/' + id, {state: {permissiontypeid:permissionTypeID, parentParams:oKParams, listby:radioTypeValue}});    
        } catch (err){
            console.log('Error is getting instance details - ' + err);
            setLoading(false);
        }
    }

    async function handleUpdateChildernClick(id) {
        setLoading(true);
        setTabValue(1);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const assEditedRec = knowledge?.filter((assRec) => (assRec.LearningObjectID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setKnowledgeID(id);
            setKnowledgeName(assEditedRec[0]?.LearningObjectName);
            setKnowledgeActive((assEditedRec[0]?.IsCourseActive) ? '1' : '0'); 
            setKnowledgeDescription(assEditedRec[0]?.LearningObjectDescription);
            setKnowledgeType(parseInt(assEditedRec[0]?.LearningObjectTypeID));
            setKnowledgeListPrice(assEditedRec[0]?.ListPrice);
            setKnowledgeVersion(assEditedRec[0]?.Version);
            setKnowledgePreEnrollmentText(assEditedRec[0]?.PreEnrollmentText);
            setKnowledgeCourseNumber(assEditedRec[0]?.CourseNumber);
            setKnowledgeDuration(assEditedRec[0]?.NumberOfDays);
            setKnowledgeGradingType(assEditedRec[0]?.GradingTypeID);
            setKnowledgePassingScore(assEditedRec[0]?.PassingScore);
            setKnowledgeCreditUnits(assEditedRec[0]?.CreditUnits);
            setKnowledgeCreditHours(assEditedRec[0]?.CreditHours);
            setKnowledgeCreditOthers(assEditedRec[0]?.CreditOthers);
            setKnowledgeWebConference((assEditedRec[0]?.IsWebEx) ? '1' : '0');
            setKnowledgeWebConferenceType(assEditedRec[0]?.ConferenceTypeID);
            setKnowledgeAllowObjectives((assEditedRec[0]?.AllowROMS) ? '1' : '0');
            setKnowledgeForCertificationOnly((assEditedRec[0]?.OnlyForCertification) ? '1' : '0');
            setKnowledgeVendor(assEditedRec[0]?.VendorID);
            setKnowledgeOnlineType(assEditedRec[0]?.WBTTypeID);
            setKnowledgeOnlineURL(assEditedRec[0]?.WBTURL);
            setKnowledgeNewWindowLaunch((assEditedRec[0]?.LaunchNewWindow) ? '1' : '0');
            setKnowledgeNewWindowLaunchText(assEditedRec[0]?.NewWindowLaunchText);
            setKnowledgeSCORMReset((assEditedRec[0]?.ResetSCORMCalls) ? '1' : '0');
            setKnowledgeCompletedSCORM((assEditedRec[0]?.StoredCompletedSCORMCalls) ? '1' : '0');
            setKnowledgeAcknowledgementText(assEditedRec[0]?.AcknowledgementText);
            setKnowledgeCategory(assEditedRec[0]?.CategoryTypeID);
            setKnowledgeTrainingFacility(assEditedRec[0]?.DivisionID);
            setKnowledgeCEBrokerPublishingNumber(assEditedRec[0]?.CEBrokerPublishing);
            setKnowledgeCEBrokerReportingNumber(assEditedRec[0]?.CEBrokerReporting);
            setKnowledgeEvaluations(assEditedRec[0]?.EvaluationID);
            setKnowledgeEvaluationNames(assEditedRec[0]?.EvaluationName);
            setKnowledgeReminders(assEditedRec[0]?.ReminderID);
            setKnowledgeReminderNames(assEditedRec[0]?.ReminderName);
            setKnowledgePaperCertificates(assEditedRec[0]?.PaperCertificateID);
            setKnowledgePaperCertificateNames(assEditedRec[0]?.PaperCertificateName);
            setEditorHTML(false, assEditedRec[0]?.LearningObjectOutline)
            setKnowledgeSCORMS(assEditedRec[0]?.SCORMRootID);
            setKnowledgeSCORMNames(assEditedRec[0]?.SCORMRootTitle);
            UpdateChildrenState(id);
           
            setEditKnowledge(true);
            const oAParams  = {
                UserID:auth?.UserID,
                EntityID: id,
                //AttachmentTypeID: AttachmentType.Course,
                AttachmentTypeIDs: AttachmentType.Course + ',' + AttachmentType.Acknowledgement,
                EntityRowTypeID: EntityRowType.Course,
                SearchFilter:'',
            };                
            await props.getAttachments(oAParams);    
    
            setIsAttachmentSectionDisabled(true);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(id) {
        if (knowledgeID === -1) {
            setAddNewKnowledge(false);
        } else {
            setEditKnowledge(false);
        }
        ClearStateFields();
    }
    
    async function ClearStateFields() {
        setKnowledgeID(-1);
        setKnowledgeName('');
        setKnowledgeActive(-1); 
        setKnowledgeDescription('');
        setKnowledgeType(-1);
        setKnowledgeListPrice(null);
        setKnowledgeVersion(null);
        setKnowledgePreEnrollmentText(null);
        setKnowledgeCourseNumber(null);
        setKnowledgeDuration(null);
        setKnowledgeGradingType(null);
        setKnowledgePassingScore(null);
        setKnowledgeCreditUnits(null);
        setKnowledgeCreditHours(null);
        setKnowledgeCreditOthers(null);
        setKnowledgeWebConference(-1);
        setKnowledgeWebConferenceType(null);
        setKnowledgeAllowObjectives(null);
        setKnowledgeForCertificationOnly(null);
        setKnowledgeVendor(null);
        setKnowledgeOnlineType(null);
        setKnowledgeOnlineURL(null);
        setKnowledgeNewWindowLaunch(null);
        setKnowledgeNewWindowLaunchText(null);
        setKnowledgeSCORMReset(null);
        setKnowledgeCompletedSCORM(null);
        setKnowledgeAcknowledgementText(null);
        // setKnowledgeTrainingFacility(null);
        setKnowledgeCEBrokerPublishingNumber(null);
        setKnowledgeCEBrokerReportingNumber(null);
        setKnowledgeEvaluations(null);
        setKnowledgeEvaluationNames('');
        setKnowledgeReminders(null);
        setKnowledgeReminderNames('');
        setKnowledgePaperCertificates(null);
        setKnowledgePaperCertificateNames('');
        setEditorHTML(true, null);
        setKnowledgeAccreditations('');
        setKnowledgeCEBrokerLicenses('');
        setKnowledgeForumThreads('');
        setKnowledgeNotificationMessages('');
        setKnowledgeOwnerships('');
        setKnowledgePromotions('');
        setKnowledgeROMS('');
        setKnowledgeResources('');
        setKnowledgeSameAs('');
        setKnowledgeSponsors('');
        setKnowledgeBasedTypes('');
        setKnowledgeTechnology('');
        setKnowledgeAssessments('');
        setKnowledgeTopics('');
        setKnowledgeTreeNodes('');
        setKnowledgeTreeNodeDetails('');
        setKnowledgeDivisions('');
        setKnowledgeDivisionDetails('');
        setKnowledgeSkillPreceptors('');
        setKnowledgeSkillPreceptorDetails('');
        setKnowledgePrerequisites('');
        setKnowledgePrerequisitesDetails('');
        setKnowledgeSCORMS(null);
        setKnowledgeSCORMNames(null);
        setKnowledgePreAssessments(null);
        setKnowledgePreAssessmentNames(null);
        setKnowledgePreAssessmentArray(null);
        setKnowledgeForcePreTest(-1);
        setKnowledgePreTestPassingScore(null);
        setKnowledgeForcePreTestComplete(-1);
        setKnowledgePreTestCompletionPassingScore(null);
        setKnowledgePostAssessments(null);
        setKnowledgePostAssessmentNames(null);
        setKnowledgePostAssessmentArray(null);
        setKnowledgeProcessStatusTypeID(null);
        setKnowledgeProcessStatusTypeName(null);
        setKnowledgeNotificationMessages('');
        setKnowledgeNotificationMessageNames('');
        setKnowledgeNotificationMessageDetails('');
        setKnowledgeNotificationMessageArray([]);
        setKnowledgeObjectives('');
        setKnowledgeObjectiveNames('');
        setKnowledgeObjectiveDetails('');
        setKnowledgeObjectiveArray([]);
        setKnowledgeObjectiveDisplayOrder(null);
        setKnowledgeObjectiveDuration(null);
        setKnowledgeObjectiveRequiresApproval(null);
        setKnowledgeObjectiveRequiresCompletion(null);
        setKnowledgeObjectiveOnlineType(null);
        setKnowledgeObjectiveOnlineURL(null);
        setKnowledgeObjective(null);
        setKnowledgeAccreditationArray(null);
        setKnowledgeAccreditationDetails(null);
        setKnowledgeAccreditationID(null);
        setKnowledgeAccreditationName(null);
        setKnowledgeAccreditationNumber(null);
        setKnowledgeAccreditationShowComments(null);
        setKnowledgeAccreditationPassingScore(null);
        setKnowledgeAccreditationCreditUnits(null);
        setKnowledgeAccreditationCreditHours(null);
        setKnowledgeAccreditationCreditOthers(null);
        setKnowledgeAccreditationEnforceAttemptsLogic(null);
        setKnowledgeAccreditationRetakeAttempts(null);
        setKnowledgeAccreditationDaysBeforeRetake(null);
        setKnowledgeAccreditationExpirationDate(null);
        setKnowledgeAccreditationReleaseDate(null);
        setKnowledgeAccreditationSCORMS(null);
        setKnowledgeAccreditationSCORMNames(null);
        setKnowledgeAccreditationEvaluations(null);
        setKnowledgeAccreditationEvaluationNames(null);
        setKnowledgeAccreditationPostAssessments(null);
        setKnowledgeAccreditationPostAssessmentNames(null);
        setKnowledgeAccreditationPaperCertificates(null);
        setKnowledgeAccreditationPaperCertificateNames(null);
        setShowAccreditationEvaluationList(null);
        setShowAccreditationContentList(null);
        setShowAccreditationPostAssessmentList(null);
        setKnowledgePromotionArray(null);
        setKnowledgePromotionDetails(null);
        setKnowledgePromotionDiscountType(null);
        setKnowledgePromotionDiscountTypeName(null);
        setKnowledgePromotionName(null);
        setKnowledgePromotionCode(null);
        setKnowledgePromotionAccessCode(null);
        setKnowledgePromotionPrice(null);
        setKnowledgePromotionAutoApply(null);
        setKnowledgePromotionStartDate(null);
        setKnowledgePromotionExpirationDate(null);
        setKnowledgePromotionEmploymentTypes(null);
        setKnowledgePromotionEmploymentTypeNames(null);
    }

    async function handleAddSubChildrenClick(tab){
        if (tab === 2) {
            if ((knowledgeProcessStatusTypeID) && (knowledgeProcessStatusTypeID !== -1) && (knowledgeNotificationMessages) && (knowledgeNotificationMessages.trim() !== '')) {
                var sSelections = knowledgeProcessStatusTypeID + '|' + knowledgeProcessStatusTypeName + '|' + knowledgeNotificationMessages + '|' + knowledgeNotificationMessageNames;
                if ((knowledgeNotificationMessageDetails) && (knowledgeNotificationMessageDetails?.trim() !== '')) {
                    sSelections = knowledgeNotificationMessageDetails + '&' + sSelections;
                }
                const oSelections = sSelections.split('&');
                setKnowledgeNotificationMessageArray(oSelections);
                setKnowledgeNotificationMessageDetails(sSelections);
                setAddNewKnowledgeNotificationMessage(false);        
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 3) {
            if ((knowledgeObjectives) && (knowledgeObjectives.trim() !== '') && (knowledgeObjectiveDisplayOrder) && (knowledgeObjectiveDisplayOrder.trim() !== '') && 
                (knowledgeObjectiveDuration) && (knowledgeObjectiveDuration.trim() !== '')) {
                    var sSelections = knowledgeObjectives + '|' + knowledgeObjectiveDisplayOrder + '|' + knowledgeObjectiveDuration + '|' + knowledgeObjective + '|' + knowledgeObjectiveRequiresApproval + 
                    '|' + knowledgeObjectiveRequiresCompletion + '|' + knowledgeObjectiveOnlineType + '|' + knowledgeObjectiveOnlineURL;
                if ((knowledgeObjectiveDetails) && (knowledgeObjectiveDetails?.trim()) !== '') {
                    sSelections = knowledgeObjectiveDetails + '&' + sSelections;
                } 
                const oSelections = sSelections.split('&');
                setKnowledgeObjectiveArray(oSelections);
                setKnowledgeObjectiveDetails(sSelections);
                setAddNewKnowledgeObjective(false);        
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 4) {
            if ((knowledgeAccreditationID) && (knowledgeAccreditationID.trim() !== -1) && (knowledgeAccreditationNumber) && (knowledgeAccreditationNumber.trim() !== '')) {
                var sSelections = knowledgeAccreditationID + '|' + knowledgeAccreditationName + '|' + knowledgeAccreditationNumber + '|' + knowledgeAccreditationShowComments + '|' + knowledgeAccreditationCreditUnits + 
                '|' + knowledgeAccreditationCreditHours + '|' + knowledgeAccreditationCreditOthers + '|' + knowledgeAccreditationPassingScore + '|' + knowledgeAccreditationReleaseDate + '|' + knowledgeAccreditationExpirationDate +
                '|' + knowledgeAccreditationEnforceAttemptsLogic + '|' + knowledgeAccreditationRetakeAttempts + '|' + knowledgeAccreditationDaysBeforeRetake + '|' + knowledgeAccreditationPostAssessments + '|' + knowledgeAccreditationPostAssessmentNames +
                '|' + knowledgeAccreditationEvaluations + '|' + knowledgeAccreditationEvaluationNames + '|' + knowledgeAccreditationSCORMS + '|' + knowledgeAccreditationSCORMNames + '|' + knowledgeAccreditationPaperCertificates;
                if ((knowledgeAccreditationDetails) && (knowledgeAccreditationDetails?.trim() !== '')) {
                    sSelections = knowledgeAccreditationDetails + '&' + sSelections;
                } 
                const oSelections = sSelections.split('&');
                setKnowledgeAccreditationArray(oSelections);
                setKnowledgeAccreditationDetails(sSelections);
                setAddNewKnowledgeAccreditation(false);   
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 5) {
            if ((knowledgePromotionName) && (knowledgePromotionName.trim() !== '') && (knowledgePromotionCode) && (knowledgePromotionCode.trim() !== '') && 
                (knowledgePromotionAccessCode) && (knowledgePromotionAccessCode.trim() !== '')) {
                    var sSelections = knowledgePromotionName + '|' + knowledgePromotionCode + '|' + knowledgePromotionAccessCode + '|' + knowledgePromotionStartDate + '|' + knowledgePromotionExpirationDate + '|' + knowledgePromotionDiscountType + '|' + knowledgePromotionDiscountTypeName + 
                    '|' + knowledgePromotionPrice + '|' + knowledgePromotionAutoApply + '|' + knowledgePromotionEmploymentTypes;
                if ((knowledgePromotionDetails) && (knowledgePromotionDetails?.trim() !== '')) {
                    sSelections = knowledgePromotionDetails + '&' + sSelections;
                } 
                const oSelections = sSelections.split('&');
                setKnowledgePromotionArray(oSelections);
                setKnowledgePromotionDetails(sSelections);
                setAddNewKnowledgePromotion(false);        
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        }
    }

    async function handleDeleteSubChildren(tab, element) {
        setLoading(true);
        if (tab === 2) {
            var tempSkills = knowledgeNotificationMessageArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setKnowledgeNotificationMessageArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setKnowledgeNotificationMessageDetails(sSelectedDetails);
            }            
        } else if (tab === 3) {
            var tempSkills = knowledgeObjectiveArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setKnowledgeObjectiveArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setKnowledgeObjectiveDetails(sSelectedDetails);
            }            
        } else if (tab === 4) {
            console.log(knowledgeAccreditationArray);
            var tempSkills = knowledgeAccreditationArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setKnowledgeAccreditationArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setKnowledgeAccreditationDetails(sSelectedDetails);
                console.log(sSelectedDetails);
            }            
        } else if (tab === 5) {
            var tempSkills = knowledgePromotionArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setKnowledgePromotionArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setKnowledgePromotionDetails(sSelectedDetails);
            }            
        }
        setLoading(false);
    }    

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Knowledge'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Knowledge'>Cancel</Button>
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radioKnowledege.map((radio, idx) => (
                ((radio.value === 7) && (addNewKnowledge)) ? 
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer}  title= 'Only available in edit mode!' onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
                :
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        }
        else if (tabValue === 2 ) {
            return (
                <div>
                    {displayWebBased()}
                </div>
            );
        } else if (tabValue === 3 ) {
            return (
                <div>
                    {displayCatalog()}
                </div>
            );
        } else if (tabValue === 4 ) {
            return (
                <div>
                    {displayCategories()}
                </div>
            );
        } else if (tabValue === 5 ) {
            return (
                <div>
                    {displayOutline()}
                </div>
            );
        } else if (tabValue === 6 ) {
            return (
                <div>
                    {displayAssessment()}
                </div>
            );
        } else if (tabValue === 7 ) {
            return (
                <div>
                    {displayAttachment()}
                </div>
            );
        } else if (tabValue === 8 ) {
            return (
                <div>
                    {displayAdvanced()}
                </div>
            );
        } else if (tabValue === 9 ) {
            return (
                <div>
                    {displayOrganization()}
                </div>
            );
        } 
    }

    function displayNewKnowledge() {
        if ((addNewKnowledge) || (editKnowledge)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewKnowledge) ? 'New Knowledge Track' : 'Edit Knowledge Track'}                        
                        </Card.Title>
                        <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayTabButtons()}
                                </td>
                            </tr>
                            </tbody>
                        </Table>    
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                {displaySaveButtons()}
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    // function displayAssignedSkills() {
    //     if (knowledgeSkillArray != null) {
    //         return (
    //             <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
    //                 {knowledgeSkillArray?.map((skill, supIndex) => (
    //                     <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center">
    //                         <div className="ms-2 me-auto">
    //                             <div>{skill.split('=')[1].split('|')[2]} - {skill.split('=')[1].split('|')[4]} - {skill.split('=')[1].split('|')[0]}</div>                                
    //                         </div>
    //                         <a onClick={() => handleDeleteAssignedSkillClick(skill)}>
    //                             <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Competency'>delete</span>
    //                         </a>
    //                 </ListGroup.Item>
    //                 ))}
    //             </ListGroup>
    //         );            
    //     }
    // }

    function displayAssignedSkillPreceptors() {
        if (knowledgeSkillPreceptorArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {knowledgeSkillPreceptorArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedSkillPreceptorClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Ownership'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedPrerequisites() {
        if (knowledgePrerequisitesArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {knowledgePrerequisitesArray?.map((goal, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{goal.split('=')[1]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedPrerequisitesClick(goal)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Prerequiste'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedSameAs() {
        if (knowledgeSameAsArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {knowledgeSameAsArray?.map((goal, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{goal.split('=')[1]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedSameAsClick(goal)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Same As'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAttributes() {
            return (
                <table class="table table-sm caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="knowledgeName" style={styles.LabelDataEntryNoPaddingRequired}>Name</label>
                                    <input id="knowledgeName" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeName} onChange={(e) => (setKnowledgeName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="asstype" style={styles.LabelDataEntryNoPaddingRequired}>Type</label>
                                    <select id="asstype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeType} onChange={(e) => (setKnowledgeType(e.target.value))}>
                                        <option value='-1'>Select One</option>
                                        {knowledgeTypeData?.map((oItem, iIndex) => (
                                            <option id={oItem?.LearningObjectTypeID?.split(',')[0]} value={oItem?.LearningObjectTypeID?.split(',')[0]}>{oItem.LearningObjectTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="assactive" style={styles.LabelDataEntryNoPaddingRequired}>Active</label>
                                    <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeActive} onChange={(e) => (setKnowledgeActive(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeDescription} onChange={(e) => (setKnowledgeDescription(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="listprice" style={styles.LabelDataEntryNoPadding}>List Price</label>
                                    <input id="listprice" type="number" min='1' max='100' style={styles.TextDataEntryNoPadding} value={knowledgeListPrice} onChange={(e) => (setKnowledgeListPrice(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="version" style={styles.LabelDataEntryNoPadding}>Version</label>
                                    <input id="version" type="number" min='1' max='100' style={styles.TextDataEntryNoPadding} value={knowledgeVersion} onChange={(e) => (setKnowledgeVersion(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>       
                        <tr>
                            <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="preenorlldesc" style={styles.LabelDataEntryNoPadding}>Pre Enrollment Text</label>
                                    <input id="preenorlldesc" type="text" style={styles.TextDataEntryNoPadding} value={knowledgePreEnrollmentText} onChange={(e) => (setKnowledgePreEnrollmentText(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="coursenumber" style={styles.LabelDataEntryNoPadding}>Number</label>
                                    <input id="coursenumber" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeCourseNumber} onChange={(e) => (setKnowledgeCourseNumber(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="duration" style={styles.LabelDataEntryNoPadding}>Duration</label>
                                    <input id="duration" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeDuration} onChange={(e) => (setKnowledgeDuration(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>       
                        <tr>
                            <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <table class="table table-sm caption-top text-left" style={{backgroundColor:companyData?.PagesBGColor}}>  
                                    <tr>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="newhire" style={styles.LabelDataEntryNoPaddingWBGRequired}>Grading Type</label>
                                            <select id="newhire" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeGradingType} onChange={(e) => (setKnowledgeGradingType(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                {gradingTypeData?.map((oItem, iIndex) => (
                                                    <option id={oItem?.GradingTypeID} value={oItem?.GradingTypeID}>{oItem.GradingTypeName}</option>        
                                                ))}
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="passingscore" style={styles.LabelDataEntryNoPaddingWBG}>Passing Score</label>
                                            <input id="passingscore" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgePassingScore} onChange={(e) => (setKnowledgePassingScore(e.target.value))}/>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="creditunits" style={styles.LabelDataEntryNoPaddingWBG}>Credit Units</label>
                                            <input id="creditunits" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeCreditUnits} onChange={(e) => (setKnowledgeCreditUnits(e.target.value))}/>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="credithours" style={styles.LabelDataEntryNoPaddingWBG}>Credit Hours</label>
                                            <input id="credithours" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeCreditHours} onChange={(e) => (setKnowledgeCreditHours(e.target.value))}/>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="creditothers" style={styles.LabelDataEntryNoPaddingWBG}>Credit Others</label>
                                            <input id="creditothers" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeCreditOthers} onChange={(e) => (setKnowledgeCreditOthers(e.target.value))}/>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>       
                        <tr>
                            <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <table class="table table-sm caption-top text-left" style={{backgroundColor:companyData?.PagesBGColor}}>  
                                    <tr>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="webconference" style={styles.LabelDataEntryNoPaddingWBG}>Web Conference</label>
                                            <select id="webconferemce" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeWebConference} onChange={(e) => (setKnowledgeWebConference(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="pillar" style={styles.LabelDataEntryNoPaddingWBG}>Web Conference Type</label>
                                            <select id="pillar" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeWebConferenceType} onChange={(e) => (setKnowledgeWebConferenceType(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value={WebConferenceType.WebExMeeting}>WebEx - Meeting</option>
                                                <option value={WebConferenceType.WebExTraining}>WebEx - Training</option>
                                                <option value={WebConferenceType.GoToMeeting}>GoToMeeting</option>
                                                <option value={WebConferenceType.GoToWebinar}>GoToWebinar</option>
                                                <option value={WebConferenceType.GoToTraining}>GoToTraining</option>
                                                <option value={WebConferenceType.AdobeConnectPro}>Adobe Connect Pro</option>
                                                <option value={WebConferenceType.Zoom}>Zoom</option>
                                                <option value={WebConferenceType.MSTeams}>MS Teams</option>
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="objectives" style={styles.LabelDataEntryNoPaddingWBG}>Allow Objectives</label>
                                            <select id="objectives" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAllowObjectives} onChange={(e) => (setKnowledgeAllowObjectives(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="certificationonly" style={styles.LabelDataEntryNoPaddingWBG}>Certification Only</label>
                                            <select id="certificationonly" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeForCertificationOnly} onChange={(e) => (setKnowledgeForCertificationOnly(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                            </select>                                     
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>       
                        <tr>
                        <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <table class="table table-sm caption-top text-left" style={{backgroundColor:companyData?.PagesBGColor}}>  
                                    <tr>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <a onClick={() => setShowEvaluationList(true)}>
                                                    <label style={styles.LabelDataEntryNoPadding}>
                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Competency/Skill List'>mystery</span>                                    
                                                        Evaluation
                                                    </label>
                                                </a>
                                                <input id="evalid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeEvaluationNames}/>                                    
                                            </div>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <a onClick={() => setShowReminderList(true)}>
                                                    <label style={styles.LabelDataEntryNoPadding}>
                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Competency/Skill List'>mystery</span>                                    
                                                        Reminder
                                                    </label>
                                                </a>
                                                <input id="reminderid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeReminderNames}/>                                    
                                            </div>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <label htmlFor="papercert" style={styles.LabelDataEntryNoPaddingWBG}>Paper Certificate</label>
                                                <select id="papercert" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgePaperCertificates} onChange={(e) => (setKnowledgePaperCertificates(e.target.value))}>
                                                    <option value="-1">Select One</option>
                                                    {paperCertificateData?.map((oItem, iIndex) => (
                                                        <option id={oItem?.ReportID} value={oItem?.ReportID}>{oItem.ReportName}</option>        
                                                    ))}
                                                </select>                                     
                                            </div>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <label htmlFor="vendor" style={styles.LabelDataEntryNoPaddingWBG}>Vendor</label>
                                                <select id="vendor" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeVendor} onChange={(e) => (setKnowledgeVendor(e.target.value))}>
                                                    <option value="-1">Select One</option>
                                                    {vendorData?.map((oItem, iIndex) => (
                                                        <option id={oItem?.VendorID} value={oItem?.VendorID}>{oItem.VendorName}</option>        
                                                    ))}
                                                </select>                                     
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
            );
    }

    function displayWebBased() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="onlinetype" style={styles.LabelDataEntryNoPadding}>Online Type</label>
                                <select id="onlinetype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeOnlineType} onChange={(e) => (setKnowledgeOnlineType(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    {wbtTypeData?.map((oItem, iIndex) => (
                                        <option id={oItem?.WBTTypeID} value={oItem?.WBTTypeID}>{oItem.WBTTypeName}</option>        
                                    ))}
                                </select>                                     
                            </div>
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                <a onClick={() => setShowContentList(true)}>
                                    <label style={styles.LabelDataEntryNoPadding}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Content List'>mystery</span>                                    
                                        Content
                                    </label>
                                </a>
                                <input id="scormid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeSCORMNames}/>                                    
                            </div>
                        </td>
                        <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="onlineURL" style={styles.LabelDataEntryNoPadding}>Online URL</label>
                                <input id="onlineURL" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeOnlineURL} onChange={(e) => (setKnowledgeOnlineURL(e.target.value))}/>
                            </div>                    
                        </td>
                    </tr>
                    <tr>    
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="newwindowlaunch" style={styles.LabelDataEntryNoPadding}>New Window Launch</label>
                                <select id="newwindowlaunch" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeNewWindowLaunch} onChange={(e) => (setKnowledgeNewWindowLaunch(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </div>
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="newwindowtext" style={styles.LabelDataEntryNoPadding}>New Window Launch Text</label>
                                <input id="newwindowtext" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeNewWindowLaunchText} onChange={(e) => (setKnowledgeNewWindowLaunchText(e.target.value))}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="scormreset" style={styles.LabelDataEntryNoPadding}>SCORM Reset</label>
                                <select id="scormreset" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeSCORMReset} onChange={(e) => (setKnowledgeSCORMReset(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </div>
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="completedscorm" style={styles.LabelDataEntryNoPadding}>Save Completed SCORM</label>
                                <select id="completedscorm" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeCompletedSCORM} onChange={(e) => (setKnowledgeCompletedSCORM(e.target.value))}>
                                    <option value="-1">Select One</option>
                                    <option value='1'>Yes</option>        
                                    <option value='0'>No</option>        
                                </select>                                     
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div>
                                <label htmlFor="acktext" style={styles.LabelDataEntryNoPadding}>Acknowledgement Text</label>
                                <input id="acktext" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeAcknowledgementText} onChange={(e) => (setKnowledgeAcknowledgementText(e.target.value))}/>
                            </div>                    
                        </td>
                    </tr>              
                </tbody>
            </table>                                   
        );
}

function displayCategories() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', width:'34%', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="technology" style={styles.LabelDataEntryNoPadding}>Technology</label>
                            <select id="technology" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={knowledgeTechnology?.split(',')} onChange={(e) => (handleCategoriesClick(e, 1))}>
                                {technologyTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.LearningObjectTechnologyTypeID} value={oItem?.LearningObjectTechnologyTypeID}>{oItem.LearningObjectTechnologyTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="sponsors" style={styles.LabelDataEntryNoPadding}>Sponsors</label>
                            <select id="sponsors" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={knowledgeSponsors?.split(',')} onChange={(e) => (handleCategoriesClick(e, 2))}>
                                {sponsorData?.map((oItem, iIndex) => (
                                    <option id={oItem?.SponsorID} value={oItem?.SponsorID}>{oItem.SponsorName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="category" style={styles.LabelDataEntryNoPadding}>Category</label>
                            <select id="category" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100WBG} value={knowledgeCategory} onChange={(e) => (handleCategoriesClick(e, 3))}>
                            <option value="-1">Select One</option>
                            {categoryTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.LearningObjectCategoryTypeID} value={oItem?.LearningObjectCategoryTypeID}>{oItem.LearningObjectCategoryTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign:'left', width:'34%', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="basedtypes" style={styles.LabelDataEntryNoPadding}>Knowledge Based Types</label>
                            <select id="basedtypes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={knowledgeBasedTypes?.split(',')} onChange={(e) => (handleCategoriesClick(e, 4))}>
                                {subCategoryTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.LearningObjectSubCategoryTypeID} value={oItem?.LearningObjectSubCategoryTypeID}>{oItem.LearningObjectSubCategoryTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="topics" style={styles.LabelDataEntryNoPadding}>Topics</label>
                            <select id="topics" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={knowledgeTopics?.split(',')} onChange={(e) => (handleCategoriesClick(e, 5))}>
                                {topicTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.TopicID} value={oItem?.TopicID}>{oItem.TopicName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="cebrokerlic" style={styles.LabelDataEntryNoPadding}>CE Broker License</label>
                            <select id="cebrokerlic" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={knowledgeCEBrokerLicenses?.split(',')} onChange={(e) => (handleCategoriesClick(e, 6))}>
                                {ceBrokerTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.CEBrokerLicenseTypeID} value={oItem?.CEBrokerLicenseTypeID}>{oItem.CEBrokerLicenseTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="cebrokerpub" style={styles.LabelDataEntryNoPadding}>CE Broker Publishing Number</label>
                            <input id="cebrokerpub" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeCEBrokerPublishingNumber} onChange={(e) => (setKnowledgeCEBrokerPublishingNumber(e.target.value))}/>
                        </div>                    
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div>
                            <label htmlFor="cebrokerrep" style={styles.LabelDataEntryNoPadding}>CE Broker Reporting Number</label>
                            <input id="cebrokerrep" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeCEBrokerReportingNumber} onChange={(e) => (setKnowledgeCEBrokerReportingNumber(e.target.value))}/>
                        </div>                    
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="trainingfacility" style={styles.LabelDataEntryNoPadding}>Training Facility</label>
                            <select id="trainingfacility" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeTrainingFacility} onChange={(e) => (setKnowledgeTrainingFacility(e.target.value))}>
                                <option value="-1">Select One</option>
                                {trainingVendorTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.TrainingVendorID} value={oItem?.TrainingVendorID}>{oItem.TrainingVendorName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayOutline() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{height:'300px', overflowY: 'auto'}}>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                readOnly={false}
                            />
                        </div>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayAssessment() {
    return (
        <>
        <table class="table table-sm caption-top text-left">  
            <caption>Pre Assessment</caption>
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => setShowPreAssessmentList(true)}>
                                <label style={styles.LabelDataEntryNoPadding}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Assessment List'>mystery</span>                                    
                                    Assessment
                                </label>
                                <input id="preassid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgePreAssessmentNames}/>                                    
                            </a>
                        </div>
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <label htmlFor="frocepret" style={styles.LabelDataEntryNoPaddingWBG}>Force PreTest</label>
                        <select id="forcepret" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeForcePreTest} onChange={(e) => (setKnowledgeForcePreTest(e.target.value))}>
                            <option value="-1">Select One</option>
                            <option value='1'>Yes</option>        
                            <option value='0'>No</option>        
                        </select>                                     
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <label htmlFor="pretestps" style={styles.LabelDataEntryNoPadding}>Passing Score</label>
                        <input id="pretestps" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={knowledgePreTestPassingScore} onChange={(e) => (setKnowledgePreTestPassingScore(e.target.value))}/>
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <label htmlFor="frocepretc" style={styles.LabelDataEntryNoPaddingWBG}>Force Complete</label>
                        <select id="forcepretc" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeForcePreTestComplete} onChange={(e) => (setKnowledgeForcePreTestComplete(e.target.value))}>
                            <option value="-1">Select One</option>
                            <option value='1'>Yes</option>        
                            <option value='0'>No</option>        
                        </select>                                     
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <label htmlFor="pretestcps" style={styles.LabelDataEntryNoPadding}>Completion Passing Score</label>
                        <input id="pretestcps" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={knowledgePreTestCompletionPassingScore} onChange={(e) => (setKnowledgePreTestCompletionPassingScore(e.target.value))}/>
                    </td>
                </tr> 
            </tbody>
        </table>                                   
        <table class="table table-sm caption-top text-left">  
            <caption>Post Assessment</caption>
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => setShowPostAssessmentList(true)}>
                                <label style={styles.LabelDataEntryNoPadding}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Assessment List'>mystery</span>                                    
                                    Assessment
                                </label>
                            </a>
                            <input id="postassid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgePostAssessmentNames}/>                                    
                        </div>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    </>
    );
}

function displayAttachment() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <Attachment isAttachmentVisible={isAttachmentSectionVisible} isAttachmentDisable={isAttachmentSectionDisabled} iAttachmentTypeID={AttachmentType.Course} iEntityRowTypeID={EntityRowType.Course} iEntityID={knowledgeID}/>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayAdvanced() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
            <tr>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => setShowKnowledgePrerequisitesList(true)}>
                                <label style={styles.LabelDataEntryNoPadding}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Prerequisites List'>mystery</span>                                    
                                    Prerequisites
                                </label>
                            </a>
                            {displayAssignedPrerequisites()}                                    
                        </div>
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => setShowKnowledgeSameAsList(true)}>
                                <label style={styles.LabelDataEntryNoPadding}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Same As'>mystery</span>                                    
                                    Same As 
                                </label>
                            </a>
                            {displayAssignedSameAs()}                                    
                        </div>
                    </td>
                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => setShowSkillPreceptorList(true)}>
                                <label style={styles.LabelDataEntryNoPadding}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Ownership List'>mystery</span>                                    
                                    Ownership
                                </label>
                            </a>
                            {displayAssignedSkillPreceptors()}
                        </div>
                    </td>
                </tr>                
                <tr>
                    <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        {displayNotificationMessages()}
                    </td>
                </tr>                
                <tr>
                    <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        {displayObjectives()}
                    </td>
                </tr>                
                <tr>
                    <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        {displayAccreditations()}
                    </td>
                </tr>                
                <tr>
                    <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                        {displayPromotions()}
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayNotificationMessages() {
    return (
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <Card.Header>
                <Card.Title>
                    <table class="table table-sm text-left">  
                        <tbody>
                            <tr>
                                <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                    Notification Message
                                </td>
                                {(permissionFlags.CanCreate) ? 
                                    (addNewKnowledgeNotificationMessage) ? 
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(2, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Notification Messages'>shadow_minus</span>
                                        </a>
                                    </td>
                                    :
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(2, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Notification Messages'>note_add</span>
                                        </a>
                                    </td>
                                : 
                                    <></>
                            }
                            </tr>
                        </tbody>
                    </table>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {(addNewKnowledgeNotificationMessage) ? 
                    <table class="table table-sm caption-top text-left">  
                        <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="processtype" style={styles.LabelDataEntryNoPaddingRequired}>Process Type</label>
                                    <select id="processtype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeProcessStatusTypeID} onChange={(e) => (updateSubChildrenSelect(1, e.target.selectedOptions))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Pending</option>        
                                        <option value='2'>Enrolled</option>        
                                        <option value='3'>Class Session Complete</option>        
                                        <option value='4'>Complete</option>        
                                        <option value='6'>No Show</option>        
                                        <option value='5'>Cancelled By User</option>        
                                        <option value='10'>Cancelled By Authorizer</option>        
                                        <option value='11'>Cancelled By Administrator</option>        
                                        <option value='12'>Denied By Authorizer</option>        
                                        <option value='13'>Denied By Administrator</option>        
                                        <option value='14'>Completed(Failed)</option>        
                                        <option value='15'>Employment Terminated</option>        
                                        <option value='16'>Waitlisted</option>        
                                        <option value='17'>Course Expired</option>        
                                        <option value='18'>Prerequisite not completed</option>        
                                        <option value='19'>Moved to another instance</option>        
                                        <option value='20'>ECard Issued</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => setShowNotificationMessageList(true)}>
                                        <label style={styles.LabelDataEntryNoPaddingRequired}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Notification Message List'>mystery</span>                                    
                                            Notifications
                                        </label>
                                    </a>
                                    <input id="notimsg" type="text" required disabled style={styles.TextDataEntryNoPadding} value={knowledgeNotificationMessageNames}/>                                    
                                </td>
                                <td style={{textAlign:'center', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                    </div>
                                    <div>
                                        <a onClick={() => handleAddSubChildrenClick(2)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Notification Message'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                : 
                    <></>
                }
                {((knowledgeNotificationMessageArray) && (knowledgeNotificationMessageArray.length > 0)) ? 
                    <table class="table table-sm caption-top text-left">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextMain}>Process Type</th>
                                <th style={styles.tableText}>Notification Message</th>
                                <th style={styles.tableText}>Delete</th>
                            </tr>
                        </thead>                            
                        <tbody>
                            {(knowledgeNotificationMessageArray && knowledgeNotificationMessageArray.map((oItem, index) => {
                                return (<tr>
                                    <td style={styles.tableText}>{index+1}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[3]}</td>
                                    <td style={styles.tableText}>
                                        <a onClick={() => handleDeleteSubChildren(2, oItem)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Notification Message'>delete</span>
                                        </a>
                                    </td>
                                </tr>)
                            }))
                            }
                        </tbody>
                    </table>
                :
                    <></>
                }
            </Card.Body>
        </Card>
    );
}

function displayObjectives() {
    return (
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <Card.Header>
                <Card.Title>
                    <table class="table table-sm text-left">  
                        <tbody>
                            <tr>
                                <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                    Objectives
                                </td>
                                {(permissionFlags.CanCreate) ? 
                                    (addNewKnowledgeObjective) ? 
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(3, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Objective'>shadow_minus</span>
                                        </a>
                                    </td>
                                    :
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(3, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Objective'>note_add</span>
                                        </a>
                                    </td>
                                : 
                                    <></>
                            }
                            </tr>
                        </tbody>
                    </table>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {(addNewKnowledgeObjective) ? 
                    <table class="table table-sm caption-top text-left">  
                        <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => setShowCompetencyList(true)}>
                                        <label style={styles.LabelDataEntryNoPaddingRequired}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Competency List'>mystery</span>                                    
                                            Competencies/Skills
                                        </label>
                                    </a>
                                    <input id="competencyname" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeObjectiveNames}/>                                    
                                </td>
                                <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="comporder" style={styles.LabelDataEntryNoPaddingRequired}>Order</label>
                                    <input id="comporder" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={knowledgeObjectiveDisplayOrder} onChange={(e) => (setKnowledgeObjectiveDisplayOrder(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="compduration" style={styles.LabelDataEntryNoPaddingRequired}>Duration</label>
                                    <input id="compduration" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={knowledgeObjectiveDuration} onChange={(e) => (setKnowledgeObjectiveDuration(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="compobj" style={styles.LabelDataEntryNoPadding}>Objective</label>
                                    <input id="compobj" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeObjective} onChange={(e) => (setKnowledgeObjective(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="compreqapp" style={styles.LabelDataEntryNoPadding}>Requires Approval</label>
                                    <select id="compreqapp" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeObjectiveRequiresApproval} onChange={(e) => (setKnowledgeObjectiveRequiresApproval(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="compreqcomp" style={styles.LabelDataEntryNoPadding}>Requires Completion</label>
                                    <select id="compreqcomp" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeObjectiveRequiresCompletion} onChange={(e) => (setKnowledgeObjectiveRequiresCompletion(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="conlineURL" style={styles.LabelDataEntryNoPadding}>Online URL</label>
                                    <input id="conlineURL" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeObjectiveOnlineURL} onChange={(e) => (setKnowledgeObjectiveOnlineURL(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="conlinetype" style={styles.LabelDataEntryNoPadding}>Online Type</label>
                                    <select id="conlinetype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeObjectiveOnlineType} onChange={(e) => (setKnowledgeObjectiveOnlineType(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        {wbtTypeData?.map((oItem, iIndex) => (
                                            <option id={oItem?.WBTTypeID} value={oItem?.WBTTypeID}>{oItem.WBTTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'center', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                    </div>
                                    <div>
                                        <a onClick={() => handleAddSubChildrenClick(3)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Objective'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                : 
                    <></>
                }
                {((knowledgeObjectiveArray) && (knowledgeObjectiveArray.length > 0)) ? 
                    <table class="table table-sm caption-top text-left">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextMain}>Process Type</th>
                                <th style={styles.tableText}>Notification Message</th>
                                <th style={styles.tableText}>Delete</th>
                            </tr>
                        </thead>                            
                        <tbody>
                            {(knowledgeObjectiveArray && knowledgeObjectiveArray.map((oItem, index) => {
                                return (<tr>
                                    <td style={styles.tableText}>{index+1}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[3]}</td>
                                    <td style={styles.tableText}>
                                        <a onClick={() => handleDeleteSubChildren(3, oItem)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Objective'>delete</span>
                                        </a>
                                    </td>
                                </tr>)
                            }))
                            }
                        </tbody>
                    </table>
                :
                    <></>
                }
            </Card.Body>
        </Card>
    );
}

function displayAccreditations() {
    return (
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <Card.Header>
                <Card.Title>
                    <table class="table table-sm text-left">  
                        <tbody>
                            <tr>
                                <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                    Accreditations
                                </td>
                                {(permissionFlags.CanCreate) ? 
                                    (addNewKnowledgeAccreditation) ? 
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(4, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Accreditation'>shadow_minus</span>
                                        </a>
                                    </td>
                                    :
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(4, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Accreditation'>note_add</span>
                                        </a>
                                    </td>
                                : 
                                    <></>
                            }
                            </tr>
                        </tbody>
                    </table>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {(addNewKnowledgeAccreditation) ? 
                    <table class="table table-sm caption-top text-left">  
                        <tbody>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="acctype" style={styles.LabelDataEntryNoPaddingRequired}>Accreditation Type</label>
                                    <select id="acctype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationID} onChange={(e) => (updateSubChildrenSelect(2, e.target.selectedOptions))}>
                                        <option value="-1">Select One</option>
                                        {accreditationData?.map((oItem, iIndex) => (
                                            <option id={oItem?.AccreditationID} value={oItem?.AccreditationID}>{oItem.AccreditationName}</option>        
                                        ))}
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="accname" style={styles.LabelDataEntryNoPaddingRequired}>Accreditation Number</label>
                                    <input id="accname" type="text" style={styles.TextDataEntryNoPadding} value={knowledgeAccreditationNumber} onChange={(e) => (setKnowledgeAccreditationNumber(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="acccomments" style={styles.LabelDataEntryNoPadding}>Show Comments</label>
                                    <select id="acccomments" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationShowComments} onChange={(e) => (setKnowledgeAccreditationShowComments(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="releasestart" style={styles.LabelDataEntryNoPadding}>Release Date</label>
                                        <input id="releasestart" type="date" style={styles.TextDataEntryNoPadding} value={knowledgeAccreditationReleaseDate?.substr(0,10)} onChange={(e) => (setKnowledgeAccreditationReleaseDate(e.target.value))}/>
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="effectivestart" style={styles.LabelDataEntryNoPadding}>Expiration Date</label>
                                        <input id="effectivestart" type="date" style={styles.TextDataEntryNoPadding} value={knowledgeAccreditationExpirationDate?.substr(0,10)} onChange={(e) => (setKnowledgeAccreditationExpirationDate(e.target.value))}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowAccreditationPostAssessmentList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Assessment List'>mystery</span>                                    
                                                Assessment
                                            </label>
                                        </a>
                                        <input id="accpostassid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeAccreditationPostAssessmentNames}/>                                    
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="accpassingscore" style={styles.LabelDataEntryNoPaddingWBG}>Passing Score</label>
                                    <input id="accpassingscore" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationPassingScore} onChange={(e) => (setKnowledgeAccreditationPassingScore(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="acccreditunits" style={styles.LabelDataEntryNoPaddingWBG}>Credit Units</label>
                                    <input id="acccreditunits" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationCreditUnits} onChange={(e) => (setKnowledgeAccreditationCreditUnits(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="acccredithours" style={styles.LabelDataEntryNoPaddingWBG}>Credit Hours</label>
                                    <input id="acccredithours" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationCreditHours} onChange={(e) => (setKnowledgeAccreditationCreditHours(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="acccreditothers" style={styles.LabelDataEntryNoPaddingWBG}>Credit Others</label>
                                    <input id="acccreditothers" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationCreditOthers} onChange={(e) => (setKnowledgeAccreditationCreditOthers(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowAccreditationContentList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Content List'>mystery</span>                                    
                                                Content
                                            </label>
                                        </a>
                                        <input id="accscormid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeAccreditationSCORMNames}/>                                    
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="useallQ" style={styles.LabelDataEntryNoPaddingWBG}>Enforce Attempt Logic</label>
                                    <select id="useallQ" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationEnforceAttemptsLogic} onChange={(e) => (setKnowledgeAccreditationEnforceAttemptsLogic(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="accretake" style={styles.LabelDataEntryNoPaddingWBG}>Retake Attempts</label>
                                    <input id="accretake" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationRetakeAttempts} onChange={(e) => (setKnowledgeAccreditationRetakeAttempts(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="accdaysretake" style={styles.LabelDataEntryNoPaddingWBG}>Days Before Retake</label>
                                    <input id="accdaysretake" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationDaysBeforeRetake} onChange={(e) => (setKnowledgeAccreditationDaysBeforeRetake(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowAccreditationEvaluationList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Evaluation List'>mystery</span>                                    
                                                Evaluation
                                            </label>
                                        </a>
                                        <input id="accevalid" type="text" disabled style={styles.TextDataEntryNoPadding} value={knowledgeAccreditationEvaluationNames}/>                                    
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="papercert" style={styles.LabelDataEntryNoPaddingWBG}>Paper Certificate</label>
                                        <select id="papercert" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgeAccreditationPaperCertificates} onChange={(e) => (updateSubChildrenSelect(3, e.target.selectedOptions))}>
                                            <option value="-1">Select One</option>
                                            {paperCertificateData?.map((oItem, iIndex) => (
                                                <option id={oItem?.ReportID} value={oItem?.ReportID}>{oItem.ReportName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td colSpan='2' style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                    </div>
                                    <div>
                                        <a onClick={() => handleAddSubChildrenClick(4)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Accreditation'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                : 
                    <></>
                }
                {((knowledgeAccreditationArray) && (knowledgeAccreditationArray.length > 0)) ? 
                    <table class="table table-sm caption-top text-left">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextMain}>Accreditation</th>
                                <th style={styles.tableText}>Number</th>
                                <th style={styles.tableTextMain}>Assessment</th>
                                <th style={styles.tableTextMain}>Evaluation</th>
                                <th style={styles.tableTextMain}>Content</th>
                                <th style={styles.tableText}>Action</th>
                            </tr>
                        </thead>                            
                        <tbody>
                            {(knowledgeAccreditationArray && knowledgeAccreditationArray.map((oItem, index) => {
                                return (<tr>
                                    <td style={styles.tableText}>{index+1}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[14]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[16]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[18]}</td>
                                    <td style={styles.tableText}>
                                        <a onClick={() => handleDeleteSubChildren(4, oItem)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Accreditation'>delete</span>
                                        </a>
                                    </td>
                                </tr>)
                            }))
                            }
                        </tbody>
                    </table>
                :
                    <></>
                }
            </Card.Body>
        </Card>
    );
}

function displayPromotions() {
    return (
        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
            <Card.Header>
                <Card.Title>
                    <table class="table table-sm text-left">  
                        <tbody>
                            <tr>
                                <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                    Promotions
                                </td>
                                {(permissionFlags.CanCreate) ? 
                                    (addNewKnowledgePromotion) ? 
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(5, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Promotion'>shadow_minus</span>
                                        </a>
                                    </td>
                                    :
                                    <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => handleAddChildernClick(5, null)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Promotion'>note_add</span>
                                        </a>
                                    </td>
                                : 
                                    <></>
                            }
                            </tr>
                        </tbody>
                    </table>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {(addNewKnowledgePromotion) ? 
                    <table class="table table-sm caption-top text-left">  
                        <tbody>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="promotionname" style={styles.LabelDataEntryNoPaddingRequired}>Name</label>
                                    <input id="promotionname" type="text" style={styles.TextDataEntryNoPadding} value={knowledgePromotionName} onChange={(e) => (setKnowledgePromotionName(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="promotioncode" style={styles.LabelDataEntryNoPaddingRequired}>Code</label>
                                    <input id="promotioncode" type="text" style={styles.TextDataEntryNoPadding} value={knowledgePromotionCode} onChange={(e) => (setKnowledgePromotionCode(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="promotionacode" style={styles.LabelDataEntryNoPaddingRequired}>Access Code</label>
                                    <input id="promotionacode" type="text" style={styles.TextDataEntryNoPadding} value={knowledgePromotionAccessCode} onChange={(e) => (setKnowledgePromotionAccessCode(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="promstartstart" style={styles.LabelDataEntryNoPadding}>Start Date</label>
                                        <input id="promstartstart" type="date" style={styles.TextDataEntryNoPadding} value={knowledgePromotionStartDate?.substr(0,10)} onChange={(e) => (setKnowledgePromotionStartDate(e.target.value))}/>
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="promreleasestart" style={styles.LabelDataEntryNoPadding}>Expiration Date</label>
                                        <input id="promreleasestart" type="date" style={styles.TextDataEntryNoPadding} value={knowledgePromotionExpirationDate?.substr(0,10)} onChange={(e) => (setKnowledgePromotionExpirationDate(e.target.value))}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="emptypes" style={styles.LabelDataEntryNoPadding}>Employment Types</label>
                                        <select id="emptypes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} defaultValue={knowledgePromotionEmploymentTypes?.split(',')} onChange={(e) => (updateSubChildrenSelect(4, e.target.selectedOptions))}>
                                            {employmentTypeData?.map((rating, supIndex) => (
                                                <option id={rating?.EmploymentTypeID} value={rating?.EmploymentTypeID}>{rating.EmploymentTypeDescription}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="promautoapply" style={styles.LabelDataEntryNoPadding}>Auto Apply</label>
                                    <select id="promoautoapply" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgePromotionAutoApply} onChange={(e) => (setKnowledgePromotionAutoApply(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="discounttype" style={styles.LabelDataEntryNoPadding}>Discount Type</label>
                                    <select id="discounttype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={knowledgePromotionDiscountType} onChange={(e) => (updateSubChildrenSelect(5, e.target.selectedOptions))}>
                                        <option value="-1">Select One</option>
                                        {discountTypeData?.map((rating, supIndex) => (
                                                <option id={rating?.DiscountTypeID} value={rating?.DiscountTypeID}>{rating.DiscountTypeName}</option>        
                                            ))}
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="promotionprice" style={styles.LabelDataEntryNoPadding}>Price</label>
                                    <input id="promotionprice" type="number" style={styles.TextDataEntryNoPadding} value={knowledgePromotionPrice} onChange={(e) => (setKnowledgePromotionPrice(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                    </div>
                                    <div>
                                        <a onClick={() => handleAddSubChildrenClick(5)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Promotion'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                : 
                    <></>
                }
                {((knowledgePromotionArray) && (knowledgePromotionArray.length > 0)) ? 
                    <table class="table table-sm caption-top text-left">  
                        <thead>
                            <tr>
                                <th style={styles.tableText}>#</th>
                                <th style={styles.tableTextMain}>Name</th>
                                <th style={styles.tableText}>Code</th>
                                <th style={styles.tableText}>Access Code</th>
                                <th style={styles.tableText}>Discount Type</th>
                                <th style={styles.tableText}>Action</th>
                            </tr>
                        </thead>                            
                        <tbody>
                            {(knowledgePromotionArray && knowledgePromotionArray.map((oItem, index) => {
                                return (<tr>
                                    <td style={styles.tableText}>{index+1}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[0]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                    <td style={styles.tableText}>{oItem.split('|')[6]}</td>
                                    <td style={styles.tableText}>
                                        <a onClick={() => handleDeleteSubChildren(5, oItem)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Promotion'>delete</span>
                                        </a>
                                    </td>
                                </tr>)
                            }))
                            }
                        </tbody>
                    </table>
                :
                    <></>
                }
            </Card.Body>
        </Card>
    );
}

function displayCatalog() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                <Card.Title>
                                    <div>
                                        <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h4 style={styles.SubHeader}>Catalog Tree</h4></td>
                                                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                    <div className='input-field'>
                                                        <input id="searchLearningObjectTreeNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchLearningObjectTreeNodeName} placeholder='Search Catalog Node'/>
                                                    </div>                                            
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>                            
                                    </div>
                                </Card.Title>
                            </Card.Header>                   
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                <Tree 
                                                    width='100%'
                                                    data={orgLearningObjectTree} 
                                                    openByDefault={false}
                                                    indent={40}
                                                    padding={10}
                                                    searchTerm={learningObjectTreeNodeName}
                                                    searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                    onSelect={(nodes) => {saveLearningObjectTreeSelectedNodes(nodes)}}         
                                                    disableEdit={false}                                    
                                                    disableDrag={false}                                    
                                                    disableDrop={false}                                    
                                                    disableMultiSelection={false}              
                                                    selection={knowledgeTreeNodes}                      
                                                >
                                                </Tree>
                                            </td>
                                            <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                    padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                {displaySelectedLearningObjectTree()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>                            
                            </Card.Body>
                            <Card.Footer className="text-left">
                            </Card.Footer>
                        </Card>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayOrganization() {
    return (
        <table class="table table-sm caption-top text-left">  
            <tbody> 
                <tr>
                    <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                <Card.Title>
                                    <div>
                                        <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h4 style={styles.SubHeader}>Organization Tree</h4></td>
                                                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                    <div className='input-field'>
                                                        <input id="searchDivisionNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchDivisionNodeName} placeholder='Search Division'/>
                                                    </div>                                            
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>                            
                                    </div>
                                </Card.Title>
                            </Card.Header>                   
                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                <Tree 
                                                    width='100%'
                                                    data={orgDivisions} 
                                                    openByDefault={false}
                                                    indent={40}
                                                    padding={10}
                                                    searchTerm={divisionNodeName}
                                                    searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                    onSelect={(nodes) => {treeSelectedNodes(nodes)}}         
                                                    disableEdit={false}                                    
                                                    disableDrag={false}                                    
                                                    disableDrop={false}                                    
                                                    disableMultiSelection={false}              
                                                    selection={knowledgeDivisions}                      
                                                >
                                                    {/* {Node} */}
                                                </Tree>
                                            </td>
                                            <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    {displaySelectedDivisions()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>                            
                            </Card.Body>
                            <Card.Footer className="text-left">
                            </Card.Footer>
                        </Card>
                    </td>
                </tr>
            </tbody>
        </table>                                   
    );
}

function displaySelectedDivisions() {
    const knowledgeDivisionArray = knowledgeDivisionDetails?.split('&'); 
    if (knowledgeDivisionArray) {
    return (
            <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
            <thead>
                <tr>
                    <th style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Division(s)</h5></th>
                </tr>
            </thead>
            <tbody>
                {knowledgeDivisionArray.map((node, index) => {
                    return (
                    <tr>
                        {/* <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.data.name}</h6></td> */}
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]}</h6></td>
                    </tr>
                    )
                })}
            </tbody>
        </Table>                                                    
        );
    }
}

function displaySelectedLearningObjectTree() {
    const knowledgeLearningObjectTreeArray = knowledgeTreeNodeDetails?.split('&'); 
    if (knowledgeLearningObjectTreeArray) {
    return (
            <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
            <thead>
                <tr>
                    <th colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Tree Node(s)</h5></th>
                </tr>
            </thead>
            <tbody>
                {knowledgeLearningObjectTreeArray.map((node, index) => {
                    return (
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]}</h6></td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <a onClick={() => handleDeleteAssignedTreeNodeClick(node)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Tree Node'>delete</span>
                            </a>
                        </td>
                    </tr>
                    )
                })}
            </tbody>
        </Table>                                                    
        );
    }
}

function getSearchParamsEx(bRadioValue, bRadioTypeValue, pageNumber)  {
    let oParams = {
        LearningObjectTypeID:bRadioTypeValue,
        LearningObjectName:nodeName,
        IsCourseActive:(bRadioValue === '1') ? 'true' : 'false',
        RecordSize:'10',
        PageNumber:pageNumber,
    }; 
    return oParams;
}

async function searchKnowledgeTracks(bRadioValue, bRadioTypeValue) {
    setLoading(true);
    const oParams = getSearchParamsEx(bRadioValue, bRadioTypeValue, '1');
    await props.getKnowledges(oParams);
    setLoading(false);
}


async function handleRadioChange(e) {
    // currentRecIndex = 0; 
    setRadioValue(e.currentTarget.value);
    searchKnowledgeTracks(e.currentTarget.value, radioTypeValue);
}

async function handleRadioTypeChange(e) {
    // currentRecIndex = 0; 
    setRadioTypeValue(e.currentTarget.value);
    // if (e.currentTarget.value === '1') {
    //     setlistTypeDescription('Knowledges');
    // } else if (e.currentTarget.value === '2') {
    //     setlistTypeDescription('Competencies');
    // } else if (e.currentTarget.value === '3') {
    //     setlistTypeDescription('Users');
    // } else {
    //     setlistTypeDescription('User Competencies');
    // } 
    searchKnowledgeTracks(radioValue, e.currentTarget.value);
}

function displayListRadioButtons() {
    return (
        radios.map((radio, idx) => (
            <ToggleButton
                key={'checklisttype' + idx}
                id={`radio-${'checklisttype' + idx}`}
                type="radio"
                style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                size='sm'
                onChange={(e) => handleRadioChange(e)}
            >
                {radio.name}
            </ToggleButton>
        ))
    );
}

function displayGroupRadioButtons() {
    return (
        radioTypes.map((radio, idx) => (
            <ToggleButton
                key={'checklistgroup' + idx}
                id={`radio-${'checklistgroup' + idx}`}
                type="radio"
                style={radioTypeValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                name="radioType"
                value={radio.value}
                checked={radioTypeValue === radio.value}
                size='sm'
                onChange={(e) => handleRadioTypeChange(e)}
            >
                {radio.name}
            </ToggleButton>
        ))
    );
}

function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Knowledge Tracks</h3></td>
                <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}>
                                    <ButtonGroup className="mb-2" size='sm'>
                                        {displayListRadioButtons()}
                                    </ButtonGroup>                                                                   
                                </td>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <ButtonGroup className="mb-2" size='sm'>
                                        {displayGroupRadioButtons()}
                                    </ButtonGroup>                                                                   
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Knowledge Track'/>
                        <a onClick={() => searchKnowledges()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {(permissionFlags.CanCreate) ? 
                            (addNewKnowledge) ? 
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Knowledge'>shadow_minus</span>
                                </a>
                                :
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Knowledge'>note_add</span>
                                </a>
                        : 
                            <></>
                        }
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );    
    }

    function displayAssignedEmployeesDetails(assRec) {
        let sMsg = '';
        if (assRec?.AssignedUsers > 0) {
            sMsg = assRec?.AssignedUsers.toLocaleString() + ' assigned';
        }
        if (assRec?.CompletedUsers > 0) {
            if (sMsg !== '') {
                sMsg += ', ';
            }
            sMsg = assRec?.CompletedUsers.toLocaleString() + ' completed';
        }
        return sMsg;
    }

    function displayKnowledgeTypeName(assRec) {
        if (assRec?.LearningObjectTypeID === 1) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Classroom-Based'>supervised_user_circle</span>
            );
        } else if (assRec?.LearningObjectTypeID === 2) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Online'>touchpad_mouse</span>
            )
        } else if (assRec?.LearningObjectTypeID === 3) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Manual/External'>other_admission</span>
            );
        } else if (assRec?.LearningObjectTypeID === 4) {
            return (
                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Historical'>work_history</span>
            );
        }
    }   
    
    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showEvaluationList} onShow={handleShowEvaluationList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseEvaluationList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EvaluationList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseEvaluationList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showReminderList} onShow={handleShowReminderList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseReminderList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReminderList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseReminderList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showSkillPreceptorList} onShow={handleShowSkillPreceptorList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseSkillPreceptorList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseSkillPreceptorList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showKnowledgePrerequisitesList} onShow={handleShowKnowledgePrerequisitesList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseKnowledgePrerequisitesList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <KnowledgeLookupList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseKnowledgePrerequisitesList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showKnowledgeSameAsList} onShow={handleShowKnowledgeSameAsList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseKnowledgeSameAsList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <KnowledgeLookupList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseKnowledgeSameAsList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showContentList} onShow={handleShowContentList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseContentList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContentList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseContentList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showPostAssessmentList} onShow={handleShowPostAssessmentList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClosePostAssessmentList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TestLibraryList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClosePostAssessmentList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showPreAssessmentList} onShow={handleShowPreAssessmentList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClosePreAssessmentList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TestLibraryList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClosePreAssessmentList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showNotificationMessageList} onShow={handleShowNotificationMessageList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseNotificationMessageList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NotificationMessageList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseNotificationMessageList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showCompetencyList} onShow={handleShowCompetencyList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseCompetencyList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CompetencyList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseCompetencyList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showAccreditationEvaluationList} onShow={handleShowAccreditationEvaluationList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseAccreditationEvaluationList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EvaluationList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseAccreditationEvaluationList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showAccreditationContentList} onShow={handleShowAccreditationContentList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseAccreditationContentList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContentList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseAccreditationContentList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showAccreditationPostAssessmentList} onShow={handleShowAccreditationPostAssessmentList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseAccreditationPostAssessmentList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TestLibraryList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseAccreditationPostAssessmentList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                            <Card.Subtitle>
                            {(showAlertMessage) ?
                                <div>
                                    <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} handleParentAlertClose={handleCloseAlert} /></h5>
                                </div>
                                :
                                    <></>
                                }
                            </Card.Subtitle>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewKnowledge()}                                               
                            {((!knowledge) || (knowledge.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Knowledge Tracks.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableText}>Type</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Number</th>
                                                <th style={styles.tableText}>Employees</th>
                                                <th style={styles.tableText}>Instances</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {knowledge && knowledge.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{assRec?.LearningObjectID}</td>
                                                        <td style={styles.tableText}>{displayKnowledgeTypeName(assRec)}</td>
                                                        <td style={styles.tableTextSubHeader}>{assRec?.LearningObjectName}</td>
                                                        <td style={styles.tableText}>{assRec?.CourseNumber}</td>
                                                        <td style={styles.tableText}>{assRec?.StudentCount}</td>
                                                        <td style={styles.tableText}>{assRec?.SessionCount}</td>
                                                        <td style={styles.tableText}>
                                                            <div>
                                                                {(permissionFlags.CanModify) ? 
                                                                    <>
                                                                    <a onClick={() => handleViewChildernClick(assRec?.LearningObjectID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='View Knowledge Instances'>table_chart_view</span>
                                                                    </a>
                                                                    <a onClick={() => handleUpdateChildernClick(assRec?.LearningObjectID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Knowledge Track'>edit</span>
                                                                    </a>
                                                                    {((permissionFlags.CanDelete) && (assRec?.AssignedUsers === 0) && (assRec?.CompletedUsers === 0)) ? 
                                                                        <a onClick={() => handleDeleteChildernClick(assRec?.LearningObjectID)}>
                                                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Knowledge Track'>delete</span>
                                                                        </a>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    </>
                                                                : 
                                                                    <></>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{knowledges?.knowledges?.RecordFound?.toLocaleString()}   Knowledge Tracks&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(knowledges?.knowledges?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(knowledges?.knowledges?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {knowledges?.knowledges?.PagingMetaData?.PageNumber}  of  {knowledges?.knowledges?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      evaluations: state.entity.evaluations, 
      knowledges: state.entity.knowledges, 
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      orgLearningObjectTree: state.entity.orgLearningObjectTree,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKnowledges: (kParams) => dispatch(getKnowledges(kParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertKnowledge: (oParams) => dispatch(insertKnowledge(oParams)),
        deleteKnowledge: (oParams) => dispatch(deleteKnowledge(oParams)),
        updateKnowledge: (oParams) => dispatch(updateKnowledge(oParams)),
        getReminders: (oParams) => dispatch(getReminders(oParams)),
        getPGEvaluations: (evalParams) => dispatch(getPGEvaluations(evalParams)),
        getKnowledgesLookup: (kParams) => dispatch(getKnowledgesLookup(kParams)),
        getContents: (oParams) => dispatch(getContents(oParams)),
        getTestLibraries: (oParams) => dispatch(getTestLibraries(oParams)),
        getNotificationMessages: (oParams) => dispatch(getNotificationMessages(oParams)),
        getSkills: (oParams) => dispatch(getSkills(oParams)),
        getKnowledgeInstances: (oParams) => dispatch(getKnowledgeInstances(oParams)),
        getAttachments: (oAParams) => dispatch(getAttachments(oAParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeList)