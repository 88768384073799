import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge, ToggleButton, ButtonGroup  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getCertifications, getUserAttributesLookups, insertCertification, updateCertification, deleteCertification, getNotificationMessages, getReminders, 
        getCertificationsLookup, getKnowledgesLookup } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import { UserLookup, Rights, AttachmentType, EntityRowType, WebConferenceType, MenuType, AlertVariant } from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import NotificationMessageList from './NotificationMessageList';
import ReminderList from './ReminderList';
import CertificationLookupList from './CertificationLookupList';
import KnowledgeLookupList from './KnowledgeLookupList';
import AlertMessage from '../layout/AlertMessage';

const CertificationList = (props) => {
    const { auth, companyData, profile, certifications, userattributelookups, orgDivisions, lookupselections, loadingMenu, listby, orgLearningObjectTree } = props;    
    const location  = useLocation();
    const navigate = useNavigate();
    const [permissionTypeID, setPermissionTypeID] = useState(location?.state?.permissiontypeid);
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location?.state?.permissiontypeid));
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [certificationTreeNodeName, setCertificationTreeNodeName] = useState('');
    const [certificationTreeSelectedNodes, setCertificationTreeSelectedNodes] = useState('');
    const [addNewCertification, setAddNewCertification] = useState(false);
    const [editCertification, setEditCertification] = useState(false);
    const [addNewCertificationAuthorization, setAddNewCertificationAuthorization] = useState(false);
    const [certificationID, setCertificationID] = useState(-1);
    const [certificationName, setCertificationName] = useState('');
    const [certificationActive, setCertificationActive] = useState('1');
    const [certificationExpiryType, setCertificationExpiryType] = useState('-1');
    const [certificationExpires, setCertificationExpires] = useState('-1');
    const [certificationAutoRenew, setCertificationAutoRenew] = useState('-1');
    const [certificationEffectiveDate, setCertificationEffectiveDate] = useState(null);
    const [certificationRequireCredits, setCertificationRequireCredits] = useState('-1');
    const [certificationDescription, setCertificationDescription] = useState('');
    const [certificationCancellationCharge, setCertificationCancellationCharge] = useState('');
    const [certificationAssignByOrder, setCertificationAssignByOrder] = useState('-1');
    const [certificationForcePayment, setCertificationForcePayment] = useState('-1');
    const [certificationByPassPrerequisities, setCertificationByPassPrerequisities] = useState('-1');
    const [certificationPrerequisiteExpression, setCertificationPrerequisiteExpression] = useState('');
    const [certificationByPassAuthorization, setCertificationByPassAuthorization] = useState('-1');
    const [certificationPaymentModes, setCertificationPaymentModes] = useState('');
    const [certificationLearningObjects, setCertificationLearningObjects] = useState('');
    const [certificationAuthorizerGroups, setCertificationAuthorizerGroups] = useState('');
    const [certificationDependentCertificationIDs, setCertificationDependentCertificationIDs] = useState('');
    const [certificationPreEnrollmentText, setCertificationPreEnrollmentText] = useState('');
    const [certificationDuration, setCertificationDuration] = useState('');
    const [certificationListPrice, setCertificationListPrice] = useState('');
    const [certificationCreditUnits, setCertificationCreditUnits] = useState('');
    const [certificationCreditHours, setCertificationCreditHours] = useState('');
    const [certificationCreditOthers, setCertificationCreditOthers] = useState('');
    const [certificationPaperCertificates, setCertificationPaperCertificates] = useState('');
    const [certificationPaperCertificateNames, setCertificationPaperCertificateNames] = useState('');    
    const [showReminderList, setShowReminderList] = useState(false);
    const [certificationReminders, setCertificationReminders] = useState('');
    const [certificationReminderNames, setCertificationReminderNames] = useState('');    
    const [certificationTreeNodes, setCertificationTreeNodes] = useState('');    
    const [certificationTreeNodeDetails, setCertificationTreeNodeDetails] = useState('');
    const [certificationTreeNodeArray, setCertificationTreeNodeArray] = useState([]);

    const [addNewCertificationNotificationMessage, setAddNewCertificationNotificationMessage] = useState(false);
    const [certificationProcessStatusTypeID, setCertificationProcessStatusTypeID] = useState(null);
    const [certificationProcessStatusTypeName, setCertificationProcessStatusTypeName] = useState(null);
    const [certificationNotificationMessageArray, setCertificationNotificationMessageArray] = useState([]);
    const [certificationNotificationMessageDetails, setCertificationNotificationMessageDetails] = useState([]);
    const [certificationNotificationMessages, setCertificationNotificationMessages] = useState('');
    const [certificationNotificationMessageNames, setCertificationNotificationMessageNames] = useState('');
    const [showNotificationMessageList, setShowNotificationMessageList] = useState(false);
    const [showAuthorizationNotificationMessageList, setShowAuthorizationNotificationMessageList] = useState(false);

    const [addNewCertificationKnowledgeTrack, setAddNewCertificationKnowledgeTrack] = useState(false);
    const [certificationKnowledgeTrackArray, setCertificationKnowledgeTrackArray] = useState([]);
    const [knowledgeTrackDetails, setKnowledgeTrackDetails] = useState(null);
    const [knowledgeTrackArray, setKnowledgeTrackArray] = useState([]);
    const [knowledgeTracks, setKnowledgeTracks] = useState('');
    const [knowledgeTrackNames, setKnowledgeTrackNames] = useState('');
    const [certificationKnowledgeTrackDetails, setCertificationKnowledgeTrackDetails] = useState('');
    const [certificationKnowledgeTracks, setCertificationKnowledgeTracks] = useState('');
    const [certificationKnowledgeTrackNames, setCertificationKnowledgeTrackNames] = useState('');
    const [certificationKnowledgeTrackPassingScore, setCertificationKnowledgeTrackPassingScore] = useState(null);
    const [certificationKnowledgeTrackDisplayOrder, setCertificationKnowledgeTrackDisplayOrder] = useState(null);
    const [certificationKnowledgeTrackOptional, setCertificationKnowledgeTrackOptional] = useState(null);
    const [certificationKnowledgeTrackType, setCertificationKnowledgeTrackType] = useState(null);
    const [certificationKnowledgeTrackByPassScore, setCertificationKnowledgeTrackByPassScore] = useState(null);
    const [certificationKnowledgeTrackCreateNew, setCertificationKnowledgeTrackCreateNew] = useState(null);
    const [showKnowledgeTrackList, setShowKnowledgeTrackList] = useState(false);

    const [addNewCertificationPromotion, setAddNewCertificationPromotion] = useState(false);
    const [certificationPromotions, setCertificationPromotions] = useState(null);
    const [certificationPromotionArray, setCertificationPromotionArray] = useState([]);
    const [certificationPromotionDetails, setCertificationPromotionDetails] = useState(null);
    const [certificationPromotionDiscountType, setCertificationPromotionDiscountType] = useState(null);
    const [certificationPromotionDiscountTypeName, setCertificationPromotionDiscountTypeName] = useState(null);
    const [certificationPromotionName, setCertificationPromotionName] = useState(null);
    const [certificationPromotionCode, setCertificationPromotionCode] = useState(null);
    const [certificationPromotionAccessCode, setCertificationPromotionAccessCode] = useState(null);
    const [certificationPromotionPrice, setCertificationPromotionPrice] = useState(null);
    const [certificationPromotionAutoApply, setCertificationPromotionAutoApply] = useState(null);
    const [certificationPromotionStartDate, setCertificationPromotionStartDate] = useState(null);
    const [certificationPromotionExpirationDate, setCertificationPromotionExpirationDate] = useState(null);
    const [certificationPromotionEmploymentTypes, setCertificationPromotionEmploymentTypes] = useState(null);
    const [certificationPromotionEmploymentTypeNames, setCertificationPromotionEmploymentTypeNames] = useState(null);

    const [certificationPrerequisitesArray, setCertificationPrerequisitesArray] = useState([]);
    const [certificationPrerequisitesDetails, setCertificationPrerequisitesDetails] = useState('');
    const [certificationPrerequisites, setCertificationPrerequisites] = useState('');
    const [showCertificationPrerequisitesList, setShowCertificationPrerequisitesList] = useState(false);
    const [certificationSameAsArray, setCertificationSameAsArray] = useState([]);
    const [certificationSameAsDetails, setCertificationSameAsDetails] = useState('');
    const [certificationSameAs, setCertificationSameAs] = useState('');    
    const [showCertificationSameAsList, setShowCertificationSameAsList] = useState(false);
    const [certificationSkillPreceptorArray, setCertificationSkillPreceptorArray] = useState([]);
    const [certificationSkillPreceptorDetails, setCertificationSkillPreceptorDetails] = useState('');
    const [certificationSkillPreceptors, setCertificationSkillPreceptors] = useState('');
    const [showSkillPreceptorList, setShowSkillPreceptorList] = useState(false);

    const [authorizationGroup, setAuthorizationGroup] = useState('');    
    const [authorizationGroupName, setAuthorizationGroupName] = useState('');    
    const [authorizationLevel, setAuthorizationLevel] = useState('');    
    const [authorizationNotificationMessageArray, setAuthorizationNotificationMessageArray] = useState([]);
    const [authorizationNotificationMessageDetails, setAuthorizationNotificationMessageDetails] = useState([]);
    const [authorizationNotificationMessages, setAuthorizationNotificationMessages] = useState('');    
    const [authorizationNotificationMessageNames, setAuthorizationNotificationMessageNames] = useState('');    
    const [authorizationDetails, setAuthorizationDetails] = useState('');    
    const [authorizationArray, setAuthorizationArray] = useState([]);    


    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('info');
    const handleCloseAlert = () => {setShowAlertMessage(false); }

    const certification = certifications?.certifications?.Certifications;

    const prerequisite = certifications?.certifications?.CertificationPrerequisites;
    const promotion = certifications?.certifications?.CertificationPromotions;
    const treeNode = certifications?.certifications?.CertificationTreeNodes;
    const paymentMode = certifications?.certifications?.CertificationPaymentModes;
    const authorizerGroups = certifications?.certifications?.CertificationAuthorizerGroups;
    const learningObjects = certifications?.certifications?.CertificationLearningObjects;

    const paperCertificateData = userattributelookups?.PaperCertificates;
    const employmentTypeData = userattributelookups?.EmploymentTypes;
    const discountTypeData = userattributelookups?.DiscountTypes;
    const paymentModeTypeData = userattributelookups?.PaymentModes;
    const certificationExpiryTypeData = userattributelookups?.CertificationExpiryTypes;
    const groupTypeData = userattributelookups?.Groups;

    const radioKnowledege = [
        { name: 'Attributes', value: 1 },
        { name: 'Knowledge Tracks', value: 2 },
        { name: 'Catalog', value: 3 },
        { name: 'Authorization', value: 4 },
        { name: 'Advanced', value: 5 },
    ];

    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }
    
    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Inactive', value: '2' },
        { name: 'All', value: '3' },
    ];

    // const [radioTypeValue, setRadioTypeValue] = useState(initialRadioTypeValue());
    // const radioTypes = [
    //     { name: 'Classroom', value: CertificationType.Classroom },
    //     { name: 'Online', value: CertificationType.Online },
    //     { name: 'Manual', value: CertificationType.Manual },
    //     { name: 'Historical', value: CertificationType.Historical },
    //     { name: 'All', value: CertificationType.All },
    // ];

    function initialRadioValue() {
        return '1';
    };

    // function initialRadioTypeValue() {
    //     return listByValue;
    // };    
   
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = certifications?.certifications?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getCertifications(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = certifications?.certifications?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };           
        await props.getCertifications(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
        
    function initialTabValue() {
        return 1;
    };

    const handleCloseNotificationMessageList = () => {
        setShowNotificationMessageList(false);
        updateNotificationMessageSelection();
    }

    async function handleShowNotificationMessageList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);

        setShowNotificationMessageList(true); 
        setLoading(false);
    };

    function updateNotificationMessageSelection() {
        var sSelections = lookupselections?.NotificationMessage;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setCertificationNotificationMessages(sSelectedIds);
            setCertificationNotificationMessageNames(sSelectedNames);
        }
    }

    const handleCloseAuthorizationNotificationMessageList = () => {
        setShowAuthorizationNotificationMessageList(false);
        updateAuthorizationNotificationMessageSelection();
    }

    async function handleShowAuthorizationNotificationMessageList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getNotificationMessages(oParams);

        setShowAuthorizationNotificationMessageList(true); 
        setLoading(false);
    };

    function updateAuthorizationNotificationMessageSelection() {
        var sSelections = lookupselections?.NotificationMessage;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setAuthorizationNotificationMessages(sSelectedIds);
            setAuthorizationNotificationMessageNames(sSelectedNames);
        }
    }

    const handleCloseReminderList = () => {
        setShowReminderList(false);
        updateReminderSelection();
    }

    async function handleShowReminderList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getReminders(oParams);

        setShowReminderList(true); 
        setLoading(false);
    };

    function updateReminderSelection() {
        var sSelections = lookupselections?.Reminder;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setCertificationReminders(sSelectedIds);
            setCertificationReminderNames(sSelectedNames);
        }
    }

    const handleCloseCertificationPrerequisitesList = () => {        
        setShowCertificationPrerequisitesList(false);
        updatePrerequisitesSelection();
    }

    async function handleShowCertificationPrerequisitesList() {
        setLoading(true);
        const oParams = {
            IsCertificationActive:'true',
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getCertificationsLookup(oParams);

        setShowCertificationPrerequisitesList(true); 
        setLoading(false);
    };

    function updatePrerequisitesSelection() {
        var sSelections = lookupselections?.Certification;
        if (certificationPrerequisitesDetails !== '') {
            sSelections = certificationPrerequisitesDetails + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined)) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setCertificationPrerequisitesDetails(sSelections);
            setCertificationPrerequisitesArray(oSelections);
            setCertificationPrerequisites(sSelectedIds);
        }
    }

    function handleDeleteAssignedPrerequisitesClick(goal) {
        var tempPrerequisites = certificationPrerequisitesArray.slice(0);
        var iRIndex = tempPrerequisites.indexOf(goal);
        if (iRIndex !== -1) {
            tempPrerequisites.splice(iRIndex, 1);
            setCertificationPrerequisitesArray(tempPrerequisites);
            let sSelectedIds = ''
            let sSelectedNames = ''
            let sSelectedDetails = ''
            tempPrerequisites?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                sSelectedDetails += element;
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setCertificationPrerequisites(sSelectedIds);
            setCertificationPrerequisitesDetails(sSelectedNames);
        }        
    }

    const handleCloseKnowledgeTrackList = () => {        
        setShowKnowledgeTrackList(false);
        updateKnowledgeTrackSelection();
    }

    async function handleShowKnowledgeTrackList() {
        setLoading(true);
        const oParams = {
            LearningObjectTypeID:'1',
            IsCourseActive:'true',
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getKnowledgesLookup(oParams);

        setShowKnowledgeTrackList(true); 
        setLoading(false);
    };

    function updateKnowledgeTrackSelection() {
        var sSelections = lookupselections?.Knowledge;
        if ((knowledgeTrackDetails) && (knowledgeTrackDetails !== '')) {
            sSelections = knowledgeTrackDetails + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined)) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element?.split('=')[1].split('|')[0];
                }
            });
            setKnowledgeTrackDetails(sSelections);
            setKnowledgeTrackArray(oSelections);
            setKnowledgeTracks(sSelectedIds);
        }
    }

    function handleDeleteAssignedKnowledgeTrackClick(goal) {
        var tempPrerequisites = knowledgeTrackArray?.slice(0);
        var iRIndex = tempPrerequisites.indexOf(goal);
        if (iRIndex !== -1) {
            tempPrerequisites.splice(iRIndex, 1);
            setKnowledgeTrackArray(tempPrerequisites);
            let sSelectedIds = ''
            let sSelectedNames = ''
            let sSelectedDetails = ''
            tempPrerequisites?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                sSelectedDetails += element;
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
            });
            setKnowledgeTracks(sSelectedIds);
            setKnowledgeTrackDetails(sSelectedNames);
        }        
    }

    function updateSubChildrenSelect(fieldIndex, selected) {
        if (fieldIndex === 1) {
            setCertificationProcessStatusTypeID(selected[0].value);
            setCertificationProcessStatusTypeName(selected[0].text);
        } else if (fieldIndex === 2) {
            setAuthorizationGroup(selected[0].value);
            setAuthorizationGroupName(selected[0].text);
        } else if (fieldIndex === 3) {
        } else if (fieldIndex === 4) {
            let selectedValues = '';
            let selectedNames = '';
            [...selected].map((sSelOption) => {
                if (selectedValues.trim() !== '') {
                    selectedValues += ',';
                }
                if (selectedNames.trim() !== '') {
                    selectedNames += ',';
                }
                selectedValues += sSelOption.value;
                selectedNames += sSelOption.text;
            });
            setCertificationPromotionEmploymentTypes(selectedValues);
            setCertificationPromotionEmploymentTypeNames(selectedNames);
        } else if (fieldIndex === 5) {
            setCertificationPromotionDiscountType(selected[0].value);
            setCertificationPromotionDiscountTypeName(selected[0].text);
        }
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        displayTabContent();
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchCertificationTreeNodeName(e) {
        setCertificationTreeNodeName(e.target.value);
    }

    async function saveCertificationTreeSelectedNodes(nodes) {
        setLoading(true);
        let treeIds = '';
        let treeDetails = '';
        console.log(nodes);
        nodes.forEach(element => {
            if (treeIds.trim() !== '') {
                treeIds += ',';
            }
            if (treeDetails.trim() !== '') {
                treeDetails += '&';
            }
            treeIds += element?.data?.id;
            treeDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (treeIds) {
            setCertificationTreeSelectedNodes(nodes);
            setCertificationTreeNodes(treeIds);
            setCertificationTreeNodeDetails(treeDetails);
            setCertificationTreeNodeArray(treeDetails.split('&'));
        }
        setLoading(false);
    }

    async function searchCertifications() {
        setLoading(true);
        const oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getCertifications(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (certification) => {
        return certification?.map((kRec) => ({
            CertificationID:kRec.CertificationID, 
            takeAction:false,
            showDetails:false,
            editCertificationClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(certification);
        setSliderValue(initialScoreValues);
    }, [certification])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleCategoriesClick(e, type) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });

        if (type === 1) {
            setCertificationPaymentModes(selectedValues);
        } 
    }

    function handleDeleteAssignedTreeNodeClick(goal) {
        var tempSkills = certificationTreeNodeArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element?.split('=')[0];
                sSelectedNames += element?.split('=')[1].split('|')[0];
                sSelectedDetails += element;
            });

            setCertificationTreeNodes(sSelectedIds);
            setCertificationTreeNodeDetails(sSelectedDetails);
            setCertificationTreeNodeArray(sSelectedDetails);
        }        
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewCertification) ? setAddNewCertification(false) : setAddNewCertification(true);
            setCertificationID(-1);
        } else if (tab === 3) {
            (addNewCertificationAuthorization) ? setAddNewCertificationAuthorization(false) : setAddNewCertificationAuthorization(true);
        } else if (tab === 4) {
            (addNewCertificationKnowledgeTrack) ? setAddNewCertificationKnowledgeTrack(false) : setAddNewCertificationKnowledgeTrack(true);
        } else if (tab === 5) {
            (addNewCertificationPromotion) ? setAddNewCertificationPromotion(false) : setAddNewCertificationPromotion(true);
        } 

        setLoading(false);
    }
    
    async function handleSaveChildernClick(tab, currentEval) {
        var bCanSave = true;
        // var bClassroomBased = false;
        // var bWebBased = false;
        // var bOthers = false;
        // if (certificationType === '1') {
        //     bClassroomBased = true;
        // } else if (certificationType === '2') {
        //     bWebBased = true;
        // } else {
        //     bOthers = true;
        // } 
        // if ((bClassroomBased) || (bOthers)) {
        //     if (!((certificationName) && (certificationName.trim !== '') && (certificationActive) && (certificationActive.trim !== -1) && (certificationGradingType) && (certificationGradingType.trim !== -1))) {
        //         setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
        //         setAlertVariant(AlertVariant.Warning);
        //         setShowAlertMessage(true);
        //         bCanSave = false;
        //     }
        // } else if (bWebBased) {
        //     if (!((certificationName) && (certificationName.trim !== '') && (certificationActive) && (certificationActive.trim !== -1) && (certificationGradingType) && (certificationGradingType.trim !== -1)) && 
        //         (certificationOnlineType) && (certificationOnlineType.trim !== -1)) {
        //         setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
        //         setAlertVariant(AlertVariant.Warning);
        //         setShowAlertMessage(true);
        //         bCanSave = false;
        //     }
        // }

        if (bCanSave) {
            setLoading(true);
            if (tab === 1) {
                const oParams  = {
                    CertificationID: certificationID,
                    CertificationName: certificationName,
                    IsDeleted: (certificationActive === '1' ? true : false),
                    Expires: (certificationExpires === '1' ? true : false),
                    AutoRenew: (certificationAutoRenew === '1' ? true : false),
                    ForcePayment: (certificationForcePayment === '1' ? true : false),
                    RequireCredits: (certificationRequireCredits === '1' ? true : false),
                    CertificationDescription: certificationDescription,
                    CertificationExpiryTypeID: certificationExpiryType,
                    ListPrice: certificationListPrice,
                    PreEnrollmentText: certificationPreEnrollmentText,
                    Duration: certificationDuration,
                    CreditUnits: certificationCreditUnits,
                    CreditHours: certificationCreditHours,
                    CreditOthers: certificationCreditOthers,
                    ReminderID: certificationReminders,
                    PaperCertificationID: certificationPaperCertificates,
                    NotificationMessageID: certificationNotificationMessages,
                    EffectiveDate: certificationEffectiveDate,
                    ByPassPrerequisiteChecking: (certificationByPassPrerequisities === '1' ? true : false),
                    PrerequisitesExpression: certificationPrerequisiteExpression,
                    DependentCertificationIDs: certificationDependentCertificationIDs,
                    DisableAuthorization: (certificationByPassAuthorization === '1' ? true : false),
                    CancellationCost: certificationCancellationCharge,
                    PreEnrollmentText: certificationPreEnrollmentText,
                    IsCertificationAssignOrder: (certificationAssignByOrder === '1' ? true : false),                   
                    CertificationPrerequisites: certificationPrerequisites,
                    CertificationLearningObjects: certificationKnowledgeTrackDetails,
                    CertificationTreeNodes: certificationTreeNodes,                   
                    CertificationPaymentModes: certificationPaymentModes,
                    CertificationPromotions: certificationPromotionDetails,
                    CertificationAuthorizerGroups: authorizationDetails,
                    CreatedByUserID: auth?.UserID,
                    UpdatedByUserID: auth?.UserID,
                    CompanyID: auth?.CompanyID,
                };        
                console.log(oParams);
                if (certificationID === -1) {
                    await props.insertCertification(oParams);    
                    setAddNewCertification(false);
                } else {
                    await props.updateCertification(oParams);    
                    setEditCertification(false);
                }
            } 
    
            const oParams = {
                CertificationName:nodeName,
                IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
                RecordSize:'10',
                PageNumber:1,
            }; 
            await props.getCertifications(oParams);
    
            setAddNewCertification(false);
            setEditCertification(false);
            setLoading(false);    
        }
    }

    async function handleDeleteChildernClick(id) {
        setLoading(true);

        const oParams  = {
            CertificationID: id,
        };        
        // console.log(oParams);
        await props.deleteCertification(oParams);    
        
        const oParams1 = {
            CertificationName:nodeName,
            IsCertificationActive:(radioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:1,
        }; 
        
        await props.getCertifications(oParams1);            

        setLoading(false);
    }

    function UpdateChildrenState(id) {
        let treeNodeIDs = '';
        let treeNodeDetails = '';
        const treeNodeRec = treeNode?.filter((cRec) => (cRec.CertificationID === id));
        treeNodeRec?.map((row, index) => {
            if (treeNodeIDs.trim() !== '') {
                treeNodeIDs += ',';
            }
            if (treeNodeDetails.trim() !== '') {
                treeNodeDetails += '&';
            }
            treeNodeIDs += row?.LearningObjectTreeID;
            treeNodeDetails += row?.LearningObjectTreeID + '=' + row?.LearningObjectTreeName;
            if (treeNodeIDs) {
                setCertificationTreeNodes(treeNodeIDs);
                setCertificationTreeNodeDetails(treeNodeDetails);
                setCertificationTreeNodeArray(treeNodeDetails.split('&'));
            }            
        });

        let authDetails = '';
        const authRec = authorizerGroups?.filter((cRec) => (cRec.CertificationID === id));
        authRec?.map((row, index) => {
            if (authDetails.trim() !== '') {
                authDetails += '&';
            }
            authDetails += row?.GroupID + '|' + row?.GroupName?.replaceAll("&", "and") + '|' + row?.LevelNumber + '|' + row?.NotificationMessageID + '|' + row?.NotificationMessageName?.replaceAll("&", "and");    
        });
        if (authDetails.trim() !== '') {
            const oSelections = authDetails?.split('&');
            setAuthorizationArray(oSelections);
            setAuthorizationDetails(authDetails);
        } else {
            setAuthorizationArray([]);
            setAuthorizationDetails('');
        }

        let preqIDs = '';
        let preqDetails = '';
        const preqRec = prerequisite?.filter((cRec) => (cRec.CertificationID === id));
        preqRec?.map((row, index) => {
            if (preqIDs.trim() !== '') {
                preqIDs += ',';
            }
            if (preqDetails.trim() !== '') {
                preqDetails += '&';
            }

            preqIDs += row?.RequiredCertificationID;
            preqDetails += row?.RequiredCertificationID + '=' + row?.RequiredCertificationName;
        });
        if (preqIDs.trim() !== '') {
            setCertificationPrerequisites(preqIDs);
        }
        if (preqDetails.trim() !== '') {
            const oSelections = preqDetails?.split('&');
            setCertificationPrerequisitesArray(oSelections);
        }

        let ktDetails = '';
        const ktRec = learningObjects?.filter((cRec) => (cRec.CertificationID === id));
        ktRec?.map((row, index) => {
            if (ktDetails.trim() !== '') {
                ktDetails += '&';
            }
            ktDetails += ((row?.MustPass === true) ? '0' : '1') + '|' + row?.RequiredScore + '|' + row?.AssignedOrder + '|' + row?.CertificationTypeID + '|' + 
                ((row?.BypassScore === true) ? '1' : '0') + '|' + ((row?.NewLOIfShared === true) ? '1' : '0') + '|' + row?.LearningObjectID + '|' + row?.LearningObjectName;    
        });
        if (ktDetails.trim() !== '') {
            const oSelections = ktDetails?.split('&');
            setCertificationKnowledgeTrackArray(oSelections);
            setCertificationKnowledgeTrackDetails(authDetails);
        } else {
            setCertificationKnowledgeTrackArray([]);
            setCertificationKnowledgeTrackDetails('');
        }

        let paymIDs = '';
        const paymRec = paymentMode?.filter((cRec) => (cRec.CertificationID === id));
        paymRec?.map((row, index) => {
            if (paymIDs.trim() !== '') {
                paymIDs += ',';
            }
            paymIDs += row?.PaymentModeID;
        });
        if (paymIDs !== '') {
            setCertificationPaymentModes(paymIDs);
        }

        // let nmIDs = '';
        // const notificationMessageRec = notificationMessage?.filter((cRec) => (cRec.CertificationID === id));
        // notificationMessageRec?.map((row, index) => {
        //     if (nmIDs.trim() !== '') {
        //         nmIDs += '&';
        //     }
        //     nmIDs += row?.LearningObjectProcessStatusTypeID + '|' + row?.LearningObjectProcessStatusTypeName + '|' + row?.NotificationMessageID + '|' + row?.NotificationMessageName;
        // });
        // if (nmIDs !== '') {
        //     setCertificationNotificationMessageDetails(nmIDs);
        //     setCertificationNotificationMessageArray(nmIDs.split('&'));    
        // }

        let promotionIDs = '';
        const promotionRec = promotion?.filter((cRec) => (cRec.CertificationID === id));
        promotionRec?.map((row, index) => {
            if (promotionIDs.trim() !== '') {
                promotionIDs += '&';
            }
            promotionIDs += row?.PromotionName + '|' + row?.PromotionCode + '|' + row?.AccessCode + '|' + row?.StartDateTime + '|' + row?.EndDateTime + '|' + row?.DiscountTypeID + '|' + row?.DiscountTypeName +
                '|' + row?.PromotionPrice + '|' + ((row?.AutoApply) ? '1' : '0') + '|' + row?.EmploymentTypeIDs;
        });
        if (promotionIDs !== '') {
            setCertificationPromotionDetails(promotionIDs);
            setCertificationPromotionArray(promotionIDs.split('&'));
        }

    }

    async function handleViewChildernClick(id) {
        try {
            //setLoading(true);
            // const oKParams = getSearchParamsEx(radioValue, radioTypeValue, certifications?.certifications?.PagingMetaData?.PageNumber);
            // const oParams = {
            //     CertificationID:id,
            //     RecordSize:'10',
            //     PageNumber:1,
            // }; 
            // await props.getCertificationInstances(oParams);
            // navigate('/certificationinstancelist/' + id, {state: {permissiontypeid:permissionTypeID, parentParams:oKParams, listby:radioTypeValue}});    
        } catch (err){
            console.log('Error is getting instance details - ' + err);
            setLoading(false);
        }
    }

    async function handleUpdateChildernClick(id) {
        setLoading(true);
        setTabValue(1);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const assEditedRec = certification?.filter((assRec) => (assRec.CertificationID === id));
        console.log(assEditedRec);
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setCertificationID(id);
            setCertificationName(assEditedRec[0]?.CertificationName);
            setCertificationActive((assEditedRec[0]?.IsCertificationActive) ? '1' : '0');
            setCertificationDescription(assEditedRec[0]?.CertificationDescription);
            setCertificationActive((assEditedRec[0]?.IsDeleted) ? '1' : '0');
            setCertificationExpires((assEditedRec[0]?.Expires) ? '1' : '0');
            setCertificationAutoRenew((assEditedRec[0]?.AutoRenew) ? '1' : '0');
            setCertificationForcePayment((assEditedRec[0]?.ForcePayment) ? '1' : '0');
            setCertificationRequireCredits((assEditedRec[0]?.RequireCredits) ? '1' : '0');
            setCertificationListPrice(assEditedRec[0]?.ListPrice);
            setCertificationExpiryType(assEditedRec[0]?.CertificationExpiryTypeID);
            setCertificationPreEnrollmentText(assEditedRec[0]?.PreEnrollmentText);
            setCertificationDuration(assEditedRec[0]?.Duration);
            setCertificationCreditUnits(assEditedRec[0]?.CreditUnits);
            setCertificationCreditHours(assEditedRec[0]?.CreditHours);
            setCertificationCreditOthers(assEditedRec[0]?.CreditOthers);
            setCertificationReminders(assEditedRec[0]?.ReminderID);
            setCertificationReminderNames(assEditedRec[0]?.ReminderName);
            setCertificationPaperCertificates(assEditedRec[0]?.PaperCertificationID);
            setCertificationPaperCertificateNames(assEditedRec[0]?.PaperCertificateName);
            setCertificationNotificationMessages(assEditedRec[0]?.NotificationMessageID);
            setCertificationNotificationMessageNames(assEditedRec[0]?.NotificationMessageName);
            setCertificationEffectiveDate(assEditedRec[0]?.EffectiveDate);
            setCertificationByPassPrerequisities((assEditedRec[0]?.ByPassPrerequisiteChecking) ? '1' : '0');
            setCertificationPrerequisiteExpression(assEditedRec[0]?.PrerequisitesExpression);
            // setCertificationListPrice(assEditedRec[0]?.DependentCertificationIDs),
            setCertificationByPassAuthorization((assEditedRec[0]?.DisableAuthorization) ? '1' : '0');
            setCertificationCancellationCharge(assEditedRec[0]?.CancellationCost);
            setCertificationAssignByOrder((assEditedRec[0]?.IsCertificationAssignOrder) ? '1' : '0');
            // setCertificationListPrice(assEditedRec[0]?.CertificationPrerequisites),
            // setCertificationListPrice(assEditedRec[0]?.CertificationLearningObjects),
            // setCertificationListPrice(assEditedRec[0]?.CertificationTreeNodes);
            // setCertificationListPrice(assEditedRec[0]?.CetificationPromotions),
            // setCertificationListPrice(assEditedRec[0]?.CertificationAuthorizerGroups),

            UpdateChildrenState(id);
           
            setEditCertification(true);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(id) {
        if (certificationID === -1) {
            setAddNewCertification(false);
        } else {
            setEditCertification(false);
        }
        ClearStateFields();
    }
    
    async function ClearStateFields() {
        setCertificationID(-1);
        setCertificationName('');
        setCertificationActive(null); 
        setCertificationDescription('');
        setCertificationExpiryType(null);
        setCertificationExpires(null);
        setCertificationAutoRenew(null);
        setCertificationForcePayment(null);
        setCertificationRequireCredits(null);
        setCertificationByPassPrerequisities(null);
        setCertificationByPassAuthorization(null);
        setCertificationAssignByOrder(null);
        setCertificationListPrice(null);
        setCertificationPreEnrollmentText(null);
        setCertificationDuration(null);
        setCertificationCreditUnits(null);
        setCertificationCreditHours(null);
        setCertificationCreditOthers(null);
        setCertificationReminders('');
        setCertificationReminderNames('');
        setCertificationPaperCertificates('');
        setCertificationPaperCertificateNames('');
        setCertificationNotificationMessages('');
        setCertificationNotificationMessageNames('');
        setCertificationEffectiveDate(null);
        setCertificationByPassPrerequisities(-1);
        setCertificationPrerequisiteExpression(null);
        setCertificationByPassAuthorization(-1);
        setCertificationCancellationCharge(null);
        setCertificationAssignByOrder(-1);
        setCertificationPromotions('');
        setCertificationTreeNodes('');
        setCertificationTreeNodeDetails('');
        setCertificationPrerequisites('');
        setCertificationPrerequisitesDetails('');
        setCertificationNotificationMessages('');
        setCertificationNotificationMessageNames('');
        setCertificationNotificationMessageDetails('');
        setCertificationNotificationMessageArray([]);
        setCertificationPromotionArray(null);
        setCertificationPromotionDetails(null);
        setCertificationPromotionDiscountType(null);
        setCertificationPromotionDiscountTypeName(null);
        setCertificationPromotionName(null);
        setCertificationPromotionCode(null);
        setCertificationPromotionAccessCode(null);
        setCertificationPromotionPrice(null);
        setCertificationPromotionAutoApply(null);
        setCertificationPromotionStartDate(null);
        setCertificationPromotionExpirationDate(null);
        setCertificationPromotionEmploymentTypes(null);
        setCertificationPromotionEmploymentTypeNames(null);
        setAuthorizationGroup('');
        setAuthorizationGroupName('');
        setAuthorizationLevel(null);                
        setAuthorizationNotificationMessages(null);                
        setAuthorizationNotificationMessageNames(null);                
        setAuthorizationNotificationMessageDetails('');                
        setAuthorizationNotificationMessageArray([]);                
        setAuthorizationDetails('');
        setAuthorizationArray([]);
        setCertificationKnowledgeTrackArray([]);
        setCertificationKnowledgeTrackDetails('');
        setKnowledgeTrackArray([]);
        setKnowledgeTrackDetails('');
    }

    async function handleAddSubChildrenClick(tab){
        if (tab === 2) {
            if ((certificationProcessStatusTypeID) && (certificationProcessStatusTypeID !== -1) && (certificationNotificationMessages) && (certificationNotificationMessages.trim() !== '')) {
                var sSelections = certificationProcessStatusTypeID + '|' + certificationProcessStatusTypeName + '|' + certificationNotificationMessages + '|' + certificationNotificationMessageNames;
                if ((certificationNotificationMessageDetails) && (certificationNotificationMessageDetails?.trim() !== '')) {
                    sSelections = certificationNotificationMessageDetails + '&' + sSelections;
                }
                const oSelections = sSelections.split('&');
                setCertificationNotificationMessageArray(oSelections);
                setCertificationNotificationMessageDetails(sSelections);
                setAddNewCertificationNotificationMessage(false);        
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 3) {
            if ((authorizationGroup) && (authorizationGroup.trim() !== '') && (authorizationLevel) && (authorizationLevel.trim() !== '')) {
                var sSelections =  authorizationGroup + '|' + authorizationGroupName + '|' + authorizationLevel + '|' + authorizationNotificationMessages + '|' + authorizationNotificationMessageNames;
                if ((authorizationDetails) && (authorizationDetails?.trim() !== '')) {
                    sSelections = authorizationDetails + '&' + sSelections;
                }
                console.log(sSelections);
                const oSelections = sSelections.split('&');
                setAuthorizationArray(oSelections);
                setAuthorizationDetails(sSelections);
                setAddNewCertificationAuthorization(false);
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 4) {
            if ((certificationKnowledgeTrackOptional) && (certificationKnowledgeTrackOptional.trim() !== '-1') && (knowledgeTrackDetails) && (knowledgeTrackDetails.trim() !== '') && 
                (knowledgeTrackArray) && (knowledgeTrackArray.length > 0 )) {

                var sSubSelections =  certificationKnowledgeTrackOptional + '|' + certificationKnowledgeTrackPassingScore + '|' + certificationKnowledgeTrackDisplayOrder + '|' + 
                        certificationKnowledgeTrackType + '|' + certificationKnowledgeTrackByPassScore + '|' + certificationKnowledgeTrackCreateNew;
                var sSelections = '';
                knowledgeTrackArray?.forEach(element => {
                    if (element.trim() !== '') {
                        if (sSelections.trim() !== '') {
                            sSelections += '&';
                        }
                        sSelections += sSubSelections + '|' + element.replaceAll('=','|');
                    }
                });
                if ((certificationKnowledgeTrackDetails) && (certificationKnowledgeTrackDetails?.trim() !== '')) {
                    sSelections = certificationKnowledgeTrackDetails + '&' + sSelections;
                }
                console.log(sSelections);
                const oSelections = sSelections.split('&');
                setCertificationKnowledgeTrackArray(oSelections);
                setCertificationKnowledgeTrackDetails(sSelections);
                setCertificationKnowledgeTrackOptional(null);
                setCertificationKnowledgeTrackPassingScore(null);
                setCertificationKnowledgeTrackDisplayOrder(null);
                setCertificationKnowledgeTrackType(null);
                setCertificationKnowledgeTrackByPassScore(null);
                setCertificationKnowledgeTrackCreateNew(null);
                setKnowledgeTrackArray([]);
                setKnowledgeTrackDetails('');
                setAddNewCertificationKnowledgeTrack(false);
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        } else if (tab === 5) {
            if ((certificationPromotionName) && (certificationPromotionName.trim() !== '') && (certificationPromotionCode) && (certificationPromotionCode.trim() !== '') && 
                (certificationPromotionAccessCode) && (certificationPromotionAccessCode.trim() !== '')) {
                    var sSelections = certificationPromotionName + '|' + certificationPromotionCode + '|' + certificationPromotionAccessCode + '|' + certificationPromotionStartDate + '|' + certificationPromotionExpirationDate + '|' + certificationPromotionDiscountType + '|' + certificationPromotionDiscountTypeName + 
                    '|' + certificationPromotionPrice + '|' + certificationPromotionAutoApply + '|' + certificationPromotionEmploymentTypes;
                if ((certificationPromotionDetails) && (certificationPromotionDetails?.trim() !== '')) {
                    sSelections = certificationPromotionDetails + '&' + sSelections;
                } 
                const oSelections = sSelections.split('&');
                setCertificationPromotionArray(oSelections);
                setCertificationPromotionDetails(sSelections);
                setAddNewCertificationPromotion(false);        
            } else {
                setAlertMessage('Some required fields are missing. Please fill them in before proceeding.');
                setAlertVariant(AlertVariant.Warning);
                setShowAlertMessage(true);
            }
        }
    }

    async function handleDeleteSubChildren(tab, element) {
        setLoading(true);
        if (tab === 2) {
            var tempSkills = certificationNotificationMessageArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setCertificationNotificationMessageArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setCertificationNotificationMessageDetails(sSelectedDetails);
            }            
        } else if (tab === 3) {
            var tempSkills = authorizationArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setAuthorizationArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setAuthorizationDetails(sSelectedDetails);
            }            
        } else if (tab === 4) {
            var tempSkills = certificationKnowledgeTrackArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setCertificationKnowledgeTrackArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setCertificationKnowledgeTrackDetails(sSelectedDetails);
            }            
        } else if (tab === 5) {
            var tempSkills = certificationPromotionArray.slice(0);
            var iRIndex = tempSkills.indexOf(element);
            if (iRIndex !== -1) {
                tempSkills.splice(iRIndex, 1);
                setCertificationPromotionArray(tempSkills);
                let sSelectedDetails = ''
                let sSelectedIds = ''
                let sSelectedNames = ''
                tempSkills?.forEach(element => {
                    if (element !== '') {
                        if (sSelectedDetails.trim() !== '') {
                            sSelectedDetails += '&';
                        }
                        sSelectedDetails += element;    
                    }
                });
                setCertificationPromotionDetails(sSelectedDetails);
            }            
        }
        setLoading(false);
    }    

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Certification'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Certification'>Cancel</Button>
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radioKnowledege.map((radio, idx) => (
                ((radio.value === 7) && (addNewCertification)) ? 
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer}  title= 'Only available in edit mode!' onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
                :
                    <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                        <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                    </li> 
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        }
        else if (tabValue === 2 ) {
            return (
                <div>
                    {displayKnowledgeTracks()}
                </div>
            );
        } else if (tabValue === 3 ) {
            return (
                <div>
                    {displayCatalog()}
                </div>
            );
        } else if (tabValue === 4 ) {
            return (
                <div>
                    {displayAuthorization()}
                </div>
            );
        } else if (tabValue === 5 ) {
            return (
                <div>
                    {displayAdvanced()}
                </div>
            );
        } 
    }

    function displayNewCertification() {
        if ((addNewCertification) || (editCertification)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewCertification) ? 'New Certification' : 'Edit Certification'}                        
                        </Card.Title>
                        <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayTabButtons()}
                                </td>
                            </tr>
                            </tbody>
                        </Table>    
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                {displaySaveButtons()}
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayAssignedCertificationKnowledgeTrack() {
        if (certificationKnowledgeTrackArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {certificationKnowledgeTrackArray?.map((goal, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{goal.split('=')[1]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedKnowledgeTrackClick(goal)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Certification Knowledge Track'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedKnowledgeTrack() {
        if (knowledgeTrackArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {knowledgeTrackArray?.map((goal, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{goal.split('=')[1]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedKnowledgeTrackClick(goal)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Knowledge Track'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedPrerequisites() {
        if (certificationPrerequisitesArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {certificationPrerequisitesArray?.map((goal, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{goal.split('=')[1]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedPrerequisitesClick(goal)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Prerequisite'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAttributes() {
            return (
                <table class="table table-sm caption-top text-left">  
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="certificationName" style={styles.LabelDataEntryNoPaddingRequired}>Name</label>
                                    <input id="certificationName" type="text" style={styles.TextDataEntryNoPadding} value={certificationName} onChange={(e) => (setCertificationName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="assactive" style={styles.LabelDataEntryNoPaddingRequired}>Active</label>
                                    <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationActive} onChange={(e) => (setCertificationActive(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="certexpire" style={styles.LabelDataEntryNoPaddingRequired}>Expires</label>
                                    <select id="certexpire" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationExpires} onChange={(e) => (setCertificationExpires(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPadding} value={certificationDescription} onChange={(e) => (setCertificationDescription(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="listprice" style={styles.LabelDataEntryNoPadding}>List Price</label>
                                    <input id="listprice" type="number" min='1' max='100' style={styles.TextDataEntryNoPadding} value={certificationListPrice} onChange={(e) => (setCertificationListPrice(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="effectivedate" style={styles.LabelDataEntryNoPadding}>Effective Date</label>
                                        <input id="effectivedate" type="date" style={styles.TextDataEntryNoPadding} value={certificationEffectiveDate?.substr(0,10)} onChange={(e) => (setCertificationEffectiveDate(e.target.value))}/>
                                    </div>
                                </td>
                        </tr>       
                        <tr>
                            <td style={{textAlign:'left', width:'70%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="preenorlldesc" style={styles.LabelDataEntryNoPadding}>Pre Enrollment Text</label>
                                    <input id="preenorlldesc" type="text" style={styles.TextDataEntryNoPadding} value={certificationPreEnrollmentText} onChange={(e) => (setCertificationPreEnrollmentText(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="duration" style={styles.LabelDataEntryNoPaddingRequired}>Duration</label>
                                    <input id="duration" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={certificationDuration} onChange={(e) => (setCertificationDuration(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="cancharge" style={styles.LabelDataEntryNoPadding}>Cancellation Charge</label>
                                    <input id="cancharge" type="number" step='1' min='1' style={styles.TextDataEntryNoPadding} value={certificationCancellationCharge} onChange={(e) => (setCertificationCancellationCharge(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>       
                        <tr>
                            <td colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <table class="table table-sm caption-top text-left" style={{backgroundColor:companyData?.PagesBGColor}}>  
                                    <tr>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="exptype" style={styles.LabelDataEntryNoPaddingWBG}>Expiry Type</label>
                                            <select id="expype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationExpiryType} onChange={(e) => (setCertificationExpiryType(e.target.value))}>
                                                <option value='-1'>Select One</option>
                                                {certificationExpiryTypeData?.map((oItem, iIndex) => (
                                                    <option id={oItem?.CertificationExpiryTypeID} value={oItem?.CertificationExpiryTypeID}>{oItem.CertificationExpiryTypeName}</option>        
                                                ))}
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="assorder" style={styles.LabelDataEntryNoPaddingWBG}>Assign By Order</label>
                                            <select id="assorder" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationAssignByOrder} onChange={(e) => (setCertificationAssignByOrder(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="reqcred" style={styles.LabelDataEntryNoPaddingWBG}>Require Credits</label>
                                            <select id="reqcred" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationRequireCredits} onChange={(e) => (setCertificationRequireCredits(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                            </select>                                     
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="creditunits" style={styles.LabelDataEntryNoPaddingWBG}>Credit Units</label>
                                            <input id="creditunits" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={certificationCreditUnits} onChange={(e) => (setCertificationCreditUnits(e.target.value))}/>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="credithours" style={styles.LabelDataEntryNoPaddingWBG}>Credit Hours</label>
                                            <input id="credithours" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={certificationCreditHours} onChange={(e) => (setCertificationCreditHours(e.target.value))}/>
                                        </td>
                                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="creditothers" style={styles.LabelDataEntryNoPaddingWBG}>Credit Others</label>
                                            <input id="creditothers" type="number" min='1' max='100' style={styles.TextDataEntryNoPaddingWBG} value={certificationCreditOthers} onChange={(e) => (setCertificationCreditOthers(e.target.value))}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <a onClick={() => setShowNotificationMessageList(true)}>
                                                    <label style={styles.LabelDataEntryNoPaddingWBG}>
                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Notification Message List'>mystery</span>                                    
                                                        Notifications
                                                    </label>
                                                </a>
                                                <input id="notimsg" type="text" required disabled style={styles.TextDataEntryNoPadding} value={certificationNotificationMessageNames}/>                                    
                                            </div>
                                        </td>
                                        <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <a onClick={() => setShowReminderList(true)}>
                                                    <label style={styles.LabelDataEntryNoPadding}>
                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Reminder List'>mystery</span>                                    
                                                        Reminder
                                                    </label>
                                                </a>
                                                <input id="reminderid" type="text" disabled style={styles.TextDataEntryNoPadding} value={certificationReminderNames}/>                                    
                                            </div>
                                        </td>
                                        <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <label htmlFor="papercert" style={styles.LabelDataEntryNoPaddingWBG}>Paper Certificate</label>
                                                <select id="papercert" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationPaperCertificates} onChange={(e) => (setCertificationPaperCertificates(e.target.value))}>
                                                    <option value="-1">Select One</option>
                                                    {paperCertificateData?.map((oItem, iIndex) => (
                                                        <option id={oItem?.ReportID} value={oItem?.ReportID}>{oItem.ReportName}</option>        
                                                    ))}
                                                </select>                                     
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>       
                    </tbody>
                </table>                                   
            );
    }

    function displayKnowledgeTracks() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header>
                    <Card.Title>
                        <table class="table table-sm text-left">  
                            <tbody>
                                <tr>
                                    <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                        Knowledge Tracks
                                    </td>
                                    {(permissionFlags.CanCreate) ? 
                                        (addNewCertificationKnowledgeTrack) ? 
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(4, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Knowledge Track'>shadow_minus</span>
                                            </a>
                                        </td>
                                        :
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(4, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Knowledge Track'>note_add</span>
                                            </a>
                                        </td>
                                    : 
                                        <></>
                                }
                                </tr>
                            </tbody>
                        </table>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {(addNewCertificationKnowledgeTrack) ? 
                        <table class="table table-sm caption-top text-left">  
                            <tbody>
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="optional" style={styles.LabelDataEntryNoPaddingRequired}>Optional</label>
                                        <select id="optional" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationKnowledgeTrackOptional} onChange={(e) => (setCertificationKnowledgeTrackOptional(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="lopassingscore" style={styles.LabelDataEntryNoPadding}>Passing Score</label>
                                        <input id="lopassingscore" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={certificationKnowledgeTrackPassingScore} onChange={(e) => (setCertificationKnowledgeTrackPassingScore(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="lodisplayorder" style={styles.LabelDataEntryNoPadding}>Display Order</label>
                                        <input id="lodisplayorder" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={certificationKnowledgeTrackDisplayOrder} onChange={(e) => (setCertificationKnowledgeTrackDisplayOrder(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="certtype" style={styles.LabelDataEntryNoPadding}>Type</label>
                                            <select id="emptypes" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100WBG} value={certificationKnowledgeTrackType} onChange={(e) => (setCertificationKnowledgeTrackType(e.target.value))}>
                                                <option value="-1">Select One</option>
                                                <option value='1'>Normal</option>        
                                                <option value='2'>Comprehensive</option>        
                                                <option value='3'>Renewal</option>        
                                            </select>                                     
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="bypassscore" style={styles.LabelDataEntryNoPadding}>ByPass Score</label>
                                        <select id="bypassscore" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationKnowledgeTrackByPassScore} onChange={(e) => (setCertificationKnowledgeTrackByPassScore(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="createnew" style={styles.LabelDataEntryNoPadding}>Create New</label>
                                        <select id="createnew" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationKnowledgeTrackCreateNew} onChange={(e) => (setCertificationKnowledgeTrackCreateNew(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='5' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => setShowKnowledgeTrackList(true)}>
                                                <label style={styles.LabelDataEntryNoPaddingRequired}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Knowledge Track'>mystery</span>                                    
                                                    Knowledge Tracks 
                                                </label>
                                            </a>
                                            {displayAssignedKnowledgeTrack()}                                    
                                        </div>
                                    </td>
                                    <td style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                        </div>
                                        <div>
                                            <a onClick={() => handleAddSubChildrenClick(4)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Certification Knowledge Track'>add_circle</span> 
                                            </a> 
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    : 
                        <></>
                    }
                    {((certificationKnowledgeTrackArray) && (certificationKnowledgeTrackArray.length > 0)) ? 
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableText}>Name</th>
                                    <th style={styles.tableText}>Optional</th>
                                    <th style={styles.tableText}>Display Order</th>
                                    <th style={styles.tableText}>Passing Score</th>
                                    <th style={styles.tableText}>Action</th>
                                </tr>
                            </thead>                            
                            <tbody>
                                {(certificationKnowledgeTrackArray && certificationKnowledgeTrackArray.map((oItem, index) => {
                                    return (<tr>
                                        <td style={styles.tableText}>{index+1}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[7]}</td>
                                        <td style={styles.tableText}>{(oItem.split('|')[0] === '1') ? 'Yes' : 'No'}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                        <td style={styles.tableText}>
                                            <a onClick={() => handleDeleteSubChildren(4, oItem)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Knowledge Track'>delete</span>
                                            </a>
                                        </td>
                                    </tr>)
                                }))
                                }
                            </tbody>
                        </table>
                    :
                        <></>
                    }
                </Card.Body>
            </Card>
        );
    }

    function displayCatalog() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                    <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                    <Card.Title>
                                        <div>
                                            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h4 style={styles.SubHeader}>Catalog Tree</h4></td>
                                                    <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                        <div className='input-field'>
                                                            <input id="searchLearningObjectTreeNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchCertificationTreeNodeName} placeholder='Search Catalog Node'/>
                                                        </div>                                            
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>                            
                                        </div>
                                    </Card.Title>
                                </Card.Header>                   
                                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                            padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    <Tree 
                                                        width='100%'
                                                        data={orgLearningObjectTree} 
                                                        openByDefault={false}
                                                        indent={40}
                                                        padding={10}
                                                        searchTerm={certificationTreeNodeName}
                                                        searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                        onSelect={(nodes) => {saveCertificationTreeSelectedNodes(nodes)}}         
                                                        disableEdit={false}                                    
                                                        disableDrag={false}                                    
                                                        disableDrop={false}                                    
                                                        disableMultiSelection={false}              
                                                        selection={certificationTreeNodes}                      
                                                    >
                                                    </Tree>
                                                </td>
                                                <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                        padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                    {displaySelectedCertificationTree()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                            
                                </Card.Body>
                                <Card.Footer className="text-left">
                                </Card.Footer>
                            </Card>
                        </td>
                    </tr>                
                </tbody>
            </table>                                   
        );
    }


    function displayAdvanced() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="reqcred" style={styles.LabelDataEntryNoPaddingRequired}>ByPass Prerequisities</label>
                            <select id="reqcred" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationByPassPrerequisities} onChange={(e) => (setCertificationByPassPrerequisities(e.target.value))}>
                                <option value="-1">Select One</option>
                                <option value='1'>Yes</option>        
                                <option value='0'>No</option>        
                            </select>                                     
                        </td>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="preenorlldesc" style={styles.LabelDataEntryNoPadding}>Prerequisite Expression</label>
                            <input id="preenorlldesc" type="text" style={styles.TextDataEntryNoPadding} value={certificationPrerequisiteExpression} onChange={(e) => (setCertificationPrerequisiteExpression(e.target.value))}/>
                        </td>
                    </tr>        
                    <tr>
                        <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                <a onClick={() => setShowCertificationPrerequisitesList(true)}>
                                    <label style={styles.LabelDataEntryNoPadding}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Prerequisites List'>mystery</span>                                    
                                        Prerequisites
                                    </label>
                                </a>
                                {displayAssignedPrerequisites()}                                    
                            </div>
                        </td>
                    </tr>        
                    <tr>
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="forcepay" style={styles.LabelDataEntryNoPadding}>Force Payment</label>
                            <select id="forcepay" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationForcePayment} onChange={(e) => (setCertificationForcePayment(e.target.value))}>
                                <option value="-1">Select One</option>
                                <option value='1'>Yes</option>        
                                <option value='0'>No</option>        
                            </select>                                     
                        </td>
                        <td style={{textAlign:'left', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="paymodes" style={styles.LabelDataEntryNoPadding}>Payment Modes</label>
                            <select id="paymodes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} value={certificationPaymentModes} onChange={(e) => (handleCategoriesClick(e, 1))}>
                                {paymentModeTypeData?.map((oItem, iIndex) => (
                                    <option id={oItem?.PaymentModeID} value={oItem?.PaymentModeID}>{oItem.PaymentModeName}</option>        
                                ))}
                            </select>                                     
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            {displayPromotions()}
                        </td>
                    </tr>                
                </tbody>
            </table>                                   
        );
    }

    function displayAuthorization() {
        return (
            <table class="table table-sm caption-top text-left">  
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', width:'15%', backgroundColor:companyData?.PagesBGColor}}>
                            <label htmlFor="reqcred" style={styles.LabelDataEntryNoPaddingRequired}>ByPass Authorization</label>
                            <select id="reqcred" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationByPassAuthorization} onChange={(e) => (setCertificationByPassAuthorization(e.target.value))}>
                                <option value="-1">Select One</option>
                                <option value='1'>Yes</option>        
                                <option value='0'>No</option>        
                            </select>                                     
                        </td>
                    </tr>                
                    <tr>
                        <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                            {displayAuthorizationGroups()}
                        </td>
                    </tr>                
                </tbody>
            </table>                                   
        );
    }

    function displaySelectedCertificationTree() {
        const certificationLearningObjectTreeArray = certificationTreeNodeDetails?.split('&'); 
        if (certificationLearningObjectTreeArray) {
        return (
                <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <thead>
                    <tr>
                        <th colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Tree Node(s)</h5></th>
                    </tr>
                </thead>
                <tbody>
                    {certificationLearningObjectTreeArray.map((node, index) => {
                        return (
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]}</h6></td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <a onClick={() => handleDeleteAssignedTreeNodeClick(node)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Tree Node'>delete</span>
                                </a>
                            </td>
                        </tr>
                        )
                    })}
                </tbody>
            </Table>                                                    
            );
        }
    }

    function displayAuthorizationGroups() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Title style={styles.LabelDataEntryNoPaddingBoldWBG}>
                        <table class="table table-sm text-left">  
                            <tbody>
                                <tr>
                                    <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                        Authorization Groups
                                    </td>
                                    {(permissionFlags.CanCreate) ? 
                                        (addNewCertificationAuthorization) ? 
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(3, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Authorization'>shadow_minus</span>
                                            </a>
                                        </td>
                                        :
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(3, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Authorization'>note_add</span>
                                            </a>
                                        </td>
                                    : 
                                        <></>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </Card.Title>
                </CardHeader>                                        
                <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    {(addNewCertificationAuthorization) ? 
                        <table class="table table-sm caption-top text-left">
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="agroups" style={styles.LabelDataEntryNoPaddingWBGRequired}>Group</label>
                                    <select id="agroups" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100WBG} value={authorizationGroup} onChange={(e) => (updateSubChildrenSelect(2, e.target.selectedOptions))}>
                                        <option value='-1'>Select One</option>
                                        {groupTypeData?.map((oItem, iIndex) => (
                                            <option id={oItem?.GroupID} value={oItem?.GroupID}>{oItem.GroupName}</option>        
                                        ))}
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="enddate" style={styles.LabelDataEntryNoPaddingWBGRequired}>Level</label>
                                    <input id="enddate" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={authorizationLevel} onChange={(e) => (setAuthorizationLevel(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowAuthorizationNotificationMessageList(true)}>
                                            <label style={styles.LabelDataEntryNoPaddingWBG}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Notification Message List'>mystery</span>                                    
                                                Notifications
                                            </label>
                                        </a>
                                        <input id="notimsg" type="text" required disabled style={styles.TextDataEntryNoPadding} value={authorizationNotificationMessageNames}/>                                    
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={styles.LabelDataEntryNoPaddingWBG}>
                                        <label style={styles.LabelDataEntryNoPaddingWBG}>Add</label>
                                    </div>
                                    <div style={styles.LabelDataEntryNoPaddingWBG}>
                                        <a onClick={() => handleAddSubChildrenClick(3)}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Authorization Group'>add_circle</span> 
                                        </a> 
                                    </div>
                                </td>
                            </tr>
                        </table>
                    :
                        <></>
                    }
                    {((authorizationArray) && (authorizationArray.length > 0)) ? 
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextMain}>Group</th>
                                    <th style={styles.tableText}>Level</th>
                                    <th style={styles.tableText}>Notification</th>
                                    <th style={styles.tableText}>Delete</th>
                                </tr>
                            </thead>                            
                            <tbody>
                                {(authorizationArray && authorizationArray.map((oItem, index) => {
                                    return (<tr>
                                        <td style={styles.tableText}>{index+1}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[4]}</td>
                                        <td style={styles.tableText}>
                                            <a onClick={() => handleDeleteSubChildren(3, oItem)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Authorization Group'>delete</span>
                                            </a>
                                        </td>
                                    </tr>)
                                }))
                                }
                            </tbody>
                        </table>
                    :
                        <></>
                    }                        
                </CardBody>
            </Card>
        )
    }

    function displayPromotions() {
        return (
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header>
                    <Card.Title>
                        <table class="table table-sm text-left">  
                            <tbody>
                                <tr>
                                    <td style={styles.LabelDataEntryNoPaddingBoldWBG}>
                                        Promotions
                                    </td>
                                    {(permissionFlags.CanCreate) ? 
                                        (addNewCertificationPromotion) ? 
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(5, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Promotion'>shadow_minus</span>
                                            </a>
                                        </td>
                                        :
                                        <td style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                            <a onClick={() => handleAddChildernClick(5, null)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Promotion'>note_add</span>
                                            </a>
                                        </td>
                                    : 
                                        <></>
                                }
                                </tr>
                            </tbody>
                        </table>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {(addNewCertificationPromotion) ? 
                        <table class="table table-sm caption-top text-left">  
                            <tbody>
                                <tr>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="promotionname" style={styles.LabelDataEntryNoPaddingRequired}>Name</label>
                                        <input id="promotionname" type="text" style={styles.TextDataEntryNoPadding} value={certificationPromotionName} onChange={(e) => (setCertificationPromotionName(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="promotioncode" style={styles.LabelDataEntryNoPaddingRequired}>Code</label>
                                        <input id="promotioncode" type="text" style={styles.TextDataEntryNoPadding} value={certificationPromotionCode} onChange={(e) => (setCertificationPromotionCode(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="promotionacode" style={styles.LabelDataEntryNoPaddingRequired}>Access Code</label>
                                        <input id="promotionacode" type="text" style={styles.TextDataEntryNoPadding} value={certificationPromotionAccessCode} onChange={(e) => (setCertificationPromotionAccessCode(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="promstartstart" style={styles.LabelDataEntryNoPadding}>Start Date</label>
                                            <input id="promstartstart" type="date" style={styles.TextDataEntryNoPadding} value={certificationPromotionStartDate?.substr(0,10)} onChange={(e) => (setCertificationPromotionStartDate(e.target.value))}/>
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="promreleasestart" style={styles.LabelDataEntryNoPadding}>Expiration Date</label>
                                            <input id="promreleasestart" type="date" style={styles.TextDataEntryNoPadding} value={certificationPromotionExpirationDate?.substr(0,10)} onChange={(e) => (setCertificationPromotionExpirationDate(e.target.value))}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign:'left', height:'200px', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label htmlFor="emptypes" style={styles.LabelDataEntryNoPadding}>Employment Types</label>
                                            <select id="emptypes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100WBG} defaultValue={certificationPromotionEmploymentTypes?.split(',')} onChange={(e) => (updateSubChildrenSelect(4, e.target.selectedOptions))}>
                                                {employmentTypeData?.map((rating, supIndex) => (
                                                    <option id={rating?.EmploymentTypeID} value={rating?.EmploymentTypeID}>{rating.EmploymentTypeDescription}</option>        
                                                ))}
                                            </select>                                     
                                        </div>
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="promautoapply" style={styles.LabelDataEntryNoPadding}>Auto Apply</label>
                                        <select id="promoautoapply" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationPromotionAutoApply} onChange={(e) => (setCertificationPromotionAutoApply(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="discounttype" style={styles.LabelDataEntryNoPadding}>Discount Type</label>
                                        <select id="discounttype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={certificationPromotionDiscountType} onChange={(e) => (updateSubChildrenSelect(5, e.target.selectedOptions))}>
                                            <option value="-1">Select One</option>
                                            {discountTypeData?.map((rating, supIndex) => (
                                                    <option id={rating?.DiscountTypeID} value={rating?.DiscountTypeID}>{rating.DiscountTypeName}</option>        
                                                ))}
                                        </select>                                     
                                    </td>
                                    <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="promotionprice" style={styles.LabelDataEntryNoPadding}>Price</label>
                                        <input id="promotionprice" type="number" style={styles.TextDataEntryNoPadding} value={certificationPromotionPrice} onChange={(e) => (setCertificationPromotionPrice(e.target.value))}/>
                                    </td>
                                    <td style={{textAlign:'center', backgroundColor:companyData?.PagesBGColor}}>
                                        <div>
                                            <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                        </div>
                                        <div>
                                            <a onClick={() => handleAddSubChildrenClick(5)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Promotion'>add_circle</span> 
                                            </a> 
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    : 
                        <></>
                    }
                    {((certificationPromotionArray) && (certificationPromotionArray.length > 0)) ? 
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextMain}>Name</th>
                                    <th style={styles.tableText}>Code</th>
                                    <th style={styles.tableText}>Access Code</th>
                                    <th style={styles.tableText}>Discount Type</th>
                                    <th style={styles.tableText}>Action</th>
                                </tr>
                            </thead>                            
                            <tbody>
                                {(certificationPromotionArray && certificationPromotionArray.map((oItem, index) => {
                                    return (<tr>
                                        <td style={styles.tableText}>{index+1}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[0]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[1]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[2]}</td>
                                        <td style={styles.tableText}>{oItem.split('|')[6]}</td>
                                        <td style={styles.tableText}>
                                            <a onClick={() => handleDeleteSubChildren(5, oItem)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Promotion'>delete</span>
                                            </a>
                                        </td>
                                    </tr>)
                                }))
                                }
                            </tbody>
                        </table>
                    :
                        <></>
                    }
                </Card.Body>
            </Card>
        );
    }

    function getSearchParamsEx(bRadioValue, pageNumber)  {
        let oParams = {
            CertificationName:nodeName,
            IsCertificationActive:(bRadioValue === '1') ? 'true' : 'false',
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 
        return oParams;
    }

    async function searchCertifications(bRadioValue) {
        setLoading(true);
        const oParams = getSearchParamsEx(bRadioValue, '1');
        await props.getCertifications(oParams);
        setLoading(false);
    }


    async function handleRadioChange(e) {
        setRadioValue(e.currentTarget.value);
        searchCertifications(e.currentTarget.value);
    }

    function displayListRadioButtons() {
        return (
            radios.map((radio, idx) => (
                <ToggleButton
                    key={'checklisttype' + idx}
                    id={`radio-${'checklisttype' + idx}`}
                    type="radio"
                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    size='sm'
                    onChange={(e) => handleRadioChange(e)}
                >
                    {radio.name}
                </ToggleButton>
            ))
        );
    }

    function displayMyTeamHeader() {
            return (
                <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                <tbody>
                <tr>
                    <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Certifications</h3></td>
                    <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                        <div className='input-field' style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'5px' }}>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Certification'/>
                            <a onClick={() => searchCertifications()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                            {(permissionFlags.CanCreate) ? 
                                (addNewCertification) ? 
                                    <a onClick={() => handleAddChildernClick(1, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Certification'>shadow_minus</span>
                                    </a>
                                    :
                                    <a onClick={() => handleAddChildernClick(1, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Certification'>note_add</span>
                                    </a>
                            : 
                                <></>
                            }
                            <ButtonGroup className="mb-2" size='sm'>
                                {displayListRadioButtons()}
                            </ButtonGroup>                                                                   
                        </div>                                            
                    </td>
                </tr>
                </tbody>
            </Table>                            
            );    
        }
    
    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showNotificationMessageList} onShow={handleShowNotificationMessageList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseNotificationMessageList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NotificationMessageList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseNotificationMessageList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showReminderList} onShow={handleShowReminderList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseReminderList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReminderList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseReminderList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showAuthorizationNotificationMessageList} onShow={handleShowAuthorizationNotificationMessageList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseAuthorizationNotificationMessageList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NotificationMessageList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseAuthorizationNotificationMessageList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showCertificationPrerequisitesList} onShow={handleShowCertificationPrerequisitesList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseCertificationPrerequisitesList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CertificationLookupList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseCertificationPrerequisitesList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showKnowledgeTrackList} onShow={handleShowKnowledgeTrackList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseKnowledgeTrackList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <KnowledgeLookupList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseKnowledgeTrackList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                            <Card.Subtitle>
                            {(showAlertMessage) ?
                                <div>
                                    <h5><AlertMessage showAlertMessage={showAlertMessage} alertMessage={alertMessage} alertVariant={alertVariant} handleParentAlertClose={handleCloseAlert} /></h5>
                                </div>
                                :
                                    <></>
                                }
                            </Card.Subtitle>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewCertification()}                                               
                            {((!certification) || (certification.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Certifications.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextLeft}>Name</th>
                                                <th style={styles.tableText}>Active</th>
                                                <th style={styles.tableText}>Expires</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {certification && certification.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{assRec?.CertificationID}</td>
                                                        <td style={styles.tableTextLeft}>{assRec?.CertificationName}</td>
                                                        <td style={styles.tableText}>{(assRec?.IsDeleted) ? 'Yes' : 'No'}</td>
                                                        <td style={styles.tableText}>{(assRec?.Expires) ? 'Yes' : 'No'}</td>
                                                        <td style={styles.tableText}>
                                                            <div>
                                                                {(permissionFlags.CanModify) ? 
                                                                    <>
                                                                    <a onClick={() => handleViewChildernClick(assRec?.CertificationID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Attendee'>group_add</span>
                                                                    </a>
                                                                    <a onClick={() => handleUpdateChildernClick(assRec?.CertificationID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Certification'>edit</span>
                                                                    </a>
                                                                    {((permissionFlags.CanDelete) && (assRec?.AssignedUsers === 0) && (assRec?.CompletedUsers === 0)) ? 
                                                                        <a onClick={() => handleDeleteChildernClick(assRec?.CertificationID)}>
                                                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Certification'>delete</span>
                                                                        </a>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    </>
                                                                : 
                                                                    <></>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{certifications?.certifications?.RecordFound?.toLocaleString()}   Certifications&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(certifications?.certifications?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(certifications?.certifications?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {certifications?.certifications?.PagingMetaData?.PageNumber}  of  {certifications?.certifications?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      certifications: state.entity.certifications, 
      userattributelookups: state.entity.userattributelookups,
      orgLearningObjectTree: state.entity.orgLearningObjectTree,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCertifications: (kParams) => dispatch(getCertifications(kParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertCertification: (oParams) => dispatch(insertCertification(oParams)),
        deleteCertification: (oParams) => dispatch(deleteCertification(oParams)),
        updateCertification: (oParams) => dispatch(updateCertification(oParams)),
        getNotificationMessages: (oParams) => dispatch(getNotificationMessages(oParams)),
        getReminders: (oParams) => dispatch(getReminders(oParams)),
        getCertificationsLookup: (kParams) => dispatch(getCertificationsLookup(kParams)),
        getKnowledgesLookup: (kParams) => dispatch(getKnowledgesLookup(kParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificationList)