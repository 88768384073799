import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, ListGroup, Badge, Table, Image, ButtonGroup, ToggleButton, Form, Modal, CardBody, FormControl } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getUserAttributesLookups, assignLookupSelection, getTestLibraries, insertTestLibrary, deleteTestLibrary, updateTestLibrary, getContents, 
    insertTestInstance, deleteTestInstance, updateTestInstance, insertTestQuestion, deleteTestQuestion, updateTestQuestion, insertTestAnswer, deleteTestAnswer, updateTestAnswer } from '../../store/actions/entityActions';
import { getUserLookup } from '../../store/actions/authActions';
import { connect, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { UserLookup, Rights, MenuType } from '../../config/Constant';
import { getPermissionFlags } from '../layout/Utils';
import ContentList from './ContentList';
import UserLookupList from './UserLookupList';

const TestLibraryList = (props) => {
    const { auth, companyData, testlibrary, userattributelookups, fromLookupParam, lookupselections, loadingMenu } = props;
    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    const testlibraries = testlibrary?.testlibrary?.TestLibrary;
    const { TestInstance, TestQuestion, TestAnswer, TestLibraryOwnership } = testlibrary?.testlibrary || {};

    const vendorData = userattributelookups?.Vendors;
    const wbtTypeData = userattributelookups?.WBTTypes;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showDeleteAlertModal, setshowDeleteAlertModal ] = useState(false);
    const [deleteSuggestion, setdeleteSuggestion] = useState([]);
    const [nodeName, setnodeName] = useState('');
    const [requirementTypeClicked, setrequirementTypeClicked] = useState(-1);
    const [selectedValue, setSelectedValue] = useState('');
    const [addNewTestLibrary, setAddNewTestLibrary] = useState(false);
    const [editTestLibrary, setEditTestLibrary] = useState(false);
    const [testID, setTestID] = useState(-1);
    const [testName, setTestName] = useState('');
    const [testLibraryVendor, setTestLibraryVendor] = useState(-1);
    const [testLibraryExternal, setTestLibraryExternal] = useState(-1);
    const [testLibraryPublic, setTestLibraryPublic] = useState(-1);
    const [testLibraryOwnershipType, setTestLibraryOwnershipType] = useState(-1);
    const [testLibraryOnlineType, setTestLibraryOnlineType] = useState(-1);
    const [testLibraryOnlineURL, setTestLibraryOnlineURL] = useState('');
    const [testLibrarySCORMS, setTestLibrarySCORMS] = useState('');
    const [testLibrarySCORMNames, setTestLibrarySCORMNames] = useState('');
    const [showContentList, setShowContentList] = useState(false);
    const [testLibraryOwnerships, setTestLibraryOwnerships] = useState('');    
    const [testLibraryUserArray, setTestLibraryUserArray] = useState([]);
    const [testLibraryUserDetails, setTestLibraryUserDetails] = useState('');
    const [showUserList, setShowUserList] = useState(false);

    const [addNewTestInstance, setAddNewTestInstance] = useState(false);
    const [editTestInstance, setEditTestInstance] = useState(false);
    const [testInstanceID, setTestInstanceID] = useState(-1);
    const [testInstanceName, setTestInstanceName] = useState('');
    const [testInstanceActive, setTestInstanceActive] = useState(-1);
    const [testInstancePublic, setTestInstancePublic] = useState(-1);
    const [testInstanceRandomizeQuestions, setTestInstanceRandomizeQuestions] = useState(-1);
    const [testInstanceUseAllQuestions, setTestInstanceUseAllQuestions] = useState(-1);
    const [testInstanceAttempts, setTestInstanceAttempts] = useState(null);
    const [testInstancePassingScore, setTestInstancePassingScore] = useState(null);
    const [testInstanceMaximumQuestions, setTestInstanceMaximumQuestions] = useState(null);
    const [testInstanceDaysBeforeRetake, setTestInstanceDaysBeforeRetake] = useState(null);
    const [testInstanceTimeAllowed, setTestInstanceTimeAllowed] = useState(null);
    const [testInstanceReviewOnCompletion, setTestInstanceReviewOnCompletion] = useState(-1);
    const [testInstanceQuestionPerPage, setTestInstanceQuestionPerPage] = useState(-1);
    const [testInstanceShowFeedback, setTestInstanceShowFeedback] = useState(-1);
    const [testInstanceEnforceAttemptsLogic, setTestInstanceEnforceAttemptsLogic] = useState(-1);
    const [testInstancePermitReview, setTestInstancePermitReview] = useState(-1);

    const [testQuestionID, setTestQuestionID] = useState(-1);
    const [addNewTestQuestion, setAddNewTestQuestion] = useState(false);
    const [editTestQuestion, setEditTestQuestion] = useState(false);
    const [testQuestionText, setTestQuestionText] = useState('');
    const [testQuestionComments, setTestQuestionComments] = useState(null);
    const [testQuestionRandomizeAnswers, setTestQuestionRandomizeAnswers] = useState(-1);
    const [testQuestionDisplayOrder, setTestQuestionDisplayOrder] = useState(null);

    const [testAnswerID, setTestAnswerID] = useState(-1);
    const [addNewTestAnswer, setAddNewTestAnswer] = useState(false);
    const [editTestAnswer, setEditTestAnswer] = useState(false);
    const [testAnswerText, setTestAnswerText] = useState('');
    const [testAnswerValue, setTestAnswerValue] = useState(null);
    const [testAnswerCorrect, setTestAnswerCorrect] = useState(-1);
    const [testAnswerType, setTestAnswerType] = useState(-1);
    const [testAnswerDisplayOrder, setTestAnswerDisplayOrder] = useState(null);

    async function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }
    
    async function searchTestLibrary() {
        setLoading(true);
        const oParams = {
            TestName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getTestLibraries(oParams);
        setLoading(false);
    }
    
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = testlibrary?.testlibrary?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            TestName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getTestLibraries(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = testlibrary?.testlibrary?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            TestName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };           
        await props.getTestLibraries(oParams);
        setLoading(false);
    }

    const handleCloseContentList = () => {
        setShowContentList(false);
        updateContentSelection();
    }

    async function handleShowContentList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getContents(oParams);

        setShowContentList(true); 
        setLoading(false);
    };

    function updateContentSelection() {
        var sSelections = lookupselections?.Content;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections.split('&');
            oSelections.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
            });
            setTestLibrarySCORMS(sSelectedIds);
            setTestLibrarySCORMNames(sSelectedNames);
        }
    }

    const handleCloseUserList = () => {
        setShowUserList(false);
        updateUserSelection();
    }

    async function handleShowUserList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.Preceptor,
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getUserLookup(oParams);

        setShowUserList(true); 
        setLoading(false);
    };

    function updateUserSelection() {
        var sSelections = lookupselections?.User;
        if (testLibraryUserDetails !== '') {
            sSelections = testLibraryUserDetails + '&' + sSelections;
        }
        if (testLibraryOwnerships !== '') {
            sSelections = testLibraryOwnerships + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections.forEach(element => {
                if (element.trim() !== '') {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element?.split('=')[0];
                    sSelectedNames += element.split('=')[1].split('|')[0];
                }
            });
            setTestLibraryUserDetails(sSelections);
            setTestLibraryUserArray(oSelections);
            setTestLibraryOwnerships(sSelectedIds);
        }
    }

    function handleDeleteAssignedUserClick(goal) {
        var tempSkills = testLibraryUserArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setTestLibraryUserArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setTestLibraryUserDetails(sSelectedDetails);
            setTestLibraryOwnerships(sSelectedIds);
        }        
    }

    function handleDeleteAlertModalClose() { 
        setshowDeleteAlertModal(false);
    }
    
    function handleDeleteAlertModalShow(suggestion) { 
        setshowDeleteAlertModal(true);
        setdeleteSuggestion(suggestion);
    }

    async function handleDeleteAlertModalYes() { 
        setshowDeleteAlertModal(false);
    }

    function handleDeleteAlertModalNo() { 
        setshowDeleteAlertModal(false);
    }    

    let currentRecIndex = 0;

    const [sliderValue, setSliderValue] = useState([]);
    const [subSliderValue, setSubSliderValue] = useState([]);

    const addInitialSliderArray = (oList) => {
        return testlibraries?.map((oDetail) => ({
            TestID:oDetail.TestID, 
            TestName: oDetail.TestName,
            addTestClicked: false,
            editTestClicked: false,
            addTestInstanceClicked: false,
            editTestInstanceClicked: false,
            addTestQuestionClicked: false,
            editTestQuestionClicked: false,
            addTestAnswerClicked: false,
            editTestAnswerClicked: false,
            takeAction:false,
            showDetails:false,
        }))
    }
        
    const addInitialSubSliderArray = (oList) => {
        return TestInstance?.map((oDetail) => ({
            TestID:oDetail.TestID, 
            TestName: oDetail.TestName,
            TestInstanceID:oDetail.TestInstanceID, 
            TestInstanceName: oDetail.TestInstanceName,
            showDetails:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(testlibraries);
        setSliderValue(initialScoreValues);
    }, [testlibraries])

    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSubSliderArray(TestInstance);
        setSubSliderValue(initialScoreValues);
    }, [TestInstance])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;
        sliderValue && sliderValue?.map((row, index) => {
            if ((row) && (parseInt(row?.TestID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
  
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.TestID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function getSubDetailSwitchValue(rowIndex) {
        let bReturn = false;
        subSliderValue && subSliderValue.map((row, index) => {
            if ((row) && (parseInt(row.TestInstanceID) === parseInt(rowIndex))) {
                bReturn = row.showDetails;
            }
        });
        return bReturn;
    }
  
    function handleSwitchSubDetailChange(rowIndex, rowName) {
        const updatedSubSliderValues = subSliderValue.slice(0);
        updatedSubSliderValues.map((row, index) => {
            if (row.TestInstanceID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSubSliderValue(updatedSubSliderValues);
        getSelectedValue();
    }

     function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        subSliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.TestID + '|' + row.TestInstanceID + '=' + row.TestInstanceName;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(MenuType.Assessment, currentSelectedValue);
        return currentSelectedValue;
    }

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);
        if (tab === 1) {
            (addNewTestLibrary) ? setAddNewTestLibrary(false) : setAddNewTestLibrary(true);
            setTestID(-1);
        } 

        setLoading(false);
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewTestLibrary) ? setAddNewTestLibrary(false) : setAddNewTestLibrary(true);
            setAddNewTestInstance(false);
            setAddNewTestQuestion(false);
            setAddNewTestAnswer(false);
        } else if (tab === 2) {
            (addNewTestInstance) ? setAddNewTestInstance(false) : setAddNewTestInstance(true);
            setAddNewTestLibrary(false);
            setAddNewTestQuestion(false);
            setAddNewTestAnswer(false);
        } else if (tab === 3) {
            (addNewTestQuestion) ? setAddNewTestQuestion(false) : setAddNewTestQuestion(true);
            setAddNewTestInstance(false);
            setAddNewTestLibrary(false);
            setAddNewTestAnswer(false);
        }  else if (tab === 4) {
            (addNewTestAnswer) ? setAddNewTestAnswer(false) : setAddNewTestAnswer(true);
            setTestQuestionID(rowIndex);
            setAddNewTestInstance(false);
            setAddNewTestQuestion(false);
            setAddNewTestLibrary(false);
        } 

        setLoading(false);
    }

    async function handleSaveChildernClick(tab, currentJP) {
        setLoading(true);
        const oParams = {
            TestName:nodeName,
            RecordSize:'10',
            PageNumber:1,
        };            

        if (tab === 1) {
            const oIParams = {
                TestID: testID,
                TestName: testName,
                ScormTest: (testLibraryExternal === '1' ? true : false),
                VendorID: testLibraryVendor,
                ScormID: testLibrarySCORMS,
                WBTTypeID: testLibraryOnlineType,
                WBTURL: testLibraryOnlineURL,
                IsPublic: testLibraryPublic,
                OwnershipID: testLibraryOwnershipType,
                TestLibraryOwnerships: testLibraryOwnerships,
                IsDeleted: false,
                CreatedByUserID:auth?.UserID,
                UpdatedByUserID:auth?.UserID
            }
            if (addNewTestLibrary) {
                console.log(oIParams);
                await props.insertTestLibrary(oIParams);
                setAddNewTestLibrary(false);
            } else if (editTestLibrary) {
                console.log('Update Test Library - ', oIParams);
                await props.updateTestLibrary(oIParams);
                setEditTestLibrary(false);
            }
            await props.getTestLibraries(oParams);
            ClearStateFields(tab);
        } 
        else if (tab === 2) {
            const oIParams = {
                TestID: testID,
                TestInstanceID: testInstanceID, 
                TestInstanceName: testInstanceName,
                IsTestInstanceActive: (testInstanceActive == '1' ? true : false),
                IsPublic: (testInstancePublic == '1' ? true : false),
                RandomizeQuestions: (testInstanceRandomizeQuestions == '1' ? true : false),
                UseAllQuestions: (testInstanceUseAllQuestions == '1' ? true : false),
                NumberofFalseStartsAllowed: testInstanceAttempts,                
                PassingScore: testInstancePassingScore,
                MaxQuestions: testInstanceMaximumQuestions,
                DaysBeforeRetake: testInstanceDaysBeforeRetake,
                TimeLimitMinutes: testInstanceTimeAllowed,
                ShowCorrectForReview: (testInstanceReviewOnCompletion == '1' ? true : false),
                ShowOneQuestionPerPage: (testInstanceQuestionPerPage == '1' ? true : false),
                ShowCorrectForReview: (testInstanceShowFeedback == '1' ? true : false),
                EnforceRetakeLogic: (testInstanceEnforceAttemptsLogic == '1' ? true : false),
                PermitReview: (testInstancePermitReview == '1' ? true : false),
                CreatedByUserID:auth?.UserID,
                UpdatedByUserID:auth?.UserID
            }
            if (addNewTestInstance) {
                console.log(oIParams);
                await props.insertTestInstance(oIParams);
                setAddNewTestInstance(false);
            } else if (editTestInstance) {
                console.log('Update Test Instance - ', oIParams);
                await props.updateTestInstance(oIParams);
                setEditTestInstance(false);
            }
            await props.getTestLibraries(oParams);
            ClearStateFields(tab);
        } else if (tab === 3) {
            const oIParams = {
                TestID: testID,
                TestQuestionID: testQuestionID, 
                TestQuestionText: testQuestionText,
                HelpText: testQuestionComments,
                RandomizeAnswers: (testQuestionRandomizeAnswers == '1' ? true : false),
                DisplayOrder: testQuestionDisplayOrder,
                CreatedByUserID:auth?.UserID,
                UpdatedByUserID:auth?.UserID
            }
            if (addNewTestQuestion) {
                console.log(oIParams);
                await props.insertTestQuestion(oIParams);
                setAddNewTestQuestion(false);
            } else if (editTestQuestion) {
                console.log('Update Test Question - ', oIParams);
                await props.updateTestQuestion(oIParams);
                setEditTestQuestion(false);
            }
            await props.getTestLibraries(oParams);
            ClearStateFields(tab);
        } else if (tab === 4) {
            const oIParams = {
                TestQuestionID: testQuestionID, 
                TestAnswerID: testAnswerID, 
                AnswerText: testAnswerText,
                AnswerValue: testAnswerValue,
                AnswerCorrect: (testAnswerCorrect == '1' ? true : false),
                DisplayOrder: testAnswerDisplayOrder,
                AnswerTypeID: testAnswerType,
                CreatedByUserID:auth?.UserID,
                UpdatedByUserID:auth?.UserID
            }
            if (addNewTestAnswer) {
                console.log(oIParams);
                await props.insertTestAnswer(oIParams);
                setAddNewTestAnswer(false);
            } else if (editTestAnswer) {
                console.log('Update Test Answer - ', oIParams);
                await props.updateTestAnswer(oIParams);
                setEditTestAnswer(false);
            }
            await props.getTestLibraries(oParams);
            ClearStateFields(tab);
        }
        setLoading(false);
    }

    async function handleDeleteChildernClick(tab, currentJP, id, otherId) {
        setLoading(true);
        const oParams = {
            TestName:nodeName,
            RecordSize:'10',
            PageNumber:1,
        };            

        if (tab === 1) {
            const oDParams = {
                TestID: id,
            }
            console.log(oDParams);
            await props.deleteTestLibrary(oDParams);
            await props.getTestLibraries(oParams);
        } else if (tab === 2) {
            const oDParams = {
                TestInstanceID: id,
            }
            console.log(oDParams);
            await props.deleteTestInstance(oDParams);
            await props.getTestLibraries(oParams);
        } else if (tab === 3) {
            const oDParams = {
                TestQuestionID: id,
            }
            console.log(oDParams);
            await props.deleteTestQuestion(oDParams);
            await props.getTestLibraries(oParams);
        } else if (tab === 4) {
            const oDParams = {
                TestAnswerID: id,
            }
            console.log(oDParams);
            await props.deleteTestAnswer(oDParams);
            await props.getTestLibraries(oParams);
        }

        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const assEditedRec = testlibraries?.filter((assRec) => (assRec.TestID === id));
            console.log(assEditedRec);
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setTestID(id);
                setTestName(assEditedRec[0]?.TestName);
                setTestLibraryExternal((assEditedRec[0]?.ScormTest) ? '1' : '0');
                setTestLibraryVendor(assEditedRec[0]?.VendorID);
                setTestLibrarySCORMS(assEditedRec[0]?.ScormID);
                setTestLibrarySCORMNames(assEditedRec[0]?.ScormName);
                setTestLibraryOnlineType(assEditedRec[0]?.WBTTypeID);
                setTestLibraryOnlineURL(assEditedRec[0]?.WBTUrl);
                setTestLibraryPublic((assEditedRec[0]?.IsPublic) ? '1' : '0');
                setTestLibraryOwnershipType(assEditedRec[0]?.OwnershipID);
    
                let ownershipIDs = '';
                let ownershipNames = '';
                let ownershipDetails = '';
                const ownershipRec = TestLibraryOwnership?.filter((cRec) => (cRec.TestID === id));
                ownershipRec?.map((row, index) => {
                    if (ownershipIDs.trim() !== '') {
                        ownershipIDs += ',';
                    }
                    if (ownershipDetails.trim() !== '') {
                        ownershipDetails += '&';
                    }
        
                    ownershipIDs += row?.OwnerUserID;
                    ownershipDetails += row?.OwnerUserID + '=' + row?.OwnerUserFullName + '|' + row?.OwnerVendorUserID + '|' + row?.OwnerEmail;
                });
                if (ownershipIDs.trim() !== '') {
                    setTestLibraryOwnerships(ownershipIDs);
                }
                if (ownershipDetails.trim() !== '') {
                    const oSelections = ownershipDetails?.split('&');
                    setTestLibraryUserArray(oSelections);
                    setTestLibraryUserDetails('ownershipDetails');
                }        
                setEditTestLibrary(true);
            }    
        } else if (tab === 2) {
            const assEditedRec = TestInstance?.filter((assRec) => (assRec.TestInstanceID === id));
            console.log(assEditedRec);
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setTestID(assEditedRec[0]?.TestID);
                setTestInstanceID(assEditedRec[0]?.TestInstanceID);
                setTestInstanceName(assEditedRec[0]?.TestInstanceName);
                setTestInstanceActive((assEditedRec[0]?.IsTestInstanceActive) ? '1' : '0');
                setTestInstancePublic((assEditedRec[0]?.IsPublic) ? '1' : '0');
                setTestInstanceRandomizeQuestions((assEditedRec[0]?.RandomizeQuestions) ? '1' : '0');
                setTestInstanceUseAllQuestions((assEditedRec[0]?.UseAllQuestions) ? '1' : '0');
                setTestInstanceAttempts(assEditedRec[0]?.NumberofFalseStartsAllowed);
                setTestInstancePassingScore(assEditedRec[0]?.PassingScore);
                setTestInstanceMaximumQuestions(assEditedRec[0]?.MaxQuestions);
                setTestInstanceDaysBeforeRetake(assEditedRec[0]?.DaysBeforeRetake);
                setTestInstanceTimeAllowed(assEditedRec[0]?.TimeLimitMinutes);
                setTestInstanceReviewOnCompletion((assEditedRec[0]?.ShowCorrectForReview) ? '1' : '0');
                setTestInstanceQuestionPerPage((assEditedRec[0]?.ShowOneQuestionPerPage) ? '1' : '0');
                setTestInstanceShowFeedback((assEditedRec[0]?.ShowCorrectForReview) ? '1' : '0');
                setTestInstanceEnforceAttemptsLogic((assEditedRec[0]?.EnforceRetakeLogic) ? '1' : '0');
                setTestInstancePermitReview((assEditedRec[0]?.PermitReview) ? '1' : '0');
                setEditTestInstance(true);
            }
        } else if (tab === 3) {
            const assEditedRec = TestQuestion?.filter((assRec) => (assRec.TestQuestionID === id));
            console.log(assEditedRec);
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setTestID(assEditedRec[0]?.TestID);
                setTestQuestionID(assEditedRec[0]?.TestQuestionID);
                setTestQuestionText(assEditedRec[0]?.TestQuestionText);
                setTestQuestionComments(assEditedRec[0]?.HelpText);
                setTestQuestionRandomizeAnswers((assEditedRec[0]?.RandomizeAnswers) ? '1' : '0');
                setTestQuestionDisplayOrder(assEditedRec[0]?.DisplayOrder);
                setEditTestQuestion(true);
            }
        } else if (tab === 4) {
            const assEditedRec = TestAnswer?.filter((assRec) => (assRec.TestAnswerID === id));
            console.log(assEditedRec);
            if ((assEditedRec) && (assEditedRec.length > 0)) {
                setTestQuestionID(assEditedRec[0]?.TestQuestionID);
                setTestAnswerID(assEditedRec[0]?.TestAnswerID);
                setTestAnswerText(assEditedRec[0]?.AnswerText);
                setTestAnswerValue(assEditedRec[0]?.AnswerValue);
                setTestAnswerCorrect((assEditedRec[0]?.AnswerCorrect) ? '1' : '0');
                setTestAnswerDisplayOrder(assEditedRec[0]?.DisplayOrder);
                setTestAnswerType(assEditedRec[0]?.AnswerTypeID);
                setEditTestAnswer(true);
            }
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(tab) {
        if (tab === 1) {
            if (testID === -1) {
                setAddNewTestLibrary(false);
            } else {
                setEditTestLibrary(false);
            }    
        } else if (tab === 2) {
            if (testInstanceID === -1) {
                setAddNewTestInstance(false);
            } else {
                setEditTestInstance(false);
            }    
        } else if (tab === 3) {
            if (testQuestionID === -1) {
                setAddNewTestQuestion(false);
            } else {
                setEditTestQuestion(false);
            }    
        } else if (tab === 4) {
            if (testAnswerID === -1) {
                setAddNewTestAnswer(false);
            } else {
                setEditTestAnswer(false);
            }    
        }
        ClearStateFields(tab);
    }
    
    async function ClearStateFields(tab) {
        if (tab === 1) {
            setTestID(-1);
            setTestName('');    
            setTestLibraryExternal(-1);
            setTestLibraryVendor(-1);
            setTestLibrarySCORMS('');
            setTestLibraryOnlineType(-1);
            setTestLibraryOnlineURL('');
            setTestLibraryPublic(-1);
            setTestLibraryOwnershipType(-1);
            setTestLibraryOwnerships('');
            setTestLibraryUserDetails('');
            setTestLibraryUserArray([]);
            setTestLibrarySCORMNames('');
        } else if (tab === 2) {
            setTestInstanceID(-1);
            setTestInstanceName('');
            setTestInstanceActive(-1);
            setTestInstancePublic(-1);
            setTestInstanceRandomizeQuestions(-1);
            setTestInstanceUseAllQuestions(-1);
            setTestInstanceAttempts(null);
            setTestInstancePassingScore(null);
            setTestInstanceMaximumQuestions(null);
            setTestInstanceDaysBeforeRetake(null);
            setTestInstanceTimeAllowed(null);
            setTestInstanceReviewOnCompletion(-1);
            setTestInstanceQuestionPerPage(-1);
            setTestInstanceShowFeedback(-1);
            setTestInstanceEnforceAttemptsLogic(-1);
            setTestInstancePermitReview(-1);
        } else if (tab === 3) {
            setTestQuestionID(-1);
            setTestQuestionText('');
            setTestQuestionComments(null);
            setTestQuestionRandomizeAnswers(-1);
            setTestQuestionDisplayOrder(null);
        } else if (tab === 4) {
            setTestAnswerID(-1);
            setTestAnswerText('');
            setTestAnswerValue(null);
            setTestAnswerCorrect(-1);
            setTestAnswerDisplayOrder(null);
            setTestAnswerType(-1);
        }
    }

    function displayHeader() {
        return (
            <Table responsive="lg" >
                <thead>
                <tr>
                    <th style={{ textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}> <h3 style={styles.SubHeader}>Test Library</h3>
                    </th>
                    <th style={{ textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                        <div className='input-field'>
                            <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Test Library'/>
                            <a onClick={() => searchTestLibrary()}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                            </a>
                            {(permissionFlags.CanCreate) ? 
                                (addNewTestLibrary) ? 
                                    <a onClick={() => handleAddChildernClick(1, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Test Library'>shadow_minus</span>
                                    </a>
                                    :
                                    <a onClick={() => handleAddChildernClick(1, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Test Library'>note_add</span>
                                    </a>
                            : 
                                <></>
                            }
                        </div>                                            
                    </th>
                </tr>
                </thead>
            </Table>
        );
    }

    function displayAddButtons(tabValue, currentJP) {
        const oRequestList = sliderValue.filter((oItem) => oItem.TestID === currentJP.TestID);

        if (tabValue === 1 ) {
            if (oRequestList[0]?.newTestLibraryClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(1, currentJP.TestID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Test Library'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(1, currentJP.TestID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Test Library'>note_add</span>
                    </a>
                );
            }
        } else if (tabValue === 2 ) {
            if (oRequestList[0]?.newTestInstanceClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(2, currentJP.TestID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Test Instance'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(2, currentJP.TestID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Test Instance'>note_add</span>
                    </a>
                );
            }
        } else if (tabValue === 3 ) {        
            if (oRequestList[0]?.newTestQuestionClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(3, currentJP.TestID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Test Question'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(3, currentJP.TestID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Test Question'>note_add</span>
                    </a>
                );
            }
        } else if (tabValue === 4 ) {        
            if (oRequestList[0]?.newTestAnswerClicked) {
                return (
                    <a onClick={() => handleAddChildernClick(4, currentJP.TestQuestionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Test Answer'>shadow_minus</span>
                    </a>
                );
            } else {
                return (
                    <a onClick={() => handleAddChildernClick(4, currentJP.TestQuestionID)}>
                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Test Answer'>note_add</span>
                    </a>
                );
            }
        } 
    }

    function displayNewTestLibrary() {
        if ((addNewTestLibrary) || (editTestLibrary)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewTestLibrary) ? 'New Test Library' : 'Edit Test Library'}                        
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="testname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                        <input id="testname" type="text" style={styles.TextDataEntryNoPadding} value={testName} onChange={(e) => (setTestName(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <label htmlFor="vendor" style={styles.LabelDataEntryNoPaddingWBG}>Vendor</label>
                                        <select id="vendor" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testLibraryVendor} onChange={(e) => (setTestLibraryVendor(e.target.value))}>
                                            <option value="-1">Select One</option>
                                            {vendorData?.map((oItem, iIndex) => (
                                                <option id={oItem?.VendorID} value={oItem?.VendorID}>{oItem.VendorName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                            </tr>
                            {/* <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="public" style={styles.LabelDataEntryNoPaddingWBG}>Public</label>
                                    <select id="public" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testLibraryPublic} onChange={(e) => (setTestLibraryPublic(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                            </tr> */}
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="external" style={styles.LabelDataEntryNoPaddingWBG}>External</label>
                                    <select id="external" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testLibraryExternal} onChange={(e) => (setTestLibraryExternal(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="onlinetype" style={styles.LabelDataEntryNoPadding}>Online Type</label>
                                    <select id="onlinetype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testLibraryOnlineType} onChange={(e) => (setTestLibraryOnlineType(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        {wbtTypeData?.map((oItem, iIndex) => (
                                            <option id={oItem?.WBTTypeID} value={oItem?.WBTTypeID}>{oItem.WBTTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => setShowContentList(true)}>
                                        <label style={styles.LabelDataEntryNoPadding}>
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Content List'>mystery</span>                                    
                                            Content
                                        </label>
                                    </a>
                                    <input id="scormid" type="text" disabled style={styles.TextDataEntryNoPadding} value={testLibrarySCORMNames}/>                                    
                                </td>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="onlineURL" style={styles.LabelDataEntryNoPadding}>Online URL</label>
                                    <input id="onlineURL" type="text" style={styles.TextDataEntryNoPadding} value={testLibraryOnlineURL} onChange={(e) => (setTestLibraryOnlineURL(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='3' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <a onClick={() => setShowUserList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Ownership List'>mystery</span>                                    
                                                Ownership
                                            </label>
                                        </a>
                                        {displayAssignedUsers()}
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="ownershiptype" style={styles.LabelDataEntryNoPaddingWBG}>Ownership Type</label>
                                    <select id="ownershiptype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testLibraryOwnershipType} onChange={(e) => (setTestLibraryOwnershipType(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Public</option>        
                                        <option value='2'>Private</option>        
                                        <option value='3'>Others</option>        
                                    </select>                                     
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Test Library'>Save</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Test Library'>Cancel</Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayAssignedUsers() {
        if (testLibraryUserArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {testLibraryUserArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedUserClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Ownership'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayNewTestInstance(currentItem) {
        if ((addNewTestInstance) || (editTestInstance)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewTestInstance) ? 'New Test Instance' : 'Edit Test Instance'}                        
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="testinstancename" style={styles.LabelDataEntryNoPadding}>Name</label>
                                        <input id="testinstancename" type="text" style={styles.TextDataEntryNoPadding} value={testInstanceName} onChange={(e) => (setTestInstanceName(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="instanceactive" style={styles.LabelDataEntryNoPaddingWBG}>Active</label>
                                    <select id="instanceactive" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceActive} onChange={(e) => (setTestInstanceActive(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="instancepublic" style={styles.LabelDataEntryNoPaddingWBG}>Public</label>
                                    <select id="instancepublic" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstancePublic} onChange={(e) => (setTestInstancePublic(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="useallW" style={styles.LabelDataEntryNoPaddingWBG}>Use All Questions</label>
                                    <select id="useallQ" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceUseAllQuestions} onChange={(e) => (setTestInstanceUseAllQuestions(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="maxq" style={styles.LabelDataEntryNoPadding}>Maximum Questions</label>
                                    <input id="maxq" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={testInstanceMaximumQuestions} onChange={(e) => (setTestInstanceMaximumQuestions(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="randomizeQ" style={styles.LabelDataEntryNoPaddingWBG}>Randomize Questions</label>
                                    <select id="randomizeQ" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceRandomizeQuestions} onChange={(e) => (setTestInstanceRandomizeQuestions(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="passingscore" style={styles.LabelDataEntryNoPadding}>Passing Score</label>
                                    <input id="passingscore" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={testInstancePassingScore} onChange={(e) => (setTestInstancePassingScore(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="useallQ" style={styles.LabelDataEntryNoPaddingWBG}>Enforce Attempt Logic</label>
                                    <select id="useallQ" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceEnforceAttemptsLogic} onChange={(e) => (setTestInstanceEnforceAttemptsLogic(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="attempts" style={styles.LabelDataEntryNoPadding}>Number of Attempts</label>
                                    <input id="attempts" type="number" step='1' min='1' max='1000' style={styles.TextDataEntryNoPadding} value={testInstanceAttempts} onChange={(e) => (setTestInstanceAttempts(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="daysbeforeretake" style={styles.LabelDataEntryNoPadding}>Days Before Retake</label>
                                    <input id="daysbeforeretake" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={testInstanceDaysBeforeRetake} onChange={(e) => (setTestInstanceDaysBeforeRetake(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="timeallowed" style={styles.LabelDataEntryNoPadding}>Time Allowed(In Minutes)</label>
                                    <input id="timeallowed" type="number" step='1' min='1' max='100' style={styles.TextDataEntryNoPadding} value={testInstanceTimeAllowed} onChange={(e) => (setTestInstanceTimeAllowed(e.target.value))}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="reviewC" style={styles.LabelDataEntryNoPaddingWBG}>Review on Completion</label>
                                    <select id="reviewC" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceReviewOnCompletion} onChange={(e) => (setTestInstanceReviewOnCompletion(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="permitreview" style={styles.LabelDataEntryNoPaddingWBG}>Permit Review</label>
                                    <select id="permitreview" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstancePermitReview} onChange={(e) => (setTestInstancePermitReview(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="questionperpage" style={styles.LabelDataEntryNoPaddingWBG}>Each Question Per Page</label>
                                    <select id="questionperpage" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceQuestionPerPage} onChange={(e) => (setTestInstanceQuestionPerPage(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="showfeedback" style={styles.LabelDataEntryNoPaddingWBG}>Show Feedback</label>
                                    <select id="showfeedback" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testInstanceShowFeedback} onChange={(e) => (setTestInstanceShowFeedback(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(2, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Test Instance'>Save</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(2)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Test Instance'>Cancel</Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayTestInstances(currentItem) {
        const oChildrens = TestInstance?.filter((oItem) => (oItem.TestID === currentItem.TestID));

        return (
            (oChildrens?.length === 0) ? <>{displayNewTestInstance(currentItem)}</> : 
            <>
            {displayNewTestInstance(currentItem)}
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Name</th>
                        <th style={styles.tableText}>Passing Score</th>
                        <th style={styles.tableText}>Randomize</th>
                        <th style={styles.tableText}>Action</th>
                    </tr>
                </thead>                        
                <tbody> 
                {oChildrens.map((oItem, iIndex) => (
                    <tr> 
                        <td style={styles.tableText}>{oItem?.TestInstanceID}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.TestInstanceName}</td> 
                        <td style={styles.tableText}>{oItem?.PassingScore}</td> 
                        <td style={styles.tableText}>{(oItem?.RandomizeQuestions === true) ? 'Yes' : 'No' }</td> 
                        <td style={styles.tableText}>
                            {(fromLookup) ? 
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" 
                                        checked={getSubDetailSwitchValue(oItem?.TestInstanceID)} id={`switch${'detail' + oItem?.TestInstanceID}`} 
                                        style={(getSubDetailSwitchValue(oItem?.TestInstanceID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                        onChange={(e) => {handleSwitchSubDetailChange(oItem?.TestInstanceID, oItem?.TestInstanceName)}}>
                                    </input>
                                    <label class="form-check-label" for={`switch${'detail' + oItem?.TestInstanceID}`}>Select</label>
                                </div>
                            :
                                <div>
                                    <a onClick={() => handleUpdateChildernClick(2, oItem?.TestInstanceID)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Test Instance'>edit</span>
                                    </a>
                                    <a onClick={() => handleDeleteChildernClick(2, oItem, oItem?.TestInstanceID)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Test Instance'>delete</span>
                                    </a>
                                </div>
                            }
                        </td>
                    </tr> 
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function displayNewTestQuestion(currentItem) {
        if ((addNewTestQuestion) || (editTestQuestion)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewTestQuestion) ? 'New Test Question' : 'Edit Test Question'}                        
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="testquestiontext" style={styles.LabelDataEntryNoPadding}>Question</label>
                                        <FormControl as='textarea' rows={5} id="testquestiontext" style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} value={testQuestionText} onChange={(e) => (setTestQuestionText(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="testquestioncomments" style={styles.LabelDataEntryNoPadding}>Comments</label>
                                        <FormControl as='textarea' rows={5} id="testquestioncomments" style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} value={testQuestionComments} onChange={(e) => (setTestQuestionComments(e.target.value))}/>
                                    </div>                    
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="qorder" style={styles.LabelDataEntryNoPadding}>Order</label>
                                    <input id="qorder" type="number" step='1' min='1' max='10000' style={styles.TextDataEntryNoPadding} value={testQuestionDisplayOrder} onChange={(e) => (setTestQuestionDisplayOrder(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="randomizeA" style={styles.LabelDataEntryNoPaddingWBG}>Randomize Answers</label>
                                    <select id="randomizeA" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testQuestionRandomizeAnswers} onChange={(e) => (setTestQuestionRandomizeAnswers(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(3, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Test Question'>Save</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(3)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Test Question'>Cancel</Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayTestQuestions(currentItem) {
        const oChildrens = TestQuestion?.filter((oItem) => (oItem.TestID === currentItem.TestID));

        return (
            (oChildrens?.length === 0) ? <>{displayNewTestQuestion(currentItem)}</> : 
            <>
            {displayNewTestQuestion(currentItem)}
            <table class="table table-sm caption-top text-left">  
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableTextLeft}>Question</th>
                        <th style={styles.tableText}>Order</th>
                        <th style={styles.tableText}>Action</th>
                    </tr>
                </thead>                        
                <tbody> 
                {oChildrens.map((oItem, supIndex) => (
                    <>
                    <tr> 
                        <td style={styles.tableText}>{oItem?.TestQuestionID}</td> 
                        <td style={styles.tableTextLeft}>{oItem?.TestQuestionText}</td> 
                        <td style={styles.tableText}>{oItem?.DisplayOrder}</td> 
                        <td style={styles.tableText}>
                            <div>
                                <a onClick={() => handleUpdateChildernClick(3, oItem?.TestQuestionID)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Test Question'>edit</span>
                                </a>
                                <a onClick={() => handleDeleteChildernClick(3, oItem, oItem?.TestQuestionID)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Test Question'>delete</span>
                                </a>
                            </div>
                        </td>
                    </tr> 
                    <tr>
                        <td colSpan='4' style={styles.tableText}>
                            {displayTestAnswers(oItem)}
                        </td> 
                    </tr>
                    </>
                ))
                }                        
                </tbody>
            </table>                                   
            </>
        );
    }

    function displayNewTestAnswer(currentItem) {
        if ((addNewTestAnswer) || (editTestAnswer)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableSubHeaderCenterBold}>
                            {(addNewTestAnswer) ? 'New Test Answer' : 'Edit Test Answer'}                        
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <table class="table caption-top text-left">  
                        <tbody> 
                            <tr>
                                <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="testanswertext" style={styles.LabelDataEntryNoPadding}>Answer</label>
                                        <FormControl as='textarea' rows={5} id="testanswertext" style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} value={testAnswerText} onChange={(e) => (setTestAnswerText(e.target.value))}/>
                                    </div>                    
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="testanswercomments" style={styles.LabelDataEntryNoPadding}>Value</label>
                                        <FormControl as='textarea' rows={5} id="testanswercomments" style={{height:'120px', backgroundColor:companyData?.PagesBGColor}} value={testAnswerValue} onChange={(e) => (setTestAnswerValue(e.target.value))}/>
                                    </div>                    
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="aorder" style={styles.LabelDataEntryNoPadding}>Order</label>
                                    <input id="aorder" type="number" step='1' min='1' max='10000' style={styles.TextDataEntryNoPadding} value={testAnswerDisplayOrder} onChange={(e) => (setTestAnswerDisplayOrder(e.target.value))}/>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="acorrect" style={styles.LabelDataEntryNoPaddingWBG}>Correct</label>
                                    <select id="acorrect" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testAnswerCorrect} onChange={(e) => (setTestAnswerCorrect(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Yes</option>        
                                        <option value='0'>No</option>        
                                    </select>                                     
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <label htmlFor="atype" style={styles.LabelDataEntryNoPaddingWBG}>Type</label>
                                    <select id="atype" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} value={testAnswerType} onChange={(e) => (setTestAnswerType(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        <option value='1'>Radio Button</option>        
                                        <option value='2'>Text Box</option>        
                                        <option value='3'>Text Area</option>        
                                        <option value='4'>Check Box</option>        
                                    </select>                                     
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                    </CardBody>
                    <Card.Footer>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-auto">
                                <Button onClick={() => handleSaveChildernClick(4, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Test Question'>Save</Button>&nbsp;&nbsp;
                            </div>
                            <div class="col-auto">
                                <Button onClick={() => handleCancelChildernClick(4)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Test Question'>Cancel</Button>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            )
        }
    }

    function displayTestAnswers(currentItem) {
        const oChildrens = TestAnswer?.filter((oItem) => (oItem.TestQuestionID === currentItem.TestQuestionID));

        return (
            (oChildrens?.length === 0) ? <>{displayNewTestAnswer(currentItem)}</> : 
            <>
            {displayNewTestAnswer(currentItem)}
            <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Title style={styles.tableTextLeftBold}>                             
                            <table class="table table-sm caption-top">  
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th style={styles.tableTextLeft}>Answers</th>
                                        <th style={styles.tableTextRight}>{displayAddButtons(4, currentItem)}</th>
                                    </tr>
                                </thead>                        
                            </table>    
                        </Card.Title>
                    </CardHeader>                                        
                    <CardBody>
                        <table class="table table-sm caption-top text-left">  
                            <thead>
                                <tr>
                                    <th style={styles.tableText}>#</th>
                                    <th style={styles.tableTextLeft}>Answer</th>
                                    <th style={styles.tableText}>Type</th>
                                    <th style={styles.tableText}>Value</th>
                                    <th style={styles.tableText}>Correct</th>
                                    <th style={styles.tableText}>Order</th>
                                    <th style={styles.tableText}>Action</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                            {oChildrens.map((oItem, supIndex) => (
                                <tr> 
                                    <td style={styles.tableText}>{oItem?.TestAnswerID}</td> 
                                    <td style={styles.tableTextLeft}>{oItem?.AnswerText}</td> 
                                    <td style={styles.tableText}>{oItem?.AnswerTypeName}</td> 
                                    <td style={styles.tableText}>{oItem?.AnswerValue}</td> 
                                    <td style={styles.tableText}>{(oItem?.AnswerCorrect) ? 'Yes' : 'No'}</td> 
                                    <td style={styles.tableText}>{oItem?.DisplayOrder}</td> 
                                    <td style={styles.tableText}>
                                        <div>
                                            <a onClick={() => handleUpdateChildernClick(4, oItem?.TestAnswerID)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Test Answer'>edit</span>
                                            </a>
                                            <a onClick={() => handleDeleteChildernClick(4, oItem, oItem?.TestAnswerID)}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Test Answer'>delete</span>
                                            </a>
                                        </div>
                                    </td>
                                </tr> 
                            ))
                            }                        
                            </tbody>
                        </table>                                   
                    </CardBody>
            </Card>
            </>
        );
    }

    function getDetailSwitchTR(currentItem) {
        const oRequestList = sliderValue?.filter((oItem) => oItem.TestID === currentItem?.TestID);

        if ((!fromLookup) && (oRequestList) && (oRequestList.length > 0) && (oRequestList[0]?.showDetails)) {
                return (
                <>
                <tr id={`tr${'instdetail' + currentItem.TestID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Instances</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentItem)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayTestInstances(currentItem)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                <tr id={`tr${'quesdetail' + currentItem.TestID}`}>
                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Questions</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(3, currentItem)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                        {displayTestQuestions(currentItem)}
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
                </tr>                     
                </>
            );
        } else if ((fromLookup)  && (oRequestList) && (oRequestList.length > 0) && (oRequestList[0]?.showDetails)) {
            const oChildrens = TestInstance?.filter((oItem) => (oItem.TestID === currentItem.TestID));
            if (oChildrens?.length > 0) {
                return (
                    <tr id={`tr${'instdetail' + currentItem.TestID}`}>
                        <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                            <table class="table table-sm caption-top">  
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th style={styles.tableTextLeft}>Instances</th>
                                    </tr>
                                </thead>                        
                                <tbody> 
                                    <tr>
                                        <td colSpan="7" style={{ backgroundColor:companyData?.PagesBGColor}}> 
                                            {displayTestInstances(currentItem)}
                                        </td> 
                                    </tr>             
                                </tbody>
                            </table>    
                        </td> 
                    </tr>                     
                );    
            } else {
                return (<></>);
            }
        }
    }

    function displayTestLibraryHeader() {
        if (fromLookup) {
            return (
                <tr>
                <th style={styles.tableText}>#</th>
                <th style={styles.tableTextLeft}>Name</th>
                <th style={styles.tableTextLeft}>External</th>
                <th style={styles.tableTextLeft}>Instances</th>
                <th style={styles.tableTextLeft}>Questions</th>
            </tr>
            );
        } else {
            return (
            <tr>
                <th style={styles.tableText}>#</th>
                <th style={styles.tableTextLeft}>Name</th>
                <th style={styles.tableTextLeft}>External</th>
                <th style={styles.tableTextLeft}>Instances</th>
                <th style={styles.tableTextLeft}>Questions</th>
                <th style={styles.tableTextLeft}>Action</th>
            </tr>
            );
        }
    }

    function displayTestLibraryName(oRow) {
        if (fromLookup) {
            return (
                <>
                <tr>
                    <td style={styles.tableText}>{oRow.TestID}</td>
                    <td style={styles.tableTextMainColumn}>
                        {oRow?.TestName}
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" 
                                checked={getDetailSwitchValue(oRow?.TestID)} id={`switch${'detail' + oRow.TestID}`} 
                                style={(getDetailSwitchValue(oRow?.TestID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                onChange={(e) => {handleSwitchDetailChange(oRow?.TestID)}}>
                            </input>
                            <label class="form-check-label" for={`switch${'detail' + oRow?.TestID}`}>Details</label>
                        </div>
                    </td>
                    <td style={styles.tableTextLeft}>{(oRow?.ScormTest) ? 'Yes' : 'No'}</td>
                    <td style={styles.tableTextLeft}>{oRow?.InstanceCount?.toLocaleString()}</td>
                    <td style={styles.tableText}>{oRow?.QuestionCount?.toLocaleString()}</td>
                </tr>
                </>
            );
        } else {
            return (
                <>
                <tr>
                    <td style={styles.tableText}>{oRow.TestID}</td>
                    <td style={styles.tableTextMainColumn}>
                        {oRow?.TestName}
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" 
                                checked={getDetailSwitchValue(oRow?.TestID)} id={`switch${'detail' + oRow.TestID}`} 
                                style={(getDetailSwitchValue(oRow?.TestID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                onChange={(e) => {handleSwitchDetailChange(oRow?.TestID)}}>
                            </input>
                            <label class="form-check-label" for={`switch${'detail' + oRow?.TestID}`}>Details</label>
                        </div>                    
                    </td>
                    <td style={styles.tableTextLeft}>{(oRow?.ScormTest) ? 'Yes' : 'No'}</td>
                    <td style={styles.tableTextLeft}>{oRow?.InstanceCount?.toLocaleString()}</td>
                    <td style={styles.tableText}>{oRow?.QuestionCount?.toLocaleString()}</td>
                    <td style={styles.tableText}>
                        <div>
                            {(permissionFlags.CanModify) ? 
                                <>
                                <a onClick={() => handleUpdateChildernClick(1, oRow?.TestID)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Test Library'>edit</span>
                                </a>
                                {((permissionFlags.CanDelete) && (parseInt(oRow?.InstanceCount) === 0) && (parseInt(oRow?.QuestionCount) === 0)) ? 
                                    <a onClick={() => handleDeleteChildernClick(1, oRow, oRow?.TestID, null)}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Test Library'>delete</span>
                                    </a>
                                    :
                                    <></>
                                }
                                </>
                            : 
                                <></>
                            }
                        </div>
                    </td>
                </tr>
                </>
            );
        }
    }
    
    return ( 
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
            <Modal style={{background:'transparent', maxHeight:'100%'}} size='xl' aria-labelledby="contained-modal-title-vcenter" centered 
                show={showDeleteAlertModal} onHide={handleDeleteAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Do you want to delete this prediction?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>If you select 'Yes', it cannot be reverted. Please confirm.</p>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleDeleteAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleDeleteAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal> 

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showContentList} onShow={handleShowContentList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseContentList}>
                <Modal.Header closeButton>
                <Modal.Title>Lookup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContentList fromLookupParam={true}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseContentList}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showUserList} onShow={handleShowUserList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseUserList}>
                <Modal.Header closeButton>
                <Modal.Title>Lookup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseUserList}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Table style={{background:'transparent'}} >
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {(testlibraries) ? 
                                (
                                    <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                            {displayHeader()}
                                        </Card.Header>
                                        {(loading) ? (            
                                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                                    <Spinner/>
                                                </div>
                                            </Card.Body>
                                        ) 
                                        : 
                                        (                                          
                                            <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                {displayNewTestLibrary()}                                               
                                                {(testlibraries?.length === 0) ? (
                                                    <div style={styles.tableText}>
                                                        No Test Libraries.
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Table responsive="sm" striped bordered hover variant="light">
                                                            <thead>
                                                                {displayTestLibraryHeader()}
                                                            </thead>
                                                            <tbody>
                                                                {testlibraries && testlibraries.map((oItem, index) => {
                                                                    return (
                                                                        <>
                                                                        {displayTestLibraryName(oItem)}
                                                                        {getDetailSwitchTR(oItem)}
                                                                        </>
                                                                    )
                                                                }
                                                            ) 
                                                            }    

                                                            </tbody>
                                                        </Table>        
                                                    </div>
                                                )}
                                            </Card.Body>
                                        )}

                                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                                            <Table variant="light">
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                                            <h5 style={styles.tableTextLeft}>{testlibrary?.testlibrary?.RecordFound.toLocaleString()}   Test Libraries&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                                        </td>
                                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                                            {(loading) ? <></> : 
                                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                                <h5 style={styles.tableText}>
                                                                {(testlibrary?.testlibrary?.PagingMetaData?.PreviousPage) ? (
                                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                                            <span title='Previous Page'>Previous</span>
                                                                        </a>
                                                                ) 
                                                                : 
                                                                <></> }
                                                                {(testlibrary?.testlibrary?.PagingMetaData?.NextPage) ? (
                                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                                        </a>
                                                                ) 
                                                                : 
                                                                <></> }
                                                                </h5>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                                            <h5 style={styles.tableTextRight}> Page   {testlibrary?.testlibrary?.PagingMetaData?.PageNumber}  of  {testlibrary?.testlibrary?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Footer>
                                    </Card>
                                )
                                :
                                (<></>)}
                        </td>
                    </tr>
                </tbody>
            </Table>

            </div>
        </div>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state?.auth?.user,
        companyData: state?.auth?.companyData,
        testlibrary: state?.entity?.testlibrary,
        userattributelookups: state?.entity?.userattributelookups,
        lookupselections: state.entity.lookupselections,
        loadingMenu: state?.auth?.loadingMenu,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
        getTestLibraries: (oParams) => dispatch(getTestLibraries(oParams)),
        insertTestLibrary: (oParams) => dispatch(insertTestLibrary(oParams)),
        deleteTestLibrary: (oParams) => dispatch(deleteTestLibrary(oParams)),
        updateTestLibrary: (oParams) => dispatch(updateTestLibrary(oParams)),
        insertTestInstance: (oParams) => dispatch(insertTestInstance(oParams)),
        deleteTestInstance: (oParams) => dispatch(deleteTestInstance(oParams)),
        updateTestInstance: (oParams) => dispatch(updateTestInstance(oParams)),
        insertTestQuestion: (oParams) => dispatch(insertTestQuestion(oParams)),
        deleteTestQuestion: (oParams) => dispatch(deleteTestQuestion(oParams)),
        updateTestQuestion: (oParams) => dispatch(updateTestQuestion(oParams)),
        insertTestAnswer: (oParams) => dispatch(insertTestAnswer(oParams)),
        deleteTestAnswer: (oParams) => dispatch(deleteTestAnswer(oParams)),
        updateTestAnswer: (oParams) => dispatch(updateTestAnswer(oParams)),
        getContents: (oParams) => dispatch(getContents(oParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(TestLibraryList)
